<accenture-base-snack-bar
  [title]="data.title"
  [iconClass]="data.iconClass"
  [iconName]="data.iconName"
  [actionButtonClass]="data.actionButtonClass"
  [isVisibleDoNotShowAgain]="data.isVisibleDoNotShowAgain"
  [hideCloseButton]="data.hideCloseButton"
  [cancelBtnText]="data.cancelBtnText"
  (snackbarClosed)="onClose()"
  (doNotShowAgain)="onDoNotShowAgain($event)"
  (snackbarCancel)="onCancel()"
>
  <p class="tt9-body-2">{{ data.message }}</p>
</accenture-base-snack-bar>
