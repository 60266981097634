import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Subject, takeUntil } from 'rxjs';

import {
    CollectionOptionsType,
    collectionOptionValueName,
    CollectionSortOptions,
    collectionSortOptionsValues,
    CollectionsSortObject,
    Dictionary,
    OptionsForSortingByNumberOfUses,
    ProjectOptionsType,
    SessionOptions,
    SessionOptionsType,
    sortByNumberOfUsesValue,
    SortOrders,
    sortOrdersValues,
} from '@accenture/shared/data';
import { trackById } from '@accenture/shared/util';

type SortForm = FormGroup<{
    sortOption: FormControl<CollectionSortOptions>;
    sortOrder: FormControl<SortOrders>;
}>;

@Component({
    selector: 'accenture-options-filters-chips',
    templateUrl: './options-filters-chips.component.html',
    styleUrls: ['./options-filters-chips.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OptionsFiltersChipsComponent implements OnInit {
    @Input() sortCollectionsData: CollectionsSortObject;
    @Input() currentOption: SessionOptions;
    @Input() optionsData: ProjectOptionsType[] | SessionOptionsType[] | CollectionOptionsType[];
    @Input() selectedOptions: Dictionary<ProjectOptionsType> | Dictionary<string> | string[];
    @Input() chipsDisabled?: boolean;
    @Input() collectionToCount?: OptionsForSortingByNumberOfUses;
    @Output() onSortValuesChanged = new EventEmitter<CollectionsSortObject>();
    @Output() onSelectedOptionsChanged = new EventEmitter<{
        option: ProjectOptionsType | SessionOptionsType;
        isSelected: boolean;
        selectedOption?: ProjectOptionsType | SessionOptionsType;
    }>();

    sortOrderOptions = sortOrdersValues;
    isProjectOption: boolean = false;
    projectOptionName = collectionOptionValueName.projectName
    optionName = collectionOptionValueName.name
    sortByOptions = collectionSortOptionsValues;
    sortForm: SortForm = new FormGroup({
        sortOption: new FormControl<CollectionSortOptions>(CollectionSortOptions.Frequency, {
            nonNullable: true,
            updateOn: 'blur',
        }),
        sortOrder: new FormControl<SortOrders>(SortOrders.Dsc, { nonNullable: true, updateOn: 'blur' }),
    });

    trackById = trackById;

    private onDestroy = new Subject<void>();

    isOptionSelected(id: string): boolean {
        return Array.isArray(this.selectedOptions) ? this.selectedOptions.includes(id) : !!this.selectedOptions?.[id];
    }

    onSelectOption(option: ProjectOptionsType | SessionOptionsType): void {
        const selectedOptionId = Object.keys(this.selectedOptions || {})[0] || '';
        const isSelected = this.isOptionSelected(option.id);
        this.onSelectedOptionsChanged.emit({
            option,
            isSelected,
            selectedOption: [...this.optionsData].find(item => selectedOptionId === item.id),
        });
    }

    getOptionUsageFrequency(option: ProjectOptionsType | SessionOptionsType | CollectionOptionsType): number {
        const collectionsToCountProperty = sortByNumberOfUsesValue[this.collectionToCount];

        return (option[collectionsToCountProperty] || []).length;
    }

    ngOnInit(): void {
        this.initializeFiltersFormData();

        this.sortForm.valueChanges.pipe(takeUntil(this.onDestroy)).subscribe(formData => {
            this.onSortValuesChanged.emit({ ...formData });
        });
    }

    ngOnDestroy() {
        this.onDestroy.next();
        this.onDestroy.complete();
    }

    private initializeFiltersFormData(): void {
        this.sortForm.controls.sortOption.patchValue(
            this.sortCollectionsData?.sortOption || CollectionSortOptions.Frequency,
        );

        this.isProjectOption = this.currentOption === SessionOptions.SessionProject

        this.sortForm.controls.sortOrder.patchValue(this.sortCollectionsData?.sortOrder || SortOrders.Dsc);
    }
}
