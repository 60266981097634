import { AfterViewChecked, ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { tap } from 'rxjs';

import {
    CollectionSession,
    DefaultCollectionColor,
    emptyScreenButtonText,
    emptyScreenDescriptions,
    emptyScreenImage,
    emptyScreenImageUserProfile,
    emptyScreenTitles,
    UserCollection,
    UserSession,
} from '@accenture/shared/data';
import { IconSize } from '@accenture/shared/ui';
import { isSafari, isTruncated, trackById } from '@accenture/shared/util';

import {
    destinationCollectionPlaceHolderText,
    noCollectionsFoundHintText,
    sessionSingleCollectionHintText,
} from './constants';
import { CreateSessionSourceTypeDialogModel, MoveToCollectionDialogFacade } from './move-to-collection-dialog-facade';

@Component({
    selector: 'accenture-move-to-collection-dialog',
    templateUrl: './move-to-collection-dialog.component.html',
    styleUrls: ['./move-to-collection-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [MoveToCollectionDialogFacade],
})
export class MoveToCollectionDialogComponent implements AfterViewChecked {
    vm$ = this.facade.vm$.pipe(
        tap((vm: CreateSessionSourceTypeDialogModel) => {
            if (vm.noCollectionFound) {
                this.collectionInputControl.setErrors({ customError: true });
                this.collectionInputControl.markAsDirty();
                this.collectionInputControl.markAsTouched();
                this.isHintVisible = true;
            } else {
                this.collectionInputControl.setErrors(null);
                this.updateFieldHint();
            }
        }),
    );

    title = 'Move to collection';
    collectionInputControl = new FormControl<string>('');
    collectionControl = new FormControl<UserCollection>({} as UserCollection);
    trackById = trackById;
    emptyScreenTitle = emptyScreenTitles.noCollections;
    emptyScreenDescription = emptyScreenDescriptions.noCollections;
    emptyScreenButton = emptyScreenButtonText.noCollections;
    emptyScreenImage = emptyScreenImage;
    emptyScreenImageUserProfile = emptyScreenImageUserProfile;
    isTooltipHidden = true;
    maxSessionToolTipStringValue = 96;
    maxCollectionSesToolTipStringValue = 91;
    iconSize = IconSize;
    defaultCollectionColor = DefaultCollectionColor;
    isHintVisible = true;
    destinationCollectionPlaceHolderText = destinationCollectionPlaceHolderText;
    private truncateRun = true;

    constructor(
        @Inject(MAT_DIALOG_DATA)
        public data: {
            userSession?: UserSession;
            collectionSession?: CollectionSession;
        },
        private facade: MoveToCollectionDialogFacade,
    ) {}

    async addChip(value: UserCollection): Promise<void> {
        // Will be removed
        console.info(value);
        // CHECK FOR THE ERRORS!!
        // this.collectionControl.setValue(value);
        // this.updateAutocompleteValue('');
    }

    async removeChip(): Promise<void> {
        this.collectionControl.setValue(null);
        this.collectionInputControl.enable();
        this.collectionInputControl.setValue('');
        this.updateAutocompleteValue('');
    }

    async moveToCollection(): Promise<void> {
        await this.facade.moveToCollection();
        this.closeDialog();
    }

    setCollection(collection: UserCollection, optionId: string): void {
        this.isHintVisible = false;
        this.collectionControl.setValue(collection);
        this.collectionInputControl.setValue('');
        this.collectionInputControl.disable();
        this.facade.setSelectedCollection(collection);
        const optionSelected = document.getElementById(optionId);
        optionSelected.setAttribute('aria-selected', 'false');
        const classString = optionSelected.getAttribute('class');
        const newClassString = classString.replace('mdc-list-item--selected', '');
        optionSelected.setAttribute('class', newClassString);
    }

    getHintValue(inputValue: string): string {
        if (!inputValue) {
            return sessionSingleCollectionHintText;
        }

        return noCollectionsFoundHintText;
    }

    updateAutocompleteValue(value: string): void {
        this.updateFieldHint();
        this.facade.autocompleteValue$.next(value);
    }

    createNewCollection(): void {
        this.closeDialog();
        this.facade.createNewCollection();
    }

    closeDialog(): void {
        this.facade.closeDialog();
    }

    ngOnInit(): void {
        this.facade.updateSessionData(this.data);
    }

    ngAfterViewChecked(): void {
        const linesTruncate = 3;
        this.truncateTextToLines('sessionNameTruncated', linesTruncate);
        this.truncateRun = true;
        this.truncateTextToLines('collectionNameTruncated', linesTruncate);
    }

    isDisabledTooltip(event: Event): void {
        this.isTooltipHidden = isTruncated(event);
    }

    updateFieldHint() {
        if (this.collectionInputControl.value !== '') {
            this.isHintVisible = false;
        } else {
            this.isHintVisible = true;
        }
    }

    truncateTextToLines(elementName: string, maxLines: number): void {
        try {
            if (!this.truncateRun) {
                return;
            }
            const tmpElement = document.getElementById(elementName);
            const lineHeight = parseFloat(window.getComputedStyle(tmpElement, '').lineHeight);
            const ellipsisLength = 3;
            const maxHeight = lineHeight * maxLines;

            const fullText = tmpElement.textContent;

            if (fullText.length < 49) {
                return;
            }
            const truncatedText = fullText;
            tmpElement.textContent = '';

            for (let i = fullText.length; i >= 0; i--) {
                tmpElement.textContent = truncatedText.substring(0, i);

                if (tmpElement.offsetHeight < maxHeight) {
                    break;
                }
            }

            if (tmpElement.offsetHeight > maxHeight) {
                tmpElement.textContent = fullText;
            }

            if (fullText.length > tmpElement.textContent.length) {
                tmpElement.textContent = tmpElement.textContent.substring(
                    0,
                    tmpElement.textContent.length - ellipsisLength,
                );
                tmpElement.textContent += '...';
            }
            this.truncateRun = false;
        } catch (e) {
            this.truncateRun = true;
        }
    }
}
