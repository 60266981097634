import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { groupBy } from 'lodash';
import { BehaviorSubject, combineLatest, map, Observable, startWith } from 'rxjs';
import { distinctUntilChanged, switchMap, tap } from 'rxjs/operators';

import { ActivityService } from '@accenture/activity/shared/domain';
import {
    FavoriteTemplateService,
    FiltersService,
    projectCreateFromTemplateErrorTitle,
    projectCreateProgressFromTemplateMessage,
    projectCreateProgressFromTemplateTitle,
    projectCreateSuccessFromTemplateMessage,
    projectCreateSuccessFromTemplateTitle,
    ProjectService,
    SessionService,
    TemplateService,
    UserAccessService,
} from '@accenture/erp-deployment/shared/domain';
import {
    AppState,
    selectAuthenticatedUserId,
    selectRouterQueryParams,
} from '@accenture/global-store';
import {
    DefaultFilterObject,
    deletingTemplateErrorSnackbarTitle,
    deletingTemplateInProgressSnackbarTitle,
    Dictionary,
    errorMessageSnackbarText,
    FavoriteAccess,
    initialTemplatesFilterObject,
    NavigationTab,
    ParentType,
    parentTypeToDisplay,
    templateDeletedSnackbarTitle,
    TemplateTab,
    templateTabValue,
    UserAccess,
} from '@accenture/shared/data';
import { ComingSoonService, ConfirmationDialogComponent, DialogService, SnackbarService } from '@accenture/shared/ui';
import { transformToDictionary } from '@accenture/shared/util';

import { UseTemplateStore } from '../component-stores/use-template.store';
import { PublishTemplateDialogComponent } from '../publish-template-dialog/publish-template-dialog.component';
import { UseTemplateDialogComponent } from '../use-template-dialog/use-template-dialog.component';
import {
    confirmationDeletionMessages,
    projectTemplateHasBeenDeletedSnackbarText,
    projectTemplateIsBeingDeletedSnackbarText,
} from './constants';

export interface TemplatesListViewModel {
    userId: string;
    activeTemplateTab: TemplateTab;
    allTemplates: UserAccess[];
    templates: UserAccess[];
    templatesOfCurrentTab: UserAccess[];
    isTemplatesPage: boolean;
    isLoading: boolean;
    isAnyTemplate: boolean;
    isFilterAndSortPanelOpened: boolean;
    searchValue: string;
    favoritesTemplatesMap: Dictionary<FavoriteAccess>;
    isFiltersApplied: boolean;
}

const defaultViewModel = {
    userId: '',
    activeTemplateTab: TemplateTab.All,
    allTemplates: [],
    templates: [],
    templatesOfCurrentTab: [],
    isTemplatesPage: false,
    isLoading: true,
    isAnyTemplate: false,
    isFilterAndSortPanelOpened: false,
    searchValue: '',
    isFiltersApplied: false,
    favoritesTemplatesMap: {},
} as TemplatesListViewModel;

@Injectable()
export class TemplatesListFacade implements OnDestroy {
    private isLoading$ = new BehaviorSubject<boolean>(false);
    private isFilterAndSortPanelOpened$ = new BehaviorSubject<boolean>(false);
    private searchValue$ = new BehaviorSubject<string>('');
    private navigationTab: NavigationTab;
    private userId: string;
    private parentTypeToDisplay = parentTypeToDisplay;
    private favoritesTemplatesMap: Dictionary<FavoriteAccess>;

    vm$ = this.buildViewModel();

    constructor(
        private store: Store<AppState>,
        private userAccessService: UserAccessService,
        private templateService: TemplateService,
        private filtersService: FiltersService,
        private router: Router,
        private dialogService: DialogService,
        private useTemplateStore: UseTemplateStore,
        private projectService: ProjectService,
        private sessionService: SessionService,
        private activityService: ActivityService,
        private snackBarService: SnackbarService,
        private favoriteTemplateService: FavoriteTemplateService,
        private comingSoonService: ComingSoonService,
    ) {
        // set coming soon page visibility
        this.comingSoonService.useComingSoonPage = true;
    }

    setTemplateTab(templateTab: TemplateTab): void {
        this.filtersService.setMyTemplatesViewTab(templateTab);
        this.templateService.updateTemplatesFilters(
            this.userId,
            this.navigationTab === NavigationTab.Dashboard ? { homePageTab: templateTab } : { tab: templateTab },
        );
    }

    setSearchValue(value: string): void {
        this.searchValue$.next(value);
    }

    navigateHome(tab: NavigationTab): void {
        this.router.navigate(['/home'], { queryParams: { tab } });
    }

    toggleFilterAndSortPanel(opened: boolean): void {
        this.isFilterAndSortPanelOpened$.next(opened);
    }

    async useTemplate(templateType: ParentType, templateId: string): Promise<void> {
        this.useTemplateStore.setUseTemplateId(templateId);
        this.useTemplateStore.setUseTemplateType(templateType);
        this.useTemplateStore.setSelectedProjectId('');
        this.useTemplateStore.setIsNewProject(false);

        switch (templateType) {
            case ParentType.Projects: {
                await this.createProjectFromTemplate(templateId);
                break;
            }
            case ParentType.Templates:
            case ParentType.ActivityTemplates: {
                this.openUseTemplateModal();
                break;
            }
            default:
                await this.createProjectFromTemplate(templateId);
        }
    }

    onOpenPublishDialog(template: UserAccess): void {
        this.dialogService.open(PublishTemplateDialogComponent, {
            width: '768px',
            panelClass: 'tt9-modal',
            data: template,
        });
    }

    async onUpdateFavorite(template: UserAccess): Promise<void> {
        const currentFavoriteTemplate = this.favoritesTemplatesMap?.[template?.templateId];
        try {
            if (!!currentFavoriteTemplate) {
                await this.favoriteTemplateService.onDeleteFavoriteTemplate(currentFavoriteTemplate);
            } else {
                const newFavoriteAccess = new FavoriteAccess({
                    ...template,
                    userId: this.userId,
                });

                await this.favoriteTemplateService.onUpdateFavoriteTemplate(newFavoriteAccess);
            }
        } catch {
            console.log('Error while updating favorite template');
        }
    }

    async deleteTemplateConfirm(template: UserAccess): Promise<void> {
        const templateToDelete = `${this.parentTypeToDisplay[template.templateType]} template`;

        this.dialogService.open(ConfirmationDialogComponent, {
            width: '444px',
            panelClass: 'tt9-modal',
            title: `Delete ${template.name} ${templateToDelete}?`,
            confirmBtnText: 'Delete',
            cancelBtnText: 'Cancel',
            isWarning: true,
            text: confirmationDeletionMessages(templateToDelete),
            confirm: () => this.deleteTemplate(template, templateToDelete),
        });
    }

    async deleteTemplate(template: UserAccess, templateToDelete: string) {
        try {
            this.templateService.deletingTemplateIds$.next([
                ...this.templateService.deletingTemplateIds$.getValue(),
                template.templateId,
            ]);
            this.snackBarService.showInfoSnackBar(
                deletingTemplateInProgressSnackbarTitle,
                projectTemplateIsBeingDeletedSnackbarText(templateToDelete),
            );
            switch (template.templateType) {
                case ParentType.ProjectTemplates:
                    await this.projectService.deleteProject(template.templateId, ParentType.ProjectTemplates);
                    break;
                case ParentType.Templates:
                    await this.sessionService.deleteSession(template.templateType, template.id, template.templateId);
                    break;
                case ParentType.ActivityTemplates: {
                    await this.activityService.deleteActivity(
                        template.templateType,
                        template.templateId,
                        template.templateId,
                    );
                    break;
                }
            }

            this.snackBarService.showSuccessSnackBar(
                templateDeletedSnackbarTitle,
                projectTemplateHasBeenDeletedSnackbarText(templateToDelete),
            );
        } catch (e) {
            this.snackBarService.showErrorSnackBar(
                deletingTemplateErrorSnackbarTitle,
                `${templateToDelete} has not been deleted`,
            );
            this.templateService.deletingTemplateIds$.next(
                this.templateService.deletingTemplateIds$
                    .getValue()
                    .filter(templateId => templateId !== template.templateId),
            );
        } finally {
            this.router.navigate(['/home'], { queryParams: { tab: NavigationTab.Templates } });
        }
    }

    private buildViewModel(): Observable<TemplatesListViewModel> {
        return combineLatest([
            this.store.select(selectAuthenticatedUserId),
            this.store.select(selectRouterQueryParams).pipe(
                map(({ tab }) => {
                    this.navigationTab = tab;

                    return tab === NavigationTab.Templates;
                }),
            ),
        ]).pipe(
            switchMap(([userId, isTemplatesPage]) => {
                this.userId = userId;

                return combineLatest([
                    this.filtersService.myTemplatesViewTab$,
                    this.getTemplatesByUserId(userId),
                    this.getAllTemplatesFilters(isTemplatesPage),
                    this.isFilterAndSortPanelOpened$.asObservable().pipe(distinctUntilChanged()),
                    this.searchValue$.asObservable().pipe(
                        map((value: string) => value.trim().toLowerCase()),
                        distinctUntilChanged(),
                    ),
                    this.favoriteTemplateService.getFavoriteTemplatesByUserId(userId),
                    this.isLoading$.asObservable().pipe(distinctUntilChanged()),
                ]).pipe(
                    map(
                        ([
                            activeTemplateTab,
                            allTemplates,
                            filtersAndSort,
                            isFilterAndSortPanelOpened,
                            searchValue,
                            favoritesTemplates,
                            isLoading,
                        ]) => {
                            const templatesOfCurrentTab = this.getTemplatesByTab(allTemplates, activeTemplateTab);
                            const visibleTemplates = this.getVisibleTemplates(
                                templatesOfCurrentTab,
                                filtersAndSort,
                                searchValue,
                                isTemplatesPage,
                            );
                            this.favoritesTemplatesMap = transformToDictionary(favoritesTemplates, 'templateId');

                            return {
                                userId,
                                allTemplates,
                                activeTemplateTab,
                                searchValue,
                                isFilterAndSortPanelOpened,
                                isLoading,
                                isTemplatesPage,
                                templatesOfCurrentTab,
                                favoritesTemplatesMap: this.favoritesTemplatesMap,
                                templates: visibleTemplates,
                                isAnyTemplate: !!allTemplates.length,
                                isFiltersApplied: this.filtersService.isFiltersApplied(
                                    filtersAndSort,
                                    initialTemplatesFilterObject,
                                ),
                            };
                        },
                    ),
                );
            }),
            startWith(defaultViewModel),
        );
    }

    private async createProjectFromTemplate(templateId: string): Promise<void> {
        this.snackBarService.showInfoSnackBar(
            projectCreateProgressFromTemplateTitle,
            projectCreateProgressFromTemplateMessage,
        );

        try {
            const createdProjectId = await this.projectService.createProjectFromProjectTemplate(
                ParentType.ProjectTemplates,
                templateId,
                this.userId,
                true,
                undefined,
            );

            if (createdProjectId) {
                this.router.navigate(['/project', `${createdProjectId}`]);
            }

            this.snackBarService.showSuccessSnackBar(
                projectCreateSuccessFromTemplateTitle,
                projectCreateSuccessFromTemplateMessage,
            );
        } catch (e) {
            this.snackBarService.showErrorSnackBar(projectCreateFromTemplateErrorTitle, errorMessageSnackbarText);
        }
    }

    private openUseTemplateModal(): void {
        this.dialogService.open(UseTemplateDialogComponent, {
            width: 'auto',
            panelClass: 'tt9-modal',
        });
    }

    private getAllTemplatesFilters(isTemplatesPage: boolean): Observable<DefaultFilterObject> {
        return this.templateService.getCurrentUserAllTemplatesFilters(this.userId).pipe(
            tap(filterAndSort => {
                this.filtersService.setMyTemplatesViewTab(
                    filterAndSort?.tab || filterAndSort?.homePageTab
                        ? isTemplatesPage
                            ? filterAndSort?.tab || TemplateTab.All
                            : filterAndSort?.homePageTab || TemplateTab.All
                        : TemplateTab.All,
                );
            }),
            switchMap(allFilters =>
                this.filtersService.myTemplatesViewTab$.pipe(
                    distinctUntilChanged(),
                    map(activeTemplateTab => ({
                        ...initialTemplatesFilterObject,
                        ...(allFilters?.[this.getParentType(activeTemplateTab)] || {}),
                    })),
                ),
            ),
        );
    }

    private getParentType(templateTab: TemplateTab): string {
        return templateTabValue[templateTab];
    }

    private getVisibleTemplates(
        templates: UserAccess[],
        filters: DefaultFilterObject,
        searchValue: string,
        isTemplatePage: boolean,
    ): UserAccess[] {
        return !isTemplatePage ? templates : this.filtersService.filterAndSort(templates, filters, searchValue);
    }

    private getTemplatesByUserId(userId: string): Observable<UserAccess[]> {
        return combineLatest([
            this.userAccessService.getTemplatesByUserId(userId),
            this.templateService.deletingTemplateIds$,
        ]).pipe(
            map(([allTemplates, deletingTemplateIds]) =>
                allTemplates?.filter(template => !deletingTemplateIds.includes(template.templateId)),
            ),
        );
    }

    private getTemplatesByTab(templates: UserAccess[], tab: TemplateTab): UserAccess[] {
        const templatesByType = {
            [templateTabValue[TemplateTab.All]]: templates,
            ...groupBy(templates, 'templateType'),
        };

        return templatesByType[this.getParentType(tab)] || [];
    }

    ngOnDestroy(): void {
        // reset coming soon page visibility
        this.comingSoonService.useComingSoonPage = false;
    }
}
