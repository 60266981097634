import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { tap } from 'rxjs';

import { ActivityType } from '@accenture/shared/data';
import { analyzeButtonTitle } from '@accenture/shared/ui';
import {
    AnalyzeResponsesDialogFacade,
    AnalyzeResponsesDialogViewModel,
    DisplayState,
} from './analyze-responses-dialog-facade';

interface InputData {
    title: string;
    projectId: string;
    activityType: ActivityType;
    topicText?: string;
    selectedQuestionId?: string;
}

@Component({
    selector: 'accenture-analyze-responses-dialog',
    templateUrl: './analyze-responses-dialog.component.html',
    styleUrls: ['./analyze-responses-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [AnalyzeResponsesDialogFacade],
})
export class AnalyzeResponsesDialogComponent {
    analyzePromptId = '';
    analyzeButtonTitle = analyzeButtonTitle;
    vm$ = this.facade.vm$.pipe(
        tap((vm: AnalyzeResponsesDialogViewModel) => {
            if (vm.analyzePrompts.length) {
                this.analyzePromptId = vm.analyzePrompts[0].id;
            }
        }),
    );
    DisplayState = DisplayState;

    constructor(@Inject(MAT_DIALOG_DATA) public data: InputData, private facade: AnalyzeResponsesDialogFacade) {}

    ngOnInit(): void {
        this.facade.updateActivityType(this.data.activityType);
    }

    onCopy(analysis: string[]): Promise<void> {
        const copyOutput = `Topic: ${this.data.topicText}\nResults:\n${analysis.join('\n')}`;
        return navigator.clipboard.writeText(copyOutput);
    }

    onAnalyze(): Promise<void> {
        return this.facade.analyzeResponses(
            this.data.topicText,
            this.analyzePromptId,
            this.data.projectId,
            this.data.selectedQuestionId,
        );
    }

    onBack(error: string): void {
        this.analyzePromptId = '';
        if (error) {
            this.facade.resetError();
        }
        this.facade.updateDisplayState(DisplayState.ChoosePrompt);
    }
}
