import { Injectable } from '@angular/core';
import { map, Observable, of } from 'rxjs';

import {
    Activity,
    ActivityFactory,
    Attribute,
    AttributeClass,
    DBPathHelper,
    Dictionary,
    ParentType,
    Project,
    Session,
    TeamMember,
    TeamMemberSnackBarsVisibilityOption,
} from '@accenture/shared/data';
import { FirestoreService } from '@accenture/shared/data-access';
import { sortBySequenceAsc } from '@accenture/shared/util';

@Injectable()
export class ProjectDataService {
    constructor(private firestoreService: FirestoreService) {}

    getActivitiesByMultipleProperties(
        parentType: ParentType,
        parentId: string,
        fields: Map<string, string>,
    ): Observable<Activity[]> {
        return this.firestoreService
            .getDocumentsByMultipleProperties<Activity>(
                DBPathHelper.getActivityPath(parentType, parentId),
                fields,
                'sequence',
            )
            .pipe(
                map((activities: Activity[]) =>
                    sortBySequenceAsc(
                        activities
                            .map(({ id, ...activity }) => ActivityFactory.create(id as string, activity as Activity))
                            .filter(activity => !!activity),
                    ),
                ),
            );
    }

    getAttributes(parentType: ParentType, parentId: string): Observable<Attribute[]> {
        return this.firestoreService
            .getCollection<Attribute>(DBPathHelper.getAttributesPath(parentType, parentId))
            .pipe(map(attributes => sortBySequenceAsc(attributes)));
    }

    getAttributeClasses(parentType: ParentType, parentId: string): Observable<AttributeClass[]> {
        return this.firestoreService
            .getCollection<AttributeClass>(DBPathHelper.getAttributesClassesPath(parentType, parentId))
            .pipe(map(classes => sortBySequenceAsc(classes)));
    }

    getProject(parentType: ParentType, projectId: string): Observable<Project> {
        return this.firestoreService.getDocument<Project>(DBPathHelper.getParentPath(parentType, projectId));
    }

    getSession(parentType: ParentType, parentId: string, sessionId: string): Observable<Session> {
        return this.firestoreService
            .getDocument<Record<string, unknown>>(this.getSessionPath(parentType, parentId, sessionId))
            .pipe(map((data: any) => this.constructSession(data)));
    }

    getSessions(parentType: ParentType, parentId: string): Observable<Session[]> {
        return this.firestoreService
            .getCollection<Record<string, unknown>>(DBPathHelper.getSessionPath(parentType, parentId))
            .pipe(map(sessionData => sortBySequenceAsc(sessionData.map(data => this.constructSession(data)))));
    }

    getTeamMemberByParentType(parentType: ParentType, parentId: string, userId: string): Observable<TeamMember> {
        return this.get(parentType, parentId, userId);
    }

    getSessionTeamMemberByParent(
        parentType: ParentType,
        parentId: string,
        sessionId: string,
        userId: string,
    ): Observable<TeamMember | null> {
        const path = [ParentType.Templates, ParentType.PublicSessionTemplates].includes(parentType)
            ? DBPathHelper.getTeamMemberPath(parentType, parentId, userId)
            : DBPathHelper.getSessionTeamMemberPath(parentType, parentId, sessionId, userId);
        return this.firestoreService
            .getDocument(path)
            .pipe(map(teamMember => (teamMember ? new TeamMember(teamMember) : null)));
    }

    updateProjectTeamMember(projectId: string, userId: string, data: Partial<TeamMember>): Promise<void> {
        return this.update(ParentType.Projects, projectId, userId, data);
    }

    updateParentTeamMember(
        parentId: string,
        userId: string,
        data: Partial<TeamMember>,
        parentType: ParentType,
    ): Promise<void> {
        return this.update(parentType, parentId, userId, data);
    }

    updateSessionTeamMember(
        projectId: string,
        sessionId: string,
        userId: string,
        data: Partial<TeamMember>,
    ): Promise<void> {
        const path = `projects/${projectId}/sessions/${sessionId}/teamMembers/${userId}`;

        return this.firestoreService.update(path, data);
    }

    clearOnlineState(projectId: string, sessionId: string, userId: string): Promise<void> {
        const batch = this.firestoreService.createBatch();
        batch.update(this.firestoreService.getDocumentRef<TeamMember>(`projects/${projectId}/teamMembers/${userId}`), {
            isOnline: false,
        });

        if (sessionId) {
            batch.update(
                this.firestoreService.getDocumentRef<TeamMember>(
                    `projects/${projectId}/sessions/${sessionId}/teamMembers/${userId}`,
                ),
                { isOnline: false },
            );
        }

        return batch.commit();
    }

    getSessionTeamMemberSnackBarsVisibility(
        userId: string,
        parentType: ParentType,
        parentId: string,
        sessionId: string,
    ): Observable<Dictionary<TeamMemberSnackBarsVisibilityOption>> {
        if (parentType === ParentType.PublicProjectTemplates) {
            return of(undefined);
        }

        const filterPath = DBPathHelper.getSessionTeamMemberSnackBarsVisibilityPath(
            parentType,
            parentId,
            userId,
            sessionId,
        );
        return this.firestoreService.getCollection(filterPath).pipe(
            map((snackBarsOptions: TeamMemberSnackBarsVisibilityOption[]) => {
                return snackBarsOptions.reduce((acc, data) => {
                    acc[data.id as string] = data;
                    return acc;
                }, {});
            }),
        );
    }

    private getSessionPath(parentType: ParentType, parentId: string, sessionId?: string): string {
        return [ParentType.Templates, ParentType.PublicSessionTemplates].includes(parentType)
            ? DBPathHelper.getParentPath(parentType, sessionId)
            : DBPathHelper.getSessionPath(parentType, parentId, sessionId);
    }

    private update(parentType: ParentType, parentId: string, userId: string, data: Partial<TeamMember>): Promise<void> {
        const path = `${parentType}/${parentId}/teamMembers/${userId}`;

        return this.firestoreService.updateDoc(path, data);
    }

    private get(parentType: ParentType, parentId: string, userId: string): Observable<TeamMember | null> {
        return this.firestoreService
            .getDocument(DBPathHelper.getTeamMemberPath(parentType, parentId, userId))
            .pipe(map(teamMember => (teamMember ? new TeamMember(teamMember) : null)));
    }

    private constructSession(data: Record<string, unknown>): Session {
        if (data) {
            const id = typeof data.id === 'string' ? data.id : '';
            return new Session(id, data);
        }
        return null;
    }
}
