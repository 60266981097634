<div class="tt9-add-template-dialog-header header d-flex-center">
  <div class="d-flex">
    <p class="tt9-h1 text-overflow-ellipsis-1">Edit Message</p>
  </div>
  <button class="tt9-btn-icon-filled" (click)="close()">
    <accenture-icon>icon-close</accenture-icon>
  </button>
</div>

<div class="d-flex-column">
  <div class="p-32">
    <mat-form-field appearance="outline" class="mat-form-field tt9-form-field textarea width-100">
      <textarea
        matInput
        #userInput
        cdkTextareaAutosize
        #autosize="cdkTextareaAutosize"
        rows="3"
        cdkAutosizeMaxRows="5"
        type="text"
        class="message-textarea"
        [value]="message"
      ></textarea>
      <mat-error>{{ validationMessages.required }}</mat-error>
    </mat-form-field>
  </div>
  <div class="tt9-add-template-dialog-footer footer d-flex-end">
    <div class="d-flex">
      <button class="tt9-btn-default btn-icon mr-8" (click)="close()">
        <span>Cancel</span>
      </button>
      <button class="tt9-btn" (click)="save(userInput.value)">
        <span>Save</span>
      </button>
    </div>
  </div>
</div>
