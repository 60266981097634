import { AfterViewInit, ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Timestamp } from 'firebase/firestore';
import { BehaviorSubject, Observable, tap } from 'rxjs';

import {
    emptyScreenDescriptions,
    emptyScreenImageUrlStore,
    emptyScreenTitles,
    inputPlaceholders,
    NavigationTab,
    PublicAccess,
    TemplateAccessType,
    TemplatesTab,
    TemplateTab,
    tooltipTexts,
} from '@accenture/shared/data';
import { trackById } from '@accenture/shared/util';

import { TemplatesStoreFacade, TemplatesStoreViewModel } from './templates-store-facade';

@UntilDestroy()
@Component({
    selector: 'accenture-templates-store',
    templateUrl: './templates-store.component.html',
    styleUrls: ['./templates-store.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [TemplatesStoreFacade],
})
export class TemplatesStoreComponent implements OnDestroy, AfterViewInit {
    vm$: Observable<TemplatesStoreViewModel> = this.getInitialViewModel();

    trackById = trackById;
    emptyScreenDescriptions = emptyScreenDescriptions;
    emptyScreenImageUrlStore = emptyScreenImageUrlStore;
    inputPlaceholders = inputPlaceholders;
    tooltipTexts = tooltipTexts;
    templateTabs = [TemplateTab.All, TemplateTab.Projects, TemplateTab.Sessions, TemplateTab.Activities];
    templateTab = TemplateTab;
    hoveredCardIndex$ = new BehaviorSubject<number | undefined>(undefined);
    menuOpened = false;
    searchControl = new FormControl<string>('', { nonNullable: true });

    readonly hoveredCardIndex = this.hoveredCardIndex$.asObservable();

    constructor(private facade: TemplatesStoreFacade, private route: ActivatedRoute, private router: Router) {}

    hoverTemplateCard(index: number): void {
        this.hoveredCardIndex$.next(index);
    }

    openTeamMembersPanel(event: Event): void {
        event?.stopPropagation();
        this.facade.openTeamMembersPanel();
    }

    closeTeamMembersPanel(event: Event): void {
        event?.stopPropagation();
        this.facade.closeTeamMembersPanel();
    }

    navigateBack(event: Event): void {
        this.closeTeamMembersPanel(event);
    }

    unhoverTemplateCard(index: number): void {
        if (this.hoveredCardIndex$.getValue() === index && !this.menuOpened) {
            this.resetTemplateCardHover();
        }
    }

    getTemplateUpdateDate(template: PublicAccess): Date {
        const updated = template.updated as Timestamp;
        return updated?.toDate ? updated.toDate() : new Date();
    }

    isAllTemplateTab(activeTemplateTab: TemplateTab): boolean {
        return activeTemplateTab === TemplateTab.All;
    }

    useTemplate(template: PublicAccess): void {
        this.facade.useTemplate(template.templateType, template.id, template.templateId);
    }

    editTemplate(template: PublicAccess, isEditPending?: boolean): void {
        if (isEditPending) {
            return;
        }
        this.facade.editTemplate(template);
    }

    getEmptyScreenTitle(vm: TemplatesStoreViewModel): string {
        const noTemplatesYetMap = {
            [TemplateTab.All]: emptyScreenTitles.noTemplatesYet,
            [TemplateTab.Projects]: emptyScreenTitles.noProjectsTemplatesYet,
            [TemplateTab.Sessions]: emptyScreenTitles.noSessionsTemplatesYet,
            [TemplateTab.Activities]: emptyScreenTitles.noActivitiesTemplatesYet,
        };

        switch (true) {
            case !vm.allTemplatesCount:
                return noTemplatesYetMap[TemplateTab.All];
            case !vm.templatesOfCurrentTabCount:
                return noTemplatesYetMap[vm.activeTemplateTab];
            case !!vm.searchValue === true && vm.isFiltersApplied === true:
                return emptyScreenTitles.noTemplatesMatchAndFound;
            case !!vm.searchValue === false && vm.isFiltersApplied === true:
                return emptyScreenTitles.noTemplatesMatch;
            case !!vm.searchValue === true && vm.isFiltersApplied === false:
                return emptyScreenTitles.noTemplatesFound;
            case !!vm.searchValue === false && vm.isFiltersApplied === false:
                return noTemplatesYetMap[vm.activeTemplateTab];
        }
    }

    setTemplateTab(tab: TemplateTab): void {
        this.facade.setTemplateTab(tab);
        this.resetTemplateCardHover();
    }

    setSearchValue(value: string): void {
        this.facade.setSearchValue(value);
    }

    toggleFilterAndSortPanel(opened: boolean, event?: Event): void {
        this.facade.toggleFilterAndSortPanel(opened);
        if (event) {
            event.stopPropagation();
        }
    }

    clearSearchValue(event: Event): void {
        event.stopPropagation();
        this.setSearchValue('');
    }

    saveAsTemplate(template: PublicAccess): void {
        this.facade.saveAsTemplate(template);
    }

    openForPreview(data: { template: PublicAccess; event?: Event }): void {
        this.facade.openForPreview(data.template);
        if (data.event) {
            data.event.stopPropagation();
        }
    }

    closePreviewPanel(event: Event): void {
        event?.stopPropagation();
        this.facade.openForPreview(null);
    }

    copyLinkAccessToClipboard(template: TemplateAccessType): void {
        this.facade.copyLinkAccessToClipboard(template);
    }

    async onUpdateFavorite(template: PublicAccess): Promise<void> {
        await this.facade.onUpdateFavorite(template);
    }

    ngAfterViewInit(): void {
        this.route.queryParams.pipe(untilDestroyed(this)).subscribe(queryParams => {
            if (queryParams.searchValue && queryParams.activeTemplateStoreTab) {
                this.setTemplateTab(queryParams.activeTemplateStoreTab);
                this.setSearchValue(queryParams.searchValue);

                this.router.navigate(['home'], {
                    queryParams: {
                        tab: NavigationTab.Templates,
                        templatesTab: TemplatesTab.TemplateStore,
                    },
                });
            }
        });
    }

    ngOnDestroy(): void {
        this.facade.openForPreview(null);
    }

    private getInitialViewModel(): Observable<TemplatesStoreViewModel> {
        return this.facade.vm$.pipe(
            tap(({ searchValue, templatesOfCurrentTabCount }) => {
                this.searchControl.patchValue(searchValue);
                this.searchControl[templatesOfCurrentTabCount ? 'enable' : 'disable']();
            }),
        );
    }

    private resetTemplateCardHover(): void {
        this.hoveredCardIndex$.next(undefined);
    }
}
