import { Injectable } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest, firstValueFrom, Observable } from 'rxjs';
import { map, startWith, switchMap, tap } from 'rxjs/operators';

import { ProjectService, UserAccessService } from '@accenture/erp-deployment/shared/domain';
import { AppState, selectAuthenticatedUser } from '@accenture/global-store';
import { DuplicateType, ParentType, routerLinksMap, User, UserAccess } from '@accenture/shared/data';
import { FirestoreService } from '@accenture/shared/data-access';
import { DialogService } from '@accenture/shared/ui';

import { SelectSourceTypeDialogComponent } from '../select-source-type-dialog/select-source-type-dialog.component';
import { AddProjectDialogComponent } from './add-project-dialog.component';

export interface AddProjectDialogViewModel {
    templates: UserAccess[];
    selectedTemplateId: string;
    isLoading: boolean;
}

const defaultViewModel: AddProjectDialogViewModel = {
    templates: [],
    selectedTemplateId: '',
    isLoading: true,
};

@Injectable()
export class AddProjectDialogFacade {
    private userId!: string;
    private selectedTemplateId$ = new BehaviorSubject<string>('');
    private searchValue$ = new BehaviorSubject('');
    private isLoading$ = new BehaviorSubject<boolean>(false);

    vm$ = this.buildViewModel();

    constructor(
        private store: Store<AppState>,
        private dialogService: DialogService,
        private dialogRef: MatDialogRef<AddProjectDialogComponent>,
        private userAccessService: UserAccessService,
        private firestoreService: FirestoreService,
        private projectService: ProjectService,
        private router: Router,
    ) {}

    closeCurrentDialog(): void {
        this.dialogRef.close();

        this.dialogService.open(SelectSourceTypeDialogComponent, {
            panelClass: 'tt9-modal',
            width: '768px',
        });
    }

    updateSearchValue(value: string): void {
        this.searchValue$.next(value);
    }

    updateSelectedTemplateId(selectedTemplateId: string): void {
        this.selectedTemplateId$.next(selectedTemplateId);
    }

    async createProject(): Promise<void> {
        this.isLoading$.next(true);
        const selectedTemplateId = this.selectedTemplateId$.getValue() || '';
        const template = await firstValueFrom(this.projectService.getProjectTemplateById(selectedTemplateId));

        const duplicateMap = await this.firestoreService.cloudFunctionCallable('duplicateData', {
            from: {
                parentId: template.id,
                parentType: ParentType.ProjectTemplates,
            },
            to: {
                parentType: ParentType.Projects,
            },
            userId: this.userId,
            type: DuplicateType.Project,
            saveResponses: true,
        });

        const createdProjectId = duplicateMap[template.id];

        if (createdProjectId) {
            this.router.navigate([routerLinksMap[ParentType.Projects], createdProjectId]);
        }

        this.dialogService.close();
        this.isLoading$.next(false);
    }

    private buildViewModel(): Observable<AddProjectDialogViewModel> {
        return this.getUser().pipe(
            switchMap(user => {
                return combineLatest([
                    this.userAccessService.getTemplatesProjectAssignmentsByUserId(user.id),
                    this.selectedTemplateId$,
                    this.searchValue$,
                    this.isLoading$,
                ]);
            }),
            map(([templates, selectedTemplateId, searchValue, isLoading]) => {
                return {
                    selectedTemplateId,
                    isLoading,
                    templates: this.getFilteredTemplates(templates, searchValue),
                };
            }),
            startWith(defaultViewModel),
        );
    }

    private getUser(): Observable<User> {
        return this.store.pipe(select(selectAuthenticatedUser), tap(this.setCurrentUser.bind(this)));
    }

    private setCurrentUser(user: User): void {
        this.userId = user.id;
    }

    private getFilteredTemplates(templates: UserAccess[], searchValue: string): UserAccess[] {
        return templates.filter(template => {
            const hasSearchName = template.name?.toLowerCase().includes(searchValue);
            const hasSearchDescription = template.description?.toLowerCase().includes(searchValue);

            return hasSearchName || hasSearchDescription;
        });
    }
}
