import { Directive, ElementRef } from '@angular/core';

@Directive({
    selector: '[accentureScrollSticky]',
})
export class ScrollStickyDirective {
    constructor(private element: ElementRef) {
        const stickyElement = this.element?.nativeElement;

        const observer = new IntersectionObserver(
            ([e]) => {
                e.target.classList.toggle('pinned', e.intersectionRatio < 1);
            },
            { threshold: [1] },
        );

        setTimeout(() => observer.observe(stickyElement), 0);
    }
}
