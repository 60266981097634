import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { groupBy } from 'lodash';
import { BehaviorSubject, combineLatest, distinctUntilChanged, map, Observable, startWith, switchMap, tap } from 'rxjs';

import {
    ActivityService,
    copyTemplateErrorTitle,
    copyTemplateProgressMessage,
    copyTemplateProgressTitle,
    FavoriteTemplateService,
    FiltersService,
    LinkAccessService,
    projectCreateFromTemplateErrorTitle,
    projectCreateProgressFromTemplateMessage,
    projectCreateProgressFromTemplateTitle,
    projectCreateSuccessFromTemplateMessage,
    projectCreateSuccessFromTemplateTitle,
    ProjectService,
    PublicTemplateService,
    savePublicActivityTmpAsTemplateInProgress,
    savePublicActivityTmpAsTemplateSuccessfully,
    savePublicProjectTmpAsTemplateInProgress,
    savePublicProjectTmpAsTemplateSuccess,
    savePublicSessionTmpAsTemplateInProgress,
    savePublicSessionTmpAsTemplateSuccess,
    savePublicTemplateTitleError,
    savePublicTemplateTitleProgress,
    savePublicTemplateTitleSuccess,
    SessionService,
    TemplateService,
} from '@accenture/erp-deployment/shared/domain';
import { AppState, selectAuthenticatedUserId, selectFeatureToggle } from '@accenture/global-store';
import {
    DefaultFilterObject,
    Dictionary,
    errorMessageSnackbarText,
    FavoriteAccess,
    FeatureToggleName,
    initialTemplatesFilterObject,
    ParentType,
    PublicAccess,
    publicTemplateTabValue,
    routerLinksMap,
    TemplateAccessType,
    TemplateTab,
    templateTabValue,
} from '@accenture/shared/data';
import { ComingSoonService, DialogService, SnackbarService, SnackBarTypes } from '@accenture/shared/ui';
import { transformToDictionary } from '@accenture/shared/util';

import { UseTemplateStore } from '../component-stores/use-template.store';
import { templateEditorWelcomeSnackBarTitle } from '../template-editor-welcome-snackbar/constants';
import { TemplateEditorWelcomeSnackbarComponent } from '../template-editor-welcome-snackbar/template-editor-welcome-snackbar.component';
import { UseTemplateDialogComponent } from '../use-template-dialog/use-template-dialog.component';

export interface TemplatesStoreViewModel {
    userId: string;
    showDeepLinks: boolean;
    activeTemplateTab: TemplateTab;
    templates: PublicAccess[];
    isLoading: boolean;
    isAnyTemplate: boolean;
    isFilterAndSortPanelOpened: boolean;
    isTemplateTeamPanelOpened: boolean;
    allTemplatesCount: number;
    templatesOfCurrentTabCount: number;
    searchValue: string;
    previewedTemplate: PublicAccess | FavoriteAccess;
    favoritesTemplatesMap: Dictionary<FavoriteAccess>;
    isFiltersApplied: boolean;
    isEditPending: boolean;
    canEditTemplate: boolean;
}

const defaultViewModel = {
    userId: '',
    showDeepLinks: false,
    activeTemplateTab: TemplateTab.All,
    templates: [],
    isLoading: true,
    isAnyTemplate: false,
    isFilterAndSortPanelOpened: false,
    isTemplateTeamPanelOpened: false,
    allTemplatesCount: 0,
    templatesOfCurrentTabCount: 0,
    searchValue: '',
    previewedTemplate: null,
    favoritesTemplatesMap: {},
    isFiltersApplied: false,
    isEditPending: false,
    canEditTemplate: false,
} as TemplatesStoreViewModel;

@Injectable()
export class TemplatesStoreFacade implements OnDestroy {
    private isLoading$ = new BehaviorSubject<boolean>(false);
    private isFilterAndSortPanelOpened$ = new BehaviorSubject<boolean>(false);
    private isTemplateTeamPanelOpened$ = new BehaviorSubject<boolean>(false);
    private searchValue$ = new BehaviorSubject<string>('');
    private editingTemplatePending$ = new BehaviorSubject(false);
    private userId: string;
    private favoritesTemplatesMap: Dictionary<FavoriteAccess>;

    vm$ = this.buildViewModel();

    constructor(
        private store: Store<AppState>,
        private router: Router,
        private useTemplateStore: UseTemplateStore,
        private templateService: TemplateService,
        private publicTemplateService: PublicTemplateService,
        private filtersService: FiltersService,
        private activityService: ActivityService,
        private sessionService: SessionService,
        private projectService: ProjectService,
        private snackbarService: SnackbarService,
        private dialogService: DialogService,
        private favoriteTemplateService: FavoriteTemplateService,
        private comingSoonService: ComingSoonService,
        private linkAccessService: LinkAccessService,
    ) {
        // set coming soon page visibility
        this.comingSoonService.useComingSoonPage = true;
    }

    async setTemplateTab(templateTab: TemplateTab): Promise<void> {
        this.filtersService.setTemplateStoreViewTab(templateTab);
        await this.templateService.updateTemplatesStoreFilters(this.userId, {
            tab: templateTab,
        });
    }

    openTeamMembersPanel(): void {
        this.isTemplateTeamPanelOpened$.next(true);
    }

    closeTeamMembersPanel(): void {
        this.isTemplateTeamPanelOpened$.next(false);
    }

    setSearchValue(value: string): void {
        this.searchValue$.next(value);
    }

    toggleFilterAndSortPanel(opened: boolean): void {
        this.isFilterAndSortPanelOpened$.next(opened);
    }

    private getTemplateLink(template: PublicAccess, templateId?: string): string {
        if (template.templateType === ParentType.PublicActivityTemplates) {
            return `/${routerLinksMap[ParentType.ActivityTemplates]}/${template.activityType.toLowerCase()}-edit/${
                templateId || template.templateId
            }`;
        }

        if (template.templateType === ParentType.PublicSessionTemplates) {
            return `/${routerLinksMap[ParentType.Templates]}/${templateId || template.templateId}`;
        }

        return `/${routerLinksMap[ParentType.ProjectTemplates]}/${templateId || template.templateId}`;
    }

    private getEditingTemplateLink(template: PublicAccess, templateId?: string): string {
        if (template.templateType === ParentType.PublicActivityTemplates) {
            return `/${
                routerLinksMap[ParentType.PublicActivityTemplates]
            }/${template.activityType.toLowerCase()}-edit/${templateId || template.draftTemplateId}`;
        }

        if (template.templateType === ParentType.PublicSessionTemplates) {
            return `/${routerLinksMap[ParentType.PublicSessionTemplates]}/${templateId || template.draftTemplateId}`;
        }

        return `/${routerLinksMap[ParentType.PublicProjectTemplates]}/${templateId || template.draftTemplateId}`;
    }

    useTemplate(templateType: ParentType, publicAccessId: string, templateId: string): void {
        this.useTemplateStore.setUsePublicAccessId(publicAccessId);
        this.useTemplateStore.setUseTemplateId(templateId);
        this.useTemplateStore.setUseTemplateType(templateType);
        this.useTemplateStore.setSelectedProjectId('');
        this.useTemplateStore.setIsNewProject(false);

        switch (templateType) {
            case ParentType.PublicProjectTemplates: {
                this.createProjectFromPublicProjectTemplate(publicAccessId, templateId);
                break;
            }
            case ParentType.PublicSessionTemplates: {
                this.openUseTemplateModal();
                break;
            }
            case ParentType.PublicActivityTemplates: {
                this.openUseTemplateModal();
                break;
            }
            default:
                this.createProjectFromPublicProjectTemplate(publicAccessId, templateId);
        }
    }

    openForPreview(template: PublicAccess): void {
        this.useTemplateStore.setPreviewTemplate(template);
    }

    openUseTemplateModal(): void {
        this.dialogService.open(UseTemplateDialogComponent, {
            width: 'auto',
            panelClass: 'tt9-modal',
        });
    }

    copyLinkAccessToClipboard(template: TemplateAccessType): void {
        this.linkAccessService.copyProjectTemplateLinkAccessToClipboard(template);
    }

    async editTemplate(publicAccess: PublicAccess): Promise<void> {
        if (publicAccess.draftTemplateId) {
            this.navigateToEditingTemplate(publicAccess);
            return;
        }

        const snackBarRef = this.snackbarService.showInfoSnackBar(
            copyTemplateProgressTitle,
            copyTemplateProgressMessage,
        );
        this.editingTemplatePending$.next(true);
        try {
            const newTemplateId = await this.publicTemplateService.createEditablePublicTemplate(
                publicAccess.templateId,
                publicAccess.templateType,
                this.userId,
                true,
            );
            snackBarRef.dismiss();
            await this.navigateToEditingTemplate(publicAccess, newTemplateId);
        } catch (e) {
            this.snackbarService.showErrorSnackBar(copyTemplateErrorTitle, errorMessageSnackbarText);
        }
        this.editingTemplatePending$.next(false);
    }

    async saveAsTemplate(template: PublicAccess): Promise<void> {
        const parentTabType = template.templateType;

        switch (parentTabType) {
            case ParentType.PublicProjectTemplates:
                this.savePublicProjectTmpAsTemplate(template);
                break;
            case ParentType.PublicSessionTemplates:
                this.savePublicSessionTmpAsTemplate(template);
                break;
            case ParentType.PublicActivityTemplates:
                this.savePublicActivityTmpAsTemplate(template);
                break;
            default:
                break;
        }
    }

    async onUpdateFavorite(template: PublicAccess): Promise<void> {
        const currentFavoriteTemplate = this.favoritesTemplatesMap?.[template?.templateId];
        try {
            if (!!currentFavoriteTemplate) {
                await this.favoriteTemplateService.onDeleteFavoriteTemplate(currentFavoriteTemplate, template.id);
            } else {
                const isTemplateOwner = template.ownerId === this.userId;
                const newFavoriteAccess = new FavoriteAccess({
                    ...template,
                    userId: this.userId,
                    publicAccessId: template.id,
                    draftTemplateId: isTemplateOwner ? template.draftTemplateId : null,
                    hideWelcomeSnackBar: isTemplateOwner ? template.hideWelcomeSnackBar : null,
                });
                await this.favoriteTemplateService.onUpdateFavoriteTemplate(newFavoriteAccess, template.id);
            }
        } catch {
            console.error('Error while updating favorite template');
        }
    }

    async createProjectFromPublicProjectTemplate(publicAccessId: string, templateId: string): Promise<void> {
        this.snackbarService.showInfoSnackBar(
            projectCreateProgressFromTemplateTitle,
            projectCreateProgressFromTemplateMessage,
        );

        try {
            const createdProjectId = await this.projectService.createProjectFromProjectTemplate(
                ParentType.PublicProjectTemplates,
                templateId,
                this.userId,
                true,
                publicAccessId,
            );

            if (createdProjectId) {
                this.router.navigate(['/project', `${createdProjectId}`]);
            }
            this.snackbarService.showSuccessSnackBar(
                projectCreateSuccessFromTemplateTitle,
                projectCreateSuccessFromTemplateMessage,
            );
        } catch (e) {
            console.error(projectCreateFromTemplateErrorTitle);
            this.snackbarService.showInfoSnackBar(projectCreateFromTemplateErrorTitle, errorMessageSnackbarText);
        }
    }

    private async savePublicActivityTmpAsTemplate(template: PublicAccess): Promise<void> {
        this.snackbarService.showInfoSnackBar(
            savePublicTemplateTitleProgress,
            savePublicActivityTmpAsTemplateInProgress,
        );
        try {
            const activityTmpId = await this.activityService.saveAsTemplate(
                template.templateId,
                template.templateId,
                ParentType.PublicActivityTemplates,
                true,
            );
            const redirectUrl = this.getTemplateLink(template, activityTmpId);
            this.router.navigateByUrl(redirectUrl);
            this.snackbarService.showSuccessSnackBar(
                savePublicTemplateTitleSuccess,
                savePublicActivityTmpAsTemplateSuccessfully,
            );
        } catch (e) {
            console.error(savePublicTemplateTitleError);
            this.snackbarService.showErrorSnackBar(savePublicTemplateTitleError, errorMessageSnackbarText);
        }
    }

    private async savePublicSessionTmpAsTemplate(template: PublicAccess): Promise<void> {
        this.snackbarService.showInfoSnackBar(
            savePublicTemplateTitleProgress,
            savePublicSessionTmpAsTemplateInProgress,
        );
        try {
            const sessionTmpId = await this.sessionService.saveAsTemplate(
                template.templateId,
                template.templateId,
                ParentType.Templates,
                ParentType.PublicSessionTemplates,
                true,
            );
            const redirectUrl = this.getTemplateLink(template, sessionTmpId);
            this.router.navigateByUrl(redirectUrl);
            this.snackbarService.showSuccessSnackBar(
                savePublicTemplateTitleSuccess,
                savePublicSessionTmpAsTemplateSuccess,
            );
        } catch (e) {
            console.error(savePublicTemplateTitleError);
            this.snackbarService.showErrorSnackBar(savePublicTemplateTitleError, errorMessageSnackbarText);
        }
    }

    private async savePublicProjectTmpAsTemplate(template: PublicAccess): Promise<void> {
        this.snackbarService.showInfoSnackBar(
            savePublicTemplateTitleProgress,
            savePublicProjectTmpAsTemplateInProgress,
        );
        try {
            const projectTmpId = await this.projectService.saveAsTemplate(
                template.templateId,
                ParentType.PublicProjectTemplates,
                true,
            );
            this.snackbarService.showSuccessSnackBar(
                savePublicTemplateTitleSuccess,
                savePublicProjectTmpAsTemplateSuccess,
            );
            const redirectUrl = this.getTemplateLink(template, projectTmpId);
            this.router.navigateByUrl(redirectUrl);
        } catch (e) {
            console.error(savePublicTemplateTitleError);
            this.snackbarService.showErrorSnackBar(savePublicTemplateTitleError, errorMessageSnackbarText);
        }
    }

    private async navigateToEditingTemplate(publicAccess: PublicAccess, newTemplateId?: string): Promise<void> {
        const redirectUrl = this.getEditingTemplateLink(publicAccess, newTemplateId);

        await this.router.navigateByUrl(redirectUrl);

        if (!publicAccess?.hideWelcomeSnackBar) {
            this.snackbarService.showCustomSnackBar(
                TemplateEditorWelcomeSnackbarComponent,
                {
                    title: templateEditorWelcomeSnackBarTitle,
                    isVisibleDoNotShowAgain: true,
                    onClickDoNotShowAgain: (value: boolean) => {
                        this.hideWelcomeSnackbar(publicAccess, value);
                    },
                },
                SnackBarTypes.Info,
                0,
            );
        }
    }

    private buildViewModel(): Observable<TemplatesStoreViewModel> {
        return combineLatest([
            this.store.select(selectAuthenticatedUserId),
            this.store.select(selectFeatureToggle(FeatureToggleName.ShowDeepLinks)),
        ]).pipe(
            switchMap(([userId, showDeepLinks]) => {
                this.userId = userId;

                return combineLatest([
                    this.filtersService.templatesStoreViewTab$,
                    this.publicTemplateService.getPublicTemplates(),
                    this.getAllTemplatesFilters(),
                    this.isFilterAndSortPanelOpened$.asObservable().pipe(distinctUntilChanged()),
                    this.isTemplateTeamPanelOpened$.asObservable().pipe(distinctUntilChanged()),
                    this.searchValue$.asObservable().pipe(
                        map((value: string) => value.trim().toLowerCase()),
                        distinctUntilChanged(),
                    ),
                    this.favoriteTemplateService.getFavoriteTemplatesByUserId(userId),
                    this.isLoading$.asObservable().pipe(distinctUntilChanged()),
                    this.useTemplateStore.previewTemplate$,
                    this.editingTemplatePending$.asObservable().pipe(distinctUntilChanged()),
                ]).pipe(
                    map(
                        ([
                            activeTemplateTab,
                            allTemplates,
                            filtersAndSort,
                            isFilterAndSortPanelOpened,
                            isTemplateTeamPanelOpened,
                            searchValue,
                            favoritesTemplates,
                            isLoading,
                            previewedTemplate,
                            isEditPending,
                        ]) => {
                            const templatesOfCurrentTabCount = this.getTemplatesCountByTab(
                                allTemplates,
                                activeTemplateTab,
                            );
                            const visibleTemplates = this.getVisibleTemplates(
                                allTemplates,
                                filtersAndSort,
                                searchValue,
                                activeTemplateTab,
                            );

                            this.favoritesTemplatesMap = transformToDictionary(favoritesTemplates, 'templateId');
                            const canEditTemplate
                                = previewedTemplate?.ownerId === userId
                                || (previewedTemplate?.teamMembersIds || []).includes(userId);

                            return {
                                userId,
                                showDeepLinks,
                                activeTemplateTab,
                                searchValue,
                                isFilterAndSortPanelOpened,
                                isTemplateTeamPanelOpened,
                                previewedTemplate,
                                templatesOfCurrentTabCount,
                                isLoading,
                                isEditPending,
                                canEditTemplate,
                                favoritesTemplatesMap: this.favoritesTemplatesMap,
                                allTemplatesCount: allTemplates?.length,
                                templates: visibleTemplates,
                                isAnyTemplate: !!allTemplates?.length,
                                isFiltersApplied: this.filtersService.isFiltersApplied(
                                    filtersAndSort,
                                    initialTemplatesFilterObject,
                                ),
                            };
                        },
                    ),
                );
            }),
            startWith(defaultViewModel),
        );
    }

    private getTemplatesCountByTab(templates: PublicAccess[], tab: TemplateTab): number {
        const templatesByType = {
            [templateTabValue[TemplateTab.All]]: templates,
            ...groupBy(templates, 'templateType'),
        };

        return (templatesByType[this.getPublicParentType(tab)] || [])?.length;
    }

    private getPublicParentType(templateTab: TemplateTab): string {
        return publicTemplateTabValue[templateTab];
    }

    private getAllTemplatesFilters(): Observable<DefaultFilterObject> {
        return this.templateService.getCurrentUserAllTemplatesStoreFilters(this.userId).pipe(
            tap(filterAndSort => {
                this.filtersService.setTemplateStoreViewTab(filterAndSort?.tab || TemplateTab.All);
            }),
            switchMap(allFilters =>
                this.filtersService.templatesStoreViewTab$.pipe(
                    distinctUntilChanged(),
                    map(activeTemplateTab => ({
                        ...initialTemplatesFilterObject,
                        ...(allFilters?.[this.getParentType(activeTemplateTab)] || {}),
                    })),
                ),
            ),
        );
    }

    private getParentType(templateTab: TemplateTab): string {
        return templateTabValue[templateTab];
    }

    private getVisibleTemplates(
        allTemplates: PublicAccess[],
        currentUserFilters: DefaultFilterObject,
        searchValue: string,
        activeTemplateTab: TemplateTab,
    ): PublicAccess[] {
        const templatesByType = {
            [TemplateTab.All]: allTemplates,
            ...groupBy(allTemplates, 'templateType'),
        };
        const templates
            = templatesByType[
                {
                    [TemplateTab.Projects]: ParentType.PublicProjectTemplates,
                    [TemplateTab.Sessions]: ParentType.PublicSessionTemplates,
                    [TemplateTab.Activities]: ParentType.PublicActivityTemplates,
                    [TemplateTab.All]: TemplateTab.All,
                }[activeTemplateTab]
            ];

        return templates?.length
            ? this.filtersService.filterAndSortTemplatesStore(templates, currentUserFilters, searchValue, this.userId)
            : [];
    }

    private async hideWelcomeSnackbar(publicAccess: PublicAccess, value: boolean): Promise<void> {
        const favoriteAccess = this.favoritesTemplatesMap?.[publicAccess?.templateId] || null;
        await this.publicTemplateService.updateTemplateSnackBarVisibility(publicAccess.id, value, favoriteAccess?.id);
    }

    ngOnDestroy(): void {
        // reset coming soon page visibility
        this.comingSoonService.useComingSoonPage = false;
    }
}
