import { Injectable } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import { groupBy } from 'lodash';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { map, startWith, switchMap, tap } from 'rxjs/operators';

import { AttributeService } from '@accenture/erp-deployment/shared/domain';
// eslint-disable-next-line @nx/enforce-module-boundaries
import {
    AppState,
    selectAuthenticatedUserId,
    selectSessionAttributeClasses,
    selectSessionAttributes,
    selectSessionIdNew,
    selectSessionTeamMemberData,
} from '@accenture/global-store';
// eslint-disable-next-line @nx/enforce-module-boundaries
import {
    Attribute,
    AttributeClass,
    Dictionary,
    ParentType,
    TeamMember,
    updateAttributes,
} from '@accenture/shared/data';
import { ConfirmationDialogComponent, DialogService } from '@accenture/shared/ui';

import { SelectAttributesDialogComponent } from './select-attributes-dialog.component';

export interface SelectAttributesDialogViewModel {
    attributeClasses: AttributeClass[];
    groupedAttributes: Dictionary<Attribute[]>;
    userAttributes: Dictionary<string[]>;
    activeAttributeClassId?: string;
    attributeClassesIds: string[];
    searchValue: string;
    isLoading: boolean;
}

const defaultViewModel: SelectAttributesDialogViewModel = {
    attributeClasses: [],
    attributeClassesIds: [],
    groupedAttributes: {} as Dictionary<Attribute[]>,
    userAttributes: {} as Dictionary<string[]>,
    searchValue: '',
    activeAttributeClassId: undefined,
    isLoading: true,
};

@Injectable()
export class SelectAttributesDialogFacade {
    vm$ = this.buildViewModel();
    teamMember!: TeamMember;
    attributes!: Attribute[];
    sessionId!: string;

    private activeAttributeClassId$: BehaviorSubject<string> = new BehaviorSubject<string>('');
    private isLoading$ = new BehaviorSubject<boolean>(false);
    private searchValue$ = new BehaviorSubject<string>('');

    constructor(
        private store: Store<AppState>,
        private dialogService: DialogService,
        private dialogRef: MatDialogRef<SelectAttributesDialogComponent>,
        private attributeService: AttributeService,
    ) {}

    filterAttributes(searchValue: string): void {
        this.searchValue$.next(searchValue);
    }

    closeDialog(): void {
        this.dialogRef.close();
    }

    setActiveAttributeClass(classId: string): void {
        this.filterAttributes('');
        this.activeAttributeClassId$.next(classId);
    }

    saveUserAttributesConfirmation(
        userSelectedAttributes: Dictionary<string[]>,
        unselectAttributesIds: Set<string>,
    ): void {
        if (unselectAttributesIds.size) {
            this.dialogService.open(ConfirmationDialogComponent, {
                title: updateAttributes.title,
                confirmBtnText: 'Update',
                cancelBtnText: 'Cancel',
                width: '444px',
                text: updateAttributes.text,
                confirm: () => this.saveUserAttributes(userSelectedAttributes, unselectAttributesIds),
            });
        } else {
            this.saveUserAttributes(userSelectedAttributes);
        }
    }

    async saveUserAttributes(
        userSelectedAttributes: Dictionary<string[]>,
        unselectAttributesIds?: Set<string>,
    ): Promise<void> {
        this.isLoading$.next(true);
        await this.attributeService.saveUserAttributes(
            this.sessionId,
            this.teamMember?.id as string,
            userSelectedAttributes,
        );

        if (unselectAttributesIds) {
            const attributes = this.attributes.filter((attribute) => unselectAttributesIds.has(attribute.id));
            await this.attributeService.deleteAttributes(ParentType.Sessions, this.sessionId, attributes, true);
        }

        this.isLoading$.next(false);
        this.closeDialog();
    }

    private buildViewModel(): Observable<SelectAttributesDialogViewModel> {
        return combineLatest([
            this.store.select(selectAuthenticatedUserId),
            this.store.select(selectSessionIdNew),
        ]).pipe(
            switchMap(([userId, { sessionId }]) => {
                if (!userId) {
                    return of(defaultViewModel);
                }

                this.sessionId = sessionId;

                return combineLatest([
                    this.getAttributeClasses(),
                    this.getAttributes(),
                    this.getTeamMember(),
                    this.activeAttributeClassId$.asObservable(),
                    this.getSearchValue(),
                    this.isLoading$.asObservable(),
                ]).pipe(
                    map(
                        ([
                            attributeClasses,
                            attributes,
                            currentUserMembership,
                            activeAttributeClassId,
                            searchValue,
                            isLoading,
                        ]) => {
                            const userAttributes = currentUserMembership?.attributes || {};
                            const filteredAttributes = attributes.filter((attribute) =>
                                attribute.name.toLowerCase().includes(searchValue),
                            );
                            const groupedAttributes = groupBy(filteredAttributes, 'classId');
                            const attributeClassesData = this.getAttributeClassesData(
                                attributeClasses,
                                userAttributes,
                                activeAttributeClassId,
                            );

                            return {
                                attributeClasses,
                                groupedAttributes,
                                userAttributes,
                                searchValue,
                                isLoading,
                                attributeClassesIds: attributeClassesData.attributeClassesIds,
                                activeAttributeClassId: activeAttributeClassId || attributeClassesData.currentTabValue,
                            };
                        },
                    ),
                );
            }),
            startWith(defaultViewModel),
        );
    }

    private getAttributeClasses(): Observable<AttributeClass[]> {
        return this.store.select(selectSessionAttributeClasses);
    }

    private getAttributeClassesData(
        attributeClasses: AttributeClass[],
        userAttributes: Dictionary<string[]>,
        activeAttributeClassId: string,
    ): { attributeClassesIds: string[]; currentTabValue: string } {
        const activeTabValue = activeAttributeClassId;
        let noUserAttributesClassId = '';
        let currentTabValue = '';
        const attributeClassesIds
            = (attributeClasses || []).map((attributeClass) => {
                const attributeClassId = attributeClass.id as string;

                // if user have no active tab and class without selected attribute -> set this class id as active tab
                if (!noUserAttributesClassId && !userAttributes[attributeClassId]?.length) {
                    noUserAttributesClassId = attributeClassId;
                }

                return attributeClassId;
            }) || [];

        if (!activeTabValue) {
            currentTabValue = noUserAttributesClassId || (attributeClasses[0]?.id as string);
        }

        return { attributeClassesIds, currentTabValue };
    }

    private getAttributes(): Observable<Attribute[]> {
        return this.store.select(selectSessionAttributes).pipe(
            tap((attributes) => {
                this.attributes = attributes;
            }),
        );
    }

    private getTeamMember(): Observable<TeamMember> {
        return this.store.pipe(
            select(selectSessionTeamMemberData),
            tap((currentUserMembership) => (this.teamMember = currentUserMembership)),
        );
    }

    private getSearchValue(): Observable<string> {
        return this.searchValue$.asObservable().pipe(map((value: string) => value.trim().toLowerCase()));
    }
}
