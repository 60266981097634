import { Pipe, PipeTransform } from '@angular/core';
import { isNull, isUndefined } from 'lodash';

import { Attribute, Question } from '@accenture/shared/data';

@Pipe({
    name: 'displayActivityRequired',
})
export class DisplayActivityRequiredPipe implements PipeTransform {
    transform(attribute: Attribute | undefined, activityItem: Question): boolean {
        const attributeId: string | undefined = attribute?.id;
        const isDefaultRequired = activityItem.required?.['default'];

        if (!attributeId) {
            return isDefaultRequired;
        }

        const required = activityItem.required?.[attributeId];
        const isRequired = isNull(required) || isUndefined(required) ? isDefaultRequired : required;

        return isRequired;
    }
}
