<div
  class="create-session-menu tt9-btn d-flex-center"
  [matMenuTriggerFor]="createSessionMenu"
  (click)="createSessionMenuOpen = true"
>
  <span class="create-session-menu-title tt9-body-2">{{ buttonText }}</span>
  <accenture-icon>{{ createSessionMenuOpen ? "icon-chevron-up-small" : "icon-chevron-down-small" }}</accenture-icon>
</div>

<mat-menu
  #createSessionMenu="matMenu"
  class="create-session-matt-menu tt9-menu"
  [xPosition]="'before'"
  (closed)="createSessionMenuOpen = false"
>
  <button mat-menu-item (click)="buttonClick.emit('primary-button')">
    <accenture-icon> icon-add </accenture-icon>
    Create New
  </button>
  <button *ngIf="!isSessionPageButton" mat-menu-item (click)="buttonClick.emit('add-existing')">
    <accenture-icon> icon-file-plus </accenture-icon>
    Add existing
  </button>
  <button mat-menu-item (click)="buttonClick.emit('from-template')">
    <accenture-icon> icon-create-template </accenture-icon>
    From Template
  </button>
  <button mat-menu-item (click)="buttonClick.emit('generate-using-ai')">
    <accenture-icon> icon-sparkle </accenture-icon>
    Generate Using AI
  </button>
</mat-menu>
