import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
    selector: '[accentureScrollTable]',
})
export class ScrollTableDirective {
    private nativeElement: HTMLElement;
    private oldScrollTopValue = 0;
    private oldScrollLeftValue = 0;

    private readonly horizontalShadowVisibleSelector = 'horizontal-shadow-visible';
    private readonly verticalShadowVisibleSelector = 'vertical-shadow-visible';

    constructor(private element: ElementRef) {
        this.nativeElement = this.element.nativeElement;
    }

    @HostListener('scroll', ['$event'])
    onEventHandler(): void {
        if (this.nativeElement.scrollTop !== this.oldScrollTopValue) {
            this.nativeElement.scrollTop > 0
                ? this.addShadowVisibleClass(this.horizontalShadowVisibleSelector)
                : this.removeShadowVisibleClass(this.horizontalShadowVisibleSelector);

            this.oldScrollTopValue = this.nativeElement.scrollTop;
        }

        if (this.nativeElement.scrollLeft !== this.oldScrollLeftValue) {
            this.nativeElement.scrollLeft > 0
                ? this.addShadowVisibleClass(this.verticalShadowVisibleSelector)
                : this.removeShadowVisibleClass(this.verticalShadowVisibleSelector);

            this.oldScrollLeftValue = this.nativeElement.scrollLeft;
        }
    }

    private addShadowVisibleClass(visibleCssClass: string) {
        if (!this.nativeElement.classList.contains(visibleCssClass)) {
            this.nativeElement.classList.add(visibleCssClass);
        }
    }

    private removeShadowVisibleClass(visibleCssClass: string) {
        if (this.nativeElement.classList.contains(visibleCssClass)) {
            this.nativeElement.classList.remove(visibleCssClass);
        }
    }
}
