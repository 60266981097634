export enum IconSize {
    XSmall,
    Small,
    SmallMedium,
    Medium,
    Large,
    XLarge,
    Huge,
}

export enum IconColor {
    Default,
    Secondary,
    Primary,
    Success,
    SuccessPrimary,
    SuccessSecondary,
    Focus,
    Warning,
    Error,
    Disabled,
    Accent,
}
