import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { AppState } from '@accenture/global-store';

export interface SessionRecorderTimer {
    seconds: string;
    minutes: string;
}

export interface SessionRecorderState {
    disclaimerId: string;
    timer: SessionRecorderTimer;
}

const initialState: SessionRecorderState = {
    disclaimerId: '',
    timer: {
        seconds: '',
        minutes: '',
    },
};

@Injectable()
export class SessionRecorderStore extends ComponentStore<SessionRecorderState> {
    constructor(private store: Store<AppState>) {
        super(initialState);
    }

    readonly disclaimerId$: Observable<string> = this.select((state) => state.disclaimerId);
    readonly timer$: Observable<SessionRecorderTimer> = this.select((state) => state.timer);

    readonly setDisClaimerId = this.updater((state, disclaimerId: string) => {
        return {
            ...state,
            disclaimerId,
        };
    });

    readonly setTimer = this.updater((state, timer: SessionRecorderTimer) => {
        return {
            ...state,
            timer,
        };
    });

    readonly resetState = this.updater(() => {
        return {
            ...initialState,
        };
    });
}
