import { Injectable } from '@angular/core';
import { Timestamp } from 'firebase/firestore';
import { firstValueFrom, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { DBPathHelper, ParentType, UserSession } from '@accenture/shared/data';
import { FirestoreService } from '@accenture/shared/data-access';

@Injectable({
    providedIn: 'root',
})
export class UserSessionService {
    constructor(private firestoreService: FirestoreService) {}

    getSessionsAssignmentsByUserId(userId: string): Observable<UserSession[]> {
        return this.getAssignments(userId, 'sessionId');
    }

    getUserAssignmentBySessionId(userId: string, sessionId: string): Observable<UserSession> {
        return this.firestoreService
            .getDocumentsByMultipleProperties<UserSession>(
                'userSessions',
                new Map([
                    ['sessionId', sessionId],
                    ['userId', userId],
                ]),
            )
            .pipe(
                map((userSession: UserSession[]) => {
                    if (userSession.length > 1) {
                        console.warn(
                            'UserSessionsService::getUserAssignmentBySessionId - more than 1 userSession documents found',
                            userSession,
                        );
                    }

                    return userSession.length > 0 ? userSession[0] : null;
                }),
            );
    }

    private getAssignments(userId: string, orderByField: string, parentType?: ParentType): Observable<UserSession[]> {
        const query = new Map([['userId', userId]]);
        if (parentType) {
            // TODO: better to update with parentType naming
            query.set('templateType', parentType);
        }
        // We use the orderByField to make sure we only get documents where that property is defined on the UserSessions document
        return this.firestoreService
            .getDocumentsByMultipleProperties(DBPathHelper.getUserSessionsPath(), query, orderByField)
            .pipe(
                // We are then sorting the returning documents by the updated property in descending order
                map((assignments: UserSession[]) => assignments.sort(UserSessionService.orderByUpdatedDesc)),
            );
    }

    private static orderByUpdatedDesc(a: UserSession, b: UserSession): number {
        const aUpdated = a.updated as Timestamp;
        const bUpdated = b.updated as Timestamp;
        return bUpdated?.toMillis() - aUpdated?.toMillis();
    }

    async getByUserAndSessionNew(userId: string, sessionId: string): Promise<UserSession> {
        const userSession = await firstValueFrom(
            this.firestoreService.getDocumentsByMultipleProperties<UserSession>(
                'userSessions',
                new Map([
                    ['sessionId', sessionId],
                    ['userId', userId],
                ]),
            ),
        );
        return userSession.length > 0 ? userSession[0] : null;
    }

    getUserSessionDocument(userId: string, sessionId: string): Observable<UserSession | null> {
        return this.firestoreService
            .getDocumentsByMultipleProperties<UserSession>(
                DBPathHelper.getUserSessionsPath(),
                new Map<string, any>([
                    ['userId', userId],
                    ['sessionId', sessionId],
                ]),
            )
            .pipe(
                map((userSessions) => {
                    if (!!userSessions.length) {
                        return userSessions.filter((userSession) => !userSession.markedForDelete)[0] || null;
                    }
                    return null;
                }),
            );
    }

    async updateSessionAssignmentBySessionId(
        sessionId: string,
        userId: string,
        data?: Partial<UserSession>,
    ): Promise<void> {
        const userAssignments = await firstValueFrom(
            this.firestoreService.getDocumentsByMultipleProperties<UserSession>(
                'userSessions',
                new Map([
                    ['sessionId', sessionId],
                    ['userId', userId],
                ]),
            ),
        );

        const userSessionId = userAssignments[0]?.id;
        if (!userSessionId) {
            return;
        }
        await this.firestoreService.update(`userSessions/${userSessionId}`, {
            ...data,
            lastViewed: this.firestoreService.timestamp,
        });
    }
}
