<ng-container *ngIf="vm$ | async as vm">
  <div class="form-wrapper">
    <ng-container *ngIf="!vm.isLoading">
      <accenture-new-session-invite-tabs />

      <mat-slide-toggle
        class="tt9-toggle mb-24"
        color="primary"
        [checked]="vm.isEnabledLinkAccess && !vm.isConfirmationDialogOpen"
        (change)="toggleLinkAccess($event.checked)"
        [disabled]="!vm.hasClient"
      >
        Enable QR / Link Access
      </mat-slide-toggle>

      <p *ngIf="!vm.hasClient" class="tt9-body-2 tt9-color-neutral-500 mb-24">
        External (non-Accenture) and guest users are not allowed to join the session if a Client is not defined. You can
        add you client in the Edit session screen.
      </p>

      <div *ngIf="isLinkAndQrAccessEnabled(vm)" class="d-flex d-flex-start flex-gap-24 mb-32">
        <div class="d-flex-column">
          <p class="tt9-body-2 tt9-color-neutral-500 mb-24">
            Share this link with QR code for guests to join the activity without needing a ThinkTank account. When
            copied to clipboard, it will contain a QR code, a link and a password for seamless access.
          </p>
          <div class="d-flex-start mt-16">
            <p class="tt9-body-1 tt9-color-neutral-500 mb-8">Session Name:</p>
          </div>
          <div class="d-flex-start">
            <p
              class="tt9-body-2 text-overflow-ellipsis-2 mb-24"
              matTooltipClass="tt9-arrow-tooltip-above"
              [matTooltip]="vm.linkAccess.sessionName"
            >
              {{ vm.linkAccess.sessionName }}
            </p>
          </div>
          <div class="d-flex-start">
            <mat-form-field appearance="outline" class="tt9-form-field width-100 hint">
              <mat-label>Password</mat-label>
              <input
                matInput
                autocomplete="new-password"
                [formControl]="passwordControl"
                [type]="vm.isVisiblePassword ? 'text' : 'password'"
                (blur)="updatePassword()"
              />
              <accenture-icon matSuffix (click)="togglePasswordVisibility()">
                {{ vm.isVisiblePassword ? "icon-visibility" : "icon-visibility-off" }}
              </accenture-icon>
              <mat-error *ngIf="passwordControl.hasError('minlength')">{{
                validationMessages.minPasswordLength(passwordControl.errors.minlength.requiredLength)
              }}</mat-error>
              <mat-error *ngIf="passwordControl.hasError('maxlength')">{{
                validationMessages.maxPasswordLength(passwordControl.errors.maxlength.requiredLength)
              }}</mat-error>
              <mat-error *ngIf="passwordControl.hasError('required')">{{ validationMessages.required }}</mat-error>
            </mat-form-field>
          </div>
        </div>

        <div class="d-flex-column qr-container">
          <img src="{{ vm.qrData }}" class="mb-16" alt="QR Code" />
          <p class="tt9-tooltip qr-note w-100">
            If you're using QR code, these are the activities available on mobile: Brainstorm, Quick Poll and Agenda.
          </p>
        </div>
      </div>
    </ng-container>

    <accenture-loading *ngIf="vm.isLoading"></accenture-loading>
  </div>

  <div class="accenture-dialog-actions">
    <button class="tt9-btn" [disabled]="!vm.isEnabledLinkAccess || passwordControl.invalid" (click)="copyInvite()">
      Copy Invite To Clipboard
    </button>
  </div>
</ng-container>
