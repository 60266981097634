import { Timestamp } from 'firebase/firestore';

import { removeEmptyKeys } from '@accenture/shared/util';

export enum SessionRecordingType {
    Capture = 'CAPTURE',
    Upload = 'UPLOAD',
}

export enum SessionRecordingMediaType {
    AudioVideo = 'video+audio',
    VideoOnly = 'video',
    AudioOnly = 'audio',
    WnKnown = '',
}

export interface SessionRecordingSubtitles {
    start: string;
    end: string;
    text: string;
}

export interface SessionRecordingCaptions {
    start: string;
    end: string;
    activityId?: string;
    text?: string;
}

export interface SessionRecordingSummary {
    agenda: string[];
    attendees: number;
    summary: string[];
    actionItems: string[];
    notes: string[];
}

export class SessionRecording {
    id?: string;
    userId?: string;
    fileUrl: string;
    disclaimerId?: string;
    transcription: string;
    type: SessionRecordingType;
    created: Timestamp;
    updated: Timestamp;
    actions: SessionRecordingCaptions[];
    fileName?: string;
    displayName?: string;
    length?: string;
    mediaType?: SessionRecordingMediaType;
    versions?: string[];

    constructor(message: Partial<SessionRecording>) {
        this.id = message.id ?? '';
        this.userId = message.userId ?? '';
        this.fileUrl = message.fileUrl ?? '';
        this.transcription = message.transcription ?? '';
        this.disclaimerId = message.disclaimerId ?? '';
        this.type = message.type ?? SessionRecordingType.Capture;
        this.created = message.created ?? Timestamp.now();
        this.updated = message.updated ?? Timestamp.now();
        this.actions = message.actions ?? [];
        this.fileName = message.fileName ?? '';
        this.displayName = message.displayName ?? '';
        this.length = message.length ?? '';
        this.mediaType = message.mediaType ?? SessionRecordingMediaType.WnKnown;
        this.versions = message.versions ?? [];
    }

    createSerializableObject(): Record<string, unknown> {
        return removeEmptyKeys({
            userId: this.userId,
            fileUrl: this.fileUrl,
            type: this.type,
            disclaimerId: this.disclaimerId,
            transcription: this.transcription,
            created: this.created,
            updated: this.updated,
            actions: this.actions,
            fileName: this.fileName,
            displayName: this.displayName,
            length: this.length,
            mediaType: this.mediaType,
            versions: this.versions,
        });
    }
}
