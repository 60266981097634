import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { Observable } from 'rxjs';

import { FavoriteAccess, ParentType, ProjectOptions, PublicAccess } from '@accenture/shared/data';

export interface UseTemplateState {
    usePublicAccessId: string;
    useTemplateId: string;
    useTemplateType: ParentType;
    selectedProjectId: string;
    isNewProject: boolean;
    isOptionChipsDisplayed: boolean;
    optionTypeActive: ProjectOptions;
    previewTemplate: PublicAccess | FavoriteAccess;
}

@Injectable({
    providedIn: 'root',
})
export class UseTemplateStore extends ComponentStore<UseTemplateState> {
    constructor() {
        super({
            usePublicAccessId: '',
            useTemplateId: '',
            useTemplateType: null,
            selectedProjectId: '',
            isNewProject: false,
            isOptionChipsDisplayed: false,
            optionTypeActive: '' as ProjectOptions,
            previewTemplate: null,
        });
    }

    readonly usePublicAccessId$: Observable<string> = this.select(state => state.usePublicAccessId);
    readonly useTemplateId$: Observable<string> = this.select(state => state.useTemplateId);
    readonly useTemplateType$: Observable<ParentType> = this.select(state => state.useTemplateType);
    readonly selectedProjectId$: Observable<string> = this.select(state => state.selectedProjectId);
    readonly isNewProject$: Observable<boolean> = this.select(state => state.isNewProject);
    readonly isOptionChipsDisplayed$: Observable<boolean> = this.select(state => state.isOptionChipsDisplayed);
    readonly optionTypeActive$: Observable<ProjectOptions> = this.select(state => state.optionTypeActive);
    readonly previewTemplate$: Observable<PublicAccess | FavoriteAccess> = this.select(state => state.previewTemplate);

    readonly setUseTemplateId = this.updater((state, useTemplateId: string) => {
        return {
            ...state,
            useTemplateId,
        };
    });

    readonly setUsePublicAccessId = this.updater((state, usePublicAccessId: string) => {
        return {
            ...state,
            usePublicAccessId,
        };
    });

    readonly setUseTemplateType = this.updater((state, useTemplateType: ParentType) => {
        return {
            ...state,
            useTemplateType,
        };
    });

    readonly setSelectedProjectId = this.updater((state, selectedProjectId: string) => {
        return {
            ...state,
            selectedProjectId,
        };
    });

    readonly setIsNewProject = this.updater((state, isNewProject: boolean) => {
        return {
            ...state,
            isNewProject,
        };
    });

    readonly setOptionsChipsDisplay = this.updater((state, isOptionChipsDisplayed: boolean) => {
        return {
            ...state,
            isOptionChipsDisplayed,
        };
    });

    readonly setActiveOptionType = this.updater((state, optionTypeActive: ProjectOptions) => {
        return {
            ...state,
            optionTypeActive,
        };
    });

    readonly setPreviewTemplate = this.updater((state, template: PublicAccess | FavoriteAccess) => {
        return {
            ...state,
            previewTemplate: template,
        };
    });
}
