import { ComponentType, Overlay } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { ElementRef, Injectable, Injector } from '@angular/core';

import { TOOLTIP_DATA, TooltipRef } from '../directives';
import { TooltipConfig } from '../models';
import { TooltipPositionService } from './tooltip-position.service';

@Injectable({
    providedIn: 'root',
})
export class TooltipService {
    constructor(
        private overlay: Overlay,
        private injector: Injector,
        private tooltipPositionService: TooltipPositionService,
    ) {}

    open<T>(elementRef: ElementRef, component: ComponentType<T>, config: TooltipConfig): TooltipRef {
        const positionStrategy = this.tooltipPositionService.getPositionStrategy(elementRef, config.position);
        const overlayRef = this.overlay.create({ positionStrategy });
        const tooltipRef = new TooltipRef(overlayRef);
        tooltipRef.addPanelClass(config.class);
        const injector = this.getInjector(tooltipRef, config);
        const portal = new ComponentPortal(component, null, injector);

        overlayRef.attach(portal);
        return tooltipRef;
    }

    private getInjector(tooltipRef: TooltipRef, config: any): Injector {
        return Injector.create({
            parent: this.injector,
            providers: [
                { provide: TooltipRef, useValue: tooltipRef },
                { provide: TOOLTIP_DATA, useValue: config?.data },
            ],
        });
    }
}
