export enum ImportType {
    Excel,
}

export enum ImportResultType {
    VoteItems,
    BrainstormResponses,
    FormQuestions,
    Emails,
    Attributes,
    TableItem,
}
