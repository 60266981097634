<ng-container *ngIf="vm$ | async as vm">
  <ng-container *accentureLoaded="!vm.isLoading">
    <ng-container *ngIf="vm.userCollections; else noUserCollections">
      <accenture-base-dialog [title]="title">
        <div class="content-container">
          <div *ngIf="vm.userCollections?.length; else emptyCollections" class="description-container">
            <!-- SESSION NAME -->
            <p class="tt9-color-neutral-500 name-title-session">SESSION NAME</p>
            <p
              id="sessionNameTruncated"
              class="move-text session-name"
              matTooltipClass="tt9-arrow-tooltip-below"
              matTooltipPosition="above"
              [matTooltip]="vm.sessionName"
              [matTooltipDisabled]="vm.sessionName.length < maxSessionToolTipStringValue"
            >
              {{ vm.truncatedSessionName }}
            </p>

            <!-- CURRENT COLLECTION NAME -->
            <div *ngIf="vm.session?.collectionName">
              <p class="tt9-color-neutral-500 name-title">CURRENT COLLECTION</p>
              <div class="d-flex">
                <accenture-icon
                  class="d-flex-center collections-icon"
                  [customColor]="vm.folderColor || defaultCollectionColor"
                  [size]="iconSize.Small"
                >
                  icon-folder-fill
                </accenture-icon>
                <span
                  id="collectionNameTruncated"
                  class="move-text collection-name"
                  matTooltipClass="tt9-arrow-tooltip-below"
                  matTooltipPosition="above"
                  [matTooltip]="vm.session?.collectionName"
                  [matTooltipDisabled]="vm.session?.collectionName?.length < maxCollectionSesToolTipStringValue"
                >
                  {{ vm.truncatedCollectionName }}
                </span>
              </div>
            </div>

            <!-- MOVE TO -->
            <p class="tt9-color-neutral-500 name-title">MOVE TO</p>
            <mat-form-field appearance="outline" class="tt9-form-field hint width-100" floatLabel="auto">
              <mat-label>{{ destinationCollectionPlaceHolderText }}</mat-label>
              <input
                #collectionsInput
                matInput
                [placeholder]="destinationCollectionPlaceHolderText"
                [matAutocomplete]="auto"
                [formControl]="collectionInputControl"
                [maxlength]="characterLimitMedium"
                (input)="updateAutocompleteValue($event.target['value'])"
                (keyup.enter)="addChip()"
                (blur)="addChip()"
                (focusin)="menuOpen = true"
                (focusout)="menuOpen = false"
              />

              <mat-hint *ngIf="isHintVisible">{{ getHintValue(collectionInputControl.value) }}</mat-hint>
              <accenture-icon [customColor]="'#8C8C8C'" (click)="menuOpen = true">
                {{ menuOpen ? "icon-drop-up" : "icon-drop-down" }}
              </accenture-icon>

              <mat-autocomplete hideSingleSelectionIndicator #auto="matAutocomplete">
                <ng-container>
                  <!-- Scrollable list of options -->
                  <div class="scrollable-options">
                    <mat-option
                      *ngFor="let collection of vm.filteredCollections; trackBy: trackById; let rowIndex = index"
                      [id]="'selOption-' + rowIndex"
                      [value]="collection.name"
                      (click)="setCollection(collection, 'selOption-' + rowIndex)"
                      (mousedown)="$event.preventDefault()"
                    >
                      {{ collection.name }}
                    </mat-option>
                  </div>

                  <!-- Divider -->
                  <div class="divider"></div>

                  <!-- Always-visible "Create Collection" option -->
                  <mat-option *ngIf="!vm.noCollectionFound" class="fixed-option" (click)="createNewCollection()"
                    >+ Create Collection</mat-option
                  >
                </ng-container>
              </mat-autocomplete>
            </mat-form-field>

            <mat-chip-listbox class="tt9-chip">
              <mat-chip-option *ngIf="!!collectionControl?.value?.name?.trim()">
                <span>{{ collectionControl?.value?.name }}</span>
                <accenture-icon matChipRemove (click)="removeChip(chip)">icon-close</accenture-icon>
              </mat-chip-option>
            </mat-chip-listbox>
          </div>

          <ng-template #emptyCollections>
            <div class="empty-collection-container">
              <accenture-empty-screen
                class="m-auto"
                [imageUrl]="emptyScreenImage"
                [title]="emptyScreenTitle"
                [description]="emptyScreenDescription"
                [isButtonVisible]="true"
                [buttonText]="emptyScreenButton"
                (buttonClick)="createNewCollection()"
              ></accenture-empty-screen>
            </div>
          </ng-template>
        </div>

        <!-- Fixed footer positioned at the bottom of the modal -->
        <div class="footer d-flex-end">
          <div class="d-flex">
            <button class="tt9-btn-default btn-icon mr-8" (click)="closeDialog()">
              <span>Cancel</span>
            </button>
            <button
              class="tt9-btn"
              [disabled]="!vm.userCollections?.length || !collectionControl?.value?.name"
              (click)="moveToCollection()"
            >
              Move
            </button>
          </div>
        </div>
      </accenture-base-dialog>
    </ng-container>
    <ng-template #noUserCollections>
      <div class="empty-screen-container">
        <accenture-empty-screen
          [title]="noCollectionsYetTitle"
          [imageUrl]="emptyScreenImage"
          [description]="noCollectionsYetDesc"
        />
      </div>
    </ng-template>
  </ng-container>
</ng-container>
