import { UserStatus, scssClassUserStatus } from '@accenture/shared/data';

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'userStatusClass',
    standalone: true,
})
export class UserStatusClassPipe implements PipeTransform {
    transform(status: UserStatus): string {
        return scssClassUserStatus[status];
    }
}
