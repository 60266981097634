import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import { ActivityType, DataFlowConnection } from '@accenture/shared/data';

import { SessionsDataFlowConnectionDialogFacade } from './sessions-data-flow-connection-dialog-facade';

@Component({
    selector: 'accenture-sessions-data-flow-connection-dialog',
    templateUrl: './sessions-data-flow-connection-dialog.component.html',
    styleUrls: ['./sessions-data-flow-connection-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [SessionsDataFlowConnectionDialogFacade],
})
export class SessionsDataFlowConnectionDialogComponent {
    vm$ = this.facade.vm$;

    constructor(@Inject(MAT_DIALOG_DATA) public data: any, private facade: SessionsDataFlowConnectionDialogFacade) {}

    async saveData(data: {
        dataFlowArray: DataFlowConnection[];
        generatedDestinationId?: string;
        destinationType?: ActivityType;
        destinationName?: string;
    }): Promise<void> {
        const { dataFlowArray } = data;

        const confirm = this.data?.confirm;

        await this.facade.saveDataFlowData(dataFlowArray, confirm);
    }
}
