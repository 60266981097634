<ng-container *ngIf="vm$ | async as vm">
  <ng-container *accentureLoaded="!vm.isLoading">
    <accenture-session-invite-tabs />

    <p class="tt9-body-2 tt9-color-neutral-500 mb-24">Invite leaders and participants by sending emails.</p>

    <div class="invitations-form" [formGroup]="inviteForm">
      <ng-container *ngFor="let role of rolesToAdd">
        <accenture-email-invitation-form
          [emailListName]="role | displayUserAccessRole"
          [blackListEmails]="role === sessionRole.Leader ? vm.blacklistLeaderEmails : vm.blacklistParticipantEmails"
          [formControlName]="role"
          [isNonAccentureAvailableRole]="true"
        >
          <accenture-icon ngProjectAs="input-suffix" (click)="openMemberPanel(role)">icon-people-group</accenture-icon>
        </accenture-email-invitation-form>
      </ng-container>

      <mat-form-field
        appearance="outline"
        class="textarea tt9-form-field mat-form-field-appearance-outline invitations-form-textarea"
      >
        <textarea
          #textInvite
          matInput
          [placeholder]="inputPlaceholders.inviteText"
          [maxlength]="characterLimitLarge"
          formControlName="message"
          (input)="setMessageValue(inviteForm.get('message').value)"
        ></textarea>
        <mat-hint class="mat-hint-limit" [class]="determineHintClass(textInvite.value.length, characterLimitLarge)">{{
          characterLimitText(textInvite.value.length, characterLimitLarge)
        }}</mat-hint>
      </mat-form-field>
    </div>
  </ng-container>
</ng-container>
