import { removeEmptyKeys } from '@accenture/shared/util';

import { Session } from '../session';
import { User } from '../user';
import { InAppNotificationType } from './in-app-notification-type';
import { BaseInAppNotification } from './in-app-notifications';

export class InAppSessionRecordingNotification extends BaseInAppNotification {
    projectId: string;
    sessionId: string;
    sessionName: string;
    message: string;

    constructor(notification: any);
    constructor(userId: string, projectId: string, session: Session, user: Partial<User>);
    constructor(notificationOrUserId: any, projectId?: string, session?: Session, user?: Partial<User>) {
        if (typeof notificationOrUserId === 'object') {
            super(notificationOrUserId as BaseInAppNotification);

            this.id = notificationOrUserId.id;
            this.type = InAppNotificationType.SessionRecording;
            this.projectId = notificationOrUserId.projectId;
            this.sessionId = notificationOrUserId.sessionId;
            this.sessionName = notificationOrUserId.sessionName;
            this.userId = notificationOrUserId.userId;
            this.message = notificationOrUserId.message;
        } else {
            super(InAppNotificationType.SessionRecording, notificationOrUserId);

            this.projectId = projectId;
            this.sessionId = session.id;
            this.sessionName = session.name;
            this.userId = user.id;
        }
    }

    createSerializableObject(): Record<string, unknown> {
        return removeEmptyKeys({
            type: this.type,
            userId: this.userId,
            unread: this.unread,
            projectId: this.projectId,
            sessionId: this.sessionId,
            sessionName: this.sessionName,
            message: this.message,
            created: this.created,
            updated: this.updated,
        });
    }
}
