import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';

import {
    DBPathHelper,
    editableCopyDuplicateType,
    ParentType,
    PublicAccess,
    PublicParentType,
    publishTemplateDuplicateType,
    publishTemplateType,
    TemplateStatus,
    UserAccess,
    UserTemplate,
} from '@accenture/shared/data';
import { FirestoreService } from '@accenture/shared/data-access';

@Injectable({
    providedIn: 'root',
})
export class PublicTemplateService {
    constructor(private firestoreService: FirestoreService) {}

    getPublicTemplates(): Observable<PublicAccess[]> {
        return this.firestoreService.getCollection(DBPathHelper.getPublicAccessPath(), 'created', 'desc');
    }

    // TODO: remove old function getPublicTemplates after implementation of collection
    getPublicTemplatesNew(): Observable<PublicAccess[]> {
        return this.firestoreService
            .getCollection(DBPathHelper.getPublicAccessPath(), 'created', 'desc')
            .pipe(
                map((items: PublicAccess[]) => items.filter((item) => item.templateType !== 'publicProjectTemplates')),
            );
    }

    getPublicTemplate<T>(parentType: PublicParentType, templateId: string): Observable<T> {
        return this.firestoreService.getDocument(DBPathHelper.getParentPath(parentType, templateId));
    }

    getIsPublicAccessExistByTemplateIdAndUserId(templateId: string, userId: string): Observable<boolean> {
        return this.firestoreService.getDocumentsExist(
            DBPathHelper.getPublicAccessPath(),
            new Map([
                ['draftTemplateId', templateId],
                ['ownerId', userId],
            ]),
        );
    }

    getIsPublicAccessExistByTemplateIdAndCollaboratorId(templateId: string, userId: string): Observable<boolean> {
        return this.firestoreService.getDocumentsExistByCombinedProperty(DBPathHelper.getPublicAccessPath(), [
            { property: 'draftTemplateId', operatorValue: '==', value: templateId },
            { property: 'teamMembersIds', operatorValue: 'array-contains', value: userId },
        ]);
    }

    // TODO: remove when old duplicateData is no longer used
    async onPublishTemplate(template: UserAccess, creatorNotes = '', saveResponses = false): Promise<void> {
        await this.firestoreService.cloudFunctionCallable('duplicateDataTt9', {
            creatorNotes,
            saveResponses,
            from: {
                parentId: template.templateId,
                parentType: template.templateType,
            },
            to: {
                parentType: publishTemplateType[template.templateType],
            },
            userId: template.userId,
            type: publishTemplateDuplicateType[template.templateType],
            env: window.location.origin,
        });

        // TODO: TT9-7258
        // To use for updating the status (published) of My Templates.
        // await this.firestoreService.update(DBPathHelper.getUserTemplatesPath(template.id), {
        //     status: TemplateStatus.Published,
        //     updated: this.firestoreService.timestamp,
        // });

        await this.firestoreService.update(DBPathHelper.getParentPath(template.templateType, template.templateId), {
            status: TemplateStatus.Published,
            updated: this.firestoreService.timestamp,
        });
    }

    async onPublishTemplateNew(template: UserTemplate, creatorNotes = '', saveResponses = false): Promise<void> {
        await this.firestoreService.cloudFunctionCallable('duplicateDataTt9', {
            creatorNotes,
            saveResponses,
            from: {
                parentId: template.templateId,
                parentType: template.templateType,
            },
            to: {
                parentType: publishTemplateType[template.templateType],
            },
            userId: template.userId,
            type: publishTemplateDuplicateType[template.templateType],
            env: window.location.origin,
        });

        await this.firestoreService.update(DBPathHelper.getUserTemplatesPath(template.id), {
            status: TemplateStatus.Published,
            updated: this.firestoreService.timestamp,
        });
    }

    // TODO: remove when old duplicateData is no longer used
    async createEditablePublicTemplate(
        templateId: string,
        templateType: ParentType,
        userId: string,
        saveResponses = false,
    ): Promise<string> {
        const newIdsMap = await this.firestoreService.cloudFunctionCallable('duplicateData', {
            userId,
            saveResponses,
            from: {
                parentId: templateId,
                parentType: templateType,
            },
            to: {
                parentType: templateType,
            },
            type: editableCopyDuplicateType[templateType],
            env: window.location.origin,
        });
        return newIdsMap[templateId];
    }

    async createEditablePublicTemplateNew(
        templateId: string,
        templateType: ParentType,
        userId: string,
        saveResponses = false,
    ): Promise<string> {
        const newIdsMap = await this.firestoreService.cloudFunctionCallable('duplicateDataTt9', {
            userId,
            saveResponses,
            from: {
                parentId: templateId,
                parentType: templateType,
            },
            to: {
                parentType: templateType,
            },
            type: editableCopyDuplicateType[templateType],
            env: window.location.origin,
        });
        return newIdsMap[templateId];
    }

    async updateTemplateSnackBarVisibility(
        publicAccessId: string,
        value: boolean,
        favoriteAccessId?: string,
    ): Promise<void> {
        const dataPath = DBPathHelper.getPublicAccessPath(publicAccessId);
        await this.firestoreService.update(dataPath, { hideWelcomeSnackBar: value });
        if (favoriteAccessId) {
            const favoriteAccessPath = DBPathHelper.getFavoriteAccessPath(favoriteAccessId);
            await this.firestoreService.update(favoriteAccessPath, { hideWelcomeSnackBar: value });
        }
    }
}
