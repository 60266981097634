import { DOCUMENT } from '@angular/common';
import { ElementRef, Inject, Injectable } from '@angular/core';
import { BehaviorSubject, EMPTY, fromEvent, Observable, startWith, Subject } from 'rxjs';
import { delay, map } from 'rxjs/operators';

import { Dictionary } from '@accenture/shared/data';

export interface FullscreenApi {
    enabled: string;
    element: string;
    request: string;
    exit: string;
    events: {
        change: string;
        error: string;
    };
}

export const defaultFullscreenApi = {
    enabled: 'fullscreenEnabled',
    element: 'fullscreenElement',
    request: 'requestFullscreen',
    exit: 'exitFullscreen',
    events: {
        change: 'fullscreenchange',
        error: 'fullscreenerror',
    },
};

@Injectable({
    providedIn: 'root',
})
export class FullscreenService {
    private readonly apis: Dictionary<FullscreenApi> = {
        w3: {
            enabled: 'fullscreenEnabled',
            element: 'fullscreenElement',
            request: 'requestFullscreen',
            exit: 'exitFullscreen',
            events: {
                change: 'fullscreenchange',
                error: 'fullscreenerror',
            },
        },
        webkit: {
            enabled: 'webkitFullscreenEnabled',
            element: 'webkitCurrentFullScreenElement',
            request: 'webkitRequestFullscreen',
            exit: 'webkitExitFullscreen',
            events: {
                change: 'webkitfullscreenchange',
                error: 'webkitfullscreenerror',
            },
        },
        moz: {
            enabled: 'mozFullScreenEnabled',
            element: 'mozFullScreenElement',
            request: 'mozRequestFullScreen',
            exit: 'mozCancelFullScreen',
            events: {
                change: 'mozfullscreenchange',
                error: 'mozfullscreenerror',
            },
        },
        ms: {
            enabled: 'msFullscreenEnabled',
            element: 'msFullscreenElement',
            request: 'msRequestFullscreen',
            exit: 'msExitFullscreen',
            events: {
                change: 'MSFullscreenChange',
                error: 'MSFullscreenError',
            },
        },
    };
    private readonly api?: FullscreenApi;
    private readonly changeObservable$: Observable<boolean>;
    private isFullScreen$ = new BehaviorSubject<boolean>(false);

    constructor(@Inject(DOCUMENT) private document: Document) {
        for (const vendor in this.apis) {
            if (this.apis[vendor].enabled in this.document) {
                this.api = this.apis[vendor];
                break;
            }
        }

        this.changeObservable$ = this.api
            ? fromEvent(this.document, this.api?.events?.change).pipe(
                  // we need to add zero delay because of safari closing event issue
                  // safari triggers closing event before the end of updating the layout
                  // and that may trigger some layout issues
                  delay(0),
                  map(() => {
                      const isFullScreen = !!this.document[this.api?.element];
                      this.isFullScreen$.next(isFullScreen);
                      return isFullScreen;
                  }),
                  startWith(false),
              )
            : EMPTY;
    }

    getFullScreenApi(): FullscreenApi {
        return this.api ?? defaultFullscreenApi;
    }

    onChange(): Observable<boolean> {
        return this.changeObservable$;
    }

    getIsFullScreen(): Observable<boolean> {
        return this.isFullScreen$.asObservable();
    }

    requestFullscreen(fullscreenElement: ElementRef): void {
        fullscreenElement?.nativeElement[this.api?.request]();
    }

    exitFullscreen(): void {
        this.document[this.api?.exit]();
    }
}
