import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';

import { TemplateAccessType } from '@accenture/shared/data';
import { FirestoreService } from '@accenture/shared/data-access';

@Injectable({
    providedIn: 'root',
})
export class TemplateAccessService {
    deleteField = this.firestoreService.deleteField;

    constructor(private firestoreService: FirestoreService) {}

    async updateTemplateAccess(
        accessPath: string,
        templateId: string,
        data: Partial<TemplateAccessType>,
    ): Promise<void> {
        const templateAccesses = await firstValueFrom(
            this.firestoreService.getDocumentsByProperty<Partial<TemplateAccessType>>(
                accessPath,
                'templateId',
                templateId,
            ),
        );

        if (!templateAccesses.length) {
            return;
        }

        const batchData = [];

        for (const templateAccess of templateAccesses) {
            if (templateAccess?.id) {
                batchData.push({
                    path: `${accessPath}/${templateAccess.id}`,
                    data: {
                        ...this.firestoreService.replaceEmptyFields(data),
                        updated: this.firestoreService.timestamp,
                    },
                });
            }
        }

        await this.firestoreService.updateBatch(batchData);
    }
}
