import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'percentagePipe',
})
export class PercentagePipe implements PipeTransform {
    transform(currentCount: number, totalCount: number): string {
        return +((currentCount / totalCount) * 100).toFixed() + '%';
    }
}
