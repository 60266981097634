import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { groupBy } from 'lodash';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { map, startWith, switchMap } from 'rxjs/operators';

import { ActivityItemService, ActivityService, TableColumnService } from '@accenture/activity/shared/domain';
import { DataFlowService, PublicTemplateService, TemplateService } from '@accenture/erp-deployment/shared/domain';
import {
    AppState,
    selectActivityIdAndParentIds,
    selectRouterQueryParams,
    selectUserSessions,
} from '@accenture/global-store';
import {
    Activity,
    ActivityItem,
    ActivityItemType,
    ActivityType,
    DataFlowAvailableActivityTypes,
    DataFlowConnection,
    Dictionary,
    ParentType,
    SessionRole,
    TableColumn,
    Template,
    UserSession,
} from '@accenture/shared/data';
import { DialogService, LoadedDescription } from '@accenture/shared/ui';
import { sortBySequenceAsc } from '@accenture/shared/util';

export interface ActivityEditDataFlowConnectionDialogFacadeState {
    activities: Activity[];
    activityItems: Dictionary<ActivityItem[]>;
    connectionData: DataFlowConnection;
    currentActivity: Activity;
    currentActivityItem: ActivityItem | undefined;
    sessions: UserSession[];
    columns: Dictionary<TableColumn[]> | undefined;
    isLoading: boolean;
    loaderDescription: string;
}

const defaultViewModel: ActivityEditDataFlowConnectionDialogFacadeState = {
    activities: [],
    activityItems: {} as Dictionary<ActivityItem[]>,
    currentActivity: {} as Activity,
    currentActivityItem: undefined,
    connectionData: {} as DataFlowConnection,
    sessions: [],
    isLoading: false,
    loaderDescription: '',
    columns: undefined,
};

@Injectable()
export class ActivityEditDataFlowConnectionDialogFacade {
    vm$ = this.buildViewModel();

    private isSavingProgress$ = new BehaviorSubject<boolean>(false);

    private parentType!: ParentType;
    private parentId!: string;

    constructor(
        private store: Store<AppState>,
        private dataFlowService: DataFlowService,
        private activityService: ActivityService,
        private dialogService: DialogService,
        private activityItemService: ActivityItemService,
        private tableColumnService: TableColumnService,
        private templateService: TemplateService,
        private publicTemplateService: PublicTemplateService,
    ) {}

    async saveDataFlowData(data: DataFlowConnection[]): Promise<void> {
        this.setLoadingStatus(true);
        await this.dataFlowService.updateBatchDataFlow(this.parentType, this.parentId, data);
        this.setLoadingStatus(false);

        this.dialogService.close();
    }

    async updateDataFlowConnection(connectionId: string, connections: DataFlowConnection[]): Promise<void> {
        this.setLoadingStatus(true);
        await this.dataFlowService.deleteDataFlowConnection(connectionId, this.parentType, this.parentId);
        await this.saveDataFlowData(connections);
        this.setLoadingStatus(false);
    }

    private buildViewModel(): Observable<ActivityEditDataFlowConnectionDialogFacadeState> {
        return this.store.pipe(select(selectActivityIdAndParentIds)).pipe(
            switchMap(({ parentId, activityId, parentType }) => {
                if (!parentId || !activityId) {
                    return of({
                        ...defaultViewModel,
                        isLoading: false,
                    });
                }

                this.setParentValue(parentId, parentType);

                return combineLatest([
                    this.getAllActivities(parentType, parentId),
                    this.activityItemService.getAllActivityItems(parentType, parentId),
                    this.dataFlowService.getDataFlowConnectionsByProperty(
                        parentType,
                        parentId,
                        'destinationId',
                        activityId,
                    ),
                    this.getUserSessions(parentType, parentId),
                    this.getColumns(parentType, parentId),
                    this.store.select(selectRouterQueryParams),
                    this.isSavingProgress$,
                ]).pipe(
                    map(([activities, activityItems, connectionData, sessions, columns, { tableId }, isLoading]) => {
                        const currentActivity = this.getCurrentActivity(activityId, activities);
                        const currentActivityItem = this.getCurrentActivityItem(tableId, activityItems);

                        return {
                            isLoading,
                            columns,
                            currentActivity,
                            currentActivityItem,
                            sessions,
                            parentType,
                            connectionData: this.getCurrentConnection(
                                connectionData,
                                currentActivity.type,
                                currentActivityItem,
                            ),
                            activities: this.getActivities(activities),
                            activityItems: groupBy(activityItems, 'activityId'),
                            loaderDescription: LoadedDescription.Saving,
                        };
                    }),
                );
            }),
            startWith(defaultViewModel),
        );
    }

    private getCurrentActivity(activityId: string, activities: Activity[]): Activity {
        return activities.find((activity) => activity.id === activityId) || ({} as Activity);
    }

    private getCurrentActivityItem(
        selectedActivityItemId: string,
        activityItems: ActivityItem[],
    ): ActivityItem | undefined {
        if (!selectedActivityItemId) {
            return;
        }

        return activityItems.find((activity) => activity.id === selectedActivityItemId) || ({} as ActivityItem);
    }

    private getCurrentConnection(
        connectionData: DataFlowConnection[],
        activityType: ActivityType,
        activityItem: ActivityItem,
    ): DataFlowConnection {
        if (activityType !== ActivityType.Table) {
            return connectionData[0];
        }

        return connectionData.find(
            (connection: DataFlowConnection) => connection.destinationActivityItem.id === activityItem.id,
        );
    }

    private getAllActivities(parentType: ParentType, parentId: string): Observable<Activity[]> {
        return this.activityService.getActivities(parentType, parentId);
    }

    private getUserSessions(parentType: ParentType, parentId: string): Observable<UserSession[]> {
        switch (parentType) {
            case ParentType.Sessions:
                return this.store.select(selectUserSessions).pipe(
                    map((userSessions) => {
                        return userSessions.filter((userSession) => userSession.role === SessionRole.Leader);
                    }),
                );
            case ParentType.Templates:
                return this.templateService.getTemplate(parentId).pipe(
                    map((template) => [
                        {
                            sessionId: template.id,
                            name: template.name,
                            description: template.description,
                            imageUrl: template.imageUrl,
                            imageId: template.imageId,
                            role: SessionRole.Leader,
                            created: template.created,
                            updated: template.updated,
                        } as UserSession,
                    ]),
                );
            case ParentType.PublicSessionTemplates:
                return this.publicTemplateService
                    .getPublicTemplate<Template>(ParentType.PublicSessionTemplates, parentId)
                    .pipe(
                        map((template) => [
                            {
                                sessionId: template.id,
                                name: template.name,
                                description: template.description,
                                imageUrl: template.imageUrl,
                                imageId: template.imageId,
                                role: SessionRole.Leader,
                                created: template.created,
                                updated: template.updated,
                            } as UserSession,
                        ]),
                    );
            default:
                return of([]);
        }
    }

    private getActivities(templateActivities: Activity[]): Activity[] {
        return templateActivities.filter((templateActivity) =>
            DataFlowAvailableActivityTypes.includes(templateActivity.type),
        );
    }

    private setLoadingStatus(isLoading: boolean): void {
        this.isSavingProgress$.next(isLoading);
    }

    private setParentValue(parentId: string, parentType: ParentType): void {
        this.parentType = parentType;
        this.parentId = parentId;
    }

    private getColumns(parentType: ParentType, parentId: string): Observable<Dictionary<TableColumn[]>> {
        return this.tableColumnService.getColumns(parentType, parentId).pipe(
            map((tableColumns: TableColumn[]) => {
                const textColumns = tableColumns.filter((column) => column?.type === ActivityItemType.Text);
                const sortedTextColumns = sortBySequenceAsc(textColumns);

                return groupBy(sortedTextColumns, 'activityItemId');
            }),
        );
    }
}
