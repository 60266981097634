import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { AttributesEffects } from './attributes.effects';
import { ATTRIBUTES_FEATURE_KEY, attributesReducer } from './attributes.reducer';
import { AttributesDataService } from './attributes-data.service';

@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        StoreModule.forFeature(ATTRIBUTES_FEATURE_KEY, attributesReducer),
        EffectsModule.forFeature([AttributesEffects]),
    ],
    exports: [],
    providers: [
        AttributesDataService
    ],
})
export class AttributesModule {}
