<!-- search -->
<div *ngIf="userSessions?.length || (!userSessions?.length && searchControl.value)" class="width-100 mb-24 mt-16">
  <mat-form-field appearance="outline" floatLabel="always" class="tt9-form-field no-label no-margin width-100">
    <input
      #search
      matInput
      [placeholder]="inputPlaceholders.search"
      [formControl]="searchControl"
      (input)="emitSearchValue(search.value)"
    />
    <accenture-icon matPrefix>icon-search</accenture-icon>
    <accenture-icon *ngIf="search.value" matSuffix (click)="resetSearchValue()">icon-close</accenture-icon>
  </mat-form-field>
</div>
<!-- /search -->

<div class="tt9-caption-2 tt9-color-neutral-500">
  <span>ONLY SESSIONS THAT ARE NOT IN ANY COLLECTION ARE LISTED AND AVAILABLE FOR SELECTION HERE.</span>
</div>
<div *ngIf="userSessions.length" class="tt9-select-table-container width-100">
  <table class="tt9-select-table width-100">
    <thead>
      <tr class="row-sticky horizontal-shadow">
        <th class="cell-select">
          <mat-checkbox
            class="tt9-checkbox"
            color="primary"
            [checked]="isAllSelected"
            (change)="toggleAllSessionSelection($event)"
          ></mat-checkbox>
        </th>
        <th class="tt9-body-2 cell-box">Session name</th>
        <th class="tt9-body-2 cell-box">Description</th>
        <th class="tt9-body-2 cell-box">Owner</th>
        <th class="tt9-body-2 cell-date">Last updated</th>
      </tr>
    </thead>

    <tbody>
      <ng-container *ngFor="let userSession of userSessions; trackBy: trackById">
        <ng-container *ngTemplateOutlet="rowTemplate; context: { userSession: userSession }"></ng-container>
      </ng-container>
    </tbody>
  </table>
</div>
<!--/table-->

<!-- row userSession -->
<ng-template #rowTemplate let-userSession="userSession">
  <tr [class.hover]="selectedSessionsMap === userSession.sessionId" (click)="toggleSessionSelection(userSession)">
    <td class="cell-select">
      <mat-checkbox
        class="tt9-checkbox"
        color="primary"
        [checked]="selectedSessionsMapIds.includes(userSession?.sessionId)"
      ></mat-checkbox>
    </td>

    <td class="cell-box">
      <span
        accentureTooltip
        class="tt9-body-1 text-overflow-ellipsis-2 width-fit-content"
        matTooltipPosition="above"
        matTooltipClass="tt9-arrow-tooltip-above"
        [matTooltip]="userSession.name"
        >{{ userSession.name }}</span
      >
    </td>

    <td class="cell-box">
      <span
        accentureTooltip
        class="tt9-body-1 text-overflow-ellipsis-2 width-fit-content"
        matTooltipPosition="above"
        matTooltipClass="tt9-arrow-tooltip-above"
        [matTooltip]="userSession.description"
        >{{ userSession.description }}</span
      >
    </td>

    <td class="cell-box">
      <span
        accentureTooltip
        class="tt9-body-1 text-overflow-ellipsis-2 width-fit-content"
        matTooltipPosition="above"
        matTooltipClass="tt9-arrow-tooltip-above"
        [matTooltip]="userSession.creatorName"
        >{{ userSession.creatorName ?? "" }}</span
      >
    </td>

    <td class="tt9-body-1 cell-date">
      {{ userSession.updated.toMillis() | date : "mediumDate" }}
    </td>
  </tr>
</ng-template>
<!-- /row template -->

<!-- empty screen -->
<ng-container *ngIf="!userSessions.length">
  <!-- No responses match Search Criteria -->
  <ng-container *ngIf="searchControl.value">
    <accenture-empty-screen
      class="empty-screen"
      [title]="emptyScreenTitles.noSessionsFound"
      [imageUrl]="noItemsFoundImageUrl"
    />
  </ng-container>
  <!-- /No responses match Search Criteria -->

  <!-- empty screen template -->
  <ng-container *ngIf="!searchControl.value">
    <accenture-empty-screen
      class="empty-screen"
      [title]="emptyScreenTitles.noSessionAvailable"
      [imageUrl]="emptyScreenImageUrlSessions"
    />
  </ng-container>
  <!-- /empty screen template -->
</ng-container>
<!-- /empty screen -->
