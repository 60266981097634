import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Params, Router, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { isUndefined } from 'lodash';
import { Observable, of, zip } from 'rxjs';
import { map, skipWhile, switchMap, take } from 'rxjs/operators';

import {
    AppState,
    selectAuthenticatedUserId,
    selectSessionData,
    selectSessionTeamMemberData,
} from '@accenture/global-store';
import { Activity, LinkAccess, notFoundUrl, Session, SessionFocus, TeamMember } from '@accenture/shared/data';

import { ActivityService } from '../services/activity.service';
import { LinkAccessService } from '../services/link-access.service';
import { SessionFocusService } from '../services/session-focus.service';
import { getRouterParams, isActiveActivityLinkAccess } from '../utils';

@Injectable()
export class SessionNavigationNewGuard {
    constructor(
        private router: Router,
        private store: Store<AppState>,
        private sessionFocusService: SessionFocusService,
        private activityService: ActivityService,
        private linkAccessService: LinkAccessService,
    ) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        const { projectId, sessionId, activityId } = getRouterParams(route);
        if (projectId && sessionId && activityId) {
            return this.resolveRouteByFocusState(projectId, sessionId, activityId, state, route.queryParams);
        }

        if (!activityId) {
            this.router.navigateByUrl(notFoundUrl);
        }

        return of(false);
    }

    private resolveRouteByFocusState(
        projectId: string,
        sessionId: string,
        activityId: string,
        state: RouterStateSnapshot,
        queryParams: Params,
    ): Observable<boolean> {
        const activityIdFromLinkAccess = queryParams?.activityIdFromLinkAccess;

        return this.store.select(selectAuthenticatedUserId).pipe(
            skipWhile(userId => isUndefined(userId)),
            take(1),
            switchMap(userId =>
                zip([
                    this.store.select(selectSessionData),
                    this.activityService.getSessionActivityById(sessionId, activityId),
                    this.sessionFocusService.getSessionFocusNew(sessionId),
                    this.store.select(selectSessionTeamMemberData),
                    activityIdFromLinkAccess
                        ? this.linkAccessService.getLinkAccessByActivity(activityIdFromLinkAccess)
                        : of(null),
                ]),
            ),
            take(1),
            map(
                ([session, activity, focus, teamMember, activityLinkAccess]: [
                    Session | null,
                    Activity | null,
                    SessionFocus,
                    TeamMember,
                    LinkAccess | null,
                ]) => {
                    const fromActiveLink = isActiveActivityLinkAccess(activityLinkAccess);

                    if (!session || !activity) {
                        this.router.navigateByUrl(notFoundUrl);
                        return false;
                    }

                    if (!teamMember) {
                        this.router.navigate(['project', projectId]);
                        return false;
                    }

                    if (teamMember?.isSessionLeader) {
                        return true;
                    }

                    if (focus) {
                        const prevActivityId = state.url.split('/')[6];
                        return !(
                            !session.selfNavigate
                            && focus.activityId !== activityId
                            && focus.activityId === prevActivityId
                        );
                    } else if (!session.selfNavigate && !fromActiveLink) {
                        this.router.navigate(['project', projectId, 'session', sessionId]);
                        return false;
                    }

                    return true;
                },
            ),
        );
    }
}
