import { Injectable } from '@angular/core';

import { ImportData, ImportResultType, ImportType } from '@accenture/shared/data';
import { FirestoreService } from '@accenture/shared/data-access';

@Injectable({
    providedIn: 'root',
})
export class ImportService {
    constructor(private firestoreService: FirestoreService) {}

    //TO DO: Delete when project is deprecated
    async import(
        fileUrl: string,
        fileName: string,
        importType: ImportType,
        importResultType: ImportResultType,
        data: Partial<ImportData>,
    ): Promise<string[]> {
        const env = `https://${window.location.host}`;
        const result = await this.firestoreService.cloudFunctionCallable('importData', {
            env,
            fileUrl,
            fileName,
            importType,
            importResultType,
            ...data,
        });

        return result as string[];
    }

    async importTT9(
        fileUrl: string,
        fileName: string,
        importType: ImportType,
        importResultType: ImportResultType,
        data: Partial<ImportData>,
    ): Promise<string[]> {
        const env = `https://${window.location.host}`;
        const result = await this.firestoreService.cloudFunctionCallable('importDataTT9', {
            env,
            fileUrl,
            fileName,
            importType,
            importResultType,
            ...data,
        });

        return result as string[];
    }
}
