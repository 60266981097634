import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { buttonLabels, DialogSelectionOption } from '@accenture/shared/data';

@Component({
    selector: 'accenture-option-selection-dialog',
    templateUrl: './option-selection-dialog.component.html',
    styleUrls: ['./option-selection-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OptionSelectionDialogComponent {
    @Input() title!: string;
    @Input() options!: DialogSelectionOption[];
    @Input() showAiGenerate?: boolean;

    buttonAiGenerate: string = buttonLabels.generateNew;
}
