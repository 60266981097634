import { AfterViewInit, ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import { Observable, Subscription } from 'rxjs';

import { LoadedDescription, LoaderSize } from '@accenture/shared/ui';

import { SessionRecorderFacade, SessionRecorderModel } from './session-recorder-facade';

@Component({
    selector: 'accenture-session-recorder',
    templateUrl: './session-recorder.component.html',
    styleUrls: ['./session-recorder.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [SessionRecorderFacade],
})
export class SessionRecorderComponent implements AfterViewInit, OnDestroy {
    loaderText = LoadedDescription.PreparingDisclaimer;
    loaderSize = LoaderSize.Small;
    vm$: Observable<SessionRecorderModel> = this.facade.vm$;

    disclaimerDetect$!: Subscription;

    constructor(private facade: SessionRecorderFacade) {}

    ngOnDestroy(): void {
        this.disclaimerDetect$.unsubscribe();
    }

    ngAfterViewInit(): void {
        this.disclaimerDetect$ = this.vm$.subscribe((vm) => {
            const { isLoading, consents } = vm;
            if (isLoading) return;

            if (consents.length > 0) {
                this.facade.replyToDisclaimer();
            }
        });
    }

    open(event: Event): void {
        this.facade.togglePanel(true);
        event.stopPropagation();
    }

    close(): void {
        this.facade.togglePanel(false);
    }

    start(event: Event): void {
        this.facade.createDisclaimer();

        event.stopPropagation();
    }

    async stop(event: Event, save = false): Promise<void> {
        this.facade.stop(save);
        event.stopPropagation();
    }

    pause(event: Event): void {
        this.facade.pause();
        event.stopPropagation();
    }

    summarizeAll(): void {
        this.facade.openSummarizeModal();
    }

    async upload(): Promise<void> {
        await this.facade.upload();
    }

    timer(minutes: string, seconds: string): string {
        return `${minutes} : ${seconds}`;
    }
}
