import { ParentType } from './parent-type';

export class StoragePathHelper {
    static getParentPath(parentType: ParentType, parentId: string): string {
        return `${parentType}/${parentId}`;
    }
    static getUserUploadsPath(userId: string, fileName: string): string {
        return `attachments/${userId}/${fileName}`;
    }

    static getSessionThreadFilePath(filename: string): string {
        return `/session-threads-files/${filename}`;
    }

    static getSessionRecordingPath(sessionId: string, filename?: string): string {
        return `sessions/${sessionId}/${filename ? filename : ''}`;
    }
}
