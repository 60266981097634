import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { combineLatest, map, Observable, startWith, tap } from 'rxjs';
import { distinctUntilChanged, switchMap } from 'rxjs/operators';

import { CollectionOptionsService, CollectionsService, FiltersService } from '@accenture/erp-deployment/shared/domain';
import { AppState, selectAuthenticatedUserId } from '@accenture/global-store';
import {
    CollectionOptions,
    CollectionOptionsType,
    CollectionsSortObject,
    DefaultCollectionSortObject,
    OptionsFiltersFields,
    OptionsForSortingByNumberOfUses,
} from '@accenture/shared/data';

export interface CollectionOptionsFiltersChipsViewModel {
    optionsData: CollectionOptionsType[];
    optionType?: string;
    sortOption: CollectionsSortObject;
    selectedOptions: string[];
    isLoading: boolean;
}

export const initialViewModel = {
    optionsData: [],
    sortOption: {},
    selectedOptions: [],
    isLoading: false,
};

@Injectable()
export class CollectionOptionsFiltersChipsFacade {
    vm$ = this.buildViewModel();

    private userId: string;
    private optionType: string;

    constructor(
        private store: Store<AppState>,
        private filtersService: FiltersService,
        private collectionOptionsService: CollectionOptionsService,
        private collectionsService: CollectionsService,
    ) {}

    updateCollectionSortOptions(sortObject: DefaultCollectionSortObject): void {
        this.collectionsService.updateCollectionsFilters(this.userId, sortObject);
    }

    applyOptionsFilter(optionId: string, isSelected: boolean): void {
        this.collectionsService.updateOptionsFilters(this.userId, optionId, isSelected, this.optionType);
    }

    private buildViewModel(): Observable<CollectionOptionsFiltersChipsViewModel> {
        return combineLatest([
            this.store.select(selectAuthenticatedUserId),
            this.filtersService.optionType$.pipe(distinctUntilChanged()),
        ]).pipe(
            switchMap(([userId, optionType]) => {
                this.userId = userId;
                this.optionType = OptionsFiltersFields[optionType];

                const collection$ = this.getOptionsCollection(optionType as CollectionOptions);
                const filtersAndSelected$ = this.getCollectionsFilters();

                return combineLatest([collection$, filtersAndSelected$]).pipe(
                    map(([collection, { sortOption, selectedOptions }]) => {
                        return {
                            sortOption,
                            optionType,
                            selectedOptions,
                            optionsData: this.filtersService.sortOptionsData(
                                collection,
                                sortOption,
                                OptionsForSortingByNumberOfUses.Collections,
                            ),
                            isLoading: false,
                        };
                    }),
                );
            }),
            startWith(initialViewModel),
        );
    }

    private getCollectionsFilters(): Observable<{ sortOption: CollectionsSortObject; selectedOptions: string[] }> {
        return this.store.select(selectAuthenticatedUserId).pipe(
            tap(userId => (this.userId = userId)),
            switchMap(userId =>
                this.collectionsService.getCurrentUserCollectionsFilters(userId).pipe(
                    map(filtersAndSort => ({
                        sortOption: {
                            sortOrder: filtersAndSort.collectionsSortOrder,
                            sortOption: filtersAndSort.collectionsSortOption,
                        },
                        selectedOptions: filtersAndSort[this.optionType] || [],
                    })),
                ),
            ),
        );
    }

    private getOptionsCollection(optionType: CollectionOptions): Observable<CollectionOptionsType[]> {
        return this.collectionOptionsService.getOptions(optionType);
    }
}
