import { ChangeDetectionStrategy, Component } from '@angular/core';

import { NavigationTab, tooltipTexts } from '@accenture/shared/data';

import { DashboardFacade } from './dashboard-facade';

@Component({
    selector: 'accenture-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss'],
    providers: [DashboardFacade],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DashboardComponent {
    vm$ = this.facade.vm$;
    tooltipTexts = tooltipTexts;

    constructor(private facade: DashboardFacade) {}

    navigateToTemplatesPage(): void {
        this.facade.navigateToTemplatesPage();
    }
}
