export interface JiraIssue {
    id: string;
    key: string;
    name: string;
    type: string;
    selected: boolean;
}

export enum JiraIssueTypes {
    Bug = 'Bug',
    Task = 'Task',
    SubTask = 'Sub-Task',
    Story = 'Story',
}

export const JiraIssuesArray: JiraIssueTypes[] = [
    JiraIssueTypes.Bug,
    JiraIssueTypes.Task,
    JiraIssueTypes.SubTask,
    JiraIssueTypes.Story,
];

export interface AtlassianIssue {
    id: string;
    key: string;
    name: string;
    type: string;
}

export interface JiraResponse {
    result: JiraIssue[];
}

export interface JiraIssueParentData {
    parentType: string;
    parentId: string;
    activityId: string;
    sessionId: string;
}
