import { Question } from '../question';
import { FileType } from '../responses';
import { ActivityItemType } from './activity-item-type';

export class Slide extends Question {
    type = ActivityItemType.Slide;

    name: string;
    slideImage: FileType;
    selected?: boolean;
    notesVisible?: boolean;

    templateId?: string;
    projectId?: string;

    notes?: string[];

    constructor(data: any) {
        super(data);

        this.name = data.name ?? '';
        this.slideImage = {
            url: data.slideImage?.url ?? '',
            id: data.slideImage?.id ?? '',
        } as FileType;
        this.selected = data.selected ?? false;
        this.notesVisible = data.notesVisible ?? true;
    }

    createSerializableObject(): Record<string, unknown> {
        return {
            ...super.createSerializableObject(),
            name: this.name,
            slideImage: this.slideImage,
            selected: this.selected,
            notesVisible: this.notesVisible,
        };
    }
}
