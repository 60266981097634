import {
    BehaviorSubject,
    combineLatest,
    distinctUntilChanged,
    Observable,
} from 'rxjs';
import {
    map,
    startWith,
    switchMap,
    tap,
} from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { SessionRecordingsService } from '@accenture/activity/shared/domain';
import {
    AppState,
    selectAuthenticatedUserId,
    selectSessionId,
} from '@accenture/global-store';
import {
    SessionRecordingConsent,
    SessionRecordingDisclaimer,
} from '@accenture/shared/data';

export interface SessionRecorderReplyToDisclaimerViewModel {
    isLoading: boolean;
    disclaimers: SessionRecordingDisclaimer[];
}

const initialState: SessionRecorderReplyToDisclaimerViewModel = {
    isLoading: true,
    disclaimers: [],
};

@Injectable()
export class SessionRecorderReplyToDisclaimerFacade {
    private isLoading$ = new BehaviorSubject<boolean>(false);

    vm$ = this.buildViewModel();

    constructor(private sessionRecordingsService: SessionRecordingsService, private store: Store<AppState>) {}

    sessionId: string;
    disclaimerId: string;
    userId: string;

    private buildViewModel(): Observable<SessionRecorderReplyToDisclaimerViewModel> {
        const data$ = combineLatest([
            this.store.select(selectSessionId),
            this.store.select(selectAuthenticatedUserId),
        ]).pipe(
            tap(([sessionId, userId]) => {
                this.sessionId = sessionId;
                this.userId = userId;
            }),
            switchMap(() =>
                combineLatest([this.getConsents(), this.getDisclaimers()]).pipe(
                    tap(() => {
                        this.isLoading$.next(false);
                    })
                )
            )
        );

        return combineLatest([data$, this.isLoading$.asObservable().pipe(distinctUntilChanged())]).pipe(
            map(([[consents, disclaimers], isLoading]) => {
                const disclaimerIds = consents.map((consents) => consents.disclaimerId);

                return {
                    disclaimers: disclaimers.filter((disclaimer) => disclaimerIds.includes(disclaimer.id)),
                    isLoading,
                };
            }),
            startWith(initialState)
        );
    }

    private getConsents(): Observable<SessionRecordingConsent[]> {
        return this.sessionRecordingsService.getConsentsByUser(this.sessionId, this.userId);
    }

    private getDisclaimers(): Observable<SessionRecordingDisclaimer[]> {
        return this.sessionRecordingsService.getDisclaimers(this.sessionId, this.userId);
    }
}
