import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    SimpleChanges,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';

import { trackByValue } from '@accenture/shared/util';

import { partColors, unusedColor } from '../../constants';

type ColorForm = FormGroup<{
    color: FormControl<string>;
}>;

@Component({
    selector: 'accenture-color-input',
    templateUrl: './color-input.component.html',
    styleUrls: ['./color-input.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ColorInputComponent implements OnChanges {
    @Input() selectedColor = unusedColor;
    @Input() colors = partColors;

    @Output() colorUpdated = new EventEmitter<string>();

    unusedColor = unusedColor;
    trackByValue = trackByValue;
    colorForm: ColorForm = this.formBuilder.group({
        color: new FormControl('', { nonNullable: true }),
    });

    constructor(private formBuilder: FormBuilder) {}

    updateColor(color: MatSelectChange): void {
        this.colorUpdated.emit(color.value);
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['selectedColor']) {
            this.colorForm.patchValue({
                color: this.selectedColor,
            });
        }
    }
}
