<div class="tabs">
  <div
    *ngFor="let tab of tabs"
    class="tab"
    [ngClass]="{ active: tab.tab === (activeTab$ | async) }"
    (click)="setActiveTab(tab.tab)"
  >
    <p class="tt9-input-text-small">{{ tab.title }}</p>
  </div>
</div>
