import {
    filter,
    firstValueFrom,
    map,
    Observable,
} from 'rxjs';

import { Injectable } from '@angular/core';

import {
    fileDownload,
    InAppNotificationsService,
} from '@accenture/erp-deployment/shared/domain';
import {
    DBPathHelper,
    InAppSessionRecordingNotification,
    SessionRecording,
    SessionRecordingCaptions,
    SessionRecordingConsent,
    SessionRecordingConsentResponse,
    SessionRecordingDisclaimer,
    SessionRecordingType,
    StoragePathHelper,
} from '@accenture/shared/data';
import {
    FileUploadService,
    FirestoreService,
} from '@accenture/shared/data-access';
import { SnackbarService } from '@accenture/shared/ui';
import { sortByDateAsc } from '@accenture/shared/util';

@Injectable({
    providedIn: 'root',
})
export class SessionRecordingsService {
    constructor(
        private firestoreService: FirestoreService,
        private fileUploadService: FileUploadService,
        private snackbarService: SnackbarService,
        private inAppNotificationsService: InAppNotificationsService
    ) {}

    async saveCapture(
        sessionId: string,
        disclaimerId: string,
        userId: string,
        recording: BlobPart[],
        format: string,
        actions: SessionRecordingCaptions[]
    ): Promise<string> {
        const blob = new Blob(recording, { type: format });

        const fileName = `capture-${new Date().toISOString().replace(/:/g, '.')}.${format.split('/').pop()}`;

        const fileUrl = await this.fileUploadService.uploadFile(
            StoragePathHelper.getSessionRecordingPath(sessionId, fileName),
            new File([blob], fileName)
        );

        const recordingId = this.add(sessionId, {
            userId,
            fileUrl,
            disclaimerId,
            actions,
            type: SessionRecordingType.Capture,
        });

        return recordingId;
    }

    async uploadFile(sessionId: string, userId: string, upload, fileName: string, type: string): Promise<string> {
        const blob = new Blob([new Uint8Array(upload)], { type });

        const fileUrl = await this.fileUploadService.uploadFile(
            StoragePathHelper.getSessionRecordingPath(sessionId, fileName),
            new File([blob], fileName)
        );

        return this.add(sessionId, {
            userId,
            fileUrl,
            fileName,
            type: SessionRecordingType.Upload,
        });
    }

    async pipeFile(sessionId: string, userId: string, sourceFileUrl: string, fileName: string): Promise<string> {
        const result = await fetch(sourceFileUrl);
        const data = await result.arrayBuffer();

        const blob = new Blob([data]);

        const fileUrl = await this.fileUploadService.uploadFile(
            StoragePathHelper.getSessionRecordingPath(sessionId, fileName),
            new File([blob], fileName)
        );

        return this.add(sessionId, {
            userId,
            fileUrl,
            fileName,
            type: SessionRecordingType.Upload,
        });
    }

    async add(sessionId: string, data: Partial<SessionRecording>): Promise<string> {
        return this.firestoreService.addDocument(DBPathHelper.getSessionRecordingsPath(sessionId), {
            ...data,
            created: this.firestoreService.timestamp,
            updated: this.firestoreService.timestamp,
        });
    }

    async delete(sessionId: string, recordingId: string): Promise<void> {
        return this.firestoreService.delete(DBPathHelper.getSessionRecordingsPath(sessionId, recordingId));
    }

    async updateTranscription(sessionId: string, recordingId: string, transcription: string): Promise<void> {
        return this.firestoreService.update(DBPathHelper.getSessionRecordingsPath(sessionId, recordingId), {
            transcription,
            updated: this.firestoreService.timestamp,
        });
    }

    getRecordings(sessionId: string): Observable<SessionRecording[]> {
        return this.firestoreService
            .getCollection<SessionRecording>(DBPathHelper.getSessionRecordingsPath(sessionId))
            .pipe(map((recordings) => recordings.sort(sortByDateAsc())));
    }

    async downloadRecording(fileUrl: string): Promise<void> {
        try {
            fileDownload(fileUrl);
            this.snackbarService.showSuccessSnackBar(`Recording downloaded`, `Finished recording download`);
        } catch (error) {
            this.snackbarService.showErrorSnackBar(`Had trouble downloading: `, error);
        }
    }

    getDisclaimer(sessionId: string, disclaimerId: string): Promise<SessionRecordingConsent> {
        return firstValueFrom(
            this.firestoreService.getDocument(DBPathHelper.getSessionRecordingDisclaimerPath(sessionId, disclaimerId))
        );
    }

    getConsent(sessionId: string, consentId: string): Promise<SessionRecordingConsent> {
        return firstValueFrom(
            this.firestoreService.getDocument(DBPathHelper.getSessionRecordingConsentPath(sessionId, consentId))
        );
    }

    getConsentsByDisclaimer(sessionId: string, disclaimerId: string): Observable<SessionRecordingConsent[]> {
        return this.firestoreService
            .getDocumentsByQuery(DBPathHelper.getSessionRecordingConsentPath(sessionId), (ref) =>
                ref.where('disclaimerId', '==', disclaimerId)
            )
            .snapshotChanges()
            .pipe(
                filter((snapshot) => snapshot.every(({ payload }) => payload.doc.metadata.fromCache === false)),
                map((snapshots) => {
                    return snapshots.map(
                        ({ payload: { doc } }) =>
                            ({
                                id: doc.id,
                                ...(doc.data() as SessionRecordingConsent),
                            } as SessionRecordingConsent)
                    );
                })
            );
    }

    getConsentsByUser(sessionId: string, userId: string): Observable<SessionRecordingConsent[]> {
        return this.firestoreService
            .getDocumentsByQuery(DBPathHelper.getSessionRecordingConsentPath(sessionId), (ref) =>
                ref.where('userId', '==', userId).where('response', '==', SessionRecordingConsentResponse.None)
            )
            .snapshotChanges()
            .pipe(
                filter((snapshot) => snapshot.every(({ payload }) => payload.doc.metadata.fromCache === false)),
                map((snapshots) => {
                    return snapshots.map(
                        ({ payload: { doc } }) =>
                            ({
                                id: doc.id,
                                ...(doc.data() as SessionRecordingConsent),
                            } as SessionRecordingConsent)
                    );
                })
            );
    }

    getDisclaimers(sessionId: string, userId: string): Observable<SessionRecordingDisclaimer[]> {
        return this.firestoreService
            .getDocumentsByQuery(DBPathHelper.getSessionRecordingDisclaimerPath(sessionId), (ref) =>
                ref.where('userId', '!=', userId)
            )
            .snapshotChanges()
            .pipe(
                filter((snapshot) => snapshot.every(({ payload }) => payload.doc.metadata.fromCache === false)),
                map((snapshots) => {
                    return snapshots.map(
                        ({ payload: { doc } }) =>
                            ({
                                id: doc.id,
                                ...(doc.data() as SessionRecordingDisclaimer),
                            } as SessionRecordingDisclaimer)
                    );
                })
            );
    }

    async scrapDisclaimer(sessionId: string, disclaimerId: string): Promise<void> {
        const consents = await firstValueFrom(this.getConsentsByDisclaimer(sessionId, disclaimerId));
        await Promise.all(consents.map((consent) => this.removeConsent(sessionId, consent.id)));

        return this.firestoreService.delete(DBPathHelper.getSessionRecordingDisclaimerPath(sessionId, disclaimerId));
    }

    removeConsent(sessionId: string, consentId: string): Promise<void> {
        return this.firestoreService.delete(DBPathHelper.getSessionRecordingConsentPath(sessionId, consentId));
    }

    async replyToConsent(
        sessionId: string,
        consentId: string,
        response: SessionRecordingConsentResponse,
        disclaimerId?: string
    ) {
        if (disclaimerId) {
            const { userId } = await this.getDisclaimer(sessionId, disclaimerId);
            const { displayName } = await this.getConsent(sessionId, consentId);

            const notification = new InAppSessionRecordingNotification({
                sessionId,
                userId,
                message: `${displayName} has ${response} your disclaimer.`,
            });
            await this.inAppNotificationsService.writeNotification(notification);
        }

        await this.firestoreService.update(DBPathHelper.getSessionRecordingConsentPath(sessionId, consentId), {
            response,
            updated: this.firestoreService.timestamp,
        });

        this.snackbarService.showSuccessSnackBar(`Consent`, `Your response has been recorded.`, true);
    }
}
