import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import moment from 'moment';
import { Observable } from 'rxjs';

import { Session, TeamMember } from '@accenture/shared/data';
import { LoaderSize } from '@accenture/shared/ui';

import { BookMeetingButtonFacade, BookMeetingButtonModel } from './book-meeting-button-facade';

@Component({
    selector: 'accenture-book-meeting-button',
    templateUrl: './book-meeting-button.component.html',
    styleUrls: ['./book-meeting-button.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [BookMeetingButtonFacade],
})
export class BookMeetingButtonComponent implements OnInit {
    loaderSize = LoaderSize.Small;

    vm$: Observable<BookMeetingButtonModel> = this.facade.vm$;

    constructor(private facade: BookMeetingButtonFacade) {}

    ngOnInit(): void {
        const initializer = this.vm$.subscribe(vm => {
            const { isLoading, session } = vm;
            if (isLoading) return;
            if (!!session.id) {
                this.facade.refreshMeetingDetails(session);
                initializer.unsubscribe();
                return;
            }
        });
    }

    tooltip(session: Session): string {
        const { meeting } = session;
        if (!!meeting) {
            const { startDateTime } = meeting;
            return `Meeting is scheduled on ${moment(startDateTime.toDate()).format(
                'LLLL',
            )}. Click to join the meeting.`;
        }
        return 'Book a meeting for this session.';
    }

    bookMeeting(session: Session, members: TeamMember[]): void {
        const { meeting } = session;
        if (!!meeting) {
            const { joinWebUrl } = meeting;
            window.open(joinWebUrl, '_blank').focus();
            return;
        }

        this.facade.openBookMeetingDialog(session, members);
    }
}
