import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { PickerModule } from '@ctrl/ngx-emoji-mart';

import { FilestackService } from '@accenture/shared/data-access';
import { SharedMaterialModule } from '@accenture/shared/material';

import { EmojisComponent } from './components/emojis/emojis.component';
import { DisplayObjectValuesPipe } from './pipes/display-object-values.pipe';
// components
import {
    AccentureIconComponent,
    BaseSnackBarComponent,
    ColorInputComponent,
    ComingSoonComponent,
    EmptyScreenComponent,
    FloatingSidePanelComponent,
    ImageModalComponent,
    ListSnackBarComponent,
    LoaderWithDescriptionComponent,
    LoadingComponent,
    QuickFilterDropdownComponent,
    SessionCreationDropdownComponent,
    SidePanelComponent,
    StarVotingComponent,
    TextSnackBarComponent,
} from './components';
// dialogs
import {
    BaseDialogComponent,
    ConfirmationDialogComponent,
    InfoDialogComponent,
    OptionSelectionDialogComponent,
} from './dialogs';
// directives
import {
    ClickOutsideDirective,
    DisabledControlDirective,
    LoadedDirective,
    MaxTextWidthDirective,
    OverlayLoadingDirective,
    PositiveNumbersOnlyDirective,
    ResizedDirective,
    ResponseCardResponseEllipsisDirective,
    ScrollSpyDirective,
    ScrollStickyDirective,
    ScrollTableDirective,
    TooltipDirective,
    TruncatedValueDirective,
} from './directives';
// pipes
import {
    DisplayActivityIconPipe,
    DisplayActivityItemIconPipe,
    DisplayActivityItemTypePipe,
    DisplayActivityLabelPipe,
    DisplayActivityRequiredPipe,
    DisplayActivityResponseLinkifyPipe,
    DisplayActivityTypePipe,
    DisplayConnectionCriteriaLabelPipe,
    DisplayConnectionSessionLabelPipe,
    DisplayConnectionSourcesLabelPipe,
    DisplayParentTypePipe,
    DisplaySessionStatusPipe,
    DisplayTemplateStatusPipe,
    DisplayUserAccessRolePipe,
    DisplayUserGlobalRolePipe,
    DisplayUserRolesPipe,
    DisplayUserStatusPipe,
    DisplayUserTypePipe,
    HighlightPipe,
    isActivityConnectionSourceSessionVisiblePipe,
    PercentagePipe,
    PureSlicePipe,
    SafeHtmlPipe,
    ScapeHtmlPipe,
    UserInitialsPipe,
} from './pipes';
// services
import { DialogService, ExcelService, ImportService, UIBlockerService } from './services';

@NgModule({
    imports: [CommonModule, SharedMaterialModule, RouterModule, ReactiveFormsModule, PickerModule],
    declarations: [
        //Components
        LoadingComponent,
        SidePanelComponent,
        FloatingSidePanelComponent,
        LoaderWithDescriptionComponent,
        BaseSnackBarComponent,
        ColorInputComponent,
        AccentureIconComponent,
        StarVotingComponent,
        ImageModalComponent,
        EmptyScreenComponent,
        ComingSoonComponent,
        ListSnackBarComponent,
        TextSnackBarComponent,
        EmojisComponent,
        //Dialogs
        BaseDialogComponent,
        InfoDialogComponent,
        ConfirmationDialogComponent,
        OptionSelectionDialogComponent,
        //Directives
        ClickOutsideDirective,
        OverlayLoadingDirective,
        DisabledControlDirective,
        PositiveNumbersOnlyDirective,
        ScrollTableDirective,
        ScrollSpyDirective,
        ScrollStickyDirective,
        MaxTextWidthDirective,
        ResizedDirective,
        ResponseCardResponseEllipsisDirective,
        LoadedDirective,
        TooltipDirective,
        TruncatedValueDirective,
        //Pipes
        DisplayActivityTypePipe,
        DisplayActivityItemTypePipe,
        DisplayActivityItemIconPipe,
        DisplayActivityResponseLinkifyPipe,
        DisplayActivityLabelPipe,
        DisplayActivityRequiredPipe,
        PureSlicePipe,
        HighlightPipe,
        DisplayUserRolesPipe,
        DisplayUserAccessRolePipe,
        DisplaySessionStatusPipe,
        DisplayActivityIconPipe,
        DisplayParentTypePipe,
        DisplayTemplateStatusPipe,
        DisplayConnectionCriteriaLabelPipe,
        DisplayConnectionSessionLabelPipe,
        DisplayConnectionSourcesLabelPipe,
        isActivityConnectionSourceSessionVisiblePipe,
        UserInitialsPipe,
        PercentagePipe,
        SafeHtmlPipe,
        ScapeHtmlPipe,
        DisplayUserGlobalRolePipe,
        DisplayUserStatusPipe,
        DisplayUserTypePipe,
        DisplayObjectValuesPipe,
        SessionCreationDropdownComponent,
        QuickFilterDropdownComponent,
    ],
    exports: [
        //Components
        LoadingComponent,
        SidePanelComponent,
        FloatingSidePanelComponent,
        LoaderWithDescriptionComponent,
        BaseSnackBarComponent,
        ColorInputComponent,
        AccentureIconComponent,
        StarVotingComponent,
        ImageModalComponent,
        EmptyScreenComponent,
        ComingSoonComponent,
        ListSnackBarComponent,
        TextSnackBarComponent,
        EmojisComponent,
        //Dialogs
        BaseDialogComponent,
        InfoDialogComponent,
        ConfirmationDialogComponent,
        OptionSelectionDialogComponent,
        //Directives
        ClickOutsideDirective,
        OverlayLoadingDirective,
        DisabledControlDirective,
        PositiveNumbersOnlyDirective,
        ScrollTableDirective,
        ScrollSpyDirective,
        ScrollStickyDirective,
        MaxTextWidthDirective,
        ResizedDirective,
        ResponseCardResponseEllipsisDirective,
        LoadedDirective,
        TooltipDirective,
        TruncatedValueDirective,
        //Pipes
        DisplayActivityTypePipe,
        DisplayActivityItemTypePipe,
        DisplayActivityItemIconPipe,
        DisplayActivityResponseLinkifyPipe,
        DisplayActivityLabelPipe,
        DisplayActivityRequiredPipe,
        PureSlicePipe,
        HighlightPipe,
        DisplayUserRolesPipe,
        DisplayUserAccessRolePipe,
        DisplaySessionStatusPipe,
        DisplayActivityIconPipe,
        DisplayParentTypePipe,
        DisplayTemplateStatusPipe,
        DisplayConnectionCriteriaLabelPipe,
        DisplayConnectionSessionLabelPipe,
        DisplayConnectionSourcesLabelPipe,
        isActivityConnectionSourceSessionVisiblePipe,
        UserInitialsPipe,
        PercentagePipe,
        SafeHtmlPipe,
        ScapeHtmlPipe,
        DisplayUserGlobalRolePipe,
        DisplayUserStatusPipe,
        DisplayUserTypePipe,
        DisplayObjectValuesPipe,
        SessionCreationDropdownComponent,
        QuickFilterDropdownComponent,
    ],
    providers: [DialogService, FilestackService, ImportService, ExcelService],
})
export class SharedUiModule {
    static forRoot(): ModuleWithProviders<SharedUiModule> {
        return {
            ngModule: SharedUiModule,
            providers: [UIBlockerService],
        };
    }
}
