import { Dictionary } from './dictionary';
import { NodeTimestamp } from './node-timestamp';
import { Tag } from './tag';

// TODO: move to separate file
export interface Client extends NodeTimestamp {
    id?: string;
    name: string;
    projects?: string[];
    collections?: string[];
    default?: boolean;
}

// TODO REMOVE AFTER COLLECTIONS IMPLEMENTED
export enum ProjectOptions {
    Client = 'clients',
    Tag = 'tags',
}

export const projectOptionsTitle = {
    [ProjectOptions.Client]: 'Client',
    [ProjectOptions.Tag]: 'Tags',
};

export const newProjectOptionText = {
    [ProjectOptions.Client]: '(New client)',
    [ProjectOptions.Tag]: '(New tag)',
};

export const projectOptionsDialogTitle = {
    [ProjectOptions.Client]: 'All clients',
    [ProjectOptions.Tag]: 'All tags',
};

export const projectOptionsArray = [ProjectOptions.Client, ProjectOptions.Tag];

export interface SelectedProjectOptions {
    clients: Dictionary<string>; // [id]: name
    tags: Dictionary<string>;
}

export type ProjectOptionsType = Tag | Client;

export interface ProjectOptionsSelection {
    optionType: ProjectOptions;
    selectedOption: Partial<ProjectOptionsType>;
    removed?: boolean;
}
