import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { groupBy, mapValues } from 'lodash';
import { BehaviorSubject, combineLatest, firstValueFrom, Observable, of } from 'rxjs';
import { distinctUntilChanged, filter, map, startWith, switchMap } from 'rxjs/operators';

import { SessionThreadsService } from '@accenture/activity/shared/domain';
import {
    deleteSessionConfirmation,
    deleteSessionInfoSnackbar,
    FiltersService,
    saveSessionAsTemplateConfirmation,
    saveSessionAsTemplateInfoSnackBarText,
    saveSessionAsTemplateSuccessSnackBarText,
    saveSessionExportReportErrorSnackBarTitle,
    saveSessionExportReportInfoSnackBar,
    saveSessionExportReportInfoSnackBarTitle,
    saveSessionExportReportSuccessSnackBar,
    saveSessionExportReportSuccessSnackBarTitle,
    saveSessionWithTableAsTemplateConfirmation,
    SessionService,
    UserAccessService,
} from '@accenture/erp-deployment/shared/domain';
import {
    AppState,
    getParentTeamMemberData,
    getSessionActivitiesData,
    getSessionData,
    selectAuthenticatedUser,
} from '@accenture/global-store';
import {
    ActivityType,
    DefaultFilterObject,
    deletingSessionErrorSnackbarTitle,
    deletingSessionInProgressSnackbarTitle,
    Dictionary,
    errorMessageSnackbarText,
    initialDefaultFilterObject,
    ParentType,
    Session,
    sessionDeletedSnackbarTitle,
    sessionHasBeenDeletedSnackbarText,
    SessionRole,
    templateCreatedSnackbarTitle,
    templateCreationErrorTitleSnackbarTitle,
    templateCreationInProgressSnackbarTitle,
    User,
    UserAccess,
    UserAccessSession,
} from '@accenture/shared/data';
import { ConfirmationDialogComponent, DialogService, SnackbarService, SnackBarTypes } from '@accenture/shared/ui';
import { sortBySequenceAsc } from '@accenture/shared/util';

import { MoveToCollectionDialogComponent } from '../move-to-collection-dialog/move-to-collection-dialog.component';
import { SelectSessionSourceTypeDialogComponent } from '../select-session-source-type-dialog/select-session-source-type-dialog.component';

export enum SessionsViewTab {
    ListView,
    CardView,
}

export interface SessionsListViewModel {
    userSessionsAccess: Dictionary<UserAccess>;
    user: User;
    activeTab: SessionsViewTab;
    sessions: Session[];
    searchValue: string;
    hasSessionLeaderRole: boolean; // if user has at least one SessionLeader role for table view
    sessionsCount: number;
    isSearchShown: boolean;
    isFiltersPanelOpened: boolean;
    isFiltersApplied: boolean;
    filtersAndSort: DefaultFilterObject;
    isLoading: boolean;
}

const defaultViewModel = {
    userSessionsAccess: {},
    user: {} as User,
    activeTab: SessionsViewTab.CardView,
    sessions: [],
    searchValue: '',
    sessionsCount: 0,
    hasSessionLeaderRole: false,
    isSearchShown: false,
    isFiltersApplied: false,
    isFiltersPanelOpened: false,
    filtersAndSort: initialDefaultFilterObject,
    isLoading: true,
};

@Injectable()
export class SessionsListFacade {
    private activeTab$ = new BehaviorSubject<SessionsViewTab>(SessionsViewTab.CardView);
    private searchValue$ = new BehaviorSubject<string>('');
    private isSearchShown$ = new BehaviorSubject<boolean>(false);
    private isLoading$ = new BehaviorSubject<boolean>(false);
    private isFiltersPanelOpened$ = new BehaviorSubject<boolean>(false);

    vm$ = this.buildViewModel();

    private sessionsCount!: number;
    private userId!: string;
    private parentType!: ParentType;

    constructor(
        private store: Store<AppState>,
        private sessionService: SessionService,
        private router: Router,
        private dialogService: DialogService,
        private userAccessService: UserAccessService,
        private snackbarService: SnackbarService,
        private filtersService: FiltersService,
        private sessionThreadsService: SessionThreadsService,
    ) {}

    setActiveTab(tab: SessionsViewTab): void {
        this.activeTab$.next(tab);
    }

    toggleShowSearch(): void {
        const isSearchShown = !this.isSearchShown$.getValue();
        this.isSearchShown$.next(isSearchShown);

        if (!isSearchShown) {
            this.searchValue$.next('');
        }
    }

    toggleFiltersAndSortPanel(opened: boolean): void {
        this.isFiltersPanelOpened$.next(opened);
    }

    //TODO update when will work on save as template task https://thinktankco.atlassian.net/browse/TT9-6388
    async openSaveAsTemplateDialog(sessionId: string): Promise<void> {
        const canSaveResponses = await this.canSaveResponses(sessionId);

        this.dialogService.open(ConfirmationDialogComponent, {
            title: 'Save as Template',
            cancelBtnText: 'Cancel',
            width: '600px',
            confirmBtnText: 'Save',
            text: canSaveResponses ? saveSessionWithTableAsTemplateConfirmation : saveSessionAsTemplateConfirmation,
            isToggleVisible: canSaveResponses,
            confirm: (saveResponses: boolean) => this.saveAsTemplate(sessionId, saveResponses),
        });
    }

    async deleteSession(sessionId: string): Promise<void> {
        this.sessionService.updateDeletingSessionsIds(sessionId);
        this.snackbarService.showSnackBar(
            deletingSessionInProgressSnackbarTitle,
            deleteSessionInfoSnackbar,
            SnackBarTypes.Info,
            false,
        );
        try {
            await this.sessionService.deleteSessionNew(ParentType.Sessions, sessionId);

            if (this.sessionsCount === 0) {
                this.isFiltersPanelOpened$.next(false);

                await this.sessionService.updateSessionsFilters(this.userId, initialDefaultFilterObject);
            }

            this.snackbarService.showSnackBar(
                sessionDeletedSnackbarTitle,
                sessionHasBeenDeletedSnackbarText,
                SnackBarTypes.Success,
                true,
            );
        } catch (e) {
            console.error(e);
            this.snackbarService.showSnackBar(
                deletingSessionErrorSnackbarTitle,
                errorMessageSnackbarText,
                SnackBarTypes.Error,
                true,
            );
        }
        this.sessionService.removeSessionIdFromDeletingSessionsIds(sessionId);
    }

    //TODO update when will work on save as template task https://thinktankco.atlassian.net/browse/TT9-6430
    openDeleteConfirmationDialog(sessionId: string, sessionName: string): void {
        this.dialogService.open(ConfirmationDialogComponent, {
            width: '444px',
            panelClass: 'tt9-modal',
            title: `Delete ${sessionName} session`,
            confirmBtnText: 'Delete',
            cancelBtnText: 'Cancel',
            text: deleteSessionConfirmation,
            isWarning: true,
            confirm: () => this.deleteSession(sessionId),
        });
    }

    filterSessions(searchValue: string): void {
        this.searchValue$.next(searchValue);
    }

    openCreateSessionDialog(): void {
        this.dialogService.open(SelectSessionSourceTypeDialogComponent, {
            width: '768px',
            cancelButtonText: 'Back',
            panelClass: 'tt9-modal',
        });
    }

    redirectToSession(sessionId: string): void {
        const parentType = ParentType.Sessions;

        this.store.dispatch(
            getSessionData({
                parentType,
                sessionId,
            }),
        );

        this.store.dispatch(
            getParentTeamMemberData({
                parentType,
                parentId: sessionId,
            }),
        );

        this.store.dispatch(
            getSessionActivitiesData({
                parentType,
                sessionId,
            }),
        );

        this.router.navigate(['session', sessionId]);
    }

    //TODO update when will work on session report task https://thinktankco.atlassian.net/browse/TT9-6391
    async downloadAnExportReport(sessionId: string): Promise<void> {
        const env = window.location.origin;
        const timezone = new Date().getTimezoneOffset();

        try {
            this.snackbarService.showInfoSnackBar(
                saveSessionExportReportInfoSnackBarTitle,
                saveSessionExportReportInfoSnackBar,
            );
            const report = await this.sessionService.downloadAnExportReportNew(sessionId, env, timezone);

            const a = document.createElement('a');
            a.setAttribute('href', report.url);
            a.setAttribute('download', report.name || '');
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);

            this.snackbarService.showSuccessSnackBar(
                saveSessionExportReportSuccessSnackBarTitle,
                saveSessionExportReportSuccessSnackBar,
            );
        } catch {
            this.showSaveAsTemplateErrorSnackBar(saveSessionExportReportErrorSnackBarTitle);
        }
    }

    async updateSessionOptions(sessionId: string, data: Partial<Session>): Promise<void> {
        await this.sessionService.updateSessionDocumentNew(ParentType.Sessions, sessionId, data);
    }

    //TODO update when will work on save as template task https://thinktankco.atlassian.net/browse/TT9-6392
    async writeSessionThreadNotification(sessionId: string, checked: boolean): Promise<void> {
        // if (this.parentType === ParentType.Projects) {
        //     const sessionThreadNotificationType = checked
        //         ? SessionThreadMessageType.AnonymousON
        //         : SessionThreadMessageType.AnonymousOFF;
        //     await this.sessionThreadsService.addNewSessionThreadNotification(
        //         this.parentId,
        //         sessionId,
        //         sessionThreadNotificationType,
        //     );
        // }
    }

    moveToCollectionDialogOpen(session: UserAccess): void {
        this.dialogService.open(MoveToCollectionDialogComponent, {
            width: '768px',
            panelClass: 'tt9-modal',
            session,
        });
    }

    private buildViewModel(): Observable<SessionsListViewModel> {
        return this.store
            .select(selectAuthenticatedUser)
            .pipe(filter((user) => !!user))
            .pipe(
                switchMap((user: User) => {
                    this.userId = user.id;

                    const $userSessionsAccess = this.userAccessService
                        .getSessionsAssignmentsByUserId(user.id)
                        .pipe(
                            map((userSessionsAccess) =>
                                mapValues(
                                    groupBy(userSessionsAccess, 'sessionId'),
                                    (userSessionsAccess) => userSessionsAccess[0],
                                ),
                            ),
                        );

                    const sessions$: Observable<Session[]> = combineLatest([
                        $userSessionsAccess,
                        this.sessionService.deletingSessionIds$,
                    ]).pipe(
                        switchMap(([userAssignments, deletingSessionIds]) => {
                            const sessionsIds = Object.keys(userAssignments || {});

                            return this.sessionService.getSessionsByIdsNew(ParentType.Sessions, sessionsIds).pipe(
                                map((sessions) =>
                                    sessions.filter((session) => !deletingSessionIds.includes(session.id)),
                                ),
                                map((sessions) => sortBySequenceAsc(sessions)),
                            );
                        }),
                    );

                    return combineLatest([
                        $userSessionsAccess,
                        sessions$,
                        this.sessionService.getUserSessionsFilters(user.id),
                        this.activeTab$.asObservable().pipe(distinctUntilChanged()),
                        this.searchValue$.asObservable().pipe(
                            map((value: string) => value.trim().toLowerCase()),
                            distinctUntilChanged(),
                        ),
                        this.isSearchShown$.asObservable().pipe(distinctUntilChanged()),
                        this.isFiltersPanelOpened$.asObservable().pipe(distinctUntilChanged()),
                        this.isLoading$,
                    ]).pipe(
                        map(
                            ([
                                userSessionsAccess,
                                sessions,
                                filtersAndSort,
                                activeTab,
                                searchValue,
                                isSearchShown,
                                isFiltersPanelOpened,
                                isLoading,
                            ]) => {
                                const sortedSessions = this.getVisibleSessions(
                                    sessions,
                                    filtersAndSort,
                                    (userSessionsAccess || {}) as Dictionary<UserAccessSession>,
                                    searchValue.replace(/^\s+|\s+$|\s+(?=\s)/g, ''),
                                );
                                const hasSessionLeaderRole = !!Object.values(userSessionsAccess || {}).find(
                                    (sessionAccess) =>
                                        [SessionRole.Leader].includes(sessionAccess?.role as SessionRole),
                                );
                                this.sessionsCount = sessions?.length || 0;

                                return {
                                    userSessionsAccess,
                                    searchValue,
                                    isSearchShown,
                                    isFiltersPanelOpened,
                                    isLoading,
                                    user,
                                    activeTab,
                                    hasSessionLeaderRole,
                                    filtersAndSort,
                                    isFiltersApplied: this.filtersService.isFiltersApplied(filtersAndSort),
                                    sessionsCount: sessions?.length || 0,
                                    sessions: sortedSessions,
                                };
                            },
                        ),
                    );
                }),
                startWith(defaultViewModel),
            );
    }

    private getVisibleSessions(
        sessions: Session[],
        currentUserFilters: DefaultFilterObject,
        userSessionsAccess: Dictionary<UserAccessSession>,
        searchValue: string,
    ): Session[] {
        return sessions.length
            ? this.filtersService.filterAndSortSessions(sessions, currentUserFilters, userSessionsAccess, searchValue)
            : [];
    }

    private showSaveAsTemplateInfoSnackBar(): void {
        this.snackbarService.showInfoSnackBar(
            templateCreationInProgressSnackbarTitle,
            saveSessionAsTemplateInfoSnackBarText,
        );
    }

    private showSaveAsTemplateSuccessSnackBar(): void {
        this.snackbarService.showSuccessSnackBar(
            templateCreatedSnackbarTitle,
            saveSessionAsTemplateSuccessSnackBarText,
        );
    }

    private showSaveAsTemplateErrorSnackBar(title: string): void {
        this.snackbarService.showErrorSnackBar(title, errorMessageSnackbarText);
    }

    private async canSaveResponses(sessionId: string): Promise<boolean> {
        const activities = await firstValueFrom(this.sessionService.getSessionActivitiesNew(sessionId));

        return !!activities?.find((activity) => activity?.type === ActivityType.Table);
    }

    private async saveAsTemplate(sessionId: string, saveResponses: boolean): Promise<void> {
        try {
            this.showSaveAsTemplateInfoSnackBar();
            await this.sessionService.saveAsTemplateNew(
                sessionId,
                ParentType.Templates,
                this.parentType,
                saveResponses,
            );
            this.showSaveAsTemplateSuccessSnackBar();
        } catch (e) {
            console.error('Save as template has failed');
            this.showSaveAsTemplateErrorSnackBar(templateCreationErrorTitleSnackbarTitle);
        }
    }
}
