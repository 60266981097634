import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Observable } from 'rxjs';

import { LoaderSize } from '@accenture/shared/ui';

import { AiSummarizeButtonFacade, AiSummarizeButtonModel } from './ai-summarize-button-facade';

@Component({
    selector: 'accenture-ai-summarize-button',
    templateUrl: './ai-summarize-button.component.html',
    styleUrls: ['./ai-summarize-button.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [AiSummarizeButtonFacade],
})
export class AiSummarizeButtonComponent {
    loaderSize = LoaderSize.Small;

    vm$: Observable<AiSummarizeButtonModel> = this.facade.vm$;

    constructor(private facade: AiSummarizeButtonFacade) {}

    tooltip(): string {
        const type = this.facade.getType();
        return `Summarize ${type.toLowerCase()}`;
    }

    async summarize(): Promise<void> {
        await this.facade.summarize();
    }
}
