import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Observable } from 'rxjs';

import {
    DefaultFilterObject,
    optionsPanelTitle,
    ParentType,
    ProjectOptions,
    publicTemplateTabValue,
    SortOptions,
    Tag,
    TemplateTab,
    TemplateType,
    templateTypeToDisplay,
} from '@accenture/shared/data';
import { trackById, trackByKey } from '@accenture/shared/util';

import { templateTypesListForFilter } from './constants';
import {
    TemplateStoreQuickFiltersFacade,
    TemplateStoreQuickFiltersViewModel,
} from './template-store-quick-filters-facade';

@Component({
    selector: 'accenture-template-store-quick-filters',
    templateUrl: './template-store-quick-filters.component.html',
    styleUrls: ['./template-store-quick-filters.component.scss'],
    providers: [TemplateStoreQuickFiltersFacade],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TemplateStoreQuickFiltersComponent {
    vm$: Observable<TemplateStoreQuickFiltersViewModel> = this.facade.vm$;

    trackById = trackById;
    trackByKey = trackByKey;

    parentTypeList = ParentType;
    templateTypesList = templateTypesListForFilter;
    displayTemplateType = templateTypeToDisplay;
    allTagsPanelTitle = optionsPanelTitle[ProjectOptions.Tag];
    isFilterAndSortPanelOpened = false;

    constructor(private facade: TemplateStoreQuickFiltersFacade) {}

    isTagsVisible(parentTypeTab: ParentType | string): boolean {
        return parentTypeTab !== publicTemplateTabValue[TemplateTab.All];
    }

    isOptionSelected(selectedOptions: string[], id: string): boolean {
        return selectedOptions.includes(id);
    }

    resetFiltersAndSortForm(): void {
        this.facade.resetFiltersAndSort();
    }

    isSortOptionSelected(filtersAndSort: DefaultFilterObject, sortKey: SortOptions): boolean {
        return filtersAndSort.sortOption === sortKey;
    }

    isTemplateTypeSelected(filtersAndSort: DefaultFilterObject, templateType: TemplateType): boolean {
        return filtersAndSort.templateTypes?.includes(templateType);
    }

    onSortOptionSelected(filtersAndSort: DefaultFilterObject, sortKey: SortOptions): void {
        this.facade.applyFiltersAndSort({ sortOption: sortKey });
    }

    onTemplateTypeSelected(filtersAndSort: DefaultFilterObject, templateType: TemplateType): void {
        const templateTypes = this.isTemplateTypeSelected(filtersAndSort, templateType)
            ? filtersAndSort.templateTypes?.filter(item => item !== templateType)
            : [...(filtersAndSort.templateTypes || []), templateType];

        this.facade.applyFiltersAndSort({ templateTypes });
    }

    onSelectOption(selectedOptions: string[], option: Tag): void {
        const isSelected = this.isOptionSelected(selectedOptions, option.id);
        this.facade.applyOptionsFilter(selectedOptions, option.id, isSelected);
    }

    openAllTagsPanel(event: Event): void {
        event.stopPropagation();
        this.facade.openAllTagsPanel();
    }

    closeAllTagsPanel(): void {
        this.facade.closeAllTagsPanel();
    }

    navigateToFilterPanel(): void {
        this.closeAllTagsPanel();
        this.isFilterAndSortPanelOpened = true;
    }

    closeFilterAndSortPanel(): void {
        this.isFilterAndSortPanelOpened = false;
    }
}
