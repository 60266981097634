import { Question } from '../question';
import { ActivityItemType } from './activity-item-type';

export class NumberOptions {
    enableRange?: boolean;
    min?: number;
    max?: number;

    constructor(data: any) {
        this.enableRange = data.enableRange ?? false;
        this.min = data.min;
        this.max = data.max;
    }

    createSerializableObject(): Record<string, unknown> {
        return {
            enableRange: this.enableRange,
            min: this.min,
            max: this.max,
        };
    }
}

export const numberOptionsDefaultValues = {
    enableRange: false,
    min: 0,
    max: 100,
};

export class Number extends Question {
    type = ActivityItemType.Number;

    options: NumberOptions;

    constructor(data: any) {
        super(data);

        const options = data.options ?? {};
        this.options = new NumberOptions(options);
    }

    createSerializableObject(): Record<string, unknown> {
        return {
            ...super.createSerializableObject(),
            options: this.options.createSerializableObject(),
        };
    }
}
