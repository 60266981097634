import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Observable } from 'rxjs';

import {
    collectionSortOptionsValues,
    DefaultCollectionSortObject,
    Dictionary,
    SessionOptionsType,
    sortOrdersValues
} from '@accenture/shared/data';

import {
    DefaultSessionsOptionsFiltersChipsFacade,
    DefaultSessionsOptionsFiltersChipsViewModel,
} from './default-session-options-filters-chips-facade';

@Component({
    selector: 'accenture-default-sessions-options-filters-chips',
    templateUrl: './default-session-options-filters-chips.component.html',
    styleUrls: ['./default-session-options-filters-chips.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [DefaultSessionsOptionsFiltersChipsFacade],
})
export class DefaultSessionsOptionsFiltersChipsComponent {
    vm$: Observable<DefaultSessionsOptionsFiltersChipsViewModel> = this.facade.vm$;
    sortOrderOptions = sortOrdersValues;
    sortByOptions = collectionSortOptionsValues;

    constructor(private facade: DefaultSessionsOptionsFiltersChipsFacade) {}

    updateCurrentSessionsSortOption(sortObject: DefaultCollectionSortObject): void {
        this.facade.updateCollectionSortOptions(sortObject);
    }

    onSelectOption(selectedOptions: Dictionary<boolean>, option: SessionOptionsType): void {
        const isSelected = !!selectedOptions[option.id];
        this.facade.updateOptionsFilter(option.id, isSelected);
    }
}
