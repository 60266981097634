import { Injectable } from '@angular/core';

import { ActivityItemType, ActivityType, ParentType, VoteActivityItemType } from '@accenture/shared/data';
import { FirestoreService } from '@accenture/shared/data-access';

@Injectable({
    providedIn: 'root',
})
export class ResponseService {
    constructor(private firestoreService: FirestoreService) {}

    async removeResponsesData(
        parentId: string,
        activityItemId: string,
        activityType: ActivityType,
        parentType?: ParentType,
        activityItemType?: VoteActivityItemType | ActivityItemType,
    ): Promise<void> {
        await this.firestoreService.cloudFunctionCallable('deleteResponsesData', {
            activityType,
            parentId,
            activityItemId,
            parentType,
            activityItemType,
        });
    }

    async removeResponsesDataByAttributeClassesIds(
        parentId: string,
        activityType: ActivityType,
        attributeClassesIds: string[],
        activityId?: string,
    ): Promise<void> {
        await this.firestoreService.cloudFunctionCallable('deleteResponsesData', {
            activityType,
            parentId,
            attributeClassesIds,
            activityId,
        });
    }
}
