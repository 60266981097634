import { ParentType } from '@accenture/shared/data';

export const title = 'Publish template as “Community” template';
export const titleName = {
    [ParentType.ProjectTemplates]: 'Project template name',
    [ParentType.Templates]: 'Session template name',
    [ParentType.ActivityTemplates]: 'Activity template name',
};

export const templatePublishInProgressTitle = 'Template publish in progress';
export const templatePublishInProgressText = 'Template is being published to Template store';
export const templatePublishedTitle = 'Template published';
export const templatePublishedText = 'has successfully been published as community template';
export const templatePublishErrorTitle = 'Template publish error';
export const templatePublishErrorText = 'Something went wrong. Please try again later';
