import { CdkOverlayOrigin } from '@angular/cdk/overlay';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { tooltipTexts } from '@accenture/shared/data';

import { IconSize } from '../../models';

@Component({
    selector: 'accenture-floating-side-panel',
    templateUrl: './floating-side-panel.component.html',
    styleUrls: ['./floating-side-panel.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FloatingSidePanelComponent {
    @Input() outsideClickDisabled!: boolean;
    @Input() panelOpened!: boolean;
    @Input() panelTrigger!: CdkOverlayOrigin;
    @Input() panelTitle?: string;
    @Input() hideCloseBtn?: boolean;
    @Input() showBackArrow?: boolean;
    @Input() overlayDisableClose = false;
    @Input() useDefaultTemplate = true;
    @Input() secondaryHeader?: boolean;
    @Input() customWidth?: number;
    @Input() customHeight?: number;
    @Input() isShowAiConcierge?: boolean;
    @Output() closePanel = new EventEmitter<void>();
    @Output() navigateBack = new EventEmitter<void>();

    tooltipTexts = tooltipTexts;
    iconSize = IconSize;
}
