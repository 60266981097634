<ng-container *ngIf="vm$ | async as vm">
  <ng-container *accentureLoaded="!vm.isLoading">
    <div class="dialogue-container">
      <div class="title-container d-flex-between">
        <h6 class="title-container-text text-overflow-ellipsis-1 d-flex-center">{{ title }}</h6>

        <button class="tt9-btn-icon" (click)="closeDialog()">
          <accenture-icon>icon-close</accenture-icon>
        </button>
      </div>
      <div class="description-container">
        <p class="tt9-color-neutral-800">Are you sure you want to remove this session from this collection?</p>

        <div class="description-container-items">
          <p class="tt9-color-neutral-500 d-flex">
            Session:
            <span
              class="text-session-name"
              matTooltipPosition="above"
              matTooltipClass="tt9-arrow-tooltip-below"
              [matTooltip]="vm.userSession.name"
              [matTooltipDisabled]="vm.userSession.name.length < maxToolTipCharacter"
            >
              {{ vm.userSession.name }}
            </span>
          </p>
          <p class="tt9-color-neutral-500 d-flex pt-8">
            Collection:
            <span class="d-flex-center">
              <accenture-icon
                class="d-flex-center collections-icon"
                [customColor]="vm.userSession.color || defaultCollectionColor"
                [size]="iconSize.Small"
              >
                icon-folder-fill
              </accenture-icon>
              <span
                class="text-collection-name"
                matTooltipPosition="above"
                matTooltipClass="tt9-arrow-tooltip-below"
                [matTooltip]="vm.collectionName"
                [matTooltipDisabled]="vm.collectionName.length < maxToolTipCharacter"
              >
                {{ vm.collectionName }}
              </span>
            </span>
          </p>
        </div>

        <p class="tt9-color-neutral-800">You will be able to access the Session from “Sessions” tab.</p>
      </div>

      <div class="footer d-flex-end">
        <div class="d-flex">
          <button class="tt9-btn-default btn-icon mr-8" (click)="closeDialog()">
            <span>Cancel</span>
          </button>
          <button class="tt9-btn" (click)="removeFromCollection()">Remove</button>
        </div>
      </div>
    </div>
  </ng-container>
</ng-container>
