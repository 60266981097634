import { emptyScreenTitles } from './empty-screen';
import { ParentType } from './parent-type';

export enum NavigationTab {
    Home = 'Home',
    Dashboard = 'Dashboard',
    Templates = 'Templates',
    Collections = 'Collections',
    Sessions = 'Sessions',

    //TODO DELETE WHEN COLLECTION FEATURE WILL BE FINISHED
    Projects = 'Projects',
}

export enum AuthenticationTab {
    Login = 'Login',
    SingUp = 'SingUp',
}

export enum TemplateTab {
    Projects = 'Projects',
    Sessions = 'Sessions',
    Activities = 'Activities',
    All = 'All',
    FeaturedTemplates = 'All Featured Templates',
}

export enum TemplatesTab {
    MyTemplates = 'My Templates',
    Favorites = 'Favorites',
    TemplateStore = 'Template Store',
}

export const templateTabValue = {
    [TemplateTab.Projects]: ParentType.ProjectTemplates,
    [TemplateTab.Sessions]: ParentType.Templates,
    [TemplateTab.Activities]: ParentType.ActivityTemplates,
    [TemplateTab.All]: 'all',
};

export const optionsFieldValue = {
    [ParentType.ProjectTemplates]: ParentType.Projects,
    [ParentType.Templates]: ParentType.Sessions,
    [ParentType.ActivityTemplates]: ParentType.Activities,
};

export const publicTemplateTabValue = {
    [TemplateTab.Projects]: ParentType.PublicProjectTemplates,
    [TemplateTab.Sessions]: ParentType.PublicSessionTemplates,
    [TemplateTab.Activities]: ParentType.PublicActivityTemplates,
    [TemplateTab.All]: 'all',
};

export const noTemplatesYetMap = {
    [TemplateTab.All]: emptyScreenTitles.noTemplatesYet,
    [TemplateTab.Projects]: emptyScreenTitles.noProjectsTemplatesYet,
    [TemplateTab.Sessions]: emptyScreenTitles.noSessionsTemplatesYet,
    [TemplateTab.Activities]: emptyScreenTitles.noActivitiesTemplatesYet,
};

export const notFoundUrl = 'object-not-available';

export enum SearchTabs {
    All = 'All',
    Sessions = 'Sessions',
    Collections = 'Collections',
    Activities = 'Activities',
    MyTemplates = 'My Templates',
    TemplateStore = 'Template Store',
}

export const templateTab = {
    [ParentType.Templates]: TemplatesTab.MyTemplates,
    [ParentType.PublicSessionTemplates]: TemplatesTab.TemplateStore,
    [ParentType.ActivityTemplates]: TemplatesTab.MyTemplates,
    [ParentType.PublicActivityTemplates]: TemplatesTab.TemplateStore,
};
