import { Injectable } from '@angular/core';
import { firstValueFrom, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ActivityUserOptions, DBPathHelper, Dictionary, ParentType, TeamMember } from '@accenture/shared/data';
import { FirestoreService } from '@accenture/shared/data-access';

@Injectable({
    providedIn: 'root',
})
export class ActivityUserOptionsService {
    constructor(private firestoreService: FirestoreService) {}

    // TODO: remove for project deprovision
    getProjectSelectedSlideId(projectId: string, activityId: string, userId: string): Observable<string> {
        return this.get(ParentType.Projects, projectId, userId, activityId).pipe(
            map((activityOptions: ActivityUserOptions) => activityOptions?.selectedSlideId),
        );
    }

    getSelectedSlideId(
        parentId: string,
        activityId: string,
        userId: string,
        parentType: ParentType,
    ): Observable<string> {
        return this.get(parentType, parentId, userId, activityId).pipe(
            map((activityOptions: ActivityUserOptions) => activityOptions?.selectedSlideId),
        );
    }

    getColumnWidths(
        parentType: ParentType,
        projectId: string,
        userId: string,
        activityId: string,
    ): Observable<Dictionary<number>> {
        return this.get(parentType, projectId, userId, activityId).pipe(
            map((activityOptions: ActivityUserOptions) => activityOptions?.columnWidths),
        );
    }

    getColumnWidthsNew(
        parentType: ParentType,
        sessionId: string,
        userId: string,
        activityId: string,
    ): Observable<Dictionary<number>> {
        return this.get(parentType, sessionId, userId, activityId).pipe(
            map((activityOptions: ActivityUserOptions) => activityOptions?.columnWidths),
        );
    }

    async updateProjectSelectedSlideId(
        slideId: string,
        projectId: string,
        activityId: string,
        userId: string,
    ): Promise<void> {
        const teamMemberRef = DBPathHelper.getTeamMemberPath(ParentType.Projects, projectId, userId);
        const teamMember = await firstValueFrom(this.firestoreService.getDocument<TeamMember>(teamMemberRef));
        if (teamMember) {
            const activityOptions: Partial<ActivityUserOptions> = {
                selectedSlideId: slideId,
            };

            return this.upsert(ParentType.Projects, projectId, userId, activityId, activityOptions);
        }
    }

    async updateSessionSelectedSlideId(
        slideId: string,
        sessionId: string,
        activityId: string,
        userId: string,
    ): Promise<void> {
        const teamMemberRef = DBPathHelper.getTeamMemberPath(ParentType.Sessions, sessionId, userId);
        const teamMember = await firstValueFrom(this.firestoreService.getDocument<TeamMember>(teamMemberRef));
        if (teamMember) {
            const activityOptions: Partial<ActivityUserOptions> = {
                selectedSlideId: slideId,
            };

            return this.upsert(ParentType.Sessions, sessionId, userId, activityId, activityOptions);
        }
    }
    // TODO: Delete after project  deprecation
    async updateColumnWidth(
        parentType: ParentType,
        projectId: string,
        userId: string,
        activityId: string,
        columnWidthsMap: Dictionary<number>,
    ): Promise<void> {
        const activityOptions: Partial<ActivityUserOptions> = {
            columnWidths: columnWidthsMap,
        };

        return this.upsert(parentType, projectId, userId, activityId, activityOptions);
    }

    async updateColumnWidthNew(
        parentType: ParentType,
        sessionId: string,
        userId: string,
        activityId: string,
        columnWidthsMap: Dictionary<number>,
    ): Promise<void> {
        const activityOptions: Partial<ActivityUserOptions> = {
            columnWidths: columnWidthsMap,
        };

        return this.upsert(parentType, sessionId, userId, activityId, activityOptions);
    }

    private upsert(
        parentType: ParentType,
        parentId: string,
        userId: string,
        activityId: string,
        activityOptions: Partial<ActivityUserOptions>,
    ): Promise<void> {
        const activityUserOptionsPath = DBPathHelper.getActivityUserOptionsPath(
            parentType,
            parentId,
            activityId,
            userId,
        );
        return this.firestoreService.upsert<Partial<ActivityUserOptions>>(activityUserOptionsPath, activityOptions);
    }

    private get(
        parentType: ParentType,
        parentId: string,
        userId: string,
        activityId: string,
    ): Observable<ActivityUserOptions> {
        const activityUserOptionsPath = DBPathHelper.getActivityUserOptionsPath(
            parentType,
            parentId,
            activityId,
            userId,
        );
        return this.firestoreService.getDocument(activityUserOptionsPath);
    }

    async removeProjectSelectedSlideId(parentId: string, activityId: string, teamMembers: TeamMember[]): Promise<void> {
        const batchData: { path: string }[] = [];

        for (const teamMember of teamMembers) {
            const activityUserOptionsPath = DBPathHelper.getActivityUserOptionsPath(
                ParentType.Projects,
                parentId,
                activityId,
                teamMember.id,
            );
            batchData.push({
                path: activityUserOptionsPath,
            });
        }

        return this.firestoreService.deleteBatch(batchData);
    }
}
