import { Directive, ElementRef, OnInit, Input, Injector, OnDestroy, SimpleChanges, OnChanges } from '@angular/core';
import { OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { LoadingComponent } from '../components';
import { DynamicOverlay } from '../services/dynamic-overlay.service';

@Directive({
    selector: '[accentureOverlayLoading]',
})
export class OverlayLoadingDirective implements OnInit, OnDestroy, OnChanges {
    @Input('accentureOverlayLoading') toggler: Observable<boolean> | boolean;

    private overlayRef!: OverlayRef;
    private prevOverflow!: string;
    private destroySubject = new Subject<void>();

    constructor(private host: ElementRef, private dynamicOverlay: DynamicOverlay, private injector: Injector) {}

    ngOnInit() {
        this.overlayRef = this.dynamicOverlay.createWithDefaultConfig(this.host.nativeElement);

        if (typeof this.toggler === 'boolean') {
            this.toggleOverlay(this.toggler);
        } else {
            this.toggler?.pipe(takeUntil(this.destroySubject)).subscribe((show: boolean) => {
                this.toggleOverlay(show);
            });
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['toggler'] && typeof this.toggler === 'boolean') {
            this.toggleOverlay(this.toggler);
        }
    }

    ngOnDestroy() {
        this.destroySubject.next();
        this.destroySubject.complete();
    }

    private toggleOverlay(show: boolean): void {
        if (show) {
            const loaderPortal = new ComponentPortal(LoadingComponent, null, this.injector);

            this.prevOverflow = this.host.nativeElement.style.overflow;
            this.host.nativeElement.style.setProperty('overflow', 'hidden');
            this.overlayRef.attach(loaderPortal);
        } else {
            this.overlayRef?.detach();
            this.host.nativeElement.style.setProperty('overflow', this.prevOverflow);
        }
    }
}
