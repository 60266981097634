import { BreakpointObserver } from '@angular/cdk/layout';
import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { combineLatest, firstValueFrom, Observable, of, Subject } from 'rxjs';
import { filter, map, startWith, switchMap } from 'rxjs/operators';

import {
    ConfigurationService,
    FeatureToggleName,
    InAppNotificationsService,
} from '@accenture/erp-deployment/shared/domain';
import {
    AppState,
    isActivityPage,
    isAdminPage,
    isHomePage,
    isLogin,
    isProfilePage,
    isProjectPage,
    isSessionPage,
    isSingUp,
    selectActivityIdAndParentIds,
    selectAuthenticatedUser,
    selectAuthStateError,
    selectCurrentTab,
    selectFeatureToggles,
    selectProject,
    selectSession,
    selectSessionTeamMember,
    setTeamMemberOfflineState,
    signOut,
} from '@accenture/global-store';
import { AuthenticationTab, breakpoints, NavigationTab, Session, TeamMember, User } from '@accenture/shared/data';
import { UserService } from '@accenture/shared/data-access';
import {
    ConfirmationDialogComponent,
    DialogService,
    InfoDialogComponent,
    SnackbarService,
    SnackBarTypes,
} from '@accenture/shared/ui';

import { FredHeaderStore } from '../component-stores/fred-header.store';
import { ContactSupportDialogComponent } from '../contact-support-dialog/contact-support-dialog.component';
import { ManageGuestAccountSnackbarComponent } from '../manage-guest-account-snackbar/manage-guest-account-snackbar.component';
import { deactivateMode, deleteNotifications } from './constants';

export interface FredHeaderViewModel {
    user: any; // firebase.User | null;
    dbUser: User;
    authError: string;
    tab: NavigationTab;
    authenticationTab: AuthenticationTab;
    showSignUp: boolean;
    showAiConcierge: boolean;
    isActivityPage: boolean;
    isMobileScreen: boolean;
    screenHandset: boolean;
    notificationsCount: string;
    totalNotificationsCount: number;
    isLoading: boolean;
    helpMenuAvailable: boolean;
}

const defaultViewModel = {
    user: null as any,
    dbUser: null as User,
    authError: undefined,
    tab: undefined,
    authenticationTab: undefined,
    showSignUp: false,
    showAiConcierge: false,
    isActivityPage: false,
    isMobileScreen: false,
    screenHandset: false,
    notificationsCount: '',
    totalNotificationsCount: 0,
    isLoading: true,
    helpMenuAvailable: false,
} as FredHeaderViewModel;

@Injectable()
export class FredHeaderFacade {
    vm$ = this.buildViewModel();

    private logoutSubject = new Subject<void>();
    private isProjectPage: boolean;
    private projectId: string;
    private sessionId: string;
    private activityId: string;
    private userId: string;
    private isGuestUserLoginWasHandled = false;

    constructor(
        private router: Router,
        private afAuth: AngularFireAuth, // TODO: get from service
        private store: Store<AppState>,
        private componentStore: FredHeaderStore,
        private dialogService: DialogService,
        private breakpointObserver: BreakpointObserver,
        private userService: UserService,
        private inAppNotificationsService: InAppNotificationsService,
        private configurationService: ConfigurationService,
        private snackbarService: SnackbarService,
    ) {}

    async logout(): Promise<void> {
        if (this.isProjectPage) {
            await this.store.dispatch(
                setTeamMemberOfflineState({
                    projectId: this.projectId,
                    sessionId: this.sessionId,
                }),
            );
        }
        this.store.dispatch(signOut());
        this.logoutSubject.next();
    }

    setTab(tab: NavigationTab): void {
        this.componentStore.setTab(tab);
    }

    setAuthenticationTab(tab: AuthenticationTab): void {
        this.componentStore.setAuthenticationTab(tab);
    }

    navigateHome(tab: NavigationTab): void {
        this.setTab(tab);

        this.router.navigate(['/home'], { queryParams: { tab } });
        return;
    }

    async navigateBack(): Promise<void> {
        if (!this.projectId) {
            this.navigateHome(NavigationTab.Dashboard);
            return;
        }

        if (this.projectId && this.sessionId && this.activityId) {
            const teamMember = await firstValueFrom(this.store.select(selectSessionTeamMember));
            const session = await firstValueFrom(this.store.select(selectSession));

            if (teamMember.isSessionLeader || session.selfNavigate) {
                this.router.navigate(['project', this.projectId, 'session', this.sessionId]);
            } else {
                this.router.navigate(['project', this.projectId]);
            }

            return;
        }

        // TODO: will be updated for activity also
        this.router.navigate(['project', this.projectId]);
        return;
    }

    navigateAuthentication(tab: AuthenticationTab): void {
        this.setAuthenticationTab(tab);

        const getRoute = tab === AuthenticationTab.Login ? '/authentication' : '/authentication/registerUser';
        this.router.navigate([getRoute]);
    }

    openContactSupportDialog(): void {
        this.dialogService.open(ContactSupportDialogComponent, {
            panelClass: 'tt9-modal',
            width: '768px',
        });
    }

    projectAndSessionInfo(): Observable<{
        session: Session;
        teamMember: TeamMember;
    }> {
        return combineLatest([
            this.store.select(selectProject),
            this.store.select(selectSession),
            this.store.select(selectSessionTeamMember),
        ]).pipe(
            map(([project, session, teamMember]) => {
                if (project?.id && session?.id) {
                    this.projectId = project?.id;
                    this.sessionId = session?.id;
                }
                return { session, teamMember };
            }),
        );
    }

    navigateDeactivation(): void {
        this.router.navigate(['authentication', deactivateMode]);
    }

    updateUser(user: Partial<User>): void {
        this.userService.updateUserData(this.userId, user);
    }

    markAllNotificationsAsRead(): void {
        this.inAppNotificationsService.markAllNotificationsAsRead(this.userId);
    }

    deleteAllNotifications(): void {
        this.dialogService.open(ConfirmationDialogComponent, {
            title: deleteNotifications.title,
            confirmBtnText: 'Delete',
            cancelBtnText: 'Cancel',
            width: '445px',
            text: deleteNotifications.text,
            isWarning: true,
            confirm: async () => {
                this.inAppNotificationsService.deleteAllNotifications(this.userId);
            },
        });
    }

    async openTermsOfUse(): Promise<void> {
        const { text, title } = await firstValueFrom(this.configurationService.getTermsOfUse());
        this.dialogService.open(InfoDialogComponent, {
            title,
            text,
            panelClass: 'tt9-modal',
            width: '768px',
        });
    }

    openManageGuestAccountSnackbar(): void {
        this.snackbarService.showCustomSnackBar(
            ManageGuestAccountSnackbarComponent,
            {
                goToLoginAction: () => {
                    this.logout();
                },
            },
            SnackBarTypes.Info,
            0,
            'manage-guest-account-snack-bar',
        );
    }

    private buildViewModel(): Observable<FredHeaderViewModel> {
        return combineLatest([
            this.afAuth.user,
            this.store.select(selectAuthenticatedUser),
            this.store.select(selectAuthStateError),
            this.store.select(selectCurrentTab),
            this.store.select(isHomePage),
            this.store.select(isLogin),
            this.store.select(isSingUp),
            this.store.select(isProjectPage),
            this.store.select(isSessionPage),
            this.store.select(isProfilePage),
            this.store.select(isAdminPage),
            this.store.select(isActivityPage),
            this.store.select(selectActivityIdAndParentIds),
            this.store.select(selectFeatureToggles),
            this.componentStore.tab$,
            this.componentStore.authenticationTab$,
            this.breakpointObserver.observe(breakpoints.screenAllMobiles),
            this.breakpointObserver.observe(breakpoints.screenHandsetPortrait),
            this.getNotificationsCount(),
            this.getTotalNotificationsCount(),
        ]).pipe(
            filter(user => !!user),
            map(
                ([
                    user,
                    dbUser,
                    authError,
                    queryTab,
                    isHomePage,
                    isLogin,
                    isSingUp,
                    isProjectPage,
                    isSessionPage,
                    isProfilePage,
                    isAdminPage,
                    isActivityPage,
                    { projectId, sessionId, activityId },
                    featureToggles,
                    tab,
                    authenticationTab,
                    isMobileScreen,
                    screenHandset,
                    notificationsCount,
                    totalNotificationsCount,
                ]) => {
                    const showSignUp = featureToggles?.[FeatureToggleName.ShowRegisterLink];
                    const showAiConcierge = featureToggles?.[FeatureToggleName.AiConciergeAvailable];
                    const helpMenuAvailable = featureToggles?.[FeatureToggleName.HelpMenu];

                    this.isProjectPage = isProjectPage;
                    this.projectId = projectId;
                    this.sessionId = sessionId;
                    this.activityId = activityId;
                    this.userId = dbUser.id as string;

                    if (isHomePage && queryTab !== tab && queryTab) {
                        this.setTab(queryTab);
                        tab = queryTab;
                    }

                    if (isProfilePage) {
                        this.setTab(queryTab);
                        tab = queryTab;
                    }

                    if (isAdminPage) {
                        this.setTab(queryTab);
                        tab = queryTab;
                    }

                    if (!isHomePage && !isProfilePage && !isAdminPage) {
                        const pageTab = isProjectPage
                            ? NavigationTab.Projects
                            : isSessionPage
                            ? NavigationTab.Sessions
                            : NavigationTab.Templates;
                        if (pageTab !== tab) {
                            this.setTab(pageTab);
                            tab = pageTab;
                        }
                    }

                    if (isLogin) {
                        const pageTab = isSingUp ? AuthenticationTab.SingUp : AuthenticationTab.Login;
                        this.setAuthenticationTab(pageTab);
                        authenticationTab = pageTab;
                    }

                    if (!isLogin && dbUser?.guest && !this.isGuestUserLoginWasHandled) {
                        this.handleGuestUserLogin();
                    }

                    return {
                        user,
                        dbUser,
                        authError,
                        tab,
                        authenticationTab,
                        showSignUp,
                        showAiConcierge,
                        isActivityPage,
                        notificationsCount,
                        totalNotificationsCount,
                        helpMenuAvailable,
                        screenHandset: screenHandset.matches,
                        isMobileScreen: isMobileScreen.matches,
                        isLoading: false,
                    };
                },
            ),
            startWith(defaultViewModel),
        );
    }

    private getNotificationsCount(): Observable<string> {
        return this.store.select(selectAuthenticatedUser).pipe(
            switchMap(user =>
                user && user.id
                    ? this.inAppNotificationsService.getNotificationsCount(
                          user.id,
                          user.lastViewedNotificationDateCreated,
                      )
                    : of(0),
            ),
            map(count => `${count > 99 ? '99+' : count ? count : ''}`),
        );
    }

    private getTotalNotificationsCount(): Observable<number> {
        return this.store
            .select(selectAuthenticatedUser)
            .pipe(
                switchMap(user =>
                    user && user.id ? this.inAppNotificationsService.getTotalNotificationsCount(user.id) : of(0),
                ),
            );
    }

    private handleGuestUserLogin(): void {
        const isGuestFirstLogin = this.userService.isGuestFirstLogin$.getValue();

        if (!isGuestFirstLogin) {
            this.openManageGuestAccountSnackbar();
        }

        this.isGuestUserLoginWasHandled = true;
    }
}
