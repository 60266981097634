import { inject, Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest, distinctUntilChanged, map, Observable, startWith, switchMap } from 'rxjs';

import { AppState, selectAuthenticatedUserId, selectParams } from '@accenture/global-store';
import { Dictionary, ImportResultType, ImportType, ParentType, StoragePathHelper } from '@accenture/shared/data';
import { FilestackService, FilestackStorage, FilestackUploadResult, FilestackUploadType } from '@accenture/shared/data-access';
import { ImportService } from '@accenture/shared/ui';

export interface ImportButtonModel {
    isLoading: boolean;
}

const defaultViewModel = {
    isLoading: false,
};

@Injectable()
export class ImportButtonFacade {
    private isLoading = new BehaviorSubject<boolean>(false);
    private isLoading$ = this.isLoading.asObservable().pipe(distinctUntilChanged());

    private readonly store: Store<AppState> = inject(Store<AppState>);
    private readonly filestackService: FilestackService = inject(FilestackService);
    private readonly importService: ImportService = inject(ImportService);

    vm$ = this.buildViewModel();

    private templateId!: string;
    private sessionId!: string;
    private activityId!: string;
    private userId!: string;

    upload(
        importType: ImportType,
        importResultType: ImportResultType,
        parentType: ParentType,
        importData: Dictionary<any>,
        fileUploadedCallback?: () => void,
    ): Promise<string[]> {
        return new Promise((resolve, reject) => {
            this.filestackService.uploadFiles({
                userId: this.userId,
                uploadType: FilestackUploadType.EXCELFile,
                storeTo: FilestackStorage.FirebaseStorage,
                fbFolder: `${StoragePathHelper.getParentPath(parentType, this.getParentId(parentType))}/`,
                onUploadStarted: () => this.isLoading.next(true),
                onFileUploadFinished: () => {
                    if (fileUploadedCallback) {
                        fileUploadedCallback();
                    }
                },
                onUploadFailed: () => this.isLoading.next(false),
                onUploadDone: async (uploadResult: FilestackUploadResult[]) => {
                    const fileUrl = uploadResult[0].url;
                    const fileName = uploadResult[0].fileName;

                    try {
                        if (fileName && fileUrl) {
                            const result = await this.import(
                                fileUrl,
                                fileName,
                                importType,
                                importResultType,
                                parentType,
                                importData,
                            );
                            this.isLoading.next(false);
                            resolve(result);
                        }
                        this.isLoading.next(false);
                        reject();
                    } catch (e) {
                        console.error('Import failed or completed with warnings');
                        this.isLoading.next(false);
                        reject(e);
                    }
                },
            });
        });
    }

    private buildViewModel(): Observable<ImportButtonModel> {
        return combineLatest([this.store.select(selectAuthenticatedUserId), this.getRouteIds()]).pipe(
            switchMap(([userId, { templateId, sessionId, activityId }]) => {
                this.userId = userId;
                this.templateId = templateId;
                this.sessionId = sessionId;
                this.activityId = activityId;

                return this.isLoading$.pipe(map((isLoading) => ({ isLoading })));
            }),
            startWith(defaultViewModel),
        );
    }

    private getRouteIds(): Observable<Dictionary<string>> {
        return this.store.pipe(select(selectParams));
    }

    private import(
        fileUrl: string,
        fileName: string,
        importType: ImportType,
        importResultType: ImportResultType,
        parentType: ParentType,
        importData: Dictionary<any>,
    ): Promise<string[]> {
        return this.importService.importTT9(fileUrl, fileName, importType, importResultType, {
            ...importData,
            parentType,
            templateId: this.templateId,
            sessionId: this.sessionId,
            activityId: this.activityId,
        });
    }

    private getParentId(parentType: ParentType): string {
        switch (parentType) {
            case ParentType.Sessions:
                return this.sessionId;
            case ParentType.Templates:
            case ParentType.PublicSessionTemplates:
                return this.templateId;
            case ParentType.PublicActivityTemplates:
            case ParentType.ActivityTemplates:
                return this.activityId;
            default:
                return '';
        }
    }
}
