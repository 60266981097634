import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { cloneDeep } from 'lodash';

import { SessionRole } from '@accenture/shared/data';

import { SessionsInvitationsByEmailStep, SessionsInvitationsTab } from './session-invite-dialog.constants';
import { SelectedForInvitationMemberData } from './session-invite-dialog.models';

export interface SessionInviteDialogState {
    activeTab: SessionsInvitationsTab;
    inviteByEmailActiveStep: SessionsInvitationsByEmailStep;
    selectedForInvitationMemberData: SelectedForInvitationMemberData;
    activeInvitationRole: SessionRole.Leader | SessionRole.Participant | undefined;
}

const initialState: SessionInviteDialogState = {
    activeTab: SessionsInvitationsTab.ByEmail,
    inviteByEmailActiveStep: SessionsInvitationsByEmailStep.Invite,
    selectedForInvitationMemberData: {},
    activeInvitationRole: undefined,
};

@Injectable()
export class SessionInviteDialogStore extends ComponentStore<SessionInviteDialogState> {
    constructor() {
        super(cloneDeep(initialState));
    }

    readonly activeTab$ = this.select(state => state.activeTab);
    readonly inviteByEmailActiveStep$ = this.select(state => state.inviteByEmailActiveStep);
    readonly selectedForInvitationMemberData$ = this.select(state => state.selectedForInvitationMemberData);
    readonly activeInvitationRole$ = this.select(state => state.activeInvitationRole);
    readonly leaderEmails$ = this.select(state => {
        return Object.entries(state.selectedForInvitationMemberData).reduce((emails: string[], [email, role]) => {
            return role === SessionRole.Leader ? [...emails, email] : emails;
        }, []);
    });
    readonly participantEmails$ = this.select(state => {
        return Object.entries(state.selectedForInvitationMemberData).reduce((emails: string[], [email, role]) => {
            return role === SessionRole.Participant ? [...emails, email] : emails;
        }, []);
    });

    readonly setActiveTab = this.updater((state, activeTab: SessionsInvitationsTab) => {
        return {
            ...state,
            activeTab,
        };
    });

    readonly setInviteByEmailActiveStep = this.updater(
        (state, inviteByEmailActiveStep: SessionsInvitationsByEmailStep) => {
            return {
                ...state,
                inviteByEmailActiveStep,
            };
        },
    );

    readonly setSelectedForInvitationMemberData = this.updater(
        (state, selectedForInvitationMemberData: SelectedForInvitationMemberData) => {
            return {
                ...state,
                selectedForInvitationMemberData,
            };
        },
    );
    readonly addSelectedForInvitationMemberData = this.updater(
        (state, selectedForInvitationMemberData: SelectedForInvitationMemberData) => {
            return {
                ...state,
                selectedForInvitationMemberData: {
                    ...state.selectedForInvitationMemberData,
                    ...selectedForInvitationMemberData,
                },
            };
        },
    );

    readonly removeSelectedForInvitationMemberData = this.updater((state, emails: string[]) => {
        const selectedForInvitationMemberData = { ...state.selectedForInvitationMemberData };

        emails.forEach(email => delete selectedForInvitationMemberData[email]);

        return {
            ...state,
            selectedForInvitationMemberData: selectedForInvitationMemberData,
        };
    });
    readonly setActiveInvitationRole = this.updater(
        (state, activeInvitationRole: SessionRole.Leader | SessionRole.Participant | undefined) => {
            return {
                ...state,
                activeInvitationRole,
            };
        },
    );
}
