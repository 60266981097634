import { Pipe, PipeTransform } from '@angular/core';

import { SessionStatus, sessionStatusToDisplay } from '@accenture/shared/data';

@Pipe({
    name: 'displaySessionStatus',
})
export class DisplaySessionStatusPipe implements PipeTransform {
    transform(type: SessionStatus): unknown {
        return sessionStatusToDisplay[type];
    }
}
