import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { isEqual } from 'lodash';
import { combineLatest, map, Observable, startWith } from 'rxjs';
import { distinctUntilChanged, switchMap } from 'rxjs/operators';

import {
    FiltersService,
    SessionOptionsService,
    SessionService,
} from '@accenture/erp-deployment/shared/domain';
import {
    AppState,
    selectActivityIdAndParentIds,
    selectAuthenticatedUserId,
} from '@accenture/global-store';
import {
    CollectionSortOptions,
    CollectionsSortObject,
    DefaultCollectionSortObject,
    Dictionary,
    OptionsFiltersFields,
    OptionsForSortingByNumberOfUses,
    ParentType,
    SessionOptions,
    SessionOptionsType,
    SortOrders,
} from '@accenture/shared/data';

export interface DefaultSessionsOptionsFiltersChipsViewModel {
    optionsData: SessionOptionsType[];
    sortOption: CollectionsSortObject;
    selectedOptions: Dictionary<boolean>;
    isLoading: boolean;
}

export const defaultViewModel = {
    optionsData: [],
    sortOption: {
        sortOrder: SortOrders.Dsc,
        sortOption: CollectionSortOptions.Frequency,
    },
    selectedOptions: {} as Dictionary<boolean>,
    isLoading: false,
};

@Injectable()
export class DefaultSessionsOptionsFiltersChipsFacade {
    vm$ = this.buildViewModel();

    private userId: string;
    private parentType: ParentType;
    private parentId: string;
    private optionType: SessionOptions;

    constructor(
        private store: Store<AppState>,
        private sessionService: SessionService,
        private sessionOptionsService: SessionOptionsService,
        private filtersService: FiltersService,
    ) {}

    async updateCollectionSortOptions(sortObject: DefaultCollectionSortObject): Promise<void> {
        await this.sessionService.updateCurrentSessionsFilters(this.userId, sortObject, this.parentType, this.parentId);
    }

    async updateOptionsFilter(optionId: string, isSelected: boolean): Promise<void> {
        await this.sessionOptionsService.updateOptionsFilters(
            this.userId,
            this.parentType,
            this.parentId,
            optionId,
            isSelected,
            this.optionType,
        );
    }

    private buildViewModel(): Observable<DefaultSessionsOptionsFiltersChipsViewModel> {
        return combineLatest([
            this.store.select(selectAuthenticatedUserId),
            this.store.select(selectActivityIdAndParentIds),
        ]).pipe(
            switchMap(([userId, { parentType, parentId }]) => {
                this.userId = userId;
                this.parentType = parentType;
                this.parentId = parentId;
                this.optionType = this.filtersService.sessionOptionType$.getValue();

                const collection$ = this.sessionOptionsService.getOptions(this.optionType);
                const sessionsFilters$ = this.getSessionsFilters();

                return combineLatest([collection$, sessionsFilters$]).pipe(
                    map(([collection, { sortOption, selectedOptions }]) => {
                        const selectedOptionsDictionary = selectedOptions.reduce((acc, el) => {
                            return {
                                ...acc,
                                [el]: true,
                            };
                        }, {} as Dictionary<boolean>);

                        return {
                            sortOption,
                            selectedOptions: selectedOptionsDictionary,
                            optionsData: this.filtersService.sortOptionsData(
                                collection,
                                sortOption,
                                OptionsForSortingByNumberOfUses.Sessions,
                            ),
                            isLoading: false,
                        };
                    }),
                );
            }),
            startWith(defaultViewModel),
        );
    }

    private getSessionsFilters(): Observable<{ sortOption: CollectionsSortObject; selectedOptions: string[] }> {
        return this.sessionService.getCurrentUserSessionsFilters(this.userId, this.parentType, this.parentId).pipe(
            distinctUntilChanged(isEqual),
            map(filtersAndSort => ({
                sortOption: {
                    sortOrder: filtersAndSort.collectionsSortOrder,
                    sortOption: filtersAndSort.collectionsSortOption,
                },
                selectedOptions: filtersAndSort[OptionsFiltersFields[this.optionType]] || [],
            })),
        );
    }
}
