import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { combineLatest, map, Observable, startWith, tap } from 'rxjs';
import { distinctUntilChanged, switchMap } from 'rxjs/operators';

import {
    ActivityOptionsService,
    FiltersService,
    SessionOptionsService,
    TemplateService,
} from '@accenture/erp-deployment/shared/domain';
import { AppState, selectAuthenticatedUserId, selectRouterQueryParams } from '@accenture/global-store';
import {
    ActivityOptions,
    ActivityOptionsType,
    CollectionsSortObject,
    DefaultCollectionSortObject,
    initialDefaultFilterObject,
    OptionsFiltersFields,
    ParentType,
    SessionOptions,
    SessionOptionsType,
    templateOptionsFieldForSorting,
    TemplatesTab,
    TemplateTab,
    templateTabValue,
} from '@accenture/shared/data';

export interface DefaultOptionsFiltersChipsViewModel {
    optionsData: SessionOptionsType[];
    optionType?: string;
    sortOption: CollectionsSortObject;
    selectedOptions: string[];
    parentType: TemplateTab | ParentType;
    isLoading: boolean;
}

export const defaultViewModel = {
    optionsData: [],
    sortOption: {},
    selectedOptions: [],
    parentType: TemplateTab.All,
    isLoading: false,
};

@Injectable()
export class DefaultOptionsFiltersChipsFacade {
    vm$ = this.buildViewModel();

    private userId: string;
    private parentType: ParentType | string;
    private optionType: string;

    constructor(
        private store: Store<AppState>,
        private templateService: TemplateService,
        private filtersService: FiltersService,
        private sessionOptionsService: SessionOptionsService,
        private activityOptionsService: ActivityOptionsService,
    ) {}

    updateCollectionSortOptions(sortObject: DefaultCollectionSortObject): void {
        this.templateService.updateTemplatesFilters(this.userId, {
            [this.parentType]: sortObject,
        });
    }

    applyOptionsFilter(selectedOptions: string[], optionId: string, isSelected: boolean): void {
        const newData = isSelected
            ? selectedOptions.filter((selectedId) => selectedId !== optionId)
            : [...selectedOptions, optionId];

        this.templateService.updateOptionsFilters(this.userId, this.optionType, newData, this.parentType as ParentType);
    }

    private buildViewModel(): Observable<DefaultOptionsFiltersChipsViewModel> {
        return combineLatest([
            this.store.select(selectAuthenticatedUserId),
            this.store.select(selectRouterQueryParams),
            this.filtersService.myTemplatesViewTab$.pipe(distinctUntilChanged()),
            this.filtersService.optionType$.pipe(distinctUntilChanged()),
        ]).pipe(
            switchMap(([userId, routerQuery, templateTab, optionType]) => {
                this.userId = userId;
                this.parentType = this.getParentType(routerQuery.templatesTab, templateTab);
                this.optionType = OptionsFiltersFields[optionType];
                const collection$ = this.getOptionsCollection(optionType as SessionOptions | ActivityOptions);

                const filtersAndSelected$ = this.getTemplateFilters();

                return combineLatest([collection$, filtersAndSelected$]).pipe(
                    map(([collection, { sortOption, selectedOptions }]) => {
                        const optionForSortingByNumberOfUses = {
                            [TemplateTab.Sessions]: templateOptionsFieldForSorting[ParentType.Templates],
                            [TemplateTab.Activities]: templateOptionsFieldForSorting[ParentType.ActivityTemplates],
                        }[templateTab];

                        return {
                            sortOption,
                            optionType,
                            selectedOptions,
                            parentType: templateTab,
                            optionsData: this.filtersService.sortOptionsData(
                                collection,
                                sortOption,
                                optionForSortingByNumberOfUses,
                            ),
                            isLoading: false,
                        };
                    }),
                );
            }),
            startWith(defaultViewModel),
        );
    }

    private getTemplateFilters(): Observable<{ sortOption: CollectionsSortObject; selectedOptions: string[] }> {
        return this.store.select(selectAuthenticatedUserId).pipe(
            tap((userId) => (this.userId = userId)),
            switchMap((userId) =>
                this.templateService.getCurrentUserAllTemplatesFilters(userId).pipe(
                    map((allFilters) => {
                        const filtersAndSort = allFilters[this.parentType] || initialDefaultFilterObject;
                        return {
                            sortOption: {
                                sortOrder:
                                    filtersAndSort?.collectionsSortOrder
                                    || initialDefaultFilterObject.collectionsSortOrder,
                                sortOption:
                                    filtersAndSort?.collectionsSortOption
                                    || initialDefaultFilterObject.collectionsSortOption,
                            },
                            selectedOptions: filtersAndSort[this.optionType] || [],
                        };
                    }),
                ),
            ),
        );
    }

    private getParentType(routeTab: TemplatesTab, templateTab?: TemplateTab): ParentType | string {
        switch (routeTab) {
            case TemplatesTab.MyTemplates:
                return templateTabValue[templateTab];
        }
    }

    private getOptionsCollection(
        optionType: SessionOptions | ActivityOptions,
    ): Observable<SessionOptionsType[]> | Observable<ActivityOptionsType[]> {
        switch (this.parentType) {
            case ParentType.Templates:
                return this.sessionOptionsService.getOptions(optionType as SessionOptions);
            case ParentType.ActivityTemplates:
                return this.activityOptionsService.getOptions(ParentType.Activities);
            default:
                return this.sessionOptionsService.getOptions(optionType as SessionOptions);
        }
    }
}
