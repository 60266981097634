import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { isEqual, isUndefined } from 'lodash';
import { combineLatest, distinctUntilChanged, tap } from 'rxjs';

import { InvitationObject } from '@accenture/erp-deployment/shared/domain';
import {
    characterLimitLarge,
    characterLimitText,
    determineHintClass,
    inputPlaceholders,
    InvitedSessions,
    ProjectRole,
    Session,
    SessionRole,
} from '@accenture/shared/data';
import { IconColor } from '@accenture/shared/ui';
import { trackById, trackByValue } from '@accenture/shared/util';

import { invitationText } from './invitation-form-bulk-invite.constants';
import { InvitationFormBulkInviteFacade } from './invitation-form-bulk-invite-facade';

interface SessionGroup {
    email: FormControl<string>;
    sessionsIds: FormControl<string[]>;
    role: FormControl<string>;
}

type InviteForm = FormGroup<{
    adminMessage: FormControl<string>;
    memberMessage: FormControl<string>;
    [ProjectRole.Admin]: FormControl<string[]>;
    [ProjectRole.Member]: FormControl<string[]>;
    sessionGroup: FormArray<FormGroup<SessionGroup>>;
}>;

@UntilDestroy()
@Component({
    selector: 'accenture-invitation-form-bulk-invite',
    templateUrl: './invitation-form-bulk-invite.component.html',
    styleUrls: ['./invitation-form-bulk-invite.component.scss'],
    providers: [InvitationFormBulkInviteFacade],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InvitationFormBulkInviteComponent implements OnInit {
    @Input() skipRestrictedEmails = false;
    @Output() viewModelChange = new EventEmitter<InvitationObject>();

    inviteForm!: InviteForm;
    vm$ = this.facade.vm$;

    projectRole = ProjectRole;
    projectRoleList = [ProjectRole.Admin, ProjectRole.Member];
    sessionRoleList = [SessionRole.Leader, SessionRole.Participant];
    invitationText = invitationText;
    inputPlaceholders = inputPlaceholders;
    characterLimitLarge = characterLimitLarge;
    iconColor = IconColor;

    characterLimitText = characterLimitText;
    determineHintClass = determineHintClass;
    trackById = trackById;
    trackByValue = trackByValue;

    constructor(private facade: InvitationFormBulkInviteFacade, private fb: FormBuilder) {}

    get sessionGroups(): FormArray<FormGroup<SessionGroup>> {
        return this.inviteForm.controls.sessionGroup;
    }

    trackByEmail = (_: number, item: FormGroup<SessionGroup>): string => {
        return item.value.email;
    };

    toggleSelectAll(value: boolean, emailIndex: number, allSessions: Session[]): void {
        const sessionIdsControl = this.sessionGroups.controls[emailIndex].controls.sessionsIds;
        const allSessionIds = allSessions.map(session => session.id);

        sessionIdsControl.setValue(value ? allSessionIds : []);
        this.setSessionInvitesValues(emailIndex);
    }

    getBlackListEmails(projectMemberEmails: string[], role: ProjectRole): string[] {
        const controls = this.inviteForm.controls;
        const isAdmin = role === ProjectRole.Admin;
        const invitedEmails = isAdmin ? controls[ProjectRole.Member].value : controls[ProjectRole.Admin].value;

        return [...projectMemberEmails, ...invitedEmails];
    }

    removeEmail(event: Event, emailForRemove: string, role: ProjectRole): void {
        event.stopPropagation();

        const emailsControl = this.inviteForm.controls[role];
        const newEmailValues = emailsControl.value.filter(email => emailForRemove !== email);

        emailsControl.setValue(newEmailValues);
    }

    setSessionInvitesValues(i?: number): void {
        if (isUndefined(i)) {
            return;
        }

        const sessionsControl = this.sessionGroups.controls[i].controls.sessionsIds;
        const roleControl = this.sessionGroups.controls[i].controls.role;

        if (sessionsControl?.value.length) {
            const newRole = roleControl.value !== ProjectRole.Member ? roleControl.value : SessionRole.Participant;

            roleControl.setValue(newRole);
            roleControl.enable();
        } else {
            roleControl.setValue(ProjectRole.Member);
            roleControl.disable();
        }
    }

    isAllSelected(i: number, sessionsLength: number): boolean {
        const value = this.sessionGroups.controls[i]?.controls.sessionsIds.value || [];
        return value.length === sessionsLength;
    }

    isDisabledTooltip(elem: HTMLElement): boolean {
        return elem.clientWidth ? elem.scrollWidth <= elem.clientWidth : false;
    }

    ngOnInit(): void {
        this.setInviteForm();
        this.subscribeOnProjectMemberEmailsUpdate();
        this.subscribeOnFormUpdate();
        this.subscribeOnCheckIsRoleControlShouldBeDisabled();
    }

    private subscribeOnProjectMemberEmailsUpdate(): void {
        combineLatest({
            vm: this.vm$,
            memberEmails: this.inviteForm.controls[ProjectRole.Member].valueChanges,
        })
            .pipe(
                untilDestroyed(this),
                distinctUntilChanged(isEqual),
                tap(data => this.updateSessionGroup(data.memberEmails, data.vm.sessionIds)),
            )
            .subscribe();
    }

    private subscribeOnFormUpdate(): void {
        this.inviteForm.valueChanges
            .pipe(
                untilDestroyed(this),
                tap(() => this.emitViewModelChange()),
            )
            .subscribe();
    }

    private subscribeOnCheckIsRoleControlShouldBeDisabled(): void {
        combineLatest({
            vm: this.vm$,
            sessionGroupChange: this.sessionGroups.valueChanges,
        })
            .pipe(
                untilDestroyed(this),
                distinctUntilChanged(isEqual),
                tap(data => {
                    const disabled = !data.vm.sessions.length;

                    this.sessionGroups.controls.forEach(control => {
                        const roleControl = control.controls.role;

                        if (roleControl.enabled && disabled) {
                            roleControl.setValue('');
                            roleControl.disable();
                        }
                    });
                }),
            )
            .subscribe();
    }

    private updateSessionGroup(emails: string[], sessionIds: string[]): void {
        const sessionGroupsValue = this.sessionGroups.value as Array<{ email: string }>;
        const sessionGroupsEmails = sessionGroupsValue.map(group => group.email);
        const indexesForRemove = sessionGroupsEmails.reduce((indexes: number[], groupEmail, currentIndex) => {
            if (!emails.includes(groupEmail)) {
                indexes.push(currentIndex);
            }

            return indexes;
        }, []);
        const emailsToAdd = emails.reduce((emailsToAdd: string[], email) => {
            if (!sessionGroupsEmails.includes(email)) {
                emailsToAdd.push(email);
            }

            return emailsToAdd;
        }, []);

        indexesForRemove.forEach(index => this.sessionGroups.removeAt(index));

        emailsToAdd.forEach(email => {
            const group = this.fb.group<SessionGroup>({
                email: new FormControl(email),
                sessionsIds: new FormControl(sessionIds),
                role: new FormControl(SessionRole.Participant),
            });

            this.sessionGroups.push(group);
        });
    }

    private setInviteForm(): void {
        const controls = {
            adminMessage: new FormControl('', { nonNullable: true }),
            memberMessage: new FormControl('', { nonNullable: true }),
            sessionGroup: new FormArray([]),
            [ProjectRole.Admin]: new FormControl([], { nonNullable: true }),
            [ProjectRole.Member]: new FormControl([], { nonNullable: true }),
        };

        this.inviteForm = new FormGroup(controls);
    }

    private emitViewModelChange(): void {
        const adminEmails = this.inviteForm.controls[ProjectRole.Admin].value;
        const memberEmails = this.inviteForm.controls[ProjectRole.Member].value;

        this.viewModelChange.emit({
            chipsObject: {
                [ProjectRole.Admin]: adminEmails,
                [ProjectRole.Member]: memberEmails,
            },
            adminMessage: this.inviteForm.controls.adminMessage.value,
            memberMessage: this.inviteForm.controls.memberMessage.value,
            invitedUsersEmails: [...adminEmails, ...memberEmails],
            invitedSessions: this.sessionGroups.getRawValue() as Array<InvitedSessions>,
        });
    }
}
