import { Action, createReducer, on } from '@ngrx/store';

import { TeamMember } from '@accenture/shared/data';

import { clearPublicTemplateStore, getParentTeamMemberSuccess } from './public-template.actions';

export const PUBLIC_TEMPLATE_FEATURE_KEY = 'publicTemplate';

export interface PublicTemplateState {
    parentTeamMember: TeamMember | null;
}

const initialState: PublicTemplateState = {
    parentTeamMember: null,
};

const _publicTemplateReducer = createReducer(
    initialState,

    on(getParentTeamMemberSuccess, (state, { teamMember }) => ({
        ...state,
        parentTeamMember: teamMember,
    })),

    on(clearPublicTemplateStore, () => ({
        ...initialState,
    })),
);

export function publicTemplateReducer(state: any, action: Action) {
    return _publicTemplateReducer(state, action);
}
