import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { combineLatest, map, Observable, startWith, tap } from 'rxjs';
import { distinctUntilChanged, switchMap } from 'rxjs/operators';

import {
    ActivityOptionsService,
    FiltersService,
    ProjectOptionsService,
    SessionOptionsService,
    TemplateService,
} from '@accenture/erp-deployment/shared/domain';
import { AppState, selectAuthenticatedUserId } from '@accenture/global-store';
import {
    ActivityOptions,
    ActivityOptionsType,
    CollectionOptions,
    CollectionsSortObject,
    DefaultCollectionSortObject,
    initialDefaultFilterObject,
    OptionsFiltersFields,
    ParentType,
    ProjectOptions,
    ProjectOptionsType,
    SessionOptions,
    SessionOptionsType,
    templateOptionsFieldForSorting,
    templateTabValue,
} from '@accenture/shared/data';

export interface TemplatesStoreOptionsFiltersChipsViewModel {
    optionsData: ProjectOptionsType[];
    optionType?: string;
    sortOption: CollectionsSortObject;
    selectedOptions: string[];
    parentTypeTab: ParentType | string | null;
    isLoading: boolean;
}

export const defaultViewModel = {
    optionsData: [],
    sortOption: {},
    selectedOptions: [],
    parentTypeTab: null,
    isLoading: false,
};

@Injectable()
export class TemplatesStoreOptionsFiltersChipsFacade {
    vm$ = this.buildViewModel();

    private userId: string;
    private parentTypeTab: ParentType | string;
    private optionType: string;

    constructor(
        private store: Store<AppState>,
        private templateService: TemplateService,
        private filtersService: FiltersService,
        private projectOptionsService: ProjectOptionsService,
        private sessionOptionsService: SessionOptionsService,
        private activityOptionsService: ActivityOptionsService,
    ) {}

    updateCollectionSortOptions(sortObject: DefaultCollectionSortObject): void {
        if (!this.userId || !this.parentTypeTab) {
            return;
        }

        this.templateService.updateTemplatesStoreFilters(this.userId, {
            [this.parentTypeTab]: sortObject,
        });
    }

    applyOptionsFilter(selectedOptions: string[], optionId: string, isSelected: boolean): void {
        if (!this.userId || !this.optionType || !this.parentTypeTab) {
            return;
        }
        const newData = isSelected
            ? selectedOptions.filter(selectedId => selectedId !== optionId)
            : [...selectedOptions, optionId];

        this.templateService.updateTemplatesStoreOptionsFilters(
            this.userId,
            this.optionType,
            newData,
            this.parentTypeTab as ParentType,
        );
    }

    private buildViewModel(): Observable<TemplatesStoreOptionsFiltersChipsViewModel> {
        return combineLatest([
            this.store.select(selectAuthenticatedUserId),
            this.filtersService.templatesStoreViewTab$.pipe(distinctUntilChanged()),
            this.filtersService.optionType$.pipe(distinctUntilChanged()),
        ]).pipe(
            switchMap(([userId, templateTab, optionType]) => {
                this.userId = userId;
                this.parentTypeTab = templateTabValue[templateTab];
                this.optionType = OptionsFiltersFields[optionType];

                const collection$ = this.getOptionsData(optionType);
                const filtersAndSelected$ = this.getTemplateProjectsFilters();

                return combineLatest([collection$, filtersAndSelected$]).pipe(
                    map(([collection, { sortOption, selectedOptions }]) => {
                        const optionForSortingByNumberOfUses = templateOptionsFieldForSorting[this.parentTypeTab];

                        return {
                            sortOption,
                            optionType,
                            selectedOptions,
                            parentTypeTab: this.parentTypeTab,
                            optionsData: this.filtersService.sortOptionsData(
                                collection,
                                sortOption,
                                optionForSortingByNumberOfUses,
                            ),
                            isLoading: false,
                        };
                    }),
                );
            }),
            startWith(defaultViewModel),
        );
    }

    private getTemplateProjectsFilters(): Observable<{ sortOption: CollectionsSortObject; selectedOptions: string[] }> {
        return this.store.select(selectAuthenticatedUserId).pipe(
            tap(userId => (this.userId = userId)),
            switchMap(userId =>
                this.templateService.getCurrentUserAllTemplatesStoreFilters(userId).pipe(
                    map(allFilters => {
                        const filtersAndSort = allFilters[this.parentTypeTab] || initialDefaultFilterObject;
                        return {
                            sortOption: {
                                sortOrder:
                                    filtersAndSort?.collectionsSortOrder
                                    || initialDefaultFilterObject.collectionsSortOrder,
                                sortOption:
                                    filtersAndSort?.collectionsSortOption
                                    || initialDefaultFilterObject.collectionsSortOption,
                            },
                            selectedOptions: filtersAndSort[this.optionType] || [],
                        };
                    }),
                ),
            ),
        );
    }

    private getOptionsData(
        optionType: ProjectOptions | SessionOptions | ActivityOptions | CollectionOptions | null,
    ): Observable<ProjectOptionsType[] | SessionOptionsType[] | ActivityOptionsType[]> {
        switch (this.parentTypeTab) {
            case ParentType.ProjectTemplates:
                return this.projectOptionsService.getOptions(optionType as ProjectOptions);
            case ParentType.Templates:
                return this.sessionOptionsService.getOptions(optionType as SessionOptions);
            case ParentType.ActivityTemplates:
                return this.activityOptionsService.getOptions(ParentType.Activities);
            default:
                return this.projectOptionsService.getOptions(optionType as ProjectOptions);
        }
    }
}
