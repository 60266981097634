import { ChangeDetectionStrategy, Component, Inject, inject } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import { fromToDateValidator } from '@accenture/erp-deployment/shared/domain';
import { SessionForm, SessionOptions, sessionOptionsDialogTitle } from '@accenture/shared/data';
import { ImageInputMode, LoadedDescription } from '@accenture/shared/ui';

import { createSessionToUseActivityTemplate } from '../use-template-dialog/constants';
import { CreateSessionDialogTT9Facade } from './create-session-dialog-tt9-facade';

@Component({
    selector: 'accenture-create-session-dialog-tt9',
    templateUrl: './create-session-dialog-tt9.component.html',
    styleUrls: ['./create-session-dialog-tt9.component.scss'],
    providers: [CreateSessionDialogTT9Facade],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreateSessionDialogTT9Component {
    private facade = inject(CreateSessionDialogTT9Facade);
    private formBuilder = inject(FormBuilder);

    vm$ = this.facade.vm$;

    imageInputMode = ImageInputMode;
    loaderDescription = LoadedDescription.SessionCreating.toUpperCase();
    sessionForm: SessionForm = this.formBuilder.group(
        {
            name: new FormControl('', { nonNullable: true, validators: Validators.required }),
            description: new FormControl('', { nonNullable: true }),
            startDate: new FormControl(null),
            endDate: new FormControl(null),
            image: new FormControl(null),
        },
        {
            validators: fromToDateValidator('startDate', 'endDate'),
        },
    );

    constructor(
        @Inject(MAT_DIALOG_DATA)
        public data: {
            title: string;
            cancelButtonText: string;
            cancelButtonIcon?: string;
            backToSelectSourceTypeDialog: boolean;
            backToSelectSessionTypeDialog: boolean;
        },
    ) {}

    getTitle(currentOption: SessionOptions | null): string {
        if (currentOption) {
            return sessionOptionsDialogTitle[currentOption];
        }

        if (this.data.title) {
            return this.data.title;
        }
        return createSessionToUseActivityTemplate;
    }

    closeDialog(): void {
        this.facade.closeDialog(this.data.backToSelectSessionTypeDialog);
    }

    onClose(): void {
        this.closeDialog();
    }

    onSave(): void {
        const { name, description, startDate, endDate, image } = this.sessionForm.value;

        if (startDate || endDate) {
            this.facade.createSession({
                name,
                description,
                startDate,
                endDate,
                imageUrl: image?.url,
                imageId: image?.id,
            });
        } else {
            this.facade.createSession({ name, description, imageUrl: image?.url, imageId: image?.id });
        }
    }
}
