import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable, tap } from 'rxjs';

import { emptyScreenTitles, EmptyScreenType, inputPlaceholders, noItemsFoundImageUrl } from '@accenture/shared/data';
import { trackById } from '@accenture/shared/util';

import {
    AddSessionToProjectDialogFacade,
    AddSessionToProjectDialogViewModel,
} from './add-session-to-project-dialog-facade';

@Component({
    selector: 'accenture-add-session-to-project-dialog',
    templateUrl: './add-session-to-project-dialog.component.html',
    styleUrls: ['./add-session-to-project-dialog.component.scss'],
    providers: [AddSessionToProjectDialogFacade],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddSessionToProjectDialogComponent {
    vm$: Observable<AddSessionToProjectDialogViewModel> = this.facade.vm$.pipe(
        tap(({ searchValue }) => this.searchControl.patchValue(searchValue)),
    );
    constructor(@Inject(MAT_DIALOG_DATA) public data: any, private facade: AddSessionToProjectDialogFacade) {}

    emptyScreenType = EmptyScreenType;
    emptyScreenTitles = emptyScreenTitles;
    emptyScreenPicture = noItemsFoundImageUrl;
    inputPlaceholders = inputPlaceholders;
    searchControl = new FormControl<string>('', { nonNullable: true });
    selectedProjectId = '';
    trackById = trackById;

    closeDialog(): void {
        this.facade.closeDialog();
    }

    updateSearchValue(searchValue: string): void {
        this.facade.updateSearchValue(searchValue);
    }

    updateSelectedProject(projectId: string): void {
        this.selectedProjectId = projectId;
        this.facade.updateSelectedTemplates(projectId);
    }

    openUseTemplateModal(): void {
        this.facade.closeDialog();
        this.facade.openUseTemplateModal();
    }

    openCreateProjectDialog(): void {
        this.facade.closeDialog();
        this.facade.openCreateProjectDialog();
    }

    saveTemplateToProject(selectedProject: string): void {
        this.facade.saveTemplateToProject(selectedProject);
    }

    resetSearchValue(): void {
        this.searchControl.patchValue('');
        this.facade.updateSearchValue('');
    }
}
