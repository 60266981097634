import { Question } from '../question';
import { FileType } from '../responses';
import { ActivityItemType } from './activity-item-type';

export interface ImageTypeOptions {
    [id: string]: FileType;
}

export class ImageOptions {
    images: ImageTypeOptions;

    constructor(data: any) {
        this.images = data?.images || {};
    }

    createSerializableObject(): Record<string, unknown> {
        return {
            images: this.images,
        };
    }
}

export class Image extends Question {
    type = ActivityItemType.Image;

    options: ImageOptions;

    constructor(data: any) {
        super(data);
        const options = data.options ?? {};
        this.options = new ImageOptions(options);
    }

    createSerializableObject(): Record<string, unknown> {
        return {
            ...super.createSerializableObject(),
            options: this.options.createSerializableObject(),
        };
    }
}
