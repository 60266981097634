import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, combineLatest, from, of, skipWhile, takeUntil } from 'rxjs';
import { map, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';

import {
    Activity,
    NavigationTab,
    notFoundUrl,
    ParentType,
    privateParentTypes,
    Project,
    publicParentTypes,
    routerLinksMap,
    Session,
    TeamMember,
} from '@accenture/shared/data';

// eslint-disable-next-line @nx/enforce-module-boundaries
import { DialogService } from '@accenture/shared/ui';

import { selectAuthenticatedUserId } from '../auth/auth.selectors';
import { navigateHome } from '../router/router.actions';
import * as projectsActions from './project.actions';
import { selectParentTeamMember, selectSessionTeamMember } from './project.selectors';
import { ProjectDataService } from './project-data.service';

@Injectable()
export class ProjectEffects {
    // TODO: Remove this file along with project related code clean-up

    public getProjects$ = createEffect(() =>
        this.actions$.pipe(
            ofType(projectsActions.getProjectAndSessionsAndAttributes),
            switchMap(({ parentType, projectId }) =>
                combineLatest([
                    this.projectDataService.getProject(parentType, projectId).pipe(
                        tap(project => {
                            if (!project) {
                                this.store.dispatch(navigateHome({ navigationTab: NavigationTab.Projects }));
                            }
                        }),
                        catchError(() => {
                            this.store.dispatch(navigateHome({ navigationTab: NavigationTab.Projects }));
                            return of({} as Project);
                        }),
                    ),
                    this.projectDataService.getSessions(parentType, projectId),
                    this.projectDataService.getAttributes(parentType, projectId),
                    this.projectDataService.getAttributeClasses(parentType, projectId),
                ]).pipe(
                    takeUntil(this.actions$.pipe(ofType(projectsActions.clearProjectStoreSubscription))),
                    map(([project, sessions, attributes, attributeClasses]) => {
                        return projectsActions.getProjectAndSessionsAndAttributesSuccess({
                            project,
                            sessions,
                            attributes,
                            attributeClasses,
                        });
                    }),
                    catchError(() => {
                        return of(projectsActions.getProjectAndSessionsAndAttributesError());
                    }),
                ),
            ),
        ),
    );

    public getSession$ = createEffect(() =>
        this.actions$.pipe(
            ofType(projectsActions.getSession),
            switchMap(({ parentType, projectId, sessionId }) =>
                this.projectDataService.getSession(parentType, projectId, sessionId).pipe(
                    takeUntil(this.actions$.pipe(ofType(projectsActions.clearProjectStoreOfSessionSubscriptions))),
                    map((session: Session) => {
                        if (!session) {
                            [
                                ParentType.Projects,
                                ParentType.ProjectTemplates,
                                ParentType.PublicProjectTemplates,
                            ].includes(parentType)
                                ? this.router.navigate([routerLinksMap[parentType], projectId])
                                : this.router.navigate(['home']);
                        }
                        return projectsActions.getSessionSuccess({ session });
                    }),
                    catchError(() => {
                        parentType === ParentType.Projects
                            ? this.router.navigate([routerLinksMap[parentType], projectId])
                            : privateParentTypes.includes(parentType) || publicParentTypes.includes(parentType)
                            ? this.router.navigateByUrl(notFoundUrl)
                            : this.router.navigate(['home']);
                        return of(projectsActions.getSessionError());
                    }),
                ),
            ),
        ),
    );

    public getSessionActivities$ = createEffect(() =>
        this.actions$.pipe(
            ofType(projectsActions.getSessionActivities),
            switchMap(({ parentType, projectId, sessionId }) =>
                this.projectDataService
                    .getActivitiesByMultipleProperties(parentType, projectId, new Map([['sessionId', sessionId]]))
                    .pipe(
                        takeUntil(this.actions$.pipe(ofType(projectsActions.clearProjectStoreOfSessionSubscriptions))),
                        map((sessionActivities: Activity[]) =>
                            projectsActions.getSessionActivitiesSuccess({ sessionActivities }),
                        ),
                    ),
            ),
        ),
    );

    public getParentTeamMember$ = createEffect(() =>
        this.actions$.pipe(
            ofType(projectsActions.getParentTeamMember),
            withLatestFrom(this.store.select(selectAuthenticatedUserId)),
            switchMap(([{ parentType, parentId }, userId]) =>
                this.projectDataService.getTeamMemberByParentType(parentType, parentId, userId).pipe(
                    takeUntil(this.actions$.pipe(ofType(projectsActions.clearProjectStoreSubscription))),
                    map((teamMember: TeamMember) => {
                        const teamMemberData = projectsActions.getParentTeamMemberSuccess({ teamMember });
                        if (
                            (privateParentTypes.includes(parentType) || publicParentTypes.includes(parentType))
                            && (!teamMember || !!teamMember?.deleted)
                        ) {
                            this.dialogService.close();
                            this.router.navigateByUrl(notFoundUrl);
                        }

                        if (parentType == ParentType.Projects && !teamMember) {
                            this.router.navigate(['/home'], {
                                queryParams: {
                                    tab: NavigationTab.Dashboard,
                                },
                            });
                        }
                        return teamMemberData;
                    }),
                    catchError(() => {
                        privateParentTypes.includes(parentType) || publicParentTypes.includes(parentType)
                            ? this.router.navigateByUrl(notFoundUrl)
                            : this.router.navigate(['home']);

                        return of(projectsActions.getParentTeamMemberError());
                    }),
                ),
            ),
        ),
    );

    public getSessionTeamMember$ = createEffect(() =>
        this.actions$.pipe(
            ofType(projectsActions.getSessionTeamMember),
            withLatestFrom(this.store.select(selectAuthenticatedUserId)),
            switchMap(([{ parentType, parentId, sessionId }, userId]) => {
                return this.projectDataService
                    .getSessionTeamMemberByParent(parentType, parentId, sessionId, userId)
                    .pipe(
                        takeUntil(this.actions$.pipe(ofType(projectsActions.clearProjectStoreOfSessionSubscriptions))),
                        map((teamMember: TeamMember) => projectsActions.getSessionTeamMemberSuccess({ teamMember })),
                        catchError(() => of(projectsActions.getSessionTeamMemberError())),
                    );
            }),
        ),
    );

    public getSessionTeamMemberSnackBarsVisibility$ = createEffect(() =>
        this.actions$.pipe(
            ofType(projectsActions.getSessionTeamMemberSnackBarsVisibility),
            withLatestFrom(this.store.select(selectAuthenticatedUserId)),
            switchMap(([{ parentType, parentId, sessionId }, userId]) => {
                return this.projectDataService
                    .getSessionTeamMemberSnackBarsVisibility(userId, parentType, parentId, sessionId)
                    .pipe(
                        takeUntil(this.actions$.pipe(ofType(projectsActions.clearProjectStoreOfSessionSubscriptions))),
                        map(teamMemberSnackBarsVisibility =>
                            projectsActions.getSessionTeamMemberSnackBarsVisibilitySuccess({
                                teamMemberSnackBarsVisibility,
                            }),
                        ),
                    );
            }),
        ),
    );

    public setProjectTeamMemberOnlineState$ = createEffect(() =>
        this.actions$.pipe(
            ofType(projectsActions.setProjectTeamMemberOnlineState),
            switchMap(({ projectId, isOnline }) =>
                combineLatest([
                    this.store.select(selectAuthenticatedUserId),
                    this.store.select(selectParentTeamMember),
                ]).pipe(
                    skipWhile(([userId, teamMember]) => !userId || !teamMember.id),
                    take(1),
                    switchMap(async ([userId, teamMember]) => {
                        if (teamMember?.isOnline !== isOnline) {
                            await this.projectDataService.updateProjectTeamMember(projectId, userId, { isOnline });
                        }
                        return projectsActions.teamMemberOnlineStateChangedSuccess();
                    }),
                    catchError(() => of(projectsActions.teamMemberOnlineStateChangedError())),
                ),
            ),
        ),
    );

    public setSessionTeamMemberOnlineState$ = createEffect(() =>
        this.actions$.pipe(
            ofType(projectsActions.setSessionTeamMemberOnlineState),
            switchMap(({ projectId, sessionId, isOnline }) =>
                combineLatest([
                    this.store.select(selectAuthenticatedUserId),
                    this.store.select(selectSessionTeamMember),
                ]).pipe(
                    skipWhile(([userId, teamMember]) => !userId || !teamMember?.id),
                    take(1),
                    switchMap(async ([userId, teamMember]) => {
                        if (teamMember?.isOnline === isOnline) {
                            return projectsActions.teamMemberOnlineStateChangedSuccess();
                        }

                        await this.projectDataService.updateSessionTeamMember(projectId, sessionId, userId, {
                            isOnline,
                        });

                        return projectsActions.teamMemberOnlineStateChangedSuccess();
                    }),
                    catchError(() => of(projectsActions.teamMemberOnlineStateChangedError())),
                ),
            ),
        ),
    );

    public setTeamMemberOfflineState$ = createEffect(() =>
        this.actions$.pipe(
            ofType(projectsActions.setTeamMemberOfflineState),
            switchMap(({ projectId, sessionId }) =>
                this.store.select(selectAuthenticatedUserId).pipe(
                    skipWhile(userId => !userId),
                    take(1),
                    switchMap(userId =>
                        from(this.projectDataService.clearOnlineState(projectId, sessionId, userId)).pipe(
                            map(() => projectsActions.teamMemberOnlineStateChangedSuccess()),
                            catchError(() => of(projectsActions.teamMemberOnlineStateChangedError())),
                        ),
                    ),
                ),
            ),
        ),
    );

    public setTemplateTeamMemberOnlineState$ = createEffect(() =>
        this.actions$.pipe(
            ofType(projectsActions.setTemplateTeamMemberOnlineState),
            switchMap(({ templateId, parentType, isOnline }) =>
                this.store.select(selectAuthenticatedUserId).pipe(
                    skipWhile(userId => !userId),
                    take(1),
                    switchMap(async userId => {
                        await this.projectDataService.updateParentTeamMember(
                            templateId,
                            userId,
                            { isOnline },
                            parentType,
                        );
                        return projectsActions.templateTeamMemberOnlineStateChangedSuccess();
                    }),
                    catchError(() => of(projectsActions.templateTeamMemberOnlineStateChangedError())),
                ),
            ),
        ),
    );

    constructor(
        private actions$: Actions,
        private projectDataService: ProjectDataService,
        private store: Store,
        private router: Router,
        private dialogService: DialogService,
    ) {}
}
