<div
  *ngIf="{ loading: isLoading$ | async, deleteLoading: isDeleteLoading$ | async } as data"
  class="image-container {{ !isBarChart ? mode : 'none' }}"
  [ngClass]="{
    'has-image': !!imageUrl && !data.loading,
    'disable-overlay': !imageUrl && readonly && isBarChart,
    'disable-hover': disableHover(),
    skeleton: data.loading
  }"
  [ngStyle]="!data.loading && { 'background-image': imageUrl }"
>
  <div *ngIf="hasPlaceholder && !data.loading && !isBarChart" class="image-placeholder" (click)="uploadImage()">
    <!-- TODO: isCircularMode - move at separated mode -->
    <img
      [src]="
        isCircularMode ? '/assets/shared-ui/svg/empty-image-input.svg' : imageInputPlaceholdersUrl[imagePlaceholder]
      "
      alt="Empty Image"
      [ngClass]="{
        'ballot-img-placeholder': isBallotImage && !imageUrl
      }"
    />
  </div>

  <div *ngIf="!data.loading">
    <accenture-icon
      *ngIf="canEditVoteActivity && !imageUrl && isIconMode"
      class="image-icon-upload d-flex-center"
      (click)="uploadImage()"
    >
      icon-image
    </accenture-icon>
  </div>

  <div class="overlay" [class.active]="isOverlayActive" (click)="onOverlayClick()">
    <div class="overlay-wrapper" *ngIf="!isIconMode">
      <accenture-icon *ngIf="!readonly" class="image-container-action-btn d-flex-center" (click)="uploadImage()">
        icon-image
      </accenture-icon>

      <accenture-icon *ngIf="imageUrl" class="image-container-action-btn d-flex-center" (click)="openPanel($event)">
        icon-fullscreen
      </accenture-icon>

      <accenture-icon
        *ngIf="imageUrl && !readonly"
        class="image-container-action-btn d-flex-center"
        (click)="deleteImage($event)"
      >
        icon-delete
      </accenture-icon>
    </div>

    <div *ngIf="isIconMode" class="overlay-wrapper">
      <accenture-icon
        *ngIf="!readonly && !isVoteActivity"
        class="image-container-action-btn d-flex-center"
        (click)="uploadImage()"
      >
        icon-image
      </accenture-icon>

      <accenture-icon
        *ngIf="!canEditVoteActivity && imageUrl && !readonly"
        class="image-container-action-btn d-flex-center"
        (click)="openPanel($event)"
      >
        icon-fullscreen
      </accenture-icon>

      <accenture-icon
        *ngIf="showIconMore()"
        [matMenuTriggerFor]="editInViewOptions"
        #menuOptions="matMenuTrigger"
        class="image-container-action-btn d-flex-center"
        [class.active]="isOverlayActive"
        (menuOpened)="toggleIconMore(true)"
        (menuClosed)="toggleIconMore(false)"
      >
        icon-more
      </accenture-icon>

      <accenture-icon
        *ngIf="imageUrl && !readonly && !isVoteActivity"
        class="image-container-action-btn d-flex-center"
        (click)="deleteImage($event)"
      >
        icon-delete
      </accenture-icon>
    </div>
  </div>

  <mat-menu class="tt9-menu" #editInViewOptions="matMenu" xPosition="before">
    <div class="vote-actions-menu-divider">
      <button mat-menu-item (click)="openPanel($event)">
        <accenture-icon [colorType]="iconColor.Default" class="action-button-icon">icon-fullscreen </accenture-icon>
        Preview
      </button>
      <button mat-menu-item (click)="uploadImage(true)">
        <accenture-icon [colorType]="iconColor.Default" class="action-button-icon">icon-edit </accenture-icon>
        Edit
      </button>
      <button mat-menu-item (click)="deleteImage($event)">
        <accenture-icon [colorType]="iconColor.Default" class="action-button-icon">icon-delete </accenture-icon>
        Delete
      </button>
    </div>
  </mat-menu>

  <accenture-loading
    *ngIf="data.deleteLoading"
    class="gs-global-spinner-large"
    [loaderSize]="loaderSize"
  ></accenture-loading>
</div>
