import { Whiteboard } from './whiteboard';
import {
    Activity,
    ActivityType,
    Brainstorm,
    LiveShare,
    Present,
    Proompt,
    QuickPoll,
    Spark,
    TableActivity,
    Vote,
} from './';

export class ActivityFactory {
    static create(id: string, data: Partial<Activity>): Activity | null {
        switch (data.type) {
            case ActivityType.Vote:
                return new Vote(id, data as Partial<Vote>);
            case ActivityType.Brainstorm:
                return new Brainstorm(id, data as Partial<Brainstorm>);
            case ActivityType.Present:
                return new Present(id, data as Partial<Present>);
            case ActivityType.QuickPoll:
                return new QuickPoll(id, data as Partial<QuickPoll>);
            case ActivityType.Table:
                return new TableActivity(id, data as Partial<TableActivity>);
            case ActivityType.LiveShare:
                return new LiveShare(id, data as Partial<LiveShare>);
            case ActivityType.Proompt:
                return new Proompt(id, data as Partial<Proompt>);
            case ActivityType.Spark:
                return new Spark(id, data as Partial<Spark>);
            case ActivityType.Whiteboard:
                return new Whiteboard(id, data as Partial<Whiteboard>);
            default:
                console.error('invalid graph node type', data.type);
                return null;
        }
    }
}
