<ng-container *ngIf="vm$ | async as vm">
  <ng-container *accentureLoaded="!vm.isLoading">
    <ng-container *accentureLoaded="!vm.isAnalyzing; description: 'Analyzing'">
      <accenture-base-dialog *ngIf="!vm.isAnalyzing" [title]="data.title">
        <div class="d-flex-column">
          <!-- Choose Prompt -->
          <ng-container *ngIf="vm.displayState === DisplayState.ChoosePrompt">
            <!-- Input Controls -->
            <div class="d-flex analyze-container">
              <!-- Graphic -->
              <div class="p-24">
                <img class="source-image" src="/assets/erp-shared-ui/svg/analyze-prompts.svg" alt="Analyze" />
              </div>
              <!-- /Graphic -->
              <!-- Prompts -->
              <div class="prompt p-24">
                <mat-radio-group class="group mb-24" [(ngModel)]="analyzePromptId">
                  <p class="tt9-h3 mb-16">Select an AI Prompt</p>

                  <mat-radio-button
                    *ngFor="let analyzePrompt of vm.analyzePrompts; let i = index"
                    class="analyze-responses-radio-button tt9-radio-button option mt-12"
                    color="primary"
                    [value]="analyzePrompt.id"
                  >
                    {{ analyzePrompt.display }}
                  </mat-radio-button>
                </mat-radio-group>
              </div>
              <!-- /Prompts -->
            </div>
            <div class="d-flex-end">
              <!-- Analyze Button -->
              <div class="p-16">
                <button class="tt9-btn" [disabled]="!analyzePromptId" (click)="onAnalyze()">
                  {{ analyzeButtonTitle }}
                </button>
              </div>
              <!-- /Analyze Button -->
            </div>
            <!-- /Input Controls -->
          </ng-container>
          <!-- /Choose Prompt -->

          <!-- Analysis -->
          <ng-container *ngIf="vm.displayState === DisplayState.ShowResults">
            <div *ngIf="vm.results.analysis.length > 0" class="d-flex-column analyze-container p-32">
              <!-- Results Header -->
              <div class="tt9-h3 pb-32">Results</div>
              <!-- /Results Header -->
              <!-- Results Container -->
              <div class="d-flex-column results-container">
                <!-- Line Container -->
                <div class="d-flex pb-16" *ngFor="let result of vm.results.analysis; let i = index">
                  <!-- /Custom Bullet -->
                  <div class="d-flex-center tt9-caption-1 results-container-number">
                    {{ i + 1 }}
                  </div>
                  <!-- /Custom Bullet -->
                  <!-- Result Item -->
                  <div class="d-flex-center tt9-body-1 pl-8">
                    {{ result }}
                  </div>
                  <!-- /Result Item -->
                </div>
                <!-- /Line Container -->
              </div>
              <!-- /Results Container -->
            </div>
            <div *ngIf="vm.error">
              <!-- Error handling -->
              <div class="d-flex-center tt9-h4 p-32">
                Unable to analyze results at this time, please try again later
              </div>
              <!-- /Error handling -->
            </div>
            <div class="d-flex-end p-16">
              <div>
                <button class="tt9-btn-default" (click)="onBack(vm.error)">< Back</button>
              </div>
              <div class="pl-8">
                <button class="tt9-btn" (click)="onCopy(vm.results.analysis)">Copy Results</button>
              </div>
            </div>
          </ng-container>
          <!-- /Analysis -->
        </div>
      </accenture-base-dialog>
    </ng-container>
  </ng-container>
</ng-container>
