import { Pipe, PipeTransform } from '@angular/core';

import { displayUserClaim, UserClaim } from '@accenture/shared/data';

@Pipe({
    name: 'displayUserRoles',
})
export class DisplayUserRolesPipe implements PipeTransform {
    transform(userRoles: Record<string, boolean>, join = false): string | string[] {
        const roles: string[] = Object.keys(userRoles || {})
            .filter(
                (role) => userRoles[role] && role !== UserClaim.ProjectCreator && role !== UserClaim.CollectionCreator,
            )
            .map((role) => displayUserClaim[role as UserClaim]);
        return join ? roles.join(', ') : roles;
    }
}
