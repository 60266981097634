import { Timestamp } from 'firebase/firestore';

import { AccessControl } from './access-control';
import { Dictionary } from './dictionary';
import { MeetingReference } from './meeting';
import { NodeTemplate, NodeType } from './node-template';
import { NodeTimestamp } from './node-timestamp';
import { SessionStatus, sessionStatusToDisplay } from './session-status';
import { UpdatedByUser } from './user';

export enum SessionSearchCriteria {
    SessionId = 'sessionId',
    SessionName = 'sessionName',
}

export const sessionSearchCriteriaMap = {
    [SessionSearchCriteria.SessionId]: 'Session id',
    [SessionSearchCriteria.SessionName]: 'Session name',
};

export const initialSessionSearchObject = {
    searchByControl: SessionSearchCriteria.SessionId,
};
export class Session extends NodeTemplate {
    access: AccessControl;
    clientId: string;
    projectId?: string;
    sequence: string;
    teamMembersCount: number;
    creatorId?: string;
    creatorName?: string;
    creatorImage?: string;
    startDate?: Timestamp;
    endDate?: Timestamp;
    phase?: Dictionary<string>; // [phaseId]: phaseName
    client?: Dictionary<string>; // [clientId]: clientName
    project?: Dictionary<string>; // [projectId]: projectName
    subPhase?: Dictionary<string>; // [subPhaseId]: subPhaseName
    status: SessionStatus;
    tags: Dictionary<string>; // [tagId]: tagName
    selfNavigate?: boolean;
    displayAgenda?: boolean;
    sessionThreadDisabled?: boolean;
    sessionThreadIsAnonymous?: boolean;
    isOptionsPanelOpened?: boolean;
    votedActivityCount?: Dictionary<number>;
    metadata?: Dictionary<string>;
    favoritesCount?: number;
    facilitationNotes?: string;

    // for public session templates
    timesUsed?: number;
    creatorNotes?: string;
    featured?: boolean;
    approved?: boolean;
    updatedBy?: UpdatedByUser;

    meeting?: MeetingReference;

    // administration
    disableChangeOwnership?: boolean;
    disableChangeOwnershipBy?: string;

    constructor(id: string, data: Partial<Session>) {
        super(id, data, NodeType.Session);

        this.creatorName = data.creatorName ?? '';
        this.access = this.isAccess(data.access) ? data.access : {};
        this.clientId = data.clientId ?? '';
        this.projectId = data.projectId ?? '';
        this.sequence = data.sequence ?? '';
        this.tags = (data.tags as Dictionary<string>) ?? {};
        this.creatorId = data.creatorId ?? '';
        this.creatorImage = data.creatorImage ?? '';
        this.startDate = data.startDate || null;
        this.endDate = data.endDate || null;
        this.phase = (data.phase as Dictionary<string>) ?? {};
        this.subPhase = (data.subPhase as Dictionary<string>) ?? {};
        this.client = (data.client as Dictionary<string>) ?? {};
        this.project = (data.project as Dictionary<string>) ?? {};
        this.selfNavigate = data.selfNavigate ?? false;
        this.displayAgenda = data.displayAgenda ?? false;
        this.sessionThreadDisabled = data.sessionThreadDisabled ?? false;
        this.sessionThreadIsAnonymous = data.sessionThreadIsAnonymous ?? false;
        this.status = [SessionStatus.Draft, SessionStatus.Active].includes(data.status as SessionStatus)
            ? (data.status as SessionStatus)
            : SessionStatus.Draft;
        this.teamMembersCount = data.teamMembersCount ?? 1;
        this.votedActivityCount = data.votedActivityCount ?? {};
        this.metadata = data.metadata ?? {};
        this.timesUsed = data.timesUsed ?? 0;
        this.favoritesCount = data.favoritesCount ?? 0;
        this.creatorNotes = data.creatorNotes ?? '';
        this.updatedBy = data.updatedBy ?? undefined;
        this.facilitationNotes = data.facilitationNotes ?? '';
        if (!!data.meeting) {
            this.meeting = data.meeting;
        }
        this.disableChangeOwnership = !!data.disableChangeOwnership;
        this.disableChangeOwnershipBy = data.disableChangeOwnershipBy ?? '';
    }

    createSerializableObject(): Record<string, unknown> {
        return {
            name: this.name,
            description: this.description,
            imageUrl: this.imageUrl,
            imageId: this.imageId,
            access: this.access,
            created: this.created,
            sequence: this.sequence,
            creatorId: this.creatorId,
            creatorName: this.creatorName,
            creatorImage: this.creatorImage,
            startDate: this.startDate,
            endDate: this.endDate,
            updated: this.updated,
            status: this.status,
            selfNavigate: this.selfNavigate,
            displayAgenda: this.displayAgenda,
            projectId: this.projectId,
            tags: this.tags,
            phase: this.phase,
            subPhase: this.subPhase,
            client: this.client,
            project: this.project,
            teamMembersCount: this.teamMembersCount,
            metadata: this.metadata,
            facilitationNotes: this.facilitationNotes,
            sessionThreadDisabled: this.sessionThreadDisabled,
            sessionThreadIsAnonymous: this.sessionThreadIsAnonymous,
        };
    }

    private isAccess(access: unknown): access is AccessControl {
        return typeof access === 'object';
    }
}

export class SessionSummary extends NodeTimestamp {
    id: string;
    name: string;
    tags?: Dictionary<string>;
    status: (typeof sessionStatusToDisplay)[keyof typeof sessionStatusToDisplay];
    sessionId?: string;
    markedForDelete?: string;
    created: Timestamp;
    updated: Timestamp;

    constructor(data: Partial<SessionSummary>) {
        super(data);
        this.id = data.sessionId ?? data.id;
        this.sessionId = data.sessionId ?? data.id;
        this.name = data.name;
        this.tags = (data.tags as Dictionary<string>) ?? {};
        this.created = data.created ?? null;
        this.updated = data.updated ?? null;
        this.status = sessionStatusToDisplay[data.status || SessionStatus.Draft];
        this.markedForDelete = data.markedForDelete ?? null;
    }
}
