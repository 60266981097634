import {
    ParentType,
    templateTypes,
} from './parent-type';
import { ProjectOptions } from './project-options';
import { SessionOptions } from './session-options';
import { TeamMemberSnackBarsVisibilityOptions } from './team-member';
import { TeamMemberFilterType } from './team-member-filter-and-sort';

export enum ConfigurationFeature {
    FeatureToggles = 'FEATURE_TOGGLES',
    Maintenance = 'MAINTENANCE',
    Timeouts = 'TIMEOUTS',
    TermsOfUse = 'TERMS',
    Support = 'SUPPORT',
}

export class DBPathHelper {
    static getParentPath(parentType: ParentType, parentId?: string): string {
        return `${parentType}` + (parentId ? `/${parentId}` : '');
    }

    //TODO DELETE WHEN COLLECTION FEATURE WILL BE FINISHED
    static getSessionPath(parentType: ParentType, parentId?: string, sessionId?: string): string {
        return templateTypes.includes(parentType)
            ? `${parentType}` + (sessionId ? `/${sessionId}` : `/${parentId}`)
            : `${parentType}/${parentId}/sessions` + (sessionId ? `/${sessionId}` : '');
    }

    static getCollectionPath(collectionId?: string): string {
        return `${ParentType.Collections}` + (collectionId ? `/${collectionId}` : '');
    }

    static getCollectionSessionsPath(id?: string): string {
        return 'collectionSessions' + (id ? `/${id}` : '');
    }

    static getSessionPathNew(parentType: ParentType, sessionId?: string): string {
        return `${parentType}` + (sessionId ? `/${sessionId}` : '');
    }

    static getTeamMemberFiltersPath(
        parentType: ParentType,
        parentId: string,
        userId: string,
        sessionId: string,
        filterName: TeamMemberFilterType
    ): string {
        return `${parentType}/${parentId}/sessions/${sessionId}/teamMembers/${userId}/filters/${filterName}`;
    }

    static getTeamMemberFiltersPathNew(
        parentType: ParentType,
        parentId: string,
        userId: string,
        filterName: TeamMemberFilterType
    ): string {
        return `${parentType}/${parentId}/teamMembers/${userId}/filters/${filterName}`;
    }

    static getSessionTeamMemberSnackBarsVisibilityPath(
        parentType: ParentType,
        parentId: string,
        userId: string,
        sessionId: string,
        snackBarName?: TeamMemberSnackBarsVisibilityOptions
    ): string {
        return (
            `${parentType}/${parentId}/sessions/${sessionId}/teamMembers/${userId}/snackBarsVisibility` +
            (snackBarName ? `/${snackBarName}` : '')
        );
    }

    static getTeamMemberPath(parentType: ParentType, parentId: string, userId?: string): string {
        return `${parentType}/${parentId}/teamMembers` + (userId ? `/${userId}` : '');
    }

    static getOwnershipHistoryPath(parentType: ParentType, parentId: string, userId?: string): string {
        return `${parentType}/${parentId}/ownershipHistory` + (userId ? `/${userId}` : '');
    }

    static getActivityUserOptionsPath(
        parentType: ParentType,
        parentId: string,
        activityId: string,
        userId: string
    ): string {
        return `${parentType}/${parentId}/teamMembers/${userId}/activityUserOptions/${activityId}`;
    }

    // TODO delete in https://thinktankco.atlassian.net/browse/TT9-6456
    static getSessionFilterPath(parentType: ParentType, parentId: string, userId: string): string {
        return `${parentType}/${parentId}/teamMembers/${userId}/filters/sessionsFilters`;
    }

    static getUserSessionFilterPath(userId: string): string {
        return `users/${userId}/filters/sessionsFilters`;
    }

    // TODO: delete after project deprecation
    static getSessionFocusPath(
        projectId: string,
        sessionId?: string,
        parentType: ParentType = ParentType.Projects
    ): string {
        const basePath = this.getSessionPath(parentType, projectId, sessionId);
        const adjustedPath = sessionId ? basePath : `${basePath}/sessions`;
        return `${adjustedPath}/focus` + (sessionId ? `/${sessionId}` : '');
    }

    static getSessionFocusPathNew(sessionId: string): string {
        return `${this.getSessionPathNew(ParentType.Sessions, sessionId)}/focus/${sessionId}`;
    }

    //TODO: Delete after project deprecation
    static getSessionTeamMemberPath(
        parentType: ParentType,
        parentId: string,
        sessionId: string,
        userId?: string
    ): string {
        return `${parentType}/${parentId}/sessions/${sessionId}/teamMembers` + (userId ? `/${userId}` : '');
    }

    static getSessionTeamMemberPathNew(parentType: ParentType, sessionId: string, userId?: string): string {
        return `${parentType}/${sessionId}/teamMembers` + (userId ? `/${userId}` : '');
    }

    static getActivityConfigurationPath(): string {
        return 'activityConfigurations';
    }

    // TODO: Delete after project deprecation
    static getActivityPath(parentType: ParentType, parentId: string, activityId?: string): string {
        return [ParentType.ActivityTemplates, ParentType.PublicActivityTemplates].includes(parentType)
            ? `${parentType}` + (activityId ? `/${activityId}` : '')
            : `${parentType}/${parentId}/activities` + (activityId ? `/${activityId}` : '');
    }

    static getActivityPathNew(parentType: ParentType, sessionId: string, activityId?: string): string {
        return `${parentType}/${sessionId}/activities` + (activityId ? `/${activityId}` : '');
    }

    static getActivityItemPath(parentType: ParentType, parentId: string, activityItemId?: string): string {
        return `${parentType}/${parentId}/activityItems` + (activityItemId ? `/${activityItemId}` : '');
    }

    static getResponsesPath(parentType: ParentType, parentId: string, responseId?: string): string {
        return `${parentType}/${parentId}/responses` + (responseId ? `/${responseId}` : '');
    }

    // Unused params will be cleaned up after repointing all data flow connection-related features.
    static getDataFlowConnectionPath(parentType: ParentType, parentId: string, connectionId?: string): string {
        return `dataFlowConnections` + (connectionId ? `/${connectionId}` : '');
    }

    static getNotAcceptedPath(parentType: ParentType, parentId: string, docId?: string): string {
        return `${parentType}/${parentId}/notAccepted` + (docId ? `/${docId}` : '');
    }

    static getValidationFeedbackPath(parentType: ParentType, parentId: string, docId?: string): string {
        return `${parentType}/${parentId}/validationFeedback` + (docId ? `/${docId}` : '');
    }

    static getNotePath(parentType: ParentType, parentId: string, noteId?: string): string {
        return `${parentType}/${parentId}/notes` + (noteId ? `/${noteId}` : '');
    }

    static getBrainstormGroupPath(parentType: ParentType, parentId: string, brainstormGroupId?: string): string {
        return `${parentType}/${parentId}/sourceGroups` + (brainstormGroupId ? `/${brainstormGroupId}` : '');
    }

    static getCommentPath(parentType: ParentType, parentId: string, commentId?: string): string {
        return `${parentType}/${parentId}/comments` + (commentId ? `/${commentId}` : '');
    }

    static getSessionThreadsPath(parentType: ParentType, parentId: string, sessionThreadId?: string): string {
        return `${parentType}/${parentId}/sessionThreads` + (sessionThreadId ? `/${sessionThreadId}` : '');
    }

    static getSessionThreadsReactionsPath(parentType: ParentType, parentId: string, reactionId?: string): string {
        return `${parentType}/${parentId}/sessionThreadsReactions` + (reactionId ? `/${reactionId}` : '');
    }

    static getUpvotePath(parentType: ParentType, parentId: string, upvoteId?: string): string {
        return `${parentType}/${parentId}/upvotes` + (upvoteId ? `/${upvoteId}` : '');
    }

    static getSessionRecordingsPath(sessionId: string, recordingId?: string): string {
        return `sessions/${sessionId}/recordings` + (recordingId ? `/${recordingId}` : '');
    }

    static getSessionRecordingDisclaimerPath(sessionId: string, disclaimerId?: string): string {
        return `sessions/${sessionId}/disclaimers` + (disclaimerId ? `/${disclaimerId}` : '');
    }

    static getSessionRecordingConsentPath(sessionId: string, consentId?: string): string {
        return `sessions/${sessionId}/consents` + (consentId ? `/${consentId}` : '');
    }

    static getRecordingPath(parentType: ParentType, parentId: string, sessionId: string): string {
        return `${parentType}/${parentId}/sessions/${sessionId}/recordings`;
    }

    static getAttributesPath(parentType: ParentType, parentId: string, attributeId?: string): string {
        return `${parentType}/${parentId}/attributes` + (attributeId ? `/${attributeId}` : '');
    }

    static getAttributesClassesPath(parentType: ParentType, parentId: string, attributeClassId?: string): string {
        return `${parentType}/${parentId}/attributeClasses` + (attributeClassId ? `/${attributeClassId}` : '');
    }

    static getTableColumnPath(parentType: ParentType, parentId: string, columnId?: string): string {
        return `${parentType}/${parentId}/tableColumns` + (columnId ? `/${columnId}` : '');
    }

    static getTableRowPath(parentType: ParentType, parentId: string, rowId?: string): string {
        return `${parentType}/${parentId}/tableRows` + (rowId ? `/${rowId}` : '');
    }

    static getUserPath(userId?: string): string {
        return 'users' + (userId ? `/${userId}` : '');
    }

    static getProjectPath(id?: string): string {
        return this.getParentPath(ParentType.Projects, id);
    }

    static getProjectTeamMemberPath(projectId: string, userId: string): string {
        return this.getTeamMemberPath(ParentType.Projects, projectId, userId);
    }

    static getAdminProjectsFilterPath(userId?: string): string {
        return `/users/${userId}/filters/adminProjectsFilters`;
    }

    static getAdminSessionsFilterPath(userId?: string): string {
        return `users/${userId}/filters/adminSessionsFilters`;
    }

    static getUserAccessPath(userAccessId?: string): string {
        return 'userAccess' + (userAccessId ? `/${userAccessId}` : '');
    }

    static getUserCollectionsPath(id?: string): string {
        return 'userCollections' + (id ? `/${id}` : '');
    }

    static getUserTemplatesPath(id?: string): string {
        return 'userTemplates' + (id ? `/${id}` : '');
    }

    static getPublicAccessPath(publicAccessId?: string): string {
        return 'publicAccess' + (publicAccessId ? `/${publicAccessId}` : '');
    }

    static getLinkAccessPath(linkAccessId?: string): string {
        return 'linkAccess' + (linkAccessId ? `/${linkAccessId}` : '');
    }

    static getPublicAccessLinkAccessPasswordPath(publicAccessId: string, linkAccessPasswordId: string): string {
        return `publicAccess/${publicAccessId}/linkAccessPasswords/${linkAccessPasswordId}`;
    }

    static getFavoriteAccessPath(favoriteAccessId?: string): string {
        return 'favoriteAccess' + (favoriteAccessId ? `/${favoriteAccessId}` : '');
    }

    static getFavoriteFilterPath(userId: string): string {
        return `users/${userId}/filters/favoriteFilters`;
    }

    static getProjectOptionsTypePath(projectOptionsType: ProjectOptions, projectOptionsId?: string): string {
        return `/${projectOptionsType}` + (projectOptionsId ? `/${projectOptionsId}` : '');
    }

    static getSessionOptionsTypePath(sessionOptionsType: SessionOptions, sessionOptionsId?: string): string {
        return [SessionOptions.Phase, SessionOptions.SubPhase].includes(sessionOptionsType)
            ? `/${sessionOptionsType}s` + (sessionOptionsId ? `/${sessionOptionsId}` : '')
            : `/${sessionOptionsType}` + (sessionOptionsId ? `/${sessionOptionsId}` : '');
    }

    static getPhasesPath(phaseId?: string): string {
        return '/phases' + (phaseId ? `/${phaseId}` : '');
    }

    static getSubPhasesPath(subPhaseId?: string): string {
        return '/subPhases' + (subPhaseId ? `/${subPhaseId}` : '');
    }

    static getTagsPath(tagId?: string): string {
        return '/tags' + (tagId ? `/${tagId}` : '');
    }

    static getClientNamesPath(clientId?: string): string {
        return '/clientNames' + (clientId ? `/${clientId}` : '');
    }

    static getProjectNamesPath(projectId?: string): string {
        return '/projectNames' + (projectId ? `/${projectId}` : '');
    }

    static getClientsPath(clientId?: string): string {
        return '/clients' + (clientId ? `/${clientId}` : '');
    }

    static getFilesRefsPath(fileRefId?: string): string {
        return '/filesRefs' + (fileRefId ? `/${fileRefId}` : '');
    }

    static getFileUploadsPath(jobId: string): string {
        return `fileUploads/${jobId}`;
    }

    static getConfigurationPath(featureName?: string): string {
        return 'configuration' + (featureName ? `/${featureName}` : '');
    }

    static getFeatureTogglePath(): string {
        return `${this.getConfigurationPath(ConfigurationFeature.FeatureToggles)}`;
    }

    static getTermsOfServicePath(): string {
        return `${this.getConfigurationPath(ConfigurationFeature.TermsOfUse)}`;
    }

    static getSupportConfigurationPath(): string {
        return `${this.getConfigurationPath(ConfigurationFeature.Support)}`;
    }

    static getRateLimitPath(rateLimitId?: string): string {
        return `/rateLimits` + (rateLimitId ? `/${rateLimitId}` : '');
    }

    static getBotConversationPath(userId: string, messageId?: string): string {
        return `${this.getUserPath(userId)}/botChat${messageId ? `/${messageId}` : ''}`;
    }

    static getBotAttachmentPath(userId: string, attachmentId?: string): string {
        return `${this.getUserPath(userId)}/botAttachments${attachmentId ? `/${attachmentId}` : ''}`;
    }

    static getBotEmbeddingsPath(userId: string, embeddingId?: string): string {
        return `${this.getUserPath(userId)}/botEmbeddings${embeddingId ? `/${embeddingId}` : ''}`;
    }

    static getInAppNotificationsPath(notificationId?: string): string {
        return 'inAppNotifications' + (notificationId ? `/${notificationId}` : '');
    }

    static getInAppNotificationsFiltersPath(userId: string): string {
        return `users/${userId}/filters/inAppNotificationsFilters`;
    }

    static getLogsPath(logId?: string): string {
        return 'logs' + (logId ? `/${logId}` : '');
    }

    static getAIPromptPath(promptId?: string): string {
        return 'aiPrompts' + (promptId ? `/${promptId}` : '');
    }

    static getPromptLibraryPath(promptLibraryId?: string): string {
        return '/promptLibrary' + (promptLibraryId ? `/${promptLibraryId}` : '');
    }

    static getAIPromptInstructionPath(promptId?: string): string {
        return 'aiPromptsInstructions' + (promptId ? `/${promptId}` : '');
    }

    static getMeetingPath(meetingId?: string): string {
        return `meetings${meetingId ? `/${meetingId}` : ''}`;
    }

    static getUserAdministrationProjectFilterPath(parentId: string, userId: string): string {
        return `projects/${parentId}/teamMembers/${userId}/filters/userAdministrationProjectFilters`;
    }

    static getUserManagementProjectFilterPath(parentId: string, userId: string): string {
        return `projects/${parentId}/teamMembers/${userId}/filters/userManagementProjectFilters`;
    }

    static getTeamsBotPath(botId?: string): string {
        return `bots${botId ? `/${botId}` : ''}`;
    }

    static getTeamsBotConversationPath(botId: string, conversationId?: string): string {
        return `${this.getTeamsBotPath(botId)}/conversations${conversationId ? `/${conversationId}` : ''}`;
    }

    static getTeamsBotActivityPath(botId: string, conversationId: string, id?: string): string {
        return `${this.getTeamsBotConversationPath(botId, conversationId)}/activities${id ? `/${id}` : ''}`;
    }

    static getTeamsBotAttachmentPath(botId: string, conversationId: string, id?: string): string {
        return `${this.getTeamsBotConversationPath(botId, conversationId)}/attachments${id ? `/${id}` : ''}`;
    }

    static getUserManagementSessionFilterPath(parentId: string, sessionId: string, userId: string): string {
        return `projects/${parentId}/sessions/${sessionId}/teamMembers/${userId}/filters/userManagementSessionFilters`;
    }

    static getSessionThreadUserConfigPath(userId: string): string {
        return `users/${userId}/configurations/sessionThread`;
    }

    static getLinkAccessPasswordPath(parentId: string, linkAccessId: string): string {
        return `projects/${parentId}/linkAccessPasswords/${linkAccessId}`;
    }

    static getLinkAccessPasswordPathNew(parentId: string, linkAccessId: string): string {
        return `${ParentType.Sessions}/${parentId}/linkAccessPasswords/${linkAccessId}`;
    }

    static getSessionActivityPath(parentId: string, activityId: string): string {
        return `${ParentType.Sessions}/${parentId}/activities/${activityId}`;
    }

    static getDataFlowConnectionsPath(sessionId: string): string {
        return `${ParentType.Sessions}/${sessionId}/dataFlowConnections`;
    }

    static getUpvotesPath(sessionId: string): string {
        return `${ParentType.Sessions}/${sessionId}/upvotes`;
    }

    static getCollectionsFilterPath(userId: string): string {
        return `users/${userId}/filters/collectionsFilters;`;
    }

    static getTeamMembersPath(sessionId: string): string {
        return `${ParentType.Sessions}/${sessionId}/teamMembers`;
    }

    static getUserSessionsPath(userSessionId?: string): string {
        return `userSessions` + (userSessionId ? `/${userSessionId}` : '');
    }

    static getUserManagementSessionFilterPathNew(sessionId: string, userId: string): string {
        return `${ParentType.Sessions}/${sessionId}/teamMembers/${userId}/filters/userManagementSessionFilters`;
    }

    static getDashboardSessionsFilterPath(userId: string): string {
        return `users/${userId}/filters/dashboardSessionsFilters`;
    }
}
