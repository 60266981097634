export const useTemplateStepOne = 'Step 1 of 2';
export const useTemplateStepTwo = 'Step 2 of 2';
export const useTemplateSessionTitle = 'Use session template';
export const useTemplateActivityTitle = 'Use activity template';
export const addActivityTemplateToSessionTitle = 'Select session to use activity template';
export const addSessionToProjectTitle = 'Select project to use session template';
export const addSessionToActivityTitle = 'Select project to use activity template';
export const createProjectToUseSessionTemplate = 'Create project to use session template';
export const createProjectToUseActivityTemplate = 'Create project to use activity template';
export const createSessionToUseActivityTemplate = 'Create session to use activity template';
