import { Question } from '../question';
import { FileType } from '../responses';
import { ActivityItemType } from './activity-item-type';

export interface AttachmentTypeOptions {
    [id: string]: FileType;
}

export class AttachmentOptions {
    attachments: AttachmentTypeOptions;

    constructor(data: any) {
        this.attachments = data?.attachments || {};
    }

    createSerializableObject(): Record<string, unknown> {
        return {
            attachments: this.attachments,
        };
    }
}

export class Attachment extends Question {
    type = ActivityItemType.Attachment;

    options: AttachmentOptions;

    constructor(data: any) {
        super(data);
        const options = data.options ?? {};
        this.options = new AttachmentOptions(options);
    }

    createSerializableObject(): Record<string, unknown> {
        return {
            ...super.createSerializableObject(),
            options: this.options.createSerializableObject(),
        };
    }
}
