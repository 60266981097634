import { Injectable } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { Timestamp } from 'firebase/firestore';
import { BehaviorSubject, combineLatest, firstValueFrom, Observable, of } from 'rxjs';
import { filter, map, startWith, switchMap, take } from 'rxjs/operators';

import {
    InvitationObject,
    InvitedUsersService,
    ProjectService,
    SessionService,
} from '@accenture/erp-deployment/shared/domain';
import {
    AppState,
    selectActivityIdAndParentIds,
    selectAuthenticatedUserId,
    selectProjectId,
} from '@accenture/global-store';
import {
    errorMessageSnackbarText,
    InvitedUser,
    invitingTeamMembersErrorSnackbarTitle,
    invitingTeamMembersInProgressSnackbarTitle,
    ParentType,
    Project,
    ProjectRole,
    projectTeamMembersAreBeingInvitedSnackbarText,
    projectTeamMembersHaveBeenInvitedSnackbarText,
    Session,
    teamMembersInvitedSnackbarTitle,
} from '@accenture/shared/data';
import { FirestoreService } from '@accenture/shared/data-access';
import { SnackbarService, SnackBarTypes } from '@accenture/shared/ui';

import { SessionDeleteSnackBarComponent } from '../session-delete-snackbar/session-delete-snack-bar.component';
import { MembersInvitationsDialogComponent } from './members-invitations-dialog.component';

export interface MembersInvitationsDialogViewModel {
    sessions: Session[];
    isInviteButtonDisabled: boolean;
    isUIBlocked: boolean;
    isLoading: boolean;
}

const defaultViewModel = {
    sessions: [],
    isInviteButtonDisabled: false,
    isUIBlocked: false,
    isLoading: true,
};

@Injectable()
export class MembersInvitationsDialogFacade {
    vm$ = this.buildViewModel();

    private userId!: string;
    private projectId!: string;
    private invitationObjectSubject$ = new BehaviorSubject<InvitationObject>({
        chipsObject: {
            [ProjectRole.Admin]: [],
            [ProjectRole.Member]: [],
        },
        adminMessage: '',
        memberMessage: '',
        invitedUsersEmails: [],
    });
    private uiBlockerSubject$ = new BehaviorSubject<boolean>(false);
    private deletedSessionNames: string[] = [];
    private sessionIds: string[] = [];
    private dialogOpeningDate = Timestamp.now().toMillis();

    constructor(
        public dialogRef: MatDialogRef<MembersInvitationsDialogComponent>,
        private store: Store<AppState>,
        private firestoreService: FirestoreService,
        private invitedUsersService: InvitedUsersService,
        private sessionService: SessionService,
        private snackbarService: SnackbarService,
        private projectService: ProjectService,
    ) {}

    async setNewInvitedEmails(): Promise<void> {
        const newInvitedEmails: InvitedUser[] = [];
        const { invitedUsersEmails } = this.invitationObjectSubject$.getValue();
        const invitedUsersEmailsInApplication = await firstValueFrom(
            this.invitedUsersService.getInvitedUsersEmails(this.userId).pipe(take(1)),
        );

        for (const currentInvitedUserEmail of invitedUsersEmails) {
            if (!invitedUsersEmailsInApplication.includes(currentInvitedUserEmail)) {
                const invitedUserEmail: InvitedUser = {
                    invitingUserId: this.userId,
                    email: currentInvitedUserEmail,
                };

                newInvitedEmails.push(invitedUserEmail);
            }
        }

        if (newInvitedEmails.length) {
            await this.invitedUsersService.setInvitedUsers(newInvitedEmails);
        }
    }

    setInvitationObject(invitationObject: InvitationObject) {
        this.invitationObjectSubject$.next(invitationObject);
    }

    async invite(): Promise<void> {
        this.snackbarService.showSnackBar(
            invitingTeamMembersInProgressSnackbarTitle,
            projectTeamMembersAreBeingInvitedSnackbarText,
            SnackBarTypes.Info,
            false,
        );
        this.dialogRef.close();
        try {
            await this.setNewInvitedEmails();
            await this.inviteTeamMembers();
            this.deletedSessionNames.length
                ? this.snackbarService.showCustomSnackBar(
                      SessionDeleteSnackBarComponent,
                      { dataList: this.deletedSessionNames },
                      SnackBarTypes.Warning,
                  )
                : this.snackbarService.showSnackBar(
                      teamMembersInvitedSnackbarTitle,
                      projectTeamMembersHaveBeenInvitedSnackbarText,
                      SnackBarTypes.Success,
                      true,
                  );
        } catch (e) {
            this.snackbarService.showSnackBar(
                invitingTeamMembersErrorSnackbarTitle,
                errorMessageSnackbarText,
                SnackBarTypes.Error,
                true,
            );
            console.error(e);
        }
    }

    async inviteTeamMembers(): Promise<void> {
        const { chipsObject, adminMessage, memberMessage, invitedSessions } = this.invitationObjectSubject$.getValue();
        const env = window.location.origin;
        const environmentName = window.location.host;
        const callbacks = [];

        if (chipsObject[ProjectRole.Admin].length) {
            callbacks.push(
                this.firestoreService.cloudFunctionCallable('accessProvisioningNew', {
                    env,
                    emails: chipsObject[ProjectRole.Admin],
                    role: ProjectRole.Admin,
                    comment: adminMessage,
                    parentType: ParentType.Projects,
                    projectIds: [this.projectId],
                }),
            );
        }

        invitedSessions.forEach(member => {
            if (member.sessionsIds.length) {
                callbacks.push(
                    this.firestoreService.cloudFunctionCallable('accessProvisioningNew', {
                        env,
                        environmentName,
                        sessionIds: member.sessionsIds,
                        role: member.role,
                        emails: [member.email],
                        isBulkInvite: member.sessionsIds.length > 1,
                        comment: memberMessage,
                        parentType: ParentType.Sessions,
                        projectIds: [this.projectId],
                    }),
                );
            } else {
                this.firestoreService.cloudFunctionCallable('accessProvisioningNew', {
                    env,
                    emails: [member.email],
                    role: ProjectRole.Member,
                    comment: adminMessage,
                    parentType: ParentType.Projects,
                    projectIds: [this.projectId],
                });
            }
        });

        await Promise.all(callbacks);
    }

    private buildViewModel(): Observable<MembersInvitationsDialogViewModel> {
        const project$: Observable<Project> = this.store
            .select(selectProjectId)
            .pipe(
                switchMap(projectId =>
                    projectId ? this.projectService.getProject(ParentType.Projects, projectId) : of({} as Project),
                ),
            );
        return combineLatest([this.store.select(selectAuthenticatedUserId), project$, this.getSessions()]).pipe(
            filter(([userId, project]) => !!userId && !!project.id),
            switchMap(([userId, project, sessions]) => {
                if (!!project?.markedForDelete) {
                    this.dialogRef.close();
                }

                this.userId = userId;
                this.projectId = project.id;

                return combineLatest([this.uiBlockerSubject$, this.invitationObjectSubject$]).pipe(
                    map(([isUIBlocked, { invitedUsersEmails }]) => {
                        const isInviteButtonDisabled = !invitedUsersEmails.length || isUIBlocked;

                        return {
                            sessions,
                            isInviteButtonDisabled,
                            isUIBlocked,
                            isLoading: false,
                        };
                    }),
                );
            }),
            startWith(defaultViewModel),
        );
    }

    private getSessions(): Observable<Session[]> {
        return this.store.select(selectActivityIdAndParentIds).pipe(
            switchMap(({ parentId, parentType }) => this.sessionService.getSessions(parentType, parentId)),
            map(sessions => {
                this.deletedSessionNames = sessions
                    .filter(
                        ({ markedForDelete }) =>
                            !!markedForDelete && markedForDelete.toMillis() >= this.dialogOpeningDate,
                    )
                    .map(({ name }) => name);
                const availableSessions = sessions.filter(({ markedForDelete }) => !markedForDelete);
                this.sessionIds = availableSessions.map(({ id }) => id);
                return [...availableSessions];
            }),
        );
    }
}
