<ng-container *ngIf="vm$ | async as vm">
  <ng-container *accentureLoaded="!vm.isLoading">
    <div class="templates-list">
      <ng-container *ngIf="vm.isAnyTemplate; else emptyTemplates">
        <div class="content-actions">
          <div class="d-flex-center">
            <ng-container *ngFor="let tab of templateTabs">
              <div
                class="tt9-navigation-tab"
                [ngClass]="{ active: tab === vm.activeTemplateTab }"
                (click)="setTemplateTab(tab)"
              >
                <p class="tt9-input-text-small">{{ tab }}</p>
              </div>
            </ng-container>
          </div>

          <div class="content-actions-search ml-40">
            <mat-form-field
              appearance="outline"
              class="tt9-form-field no-label no-margin width-100"
              floatLabel="always"
            >
              <input
                #search
                matInput
                [formControl]="searchControl"
                [placeholder]="inputPlaceholders.search"
                (input)="setSearchValue(search.value)"
              />
              <accenture-icon matPrefix>icon-search</accenture-icon>
              <accenture-icon *ngIf="vm.searchValue && !search.disabled" matSuffix (click)="clearSearchValue($event)"
                >icon-close</accenture-icon
              >
            </mat-form-field>
          </div>

          <button
            class="tt9-btn-icon-filled tt9-btn-applied"
            matTooltipPosition="above"
            matTooltipClass="tt9-arrow-tooltip-below"
            [matTooltip]="tooltipTexts.filter"
            [class.active]="vm.isFilterAndSortPanelOpened"
            [disabled]="vm.isFilterAndSortPanelOpened || !vm.templatesOfCurrentTabCount"
            (click)="toggleFilterAndSortPanel(true, $event)"
          >
            <accenture-icon>icon-filter</accenture-icon>

            <div *ngIf="vm.isFiltersApplied && vm?.templatesOfCurrentTabCount" class="applied"></div>
          </button>
        </div>

        <div class="quick-filters">
          <accenture-template-store-quick-filters></accenture-template-store-quick-filters>
        </div>

        <ng-container *ngIf="vm.templates?.length; else emptyTemplates">
          <div class="main-data">
            <div
              *ngFor="let template of vm.templates; trackBy: trackById; let i = index"
              class="card-container"
              (mouseenter)="hoverTemplateCard(i)"
              (mouseleave)="unhoverTemplateCard(i)"
            >
              <accenture-template-card
                [template]="template"
                [hoveredCardIndex]="hoveredCardIndex | async"
                [isTemplatesPage]="true"
                [isAllTemplateTab]="vm.activeTemplateTab === templateTab.All"
                [isFavorite]="!!vm.favoritesTemplatesMap?.[template.templateId]"
                [templateIndex]="i"
                [userId]="vm.userId"
                [showDeepLinks]="vm.showDeepLinks"
                (useTemplate)="useTemplate($event)"
                (saveAsTemplate)="saveAsTemplate($event)"
                (updateFavorite)="onUpdateFavorite($event)"
                (editTemplate)="editTemplate($event, vm.isEditPending)"
                (openForPreview)="openForPreview($event)"
                (copyLinkAccessToClipboard)="copyLinkAccessToClipboard($event)"
                (openMenu)="menuOpened = true"
                (closeMenu)="menuOpened = false"
              ></accenture-template-card>
            </div>
          </div>
        </ng-container>
      </ng-container>

      <ng-template #emptyTemplates>
        <div class="empty-screen">
          <accenture-empty-screen
            [title]="getEmptyScreenTitle(vm)"
            [imageUrl]="emptyScreenImageUrlStore"
          ></accenture-empty-screen>
        </div>
      </ng-template>
    </div>

    <!-- Filter and Sort side panel -->
    <accenture-side-panel
      *ngIf="vm.isFilterAndSortPanelOpened"
      cdkOverlayOrigin
      #origin="cdkOverlayOrigin"
      [panelTrigger]="origin"
      [panelTitle]="'Filter & Sort'"
      [panelOpened]="vm.isFilterAndSortPanelOpened"
      (closePanel)="toggleFilterAndSortPanel(false)"
    >
      <accenture-templates-store-filters-and-sort></accenture-templates-store-filters-and-sort>
    </accenture-side-panel>
    <!-- / Filter and Sort side panel -->

    <!-- Template preview side panel -->
    <accenture-side-panel
      *ngIf="vm.previewedTemplate"
      cdkOverlayOrigin
      #origin="cdkOverlayOrigin"
      [useDefaultTemplate]="false"
      [panelTrigger]="origin"
      [panelOpened]="!!vm.previewedTemplate"
      (closePanel)="closePreviewPanel($event)"
    >
      <accenture-template-preview
        [isTemplateOwner]="vm.previewedTemplate.ownerId === vm.userId"
        [isEditPending]="vm.isEditPending"
        [isFavoriteTemplate]="!!vm.favoritesTemplatesMap?.[vm.previewedTemplate.templateId]"
        [hasUnsavedChanges]="!!vm.previewedTemplate.draftTemplateId"
        [canEditTemplate]="vm.canEditTemplate"
        (useTemplateAction)="useTemplate(vm.previewedTemplate)"
        (saveToMyTemplatesAction)="saveAsTemplate(vm.previewedTemplate)"
        (addToFavoritesAction)="onUpdateFavorite(vm.previewedTemplate)"
        (editTemplateAction)="editTemplate(vm.previewedTemplate)"
        (toggleTeamMembersPanel)="openTeamMembersPanel($event)"
      ></accenture-template-preview>
    </accenture-side-panel>
    <!-- /Template preview side panel -->

    <!--Project template team members side panel -->
    <accenture-side-panel
      *ngIf="vm.isTemplateTeamPanelOpened"
      #origin="cdkOverlayOrigin"
      cdkOverlayOrigin
      [panelTrigger]="origin"
      [panelOpened]="vm.isTemplateTeamPanelOpened"
      [useDefaultTemplate]="false"
      (closePanel)="closeTeamMembersPanel($event)"
    >
      <accenture-template-team-members-panel
        [showBackArrow]="true"
        [publicAccess]="vm.previewedTemplate"
        (navigateBack)="navigateBack($event)"
        (closePanel)="closeTeamMembersPanel($event)"
      ></accenture-template-team-members-panel>
    </accenture-side-panel>
    <!--Project template team members side panel -->
  </ng-container>
</ng-container>
