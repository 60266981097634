import { ProomptResponse } from './proompt-response';

export interface SparkAction {
    type: string;
    activityType?: string;
    targetActivityId?: string;
}

export class SparkResponse extends ProomptResponse {
    action?: SparkAction;
    constructor(response: Partial<SparkResponse>) {
        super(response);
        this.action = response.action;
    }

    createSerializableObject(): Record<string, unknown> {
        return {
            activityItemId: this.activityItemId,
            activityId: this.activityId,
            sessionId: this.sessionId,
            userId: this.userId,
            value: this.value,
            action: this.action,
            prompt: this.prompt,
            created: this.created,
            updated: this.updated,
        };
    }
}
