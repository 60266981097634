import { Injectable } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

import { AppState, selectFeatureToggles } from '@accenture/global-store';
import { BotActions, FeatureToggleName } from '@accenture/shared/data';
import { DialogService } from '@accenture/shared/ui';

import { AddSessionTt9DialogComponent } from '../add-session-tt9-dialog/add-session-tt9-dialog.component';
import { AiGenerateModalComponent } from '../ai-generate-modal/ai-generate-modal.component';
import { CreateSessionDialogTT9Component } from '../create-session-dialog-tt9/create-session-dialog-tt9.component';
import { SelectSessionSourceTypeDialogComponent } from './select-session-source-type-dialog.component';

export interface CreateSessionSourceTypeDialogModel {
    dialogTitle: string;
    isLoading: boolean;
    showAiGenerate: boolean;
}

const defaultViewModel: CreateSessionSourceTypeDialogModel = {
    dialogTitle: '',
    isLoading: true,
    showAiGenerate: true,
};

@Injectable()
export class SelectSessionSourceTypeDialogFacade {
    vm$ = this.buildViewModel();

    constructor(
        private store: Store<AppState>,
        private dialogService: DialogService,
        private dialogRef: MatDialogRef<SelectSessionSourceTypeDialogComponent>,
    ) {}

    createNewDialog(): void {
        this.openCreateNewSessionDialog();
    }

    selectTemplateDialog(): void {
        this.openSelectSessionTemplateDialog();
    }

    openAiGenerateDialog(): void {
        this.dialogRef.close();

        this.dialogService.open(AiGenerateModalComponent, {
            title: 'Generate session using AI',
            panelClass: 'tt9-modal',
            width: '768px',
            disableClose: true,
            botAction: BotActions.CreateSession,
        });
    }

    private buildViewModel(): Observable<CreateSessionSourceTypeDialogModel> {
        return combineLatest([this.store.select(selectFeatureToggles)]).pipe(
            map(([featureToggles]) => {
                const showAiGenerate = featureToggles?.[FeatureToggleName.AiGenerateAvailable];
                return {
                    showAiGenerate,
                    isLoading: false,
                    dialogTitle: 'Create session',
                } as CreateSessionSourceTypeDialogModel;
            }),
            startWith(defaultViewModel),
        );
    }

    private openCreateNewSessionDialog(): void {
        this.dialogRef.close();

        this.dialogService.open(CreateSessionDialogTT9Component, {
            title: 'Create session',
            width: '768px',
            cancelButtonText: 'Back',
            cancelButtonIcon: 'icon-chevron-left',
            backToSelectSourceTypeDialog: true,
            panelClass: 'tt9-modal',
        });
    }

    private openSelectSessionTemplateDialog(): void {
        this.dialogRef.close();

        this.dialogService.open(AddSessionTt9DialogComponent, {
            width: '768px',
            panelClass: 'tt9-modal',
        });
    }
}
