<form class="color-input-form d-flex" [formGroup]="colorForm">
  <div
    class="color-icon cursor-pointer"
    [style.background-color]="selectedColor || unusedColor"
    (click)="colorSelect.toggle()"
  ></div>

  <accenture-icon class="color-select-input-trigger cursor-pointer" matSuffix (click)="colorSelect.toggle()">
    {{ colorSelect.panelOpen ? "icon-chevron-up" : "icon-chevron-down" }}
  </accenture-icon>

  <mat-select
    #colorSelect
    id="color-select-input"
    class="color-select-input"
    formControlName="color"
    [disableOptionCentering]="true"
    [panelClass]="['tt9-select-panel-custom color-input-select-panel']"
    (selectionChange)="updateColor($event)"
  >
    <mat-option
      *ngFor="let value of colors; trackBy: trackByValue"
      class="color-input-component-select-option"
      [value]="value"
    >
      <div class="group-color" [style.background-color]="value"></div>
    </mat-option>
  </mat-select>
</form>
