import { Pipe, PipeTransform } from '@angular/core';

import { UserStatus, userStatusesMap } from '@accenture/shared/data';

@Pipe({
    name: 'displayUserStatus',
})
export class DisplayUserStatusPipe implements PipeTransform {
    transform(status: UserStatus): unknown {
        return userStatusesMap[status] || userStatusesMap[UserStatus.Inactive];
    }
}
