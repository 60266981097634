<nav *ngIf="vm$ | async as vm" class="tt9-breadcrumb-container breadcrumbs">
  <ul class="tt9-breadcrumbs">
    <li *ngIf="vm.isProject && !vm.isAdministrationProject" class="breadcrumb">
      <a class="breadcrumb-link" [routerLink]="['/home']" [queryParams]="{ tab: 'Projects' }">Projects</a>
    </li>
    <li *ngIf="vm.isSessionTemplate" class="breadcrumb">
      <a class="breadcrumb-link" [routerLink]="['/home']" [queryParams]="{ tab: 'Templates' }">Session Templates</a>
    </li>
    <li *ngIf="vm.isActivityTemplate" class="breadcrumb">
      <a class="breadcrumb-link" [routerLink]="['/home']" [queryParams]="{ tab: 'Templates' }">Activity Templates</a>
    </li>
    <li *ngIf="vm.isProjectTemplate" class="breadcrumb">
      <a class="breadcrumb-link" [routerLink]="['/home']" [queryParams]="{ tab: 'Templates' }">Project Templates</a>
    </li>
    <li *ngIf="vm.isAdminUser" class="breadcrumb">
      <a class="breadcrumb-link" [routerLink]="['/admin/users']">Users</a>
    </li>
    <li *ngIf="vm.isAdministrationProject" class="breadcrumb">
      <a class="breadcrumb-link" [routerLink]="['/admin/projects']">Projects</a>
    </li>

    <!-- project/project template -->
    <li *ngIf="vm.project?.id && !vm.isAdministrationProject" class="breadcrumb">
      <a
        #projectName
        class="breadcrumb-link"
        matTooltipPosition="below"
        matTooltipClass="tt9-arrow-tooltip-above"
        [matTooltip]="vm.project.name"
        [matTooltipDisabled]="isTooltipHidden"
        [routerLink]="getProjectLink(vm.parentType, vm.project.id)"
        (mouseover)="isDisabledTooltip(vm.project.name)"
      >
        {{ sliceTextWithEllipsis(vm.project?.name, breadcrumbsDisplayLimit) }}
      </a>
    </li>
    <!-- /project/project template -->

    <!-- session/session template -->
    <li *ngIf="vm.session?.id" class="breadcrumb">
      <a
        #sessionName
        class="breadcrumb-link"
        matTooltipPosition="below"
        matTooltipClass="tt9-arrow-tooltip-above"
        [matTooltip]="vm.session.name"
        [matTooltipDisabled]="isTooltipHidden"
        [routerLink]="getSessionLink(vm.parentType, vm.project?.id, vm.session?.id)"
        (mouseover)="isDisabledTooltip(vm.session.name)"
      >
        {{ sliceTextWithEllipsis(vm.session?.name, breadcrumbsDisplayLimit) }}
      </a>
    </li>
    <!-- /session/session template -->

    <li *ngIf="vm.isTeamPage" class="breadcrumb">
      <a #team class="breadcrumb-link"> {{ vm.session?.id ? "Session" : "Project" }} Team </a>
    </li>

    <!-- activity/activity template -->
    <li *ngIf="vm.activity?.id" class="breadcrumb">
      <a
        #activityName
        class="breadcrumb-link"
        matTooltipPosition="below"
        matTooltipClass="tt9-arrow-tooltip-above"
        [matTooltip]="vm.activity.name"
        [matTooltipDisabled]="isTooltipHidden"
        [routerLink]="getActivityLink(vm.parentType, vm.project?.id, vm.session?.id, vm.activity)"
        (mouseover)="isDisabledTooltip(vm.activity.name)"
      >
        {{ sliceTextWithEllipsis(vm.activity?.name, breadcrumbsDisplayLimit) }}
      </a>
    </li>
    <!-- /activity/activity template -->

    <!-- Admin User -->
    <li *ngIf="vm.user?.id" class="breadcrumb">
      <a
        #adminUserName
        class="breadcrumb-link"
        matTooltipPosition="below"
        matTooltipClass="tt9-arrow-tooltip-above"
        [matTooltip]="vm.user.displayName"
        [matTooltipDisabled]="isTooltipHidden"
        [routerLink]="getUserLink(vm.user.id)"
        (mouseover)="isDisabledTooltip(vm.user.displayName)"
      >
        {{ sliceTextWithEllipsis(vm.user?.displayName, breadcrumbsDisplayLimit) }}
      </a>
    </li>
    <!-- Admin User -->

    <!-- Admin User Project -->
    <li *ngIf="vm.isAdminUser && vm.userProject" class="breadcrumb">
      <a
        #adminUserProject
        class="breadcrumb-link"
        matTooltipPosition="below"
        matTooltipClass="tt9-arrow-tooltip-above"
        [matTooltip]="vm.userProject.name"
        [matTooltipDisabled]="isTooltipHidden"
        [routerLink]="getUserProjectLink(vm.user?.id, vm.projectId)"
        (mouseover)="isDisabledTooltip(vm.userProject?.name)"
      >
        {{ sliceTextWithEllipsis(vm.userProject?.name, breadcrumbsDisplayLimit) }}
      </a>
    </li>
    <!-- Admin User Project -->

    <!-- Administration Project Details -->
    <li *ngIf="vm.isAdministrationProject && vm.userProject" class="breadcrumb">
      <a
        class="breadcrumb-link"
        matTooltipPosition="below"
        matTooltipClass="tt9-arrow-tooltip-above"
        [matTooltip]="vm.userProject.name"
        [matTooltipDisabled]="isTooltipHidden"
        [routerLink]="getAdministrationProjectLink(vm.projectId)"
        (mouseover)="isDisabledTooltip(vm.userProject?.name)"
      >
        {{ sliceTextWithEllipsis(vm.userProject?.name, breadcrumbsDisplayLimit) }}
      </a>
    </li>
    <!-- Administration Project Details -->

    <!-- postfix -->
    <li *ngIf="postfix" class="breadcrumb">
      <a class="breadcrumb-link">{{ postfix }}</a>
    </li>
    <!-- /postfix -->
  </ul>
</nav>
