import { Inject, Injectable } from '@angular/core';
import { IAccount, IVisitor, NgxPendoService } from 'ngx-pendo';

import { FredEnvironment, User } from '@accenture/shared/data';

@Injectable({
    providedIn: 'root',
})
export class PendoService {
    constructor(
        @Inject('environment') private environment: FredEnvironment,
        private ngxPendoService: NgxPendoService,
    ) {}

    initPendo(user: User): void {
        if (this.environment.pendo.sendData) {
            if (user) {
                const userEmailArr = (user.email || '').split('@');
                const visitor = {
                    id: user.id,
                    email: user.email || '',
                    email_domain: userEmailArr[1] || '',
                    location: user.location || '',
                    title: user.title || '',
                } as IVisitor;
                const account = {
                    id: this.environment.name, // Highly recommended
                } as IAccount;

                try {
                    this.ngxPendoService.initialize(visitor, account);
                } catch (e) {
                    console.log('PendoService::initPendo - problems with initialization', e);
                }
            } else {
                console.log('PendoService::initPendo - no user to initialize Pendo');
            }
        } else {
            console.log('PendoService::initPendo - no data will be sent to Pendo');
        }
    }
}
