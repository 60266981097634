import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import { CollectionSession, DefaultCollectionColor, UserSession } from '@accenture/shared/data';
import { IconSize } from '@accenture/shared/ui';

import { RemoveFromCollectionDialogueFacade } from './remove-from-collection-dialogue-facade';
@Component({
    selector: 'accenture-remove-from-collection-dialogue',
    templateUrl: './remove-from-collection-dialogue.component.html',
    styleUrls: ['./remove-from-collection-dialogue.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [RemoveFromCollectionDialogueFacade],
})
export class RemoveFromCollectionDialogueComponent {
    vm$ = this.facade.vm$;
    title = 'Remove from collection';
    defaultCollectionColor = DefaultCollectionColor;
    iconSize = IconSize;
    maxToolTipCharacter = 40;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: { session: CollectionSession | UserSession; collectionName: string },
        private facade: RemoveFromCollectionDialogueFacade,
    ) {}

    async removeFromCollection(): Promise<void> {
        await this.facade.removeFromCollection();
    }

    closeDialog(): void {
        this.facade.closeDialog();
    }

    ngOnInit(): void {
        this.facade.updateSessionData(this.data);
    }
}
