import { BreakpointObserver } from '@angular/cdk/layout';
import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { combineLatest, firstValueFrom, Observable, of, Subject } from 'rxjs';
import { filter, map, startWith, switchMap } from 'rxjs/operators';

import {
    ConfigurationService,
    FeatureToggleName,
    InAppNotificationsService,
} from '@accenture/erp-deployment/shared/domain';
import {
    AppState,
    isActivityPage,
    isAdminPage,
    isHomePage,
    isLogin,
    isProfilePage,
    isSessionPage,
    isSingUp,
    selectActivityIdAndParentIds,
    selectAdminRoute,
    selectAuthenticatedUser,
    selectAuthStateError,
    selectCurrentTab,
    selectFeatureToggles,
    selectIsAdministrationSession,
    selectSessionData,
    selectSessionTeamMemberData,
    setTeamMemberOfflineStateSession,
    signOut,
    toggleSideNavigationDisplay,
} from '@accenture/global-store';
import {
    AuthenticationTab,
    breakpoints,
    NavigationTab,
    ParentType,
    Session,
    TeamMember,
    User,
} from '@accenture/shared/data';
import { UserService } from '@accenture/shared/data-access';
import {
    ConfirmationDialogComponent,
    DialogService,
    InfoDialogComponent,
    SnackbarService,
    SnackBarTypes,
} from '@accenture/shared/ui';

import { FredHeaderStore } from '../component-stores/fred-header.store';
import { ContactSupportDialogComponent } from '../contact-support-dialog/contact-support-dialog.component';
import { ManageGuestAccountSnackbarComponent } from '../manage-guest-account-snackbar/manage-guest-account-snackbar.component';
import { deactivateMode, deleteNotifications } from './constants';

@Injectable()
export class TopNavigationBarFacade {
    user: any; // firebase.User | null;
    dbUser: User;
    authError: string | null;
    tab: NavigationTab;
    authenticationTab: AuthenticationTab;
    showSignUp: boolean;
    showAiConcierge: boolean;
    isActivityPage: boolean;
    isMobileScreen: boolean;
    isDesktopScreen: boolean;
    screenHandset: boolean;
    isTabletScreenAll: boolean;
    notificationsCount: string;
    totalNotificationsCount: number;
    isLoading: boolean;
    helpMenuAvailable: boolean;
}

const defaultViewModel = {
    user: null as any,
    dbUser: null as unknown as User,
    authError: null,
    tab: NavigationTab.Home,
    authenticationTab: AuthenticationTab.SingUp,
    showSignUp: false,
    showAiConcierge: false,
    isActivityPage: false,
    isMobileScreen: false,
    isDesktopScreen: false,
    screenHandset: false,
    isTabletScreenAll: false,
    notificationsCount: '',
    totalNotificationsCount: 0,
    isLoading: true,
    helpMenuAvailable: false,
} as TopNavigationBarFacade;

@Injectable()
export class TopNavigationFacade {
    vm$ = this.buildViewModel();

    private logoutSubject = new Subject<void>();
    private isSessionPage!: boolean;
    private sessionId!: string;
    private activityId!: string;
    private userId!: string;
    private isGuestUserLoginWasHandled = false;
    private user!: User;
    private isAdminPageTabs!: boolean;

    constructor(
        private router: Router,
        private afAuth: AngularFireAuth, // TODO: get from service
        private store: Store<AppState>,
        private componentStore: FredHeaderStore,
        private dialogService: DialogService,
        private breakpointObserver: BreakpointObserver,
        private userService: UserService,
        private inAppNotificationsService: InAppNotificationsService,
        private configurationService: ConfigurationService,
        private snackbarService: SnackbarService,
    ) {}

    async logout(): Promise<void> {
        if (this.isSessionPage) {
            await this.store.dispatch(
                setTeamMemberOfflineStateSession({
                    sessionId: this.sessionId,
                }),
            );
        }
        this.store.dispatch(signOut());
        localStorage.setItem('usedGreetingIndices', '');
        this.logoutSubject.next();
    }

    setTab(tab: NavigationTab): void {
        this.componentStore.setTab(tab);
    }

    setAuthenticationTab(tab: AuthenticationTab): void {
        this.componentStore.setAuthenticationTab(tab);
    }

    navigateHome(): void {
        this.router.navigate(['/home']);
        return;
    }

    async navigateBack(): Promise<void> {
        if (this.sessionId && this.activityId) {
            const teamMember = await firstValueFrom(this.store.select(selectSessionTeamMemberData));
            const session = await firstValueFrom(this.store.select(selectSessionData));

            if (teamMember.isSessionLeader || session.selfNavigate) {
                this.router.navigate(['dashboard', ParentType.Sessions, this.sessionId]);
            } else {
                if (this.user?.guest) {
                    this.router.navigate(['dashboard', 'home']);
                } else {
                    this.router.navigate(['dashboard', ParentType.Sessions]);
                }
            }

            return;
        }

        // for admin page
        if (this.isAdminPageTabs) {
            this.router.navigate(this.router.url.split('/').splice(0, 4));
            return;
        }

        // TODO: will be updated for activity also
        this.router.navigate(['dashboard', 'home']);
        return;
    }

    navigateAuthentication(tab: AuthenticationTab): void {
        this.setAuthenticationTab(tab);

        const getRoute = tab === AuthenticationTab.Login ? '/authentication' : '/authentication/registerUser';
        this.router.navigate([getRoute]);
    }

    openContactSupportDialog(): void {
        this.dialogService.open(ContactSupportDialogComponent, {
            panelClass: 'tt9-modal',
            width: '768px',
        });
    }

    SessionInfo(): Observable<{
        session: Session;
        teamMember: TeamMember;
    }> {
        return combineLatest([
            this.store.select(selectSessionData),
            this.store.select(selectSessionTeamMemberData),
        ]).pipe(
            map(([session, teamMember]) => {
                if (session?.id) {
                    this.sessionId = session?.id;
                }
                return { session, teamMember };
            }),
        );
    }

    navigateDeactivation(): void {
        this.router.navigate(['authentication', deactivateMode]);
    }

    updateUser(user: Partial<User>): void {
        this.userService.updateUserData(this.userId, user);
    }

    markAllNotificationsAsRead(): void {
        this.inAppNotificationsService.markAllNotificationsAsRead(this.userId);
    }

    deleteAllNotifications(): void {
        this.dialogService.open(ConfirmationDialogComponent, {
            title: deleteNotifications.title,
            confirmBtnText: 'Delete',
            cancelBtnText: 'Cancel',
            width: '445px',
            text: deleteNotifications.text,
            isWarning: true,
            confirm: async () => {
                this.inAppNotificationsService.deleteAllNotifications(this.userId);
            },
        });
    }

    async openTermsOfUse(): Promise<void> {
        const { text, title } = await firstValueFrom(this.configurationService.getTermsOfUse());
        this.dialogService.open(InfoDialogComponent, {
            title,
            text,
            panelClass: 'tt9-modal',
            width: '768px',
        });
    }

    toggleSideNavigationDisplay() {
        this.store.dispatch(toggleSideNavigationDisplay());
    }

    openManageGuestAccountSnackbar(): void {
        this.snackbarService.showCustomSnackBar(
            ManageGuestAccountSnackbarComponent,
            {
                goToLoginAction: () => {
                    this.logout();
                },
            },
            SnackBarTypes.Info,
            0,
            'manage-guest-account-snack-bar',
        );
    }

    private buildViewModel(): Observable<TopNavigationBarFacade> {
        return combineLatest([
            this.afAuth.user,
            this.store.select(selectAuthenticatedUser),
            this.store.select(selectAuthStateError),
            this.store.select(selectCurrentTab),
            this.store.select(isHomePage),
            this.store.select(isLogin),
            this.store.select(isSingUp),
            this.store.select(isSessionPage),
            this.store.select(isProfilePage),
            this.store.select(isAdminPage),
            this.store.select(isActivityPage),
            this.store.select(selectActivityIdAndParentIds),
            this.store.select(selectFeatureToggles),
            this.componentStore.tab$,
            this.componentStore.authenticationTab$,
            this.breakpointObserver.observe(breakpoints.screenAllMobiles),
            this.breakpointObserver.observe(breakpoints.screenAllDesktops),
            this.breakpointObserver.observe(breakpoints.screenHandsetPortrait),
            this.breakpointObserver.observe(breakpoints.screenTablet),
            this.getNotificationsCount(),
            this.getTotalNotificationsCount(),
            this.store.select(selectAdminRoute),
            this.store.select(selectIsAdministrationSession),
        ]).pipe(
            filter((user) => !!user),
            map(
                ([
                    user,
                    dbUser,
                    authError,
                    queryTab,
                    isHomePage,
                    isLogin,
                    isSingUp,
                    isSessionPage,
                    isProfilePage,
                    isAdminPage,
                    isActivityPage,
                    { sessionId, activityId },
                    featureToggles,
                    tab,
                    authenticationTab,
                    isMobileScreen,
                    isDesktopScreen,
                    screenHandset,
                    isTabletScreenAll,
                    notificationsCount,
                    totalNotificationsCount,
                    isPathAdminSelectedUser,
                    isPathAdminSelectedSession,
                ]) => {
                    const showSignUp = featureToggles?.[FeatureToggleName.ShowRegisterLink];
                    const showAiConcierge = featureToggles?.[FeatureToggleName.AiConciergeAvailable];
                    const helpMenuAvailable = featureToggles?.[FeatureToggleName.HelpMenu];

                    this.sessionId = sessionId;
                    this.activityId = activityId;
                    this.userId = dbUser.id as string;
                    this.user = dbUser;

                    if (isHomePage && queryTab !== tab && queryTab) {
                        this.setTab(queryTab);
                        tab = queryTab;
                    }

                    if (isProfilePage) {
                        this.setTab(queryTab);
                        tab = queryTab;
                    }

                    if (isAdminPage) {
                        this.setTab(queryTab);
                        tab = queryTab;
                    }

                    if (!isHomePage && !isProfilePage && !isAdminPage) {
                        const pageTab = isSessionPage ? NavigationTab.Sessions : NavigationTab.Templates;
                        if (pageTab !== tab) {
                            this.setTab(pageTab);
                            tab = pageTab;
                        }
                    }

                    if (isLogin) {
                        const pageTab = isSingUp ? AuthenticationTab.SingUp : AuthenticationTab.Login;
                        this.setAuthenticationTab(pageTab);
                        authenticationTab = pageTab;
                    }

                    if (!isLogin && dbUser?.guest && !this.isGuestUserLoginWasHandled) {
                        this.handleGuestUserLogin();
                    }

                    // admin/user/:userId || admin/sessions/:sessionId
                    const isAdminPageTabs = isPathAdminSelectedUser || isPathAdminSelectedSession;
                    this.isAdminPageTabs = isAdminPageTabs;

                    return {
                        user,
                        dbUser,
                        authError,
                        tab,
                        authenticationTab,
                        showSignUp,
                        showAiConcierge,
                        isActivityPage,
                        notificationsCount,
                        totalNotificationsCount,
                        helpMenuAvailable,
                        screenHandset: screenHandset.matches,
                        isMobileScreen: isMobileScreen.matches,
                        isDesktopScreen: isDesktopScreen.matches,
                        isTabletScreenAll: isTabletScreenAll.matches,
                        isLoading: false,
                        showBackButton: this.showBackButton(
                            isMobileScreen.matches,
                            isProfilePage,
                            isActivityPage,
                            isAdminPageTabs,
                            isSessionPage,
                            dbUser,
                        ),
                    };
                },
            ),
            startWith(defaultViewModel),
        );
    }

    private getNotificationsCount(): Observable<string> {
        return this.store.select(selectAuthenticatedUser).pipe(
            switchMap((user) =>
                user && user.id
                    ? this.inAppNotificationsService.getNotificationsCount(
                          user.id,
                          user.lastViewedNotificationDateCreated,
                      )
                    : of(0),
            ),
            map((count) => `${count > 99 ? '99+' : count ? count : ''}`),
        );
    }

    private getTotalNotificationsCount(): Observable<number> {
        return this.store
            .select(selectAuthenticatedUser)
            .pipe(
                switchMap((user) =>
                    user && user.id ? this.inAppNotificationsService.getTotalNotificationsCount(user.id) : of(0),
                ),
            );
    }

    private handleGuestUserLogin(): void {
        const isGuestFirstLogin = this.userService.isGuestFirstLogin$.getValue();

        if (!isGuestFirstLogin) {
            this.openManageGuestAccountSnackbar();
        }

        this.isGuestUserLoginWasHandled = true;
    }

    private showBackButton(
        isMobileScreen: boolean,
        isProfilePage: boolean,
        isActivityPage: boolean,
        isAdminPageTabs: boolean,
        isSessionPage: boolean,
        dbUser: User,
    ): boolean {
        if (isMobileScreen) {
            // screenAllMobiles (< 1279)

            if (dbUser?.guest) {
                // if user is a guest, only show back button on these pages
                return isSessionPage || isProfilePage || isActivityPage;
            }
            // if user is NOT a guest, only show back button on these pages
            return isSessionPage || isProfilePage || isActivityPage || isAdminPageTabs;
        }
        return false;
    }
}
