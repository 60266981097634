import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';

import { DBPathHelper, ParentType, ValidationFeedback } from '@accenture/shared/data';
import { FirestoreService } from '@accenture/shared/data-access';

@Injectable({
    providedIn: 'root',
})
export class TableValidationFeedbackService {
    constructor(private firestoreService: FirestoreService) {}

    getFeedbacksForUsersWhoCompletedValidation(
        projectId: string,
        activityItemId: string,
        usersCompletedValidationIds: string[],
    ): Observable<ValidationFeedback[]> {
        return this.firestoreService
            .getDocumentsByMultipleProperties<ValidationFeedback>(
                DBPathHelper.getValidationFeedbackPath(ParentType.Projects, projectId),
                new Map([['activityItemId', activityItemId]]),
            )
            .pipe(
                map(validationFeedbacks => {
                    return validationFeedbacks.filter(feedback =>
                        [...usersCompletedValidationIds].includes(feedback.userId),
                    );
                }),
            );
    }

    getFeedbacksForUsersWhoCompletedValidationNew(
        sessionId: string,
        activityItemId: string,
        usersCompletedValidationIds: string[],
    ): Observable<ValidationFeedback[]> {
        return this.firestoreService
            .getDocumentsByMultipleProperties<ValidationFeedback>(
                DBPathHelper.getValidationFeedbackPath(ParentType.Sessions, sessionId),
                new Map([['activityItemId', activityItemId]]),
            )
            .pipe(
                map(validationFeedbacks => {
                    return validationFeedbacks.filter(feedback =>
                        [...usersCompletedValidationIds].includes(feedback.userId),
                    );
                }),
            );
    }
}
