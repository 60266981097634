import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { firstValueFrom } from 'rxjs';

import {
    AppState,
    selectAuthenticatedUser,
    selectProject,
    selectSession,
    selectSessionData,
} from '@accenture/global-store';
import {
    Activity,
    DBPathHelper,
    NavigationTab,
    ParentType,
    Project,
    PublicAccess,
    Session,
    TemplateAccessType,
    TemplatesTab,
    UpdatedByUser,
} from '@accenture/shared/data';
import { FirestoreService } from '@accenture/shared/data-access';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { SnackbarService, SnackBarTypes } from '@accenture/shared/ui';

import {
    updateByUserTemplateDoneMessage,
    updateTemplateDoneMessage,
    updateTemplateDoneTitle,
    updateTemplateErrorMessage,
    updateTemplateErrorTitle,
    updateTemplateInProgressMessage,
    updateTemplateInProgressTitle,
    updatingCancelledMessage,
    updatingCancelledTitle,
} from '../constants';
import { ActivityTemplateService } from './activity-template.service';
import { FavoriteTemplateService } from './favorite-template.service';
import { LinkAccessService } from './link-access.service';
import { ProjectTemplateService } from './project-template.service';
import { PublicAccessService } from './public-access.service';
import { SessionTemplateService } from './session-template.service';
import { TemplateAccessService } from './template-access.service';

@Injectable({
    providedIn: 'root',
})
export class PublicTemplateEditService {
    constructor(
        private sessionTemplateService: SessionTemplateService,
        private activityTemplateService: ActivityTemplateService,
        private projectTemplateService: ProjectTemplateService,
        private favoriteTemplateService: FavoriteTemplateService,
        private publicAccessService: PublicAccessService,
        private snackbarService: SnackbarService,
        private templateAccessService: TemplateAccessService,
        private router: Router,
        private store: Store<AppState>,
        private firestoreService: FirestoreService,
        private linkAccessService: LinkAccessService,
    ) {}

    async publishChangesAction(
        publicAccess: PublicAccess,
        parentType: ParentType,
        accessData: Partial<TemplateAccessType>,
        userId?: string,
    ): Promise<void> {
        const user = await firstValueFrom(this.store.select(selectAuthenticatedUser));
        const action = async () => {
            const draftTemplateId = publicAccess.draftTemplateId;

            // set user, who pushed updates to notify others
            await this.updateEditorData<{ updatedBy: UpdatedByUser }>(publicAccess.draftTemplateId, parentType, {
                updatedBy: { userId: user.id, displayName: user.displayName, published: true },
            });

            await this.markTemplateForDelete(publicAccess.templateId, parentType);
            await this.updateFavoritesCountForDraftTemplate(draftTemplateId, parentType, publicAccess.favoritesCount);
            await this.updatePublicAccessOnDraftTemplate(publicAccess.id, draftTemplateId);
            await this.templateAccessService.updateTemplateAccess(
                DBPathHelper.getPublicAccessPath(),
                draftTemplateId,
                accessData,
            );
            await this.templateAccessService.updateTemplateAccess(
                DBPathHelper.getFavoriteAccessPath(),
                publicAccess.templateId,
                { ...accessData, templateId: draftTemplateId, draftTemplateId: null },
            );

            // check if linkAccess exists with given templateId
            // when projects are removed, published public session templates will generate link and password
            const linkAccess = await firstValueFrom(
                this.linkAccessService.getLinkAccessByTemplate(publicAccess.templateId),
            );
            // temporary toggle
            // TODO: remove toggle when projects is no longer used
            const collectionsToggle = parentType === ParentType.PublicSessionTemplates && linkAccess.id;

            if (collectionsToggle) {
                const linkAccessUpdateData = {
                    templateId: draftTemplateId,
                    publicAccessName: accessData.name,
                };
                // update linkAccess, change templateId and publicAccessName to updated data
                // dependent on /linkAccess/{linkAccessId} firestore rule
                await this.linkAccessService.updateLinkAccessTemplateData(linkAccess.id, linkAccessUpdateData);
            }
        };

        await this.executeAction(action);
        const access = accessData.name ? { ...publicAccess, name: accessData.name } : publicAccess;
        await this.publicAccessService.sendNotificationsAfterPublishChanges(access, userId);

        // remove user, who pushed updates
        await this.updateEditorData(publicAccess.draftTemplateId, parentType, {
            updatedBy: this.firestoreService.deleteField,
        });
    }

    //TODO delete after project deprecation
    async getAccessUpdateData(parentType: ParentType, activity?: Activity): Promise<Partial<TemplateAccessType>> {
        if (parentType === ParentType.PublicActivityTemplates && !activity) {
            throw new Error('Missing data for activity');
        }

        switch (parentType) {
            case ParentType.PublicProjectTemplates: {
                const template = await firstValueFrom(this.store.select(selectProject));

                return {
                    name: template.name || '',
                    description: template.description || '',
                    imageUrl: template.imageUrl || '',
                    clients: template.clients || null,
                    tags: template.tags || null,
                };
            }
            case ParentType.PublicSessionTemplates: {
                const template = await firstValueFrom(this.store.select(selectSessionData));

                return {
                    name: template.name || '',
                    description: template.description || '',
                    imageUrl: template.imageUrl || '',
                    tags: template.tags || null,
                    phases: template.phase || null,
                    subPhases: template.subPhase || null,
                };
            }
            case ParentType.PublicActivityTemplates:
                return {
                    name: activity.name || '',
                    description: activity.description || '',
                    imageUrl: activity.imageUrl || '',
                    tags: activity.tags || null,
                };
        }
    }

    async getAccessUpdateDataNew(parentType: ParentType, activity?: Activity): Promise<Partial<TemplateAccessType>> {
        if (parentType === ParentType.PublicActivityTemplates && !activity) {
            throw new Error('Missing data for activity');
        }

        switch (parentType) {
            case ParentType.PublicSessionTemplates: {
                const template = await firstValueFrom(this.store.select(selectSessionData));

                return {
                    name: template.name || '',
                    description: template.description || '',
                    imageUrl: template.imageUrl || '',
                    tags: template.tags || null,
                    phases: template.phase || null,
                    subPhases: template.subPhase || null,
                };
            }
            case ParentType.PublicActivityTemplates:
                return {
                    name: activity.name || '',
                    description: activity.description || '',
                    imageUrl: activity.imageUrl || '',
                    tags: activity.tags || null,
                };
        }
    }

    async cancelEditAction(publicAccess: PublicAccess, parentType: ParentType): Promise<void> {
        const user = await firstValueFrom(this.store.select(selectAuthenticatedUser));

        // set user, who cancelling updates to notify others
        await this.updateEditorData<{ updatedBy: UpdatedByUser }>(publicAccess.draftTemplateId, parentType, {
            updatedBy: { userId: user.id, displayName: user.displayName },
        });
        const action = async () => {
            await this.markTemplateForDelete(publicAccess.draftTemplateId, parentType);
            await this.rollbackPublicAccess(publicAccess.id);
            await this.rollbackFavoriteAccess(publicAccess.templateId, publicAccess.ownerId);
        };

        await this.executeAction(action);
    }

    //TODO delete after poroject deprecation
    navigateHome(): void {
        this.router.navigate(['/home'], {
            queryParams: {
                tab: NavigationTab.Templates,
                templatesTab: TemplatesTab.TemplateStore,
            },
            queryParamsHandling: 'merge',
        });
    }

    navigateHomeNew(): void {
        this.router.navigate(['/dashboard/templates'], {
            queryParams: {
                templatesTab: TemplatesTab.TemplateStore,
            },
            queryParamsHandling: 'merge',
        });
    }

    showSnackBar(snackBarType: SnackBarTypes, userName?: string, templateName?: string): void {
        const snackBarCallbackMap = {
            [SnackBarTypes.Info]: () =>
                !!userName
                    ? this.snackbarService.showInfoSnackBar(
                          updatingCancelledTitle,
                          updatingCancelledMessage(userName, templateName),
                          true,
                      )
                    : this.snackbarService.showInfoSnackBar(
                          updateTemplateInProgressTitle,
                          updateTemplateInProgressMessage,
                      ),
            [SnackBarTypes.Success]: () =>
                !!userName
                    ? this.snackbarService.showSuccessSnackBar(
                          updateTemplateDoneTitle,
                          updateByUserTemplateDoneMessage(userName, templateName),
                      )
                    : this.snackbarService.showSuccessSnackBar(updateTemplateDoneTitle, updateTemplateDoneMessage),
            [SnackBarTypes.Error]: () =>
                this.snackbarService.showErrorSnackBar(updateTemplateErrorTitle, updateTemplateErrorMessage),
        };

        snackBarCallbackMap[snackBarType]();
    }

    private async updateEditorData<T>(templateId: string, parentType: ParentType, data: T): Promise<void> {
        switch (parentType) {
            case ParentType.PublicActivityTemplates:
                return this.activityTemplateService.updateWithMerge(parentType, templateId, data as Partial<Activity>);
            case ParentType.PublicSessionTemplates:
                return this.sessionTemplateService.updateWithMerge(parentType, templateId, data as Partial<Session>);
            case ParentType.PublicProjectTemplates:
                return this.projectTemplateService.updateWithMerge(parentType, templateId, data as Partial<Project>);
        }
    }

    private async executeAction(actionMethod: () => Promise<void>): Promise<void> {
        this.showSnackBar(SnackBarTypes.Info);

        try {
            await actionMethod();
            this.showSnackBar(SnackBarTypes.Success);
            this.navigateHomeNew();
        } catch (error) {
            this.showSnackBar(SnackBarTypes.Error);
        }
    }

    private async rollbackPublicAccess(publicAccessId: string): Promise<void> {
        await this.publicAccessService.removeDraftTemplateId(publicAccessId);
    }

    private async rollbackFavoriteAccess(templateId: string, userId: string): Promise<void> {
        const favoriteAccessArray = await firstValueFrom(
            this.favoriteTemplateService.getFavoriteTemplateByUserId(templateId, userId),
        );
        const [favoriteAccess] = favoriteAccessArray;

        if (favoriteAccess) {
            await this.favoriteTemplateService.removeDraftTemplateIdFromFavoriteAccess(favoriteAccess.id);
        }
    }

    private async updatePublicAccessOnDraftTemplate(publicAccessId: string, draftTemplateId: string): Promise<void> {
        const updatedPublicAccess: Partial<PublicAccess> = {
            templateId: draftTemplateId,
            draftTemplateId: null,
        };

        await this.publicAccessService.updateWithMerge(publicAccessId, updatedPublicAccess);
    }

    private async markTemplateForDelete(templateId: string, parentType: ParentType): Promise<void> {
        const serviceMap = {
            [ParentType.PublicActivityTemplates]: this.activityTemplateService,
            [ParentType.PublicProjectTemplates]: this.projectTemplateService,
            [ParentType.PublicSessionTemplates]: this.sessionTemplateService,
        };
        const service = serviceMap[parentType];

        await service.markForDelete(parentType, templateId);
    }

    private async updateFavoritesCountForDraftTemplate(
        templateId: string,
        parentType: ParentType,
        favoritesCount: number,
    ): Promise<void> {
        const serviceMap = {
            [ParentType.PublicActivityTemplates]: this.activityTemplateService,
            [ParentType.PublicProjectTemplates]: this.projectTemplateService,
            [ParentType.PublicSessionTemplates]: this.sessionTemplateService,
        };
        const service = serviceMap[parentType];

        await service.updateWithMerge(parentType, templateId, { favoritesCount });
    }
}
