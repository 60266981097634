import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';

import { inputPlaceholders } from '@accenture/activity/shared/domain';
import {
    emptyScreenImageUrlTemplates,
    emptyScreenTitles,
    noItemsFoundImageUrl,
    UserAccess,
} from '@accenture/shared/data';
import { trackById } from '@accenture/shared/util';

@Component({
    selector: 'accenture-add-project-table',
    templateUrl: './add-project-table.component.html',
    styleUrls: ['./add-project-table.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddProjectTableComponent {
    @Input() templates: UserAccess[];
    @Input() selectedTemplateId = '';

    @Output() searchValue = new EventEmitter<string>();
    @Output() newSelectedTemplateId = new EventEmitter<string>();

    searchControl = new FormControl<string>('', { nonNullable: true });
    trackById = trackById;
    inputPlaceholders = inputPlaceholders;
    emptyScreenTitles = emptyScreenTitles;
    emptyScreenImageUrlTemplates = emptyScreenImageUrlTemplates;
    noItemsFoundImageUrl = noItemsFoundImageUrl;

    emitSearchValue(value: string): void {
        const searchValue = value.trim().toLowerCase();

        this.searchControl.patchValue(searchValue);
        this.searchValue.emit(searchValue);
    }

    resetSearchValue(): void {
        this.searchControl.patchValue('');
        this.emitSearchValue('');
    }

    toggleTemplatesSelection(templateId: string): void {
        this.newSelectedTemplateId.emit(templateId);
    }
}
