import { inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { groupBy } from 'lodash';
import { BehaviorSubject, combineLatest, distinctUntilChanged, map, Observable, of, startWith, switchMap } from 'rxjs';

import { ActivityItemService, ActivityService } from '@accenture/activity/shared/domain';
import { DataFlowService } from '@accenture/erp-deployment/shared/domain';
import { AppState, selectActivityIdAndParentIds, selectRouterQueryParams } from '@accenture/global-store';
import {
    Activity,
    ActivityItem,
    ActivityType,
    DataFlowAvailableActivityTypes,
    DataFlowConnection,
    Dictionary,
    ParentType,
    UserSession,
} from '@accenture/shared/data';

export interface DataFlowFormModel {
    sourceActivities: Activity[];
    destinationActivities: Activity[];
    connectionData: DataFlowConnection;
    activityItems: Dictionary<ActivityItem[]>;
    isInSessionActivityPath: boolean;
    isFormReady: boolean;
}

export const initialValues: DataFlowFormModel = {
    sourceActivities: [],
    destinationActivities: [],
    connectionData: {} as DataFlowConnection,
    activityItems: {} as Dictionary<ActivityItem[]>,
    isInSessionActivityPath: false,
    isFormReady: false,
};

@Injectable()
export class DataFlowFormFacade {
    private activityService = inject(ActivityService);
    private activityItemService = inject(ActivityItemService);
    private dataFlowService = inject(DataFlowService);
    private store = inject(Store<AppState>);

    private sourceSession = new BehaviorSubject<UserSession>(null);
    private destinationSession = new BehaviorSubject<UserSession>(null);

    private currentActivity: Activity = null;
    private currentActivityItem: ActivityItem = null;
    private isInitialized = false;
    private isInSessionActivityPath: boolean;

    vm$ = this.buildViewModel();

    setSourceSession(session: UserSession | null) {
        this.sourceSession.next(session);
    }

    setDestinationSession(session: UserSession | null) {
        this.destinationSession.next(session);
    }

    private buildViewModel(): Observable<DataFlowFormModel> {
        return combineLatest({
            ids: this.store.select(selectActivityIdAndParentIds),
            queryParams: this.store.select(selectRouterQueryParams),
        }).pipe(
            switchMap(({ ids, queryParams }) => {
                const { parentType, parentId, activityId, isActivityEdit } = ids;
                const { tableId } = queryParams;
                const sourceActivities$ = this.getExistingConnectionSessionActivities(
                    parentType,
                    parentId,
                    this.sourceSession,
                    [ActivityType.Brainstorm, ActivityType.Vote],
                );
                const destinationActivities$ = this.getExistingConnectionSessionActivities(
                    parentType,
                    parentId,
                    this.destinationSession,
                    [ActivityType.Brainstorm, ActivityType.Vote, ActivityType.Table],
                );

                this.isInSessionActivityPath = parentType === ParentType.Sessions && activityId && !isActivityEdit;

                return combineLatest({
                    currentActivity: activityId
                        ? this.activityService.getActivity(parentType, parentId, activityId)
                        : of(null),
                    activityItems: this.activityItemService.getAllActivityItems(parentType, parentId),
                }).pipe(
                    switchMap(({ currentActivity, activityItems }) => {
                        this.currentActivity = currentActivity;
                        this.currentActivityItem = this.getCurrentActivityItem(activityItems, tableId);

                        return combineLatest({
                            sourceActivities: sourceActivities$,
                            destinationActivities: destinationActivities$,
                            existingConnection: this.getExistingConnection(parentType, parentId),
                        }).pipe(
                            map(({ sourceActivities, destinationActivities, existingConnection }) => {
                                if (!!existingConnection && !this.isInitialized) {
                                    this.setSourceSession({
                                        sessionId: existingConnection.sourceSessionId,
                                    } as UserSession);
                                    this.setDestinationSession({
                                        sessionId: existingConnection.destinationSessionId,
                                    } as UserSession);
                                    this.isInitialized = true;
                                }
                                return {
                                    sourceActivities,
                                    destinationActivities,
                                    activityItems: groupBy(activityItems, 'activityId'),
                                    connectionData: existingConnection,
                                    isInSessionActivityPath: this.isInSessionActivityPath,
                                    isFormReady: true,
                                };
                            }),
                        );
                    }),
                );
            }),
            startWith(initialValues),
        );
    }

    private getActivities(parentType: ParentType, parentId: string): Observable<Activity[]> {
        return this.activityService
            .getAllParentActivities(parentType, parentId)
            .pipe(
                map((activities) =>
                    activities.filter((activity) => DataFlowAvailableActivityTypes.includes(activity.type)),
                ),
            );
    }

    private getCurrentActivityItem(
        activityItems: ActivityItem[],
        currentActivityItemId: string,
    ): ActivityItem | undefined {
        if (!currentActivityItemId) {
            return;
        }

        return activityItems.find((activity) => activity.id === currentActivityItemId) || ({} as ActivityItem);
    }

    private getExistingConnection(parentType: ParentType, parentId: string): Observable<DataFlowConnection | null> {
        if (!!this.currentActivity && !this.isInSessionActivityPath) {
            return this.dataFlowService
                .getDataFlowConnectionsByProperty(parentType, parentId, 'destinationId', this.currentActivity.id)
                .pipe(
                    map((existingConnection) => {
                        if (this.currentActivity.type !== ActivityType.Table) {
                            return existingConnection[0];
                        }

                        return existingConnection.find(
                            (connection: DataFlowConnection) =>
                                connection.destinationActivityItem.id === this.currentActivityItem.id,
                        );
                    }),
                );
        }

        return of(null);
    }

    private getSelectedSessionActivities(
        selectedSession: BehaviorSubject<UserSession>,
        displayedActivityTypes: ActivityType[] = [ActivityType.Brainstorm, ActivityType.Vote, ActivityType.Table],
    ): Observable<Activity[]> {
        return selectedSession.asObservable().pipe(
            distinctUntilChanged(),
            switchMap((userSession) => {
                if (!userSession) {
                    return of([]);
                }

                return this.getActivities(ParentType.Sessions, userSession.sessionId).pipe(
                    map((activities) => {
                        return activities.filter((activity) => displayedActivityTypes.includes(activity.type));
                    }),
                );
            }),
        );
    }

    getSourceActivities(parentType: ParentType, parentId: string): Observable<Activity[]> {
        const includedActivityTypes = [ActivityType.Brainstorm, ActivityType.Vote];

        if (parentType === ParentType.Sessions) {
            return this.getSelectedSessionActivities(this.sourceSession, includedActivityTypes);
        } else if ([ParentType.Templates, ParentType.PublicSessionTemplates].includes(parentType)) {
            return this.activityService
                .getActivities(parentType, parentId)
                .pipe(
                    map((activities) => activities.filter((activity) => includedActivityTypes.includes(activity.type))),
                );
        }

        return of([]);
    }

    getExistingConnectionSessionActivities(
        parentType: ParentType,
        parentId: string,
        session: BehaviorSubject<UserSession>,
        includedActivityTypes = [ActivityType.Brainstorm, ActivityType.Vote, ActivityType.Table],
    ): Observable<Activity[]> {
        if (parentType === ParentType.Sessions) {
            return this.getSelectedSessionActivities(session, includedActivityTypes);
        } else if ([ParentType.Templates, ParentType.PublicSessionTemplates].includes(parentType)) {
            return this.activityService
                .getActivities(parentType, parentId)
                .pipe(
                    map((activities) => activities.filter((activity) => includedActivityTypes.includes(activity.type))),
                );
        }

        return of([]);
    }
}
