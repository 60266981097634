import { Injectable } from '@angular/core';
import { unset } from 'lodash';
import { map, Observable } from 'rxjs';

import {
    DBPathHelper,
    DefaultFilterObject,
    Dictionary,
    FavoriteAccess,
    ParentType,
    publicParentTypes,
    TemplateTab,
} from '@accenture/shared/data';
import { FirestoreService } from '@accenture/shared/data-access';
import { removeNoValuesKeys } from '@accenture/shared/util';

@Injectable({
    providedIn: 'root',
})
export class FavoriteTemplateService {
    deleteField = this.firestoreService.deleteField;

    constructor(private firestoreService: FirestoreService) {}

    async onUpdateFavoriteTemplate(favoriteAccess: FavoriteAccess, initialAccessId?: string): Promise<void> {
        const newFavoriteAccess = removeNoValuesKeys(favoriteAccess);

        if (publicParentTypes.includes(favoriteAccess.templateType)) {
            // update favoriteCount for public access
            await this.firestoreService.update(DBPathHelper.getPublicAccessPath(initialAccessId), {
                favoritesCount: this.firestoreService.changeCounterValue(1),
            });
        }

        // update favoriteCount for templates
        await this.firestoreService.update(
            DBPathHelper.getParentPath(favoriteAccess.templateType, favoriteAccess.templateId),
            {
                favoritesCount: this.firestoreService.changeCounterValue(1),
            },
        );

        await this.firestoreService.addDocument(DBPathHelper.getFavoriteAccessPath(), {
            ...newFavoriteAccess,
            created: this.firestoreService.timestamp,
            updated: this.firestoreService.timestamp,
        });
    }

    async onDeleteFavoriteTemplate(favoriteAccess: FavoriteAccess, initialAccessId?: string): Promise<void> {
        if (publicParentTypes.includes(favoriteAccess.templateType)) {
            // update favoriteCount for public access
            await this.firestoreService.update(DBPathHelper.getPublicAccessPath(initialAccessId), {
                favoritesCount: this.firestoreService.changeCounterValue(-1),
            });
        }

        // update favoriteCount for templates
        await this.firestoreService.update(
            DBPathHelper.getParentPath(favoriteAccess.templateType, favoriteAccess.templateId),
            {
                favoritesCount: this.firestoreService.changeCounterValue(-1),
            },
        );

        await this.firestoreService.delete(DBPathHelper.getFavoriteAccessPath(favoriteAccess?.id));
    }

    getFavoriteTemplatesByUserId(userId: string): Observable<FavoriteAccess[]> {
        return this.firestoreService.getDocumentsByProperty(DBPathHelper.getFavoriteAccessPath(), 'userId', userId);
    }

    // TODO: remove old function getFavoriteTemplatesByUserId after implementation of collection
    getFavoriteTemplatesByUserIdNew(userId: string): Observable<FavoriteAccess[]> {
        return this.firestoreService
            .getDocumentsByProperty(DBPathHelper.getFavoriteAccessPath(), 'userId', userId)
            .pipe(
                map((items: FavoriteAccess[]) =>
                    items.filter(
                        (item) =>
                            item.templateType !== 'projectTemplates' && item.templateType !== 'publicProjectTemplates',
                    ),
                ),
            );
    }

    getFavoriteTemplateByUserId(templateId: string, userId: string): Observable<FavoriteAccess[]> {
        return this.firestoreService.getDocumentsByMultiplePropertiesWithoutCaching(
            DBPathHelper.getFavoriteAccessPath(),
            new Map([
                ['templateId', templateId],
                ['userId', userId],
            ]),
        );
    }

    getCurrentUserAllFavoriteTemplatesFilters(
        userId: string | undefined,
    ): Observable<Dictionary<DefaultFilterObject> & { tab?: TemplateTab }> {
        return this.firestoreService
            .getDocument<Dictionary<DefaultFilterObject>>(DBPathHelper.getFavoriteFilterPath(userId))
            .pipe(
                map((filter: Dictionary<DefaultFilterObject>) => {
                    unset(filter, 'id');
                    return filter;
                }),
            );
    }

    async updateFavoriteFilters(
        userId: string | undefined,
        data: DefaultFilterObject & { tab?: TemplateTab },
    ): Promise<void> {
        await this.firestoreService.upsert(DBPathHelper.getFavoriteFilterPath(userId), data);
    }

    async updateFavoriteTemplatesOptionsFilters(
        userId: string,
        optionType: string,
        data: string[],
        parentType: ParentType,
    ): Promise<void> {
        await this.firestoreService.upsert(DBPathHelper.getFavoriteFilterPath(userId), {
            [parentType]: {
                [optionType]: data,
            },
        });
    }

    async removeDraftTemplateIdFromFavoriteAccess(favoriteAccessId: string): Promise<void> {
        await this.firestoreService.update(DBPathHelper.getFavoriteAccessPath(favoriteAccessId), {
            ...this.firestoreService.replaceEmptyFields({ draftTemplateId: null } as FavoriteAccess),
        });
    }
}
