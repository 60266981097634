import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { MatFormFieldAppearance } from '@angular/material/form-field';

import {
    DisplaySessionSelectRole,
    SearchFilterOptions,
    SessionDropdownFilter,
    SessionFilter,
} from '@accenture/shared/data';
import { trackById } from '@accenture/shared/util';

@Component({
    selector: 'accenture-quick-filter-dropdown',
    templateUrl: './quick-filter-dropdown.component.html',
    styleUrls: ['./quick-filter-dropdown.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QuickFilterDropdownComponent {
    @Input() appearance!: MatFormFieldAppearance;
    @Input() floatLabel!: 'auto' | 'always';
    @Input() isSearch?: boolean;
    @Input() activeFilterOption?: SearchFilterOptions;

    @Output() selectionClick = new EventEmitter();

    trackById = trackById;
    sessionDropdownFilter = SessionDropdownFilter;
    displaySessionSelectRole = DisplaySessionSelectRole;
    searchFilterOptions = Object.values(SearchFilterOptions);

    get defaultValue(): SearchFilterOptions | SessionFilter | undefined {
        return this.isSearch ? this.activeFilterOption : SessionFilter.All;
    }
}
