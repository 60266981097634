import {
    projectCreateFromTemplateErrorTitle,
    sessionCreateFromTemplateErrorTitle,
    updateTemplateErrorMessage,
} from '@accenture/erp-deployment/shared/domain';

export const snackbarData = {
    error: {
        message: updateTemplateErrorMessage,
        title: projectCreateFromTemplateErrorTitle,
        isVisibleNavigateHome: true,
    },
};

export const sessionSnackbarData = {
    error: {
        message: updateTemplateErrorMessage,
        title: sessionCreateFromTemplateErrorTitle,
        isVisibleNavigateHome: true,
    },
};
