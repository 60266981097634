import {
    AfterContentChecked,
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    Input,
    ViewChild,
} from '@angular/core';
import { ChangeDetectorRef } from '@angular/core';

import { Dictionary } from '@accenture/shared/data';

import { IconColor, IconSize } from '../../models';

@Component({
    selector: 'accenture-icon',
    templateUrl: './accenture-icon.component.html',
    styleUrls: ['./accenture-icon.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccentureIconComponent implements AfterViewInit, AfterContentChecked {
    @Input() size?: IconSize;
    @Input() colorType?: IconColor | string;
    @Input() customColor?: string;

    @ViewChild('icon') icon!: ElementRef;

    iconClass!: string;

    constructor(private changeDetectorRef: ChangeDetectorRef) {}

    setIconClasses(): Dictionary<string> {
        const colorClass = this.getColorClass();
        const sizeClass = this.getSizeClass();

        return {
            [sizeClass]: sizeClass,
            [colorClass]: colorClass,
        };
    }

    ngAfterViewInit(): void {
        if (this.icon.nativeElement && this.icon.nativeElement.textContent) {
            this.iconClass = this.icon.nativeElement.textContent;
            this.changeDetectorRef.detectChanges();
        }
    }

    ngAfterContentChecked(): void {
        if (this.icon && this.icon.nativeElement) {
            this.iconClass = this.icon.nativeElement.textContent;
            this.changeDetectorRef.detectChanges();
        }
    }

    private getSizeClass(): string {
        const classMap: Dictionary<string> = {
            [IconSize.XSmall]: 'x-small',
            [IconSize.Small]: 'small',
            [IconSize.SmallMedium]: 'small-medium',
            [IconSize.Medium]: 'medium',
            [IconSize.Large]: 'large',
            [IconSize.XLarge]: 'x-large',
            [IconSize.Huge]: 'huge',
        };

        return classMap[this.size as IconSize] || '';
    }

    private getColorClass(): string {
        const classMap: Dictionary<string> = {
            [IconColor.Secondary]: 'color-secondary',
            [IconColor.Primary]: 'color-primary',
            [IconColor.Success]: 'color-success',
            [IconColor.SuccessPrimary]: 'color-success-primary',
            [IconColor.SuccessSecondary]: 'color-success-secondary',
            [IconColor.Focus]: 'color-focus',
            [IconColor.Warning]: 'color-warning',
            [IconColor.Disabled]: 'color-disabled',
            [IconColor.Error]: 'color-error',
        };

        return classMap[this.colorType as IconColor] || '';
    }
}
