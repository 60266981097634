import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAuth, AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { AngularFireFunctionsModule, USE_EMULATOR } from '@angular/fire/compat/functions';
import { AngularFireStorageModule } from '@angular/fire/compat/storage';
/* eslint-disable */
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import {
    MSAL_GUARD_CONFIG,
    MSAL_INSTANCE,
    MSAL_INTERCEPTOR_CONFIG,
    MsalBroadcastService,
    MsalGuard,
    MsalGuardConfiguration,
    MsalInterceptor,
    MsalInterceptorConfiguration,
    MsalRedirectComponent,
    MsalService,
} from '@azure/msal-angular';
import {
    BrowserCacheLocation,
    InteractionType,
    IPublicClientApplication,
    LogLevel,
    PublicClientApplication,
} from '@azure/msal-browser';
import { EffectsModule } from '@ngrx/effects';
import { NavigationActionTiming, RouterState, StoreRouterConnectingModule } from '@ngrx/router-store';
import { select, Store, StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { NgxPendoModule } from 'ngx-pendo';
import { combineLatest, Observable, takeWhile, tap } from 'rxjs';

import { PendoService } from '@accenture/erp-deployment/shared/domain';
import {
    FredHeaderComponent,
    TopNavigationBarComponent,
    UiModule as ErpDeploymentUiModule,
} from '@accenture/erp-deployment/shared/ui';
import {
    AppState,
    CustomSerializer,
    getApplicationConfiguration,
    getAttributeClasses,
    GlobalStoreModule,
    retrieveAuthenticatedUser,
    selectAuthenticatedUser,
    setUserIsAuthenticated,
} from '@accenture/global-store';
import { MaintenanceComponent } from '@accenture/maintenance';
import { ENVIRONMENT, SharedDataAccessModule } from '@accenture/shared/data-access';
import { SharedMaterialModule } from '@accenture/shared/material';
import { SharedUiModule } from '@accenture/shared/ui';

import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { AuthSystemGuard } from './auth-system.guard';
import { DevEnvGuard } from './dev-env.guard';
import { routes } from './routes';

const addZendeskScript = (location: string) => {
    const scriptId = 'ze-snippet';
    const nodeList = document.querySelectorAll(`script[id="${scriptId}"]`);
    if (nodeList.length === 0) {
        const parentNode = document.getElementsByTagName(location)[0];
        const script = document.createElement('script');
        script.id = scriptId;
        script.defer = true;
        script.src = 'https://static.zdassets.com/ekr/snippet.js?key=23c796d6-b48b-44c1-95ea-ca0978e79a20';
        parentNode.appendChild(script);
    }
};

const appInitFactory = (
    afAuth: AngularFireAuth,
    store: Store<AppState>,
    pendoService: PendoService,
): (() => Observable<any>) => {
    return () => {
        console.log('appInitFactory');
        store.dispatch(getApplicationConfiguration());
        // addZendeskScript('head');
        return combineLatest([afAuth.user, store.pipe(select(selectAuthenticatedUser))]).pipe(
            tap(([user]) => {
                if (!user) {
                    store.dispatch(setUserIsAuthenticated({ isAuthenticated: false }));
                }
            }),
            takeWhile(([user, dbUser]) => user?.uid && Object.keys(dbUser || {}).length === 0),
            tap(([user, dbUser]) => {
                if (user && Object.keys(dbUser || {}).length === 0) {
                    pendoService.initPendo(user);
                    store.dispatch(retrieveAuthenticatedUser({ firebaseUserId: user.uid }));
                    store.dispatch(getAttributeClasses());
                }
            }),
        );
    };
};

export function loggerCallback(logLevel: LogLevel, message: string) {
    console.log(message);
}

export function MSALInstanceFactory(): IPublicClientApplication {
    return new PublicClientApplication({
        auth: {
            clientId: environment.sso.clientId,
            authority: environment.sso.authority,
            redirectUri: environment.sso.redirectUri,
            postLogoutRedirectUri: '/authentication',
            navigateToLoginRequestUrl: false,
        },
        cache: {
            cacheLocation: BrowserCacheLocation.LocalStorage,
            storeAuthStateInCookie: true,
        },
        system: {
            loggerOptions: {
                loggerCallback,
                logLevel: LogLevel.Error,
                piiLoggingEnabled: false,
            },
        },
    });
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
    const protectedResourceMap = new Map<string, Array<string>>();
    protectedResourceMap.set('https://graph.microsoft.com/v1.0/me', ['user.read']);

    return {
        protectedResourceMap,
        interactionType: InteractionType.Redirect,
    };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
    return {
        interactionType: InteractionType.Redirect,
        authRequest: {
            scopes: ['user.read'],
        },
        loginFailedRoute: '/authentication',
    };
}

@NgModule({
    declarations: [AppComponent],
    imports: [
        FredHeaderComponent,
        TopNavigationBarComponent,
        BrowserModule,
        BrowserAnimationsModule,
        SharedUiModule.forRoot(),
        ErpDeploymentUiModule,
        AngularFireModule.initializeApp(environment.firebase),
        AngularFirestoreModule,
        AngularFireAuthModule,
        AngularFireFunctionsModule,
        AngularFireStorageModule,
        GlobalStoreModule.forRoot(),
        MaintenanceComponent,
        RouterModule.forRoot(routes, {
            initialNavigation: 'enabledBlocking',
            paramsInheritanceStrategy: 'always',
        }),
        StoreModule.forRoot(
            {},
            {
                metaReducers: !environment.production ? [] : [],
                runtimeChecks: {
                    strictActionImmutability: true,
                    strictStateImmutability: true,
                },
            },
        ),
        EffectsModule.forRoot([]),
        !environment.production ? StoreDevtoolsModule.instrument() : [],
        StoreRouterConnectingModule.forRoot({
            serializer: CustomSerializer,
            navigationActionTiming: NavigationActionTiming.PostActivation,
            routerState: RouterState.Minimal,
        }),
        NgxPendoModule.forRoot({
            pendoApiKey: environment.pendo.APIKey,
        }),
        SharedDataAccessModule,
        SharedMaterialModule,
    ],
    providers: [
        {
            provide: APP_INITIALIZER,
            useFactory: appInitFactory,
            deps: [AngularFireAuth, Store, PendoService],
            multi: true,
        },
        {
            provide: ENVIRONMENT,
            useValue: environment,
        },
        {
            provide: 'environment',
            useValue: environment,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: MsalInterceptor,
            multi: true,
        },
        {
            provide: MSAL_INSTANCE,
            useFactory: MSALInstanceFactory,
        },
        {
            provide: MSAL_GUARD_CONFIG,
            useFactory: MSALGuardConfigFactory,
        },
        {
            provide: MSAL_INTERCEPTOR_CONFIG,
            useFactory: MSALInterceptorConfigFactory,
            multi: true,
        },
        {
            provide: USE_EMULATOR,
            useValue: (environment as any).cloudFunctions && [
                (environment as any).cloudFunctions.host,
                (environment as any).cloudFunctions.port,
            ],
        },
        MsalService,
        MsalGuard,
        MsalBroadcastService,
        DevEnvGuard,
        AuthSystemGuard,
    ],
    bootstrap: [AppComponent, MsalRedirectComponent],
})
export class AppModule {}
