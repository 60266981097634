<ng-container *ngIf="vm$ | async as vm">
  <ng-container *accentureLoaded="!vm.isLoading">
    <div *ngIf="!vm.currentOptionToDisplay" [formGroup]="optionsFormGroup">
      <ng-container *ngIf="!vm.isPublicProjectTemplate">
        <ng-container
          *ngTemplateOutlet="
            projectOptionTemplate;
            context: {
              projectOption: projectOptions.Client
            }
          "
        ></ng-container>
      </ng-container>
      <ng-container
        *ngTemplateOutlet="
          projectOptionTemplate;
          context: {
            projectOption: projectOptions.Tag
          }
        "
      ></ng-container>
    </div>

    <accenture-options-filters-chips
      *ngIf="vm.currentOptionToDisplay"
      [sortCollectionsData]="vm.sortCollectionsData"
      [collectionToCount]="collectionToCount.Projects"
      [optionsData]="getOptionsData(vm)"
      [selectedOptions]="vm.selectedProjectOptions[vm.currentOptionToDisplay]"
      (onSelectedOptionsChanged)="updateSelectedOptions($event)"
      (onSortValuesChanged)="updateFilters($event)"
    ></accenture-options-filters-chips>
  </ng-container>

  <!-- Project option template -->
  <ng-template #projectOptionTemplate let-projectOption="projectOption">
    <div [class.mb-24]="isClientsOption(projectOption)">
      <!-- project option -->
      <mat-form-field ngDefaultControl appearance="outline" class="tt9-form-field no-margin width-100">
        <mat-label>{{ projectOptionsTitle[projectOption] }}</mat-label>
        <input
          #optionsInput
          matInput
          type="text"
          [formControl]="getControl(projectOption)"
          [placeholder]="optionPlaceholderText[projectOption]"
          [matAutocomplete]="optionAutocomplete"
          [attr.maxLength]="characterLimitMedium"
        />
        <mat-error>{{ validationMessages.required }}</mat-error>
        <mat-hint
          class="mat-hint-limit"
          [class]="determineHintClass(optionsInput.value.length, characterLimitMedium)"
          >{{ characterLimitText(optionsInput.value.length, characterLimitMedium) }}</mat-hint
        >
        <accenture-icon matSuffix (click)="showOptionChips(projectOption, $event)"> icon-label-outline </accenture-icon>

        <mat-autocomplete #optionAutocomplete="matAutocomplete" [displayWith]="displayEmptyOption">
          <ng-container *ngIf="optionsInput.value.trim()">
            <mat-option
              *ngIf="isNewOptionShown(projectOption, vm.selectedProjectOptions, optionsInput.value)"
              (click)="addNewOption(projectOption)"
            >
              {{ optionsInput.value }} {{ newOptionText[projectOption] }}
            </mat-option>

            <mat-option
              *ngFor="let filteredOption of filteredOptions; trackBy: trackById"
              [value]="filteredOption.name"
              (click)="updateOptionValue(filteredOption, projectOption)"
            >
              {{ filteredOption.name }}
            </mat-option>
          </ng-container>
        </mat-autocomplete>
      </mat-form-field>
      <!-- /project option -->

      <!-- project option chips list -->
      <div
        *ngIf="
          hasSelectedClientsOptionsInEditModal(
            vm.selectedProjectOptions[projectOption],
            projectOption,
            vm.hasClientChanged
          ) || hasSelectedOptions(vm.selectedProjectOptions[projectOption])
        "
        class="d-flex mt-24"
      >
        {{ vm.selectedProjectOptions[projectOption]?.value }}
        <div class="width-100">
          <mat-chip-listbox hideSingleSelectionIndicator class="tt9-chip">
            <ng-container *ngIf="hasSelectedOptions(vm.selectedProjectOptions[projectOption]); else noClient">
              <mat-chip-option
                *ngFor="
                  let option of vm.selectedProjectOptions[projectOption] | keyvalue : alphabeticalKeyValueOrder;
                  trackBy: trackById
                "
                (removed)="removeOptionValue(projectOption, option)"
              >
                <span
                  matTooltipPosition="above"
                  matTooltipClass="tt9-arrow-tooltip-below-large"
                  [matTooltip]="option.value"
                  >{{ option.value | uppercase }}</span
                >
                <accenture-icon matChipRemove>icon-close</accenture-icon>
              </mat-chip-option>
            </ng-container>

            <!-- no client tag -->
            <ng-template #noClient>
              <mat-chip-option *ngIf="isClientsOption(projectOption)" (removed)="removeOptionValue(projectOption)">
                <span
                  matTooltipPosition="above"
                  matTooltipClass="tt9-arrow-tooltip-below-large"
                  [matTooltip]="option?.value"
                  >{{ noClientSelected | uppercase }}</span
                >
                <accenture-icon matChipRemove>icon-close</accenture-icon>
              </mat-chip-option>
            </ng-template>
            <!-- /no client tag -->
          </mat-chip-listbox>
        </div>
      </div>
      <!-- /project option chips list -->
    </div>
  </ng-template>
</ng-container>
