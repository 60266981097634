import { Injectable } from '@angular/core';

import { DBPathHelper, Meeting } from '@accenture/shared/data';
import { FirestoreService } from '@accenture/shared/data-access';

@Injectable({
    providedIn: 'root',
})
export class MeetingService {
    constructor(private firestoreService: FirestoreService) {}

    async addMeeting(meetingId: string, data: Partial<Meeting>): Promise<void> {
        return this.firestoreService.addDocumentWithKey(DBPathHelper.getMeetingPath(), meetingId, data);
    }
}
