import { ActivityType } from './activities';

export const AllTopicsValue = 'ALL';

export enum DataFlowFilters {
    All = 'all',
    Bookmarked = 'bookmarked',
    NotBookmarked = 'not_bookmarked',
    Responses = 'responses',
    ResponsesAndComments = 'responses_and_comments',
    Comments = 'comments',
    TopicsWithResponses = 'topics_with_responses',
    Top = 'top_x',
    Bottom = 'bottom_x',
    Topics = 'topics',
    TopParameter = 'top_parameter',
    BottomParameter = 'bottom_parameter',
    Columns = 'columns',
    ColumnResponses = 'column_responses',
}

export const DataFlowAvailableActivityTypes = [ActivityType.Brainstorm, ActivityType.Vote, ActivityType.Table];

export const DataFlowFiltersTitles = {
    [DataFlowFilters.All]: 'All',
    [DataFlowFilters.Bookmarked]: 'Bookmarked',
    [DataFlowFilters.NotBookmarked]: 'Not bookmarked',
    [DataFlowFilters.Responses]: 'Responses',
    [DataFlowFilters.ResponsesAndComments]: 'Responses & comments',
    [DataFlowFilters.TopicsWithResponses]: 'Topics with responses',
    [DataFlowFilters.Comments]: 'Comments',
    [DataFlowFilters.Top]: 'Top',
    [DataFlowFilters.Bottom]: 'Bottom',
    [DataFlowFilters.Topics]: 'Topics',
    [DataFlowFilters.Columns]: 'Columns',
    [DataFlowFilters.ColumnResponses]: 'Column responses',
};

// destination types, after selected source
export const DataFlowDestinationTypes = {
    [ActivityType.Vote]: [ActivityType.Brainstorm, ActivityType.Vote],
    [ActivityType.Brainstorm]: [ActivityType.Brainstorm, ActivityType.Vote],
    [ActivityType.QuickPoll]: [],
    [ActivityType.LiveShare]: [],
    [ActivityType.Present]: [],
    [ActivityType.Table]: [],
};

export interface DataFlowFilterOptions {
    value: string;
    title: string;
    parameterValue?: string;
    displayParameter?: boolean;
    displayActivityParameter?: boolean;
}

export const DataFlowFiltersOptionsData: { [key in ActivityType]: DataFlowFilterOptions[] } = {
    [ActivityType.Brainstorm]: [
        {
            value: DataFlowFilters.All,
            title: DataFlowFiltersTitles[DataFlowFilters.All],
        },
        {
            value: DataFlowFilters.Bookmarked,
            title: DataFlowFiltersTitles[DataFlowFilters.Bookmarked],
        },
        {
            value: DataFlowFilters.NotBookmarked,
            title: DataFlowFiltersTitles[DataFlowFilters.NotBookmarked],
        },
        {
            value: DataFlowFilters.Top,
            title: DataFlowFiltersTitles[DataFlowFilters.Top],
            displayParameter: true,
        },
        {
            value: DataFlowFilters.Bottom,
            title: DataFlowFiltersTitles[DataFlowFilters.Bottom],
            displayParameter: true,
        },
    ],
    [ActivityType.Vote]: [
        {
            value: DataFlowFilters.All,
            title: DataFlowFiltersTitles[DataFlowFilters.All],
        },
        {
            value: DataFlowFilters.Bookmarked,
            title: DataFlowFiltersTitles[DataFlowFilters.Bookmarked],
        },
        {
            value: DataFlowFilters.NotBookmarked,
            title: DataFlowFiltersTitles[DataFlowFilters.NotBookmarked],
        },
        {
            value: DataFlowFilters.Top,
            title: DataFlowFiltersTitles[DataFlowFilters.Top],
            parameterValue: DataFlowFilters.TopParameter,
            displayParameter: true,
            displayActivityParameter: true,
        },
        {
            value: DataFlowFilters.Bottom,
            title: DataFlowFiltersTitles[DataFlowFilters.Bottom],
            parameterValue: DataFlowFilters.BottomParameter,
            displayParameter: true,
            displayActivityParameter: true,
        },
    ],
    [ActivityType.QuickPoll]: [],
    [ActivityType.LiveShare]: [],
    [ActivityType.Present]: [],
    [ActivityType.Table]: [],
    [ActivityType.Proompt]: [],
    [ActivityType.Spark]: [],
    [ActivityType.Whiteboard]: [],
};

export const DataFlowFiltersContentToMove = {
    [ActivityType.Brainstorm]: [
        {
            value: DataFlowFilters.Responses,
            title: DataFlowFiltersTitles[DataFlowFilters.Responses],
        },
        {
            value: DataFlowFilters.ResponsesAndComments,
            title: DataFlowFiltersTitles[DataFlowFilters.ResponsesAndComments],
        },
        {
            value: DataFlowFilters.Comments,
            title: DataFlowFiltersTitles[DataFlowFilters.Comments],
        },
    ],
    [ActivityType.Vote]: [
        {
            value: DataFlowFilters.Responses,
            title: DataFlowFiltersTitles[DataFlowFilters.Responses],
        },
        {
            value: DataFlowFilters.Comments,
            title: DataFlowFiltersTitles[DataFlowFilters.Comments],
        },
    ],
    [ActivityType.Table]: [
        {
            value: DataFlowFilters.Topics,
            title: DataFlowFiltersTitles[DataFlowFilters.Topics],
        },
        {
            value: DataFlowFilters.TopicsWithResponses,
            title: DataFlowFiltersTitles[DataFlowFilters.TopicsWithResponses],
        },
        {
            value: DataFlowFilters.Responses,
            title: DataFlowFiltersTitles[DataFlowFilters.Responses],
        },
    ],
    [ActivityType.QuickPoll]: [],
    [ActivityType.LiveShare]: [],
    [ActivityType.Present]: [],
    [ActivityType.Proompt]: [],
    [ActivityType.Spark]: [],
};

export const DataFlowFiltersTransformInto = {
    [ActivityType.Brainstorm]: [
        {
            value: false,
            title: DataFlowFiltersTitles[DataFlowFilters.Topics],
        },
        {
            value: true,
            title: DataFlowFiltersTitles[DataFlowFilters.Responses],
        },
    ],
    [ActivityType.Table]: [
        {
            value: false,
            title: DataFlowFiltersTitles[DataFlowFilters.Columns],
        },
        {
            value: true,
            title: DataFlowFiltersTitles[DataFlowFilters.ColumnResponses],
        },
    ],
    [ActivityType.Vote]: [],
    [ActivityType.QuickPoll]: [],
    [ActivityType.LiveShare]: [],
    [ActivityType.Present]: [],
    [ActivityType.Proompt]: [],
    [ActivityType.Spark]: [],
};
