export enum PromptCategory {
    Marketing = 'Marketing',
    SocialMedia = 'Social Media',
    Sales = 'Sales',
    Assistant = 'Assistant',
    Productivity = 'Productivity',
    ProductManagement = 'Product Management',
    ProductStrategyAndDesign = 'Product Strategy And Design',
    ExecutiveOversight = 'Executive Oversight',
    Business = 'Business',
    Strategy = 'Strategy',
    SalesStrategy = 'Sales Strategy',
    Programmer = 'Programmer',
    Developer = 'Developer',
    PresentationCoach = 'Presentation Coach',
    PublicSpeaker = 'Public Speaker',
    DataAnalytics = 'Data Analytics',
    DataVisualization = 'Data Visualization',
    ProjectManagementAndPmo = 'Project Management And PMO',
    MarketAnalysis = 'Market Analysis',
    IndustryAnalyst = 'Industry Analyst',
    PromptEngineering = 'Prompt Engineering',
    Coding = 'Coding',
    QA = 'QA',
    EmailMarketing = 'Email Marketing',
    Branding = 'Branding',
    BusinessPlanning = 'Business Planning',
    Creative = 'Creative',
    ImageGeneration = 'Image Generation',
    FacilitationGuidance = 'Facilitation Guidance',
    Meetings = 'Meetings',
    SessionFacilitation = 'Session Facilitation',
    Workshops = 'Workshops',
    Agile = 'Agile',
    Daci = 'Daci',
    Retro = 'Retro',
    StandUp = 'Stand Up',
    VoteActivity = 'Vote Activity',
    ChartAndGraphAnalysis = 'Chart And Graph Analysis',
    VoteAndBrainstormTextCloudAnalysis = 'Vote And Brainstorm Text Cloud Analysis',
    ExcelOutput = 'Excel Output',
    SessionDeliverableCreation = 'Session Deliverable Creation',
}
