import { AttributeClass } from '../attribute-class';
import { Dictionary } from '../dictionary';

export class AttributesTransformation {
    static isAllAttributesSelectedForUser = (
        attributeClasses: AttributeClass[],
        teamMemberAttributesData: Dictionary<string[]>,
    ): boolean => {
        return attributeClasses.every(attributeClass => {
            const isAttributesExist
                = !!teamMemberAttributesData && !!teamMemberAttributesData[attributeClass.id as string]?.length;
            return isAttributesExist;
        });
    };
}
