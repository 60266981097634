import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject } from '@angular/core';
import { NgxMicRecorderService } from 'ngx-mic-recorder';
import { firstValueFrom } from 'rxjs';

import { SharedMaterialModule } from '@accenture/shared/material';
import { LoaderSize, SharedUiModule } from '@accenture/shared/ui';

import { BotChatFacade } from '../bot-chat/bot-chat-facade';
import { startRecording, stopRecording } from '../bot-chat/constants';

@Component({
    selector: 'accenture-bot-chat-recorder',
    standalone: true,
    templateUrl: './bot-chat-recorder.component.html',
    styleUrls: ['./bot-chat-recorder.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [CommonModule, SharedMaterialModule, SharedUiModule],
    providers: [BotChatFacade],
})
export class BotChatRecorderComponent {
    recorder!: NgxMicRecorderService;

    private readonly facade: BotChatFacade = inject(BotChatFacade);
    private readonly cdr: ChangeDetectorRef = inject(ChangeDetectorRef);

    vm$ = this.facade.vm$;
    loaderSize = LoaderSize.Small;

    isRecording(): boolean {
        return !!this.recorder;
    }

    async record(): Promise<void> {
        if (this.recorder) {
            const recording = await firstValueFrom(this.recorder.recordedBlobAsMp3$);
            this.recorder.stopRecording();
            delete this.recorder;
            const stop = new Audio(stopRecording);
            await stop.play();
            const feedback = await this.facade.uploadRecording(recording);
            const utterance = new SpeechSynthesisUtterance(feedback);
            speechSynthesis.speak(utterance);
        } else {
            const start = new Audio(startRecording);
            await start.play();
            this.recorder = new NgxMicRecorderService();
            this.recorder.startRecording();
        }
        this.cdr.detectChanges();
    }
}
