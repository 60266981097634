import { removeEmptyKeys } from '@accenture/shared/util';

import { InAppNotificationType } from './in-app-notification-type';
import { BaseInAppNotification } from './in-app-notifications';

export class InAppVirusDetectedInUploadNotification extends BaseInAppNotification {
    filename: string;

    constructor(notification: any);
    constructor(userId: string, filename: string);
    constructor(notificationOrUserId: any, filename?: string) {
        if (typeof notificationOrUserId === 'object') {
            super(notificationOrUserId as BaseInAppNotification);

            this.id = notificationOrUserId.id;
            this.type = InAppNotificationType.VirusDetectedInUpload;
            this.filename = notificationOrUserId.filename;
        } else {
            super(InAppNotificationType.VirusDetectedInUpload, notificationOrUserId);

            this.filename = filename;
        }
    }

    createSerializableObject(): Record<string, unknown> {
        return removeEmptyKeys({
            type: this.type,
            userId: this.userId,
            unread: this.unread,
            filename: this.filename,
            created: this.created,
            updated: this.updated,
        });
    }
}
