import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    ViewChild,
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import {
    AttributeClass,
    emptyScreenDescriptions,
    emptyScreenImageUrlAttributes,
    emptyScreenTitles,
    noItemsFoundImageUrl,
} from '@accenture/shared/data';
import { IconColor, IconSize } from '@accenture/shared/ui';
import { trackById } from '@accenture/shared/util';

import { addGroup } from '../attributes-edit-dialog/constants';
import { AttributesEditorFacade } from './attributes-editor-facade';

@UntilDestroy()
@Component({
    selector: 'accenture-attributes-editor',
    templateUrl: './attributes-editor.component.html',
    styleUrls: ['./attributes-editor.component.scss'],
    providers: [AttributesEditorFacade],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AttributesEditorComponent implements AfterViewInit {
    @ViewChild('attributesListWrapper') private attributesListWrapper!: ElementRef;
    @ViewChild('attributesListContainer') private attributesListContainer!: ElementRef;
    @ViewChild('addAttributeBtn') private addAttributeBtn!: ElementRef;

    vm$ = this.facade.vm$;

    scrollToAttributeIndex = 0;
    isBtnVisible = true;

    trackById = trackById;
    noAttributeTitles = emptyScreenTitles;
    noAttributeDescriptions = emptyScreenDescriptions;
    noAttributesYetImage = emptyScreenImageUrlAttributes;
    noAttributesFindImageUrl = noItemsFoundImageUrl;
    addGroup = addGroup;
    iconSize = IconSize;
    iconColor = IconColor;

    constructor(private facade: AttributesEditorFacade, private changeDetector: ChangeDetectorRef) {}

    attributesControlsVisibility(): void {
        setTimeout(() => {
            const attributesListWrapperClientWidth = this.attributesListWrapper?.nativeElement?.clientWidth;
            const attributesListScrollWidth = this.attributesListContainer?.nativeElement?.scrollWidth;
            const addAttributeBtnWidth = !this.addAttributeBtn?.nativeElement ? 185 : 0; // 185 - Add attribute group btn width

            this.isBtnVisible = attributesListScrollWidth + addAttributeBtnWidth > attributesListWrapperClientWidth; // 185 - Add attribute group btn width

            this.changeDetector.detectChanges();
        }, 200);
    }

    emptyScreenTitle(
        attributeClassesLength: number,
        activeAttributeClassId: string,
        searchValue: string,
        activeAttributesLength: number,
    ): string {
        return !attributeClassesLength
            ? this.noAttributeTitles.noAttributeGroupsYet
            : activeAttributeClassId && !activeAttributesLength && searchValue
            ? this.noAttributeTitles.noAttributesMatch
            : this.noAttributeTitles.noAttributesAddedYet;
    }

    emptyScreenDescription(
        attributeClassesLength: number,
        activeAttributeClassId: string,
        searchValue: string,
        activeAttributesLength: number,
    ): string {
        return !attributeClassesLength
            ? this.noAttributeDescriptions.noAttributeGroupsYet
            : activeAttributeClassId && !activeAttributesLength && searchValue
            ? this.noAttributeDescriptions.noAttributesMatch
            : this.noAttributeDescriptions.noAttributesAddedYet;
    }

    isAddButtonVisible(attributeClassesLength: number, activeAttributesLength: number): boolean {
        const attributesListWrapperClientWidth = this.attributesListWrapper?.nativeElement?.clientWidth;
        const attributesListScrollWidth = this.attributesListContainer?.nativeElement?.scrollWidth;
        const addAttributeBtnWidth = !this.addAttributeBtn?.nativeElement ? 185 : 0; // 185 - Add attribute group btn width

        return (
            !this.isBtnVisible
            && attributesListScrollWidth + addAttributeBtnWidth <= attributesListWrapperClientWidth
            && (attributeClassesLength > 0 || activeAttributesLength > 0)
        );
    }

    isDisabledTooltip(elem: HTMLElement): boolean {
        return elem.scrollWidth <= elem.clientWidth;
    }

    async addDefaultAttributeClass(attributeClass: AttributeClass, selectedClasses: AttributeClass[]): Promise<void> {
        const lastSequence = selectedClasses?.length ? selectedClasses[selectedClasses?.length - 1].sequence : null;
        await this.facade.addDefaultAttributeClass(attributeClass, lastSequence);
        this.attributesControlsVisibility();
    }

    openEditAttributesDialog(attributeClassId: string | null, selectedClasses?: AttributeClass[]): void {
        const lastSequence = selectedClasses?.length ? selectedClasses[selectedClasses?.length - 1].sequence : null;

        this.facade.openEditAttributeDialog(attributeClassId, lastSequence);
        this.attributesControlsVisibility();
    }

    deleteAttributeClass(attributeClassId: string): void {
        this.facade.openDeleteAttributeClassConfirmationDialog(attributeClassId);
        this.attributesControlsVisibility();
    }

    setActiveAttributeClass(activeClassId: string, activeAttributeClassId: string | undefined): void {
        if (activeAttributeClassId === activeClassId) {
            return;
        }

        this.facade.setActiveClass(activeClassId);
    }

    filterAttributes(searchValue: string): void {
        this.facade.filterAttributes(searchValue);
    }

    scrollToAttribute(direction: string): void {
        const itemAttributesList = this.attributesListContainer.nativeElement.children;
        if (direction === 'prev' && !this.scrollToAttributeIndex) {
            return;
        }

        const attributesListClientRect = this.attributesListContainer.nativeElement.getBoundingClientRect();
        const attributesListWrapperClientRect = this.attributesListWrapper.nativeElement.getBoundingClientRect();
        if (
            direction === 'next'
            && attributesListWrapperClientRect.right
                > itemAttributesList[itemAttributesList.length - 1].getBoundingClientRect().right
        ) {
            return;
        }

        direction === 'prev' ? (this.scrollToAttributeIndex -= 1) : (this.scrollToAttributeIndex += 1);

        this.attributesListContainer.nativeElement.style.transform = `translateX(-${
            itemAttributesList[this.scrollToAttributeIndex].getBoundingClientRect().left - attributesListClientRect.left
        }px)`;

        this.attributesControlsVisibility();
    }

    ngAfterViewInit(): void {
        this.facade.afterCreateAttributeModalWasClosed$.pipe(untilDestroyed(this)).subscribe((result: boolean) => {
            if (!result) {
                this.scrollToStart();
            }
            this.attributesControlsVisibility();
        });
        this.attributesControlsVisibility();
        this.changeDetector.detectChanges();
    }

    private scrollToStart(): void {
        if (this.attributesListContainer) {
            this.attributesListContainer.nativeElement.style.transform = 'translateX(0px)';
            this.scrollToAttributeIndex = 0;
        }
    }
}
