import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Observable } from 'rxjs';

import { emptyScreenDescriptions, emptyScreenNoMobileDesign, emptyScreenTitles } from '@accenture/shared/data';

import { ComingSoonService } from '../../services';

@Component({
    selector: 'accenture-coming-soon',
    templateUrl: './coming-soon.component.html',
    styleUrls: ['./coming-soon.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ComingSoonComponent {
    emptyScreenImageUrlWebPage = emptyScreenNoMobileDesign;
    emptyScreenTitles = emptyScreenTitles;
    emptyScreenDescriptions = emptyScreenDescriptions;
    showComingSoonPage$: Observable<boolean>;
    showComingSoonPageOnDashboard$: Observable<boolean>;

    constructor(private comingSoonService: ComingSoonService) {
        this.showComingSoonPage$ = comingSoonService.showComingSoonPage$;
        this.showComingSoonPageOnDashboard$ = comingSoonService.showComingSoonPageOnDashboard$;
    }

    back(): void {
        this.comingSoonService.useComingSoonPage = false;
        this.comingSoonService.showComingSoonPageOnDashboardHome = false;
    }
}
