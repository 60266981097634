import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, ViewEncapsulation } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';

import { SharedMaterialModule } from '@accenture/shared/material';
import { SharedUiModule } from '@accenture/shared/ui';

@Component({
    selector: 'accenture-manage-guest-account-snackbar',
    templateUrl: './manage-guest-account-snackbar.component.html',
    styleUrls: ['./manage-guest-account-snackbar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [
        CommonModule,
        // change SharedUiModule to accenture-icon components when at changed to standalone
        SharedUiModule,
        SharedMaterialModule,
    ],
})
export class ManageGuestAccountSnackbarComponent {
    readonly title = 'You are logged in as a Guest user';
    readonly message = 'Your permissions are limited. Please log in via Accenture SSO or Email to have a full access.';

    constructor(
        public snackBarRef: MatSnackBarRef<ManageGuestAccountSnackbarComponent>,
        @Inject(MAT_SNACK_BAR_DATA)
        public data: {
            iconClass: string;
            iconName: string;
            actionButtonClass: string;
            goToLoginAction: () => void;
        },
    ) {}

    close(): void {
        this.snackBarRef.dismiss();
    }

    goToLogin(): void {
        this.data.goToLoginAction();
        this.snackBarRef.dismiss();
    }
}
