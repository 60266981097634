export interface ImageData {
    src: string;
    width: number;
    height: number;
}

export const slideDefaultIllustration = '/assets/activity-shared-ui/svg/illustration.svg';

export const slideDefaultImageData: ImageData = {
    src: slideDefaultIllustration,
    width: 1068,
    height: 716,
};

export const responseCursorHeight = 24;
