export enum SessionFilter {
    All = 'All_SESSION',
    Leader = 'SESSION_LEADER',
    Participant = 'SESSION_PARTICIPANT',
}
export const SessionDropdownFilter = [SessionFilter.All, SessionFilter.Leader, SessionFilter.Participant];

export const DisplaySessionSelectRole = {
    [SessionFilter.All]: 'All Sessions',
    [SessionFilter.Leader]: 'Session Leader',
    [SessionFilter.Participant]: 'Session Participant',
};
