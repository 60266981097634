import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ROUTER_NAVIGATED } from '@ngrx/router-store';
import { Store } from '@ngrx/store';
import { distinctUntilChanged, map, switchMap } from 'rxjs';

import { ParentType, privateParentTypes, publicParentTypes } from '@accenture/shared/data';

import {
    clearCollectionStore,
    getCollectionAndSessions,
    getUserCollection,
    setCollectionsDisplayMode,
} from '../collection/collection.actions';
import {
    clearProjectStore,
    clearProjectStoreOfSessionSubscriptions,
    clearProjectStoreSubscription,
    clearSessionTeamMember,
    getParentTeamMember,
    getProjectAndSessionsAndAttributes,
    getSession,
    getSessionActivities,
    getSessionTeamMember,
    getSessionTeamMemberSnackBarsVisibility,
    setProjectTeamMemberOnlineState,
    setSessionTeamMemberOnlineState,
    setTeamMemberOfflineState,
    setTemplateTeamMemberOnlineState,
} from '../project/project.actions';
import {
    clearPublicTemplateStore,
    getParentTeamMember as getPublicParentTeamMember,
    setPublicTeamMemberOnlineState,
} from '../public-template/public-template.actions';
import {
    clearSessionStore,
    getParentTeamMemberData,
    getSessionActivitiesData,
    getSessionData,
    getSessionTeamMemberData,
    getUserSessions,
} from '../session/session.actions';
import { blankNavigate, navigateHome, navigateHomeSuccess } from './router.actions';

@Injectable()
export class RouterEffects {
    isProjectIdNotChanged = false;
    isProjectAndSessionNotChanged = false;
    isParentNotChanged = false;

    public navigateHome$ = createEffect(() =>
        this.actions$.pipe(
            ofType(navigateHome),
            switchMap(({ navigationTab }) => this.router.navigate(['home'], { queryParams: { tab: navigationTab } })),
            map(() => navigateHomeSuccess()),
        ),
    );

    public navigateEnded$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ROUTER_NAVIGATED),
            distinctUntilChanged((previousPayload: any, currentPayload: any) => {
                const routerParams = (parentPayload) => parentPayload.payload.routerState.params;

                const previousProjectId = routerParams(previousPayload).projectId;
                const currentProjectId = routerParams(currentPayload).projectId;
                const previousCollectionId = routerParams(previousPayload).collectionId;
                const currentCollectionId = routerParams(currentPayload).collectionId;
                const previousParentType = previousPayload.payload.routerState?.data?.parentType;
                const currentParentType = currentPayload.payload.routerState?.data?.parentType;
                const previousSessionId
                    = previousParentType === ParentType.Sessions
                        ? routerParams(previousPayload).sessionId
                        : routerParams(previousPayload).templateId;
                const currentSessionId
                    = currentParentType === ParentType.Sessions
                        ? routerParams(currentPayload).sessionId
                        : routerParams(currentPayload).templateId;
                const previousActivityId = routerParams(previousPayload).activityId;
                const currentActivityId = routerParams(currentPayload).activityId;
                const previousPublicTemplateId = this.getPublicTemplateId(
                    previousParentType,
                    routerParams(previousPayload),
                );
                const currentPublicTemplateId = this.getPublicTemplateId(
                    currentParentType,
                    routerParams(currentPayload),
                );
                const previousPrivateTemplateId = this.getPrivateTemplateId(
                    previousParentType,
                    routerParams(previousPayload),
                );
                const currentPrivateTemplateId = this.getPrivateTemplateId(
                    currentParentType,
                    routerParams(currentPayload),
                );

                this.isProjectIdNotChanged = previousProjectId === currentProjectId;

                this.isProjectAndSessionNotChanged
                    = previousProjectId === currentProjectId && previousSessionId === currentSessionId;

                this.isParentNotChanged = previousParentType === currentParentType;

                if (!this.isProjectAndSessionNotChanged && (previousParentType || currentParentType)) {
                    this.store.dispatch(clearProjectStoreOfSessionSubscriptions());
                }

                if (!this.isParentNotChanged && (previousParentType || currentParentType)) {
                    this.store.dispatch(clearProjectStoreSubscription());
                    this.store.dispatch(clearProjectStore());
                }

                if (!previousPublicTemplateId && !currentPublicTemplateId) {
                    this.store.dispatch(clearPublicTemplateStore());
                }

                if (previousCollectionId !== currentCollectionId) {
                    this.store.dispatch(clearCollectionStore());

                    if (!!currentCollectionId) {
                        this.store.dispatch(getCollectionAndSessions({ collectionId: currentCollectionId }));
                        this.store.dispatch(getUserCollection({ collectionId: currentCollectionId }));
                    }
                }

                if (
                    previousProjectId === currentProjectId
                    && previousSessionId === currentSessionId
                    && previousActivityId === currentActivityId
                ) {
                    return true;
                }

                if (
                    !currentSessionId
                    && previousProjectId === currentProjectId
                    && previousParentType === ParentType.Projects
                ) {
                    this.store.dispatch(
                        setSessionTeamMemberOnlineState({
                            projectId: previousProjectId,
                            sessionId: previousSessionId,
                            isOnline: false,
                        }),
                    );
                }

                if (previousProjectId && !currentProjectId && previousParentType === ParentType.Projects) {
                    this.store.dispatch(
                        setTeamMemberOfflineState({
                            projectId: previousProjectId,
                            sessionId: previousSessionId,
                        }),
                    );
                }

                if (previousPublicTemplateId && publicParentTypes.includes(previousParentType)) {
                    this.store.dispatch(
                        setPublicTeamMemberOnlineState({
                            parentType: previousParentType,
                            templateId: previousPublicTemplateId,
                            isOnline: false,
                        }),
                    );
                }

                if (
                    previousPrivateTemplateId
                    && previousPrivateTemplateId !== currentPrivateTemplateId
                    && privateParentTypes.includes(previousParentType)
                ) {
                    this.store.dispatch(
                        setTemplateTeamMemberOnlineState({
                            parentType: previousParentType,
                            templateId: previousPrivateTemplateId,
                            isOnline: false,
                        }),
                    );
                }

                return false;
            }),
            map(({ payload }: any) => {
                const isAdministration = payload?.routerState?.data?.administrationProject;
                const parentType = payload?.routerState?.data?.parentType;
                const collectionId = payload?.routerState?.params?.collectionId;
                const projectId = [ParentType.Templates, ParentType.PublicSessionTemplates].includes(parentType)
                    ? payload.routerState.params.templateId
                    : payload.routerState.params.projectId;
                const activityId = payload.routerState.params.activityId;
                const sessionId
                    = parentType === ParentType.Projects
                        ? payload.routerState.params.sessionId
                        : payload.routerState.params.templateId;

                if (!parentType) {
                    return clearProjectStore();
                }

                // If parent and session not changed all action will not be called
                if (
                    this.isProjectAndSessionNotChanged
                    && [
                        ParentType.Projects,
                        ParentType.ProjectTemplates,
                        ParentType.PublicProjectTemplates,
                        ParentType.PublicSessionTemplates,
                        ParentType.Templates,
                    ].includes(parentType)
                ) {
                    return blankNavigate();
                }

                if (publicParentTypes.includes(parentType)) {
                    const parentId = {
                        [ParentType.PublicSessionTemplates]: sessionId,
                        [ParentType.PublicActivityTemplates]: activityId,
                        [ParentType.PublicProjectTemplates]: projectId,
                    };

                    this.store.dispatch(
                        getPublicParentTeamMember({
                            parentType,
                            parentId: parentId[parentType],
                        }),
                    );
                }

                if ([ParentType.Projects, ParentType.ProjectTemplates].includes(parentType) && projectId && sessionId) {
                    this.store.dispatch(getSessionTeamMember({ parentType, parentId: projectId, sessionId }));
                    this.store.dispatch(
                        getSessionTeamMemberSnackBarsVisibility({ parentType, parentId: projectId, sessionId }),
                    );
                }

                if (
                    [ParentType.Projects, ParentType.ProjectTemplates].includes(parentType)
                    && projectId
                    && !sessionId
                ) {
                    this.store.dispatch(clearSessionTeamMember());
                }

                if ([ParentType.Projects].includes(parentType) && projectId && sessionId && !isAdministration) {
                    this.store.dispatch(setSessionTeamMemberOnlineState({ projectId, sessionId, isOnline: true }));
                }

                const publicTemplateId = this.getPublicTemplateId(parentType, payload.routerState.params);
                const privateTemplateId = this.getPrivateTemplateId(parentType, payload.routerState.params);

                if (publicParentTypes.includes(parentType) && !!publicTemplateId) {
                    this.store.dispatch(
                        setPublicTeamMemberOnlineState({ parentType, templateId: publicTemplateId, isOnline: true }),
                    );
                }

                if (privateParentTypes.includes(parentType) && !!privateTemplateId) {
                    this.store.dispatch(
                        setTemplateTeamMemberOnlineState({ parentType, templateId: privateTemplateId, isOnline: true }),
                    );
                }

                if (parentType === ParentType.Collections && !!collectionId) {
                    this.store.dispatch(getCollectionAndSessions({ collectionId }));
                    this.store.dispatch(getUserCollection({ collectionId }));
                }

                if (
                    parentType === ParentType.Sessions
                    || (payload.routerState.url.includes('/dashboard/sessions') && !!payload.routerState.params.sessionId)
                ) {
                    const sessionId = payload.routerState.params.sessionId;

                    this.store.dispatch(
                        getSessionData({
                            parentType,
                            sessionId,
                        }),
                    );

                    this.store.dispatch(
                        getParentTeamMemberData({
                            parentType,
                            parentId: sessionId,
                        }),
                    );

                    this.store.dispatch(
                        getSessionActivitiesData({
                            parentType,
                            sessionId,
                        }),
                    );

                    this.store.dispatch(getUserSessions());
                }

                //TO DO: remove after project deprecation
                if (
                    [ParentType.Projects, ParentType.ProjectTemplates, ParentType.PublicProjectTemplates].includes(
                        parentType,
                    )
                    && sessionId
                ) {
                    this.store.dispatch(
                        getSession({
                            parentType,
                            projectId,
                            sessionId,
                        }),
                    );

                    this.store.dispatch(
                        getSessionActivities({
                            parentType,
                            projectId,
                            sessionId,
                        }),
                    );
                }

                if ([ParentType.Templates, ParentType.PublicSessionTemplates].includes(parentType) && sessionId) {
                    this.store.dispatch(
                        getSessionData({
                            parentType,
                            sessionId,
                        }),
                    );

                    this.store.dispatch(
                        getSessionActivitiesData({
                            parentType,
                            sessionId,
                        }),
                    );

                    this.store.dispatch(
                        getSessionTeamMemberData({
                            parentType,
                            sessionId,
                        }),
                    );
                }

                if (
                    [ParentType.ActivityTemplates, ParentType.PublicActivityTemplates].includes(parentType)
                    && !sessionId
                ) {
                    this.store.dispatch(clearSessionStore());
                }

                // If parent and projectId are not changed all action will not be called. And the actions above are called
                if (
                    this.isParentNotChanged
                    && this.isProjectIdNotChanged
                    && [ParentType.Projects, ParentType.ProjectTemplates, ParentType.PublicProjectTemplates].includes(
                        parentType,
                    )
                ) {
                    return blankNavigate();
                }

                if ([ParentType.Projects].includes(parentType) && projectId && !isAdministration) {
                    this.store.dispatch(setProjectTeamMemberOnlineState({ projectId, isOnline: true }));
                }

                if (
                    [ParentType.Projects, ParentType.ProjectTemplates, ParentType.PublicProjectTemplates].includes(
                        parentType,
                    )
                    && projectId
                    && !isAdministration
                ) {
                    this.store.dispatch(
                        getProjectAndSessionsAndAttributes({
                            parentType,
                            projectId,
                        }),
                    );
                }

                // TeamMembers:
                // there should be only 1 array of team members
                // if on project - project team member, on session - session team member, on activity - session team member, activity template - activity team member
                if (
                    [ParentType.Projects, ParentType.ProjectTemplates].includes(parentType)
                    && projectId
                    && !isAdministration
                ) {
                    this.store.dispatch(
                        getParentTeamMember({
                            parentType,
                            parentId: projectId,
                        }),
                    );
                }

                if ([ParentType.Templates].includes(parentType) && sessionId && !isAdministration) {
                    this.store.dispatch(
                        getParentTeamMemberData({
                            parentType,
                            parentId: sessionId,
                        }),
                    );
                }

                if ([ParentType.ActivityTemplates].includes(parentType) && activityId && !isAdministration) {
                    this.store.dispatch(
                        getParentTeamMemberData({
                            parentType,
                            parentId: activityId,
                        }),
                    );
                }

                return blankNavigate();
            }),
        ),
    );

    constructor(private actions$: Actions, private router: Router, private store: Store) {}

    private getPublicTemplateId(parentType: ParentType, params: any): string | undefined {
        switch (parentType) {
            case ParentType.PublicActivityTemplates: {
                return params.activityId || params.templateId;
            }
            case ParentType.PublicSessionTemplates: {
                return params.sessionId || params.templateId;
            }
            case ParentType.PublicProjectTemplates: {
                return params.projectId || params.templateId;
            }
        }
    }

    private getPrivateTemplateId(parentType: ParentType, params: any): string | undefined {
        switch (parentType) {
            case ParentType.ActivityTemplates: {
                return params.activityId || params.templateId;
            }
            case ParentType.Templates: {
                return params.sessionId || params.templateId;
            }
            case ParentType.ProjectTemplates: {
                return params.projectId || params.templateId;
            }
        }
    }
}
