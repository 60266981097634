import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Directive({
    selector: '[accenturePositiveNumbersOnly]',
})
export class PositiveNumbersOnlyDirective {
    @Input('accenturePositiveNumbersOnly') control?: AbstractControl;
    @Input('accenturePositiveNumbersOnlyRange') range?: boolean;
    @Input('accenturePositiveNumbersOnlyPhone') phone?: boolean;

    constructor(private el: ElementRef) {}

    @HostListener('input', ['$event']) onInputChange() {
        const initialValue = this.el.nativeElement.value;
        const regExp = this.range ? /(^0)?[^0-9]*/g : this.phone ? /[^+0-9 ]*/g : /[^0-9]*/g;

        this.el.nativeElement.value = initialValue.replace(regExp, '');

        if (this.control && initialValue !== this.el.nativeElement.value) {
            this.control.setValue(this.el.nativeElement.value);
        }
    }
}
