import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { PublicTemplateEffects } from './public-template.effects';
import { PUBLIC_TEMPLATE_FEATURE_KEY, publicTemplateReducer } from './public-template.reducer';
import { PublicTemplateDataService } from './public-template-data.service';

@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        StoreModule.forFeature(PUBLIC_TEMPLATE_FEATURE_KEY, publicTemplateReducer),
        EffectsModule.forFeature([PublicTemplateEffects]),
    ],
    exports: [],
    providers: [PublicTemplateDataService],
})
export class PublicTemplateModule {}
