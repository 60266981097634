import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { groupBy, mapValues } from 'lodash';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { distinctUntilChanged, map, startWith, switchMap } from 'rxjs/operators';

import {
    deleteSessionTeamMemberConfirmation,
    FeatureToggleName,
    TeamMemberService,
    UserAccessService,
} from '@accenture/erp-deployment/shared/domain';
import {
    AppState,
    selectAuthenticatedUserId,
    selectFeatureToggle,
    selectProjectIdAndSessionId,
    selectSession,
    selectSessionActivities,
} from '@accenture/global-store';
import {
    AccessControlRole,
    Activity,
    deletingUserDoneSnackbarText,
    deletingUserDoneSnackbarTitle,
    deletingUserErrorSnackbarTitle,
    deletingUserInProgressSnackbarText,
    deletingUserInProgressSnackbarTitle,
    Dictionary,
    errorMessageSnackbarText,
    ParentType,
    ProjectRole,
    routerLinksMap,
    Session,
    TeamMember,
    UserAccess,
} from '@accenture/shared/data';
import { ConfirmationDialogComponent, DialogService, SnackbarService } from '@accenture/shared/ui';

interface SessionUsersMap {
    leaderUsers: TeamMember[];
    participantUsers: TeamMember[];
}

export interface SessionTeamViewModel {
    projectId: string;
    sessionId: string;
    leaderUsers: TeamMember[];
    participantUsers: TeamMember[];
    isUserSearchAvailable: boolean;
    sessionCreatorId: string;
    currentUserId: string;
    searchValue: string;
    projectRolesMap: Dictionary<ProjectRole>;
    userInActionIds: Set<string>;
    isLoading: boolean;
    teamMemActivities: TeamMemberActivities[];
    sessionActivities: Activity[];
    showGetLinks: boolean;
}

export interface TeamMemberActivities {
    linkWithActivity: boolean;
    activities: Activity[];
}

const defaultViewModel: SessionTeamViewModel = {
    projectId: '',
    sessionId: '',
    leaderUsers: [],
    participantUsers: [],
    isUserSearchAvailable: false,
    sessionCreatorId: '',
    currentUserId: '',
    searchValue: '',
    projectRolesMap: {},
    userInActionIds: new Set(),
    isLoading: true,
    teamMemActivities: [],
    sessionActivities: [],
    showGetLinks: false,
};

@Injectable()
export class SessionTeamFacade {
    private isLoading = new BehaviorSubject<boolean>(false);
    readonly isLoading$ = this.isLoading.asObservable();

    private readonly store: Store<AppState> = inject(Store<AppState>);
    private readonly teamMemberService: TeamMemberService = inject(TeamMemberService);
    private readonly dialogService: DialogService = inject(DialogService);
    private readonly snackbarService: SnackbarService = inject(SnackbarService);
    private readonly userAccessService: UserAccessService = inject(UserAccessService);

    private searchValue$ = new BehaviorSubject<string>('');
    private userInActionIds: Set<string> = new Set();
    private userInActionIds$ = new BehaviorSubject<Set<string>>(this.userInActionIds);

    vm$ = this.buildViewModel();

    constructor(private router: Router) {}

    updateSearchValue(value: string): void {
        this.searchValue$.next(value);
    }

    navigateToSessionTeamPage(projectId: string, sessionId: string): void {
        this.router.navigate([routerLinksMap[ParentType.Projects], projectId, 'session', sessionId, 'team']);
    }

    openDeleteConfirmationDialog(projectId: string, sessionId: string, userId: string): void {
        this.dialogService.open(ConfirmationDialogComponent, {
            width: '444px',
            panelClass: 'tt9-modal',
            title: 'Delete user from session',
            confirmBtnText: 'Delete',
            cancelBtnText: 'Cancel',
            text: deleteSessionTeamMemberConfirmation,
            isWarning: true,
            confirm: () => this.deleteUser(projectId, sessionId, userId),
        });
    }

    async changeUserRole(projectId: string, sessionId: string, userId: string, role: AccessControlRole): Promise<void> {
        this.userInActionIds.add(userId);
        await this.teamMemberService.changeSessionRole(projectId, sessionId, userId, role);
        this.userInActionIds.delete(userId);
    }

    private buildViewModel(): Observable<SessionTeamViewModel> {
        return this.store.pipe(
            select(selectProjectIdAndSessionId),
            switchMap(({ projectId, sessionId }) => {
                return combineLatest([
                    this.store.select(selectAuthenticatedUserId),
                    this.store.select(selectSession),
                    this.teamMemberService.getSessionTeamMembers(projectId, sessionId),
                    this.store.select(selectSessionActivities),
                    this.store.select(selectFeatureToggle(FeatureToggleName.ShowGetLinks)),
                    this.teamMemberService
                        .getProjectTeamMembers(projectId)
                        .pipe(
                            map(teamMembers =>
                                mapValues(groupBy(teamMembers, 'userId'), teamMembers => teamMembers[0].role),
                            ),
                        ),
                    this.searchValue$,
                    this.userInActionIds$.asObservable(),
                    this.isLoading$.pipe(distinctUntilChanged()),
                    this.getUserAccess(projectId),
                ]).pipe(
                    map(
                        ([
                            currentUserId,
                            session,
                            sessionTeamMembers,
                            sessionActivities,
                            showGetLinks,
                            projectRolesMap,
                            searchCriteria,
                            userInActionIds,
                            isLoading,
                            userAccess,
                        ]: [
                            string | undefined,
                            Session,
                            TeamMember[],
                            Activity[],
                            boolean,
                            Dictionary<ProjectRole>,
                            string | null,
                            Set<string>,
                            boolean,
                            UserAccess[],
                        ]) => {
                            const usersToShow = this.getUsersToShow(
                                sessionTeamMembers,
                                (searchCriteria || '').toLowerCase(),
                            );
                            const { leaderUsers, participantUsers } = this.splitUsersByRole(usersToShow);
                            const teamMemberActivities: TeamMemberActivities[] = [];
                            const mappedUserAccess = [];
                            userAccess.map(assignment => {
                                mappedUserAccess[assignment.userId] = assignment;
                            });

                            participantUsers.map(async teamMember => {
                                const assignment = mappedUserAccess[teamMember.userId];
                                let linkWithActivity = false;
                                let activityList: Activity[] = undefined;

                                if (assignment && sessionId in assignment.sessions) {
                                    if (assignment.sessions[sessionId] !== undefined) {
                                        linkWithActivity = !!assignment.sessions[sessionId]?.isLinkWithActivity;
                                        activityList = assignment.sessions[sessionId]?.activities
                                            ? Object.values(assignment.sessions[sessionId]?.activities)
                                            : [];
                                    }
                                }

                                teamMemberActivities[teamMember.userId] = {
                                    linkWithActivity,
                                    activities: activityList,
                                };
                            });

                            const teamMemActivities = teamMemberActivities;

                            return {
                                projectId,
                                sessionId,
                                leaderUsers,
                                participantUsers,
                                isLoading,
                                projectRolesMap,
                                sessionActivities,
                                showGetLinks,
                                teamMemActivities,
                                userInActionIds,
                                searchValue: searchCriteria as string,
                                currentUserId: currentUserId as string,
                                sessionCreatorId: session?.creatorId as string,
                                isUserSearchAvailable: !searchCriteria && !sessionTeamMembers?.length,
                            };
                        },
                    ),
                );
            }),
            startWith(defaultViewModel),
        );
    }

    private async deleteUser(projectId: string, sessionId: string, userId: string): Promise<void> {
        this.userInActionIds.add(userId);
        this.showInfoSnackBar(deletingUserInProgressSnackbarTitle, deletingUserInProgressSnackbarText(true));
        try {
            await this.teamMemberService.deleteUserFromSession(projectId, sessionId, userId);
            this.showSuccessSnackBar(deletingUserDoneSnackbarTitle, deletingUserDoneSnackbarText(true));
        } catch {
            console.error('Error while deleting activity');
            this.showErrorSnackBar(deletingUserErrorSnackbarTitle, errorMessageSnackbarText);
        }
        this.userInActionIds.delete(userId);
    }

    private getUsersToShow(users: TeamMember[], searchCriteria: string): TeamMember[] {
        return users.filter(user => {
            const isValid = user.displayName?.toLowerCase().includes(searchCriteria);

            return isValid;
        });
    }

    private splitUsersByRole(users: TeamMember[]): SessionUsersMap {
        return users.reduce(
            (splitUsersMap: SessionUsersMap, user: TeamMember) => {
                if (user.isSessionLeader) {
                    splitUsersMap.leaderUsers = [...splitUsersMap.leaderUsers, user];
                }
                if (user.isSessionParticipant) {
                    splitUsersMap.participantUsers = [...splitUsersMap.participantUsers, user];
                }
                return splitUsersMap;
            },
            {
                leaderUsers: [],
                participantUsers: [],
            },
        );
    }

    private showInfoSnackBar(title: string, message: string): void {
        this.snackbarService.showInfoSnackBar(title, message);
    }

    private showSuccessSnackBar(title: string, message: string): void {
        this.snackbarService.showSuccessSnackBar(title, message);
    }

    private showErrorSnackBar(title: string, message: string): void {
        this.snackbarService.showErrorSnackBar(title, message);
    }

    private getUserAccess(parentId: string): Observable<UserAccess[]> {
        return this.userAccessService.getUserAssignmentsByProjectId(parentId);
    }
}
