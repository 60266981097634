<!-- search -->
<div *ngIf="templates?.length || (!templates?.length && searchControl.value)" class="width-100 mb-24 mt-16">
  <mat-form-field appearance="outline" floatLabel="always" class="tt9-form-field no-label no-margin width-100">
    <input
      #search
      matInput
      [placeholder]="inputPlaceholders.search"
      [formControl]="searchControl"
      (input)="emitSearchValue(search.value)"
    />
    <accenture-icon matPrefix>icon-search</accenture-icon>
    <accenture-icon *ngIf="search.value" matSuffix (click)="resetSearchValue()">icon-close</accenture-icon>
  </mat-form-field>
</div>
<!-- /search -->

<div *ngIf="templates.length" class="tt9-select-table-container width-100">
  <table class="tt9-select-table width-100">
    <thead>
      <tr class="row-sticky horizontal-shadow">
        <th class="cell-select"></th>
        <th class="tt9-body-2 short-cell">Template name</th>
        <th class="tt9-body-2 short-cell">Description</th>
        <th class="tt9-body-2 cell-date">Date created</th>
      </tr>
    </thead>

    <tbody>
      <ng-container *ngFor="let template of templates; trackBy: trackById">
        <ng-container *ngTemplateOutlet="rowTemplate; context: { template: this.template }"></ng-container>
      </ng-container>
    </tbody>
  </table>
</div>
<!--/table-->

<!-- row template -->
<ng-template #rowTemplate let-template="template">
  <tr
    [class.hover]="selectedTemplateId === template.templateId"
    (click)="toggleTemplatesSelection(template.templateId)"
  >
    <td class="cell-select">
      <mat-radio-group class="group mb-24">
        <mat-radio-button
          class="tt9-radio-button option"
          color="primary"
          [value]="template.templateId"
          [checked]="selectedTemplateId === template.templateId"
        ></mat-radio-button>
      </mat-radio-group>
    </td>

    <td class="short-cell">
      <span
        class="tt9-body-1 text-overflow-ellipsis-2"
        matTooltipPosition="above"
        matTooltipClass="tt9-arrow-tooltip-below-right"
        [matTooltip]="template.name"
        >{{ template.name }}</span
      >
    </td>

    <td class="short-cell">
      <span
        class="tt9-body-1 text-overflow-ellipsis-2"
        matTooltipPosition="above"
        matTooltipClass="tt9-arrow-tooltip-below-right"
        [matTooltip]="template.description"
        >{{ template.description }}</span
      >
    </td>

    <td class="tt9-body-1 resizable-width">
      {{ template.created.toMillis() | date: "mediumDate" }}
    </td>
  </tr>
</ng-template>
<!-- /row template -->

<!-- empty screen -->
<ng-container *ngIf="!templates.length">
  <!-- No responses match Search Criteria -->
  <ng-container *ngIf="searchControl.value">
    <accenture-empty-screen
      class="empty-screen"
      [title]="emptyScreenTitles.noTemplatesFound"
      [imageUrl]="noItemsFoundImageUrl"
    ></accenture-empty-screen>
  </ng-container>
  <!-- /No responses match Search Criteria -->

  <!-- empty screen template -->
  <ng-container *ngIf="!searchControl.value">
    <accenture-empty-screen
      class="empty-screen"
      [title]="emptyScreenTitles.noProjectTemplates"
      [imageUrl]="emptyScreenImageUrlTemplates"
    ></accenture-empty-screen>
  </ng-container>
  <!-- /empty screen template -->
</ng-container>
<!-- /empty screen -->
