import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { IconSize } from '../../../models';

@Component({
    selector: 'accenture-base-snack-bar',
    templateUrl: './base-snack-bar.component.html',
    styleUrls: ['./base-snack-bar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BaseSnackBarComponent {
    @Input() title?: string;
    @Input() iconClass!: string;
    @Input() iconName!: string;
    @Input() actionButtonClass!: string;
    @Input() isVisibleDoNotShowAgain?: boolean;
    @Input() hideCloseButton?: boolean;
    @Input() cancelBtnText?: string;

    @Output() snackbarClosed = new EventEmitter<void>();
    @Output() doNotShowAgain = new EventEmitter<boolean>();
    @Output() snackbarCancel = new EventEmitter<boolean>();

    iconSize = IconSize;
}
