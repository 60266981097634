<div class="accenture-tooltip data-flow-tooltip">
  <div *ngIf="data.sourceSession" class="width-100 d-flex pb-8">
    <accenture-icon [size]="iconSize.Small" [colorType]="iconColor.Secondary">icon-home</accenture-icon>
    <div class="pl-8">
      {{ data.sourceSession }}
    </div>
  </div>
  <div *ngFor="let connectionId of data.connectionsIds; trackBy: trackById; let i = index">
    <div *ngIf="i === 0; else activityName" class="d-flex-start">
      <accenture-icon [size]="iconSize.Small" [colorType]="iconColor.Secondary"> icon-corner-left-up </accenture-icon>
      <div class="width-100 pl-8 pb-8">
        {{ data.activitiesById?.[connectionId]?.name }}
      </div>
    </div>
    <ng-template #activityName>
      <div class="pl-24 pb-8">{{ data.activitiesById?.[connectionId]?.name }}</div>
    </ng-template>
  </div>

  <div class="d-flex-start">
    <accenture-icon [size]="iconSize.Small" [colorType]="iconColor.Secondary">icon-done</accenture-icon>
    <div class="pl-8">
      {{ data.connectionCriteria }}
    </div>
  </div>
</div>
