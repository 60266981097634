import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AppState, selectActivityConfigurationsMap } from '@accenture/global-store';

@Injectable()
export class ActivityVisibleGuard {
    constructor(private router: Router, private store: Store<AppState>) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        const activityType = route.data.activityType;

        return this.store.pipe(
            select(selectActivityConfigurationsMap),
            map(activityConfigurationsMap => {
                if (activityConfigurationsMap[activityType] && activityConfigurationsMap[activityType].visible) {
                    return true;
                }

                this.router.navigate(['/']);
                return false;
            }),
        );
    }
}
