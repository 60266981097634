export const characterLimitText = (currentAmount: number, totalLimit: number): string => {
    return currentAmount >= totalLimit
        ? 'Character limit has been reached'
        : `Character limit: ${currentAmount}/${totalLimit}`;
};
export const characterLimitMedium = 255;
export const characterLimitLarge = 512;
export const characterLimitXL = 1024;

export const determineHintClass = (currentAmount: number, totalLimit: number): string => {
    // 20 is the character limit border at which the styles need to change
    const charactersLeft = totalLimit - currentAmount;
    if (charactersLeft < 20 && charactersLeft !== 0) {
        return HintClassType.INPUT_WARNING;
    } else if (charactersLeft === 0) {
        return HintClassType.INPUT_ERROR;
    } else {
        return HintClassType.INPUT_DEFAULT;
    }
};

export const HintClassType = {
    INPUT_WARNING: 'mat-hint-warning',
    INPUT_ERROR: 'mat-hint-error',
    INPUT_DEFAULT: 'mat-hint-default',
};
