import { inputPlaceholders, ParentType, validationHints, validationMessages } from '@accenture/shared/data';

export const noResponseText = 'No response given';

export const noFileAttachedText = 'No file attached';

export const defaultCommentTitle = 'Enter comments about this response here...';

export const noMatchFiltersCriteria = {
    noMatchFilterCriteria: 'No responses match your filter criteria',
};

export const defaultActivityScreenBackgroundUrl = '/assets/activity-shared-ui/svg/waiting-screen.svg';

export const emptyOptionImageUrl = '/assets/activity-shared-ui/svg/empty-option.svg';

export const tooltipAttributesInfo = (parentType: ParentType) =>
    [ParentType.Projects, ParentType.ProjectTemplates].includes(parentType)
        ? 'Please go to the project options to add attributes'
        : 'Please add current session to a project to configure attributes';

export const dataFlowSnackbarText = {
    inProgressTitle: 'Data move in progress',
    inProgressMessage: 'Data is being moved to the destination activity',
    successTitle: 'Data moved',
};

export const customTooltipIcon = {
    iconFocus: 'icon-focus',
};

export const customTooltipKey = {
    F: 'F',
};

export { inputPlaceholders, validationHints, validationMessages };
