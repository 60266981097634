import { Injectable } from '@angular/core';
import firebase from 'firebase/compat/app';
import { Timestamp } from 'firebase/firestore';
import { firstValueFrom, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import {
    AccessControlRole,
    DBPathHelper,
    Dictionary,
    ParentType,
    ProjectRole,
    UserAccess,
    UserSession,
} from '@accenture/shared/data';
import { FirestoreService } from '@accenture/shared/data-access';
import WhereFilterOp = firebase.firestore.WhereFilterOp;

@Injectable({
    providedIn: 'root',
})
export class UserAccessService {
    constructor(private firestoreService: FirestoreService) {}

    getByUserId(userId: string): Observable<UserAccess[]> {
        return this.firestoreService.getDocumentsByProperty('userAccess', 'userId', userId);
    }

    getProjectAssignmentsWithRole(userId: string, role: AccessControlRole): Observable<UserAccess[]> {
        return this.getAssignmentsWithRole(ParentType.Projects, userId, role);
    }

    getTemplateAssignmentsWithRole(userId: string, role: AccessControlRole): Observable<UserAccess[]> {
        return this.getAssignmentsWithRole(ParentType.Templates, userId, role);
    }

    getProjectAssignmentsByUserId(userId: string): Observable<UserAccess[]> {
        return this.getAssignments(userId, 'projectId');
    }

    getSessionsAssignmentsByUserId(userId: string): Observable<UserAccess[]> {
        return this.getAssignments(userId, 'sessionId');
    }

    getProjectAssignmentsByUserIdWithoutCaching(userId: string): Observable<UserAccess[]> {
        return this.getAssignmentsWithoutCaching(userId, 'projectId');
    }

    getTemplateAssignmentsByUserId(userId: string): Observable<UserAccess[]> {
        return this.getAssignments(userId, 'templateId', ParentType.Templates);
    }

    getTemplatesProjectAssignmentsByUserId(userId: string): Observable<UserAccess[]> {
        return this.getAssignments(userId, 'templateId', ParentType.ProjectTemplates);
    }

    getTemplatesActivityAssignmentsByUserId(userId: string): Observable<UserAccess[]> {
        return this.getAssignments(userId, 'templateId', ParentType.ActivityTemplates);
    }

    getTemplatesByUserId(userId: string): Observable<UserAccess[]> {
        const templatesTypes = [
            ParentType.Templates,
            ParentType.ProjectTemplates,
            ParentType.ActivityTemplates,
        ] as string[];
        const queries = [
            ['templateType', 'in', templatesTypes],
            ['userId', '==', userId],
        ] as [property: string, operatorsValue: WhereFilterOp, value: any][];

        return this.firestoreService
            .getDocumentsByCombinedProperty('userAccess', queries)
            .pipe(
                map((assignments: UserAccess[]) =>
                    assignments.filter((assignment) => !assignment.public).sort(UserAccessService.orderByUpdatedDesc),
                ),
            );
    }

    // TODO: remove old function getTemplatesByUserId after implementation of collection
    getTemplatesByUserIdNew(userId: string): Observable<UserAccess[]> {
        const templatesTypes = [ParentType.Templates, ParentType.ActivityTemplates] as string[];
        const queries = [
            ['templateType', 'in', templatesTypes],
            ['userId', '==', userId],
        ] as [property: string, operatorsValue: WhereFilterOp, value: any][];

        return this.firestoreService
            .getDocumentsByCombinedProperty('userAccess', queries)
            .pipe(
                map((assignments: UserAccess[]) =>
                    assignments.filter((assignment) => !assignment.public).sort(UserAccessService.orderByUpdatedDesc),
                ),
            );
    }

    getUserAssignmentByParentType(parentType: ParentType, parentId: string, userId: string): Observable<UserAccess> {
        switch (parentType) {
            case ParentType.Templates:
                return this.getUserAssignmentBySessionTemplateId(userId, parentId);
            case ParentType.Projects:
                return this.getUserAssignmentByProjectId(userId, parentId);
            case ParentType.ProjectTemplates:
                return this.getUserAssignmentByProjectTemplateId(userId, parentId);
        }
    }

    getUserAssignmentByProjectId(userId: string, projectId: string): Observable<UserAccess> {
        return this.firestoreService
            .getDocumentsByMultipleProperties<UserAccess>(
                'userAccess',
                new Map([
                    ['projectId', projectId],
                    ['userId', userId],
                ]),
            )
            .pipe(
                map((userAccess: UserAccess[]) => {
                    if (userAccess.length > 1) {
                        console.warn(
                            'UserAccessService::getUserAssignmentByProjectId - more than 1 userAccess documents for this user and project',
                            userAccess,
                        );
                    }

                    return userAccess.length > 0 ? userAccess[0] : null;
                }),
            );
    }

    // TODO: Delete after project deprecation
    getUserAssignmentsByProjectId(projectId: string): Observable<UserAccess[]> {
        return this.firestoreService.getDocumentsByPropertyWithoutCaching<UserAccess>(
            'userAccess',
            'projectId',
            projectId,
        );
    }

    // TODO: Delete after project deprecation
    getUserAssignmentsBySessionId(sessionId: string): Observable<UserAccess[]> {
        return this.firestoreService.getDocumentsByPropertyWithoutCaching<UserAccess>(
            'userAccess',
            'sessionId',
            sessionId,
        );
    }

    getUserAssignmentByProjectIdWithoutCaching(userId: string, projectId: string): Observable<UserAccess> {
        return this.firestoreService
            .getDocumentsByMultiplePropertiesWithoutCaching<UserAccess>(
                'userAccess',
                new Map([
                    ['projectId', projectId],
                    ['userId', userId],
                ]),
            )
            .pipe(
                map((userAccess: UserAccess[]) => {
                    if (userAccess.length > 1) {
                        console.warn(
                            'UserAccessService::getUserAssignmentByProjectId - more than 1 userAccess documents for this user and project',
                            userAccess,
                        );
                    }

                    return userAccess.length > 0 ? userAccess[0] : null;
                }),
            );
    }

    getAllUserAssignmentByProjectIdMap(projectId: string): Observable<Dictionary<UserAccess>> {
        return this.firestoreService
            .getDocumentsByPropertyWithoutCaching<UserAccess>('userAccess', 'projectId', projectId)
            .pipe(
                map((userAccess: UserAccess[]) => {
                    return userAccess.reduce((acc, item) => {
                        if (!!item.userId) {
                            acc[item.userId] = item;
                        }
                        return acc;
                    }, {});
                }),
            );
    }

    getAllUserAssignmentBySessionIdMap(sessionId: string): Observable<Dictionary<UserAccess>> {
        return this.firestoreService
            .getDocumentsByPropertyWithoutCaching<UserAccess>('userAccess', 'sessionId', sessionId)
            .pipe(
                map((userAccess: UserAccess[]) => {
                    return userAccess.reduce((acc, item) => {
                        if (!!item.userId) {
                            acc[item.userId] = item;
                        }
                        return acc;
                    }, {});
                }),
            );
    }

    getUserAssignmentByParentTemplateId(
        userId: string,
        parentType: ParentType,
        templateId: string,
    ): Observable<UserAccess> {
        return this.firestoreService
            .getDocumentsByMultipleProperties<UserAccess>(
                'userAccess',
                new Map([
                    ['templateId', templateId],
                    ['templateType', parentType],
                    ['userId', userId],
                ]),
            )
            .pipe(
                map((userAccess: UserAccess[]) => {
                    if (userAccess.length > 1) {
                        console.warn(
                            `UserAccessService::getUserAssignmentByParentTemplateId - more than 1 userAccess documents for this user and ${parentType} template`,
                            userAccess,
                        );
                    }

                    return userAccess.length > 0 ? userAccess[0] : null;
                }),
            );
    }

    getUserAssignmentBySessionTemplateId(userId: string, templateId: string): Observable<UserAccess> {
        return this.firestoreService
            .getDocumentsByMultipleProperties<UserAccess>(
                'userAccess',
                new Map([
                    ['templateId', templateId],
                    ['templateType', ParentType.Templates],
                    ['userId', userId],
                ]),
            )
            .pipe(
                map((userAccess: UserAccess[]) => {
                    if (userAccess.length > 1) {
                        console.warn(
                            'UserAccessService::getUserAssignmentBySessionTemplateId - more than 1 userAccess documents for this user and session template',
                            userAccess,
                        );
                    }

                    return userAccess.length > 0 ? userAccess[0] : null;
                }),
            );
    }

    getUserAssignmentByProjectTemplateId(userId: string, projectTemplateId: string): Observable<UserAccess> {
        return this.firestoreService
            .getDocumentsByMultipleProperties<UserAccess>(
                'userAccess',
                new Map([
                    ['templateId', projectTemplateId],
                    ['templateType', ParentType.ProjectTemplates],
                    ['userId', userId],
                ]),
            )
            .pipe(
                map((userAccess: UserAccess[]) => {
                    if (userAccess.length > 1) {
                        console.warn(
                            'UserAccessService::getUserAssignmentByProjectTemplateId - more than 1 userAccess documents for this user and project template',
                            userAccess,
                        );
                    }

                    return userAccess.length > 0 ? userAccess[0] : null;
                }),
            );
    }

    async addUserAccess(userAccess: UserAccess): Promise<void> {
        await this.firestoreService.addDocument('/userAccess', {
            ...userAccess,
            created: this.firestoreService.timestamp,
            updated: this.firestoreService.timestamp,
        });
    }

    async provideAccessForGuest(projectId: string, projectOwnerId: string, guestId: string): Promise<void> {
        const userAssignment = await firstValueFrom(this.getUserAssignmentByProjectId(projectId, projectOwnerId));

        await this.addUserAccess({
            ...userAssignment,
            userId: guestId,
            role: ProjectRole.Member,
        } as unknown as UserAccess);
    }

    async provideProjectAccess(
        userId: string,
        projectId: string,
        guest: boolean,
        role?: AccessControlRole,
        sessionId?: string,
    ): Promise<void> {
        await this.firestoreService.cloudFunctionCallable('provideAccessByUid', {
            userId,
            projectId,
            guest,
            role: role || ProjectRole.Member,
            sessionId: sessionId || '',
        });
    }

    async updateProjectAssignmentByProjectId(
        projectId: string,
        userId: string,
        data?: Partial<UserAccess>,
    ): Promise<void> {
        const userAssignments = await firstValueFrom(
            this.firestoreService.getDocumentsByMultipleProperties<UserAccess>(
                'userAccess',
                new Map([
                    ['projectId', projectId],
                    ['userId', userId],
                ]),
            ),
        );

        const userAccessId = userAssignments[0]?.id;

        if (!userAccessId) {
            return;
        }

        await this.firestoreService.update(`userAccess/${userAccessId}`, {
            ...data,
            lastViewed: this.firestoreService.timestamp,
        });
    }

    async updateSessionAssignmentBySessionId(
        sessionId: string,
        userId: string,
        data?: Partial<UserAccess>,
    ): Promise<void> {
        const userAssignments = await firstValueFrom(
            this.firestoreService.getDocumentsByMultipleProperties<UserAccess>(
                'userAccess',
                new Map([
                    ['sessionId', sessionId],
                    ['userId', userId],
                ]),
            ),
        );

        const userAccessId = userAssignments[0]?.id;

        if (!userAccessId) {
            return;
        }

        await this.firestoreService.update(`userAccess/${userAccessId}`, {
            ...data,
            lastViewed: this.firestoreService.timestamp,
        });
    }

    async updateProjectTemplateAssignmentByProjectId(
        projectTemplateId: string,
        userId: string,
        data?: Partial<UserAccess>,
    ): Promise<void> {
        const userAssignments = await firstValueFrom(
            this.firestoreService.getDocumentsByMultipleProperties<UserAccess>(
                'userAccess',
                new Map([
                    ['templateId', projectTemplateId],
                    ['templateType', ParentType.ProjectTemplates],
                    ['userId', userId],
                ]),
            ),
        );

        const userAccessId = userAssignments[0]?.id;

        if (!userAccessId) {
            return;
        }

        await this.firestoreService.update(`userAccess/${userAccessId}`, {
            ...data,
            lastViewed: this.firestoreService.timestamp,
        });
    }

    // async changeProjectUserAccessTeamMembersRole(
    //     projectId: string,
    //     userId: string,
    //     role: AccessControlRole,
    // ): Promise<void> {
    //     this.firestoreService.cloudFunctionCallable('changeUserAccessTeamMembersRole', { projectId, userId, role });
    // }

    // async changeTemplateUserAccessTeamMembersRole(
    //     templateId: string,
    //     userId: string,
    //     role: AccessControlRole,
    // ): Promise<void> {
    //     this.firestoreService.cloudFunctionCallable('changeUserAccessTeamMembersRole', { templateId, userId, role });
    // }

    async removeProjectAssignmentByUserId(projectId: string, userId: string): Promise<void> {
        const userAssignments = await firstValueFrom(
            this.firestoreService.getDocumentsByMultipleProperties<UserAccess>(
                'userAccess',
                new Map([
                    ['projectId', projectId],
                    ['userId', userId],
                ]),
            ),
        );

        const userAccessId = userAssignments[0]?.id;
        if (!userAccessId) {
            return;
        }

        await this.firestoreService.delete(`userAccess/${userAccessId}`);
    }

    private getAssignments(userId: string, orderByField: string, parentType?: ParentType): Observable<UserAccess[]> {
        const query = new Map([['userId', userId]]);
        if (parentType) {
            // TODO: better to update with parentType naming
            query.set('templateType', parentType);
        }
        // We use the orderByField to make sure we only get documents where that property is defined on the UserAccess document
        return this.firestoreService.getDocumentsByMultipleProperties('userAccess', query, orderByField).pipe(
            // We are then sorting the returning documents by the updated property in descending order
            map((assignments: UserAccess[]) => assignments.sort(UserAccessService.orderByUpdatedDesc)),
        );
    }

    private getAssignmentsWithoutCaching(
        userId: string,
        orderByField: string,
        parentType?: ParentType,
    ): Observable<UserAccess[]> {
        const query = new Map([['userId', userId]]);
        if (parentType) {
            // TODO: better to update with parentType naming
            query.set('templateType', parentType);
        }
        // We use the orderByField to make sure we only get documents where that property is defined on the UserAccess document
        return this.firestoreService
            .getDocumentsByMultiplePropertiesWithoutCaching('userAccess', query, orderByField)
            .pipe(
                // We are then sorting the returning documents by the updated property in descending order
                map((assignments: UserAccess[]) => assignments.sort(UserAccessService.orderByUpdatedDesc)),
            );
    }

    private static orderByUpdatedDesc(a: UserAccess, b: UserAccess): number {
        const aUpdated = a.updated as Timestamp;
        const bUpdated = b.updated as Timestamp;
        return bUpdated?.toMillis() - aUpdated?.toMillis();
    }

    private getAssignmentsWithRole(
        parentType: ParentType,
        userId: string,
        role: AccessControlRole,
    ): Observable<UserAccess[]> {
        return this.firestoreService
            .getDocumentsByMultipleProperties<UserAccess>(
                'userAccess',
                new Map([
                    ['userId', userId],
                    ['role', role],
                ]),
            )
            .pipe(
                map((userAccesses) => {
                    const isProject = parentType === ParentType.Projects;
                    return userAccesses.filter(({ projectId, templateId }) => (isProject ? projectId : templateId));
                }),
            );
    }
}
