<div class="invitations-form">
  <div
    class="d-flex-column"
    [ngClass]="{
      'flex-gap-16': control.value.length
    }"
  >
    <mat-form-field appearance="outline" class="tt9-form-field hint" floatLabel="auto">
      <mat-label *ngIf="emailListName">{{ emailListName }}'s email address</mat-label>
      <mat-label *ngIf="!emailListName">Email address</mat-label>

      <input
        #emailsInput
        matInput
        [placeholder]="inputPlaceholders.enterEmail"
        [matAutocomplete]="auto"
        [formControl]="emailsInputControl"
        [maxlength]="characterLimitMedium"
        (keyup.enter)="addChip()"
        (blur)="addChip()"
      />

      <div matSuffix>
        <ng-content select="input-suffix"></ng-content>
      </div>

      <mat-hint class="mat-hint-limit" [class]="determineHintClass(emailsInput.value.length, characterLimitMedium)">
        {{ characterLimitText(emailsInput.value.length, characterLimitMedium) }}
      </mat-hint>

      <mat-error *ngIf="emailsInputControl.hasError(validationErrors.nonAcnEmail)">
        {{ validationMessages.nonAcnEmail }}
      </mat-error>

      <mat-error *ngIf="emailsInputControl.hasError(validationErrors.invalidEmail)">
        {{ validationMessages.invalidEmail }}
      </mat-error>

      <mat-error *ngIf="emailsInputControl.hasError(validationErrors.restrictedEmail)">
        {{ getRestrictedEmailMessage() }}
      </mat-error>

      <mat-autocomplete #auto="matAutocomplete">
        <ng-container>
          <mat-option
            *ngFor="let option of emailsAutoComplete$ | async; trackBy: trackByValue"
            [value]="option"
            (mousedown)="$event.preventDefault()"
          >
            {{ option }}
          </mat-option>
        </ng-container>
      </mat-autocomplete>
    </mat-form-field>

    <mat-chip-listbox *ngIf="!hideChips" hideSingleSelectionIndicator class="invitations-form-chip-list tt9-chip">
      <mat-chip-option *ngFor="let chip of control.value; trackBy: trackByValue" class="invitations-form-chip">
        <span>{{ chip }}</span>
        <accenture-icon matChipRemove (click)="removeChip(chip)">icon-close</accenture-icon>
      </mat-chip-option>
    </mat-chip-listbox>
  </div>
</div>
