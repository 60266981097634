import moment from 'moment';

import {
    ChangeDetectionStrategy,
    Component,
    Input,
    ViewChild,
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';

import { SessionRecordingsService } from '@accenture/activity/shared/domain';
import {
    SessionRecording,
    SessionRecordingMediaType,
    SessionRecordingType,
} from '@accenture/shared/data';
import {
    ConfirmationDialogComponent,
    DialogService,
    SnackbarService,
} from '@accenture/shared/ui';
import { trackById } from '@accenture/shared/util';

import {
    SessionRecorderListViewTranscriptionComponent,
} from '../session-recorder-list-view-transcription/session-recorder-list-view-transcription.component';

// eslint-disable-next-line max-len

const MediaTypeDisplay = {
    [SessionRecordingMediaType.AudioOnly]: 'Audio Only',
    [SessionRecordingMediaType.VideoOnly]: 'Video Only',
    [SessionRecordingMediaType.AudioVideo]: 'Video+Audio',
};

@Component({
    selector: 'accenture-session-recorder-list',
    templateUrl: './session-recorder-list.component.html',
    styleUrls: ['./session-recorder-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SessionRecorderListComponent {
    trackById = trackById;
    @Input() sessionId: string;
    @Input() recordings!: SessionRecording[];
    @ViewChild(MatPaginator) paginator: MatPaginator;

    recordingType = SessionRecordingType;
    mediaType = MediaTypeDisplay;

    constructor(
        private sessionRecordingsService: SessionRecordingsService,
        private dialogService: DialogService,
        private snackbarService: SnackbarService
    ) {}

    formatDate(timestamp) {
        const converted = timestamp.toDate();
        return moment(converted).format('dddd, MMMM Do YYYY, h:mm:ss a');
    }

    viewTranscription(recordingId: string, mediaType: SessionRecordingMediaType, transcription: string): void {
        this.dialogService.open(SessionRecorderListViewTranscriptionComponent, {
            recordingId,
            mediaType,
            transcription,
            height: '550px',
        });
    }

    downloadRecording(fileUrl): void {
        this.sessionRecordingsService.downloadRecording(fileUrl);
    }

    deleteRecording(recordingId: string): void {
        this.dialogService.open(ConfirmationDialogComponent, {
            title: 'Delete Recording',
            confirmBtnText: 'Remove',
            cancelBtnText: 'Cancel',
            width: 'auto',
            text: 'Are you sure you want to delete this recording?',
            confirm: async () => {
                try {
                    const { sessionId } = this;
                    await this.sessionRecordingsService.delete(sessionId, recordingId);
                    this.snackbarService.showSuccessSnackBar(`Recording deleted`, `Successfully deleted recording`);
                } catch (error) {
                    this.snackbarService.showErrorSnackBar(`Had trouble deleting record: `, error);
                }
            },
        });
    }
}
