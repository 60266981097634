<div class="p-32">
  <div class="information-container">
    <accenture-icon [colorType]="iconColor || defaultColor" [size]="iconSize || defaultSize">{{
      iconName
    }}</accenture-icon>

    <div class="ml-32">
      <h4 class="tt9-h4-medium mb-8">{{ title }}</h4>
      <p class="tt9-body-1 break-word" [innerHTML]="text"></p>
    </div>
  </div>

  <button *ngIf="!hideConfirmationButton" class="tt9-btn ml-auto mt-32" [routerLink]="['../']">Ok</button>
</div>
