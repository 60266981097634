import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { map, startWith } from 'rxjs';

import { AppState, selectMaintenanceConfig } from '@accenture/global-store';
import { MaintenanceConfig } from '@accenture/shared/data';
@Component({
    selector: 'accenture-maintenance',
    templateUrl: './maintenance.component.html',
    styleUrls: ['./maintenance.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [CommonModule],
})
export class MaintenanceComponent {
    private readonly store = inject(Store<AppState>);

    vm$ = this.store.select(selectMaintenanceConfig).pipe(
        map((maintenanceConfig: MaintenanceConfig) => {
            return {
                maintenanceActive: maintenanceConfig.maintenanceActive,
                title: maintenanceConfig.title,
                message: maintenanceConfig.message,
            };
        }),
        startWith({
            maintenanceActive: false,
            title: '',
            message: '',
        }),
    );
}
