import { Pipe, PipeTransform } from '@angular/core';

import { UserType, userTypesMap } from '@accenture/shared/data';

@Pipe({
    name: 'displayUserType',
})
export class DisplayUserTypePipe implements PipeTransform {
    transform(type: UserType, email?: string): string {
        // Temporary solution for release 7
        if (email) {
            return userTypesMap[email.includes('@accenture') ? UserType.AccentureUser : UserType.GeneralUser];
        }
        return userTypesMap[type] || userTypesMap[UserType.Guest];
    }
}
