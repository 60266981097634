import { Action, createReducer, on } from '@ngrx/store';

import { AttributeClass } from '@accenture/shared/data';
import { getAttributeClasses, getAttributeClassesSuccess } from './attributes.actions';

export const ATTRIBUTES_FEATURE_KEY = 'attributes';

export interface AttributesState {
    attributeClasses: AttributeClass[] | undefined;
}

const initialState: AttributesState = {
    attributeClasses: undefined,
};

const _attributesReducer = createReducer(
    initialState,
    on(getAttributeClasses, state => ({ ...state })),
    on(getAttributeClassesSuccess, (state, { attributeClasses }) => ({
        ...state,
        attributeClasses,
    })),
);

export function attributesReducer(state: any, action: Action) {
    return _attributesReducer(state, action);
}
