import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { combineLatest, distinctUntilChanged, map, Observable, startWith, switchMap } from 'rxjs';

import { FiltersService, OptionsStore, ProjectOptionsService } from '@accenture/erp-deployment/shared/domain';
import { AppState, selectAuthenticatedUserId, selectParentType } from '@accenture/global-store';
import {
    CollectionOptions,
    CollectionsSortObject,
    Dictionary,
    OptionsForSortingByNumberOfUses,
    ParentType,
    ProjectOptions,
    ProjectOptionsType,
    SelectedProjectOptions,
    SessionOptions,
} from '@accenture/shared/data';

export interface ProjectOptionsViewModel {
    selectedProjectOptions: SelectedProjectOptions;
    clients: ProjectOptionsType[];
    tags: ProjectOptionsType[];
    currentOptionToDisplay: ProjectOptions | SessionOptions | CollectionOptions | null;
    sortCollectionsData: CollectionsSortObject;
    isPublicProjectTemplate: boolean;
    isLoading: boolean;
    hasClientChanged: boolean;
}

const defaultProjectOptionsModel = {
    selectedProjectOptions: {} as SelectedProjectOptions,
    clients: [],
    tags: [],
    sortCollectionsData: {} as CollectionsSortObject,
    currentOptionToDisplay: null,
    isPublicProjectTemplate: false,
    isLoading: true,
    hasClientChanged: false,
};

@Injectable()
export class ProjectOptionsFacade {
    vm$ = this.buildViewModel();

    private userId!: string;
    private currentOptionToDisplay: ProjectOptions;
    // all options from db
    private projectOptionsValues: { [name: string]: ProjectOptionsType[] } = {
        clients: [],
        tags: [],
    };
    private projectOptionsToCreateIds: { [name: string]: string[] } = {
        clients: [],
        tags: [],
    };

    constructor(
        private store: Store<AppState>,
        private projectOptionsService: ProjectOptionsService,
        private optionsStore: OptionsStore,
        private filtersService: FiltersService,
    ) {}

    showOptionChips(optionType: ProjectOptions): void {
        this.optionsStore.setCurrentOptionToDisplay(optionType);
    }

    updateProjectOptions(option: Dictionary<string>, currentType?: ProjectOptions): void {
        const optionType = currentType || this.currentOptionToDisplay;
        const optionId = Object.keys(option)[0];

        this.optionsStore.setSelectedProjectOption({ option, optionType });

        if (
            this.projectOptionsToCreateIds[optionType]
            && this.projectOptionsToCreateIds[optionType].includes(optionId)
        ) {
            this.optionsStore.removeProjectOptionToCreate({ optionId, optionType });
        }
    }

    updateProjectOptionsToCreate(option: ProjectOptionsType, optionType?: ProjectOptions): void {
        const currentOptionToDisplay = optionType || this.currentOptionToDisplay;
        const data = {
            option,
            optionType: currentOptionToDisplay,
        };
        this.optionsStore.setProjectOptionToCreate(data);
    }

    getFilteredOptions(
        sessionOption: ProjectOptions,
        selectedOptions: Dictionary<string>,
        value: string,
    ): { filteredOptions: ProjectOptionsType[]; isOptionExist: boolean } {
        let isOptionExist = false;
        const filteredOptions = this.projectOptionsValues[sessionOption].filter(option => {
            const optionName = option.name?.toLowerCase();

            if (optionName === value) {
                isOptionExist = true;
            }

            return optionName?.includes(value) && !Object.keys(selectedOptions || {})?.includes(option.id);
        });

        return {
            isOptionExist,
            filteredOptions,
        };
    }

    setHasClientChanged(value: boolean): void {
        this.optionsStore.setHasClientChanged(value);
    }

    async addNewOption(optionType: ProjectOptions, optionName: string): Promise<void> {
        const id = this.projectOptionsService.getNewOptionId();
        const newOption = {
            [id]: optionName,
        };

        const optionToCreate = {
            id,
            name: optionName,
        } as ProjectOptionsType;

        this.updateProjectOptions(newOption, optionType);
        this.updateProjectOptionsToCreate(optionToCreate, optionType);
    }

    async updateFilters(filters: CollectionsSortObject): Promise<void> {
        await this.projectOptionsService.updateCollectionsSorting(this.userId, filters);
    }

    private sortProjectOptions(
        options: ProjectOptionsType[],
        sortOptions: CollectionsSortObject,
    ): ProjectOptionsType[] {
        return this.filtersService.sortOptionsData(options, sortOptions, OptionsForSortingByNumberOfUses.Projects);
    }

    private buildViewModel(): Observable<ProjectOptionsViewModel> {
        return this.store.select(selectAuthenticatedUserId).pipe(
            switchMap(userId => {
                this.userId = userId;

                return combineLatest([
                    this.optionsStore.selectedProjectOptions$,
                    this.projectOptionsService.getOptions(ProjectOptions.Client),
                    this.projectOptionsService.getOptions(ProjectOptions.Tag),
                    this.optionsStore.currentOptionToDisplay$,
                    this.optionsStore.projectOptionsToCreateIds$,
                    this.optionsStore.projectOptionsToCreate$,
                    this.projectOptionsService.getCurrentUserCollectionsSorting(userId),
                    this.store.select(selectParentType),
                    this.optionsStore.hasClientChanged$.pipe(distinctUntilChanged()),
                ]).pipe(
                    map(
                        ([
                            selectedProjectOptions,
                            clients,
                            tags,
                            currentOptionToDisplay,
                            projectOptionsToCreateIds,
                            projectOptionsToCreate,
                            sortCollectionsData,
                            parentType,
                            hasClientChanged,
                        ]) => {
                            this.currentOptionToDisplay = currentOptionToDisplay as ProjectOptions;
                            this.projectOptionsToCreateIds = projectOptionsToCreateIds;
                            this.projectOptionsValues = {
                                clients: [...clients, ...(projectOptionsToCreate.clients || [])],
                                tags: [...tags, ...(projectOptionsToCreate.tags || [])],
                            };

                            let options = { ...this.projectOptionsValues };

                            if (currentOptionToDisplay) {
                                options = this.sortDataToDisplay(sortCollectionsData, options.clients, options.tags);
                            }

                            return {
                                sortCollectionsData,
                                currentOptionToDisplay,
                                selectedProjectOptions,
                                hasClientChanged,
                                isPublicProjectTemplate: parentType === ParentType.PublicProjectTemplates,
                                tags: options.tags,
                                clients: options.clients,
                                isLoading: false,
                            };
                        },
                    ),
                );
            }),
            startWith(defaultProjectOptionsModel),
        );
    }

    private sortDataToDisplay(
        sortCollectionsData: CollectionsSortObject,
        clients: ProjectOptionsType[],
        tags: ProjectOptionsType[],
    ): Dictionary<ProjectOptionsType[]> {
        return {
            clients: this.sortProjectOptions(clients, sortCollectionsData) || [],
            tags: this.sortProjectOptions(tags, sortCollectionsData) || [],
        };
    }
}
