import { removeEmptyKeys } from '@accenture/shared/util';

import { SessionRole } from '../access-control';
import { Session } from '../session';
import { InAppNotificationType } from './in-app-notification-type';
import { BaseInAppNotification } from './in-app-notifications';

export class InAppSessionRoleChangeNotification extends BaseInAppNotification {
    sessionId: string;
    sessionName: string;
    role: SessionRole;
    newRole: SessionRole;

    constructor(notification: any);
    constructor(userId: string, session: Session, role: SessionRole, newRole: SessionRole);
    constructor(
        notificationOrUserId: any,
        session?: Session,
        role?: SessionRole,
        newRole?: SessionRole,
    ) {
        if (typeof notificationOrUserId === 'object') {
            super(notificationOrUserId as BaseInAppNotification);

            this.id = notificationOrUserId.id;
            this.type = InAppNotificationType.SessionRoleChange;
            this.sessionId = notificationOrUserId.sessionId;
            this.sessionName = notificationOrUserId.sessionName;
            this.role = notificationOrUserId.role;
            this.newRole = notificationOrUserId.newRole;
        } else {
            super(InAppNotificationType.SessionRoleChange, notificationOrUserId);

            this.sessionId = session.id;
            this.sessionName = session.name;
            this.role = role;
            this.newRole = newRole;
        }
    }

    createSerializableObject(): Record<string, unknown> {
        return removeEmptyKeys({
            type: this.type,
            userId: this.userId,
            unread: this.unread,
            sessionId: this.sessionId,
            sessionName: this.sessionName,
            role: this.role,
            newRole: this.newRole,
            created: this.created,
            updated: this.updated,
        });
    }
}
