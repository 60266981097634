import { Injectable } from '@angular/core';

import { FirestoreService } from '@accenture/shared/data-access';

@Injectable({
    providedIn: 'root',
})
export class ExcelService {
    constructor(private firestoreService: FirestoreService) {}

    async getExcelSheetTabs(fileUrl: string): Promise<{ key: number; value: string }[] | null> {
        try {
            const result = await this.firestoreService.cloudFunctionCallable('getExcelSheetTabs', {
                fileUrl,
            });

            return result as { key: number; value: string }[];
        } catch (error) {
            console.error('Upload error');
            return Promise.reject();
        }
    }
}
