import { RouterReducerState } from '@ngrx/router-store';
import { createFeatureSelector, createSelector } from '@ngrx/store';

import { activityTypes, linkAccessUrls, ParentType } from '@accenture/shared/data';

import { RouterStateUrl } from './router.reducer';

export const selectRouterState = createFeatureSelector<RouterReducerState<RouterStateUrl>>('router');

export const getRouterState = createSelector(selectRouterState, (state) => state?.state);

export const selectRouterData = createSelector(getRouterState, (state) => state?.data || {});

export const selectParentType = createSelector(selectRouterData, (data) => data.parentType);

export const selectAdminRoute = createSelector(selectRouterData, (data) => data.adminRoute);

// TODO: Remove this code during project-related cleanup
export const selectIsAdministrationProject = createSelector(selectRouterData, (data) => data.administrationProject);

export const selectIsAdministrationSession = createSelector(selectRouterData, (data) => data.administrationSession);

export const selectRouterParams = createSelector(getRouterState, (routerState: RouterStateUrl) => routerState?.params);

export const selectRouterQueryParams = createSelector(
    getRouterState,
    (routerState: RouterStateUrl) => routerState?.queryParams || {},
);

export const selectParams = createSelector(getRouterState, (routerState: RouterStateUrl) => ({
    ...(routerState?.params || {}),
    ...(routerState?.queryParams || {}),
}));

export const selectTemplateId = createSelector(
    selectRouterParams,
    (routerParams) => routerParams && routerParams['templateId'],
);

// TODO: Remove this code during project-related cleanup
export const selectProjectId = createSelector(
    selectRouterParams,
    (routerParams) => routerParams && routerParams['projectId'],
);

export const selectCollectionId = createSelector(
    selectRouterParams,
    (routerParams) => routerParams && routerParams['collectionId'],
);

export const selectSessionId = createSelector(
    selectRouterParams,
    (routerParams) => routerParams && routerParams['sessionId'],
);

export const selectLinkAccessId = createSelector(
    selectRouterParams,
    (routerParams) => routerParams && routerParams['linkAccessId'],
);

export const selectFormId = createSelector(
    selectRouterParams,
    (routerParams) => routerParams && routerParams['formId'],
);

export const selectActivityId = createSelector(
    selectRouterParams,
    (routerParams) => routerParams && routerParams['activityId'],
);

export const selectUserId = createSelector(
    selectRouterParams,
    (routerParams) => routerParams && routerParams['userId'],
);

export const selectSectionId = createSelector(
    selectRouterQueryParams,
    (queryParams) => (queryParams && queryParams['sectionId']) || '',
);

export const selectCurrentQuestionId = createSelector(
    selectRouterQueryParams,
    (queryParams) => queryParams && queryParams['selectedQuestionId'],
);

export const selectGroupId = createSelector(
    selectRouterQueryParams,
    (queryParams) => queryParams && queryParams['groupId'],
);

export const selectSelectedSlideId = createSelector(
    selectRouterQueryParams,
    (queryParams) => queryParams && queryParams['selectedSlideId'],
);

export const selectQuickPollSummaryTab = createSelector(
    selectRouterQueryParams,
    (queryParams) => queryParams && queryParams['activeTab'],
);

export const selectCurrentAttributeId = createSelector(
    selectRouterQueryParams,
    (queryParams) => queryParams && queryParams['attributeId'],
);
// TODO: Delete after project deprecation
export const selectProjectIdAndSessionId = createSelector(selectProjectId, selectSessionId, (projectId, sessionId) => ({
    projectId,
    sessionId,
}));

export const selectSessionIdNew = createSelector(selectSessionId, (sessionId) => ({
    sessionId,
}));

export const selectTemplateIdAndProjectId = createSelector(
    selectTemplateId,
    selectProjectId,
    (templateId, projectId) => ({
        templateId,
        projectId,
    }),
);

export const selectTemplateIdAndFormId = createSelector(selectTemplateId, selectFormId, (templateId, formId) => ({
    templateId,
    formId,
}));

export const selectTemplateIdAndActivityId = createSelector(
    selectTemplateId,
    selectActivityId,
    (templateId, activityId) => ({ templateId, activityId }),
);

// TODO: Remove this code during project-related cleanup
export const selectProjectIdAndActivityId = createSelector(
    selectProjectId,
    selectActivityId,
    (projectId, activityId) => ({ projectId, activityId }),
);

// TODO: DELETE
export const selectProjectIdAndSessionIdAndActivityId = createSelector(
    selectProjectId,
    selectActivityId,
    selectSessionId,
    (projectId, activityId, sessionId) => ({ projectId, activityId, sessionId }),
);

export const selectSessionIdAndActivityId = createSelector(
    selectActivityId,
    selectSessionId,
    (activityId, sessionId) => ({ activityId, sessionId }),
);

export const selectIsActivityEdit = createSelector(
    getRouterState,
    (state) => state?.url.includes('-edit') || state?.url.includes('template'),
);

//TODO: remove projectId
export const selectActivityIdAndParentIds = createSelector(
    selectTemplateId,
    selectProjectId,
    selectCollectionId,
    selectSessionId,
    selectActivityId,
    selectIsActivityEdit,
    selectRouterData,
    (templateId, projectId, collectionId, sessionId, activityId, isActivityEdit, { parentType }) => ({
        templateId,
        projectId,
        collectionId,
        sessionId,
        activityId,
        isActivityEdit,
        parentType,
        parentId: projectId || sessionId || collectionId || templateId || activityId,
    }),
);

// TODO: Remove this code during project-related cleanup
export const selectProjectIdAndActivityIdAndQuestionId = createSelector(
    selectProjectId,
    selectActivityId,
    selectCurrentQuestionId,
    selectCurrentAttributeId,
    (projectId, activityId, selectedQuestionId, attributeId) => ({
        projectId,
        activityId,
        selectedQuestionId,
        attributeId,
    }),
);

export const selectRedirectUrl = createSelector(
    selectRouterQueryParams,
    (queryParams) => queryParams && queryParams['redirectUrl'],
);

export const selectCurrentTab = createSelector(
    selectRouterQueryParams,
    (queryParams) => queryParams && queryParams['tab'],
);

export const selectCurrentToolTab = createSelector(
    selectRouterQueryParams,
    (queryParams) => queryParams && queryParams['toolTab'],
);

export const isBranchingConnectionPage = createSelector(getRouterState, (state) => state.url.includes('branching'));

export const isSummaryPage = createSelector(getRouterState, (state) => state.url.includes('summary'));

export const isHomePage = createSelector(getRouterState, (state) => state?.url?.includes('home'));

export const isProfilePage = createSelector(
    getRouterState,
    (state) => state?.url?.includes('profile') && !state?.url?.includes('search='), // don't identify as profile page if url has (search=profile)
);

export const isAdminPage = createSelector(getRouterState, (state) => state?.url?.includes('admin'));

export const isLogin = createSelector(getRouterState, (state) => state?.url?.includes('authentication'));

export const isSingUp = createSelector(getRouterState, (state) => state?.url?.includes('registerUser'));

export const isTeamPage = createSelector(getRouterState, (state) => state?.url?.includes('team'));

export const isDashboardPage = createSelector(
    getRouterState,
    (state) => state?.url?.toLowerCase().includes('home') && state?.queryParams?.['tab']?.toLowerCase() === 'dashboard',
);

export const isValidateSummaryPage = createSelector(getRouterState, (state) => state.url.includes('validate-summary'));

export const isSummaryStatus = createSelector(getRouterState, (state) => state.url.includes('summary-status'));

export const isValidatePage = createSelector(getRouterState, (state) => state.url.includes('validate'));

export const isWaitingScreenPage = createSelector(getRouterState, (state) => state.url.includes('waiting-screen'));

export const isStatusScreenPage = createSelector(getRouterState, (state) => state.url.includes('status'));

export const isWaitingOrStatusScreenPage = createSelector(
    isWaitingScreenPage,
    isStatusScreenPage,
    (isWaiting, isStatus) => isWaiting || isStatus,
);

export const isLinkAccessPage = createSelector(getRouterState, (state) =>
    linkAccessUrls.some((linkAccessUrl) => state?.url?.includes(linkAccessUrl)),
);

export const isActivityPage = createSelector(
    getRouterState,
    (state) =>
        activityTypes.some((value) => state?.url?.includes(value.toLowerCase())) && !state?.url?.includes('search='), // don't identify as activity page if url has (search={activityType})
);

// TODO: Remove this code during project-related cleanup
export const isProjectPage = createSelector(
    getRouterState,
    (state) => state?.url?.includes('project/') || state?.data?.parentType === ParentType.Projects,
);

export const isSessionPage = createSelector(
    getRouterState,
    (state) => state?.url?.includes('sessions/') || state?.data?.parentType === ParentType.Sessions,
);

export const isCollectionPage = createSelector(
    getRouterState,
    (state) => state?.url?.includes('collections') || state?.data?.parentType === ParentType.Collections,
);

export const selectChildPage = createSelector(
    getRouterState,
    (state) => (state?.url || '').split('?')[0].split('/').pop() ?? '',
);

export const selectDisplayMode = createSelector(
    selectRouterQueryParams,
    (queryParams) => queryParams && queryParams['displayMode'],
);

export const isDashboardHomePage = createSelector(getRouterState, (state) =>
    state?.url?.toLowerCase().includes('dashboard/home'),
);
