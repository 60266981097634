<nav *ngIf="vm$ | async as vm" class="tt9-breadcrumb-container breadcrumbs">
  <ul class="tt9-breadcrumbs">
    <li *ngIf="vm.isSessionTemplate" class="breadcrumb">
      <a class="breadcrumb-link" [routerLink]="['/dashboard/templates']" [queryParams]="getQueryParams(vm.parentType)"
        >Session Templates</a
      >
    </li>
    <li *ngIf="vm.isActivityTemplate" class="breadcrumb">
      <a class="breadcrumb-link" [routerLink]="['/dashboard/templates']" [queryParams]="getQueryParams(vm.parentType)"
        >Activity Templates</a
      >
    </li>
    <li *ngIf="vm.isAdminUser" class="breadcrumb">
      <a class="breadcrumb-link" [routerLink]="['/dashboard/admin/users']">Users</a>
    </li>
    <li *ngIf="vm.isAdminSession" class="breadcrumb">
      <a class="breadcrumb-link" [routerLink]="['/dashboard/admin/sessions']">Sessions</a>
    </li>

    <!-- collection/session/activity template -->
    <li
      *ngIf="vm.collection?.id && vm.activity?.id && !(vm.isSessionTemplate || vm.isActivityTemplate)"
      class="breadcrumb"
    >
      <a
        #collectionName
        class="breadcrumb-link"
        matTooltipPosition="below"
        matTooltipClass="tt9-arrow-tooltip-above"
        [matTooltip]="vm.collection.name"
        [matTooltipDisabled]="isTooltipHidden"
        [routerLink]="getCollectionLink(vm.parentType, vm.collection.id)"
        (mouseover)="isDisabledTooltip(vm.collection.name)"
      >
        {{ sliceTextWithEllipsis(vm.collection?.name, breadcrumbsDisplayLimit) }}
      </a>
    </li>
    <!-- collection/session/activity template -->

    <!-- session/session template and admin -->
    <li *ngIf="vm.session?.id && (vm.activity?.id || vm.isSessionTemplate || vm.isAdminSession)" class="breadcrumb">
      <a
        #sessionName
        class="breadcrumb-link"
        matTooltipPosition="below"
        matTooltipClass="tt9-arrow-tooltip-above"
        [matTooltip]="vm.session.name"
        [matTooltipDisabled]="isTooltipHidden"
        [routerLink]="getSessionLink(vm.parentType, vm.session?.id, vm.isAdminSession)"
        (mouseover)="isDisabledTooltip(vm.session.name)"
      >
        {{ sliceTextWithEllipsis(vm.session?.name, breadcrumbsDisplayLimit) }}
      </a>
    </li>
    <!-- /session/session template -->

    <li *ngIf="vm.isTeamPage" class="breadcrumb">
      <a #team class="breadcrumb-link"> Session Team </a>
    </li>

    <!-- activity/activity template -->
    <li *ngIf="vm.activity?.id" class="breadcrumb">
      <a
        #activityName
        class="breadcrumb-link"
        matTooltipPosition="below"
        matTooltipClass="tt9-arrow-tooltip-above"
        [matTooltip]="vm.activity.name"
        [matTooltipDisabled]="isTooltipHidden"
        [routerLink]="getActivityLink(vm.parentType, vm.session?.id, vm.activity)"
        (mouseover)="isDisabledTooltip(vm.activity.name)"
      >
        {{ sliceTextWithEllipsis(vm.activity?.name, breadcrumbsDisplayLimit) }}
      </a>
    </li>
    <!-- /activity/activity template -->

    <!-- Admin User -->
    <li *ngIf="vm.user?.id" class="breadcrumb">
      <a
        #adminUserName
        class="breadcrumb-link"
        matTooltipPosition="below"
        matTooltipClass="tt9-arrow-tooltip-above"
        [matTooltip]="vm.user.displayName"
        [matTooltipDisabled]="isTooltipHidden"
        (mouseover)="isDisabledTooltip(vm.user.displayName)"
      >
        {{ sliceTextWithEllipsis(vm.user?.displayName, breadcrumbsDisplayLimit) }}
      </a>
    </li>
    <!-- /Admin User -->

    <!-- postfix -->
    <li *ngIf="postfix" class="breadcrumb">
      <a class="breadcrumb-link">{{ postfix }}</a>
    </li>
    <!-- /postfix -->
  </ul>
</nav>
