import { Pipe, PipeTransform } from '@angular/core';

import { DataFlowConnection, Dictionary } from '@accenture/shared/data';

@Pipe({
    name: 'displayConnectionSessionLabel',
    pure: true,
})
export class DisplayConnectionSessionLabelPipe implements PipeTransform {
    transform(connections: DataFlowConnection[], sessionsNames: Dictionary<string>): string {
        const connection = connections?.[0];
        return connection.sourceSessionId ? sessionsNames[connection.sourceSessionId] : '';
    }
}
