import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, Input } from '@angular/core';
import { RouterLink } from '@angular/router';

import { Activity, Dictionary, ParentType, TemplateTab,templateTab } from '@accenture/shared/data';
import { SharedMaterialModule } from '@accenture/shared/material';

import { BreadcrumbsTT9Facade } from './breadcrumbs-tt9-facade';

@Component({
    selector: 'accenture-breadcrumbs-tt9',
    standalone: true,
    templateUrl: './breadcrumbs-tt9.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [BreadcrumbsTT9Facade],
    imports: [CommonModule, RouterLink, SharedMaterialModule],
})
export class BreadcrumbsTt9Component {
    @Input() postfix?: string;

    private readonly breadcrumbsDisplayLimit: number = 9;
    private readonly facade: BreadcrumbsTT9Facade = inject(BreadcrumbsTT9Facade);

    readonly linkSymbolLimit: number = 15;
    isTooltipHidden = true;
    vm$ = this.facade.vm$;

    sessionLinksMap: Dictionary<(sessionId: string) => string[]> = {
        [ParentType.Sessions]: () => null,
        [ParentType.Templates]: (sessionId: string) => ['/session-template', `${sessionId}`],
        [ParentType.PublicSessionTemplates]: (sessionId: string) => ['/public-session-template', `${sessionId}`],
    };

    activityLinksMap: Dictionary<(activityId: string, type: string) => string[]> = {
        [ParentType.ActivityTemplates]: (activityId: string, type: string) => [
            '/activity-template',
            `${type}-edit`,
            activityId,
        ],
        [ParentType.PublicActivityTemplates]: (activityId: string, type: string) => [
            '/public-activity-template',
            `${type}-edit`,
            activityId,
        ],
    };

    collectionLinksMap: Dictionary<(collectionId: string) => string[]> = {
        [ParentType.Collections]: (collectionId: string) => ['/collections', `${collectionId}`],
    };

    isDisabledTooltip(name: string): void {
        this.isTooltipHidden = name?.length <= this.breadcrumbsDisplayLimit;
    }

    getSessionLink(parentType: ParentType, sessionId: string | undefined, isAdmin: boolean): string[] {
        return ![ParentType.Templates, ParentType.PublicSessionTemplates].includes(parentType) && !isAdmin
            ? ['/dashboard', ParentType.Sessions, `${sessionId}`]
            : this.sessionLinksMap[parentType](sessionId as string);
    }

    getCollectionLink(parentType: ParentType, collectionId: string | undefined): string[] {
        return ![ParentType.Templates, ParentType.Collections].includes(parentType)
            ? ['/dashboard', ParentType.Collections, `${collectionId}`]
            : this.collectionLinksMap[parentType](collectionId as string);
    }

    getActivityLink(
        parentType: ParentType,
        sessionId: string | undefined,
        activity: Activity | undefined,
    ): (string | undefined)[] {
        const isActivityTemplates = [ParentType.ActivityTemplates, ParentType.PublicActivityTemplates].includes(
            parentType,
        );
        if (activity && !sessionId && isActivityTemplates) {
            return this.activityLinksMap[parentType](activity.id, activity.type.toLowerCase());
        } else {
            const sessionLink = this.getSessionLink(parentType, sessionId, false);
            return activity ? [...sessionLink, activity.type?.toLowerCase(), activity.id] : sessionLink;
        }
    }

    sliceTextWithEllipsis(text: string, limit: number): string {
        return text?.length > limit ? `${text.slice(0, limit)}…` : text;
    }

    getQueryParams(parentType: ParentType): Dictionary<string> {
        return { templatesTab: templateTab[parentType] };
    }
}
