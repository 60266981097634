import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import firebase from 'firebase/compat/app';
import { Timestamp } from 'firebase/firestore';
import { isEqual } from 'lodash';
import { Observable, Subject, tap } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { fromToDateValidator } from '@accenture/erp-deployment/shared/domain';
import {
    DefaultFilterObject,
    displayRole,
    initialDefaultFilterObject,
    inputPlaceholders,
    notFoundOptionsText,
    optionsPanelTitle,
    ParentType,
    Phase,
    SessionOptions,
    SessionRole,
    SessionStatus,
    sessionStatusToDisplay,
    SortOptions,
    SortOrders,
    sortOrdersValues,
    SubPhase,
    Tag,
    validationMessages,
} from '@accenture/shared/data';
import { trackById } from '@accenture/shared/util';

import { rolesListForFilter, statusesListForFilter } from './constants';
import {
    SessionsFiltersAndSortNewFacade,
    SessionsFiltersAndSortNewViewModel,
} from './sessions-filters-and-sort-new-facade';

type FiltersAndSortForm = FormGroup<{
    roles: FormControl<string[]>;
    statuses: FormControl<string[]>;
    tags: FormControl<string[]>;
    phases: FormControl<string[]>;
    subPhases: FormControl<string[]>;
    fromDate: FormControl<Date | null>;
    toDate: FormControl<Date | null>;
    startDate: FormControl<Date | null>;
    endDate: FormControl<Date | null>;
    sortOption: FormControl<SortOptions | null>;
    sortOrder: FormControl<SortOrders | null>;
}>;

@Component({
    selector: 'accenture-sessions-filters-and-sort-new',
    templateUrl: './sessions-filters-and-sort-new.component.html',
    styleUrls: ['./sessions-filters-and-sort-new.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [SessionsFiltersAndSortNewFacade],
})
export class SessionsFiltersAndSortNewComponent implements OnInit, OnDestroy {
    vm$: Observable<SessionsFiltersAndSortNewViewModel> = this.facade.vm$.pipe(
        tap(({ filtersAndSort }) => this.updateFormValues(filtersAndSort)),
    );

    trackById = trackById;
    filtersAndSortForm: FiltersAndSortForm;
    sessionOptions = SessionOptions;
    roleDisplayName = displayRole;
    sortOrdersValues = sortOrdersValues;
    rolesList = rolesListForFilter;
    statusesList = statusesListForFilter;
    displayStatus = sessionStatusToDisplay;
    notFoundOptionsText = notFoundOptionsText;
    inputPlaceholders = inputPlaceholders;
    validationMessages = validationMessages;
    optionPanelTitle: string;

    private pickedDate: Date;
    private onDestroy = new Subject<void>();

    constructor(private fb: FormBuilder, private facade: SessionsFiltersAndSortNewFacade) {}

    get isToDateGrFromDateErrorVisibility(): boolean {
        const toDateControl = this.filtersAndSortForm && this.filtersAndSortForm.get('toDate');
        return toDateControl && toDateControl.hasError('invalidDate');
    }

    get isEndDateGreaterStartDateErrorVisibility(): boolean {
        const toDateControl = this.filtersAndSortForm && this.filtersAndSortForm.get('endDate');
        return toDateControl && toDateControl.hasError('invalidDate');
    }

    get resetAllLinkDisabled(): boolean {
        return isEqual(this.filtersAndSortForm.value, initialDefaultFilterObject);
    }

    initializeFiltersAndSortForm(): void {
        this.filtersAndSortForm = this.fb.group(
            {
                roles: new FormControl({ value: [], disabled: false }, { nonNullable: true }),
                statuses: new FormControl({ value: [], disabled: false }, { nonNullable: true }),
                tags: new FormControl({ value: [], disabled: false }, { nonNullable: true }),
                phases: new FormControl({ value: [], disabled: false }, { nonNullable: true }),
                subPhases: new FormControl({ value: [], disabled: false }, { nonNullable: true }),
                fromDate: new FormControl(null),
                toDate: new FormControl(null),
                startDate: new FormControl(null),
                endDate: new FormControl(null),
                sortOption: new FormControl({ value: null, disabled: false }),
                sortOrder: new FormControl({ value: null, disabled: false }),
            },
            {
                updateOn: 'blur',
                validators: [fromToDateValidator('startDate', 'endDate'), fromToDateValidator('fromDate', 'toDate')],
            },
        );
    }

    toggleOptionsPanel(opened: boolean): void {
        this.facade.toggleOptionsPanel(opened);
    }

    navigateToFilterPanel(): void {
        this.toggleOptionsPanel(false);
    }

    clearDateInput(controlName: string, event: Event): void {
        event.stopPropagation();
        this.filtersAndSortForm.get(controlName).reset();
    }

    resetFiltersAndSortForm(): void {
        this.filtersAndSortForm.reset({
            ...initialDefaultFilterObject,
            fromDate: initialDefaultFilterObject.fromDate?.toDate() || null,
            toDate: initialDefaultFilterObject.toDate?.toDate() || null,
            endDate: initialDefaultFilterObject.endDate?.toDate() || null,
            startDate: initialDefaultFilterObject.startDate?.toDate() || null,
        });
    }

    setTag(option: Tag): void {
        const control = this.filtersAndSortForm.controls.tags;
        const tags = control.value || [];

        control.setValue([...tags, option.id]);
        this.updateTagOptions('');
    }

    setSubPhase(option: SubPhase): void {
        const control = this.filtersAndSortForm.controls.subPhases;
        const subPhases = control.value || [];

        control.setValue([...subPhases, option.id]);
        this.updateSubPhaseOptions('');
    }

    setPhase(option: Phase): void {
        const control = this.filtersAndSortForm.controls.phases;
        const phases = control.value || [];

        control.setValue([...phases, option.id]);
        this.updatePhaseOptions('');
    }

    openFilterAndSortPanel(event: Event, optionType: SessionOptions): void {
        this.optionPanelTitle = optionsPanelTitle[optionType];
        this.facade.setOptionType(optionType);

        if (event) {
            event.stopPropagation();
        }
    }

    updateTagOptions(value: string): void {
        this.facade.autocompleteValues$.next({
            ...this.facade.autocompleteValues$.getValue(),
            tag: value,
        });
    }

    updateSubPhaseOptions(value: string): void {
        this.facade.autocompleteValues$.next({
            ...this.facade.autocompleteValues$.getValue(),
            subPhase: value,
        });
    }

    updatePhaseOptions(value: string): void {
        this.facade.autocompleteValues$.next({
            ...this.facade.autocompleteValues$.getValue(),
            phase: value,
        });
    }

    removeSelectedSessionRole(selectedRole: SessionRole): void {
        const control = this.filtersAndSortForm.controls.roles;
        const value = control.value.filter((role) => role !== selectedRole);

        control.setValue(value);
    }

    removeSelectedSessionStatus(selectedStatus: SessionStatus): void {
        const control = this.filtersAndSortForm.controls.statuses;
        const value = control.value.filter((status: SessionStatus) => status !== selectedStatus);

        control.setValue(value);
    }

    removeSelectedTag(selectedTagId: string): void {
        const control = this.filtersAndSortForm.controls.tags;
        const value = control.value.filter((tagId: string) => tagId !== selectedTagId);

        control.setValue(value);
        this.updateTagOptions('');
    }

    removeSelectedPhase(selectedPhaseId: string): void {
        const control = this.filtersAndSortForm.controls.phases;
        const value = control.value.filter((phaseId: string) => phaseId !== selectedPhaseId);

        control.setValue(value);
        this.updatePhaseOptions('');
    }

    removeSelectedSubPhase(selectedSubPhasesId: string): void {
        const control = this.filtersAndSortForm.controls.subPhases;
        const value = control.value.filter((subPhasesId: string) => subPhasesId !== selectedSubPhasesId);

        control.setValue(value);
        this.updateSubPhaseOptions('');
    }

    ngOnInit(): void {
        this.initializeFiltersAndSortForm();
        this.filtersAndSortForm.valueChanges.pipe(takeUntil(this.onDestroy)).subscribe((formData) => {
            const pickedDate = formData.toDate ? new Date(formData.toDate) : null;
            const fromDate = formData.fromDate ? firebase.firestore.Timestamp.fromDate(formData.fromDate) : null;
            const pickedEndDate = formData.endDate ? new Date(formData.endDate) : null;
            const startDate = formData.startDate ? firebase.firestore.Timestamp.fromDate(formData.startDate) : null;

            formData.tags = formData.tags?.length ? formData.tags : null;
            formData.phases = formData.phases?.length ? formData.phases : null;
            formData.subPhases = formData.subPhases?.length ? formData.subPhases : null;
            formData.roles = formData.roles?.length ? formData.roles : null;
            formData.statuses = formData.statuses?.length ? formData.statuses : null;

            if (!isEqual(this.pickedDate, pickedDate)) {
                pickedDate?.setDate(pickedDate.getDate() + 1);
                pickedDate?.setSeconds(pickedDate.getSeconds() - 1);
                this.pickedDate = pickedDate;
            }

            const toDate = pickedDate ? firebase.firestore.Timestamp.fromDate(pickedDate) : null;
            const endDate = pickedEndDate ? firebase.firestore.Timestamp.fromDate(pickedEndDate) : null;

            this.facade.applyFiltersAndSort({
                ...formData,
                toDate: toDate as Timestamp,
                fromDate: fromDate as Timestamp,
                endDate: endDate as Timestamp,
                startDate: startDate as Timestamp,
            });
        });
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.complete();
    }

    private updateFormValues(filtersAndSort: DefaultFilterObject): void {
        this.filtersAndSortForm.patchValue(
            {
                ...filtersAndSort,
                fromDate: filtersAndSort.fromDate?.toDate() ?? null,
                toDate: filtersAndSort.toDate?.toDate() ?? null,
                startDate: filtersAndSort.startDate?.toDate() ?? null,
                endDate: filtersAndSort.endDate?.toDate() ?? null,
            },
            { emitEvent: false },
        );
        this.pickedDate = filtersAndSort?.toDate?.toDate();
    }
}
