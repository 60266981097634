<ng-container *ngIf="vm$ | async as vm">
  <ng-container *accentureLoaded="!vm.isLoading; description: loaderText">
    <accenture-base-dialog [title]="'Select project template'">
      <div class="content-container">
        <accenture-add-project-table
          [templates]="vm.templates"
          [selectedTemplateId]="vm.selectedTemplateId"
          (newSelectedTemplateId)="updateSelectedTemplateId($event)"
          (searchValue)="updateSearchValue($event)"
        ></accenture-add-project-table>
      </div>

      <div class="footer d-flex-end">
        <button class="tt9-btn-default back-btn" (click)="closeCurrentDialog()">
          <accenture-icon>icon-chevron-left</accenture-icon>
          Back
        </button>

        <button class="tt9-btn ml-16" [disabled]="!vm.selectedTemplateId" (click)="createProject()">Save</button>
      </div>
    </accenture-base-dialog>
  </ng-container>
</ng-container>
