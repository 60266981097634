<!-- search -->
<div *ngIf="templates?.length || (!templates?.length && searchControl.value)" class="width-100 mt-16">
  <mat-form-field appearance="outline" class="tt9-form-field no-label no-margin width-100" floatLabel="always">
    <input
      #search
      matInput
      [placeholder]="inputPlaceholders.search"
      [formControl]="searchControl"
      (input)="emitSearchValue(search.value)"
    />
    <accenture-icon matPrefix>icon-search</accenture-icon>
    <accenture-icon *ngIf="search.value" matSuffix (click)="resetSearchValue()">icon-close</accenture-icon>
  </mat-form-field>
</div>
<!-- /search -->

<!--table-->
<div *ngIf="templates.length" class="tt9-select-table-container">
  <table class="tt9-select-table width-100 add-session-table">
    <thead>
      <tr class="row-sticky horizontal-shadow add-session-table-row">
        <th class="add-session-table-cell">
          <mat-checkbox
            class="tt9-checkbox"
            color="primary"
            [checked]="isAllTemplatesSelected"
            (change)="toggleAllTemplatesSelection($event)"
          ></mat-checkbox>
        </th>
        <th class="tt9-body-2 add-session-table-cell">Template name</th>
        <th class="tt9-body-2 add-session-table-cell">Description</th>
        <th class="tt9-body-2 add-session-table-cell">Creator</th>
        <th class="tt9-body-2 add-session-table-cell">Date created</th>
      </tr>
    </thead>

    <tbody>
      <ng-container *ngFor="let template of templates; trackBy: trackById">
        <ng-container *ngTemplateOutlet="rowTemplate; context: { template: template }"></ng-container>
      </ng-container>
    </tbody>
  </table>
</div>

<!-- row template -->
<ng-template #rowTemplate let-template="template">
  <tr (click)="toggleTemplatesSelection(template)" (mouseover)="$event.stopPropagation()" class="add-session-table-row">
    <td class="add-session-table-cell">
      <mat-checkbox
        class="tt9-checkbox"
        color="primary"
        [checked]="selectedTemplatesMap[template?.templateId]"
      ></mat-checkbox>
    </td>

    <td class="add-session-table-cell">
      <span
        #templateName
        class="tt9-body-1 text-overflow-ellipsis-2"
        matTooltipPosition="above"
        matTooltipClass="tt9-arrow-tooltip-below-left"
        [matTooltip]="template.name"
        [matTooltipDisabled]="isDisabledTooltip(templateName)"
        >{{ template.name }}</span
      >
    </td>

    <td class="add-session-table-cell">
      <span
        #templateDescription
        class="tt9-body-1 text-overflow-ellipsis-2"
        matTooltipPosition="above"
        matTooltipClass="tt9-arrow-tooltip-below"
        [matTooltip]="template.description"
        [matTooltipDisabled]="isDisabledTooltip(templateDescription)"
        >{{ template.description }}</span
      >
    </td>

    <td class="add-session-table-cell">
      <span
        #templateOwner
        class="tt9-body-1 text-overflow-ellipsis-2"
        [matTooltip]="template.ownerDisplayName"
        matTooltipPosition="above"
        matTooltipClass="tt9-arrow-tooltip-below"
        [matTooltipDisabled]="isDisabledTooltip(templateOwner)"
        >{{ template.ownerDisplayName }}</span
      >
    </td>

    <td class="add-session-table-cell">
      {{ template.created.toMillis() | date : "mediumDate" }}
    </td>
  </tr>
</ng-template>
<!-- /row template -->

<!-- empty screen -->
<ng-container *ngIf="!templates.length">
  <!-- No responses match Search Criteria -->
  <ng-container *ngIf="searchControl.value">
    <accenture-empty-screen
      class="empty-screen"
      [title]="emptyScreenTitles.noTemplatesFound"
      [imageUrl]="noItemsFoundImageUrl"
    ></accenture-empty-screen>
  </ng-container>
  <!-- /No responses match Search Criteria -->

  <!-- empty screen template -->
  <ng-container *ngIf="!searchControl.value">
    <accenture-empty-screen
      class="empty-screen"
      [title]="emptyScreenTitles.noSessionsTemplatesYet"
      [imageUrl]="emptyScreenImageUrlTemplates"
    ></accenture-empty-screen>
  </ng-container>
  <!-- /empty screen template -->
</ng-container>
<!-- /empty screen -->
