import { AbstractControl, FormControl, FormGroup, ValidatorFn } from '@angular/forms';

import { Dictionary } from '@accenture/shared/data';

import { VoteFormGroup } from '../extended-forms';
import { validationErrors } from './validation-errors';

const voteNumericError = { [validationErrors.numericMaxValue]: true };

export const voteNumericMaxValueValidator = (
    maxValue: number,
    numericRequiredValue: number | undefined,
    parameterItemId: string,
    attributeId: string,
): ValidatorFn => {
    return (control: AbstractControl) => {
        const questionItemsGroup = control?.parent?.parent as FormGroup;

        if (!questionItemsGroup) {
            return null;
        }

        const valuesSummary = Object.values(questionItemsGroup.controls).reduce(
            (value: number, formGroup: FormGroup<Dictionary<FormControl<number>>>) => {
                return value + Number(formGroup?.controls[attributeId]?.value ?? 0);
            },
            0,
        );

        const remainingValue = maxValue - valuesSummary;
        const hasError = numericRequiredValue ? Number(valuesSummary) !== Number(maxValue) : remainingValue < 0;
        const voteFormGroup = questionItemsGroup.parent as VoteFormGroup;

        voteFormGroup.setRemainingValue(remainingValue, parameterItemId, attributeId);
        voteFormGroup.setVoteFormErrorsMap(hasError, parameterItemId, attributeId);

        Object.values(questionItemsGroup.controls).forEach(voteItemControl => {
            const attributeControl = voteItemControl.get(attributeId);
            if (attributeControl && !hasError) {
                attributeControl.setErrors(null);
            }
        });

        return hasError ? voteNumericError : null;
    };
};
