import { Injectable } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { distinctUntilChanged, map, startWith, switchMap } from 'rxjs/operators';

import { CollectionsService, UserSessionService } from '@accenture/erp-deployment/shared/domain';
import { AppState, selectAuthenticatedUserId } from '@accenture/global-store';
import {
    CollectionSession,
    errorSnackbarText,
    getSnackBarTextForAddingSession,
    moveToCollectionErrorTitle,
    UserCollection,
    UserSession,
} from '@accenture/shared/data';
import { DialogService, SnackbarService, SnackBarTypes } from '@accenture/shared/ui';

import { CreateCollectionDialogComponent } from '../create-collection-dialog/create-collection-dialog.component';
import { MoveToCollectionDialogComponent } from './move-to-collection-dialog.component';

export interface CreateSessionSourceTypeDialogModel {
    userCollections: UserCollection[];
    collectionSession: CollectionSession;
    filteredCollections: UserCollection[];
    userSession: UserSession;
    session: UserSession | CollectionSession;
    noCollectionFound: boolean;
    isLoading: boolean;
}

const defaultViewModel: CreateSessionSourceTypeDialogModel = {
    userCollections: [],
    filteredCollections: [],
    collectionSession: {} as CollectionSession,
    session: {} as CollectionSession,
    userSession: {} as UserSession,
    noCollectionFound: false,
    isLoading: true,
};

@Injectable()
export class MoveToCollectionDialogFacade {
    private sessionId$ = new BehaviorSubject<string>(null);
    private sessionData$ = new BehaviorSubject<{
        userSession?: UserSession;
        collectionSession?: CollectionSession;
    }>(null);
    private selectedCollection$ = new BehaviorSubject<UserCollection>(null);

    private userId!: string;
    private sessionId!: string;
    private session: CollectionSession | UserSession;

    autocompleteValue$ = new BehaviorSubject<string>('');

    vm$ = this.buildViewModel();
    isCollectionPage: boolean;

    constructor(
        private store: Store<AppState>,
        private dialogService: DialogService,
        private snackbarService: SnackbarService,
        private collectionsService: CollectionsService,
        private userSessionService: UserSessionService,
        private dialogRef: MatDialogRef<MoveToCollectionDialogComponent>,
    ) {}

    updateSessionData(data: { userSession?: UserSession; collectionSession?: CollectionSession }): void {
        this.sessionData$.next(data);
        this.sessionId$.next(data.userSession ? data.userSession.sessionId : data.collectionSession.sessionId);
    }

    closeDialog(): void {
        this.dialogRef.close();
    }

    getCollectionSessionData(
        sessionData: { userSession?: UserSession; collectionSession?: CollectionSession },
        userId: string,
    ): Observable<CollectionSession> {
        if (sessionData.collectionSession) {
            return of(sessionData.collectionSession as CollectionSession);
        }

        return this.collectionsService.getCollectionSessionDocument(userId, sessionData.userSession.id);
    }

    getCollectionsAutoComplete(inputValue: string, userCollections: UserCollection[]): UserCollection[] {
        const searchValue = inputValue?.toLowerCase() || '';

        if (!searchValue) {
            return userCollections;
        }

        return userCollections.filter((userCollection: UserCollection) => {
            const name = userCollection.name.trim().toLowerCase();

            return name.includes(searchValue);
        });
    }

    async moveToCollection(): Promise<void> {
        const selectedCollection = this.selectedCollection$.getValue();
        const { userSession, collectionSession } = this.sessionData$.getValue();
        const session = !!userSession
            ? userSession
            : await this.userSessionService.getByUserAndSessionNew(this.userId, collectionSession?.sessionId);

        this.showSessionSnackBar(SnackBarTypes.Info, session.collectionName?.length > 0);

        if (!selectedCollection.collectionId || !this.userId) {
            this.snackbarService.showErrorSnackBar(moveToCollectionErrorTitle, errorSnackbarText);
            this.dialogRef.close();
            return;
        }

        try {
            await this.collectionsService.moveToCollection(
                selectedCollection,
                this.userId,
                session.sessionId,
                session.id,
            );
            this.showSessionSnackBar(SnackBarTypes.Success, session.collectionName?.length > 0);
        } catch (error) {
            console.error('Error moving session to collection:', error);
            this.snackbarService.showErrorSnackBar(moveToCollectionErrorTitle, errorSnackbarText);
        }
        this.dialogRef.close();
    }

    createNewCollection(): void {
        if (!this.session) {
            return;
        }
        this.dialogService.open(CreateCollectionDialogComponent, {
            panelClass: 'tt9-modal',
            width: '768px',
            session: this.session,
            isCollectionPage: this.isCollectionPage,
        });
    }

    setSelectedCollection(collection: UserCollection): void {
        this.selectedCollection$.next(collection);
    }

    private buildViewModel(): Observable<CreateSessionSourceTypeDialogModel> {
        return combineLatest([
            this.sessionId$.asObservable().pipe(distinctUntilChanged()),
            this.sessionData$.asObservable().pipe(distinctUntilChanged()),
            this.store.select(selectAuthenticatedUserId),
        ]).pipe(
            switchMap(([sessionId, data, userId]) => {
                const folderColor$ = this.userSessionService.getByUserAndSessionNew(userId, sessionId);
                return combineLatest([
                    of(sessionId),
                    of(data.userSession),
                    folderColor$,
                    this.getCollectionSessionData(data, userId),
                    this.collectionsService.getCollectionAssignmentsByUserId(userId),
                    this.autocompleteValue$.asObservable().pipe(distinctUntilChanged()),
                ]).pipe(
                    map(
                        ([
                            sessionId,
                            userSession,
                            folderColor,
                            collectionSession,
                            userCollections,
                            autocompleteValue,
                        ]) => {
                            this.userId = userId;
                            this.sessionId = sessionId;
                            this.session = userSession || collectionSession;
                            const filteredCollections = this.getCollectionsAutoComplete(
                                autocompleteValue,
                                userCollections,
                            ).filter((userCollection) =>
                                this.session?.collectionId
                                    ? userCollection.collectionId !== this.session?.collectionId
                                    : true,
                            );
                            this.isCollectionPage = !!collectionSession;

                            const noCollectionFound = !filteredCollections.length && !!autocompleteValue;
                            const sessionName = userSession?.name || collectionSession?.sessionName;

                            return {
                                userCollections,
                                collectionSession,
                                filteredCollections,
                                userSession,
                                session: this.session,
                                folderColor: folderColor.color,
                                sessionName,
                                noCollectionFound,
                                truncatedSessionName: sessionName,
                                truncatedCollectionName:
                                    userSession?.collectionName || collectionSession?.collectionName,
                                isLoading: false,
                            } as CreateSessionSourceTypeDialogModel;
                        },
                    ),
                );
            }),
            startWith(defaultViewModel),
        );
    }

    private showSessionSnackBar(type: SnackBarTypes, isAnother: boolean): void {
        const snackbarText = getSnackBarTextForAddingSession(type, isAnother);
        switch (type) {
            case 'info':
                this.snackbarService.showInfoSnackBar(
                    snackbarText.title,
                    this.handleSnackbarDescription(snackbarText.description),
                    true,
                );
                break;
            case 'success':
                this.snackbarService.showSuccessSnackBar(
                    snackbarText.title,
                    this.handleSnackbarDescription(snackbarText.description),
                    true,
                );
                break;
            case 'error':
                this.snackbarService.showErrorSnackBar(snackbarText.title, snackbarText.description, true);
                break;
        }
    }

    private handleSnackbarDescription(description: string): string {
        return `${description} "${this.truncateToSingleLine(this.selectedCollection$.getValue().name)}"`;
    }

    private truncateToSingleLine(description: string): string {
        const maxLength = 38;

        return description.length > maxLength ? description.slice(0, maxLength - 4) + '...' : description;
    }
}
