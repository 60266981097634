import { Question } from '../question';
import { ActivityItemType } from './activity-item-type';

export const starsCount = 5;

export const starsColorsCssClasses = {
    gold: 'star-gold',
    grey: 'star-grey',
};

export class StarVotingOptions {
    labels: string[];

    constructor(data: any) {
        this.labels = data.labels ?? [];
    }

    createSerializableObject(): Record<string, unknown> {
        return {
            labels: this.labels,
        };
    }
}

export class StarVoting extends Question {
    type = ActivityItemType.StarVoting;

    options: StarVotingOptions;

    constructor(data: any) {
        super(data);

        const options = data.options ?? {};
        this.options = new StarVotingOptions(options);
    }

    createSerializableObject(): Record<string, unknown> {
        return {
            ...super.createSerializableObject(),
            options: this.options.createSerializableObject(),
        };
    }
}
