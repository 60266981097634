import { createFeatureSelector, createSelector } from '@ngrx/store';

import { CollectionsDisplayMode } from '@accenture/shared/data';

import { AppState } from '../app.state';
import { DASHBOARD_SESSION_FEATURE_KEY, DashboardSessionState } from './dashboard.reducer';

export const dashboardSessionState = createFeatureSelector<DashboardSessionState>(DASHBOARD_SESSION_FEATURE_KEY);

export const selectDashboardSessionsDisplayMode = createSelector<
    AppState,
    [DashboardSessionState],
    CollectionsDisplayMode
>(dashboardSessionState, (sessionStateData) => sessionStateData?.displayMode);

export const selectIsSideNavigationDisplayed = createSelector<
    AppState,
    [DashboardSessionState],
    boolean
>(dashboardSessionState, (dashboardStateData) => dashboardStateData?.isSideNavigationDisplayed);
