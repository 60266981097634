import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { MenuPositionX, MenuPositionY } from '@angular/material/menu';
import { EmojiData } from '@ctrl/ngx-emoji-mart/ngx-emoji';

import { emoji } from './constants';

@Component({
    selector: 'accenture-emojis',
    templateUrl: './emojis.component.html',
    styleUrls: ['./emojis.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmojisComponent {
    emojiIcon = emoji.default;
    emojiOnClickValue = false;

    @Input() yPosition(value: 'below' | 'above') {
        this.emojiMenuPositionY = value;
    }

    @Input() xPosition(value: 'before' | 'after') {
        this.emojiMenuPositionX = value;
    }

    @Output() emoji = new EventEmitter<string>();

    emojiMenuPositionY: MenuPositionY = 'below';
    emojiMenuPositionX: MenuPositionX = 'after';

    addEmoji(event: { emoji: EmojiData }): void {
        this.emoji.emit(event.emoji.native);
    }

    onclickEmoji(clicked: boolean): void {
        if (clicked) {
            this.emojiOnClickValue = true;
            this.emojiIcon = emoji.active;
        }
    }

    onClose(close: boolean): void {
        if (close) {
            this.emojiIcon = emoji.default;
            this.emojiOnClickValue = false;
        }
    }
}
