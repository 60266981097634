<ng-container *ngIf="vm$ | async as vm">
  <div class="activities-panel d-flex-column">
    <!-- Create Activity side panel header -->
    <div class="activities-panel-header d-flex-center-between p-16">
      <h2 class="tt9-h2">Create activity (New)</h2>
      <div class="d-flex">
        <button class="tt9-btn-icon-filled" (click)="closePanel()">
          <accenture-icon>icon-close</accenture-icon>
        </button>
      </div>
    </div>
    <!-- /Create Activity side panel header-->

    <!-- Create from panel -->
    <div *ngIf="vm.isActivityCreateFromPanelVisible" class="create-from-panel" [class.bottom-shadow]="isListScrolled">
      <button
        class="tt9-btn-default btn-icon d-flex-center width-100 accenture-disable-outside-click"
        matTooltipPosition="below"
        matTooltipClass="tt9-arrow-tooltip-above"
        [matTooltip]="tooltipTexts.selectActivityTemplates"
        (click)="createActivityByTemplate(vm.activities, sequenceData)"
      >
        <accenture-icon>icon-template</accenture-icon>
        From Template
      </button>
      <button
        *ngIf="vm.available"
        class="tt9-btn btn-icon d-flex-center width-100 accenture-disable-outside-click"
        matTooltipPosition="below"
        matTooltipClass="tt9-arrow-tooltip-above"
        [matTooltip]="tooltipTexts.generateActivityByAI"
        (accentureClickOutside)="closePanel()"
        (click)="openGenerateByAiDialog()"
      >
        <accenture-icon>icon-concierge-figma</accenture-icon>
        {{ buttonLabels.generateNew }}
      </button>
    </div>
    <!-- /Create from panel -->

    <!-- Create Activity side panel body -->
    <div accentureScrollSpy class="activities-panel-body" (scrollSpyPositionChange)="setScrollPosition($event)">
      <ul class="activities-panel-column">
        <li
          *ngFor="let activityConfiguration of vm.activityConfigurations; trackBy: trackById"
          class="tt9-navigation-card-wrapper activities-panel-card accenture-disable-outside-click"
          (click)="createActivity(activityConfiguration.type, vm.activities, sequenceData)"
        >
          <div class="tt9-navigation-card-container">
            <p class="tt9-navigation-card-title-with-icon">
              <accenture-icon class="mr-8">{{ activityConfiguration.icon }}</accenture-icon>
              {{ activityConfiguration.shortName }}
            </p>
            <p class="tt9-navigation-card-long-description">
              {{ activityConfiguration.description }}
            </p>
          </div>
        </li>
      </ul>
    </div>
    <!-- /Create Activity side panel body -->
  </div>
</ng-container>
