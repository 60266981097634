export const sectionTemplate = {
    name: 'Section',
    description: 'Cluster Activities into Sub-Sessions.',
};

export const confirmationDeletionMessage = {
    activity: 'Are you sure you want to delete this activity? You will lose all the associated data.',
};

export enum tooltipText {
    addActivities = 'Add Activities to Section',
    hideActivity = 'Hide activity from participants',
    showActivity = 'Show activity to participants',
}
