<ng-container *ngIf="vm$ | async as vm">
  <ng-container *accentureLoaded="!vm.isLoading; description: loaderText">
    <accenture-base-dialog [title]="title" [closeButtonVisible]="false">
      <div class="p-32">
        <p>The following users are requesting your consent to record this session.</p>
        <div class="team-page-container d-flex-column">
          <div class="team-page-content">
            <ng-container>
              <section accentureScrollSpy class="table-container tt9-team-table-container">
                <table class="tt9-team-table tt9-body-2">
                  <thead>
                    <tr class="row-sticky">
                      <th class="column-name">User</th>
                    </tr>
                  </thead>
                  <tbody>
                    <ng-container *ngFor="let user of vm.disclaimers; trackBy: trackById">
                      <ng-container *ngTemplateOutlet="userRow; context: { user: user }"></ng-container>
                    </ng-container>
                  </tbody>
                </table>

                <!-- User row template -->
                <ng-template #userRow let-user="user">
                  <tr>
                    <td class="user-cell">
                      <div class="d-flex">
                        <div class="userpic-container">
                          <div class="tt9-userpic medium tt9-tooltip mr-16">
                            <img *ngIf="user.imageUrl" [src]="user.imageUrl" [alt]="user.displayName" />
                            <ng-container *ngIf="!user.imageUrl">{{ user.displayName | userInitials }}</ng-container>
                          </div>
                          <div *ngIf="user.isOnline" class="userpic-online-badge"></div>
                        </div>
                        <div class="user-cell-info d-flex-column">
                          <p
                            #userName
                            class="tt9-color-neutral tt9-body-medium-1 text-overflow-ellipsis-1"
                            *ngIf="user.displayName !== user.email"
                            matTooltipClass="tt9-arrow-tooltip-above"
                            [matTooltip]="user.displayName"
                          >
                            {{ user.displayName }}
                          </p>
                          <p #userEmail class="text-overflow-ellipsis-1" matTooltipClass="tt9-arrow-tooltip-above">
                            {{ user.email || "Email not available" }}
                          </p>
                        </div>
                      </div>
                    </td>
                  </tr>
                </ng-template>
                <!-- /User row template -->
              </section>
            </ng-container>
          </div>
        </div>
      </div>
      <div class="footer d-flex-end accenture-dialog-actions">
        <div class="d-flex">
          <button class="tt9-btn-default mr-8" (click)="decline()">Decline</button>
          <button class="tt9-btn" (click)="accept()">Accept</button>
        </div>
      </div>
    </accenture-base-dialog>
  </ng-container>
</ng-container>
