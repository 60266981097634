import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { combineLatest, Observable, of } from 'rxjs';
import { map, startWith, switchMap } from 'rxjs/operators';

import { ActivityService } from '@accenture/activity/shared/domain';
import { PublicTemplateService, SessionService } from '@accenture/erp-deployment/shared/domain';
import { AppState, selectActivityConfigurationsMap } from '@accenture/global-store';
import {
    Activity,
    ActivityConfigurationsMap,
    ParentType,
    Project,
    PublicParentType,
    Session,
    Template,
} from '@accenture/shared/data';

import { UseTemplateStore } from '../component-stores/use-template.store';

export interface TemplatePreviewModel {
    template: Project | Template | Activity;
    sessions: Session[];
    activities: Activity[];
    templateType: PublicParentType;
    activityConfigurationsMap: ActivityConfigurationsMap;
    isLoading: boolean;
}

export const defaultViewModel = {
    template: null,
    sessions: [],
    activities: [],
    templateType: null,
    activityConfigurationsMap: {},
    isLoading: true,
};

@Injectable()
export class TemplatePreviewFacade {
    vm$: Observable<TemplatePreviewModel> = this.buildViewModel();

    constructor(
        private store: Store<AppState>,
        private useTemplateStore: UseTemplateStore,
        private publicTemplateService: PublicTemplateService,
        private sessionService: SessionService,
        private activityService: ActivityService,
    ) {}

    closePanel(): void {
        this.useTemplateStore.setPreviewTemplate(null);
    }

    private buildViewModel(): Observable<TemplatePreviewModel> {
        return combineLatest([
            this.store.select(selectActivityConfigurationsMap),
            this.useTemplateStore.previewTemplate$,
        ]).pipe(
            switchMap(([activityConfigurationsMap, publicAccess]) => {
                if (!publicAccess) {
                    return of({
                        ...defaultViewModel,
                        isLoading: false,
                    });
                }

                return combineLatest([
                    this.getPublicTemplate(publicAccess.templateType as PublicParentType, publicAccess.templateId),
                    this.getSessions(publicAccess.templateType, publicAccess.templateId),
                    this.getActivities(publicAccess.templateType, publicAccess.templateId),
                ]).pipe(
                    map(([template, sessions, activities]) => {
                        return {
                            template,
                            sessions,
                            activities,
                            activityConfigurationsMap,
                            templateType: publicAccess.templateType,
                            isLoading: false,
                        };
                    }),
                    startWith(defaultViewModel),
                );
            }),
        );
    }

    private getPublicTemplate<T>(parentType: PublicParentType, parentId: string): Observable<T> {
        return this.publicTemplateService.getPublicTemplate<T>(parentType, parentId);
    }

    private getSessions(parentType: ParentType, parentId: string): Observable<Session[]> {
        if (parentType === ParentType.PublicProjectTemplates) {
            return this.sessionService.getSessions(parentType, parentId);
        } else {
            return of([]);
        }
    }

    private getActivities(parentType: ParentType, parentId: string): Observable<Activity[]> {
        if (parentType === ParentType.PublicSessionTemplates) {
            return this.activityService.getActivities(parentType, parentId);
        } else {
            return of([]);
        }
    }
}
