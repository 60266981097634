import { Injectable } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest, firstValueFrom, Observable } from 'rxjs';
import { filter, map, startWith, switchMap, take, tap } from 'rxjs/operators';

import { InvitationObject, InvitedUsersService } from '@accenture/erp-deployment/shared/domain';
import { AppState, selectAuthenticatedUserId, selectProjectIdAndSessionId } from '@accenture/global-store';
import {
    errorMessageSnackbarText,
    InvitedUser,
    invitingTeamMembersErrorSnackbarTitle,
    invitingTeamMembersInProgressSnackbarTitle,
    ParentType,
    SessionRole,
    teamMembersInvitedSnackbarTitle,
} from '@accenture/shared/data';
import { FirestoreService } from '@accenture/shared/data-access';
import { SnackbarService } from '@accenture/shared/ui';

import { SessionInviteDialogComponent } from './session-invite-dialog.component';
import { SessionsInvitationsByEmailStep, SessionsInvitationsTab } from './session-invite-dialog.constants';
import { SessionInviteDialogStore } from './session-invite-dialog.store';

export interface SessionInviteDialogViewModel {
    isInviteButtonDisabled: boolean;
    isLoading: boolean;
    activeTab: SessionsInvitationsTab;
    inviteByEmailActiveStep: SessionsInvitationsByEmailStep;
}

const defaultViewModel = {
    isInviteButtonDisabled: false,
    isLoading: true,
    activeTab: SessionsInvitationsTab.ByEmail,
    inviteByEmailActiveStep: SessionsInvitationsByEmailStep.Invite,
};

@Injectable()
export class SessionInviteDialogFacade {
    vm$ = this.buildViewModel();

    private userId!: string;
    private projectId!: string;
    private sessionId!: string;
    private chipsEmailsArray: string[] = [];

    private invitationObjectSubject$ = new BehaviorSubject<Partial<InvitationObject>>({
        chipsObject: {
            [SessionRole.Leader]: [],
            [SessionRole.Participant]: [],
        },
        message: '',
    });

    constructor(
        public dialogRef: MatDialogRef<SessionInviteDialogComponent>,
        private store: Store<AppState>,
        private sessionInviteDialogStore: SessionInviteDialogStore,
        private firestoreService: FirestoreService,
        private invitedUsersService: InvitedUsersService,
        private snackbarService: SnackbarService,
    ) {}

    async setInvitedUsers(): Promise<void> {
        const newInvitedEmails: InvitedUser[] = [];
        const currentInvitedUsersEmails = this.chipsEmailsArray;
        const invitedUsersEmailsInApplication = await firstValueFrom(
            this.invitedUsersService.getInvitedUsersEmails(this.userId).pipe(take(1)),
        );

        for (const currentInvitedUserEmail of currentInvitedUsersEmails) {
            if (!invitedUsersEmailsInApplication.includes(currentInvitedUserEmail)) {
                const invitedUserEmail: InvitedUser = {
                    invitingUserId: this.userId,
                    email: currentInvitedUserEmail,
                };

                newInvitedEmails.push(invitedUserEmail);
            }
        }

        if (newInvitedEmails.length) {
            await this.invitedUsersService.setInvitedUsers(newInvitedEmails);
        }
    }

    setInvitationObject(invitationObject: InvitationObject) {
        this.invitationObjectSubject$.next(invitationObject);
    }

    async invite(): Promise<void> {
        this.snackbarService.showInfoSnackBar(
            invitingTeamMembersInProgressSnackbarTitle,
            'Session team members are being invited',
        );
        this.dialogRef.close();
        try {
            this.setInvitedUsers();
            await this.inviteTeamMembers();
            this.snackbarService.showSuccessSnackBar(
                teamMembersInvitedSnackbarTitle,
                'Session team members have been invited successfully',
            );
        } catch (e) {
            console.error(e);
            this.snackbarService.showErrorSnackBar(invitingTeamMembersErrorSnackbarTitle, errorMessageSnackbarText);
        }
    }

    async inviteTeamMembers(): Promise<void> {
        const { chipsObject, message } = this.invitationObjectSubject$.getValue();
        const env = window.location.origin;

        const callbacks = [
            { role: SessionRole.Leader, emails: chipsObject[SessionRole.Leader] },
            { role: SessionRole.Participant, emails: chipsObject[SessionRole.Participant] },
        ]
            .filter(({ emails }) => emails.length)
            .map(({ role, emails }) =>
                this.firestoreService.cloudFunctionCallable('accessProvisioningNew', {
                    role,
                    emails,
                    env,
                    comment: message,
                    parentType: ParentType.Sessions,
                    projectIds: [this.projectId],
                    sessionIds: [this.sessionId],
                }),
            );

        await Promise.all(callbacks);
    }

    private buildViewModel(): Observable<SessionInviteDialogViewModel> {
        return combineLatest([
            this.store.select(selectAuthenticatedUserId),
            this.store.select(selectProjectIdAndSessionId),
        ]).pipe(
            filter(([userId, { projectId, sessionId }]) => !!userId && !!projectId && !!sessionId),
            switchMap(([userId, { projectId, sessionId }]) => {
                this.setCurrentUser(userId);
                this.setProjectId(projectId);
                this.setSessionId(sessionId);

                return combineLatest([
                    this.sessionInviteDialogStore.activeTab$,
                    this.sessionInviteDialogStore.inviteByEmailActiveStep$,
                    this.invitationObjectSubject$.pipe(
                        tap(invitationObject => this.setChipsEmailsArray(invitationObject)),
                    ),
                ]).pipe(
                    map(([activeTab, inviteByEmailActiveStep]) => {
                        const isInviteButtonDisabled = !this.chipsEmailsArray.length;

                        return {
                            isInviteButtonDisabled,
                            activeTab,
                            inviteByEmailActiveStep,
                            isLoading: false,
                        };
                    }),
                );
            }),
            startWith(defaultViewModel),
        );
    }

    private setCurrentUser(userId: string): void {
        this.userId = userId;
    }

    private setProjectId(projectId: string): void {
        this.projectId = projectId;
    }

    private setSessionId(sessionId: string): void {
        this.sessionId = sessionId;
    }

    private setChipsEmailsArray(invitationObject: Partial<InvitationObject>) {
        if (Object.keys(invitationObject.chipsObject).length > 0) {
            this.chipsEmailsArray = [
                ...invitationObject.chipsObject[SessionRole.Leader],
                ...invitationObject.chipsObject[SessionRole.Participant],
            ];
        }
    }
}
