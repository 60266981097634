import { Pipe, PipeTransform } from '@angular/core';

import { displayUserClaim, UserClaim } from '@accenture/shared/data';

@Pipe({
    name: 'displayUserClaim',
    standalone: true,
})
export class DisplayUserClaimPipe implements PipeTransform {
    transform(claim: UserClaim): string {
        return displayUserClaim[claim];
    }
}
