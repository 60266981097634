import { Inject, Injectable } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

import { PublicTemplateService } from '@accenture/erp-deployment/shared/domain';
import { UserTemplate } from '@accenture/shared/data';
import { SnackbarService, SnackBarTypes } from '@accenture/shared/ui';

import {
    templatePublishedText,
    templatePublishedTitle,
    templatePublishErrorText,
    templatePublishErrorTitle,
    templatePublishInProgressText,
    templatePublishInProgressTitle,
} from './constants';
import { PublishTemplateDialogComponent } from './publish-template-dialog.component';

export interface PublishTemplateDialogViewModel {
    template: UserTemplate;
    isLoading: boolean;
}

const defaultViewModel: PublishTemplateDialogViewModel = {
    template: {} as UserTemplate,
    isLoading: true,
};

@Injectable()
export class PublishTemplateDialogFacade {
    private isLoading$ = new BehaviorSubject<boolean>(false);

    vm$ = this.buildViewModel();
    private template!: UserTemplate;

    constructor(
        private dialogRef: MatDialogRef<PublishTemplateDialogComponent>,
        private publicTemplateService: PublicTemplateService,
        private snackbarService: SnackbarService,
        @Inject(MAT_DIALOG_DATA) public data: any,
    ) {}

    closeDialog(): void {
        this.dialogRef.close();
    }

    async onPublish(creatorNotes: string): Promise<void> {
        try {
            this.snackbarService.showSnackBar(
                templatePublishInProgressTitle,
                templatePublishInProgressText,
                SnackBarTypes.Info,
            );
            await this.publicTemplateService.onPublishTemplateNew(this.template, creatorNotes, true);
            this.snackbarService.showSnackBar(
                templatePublishedTitle,
                `${this.template.name} ${templatePublishedText}`,
                SnackBarTypes.Success,
                true,
            );
        } catch (e) {
            console.error(e);
            this.snackbarService.showSnackBar(
                templatePublishErrorTitle,
                templatePublishErrorText,
                SnackBarTypes.Error,
                true,
            );
        }
    }

    private buildViewModel(): Observable<PublishTemplateDialogViewModel> {
        return this.isLoading$.pipe(
            map((isLoading) => {
                this.template = this.data?.data;
                return {
                    isLoading,
                    template: this.template,
                };
            }),
            startWith(defaultViewModel),
        );
    }
}
