import { Pipe, PipeTransform } from '@angular/core';

import { Activity, DataFlowConnection, Dictionary } from '@accenture/shared/data';

@Pipe({
    name: 'displayConnectionSourcesLabel',
    pure: true,
})
export class DisplayConnectionSourcesLabelPipe implements PipeTransform {
    transform(connections: DataFlowConnection[], activities: Dictionary<Activity>): string[] {
        const connection = connections?.[0];
        return connection.sourceIds.map(id => activities[id].name);
    }
}
