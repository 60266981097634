import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { groupBy } from 'lodash';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { map, startWith, switchMap } from 'rxjs/operators';

import { ActivityItemService, TableColumnService } from '@accenture/activity/shared/domain';
import { DataFlowService, UserSessionService } from '@accenture/erp-deployment/shared/domain';
import {
    AppState,
    selectActivityIdAndParentIds,
    selectAuthenticatedUserId,
    selectUserSessions,
} from '@accenture/global-store';
import {
    Activity,
    ActivityItem,
    ActivityItemType,
    DataFlowConnection,
    Dictionary,
    ParentType,
    SessionRole,
    TableColumn,
    UserSession,
} from '@accenture/shared/data';
import { DialogService, LoadedDescription } from '@accenture/shared/ui';

export interface SessionsDataFlowConnectionDialogFacadeState {
    activities: Activity[];
    activityItems: Dictionary<ActivityItem[]>;
    connections: DataFlowConnection[];
    currentSession: UserSession | undefined;
    sessions: UserSession[];
    isLoading: boolean;
    loaderDescription: string;
    currentActivityId: string;
    columns: Dictionary<TableColumn[]> | undefined;
}

const defaultViewModel: SessionsDataFlowConnectionDialogFacadeState = {
    activities: [],
    activityItems: {} as Dictionary<ActivityItem[]>,
    currentSession: {} as UserSession,
    currentActivityId: '',
    connections: [],
    sessions: [],
    isLoading: false,
    loaderDescription: '',
    columns: undefined,
};

@Injectable()
export class SessionsDataFlowConnectionDialogFacade {
    vm$ = this.buildViewModel();

    private isSavingProgress$ = new BehaviorSubject<boolean>(false);

    private parentType!: ParentType;
    private parentId!: string;

    constructor(
        private store: Store<AppState>,
        private activityItemService: ActivityItemService,
        private dataFlowService: DataFlowService,
        private userSessionService: UserSessionService,
        private dialogService: DialogService,
        private tableColumnService: TableColumnService
    ) {}

    async saveDataFlowData(data: DataFlowConnection[], confirm?: () => Promise<void>): Promise<void> {
        this.setLoadingStatus(true);
        await this.dataFlowService.updateBatchDataFlow(this.parentType, this.parentId, data);
        if (Boolean(confirm)) {
            await confirm();
        }
        this.setLoadingStatus(false);

        this.dialogService.close();
    }

    private buildViewModel(): Observable<SessionsDataFlowConnectionDialogFacadeState> {
        return combineLatest([
            this.store.select(selectAuthenticatedUserId),
            this.store.pipe(select(selectActivityIdAndParentIds)),
        ]).pipe(
            switchMap(([userId, { sessionId, parentType, parentId, activityId }]) => {
                if (!parentId || !userId) {
                    return of({
                        ...defaultViewModel,
                        isLoading: false,
                    });
                }

                this.parentType = parentType;
                this.parentId = parentId;

                return combineLatest({
                    activityItems: this.getActivityItems(parentType, parentId),
                    userSessions: this.getUserSessions(userId, parentId, parentType),
                    connections: this.getDataFlowConnections(parentType, parentId),
                    columns: this.getColumns(parentType, parentId),
                    isLoading: this.isSavingProgress$,
                }).pipe(
                    map((data) => {
                        const currentSession = sessionId
                            ? data.userSessions.find((userSession) => userSession.sessionId === sessionId)
                            : undefined;

                        return {
                            currentSession,
                            columns: data.columns,
                            activities: [],
                            currentActivityId: activityId,
                            activityItems: data.activityItems,
                            connections: data.connections,
                            isLoading: data.isLoading,
                            loaderDescription: LoadedDescription.Saving,
                            sessions: data.userSessions,
                        };
                    })
                );
            }),
            startWith(defaultViewModel)
        );
    }

    private getActivityItems(parentType: ParentType, parentId: string): Observable<Dictionary<ActivityItem[]>> {
        return this.activityItemService
            .getAllActivityItems(parentType, parentId)
            .pipe(map((activityItems) => groupBy(activityItems, 'activityId')));
    }

    private getColumns(parentType: ParentType, parentId: string): Observable<Dictionary<TableColumn[]>> {
        return this.tableColumnService.getColumns(parentType, parentId).pipe(
            map((tableColumns: TableColumn[]) => {
                const textColumns = tableColumns.filter((column) => column?.type === ActivityItemType.Text);
                return groupBy(textColumns, 'activityItemId');
            })
        );
    }

    private getDataFlowConnections(parentType: ParentType, parentId: string): Observable<DataFlowConnection[]> {
        return this.dataFlowService.getDataFlowConnections(parentType, parentId);
    }

    private getUserSessions(userId: string, sessionId: string, parentType: ParentType): Observable<UserSession[]> {
        if (parentType === ParentType.Templates) {
            return this.userSessionService
                .getUserAssignmentBySessionId(userId, sessionId)
                .pipe(map((userSession) => [userSession]));
        }

        return this.store.select(selectUserSessions).pipe(
            map((userSessions) => {
                return userSessions.filter((userSession) => userSession.role === SessionRole.Leader);
            })
        );
    }

    private setLoadingStatus(isLoading: boolean): void {
        this.isSavingProgress$.next(isLoading);
    }
}
