<div class="container d-flex-column-center">
  <img
    src="/assets/shared-ui/svg/not-available.svg"
    alt="This object is no longer available"
  />
  <h3 class="tt9-h3 title text-center">This object is no longer available</h3>
  <button class="tt9-btn btn-rounded btn-with-border action btn-medium-size" [routerLink]="['/']">
    <i class="icon-chevron-left-small"></i>
    <span>Back To Home Page</span>
  </button>
</div>
