import { Injectable } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest, map, Observable, startWith, switchMap, tap } from 'rxjs';

import { ActivityService } from '@accenture/activity/shared/domain';
import {
    saveActivityToSessionInfoSnackBar,
    saveActivityToSessionSuccessSnackBar,
    SessionService,
} from '@accenture/erp-deployment/shared/domain';
import { AppState, selectAuthenticatedUserId } from '@accenture/global-store';
import {
    addingTemplateErrorSnackbarTitle,
    addingTemplateInProgressSnackbarTitle,
    errorMessageSnackbarText,
    errorMessageTemplatesText,
    ParentType,
    routerLinksMap,
    Session,
    templateAddedSnackbarTitle,
} from '@accenture/shared/data';
import { DialogService, SnackbarService, SnackBarTypes } from '@accenture/shared/ui';
import { sortByDateDesc } from '@accenture/shared/util';

import { AddSessionDialogComponent } from '../..';
import { UseTemplateStore } from '../component-stores/use-template.store';
import { CreateSessionDialogComponent } from '../create-session-dialog/create-session-dialog.component';
import { UseTemplateDialogComponent } from '../use-template-dialog/use-template-dialog.component';

export interface AddActivityTemplateToSessionDialogViewModel {
    templateType: string;
    sessions: Session[];
    searchValue: string;
    selectedSessionId: string;
    isLoading: boolean;
}

const defaultViewModel: AddActivityTemplateToSessionDialogViewModel = {
    templateType: '',
    sessions: [],
    searchValue: '',
    selectedSessionId: '',
    isLoading: false,
};

@Injectable()
export class AddActivityTemplateToSessionDialogFacade {
    private searchValue$ = new BehaviorSubject<string>('');
    private selectedSessionId$ = new BehaviorSubject<string>('');
    private isLoading$ = new BehaviorSubject<boolean>(false);

    vm$ = this.buildViewModel();

    private projectId: string;
    private userId!: string;
    private usePublicAccessId: string;
    private useTemplateId: string;
    private useTemplateType: ParentType;

    constructor(
        private store: Store<AppState>,
        private sessionService: SessionService,
        private useTemplateStore: UseTemplateStore,
        private activityService: ActivityService,
        private dialogRef: MatDialogRef<AddSessionDialogComponent>,
        private dialogService: DialogService,
        private snackbarService: SnackbarService,
        private router: Router,
    ) {}

    closeDialog(): void {
        this.dialogRef.close();
    }

    updateSearchValue(value: string): void {
        this.searchValue$.next(value);
    }

    updateSelectedTemplates(value: string): void {
        this.selectedSessionId$.next(value);
    }

    openUseTemplateModal(): void {
        this.closeDialog();
        this.dialogService.open(UseTemplateDialogComponent, {
            width: 'auto',
            panelClass: 'tt9-modal',
        });
    }

    openCreateSessionModal(): void {
        this.dialogService.open(CreateSessionDialogComponent, {
            width: '768px',
            panelClass: 'tt9-modal',
        });
    }

    async addPublicActivityTemplateToSession(sessionId: string) {
        this.showSnackBar(SnackBarTypes.Info);
        this.closeDialog();

        try {
            await this.activityService.addActivityToSession(
                this.useTemplateId,
                this.projectId,
                sessionId,
                this.userId,
                ParentType.Projects,
                this.useTemplateType,
                this.usePublicAccessId,
                undefined,
                true,
            );

            this.showSnackBar(SnackBarTypes.Success);

            const parentRoute = routerLinksMap[ParentType.Projects];
            this.router.navigate([parentRoute, this.projectId, 'session', sessionId]);
        } catch (e) {
            console.error(errorMessageTemplatesText.publicActivityTemplate);
            this.showSnackBar(SnackBarTypes.Error);
        }
    }

    async addActivityTemplateToSession(sessionId: string): Promise<void> {
        this.showSnackBar(SnackBarTypes.Info);
        this.closeDialog();
        try {
            await this.activityService.addActivityToSession(
                this.useTemplateId,
                this.projectId,
                sessionId,
                this.userId,
                ParentType.Projects,
                this.useTemplateType,
                undefined,
                undefined,
                true,
            );
            this.showSnackBar(SnackBarTypes.Success);

            const parentRoute = routerLinksMap[ParentType.Projects];
            this.router.navigate([parentRoute, this.projectId, 'session', sessionId]);
        } catch (e) {
            console.error(errorMessageTemplatesText.activityTemplate);
            this.showSnackBar(SnackBarTypes.Error);
        }
    }

    private buildViewModel(): Observable<AddActivityTemplateToSessionDialogViewModel> {
        return combineLatest([
            this.useTemplateStore.selectedProjectId$,
            this.useTemplateStore.useTemplateId$,
            this.useTemplateStore.useTemplateType$,
            this.useTemplateStore.usePublicAccessId$,
            this.getUser(),
        ]).pipe(
            tap(([selectedProjectId, useTemplateId, useTemplateType, usePublicAccessId]) =>
                this.setActualIdsData(selectedProjectId, useTemplateId, usePublicAccessId, useTemplateType),
            ),
            switchMap(([selectedProjectId]) => {
                return combineLatest([
                    this.getSessions(selectedProjectId),
                    this.searchValue$,
                    this.selectedSessionId$,
                    this.isLoading$,
                ]).pipe(
                    map(([sessions, searchValue, selectedSessionId, isLoading]) => {
                        return {
                            searchValue,
                            selectedSessionId,
                            isLoading,
                            templateType: this.useTemplateType,
                            sessions: this.getFilteredTemplates(sessions, searchValue),
                        };
                    }),
                );
            }),
            startWith(defaultViewModel),
        );
    }

    private setActualIdsData(
        projectId: string,
        useTemplateId: string,
        usePublicAccessId: string,
        useTemplateType: ParentType,
    ): void {
        this.projectId = projectId;
        this.usePublicAccessId = usePublicAccessId;
        this.useTemplateId = useTemplateId;
        this.useTemplateType = useTemplateType;
    }

    private getSessions(projectId: string): Observable<Session[]> {
        return this.sessionService
            .getSessions(ParentType.Projects, projectId)
            .pipe(map(sessions => sessions.sort(sortByDateDesc('created'))));
    }

    private getUser(): Observable<string> {
        return this.store.pipe(
            select(selectAuthenticatedUserId),
            tap(userId => (this.userId = userId)),
        );
    }

    private getFilteredTemplates(templates: Session[], searchValue: string): Session[] {
        return templates.filter(template => {
            const hasSearchName = template.name?.toLowerCase().includes(searchValue);
            const hasSearchDescription = template.description?.toLowerCase().includes(searchValue);

            return hasSearchName || hasSearchDescription;
        });
    }

    private showSnackBar(snackBarType: SnackBarTypes): void {
        switch (snackBarType) {
            case SnackBarTypes.Info:
                this.snackbarService.showSnackBar(
                    addingTemplateInProgressSnackbarTitle,
                    saveActivityToSessionInfoSnackBar,
                    SnackBarTypes.Info,
                );
                break;
            case SnackBarTypes.Success:
                this.snackbarService.showSnackBar(
                    templateAddedSnackbarTitle,
                    saveActivityToSessionSuccessSnackBar,
                    SnackBarTypes.Success,
                    true,
                );
                break;
            case SnackBarTypes.Error:
                this.snackbarService.showSnackBar(
                    addingTemplateErrorSnackbarTitle,
                    errorMessageSnackbarText,
                    SnackBarTypes.Error,
                    true,
                );
                break;
        }
    }
}
