import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { TooltipPosition } from '@angular/material/tooltip';

import { Dictionary, ImportResultType, ImportType, ParentType } from '@accenture/shared/data';
import { FilestackService } from '@accenture/shared/data-access';
import { SharedMaterialModule } from '@accenture/shared/material';
import { ImportService, LoaderSize, SharedUiModule } from '@accenture/shared/ui';

import { tooltipText } from './import-button.constants';
import { ImportButtonFacade } from './import-button-facade';

@Component({
    selector: 'accenture-import-button',
    standalone: true,
    imports: [CommonModule, SharedUiModule, SharedMaterialModule],
    templateUrl: './import-button.component.html',
    styleUrls: ['./import-button.component.scss'],
    providers: [ImportButtonFacade, FilestackService, ImportService],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImportButtonComponent {
    @Input() importType!: ImportType;
    @Input() importResultType!: ImportResultType;
    @Input() importTitle!: string;
    @Input() parentType!: ParentType;
    @Input() importData!: Dictionary<any>;
    @Input() iconName?: string;
    @Input() tooltipPosition: TooltipPosition = 'below';
    @Input() tooltipClassName = 'tt9-arrow-tooltip-above';
    @Input() buttonClassName = 'tt9-btn-ghost';
    @Input() showButtonText?: boolean;
    @Input() isMatMenu?: boolean;

    @Output() importStarted: EventEmitter<void> = new EventEmitter<void>();
    @Output() importFailed: EventEmitter<any> = new EventEmitter<any>();
    @Output() importCompleted: EventEmitter<string[]> = new EventEmitter<string[]>();

    private readonly facade = inject(ImportButtonFacade);

    vm$ = this.facade.vm$;

    loaderSize = LoaderSize.Small;
    tooltipText = tooltipText;

    async import(): Promise<void> {
        try {
            const result = await this.facade.upload(
                this.importType,
                this.importResultType,
                this.parentType,
                this.importData,
                () => this.importStarted.emit(),
            );
            this.importCompleted.emit(result);
        } catch (e) {
            this.importFailed.emit(e);
        }
    }
}
