<accenture-base-dialog [title]="title">
  <div class="option-selection-dialog-container">
    <ng-container *ngFor="let option of options">
      <div
        class="option-selection-dialog-wrapper"
        *ngIf="(option.name === buttonAiGenerate && showAiGenerate) || option.name !== buttonAiGenerate"
        (click)="option.callBack()"
      >
        <img class="option-selection-dialog-image" [src]="option.imageUrl" alt="No elements" />
        <p class="option-selection-label tt9-h3 mt-8 text-center">{{ option.name }}</p>
      </div>
    </ng-container>
  </div>
</accenture-base-dialog>
