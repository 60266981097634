import { ActivityType } from './activities';

export interface EmptyScreenConfig {
    title: string;
    description?: string;
    compact?: boolean;
}

export enum EmptyScreenType {
    Default = 'DEFAULT',
    Small = 'SMALL',
}

export const navigationCardImageUrl = {
    path: '/assets/erp-shared-ui/svg/navigation-card.svg',
};

export const imageUrl = {
    path: '/assets/shared-ui/svg/empty-screen.svg',
};

export const noItemsFoundImageUrl = {
    path: '/assets/shared-ui/svg/no-items-found.svg',
};

export const emptyScreenImageUrlQuickPoll = {
    path: '/assets/shared-ui/svg/empty-screen-quick-poll.svg',
};

export const emptyScreenImageUrlVoteEditor = {
    path: '/assets/shared-ui/svg/empty-screen-vote-editor.svg',
};

export const emptyScreenImageUrlVote = {
    path: '/assets/shared-ui/svg/empty-screen-vote.svg',
};

export const emptyScreenImageUrlBrainstorm = {
    path: '/assets/shared-ui/svg/empty-screen-brainstorm.svg',
};

export const emptyScreenImageUrlTableActivity = {
    path: '/assets/shared-ui/svg/description-session.svg',
};

export const emptyScreenImageUrlTable = {
    path: '/assets/shared-ui/svg/empty-screen-brainstorm-edit-topics.svg',
};

export const emptyScreenImageUserProfile = {
    path: '/assets/shared-ui/svg/empty-screen-projects.svg',
};

export const emptyScreenResponses = {
    path: '/assets/shared-ui/svg/empty-screen-brainstorm.svg',
};

export const emptyScreenImageUrlPresent = {
    path: '/assets/shared-ui/svg/empty-screen-present.svg',
};

export const emptyScreenImageUrlAttributes = {
    path: '/assets/shared-ui/svg/empty-screen-attributes.svg',
};

export const emptyScreenImageUrlTemplates = {
    path: '/assets/shared-ui/svg/empty-screen-templates.svg',
};

export const emptyScreenImageUrlStore = {
    path: '/assets/shared-ui/svg/empty-screen-store.svg',
};

export const emptyScreenImageUrlFavorite = {
    path: '/assets/shared-ui/svg/empty-screen-favorite.svg',
};

export const emptyScreenImage = {
    path: '/assets/shared-ui/svg/empty-screen-projects.svg',
};

export const emptyScreenImageUrlUserProjects = {
    path: '/assets/shared-ui/svg/description-project.svg',
};

export const emptyScreenImageUrlUserProjectsSearch = {
    path: '/assets/shared-ui/svg/search-result-empty.svg',
};

export const emptyScreenImageUrlSessions = {
    path: '/assets/shared-ui/svg/description-session.svg',
};

export const emptyScreenImageUrlActivities = {
    path: '/assets/shared-ui/svg/empty-screen-brainstorm-edit-topics.svg',
};

export const emptyScreenImageUrlBrainstormEditGroups = {
    path: '/assets/shared-ui/svg/empty-screen-brainstorm-edit-groups.svg',
};

export const emptyScreenImageUrlBrainstormEditTopics = {
    path: '/assets/shared-ui/svg/empty-screen-brainstorm-edit-topics.svg',
};

export const emptyScreenNoMobileDesign = {
    path: '/assets/shared-ui/svg/empty-screen-no-mobile-design.svg',
};

export const emptyScreenDesign = {
    path: '/assets/shared-ui/svg/empty-screen-illustration.svg',
};
export const emptyScreenInAppNotificationsDesign = {
    path: '/assets/shared-ui/svg/empty-screen-in-app-notifications.svg',
};
export const emptyScreenImageUrlLiveShare = {
    path: '/assets/shared-ui/svg/empty-screen-live-share.svg',
};

export const emptyScreenImageUrlBrainstormGroups = {
    path: '/assets/shared-ui/svg/empty-screen-brainstorm-groups.svg',
};

export const emptyScreenImageUrlUsers = {
    path: '/assets/shared-ui/svg/empty-screen-users.svg',
};

export const emptyScreenImageUrlAll = {
    path: '/assets/shared-ui/svg/empty-screen-all.svg',
};

// !!! TECH NOTE !!! Please add here to display in style guide
export const allEmptyScreens = {
    navigationCardImageUrl,
    imageUrl,
    noItemsFoundImageUrl,
    emptyScreenImageUrlQuickPoll,
    emptyScreenImageUrlVoteEditor,
    emptyScreenImageUrlVote,
    emptyScreenImageUrlBrainstorm,
    emptyScreenImageUrlTableActivity,
    emptyScreenImageUrlTable,
    emptyScreenImageUserProfile,
    emptyScreenResponses,
    emptyScreenImageUrlPresent,
    emptyScreenImageUrlAttributes,
    emptyScreenImageUrlTemplates,
    emptyScreenImageUrlStore,
    emptyScreenImageUrlFavorite,
    emptyScreenImage,
    emptyScreenImageUrlUserProjects,
    emptyScreenImageUrlUserProjectsSearch,
    emptyScreenImageUrlSessions,
    emptyScreenImageUrlActivities,
    emptyScreenImageUrlBrainstormEditGroups,
    emptyScreenImageUrlBrainstormEditTopics,
    emptyScreenNoMobileDesign,
    emptyScreenDesign,
    emptyScreenInAppNotificationsDesign,
    emptyScreenImageUrlLiveShare,
    emptyScreenImageUrlUsers,
    emptyScreenImageUrlAll,
};

// TODO: add path to all activities when they would be ready
export const emptyScreenActivities = {
    [ActivityType.Vote]: emptyScreenImageUrlVote,
    [ActivityType.Brainstorm]: emptyScreenImageUrlBrainstorm,
    [ActivityType.QuickPoll]: emptyScreenImageUrlQuickPoll,
    [ActivityType.Table]: navigationCardImageUrl,
    [ActivityType.Present]: navigationCardImageUrl,
    [ActivityType.LiveShare]: emptyScreenImageUrlLiveShare,
};

//TODO: remove project constants when collection feature is finished
export const emptyScreenTitles = {
    noQuestionsYet: 'There are no questions yet',
    noQuestionYet: 'There is no question yet',
    noOptionsYet: 'There are no options yet',
    noBallotsYet: 'There are no ballots yet',
    noParametersYet: 'There are no criteria yet',
    noVoteItemsYet: 'There are no ballot items or vote criteria yet',
    noVoteResponsesYet: 'No responses given',
    noTopicsYet: 'There are no topics yet',
    noGroupsYet: 'There are no groups yet',
    noGroupsForTopicYet: 'This topic has no groups yet',
    noTopicResponsesYet: 'This topic has no responses yet',
    createFirstActivity: 'Create first activity',
    noActivitiesYet: 'There are no activities yet',
    noActivitiesFound: 'No activities match your search criteria',
    noTemplatesMatch: 'No templates match your filter criteria',
    noTemplatesFound: 'No templates match your search criteria',
    noTemplatesMatchAndFound: 'No templates match your filter and search criteria',
    noProjectsMatch: 'No projects match your filter criteria',
    noProjectsFound: 'No projects match your search criteria',
    noProjectTemplates: 'There are no project templates yet',
    noSessionsMatch: 'No sessions match your filter criteria',
    noSessionsFound: 'No sessions match your search criteria',
    noSessionsFilterAndSearch: 'No sessions match your filter and search criteria',
    noSessionsYet: 'There are no sessions yet',
    noSessionsAdmin: 'There are no sessions in this project for the user',
    noSessionsAdminYet: 'This user does not have any sessions yet',
    noSessionsProjectAdmin: 'There are no sessions in this project',
    noActivitiesMatch: 'No activities match your search criteria',
    noResponsesMatch: 'No responses match your search criteria',
    noChartsYet: 'There are no charts yet',
    noPresent: 'There is nothing to present',
    noPresentResponses: 'No responses yet',
    noTopicGroupsYet: 'This topic has no groups yet',
    noActivityGroupsYet: 'This activity has no groups yet',
    noTemplatesYet: 'There are no templates yet',
    noProjectsTemplatesYet: 'There are no project templates yet',
    noSessionsTemplatesYet: 'There are no session templates yet',
    noActivitiesTemplatesYet: 'There are no activity templates yet',

    // TODO: Remove proj constants once project-related code is deprecated.
    noProjectsYet: 'There are no projects yet',
    noProjectsAdminYet: 'This user does not have any projects yet',
    noProjectsAdminSearch: 'No projects match your search criteria',
    noProjectsAdminFilter: 'No projects match your filter criteria',
    noProjectsAdminFilterAndSearch: 'No projects match your filter and search criteria',
    noProjectsAndTemplates: 'There are no Projects, Sessions and Templates you are member on',
    noSessionsAdminFilterAndSearch: 'No session details match search & filter criteria',
    noSessionsSearchMatch: 'No session details match search criteria',
    noSessionsAdminFilter: 'No session details match filter criteria',
    noUsersMatch: 'No users match your search criteria',
    noUsersSearchMatch: 'No user details match your search criteria',
    noUsersFilterMatch: 'No user details match your filter criteria',
    noUsersYet: 'No users added',
    noVoteDisplayed: 'No vote results to display. Leader will open them shortly!',
    noAttributesYet: 'Please select your attributes to begin this activity',
    noTablesYet: 'There are no tables yet',
    addTable: 'Please add table to start',
    noColumnsYet: 'There are no columns yet',
    addColumn: 'Please add a column to start',
    noAttributesMatch: 'No attributes match your search criteria',
    noAttributeGroupsYet: 'There are no attribute groups yet',
    noAttributesAddedYet: 'There are no attributes yet',
    noPromptsAddedYet: 'No prompts have been configured',
    noPreconfiguredResources: 'There are no preconfigured resources',
    liveShareNotStarted: 'LiveShare has not started yet',
    liveShareStarted: 'LiveShare has started',
    noResources: 'There are no resources to share yet',
    noAdaptiveDesignYet: 'Use desktop to view the page',
    noInAppNotificationsYet: 'There are no notifications yet',
    noInAppNotificationsSearchMatch: 'No notifications match your search criteria',
    noInAppNotificationsFilterMatch: 'No notifications match your filter criteria',
    noProjectSearchMatch: 'No project details match your search criteria',
    noMessagesYet: 'There are no messages yet',
    noUsersFilterAndSearchMatches: 'No users match your filter and search criteria',
    noUsersFilterCriteriaMatch: 'No users match your filter criteria',
    noCollections: 'There are no collections available',
    noSessionsInCollection: 'There are no sessions in this collection',
    noCollectionsYet: 'There are no collections yet',
    noCollectionsFilterAndSearchMatches: 'No collections match your filter and search criteria',
    noCollectionsFilterMatches: 'No collections match your filter criteria',
    noCollectionsSearchMatches: 'No collections match your search criteria',
    noSessionAvailable: 'There are no sessions available',
};

export const emptyScreenDescriptions = {
    noActivitiesYet: 'Please create an activity to start',
    noElementsDefault: 'Gather lots of valuable information, from lots of people, in a short amount of time.',
    noMatchDefault:
        'Effortlessly gather requirements and collect valuable information from large groups of people, in the same room or around the globe.',
    noChartsDescription:
        'Look at the similarities and differences between ballot items across different criteria. Bar chart and Bubble chart can be used for comparison.',
    noGroupsYet: 'Create Groups to organize responses',
    noTopicsYetDescription: 'Gather lots of valuable information, from lots of people, in a short amount of time',
    noPresentationYet: 'Upload a presentation to share with your participants.',
    noProjectsOnHomeYet: 'Navigate to projects page and create your first project',
    noSessionsYet: 'Please create session to start',
    noVoteDisplayedYet: 'Look at the similarities and differences between ballot items across different criteria.',
    noTablesYet: 'Collect data and engage with stakeholders in a structured tabular view',
    noVoteResponsesYet: 'Please wait for responses to be given',
    noAttributesMatch: 'Please try another search request',
    noAttributeGroupsYet: 'Please add attribute group',
    noAttributesAddedYet: 'Please add attributes',
    noPromptsAddedYet: 'Set up predefined inquiries intended for GPT to respond to',
    leaderSharingSoon: 'Please wait - a Leader will start sharing soon',
    noAdaptiveDesignYet: 'This page has not been optimized for mobile. Please login on a desktop to view the page',
    // TODO: Remove this once project-related code is deprecated.
    noSearchFilterYet: 'To download all project reports, click the "Export Project Report" button',
    noSearchFilterYetSessions: 'To download all session reports, click the "Export Session Report" button',
    noSearchFilterYetUsers: 'To download all users reports, click the "Export User Report" button',
    liveShareStartSharing: 'Click the "Start Sharing" button to start sharing',
    liveShareSharing: 'Click the “Stop Sharing” button to stop sharing',
    noResources: 'Please add a resource to start with',
    noSessionsInProjectTeam: 'Please go to Project page to create sessions',
    noCollections: 'Please create a new collection',
    noSessionsInCollection: 'Add, create or generate new session to start',
    noCollectionsYet: 'Organize sessions by adding collections.',
    noInvitedSessionsYet: `You're not invited to any sessions yet. Once you have been invited to a session, you’ll see it listed here`,
};

export const emptyScreenButtonText = {
    noCollections: 'Create Collection',
};

export const emptyScreenEditActivityButton = {
    editActivity: 'Edit Activity',
    editTable: 'Edit Table',
};

export const emptyScreenShareButton = {
    startSharing: 'Start Sharing',
    stopSharing: 'Stop Sharing',
};
