import { Question } from '../question';
import { ActivityItemType } from './activity-item-type';

export class BrainstormTopic extends Question {
    type = ActivityItemType.BrainstormTopic;

    responsePrompt?: string;
    commentTitle?: string;
    commentPrompt?: string;
    numberOfVotes?: number;

    constructor(data: Partial<BrainstormTopic>) {
        super(data);

        this.responsePrompt = data.responsePrompt ?? '';
        this.commentTitle = data.commentTitle ?? '';
        this.commentPrompt = data.commentPrompt ?? '';
        this.numberOfVotes = data.numberOfVotes ?? null;
    }

    createSerializableObject(): Record<string, unknown> {
        return {
            ...super.createSerializableObject(),
            responsePrompt: this.responsePrompt,
            commentTitle: this.commentTitle,
            commentPrompt: this.commentPrompt,
            numberOfVotes: this.numberOfVotes,
        };
    }
}
