import { ActivityItemType, defaultBallotItemsColumnWidth } from '../activity-items';
import { AllSelected } from '../all-selected';
import { Dictionary } from '../dictionary';
import { NodeTemplate, NodeType } from '../node-template';
import { FileType } from '../responses';
import { UpdatedByUser } from '../user';
import { Activity, ActivityType } from './';

export enum VoteSummaryTab {
    Table = 'table',
    BarCharts = 'bar-charts',
    PieCharts = 'pie-charts',
    BubbleCharts = 'bubble-charts',
    WordClouds = 'word-clouds',
    EmptyScreen = 'empty-screen',
}

export enum BubbleChartFilters {
    XParameterId = 'xParameterId',
    YParameterId = 'yParameterId',
    BubbleSize = 'bubbleSize',
}

export enum ChartType {
    Bar = 'BAR',
    Bubble = 'BUBBLE',
    Pie = 'PIE',
}

export type VoteSummaryFilterSelection = string[] | AllSelected;

export interface VoteSummaryData {
    id?: string;
    criteria: { [key in VoteSummaryTab]?: VoteSummaryFilterSelection };
    bubbleCriteria?: { [key in BubbleChartFilters]?: string };
    attributes: VoteSummaryFilterSelection;
    showAttributes: boolean;
    showAverageAcrossAllAttributes?: boolean;
    showAbstained?: boolean;
    showStandardDeviation?: boolean;
    sortOptions?: VoteSummarySortOptions;
}

export interface VoteComparisonChartData {
    id?: string;
    name: string;
    chartType: ChartType;
    parameters: VoteSummaryFilterSelection;
    attributes: VoteSummaryFilterSelection;
    showAllAttributes: boolean;
    showStandardDeviation: boolean;
    sequence: string;
    xParameterId?: string;
    yParameterId?: string;
    sizeParameterId?: string;
}

export type VoteSummaryTabsVisibility = {
    [tab in VoteSummaryTab]?: boolean;
};

export interface VoteSummarySortOptions {
    parameterId: string;
    parameterExtra?: ParameterExtrasType;
    direction: SortingDirection;
}

export interface VoteDetailsOptions {
    id?: string;
    sortingDirection: SortingDirection;
    hideStdDev: boolean;
    includeAbstainedUsers: boolean;
    showWordCloud: boolean;
    includeUnusedPart: boolean;
    chartType: ChartType;
}

export enum ParameterExtrasType {
    stdDev = 'stdDev',
    avg = 'avg',
}

export const activeTypesWithAverageValue = [
    ActivityItemType.Slider,
    ActivityItemType.Numeric,
    ActivityItemType.StarVoting,
];

export enum SortingDirection {
    Ascending = 'asc',
    Descending = 'desc',
}

export interface VoteSummarySortingData {
    voteItemId?: string;
    response: any;
    byAttributes: string[];
    sequence: number;
}

export class Vote extends NodeTemplate implements Activity {
    type = ActivityType.Vote;

    sessionId?: string;
    projectId?: string;
    visible?: boolean;
    templateId?: string;
    sequence: string;
    useAttributes?: boolean;
    selectedAttributes?: Dictionary<boolean>;
    sectionId?: string;
    importedFiles?: FileType[];
    creatorId?: string;
    creatorName?: string;
    creatorImage?: string;
    favoritesCount?: number;

    // vote specific
    anonymous?: boolean;
    allowChangeAnswers?: boolean;
    additionalTextAndImageVisible?: boolean;
    additionalText?: string;
    additionalImage?: FileType;
    waitingScreenVisible?: boolean;
    waitingScreenText?: string;
    waitingScreenImage?: FileType;
    statusScreenVisible?: boolean;
    statusScreenText?: string;
    statusScreenImage?: FileType;
    isOptionConfigured?: boolean;
    tags?: Dictionary<string>;

    // for public activity templates
    timesUsed?: number;
    creatorNotes?: string;
    updatedBy?: UpdatedByUser;

    dndDisabled?: boolean;

    ballotItemsColumnWidth?: number;
    isResizing?: boolean;

    constructor(id: string | undefined, vote: Partial<Vote>) {
        super(id, vote, NodeType.Activity);

        this.projectId = vote.projectId ?? '';
        this.sessionId = vote.sessionId ?? '';
        this.templateId = vote.templateId ?? '';
        this.visible = vote.visible ?? false;
        this.sequence = vote.sequence ?? '';
        this.sectionId = vote.sectionId;
        this.importedFiles = vote.importedFiles ?? [];

        this.anonymous = vote.anonymous ?? false;
        this.allowChangeAnswers = vote.allowChangeAnswers ?? false;
        this.useAttributes = vote.useAttributes ?? false;
        this.selectedAttributes = (vote.selectedAttributes as Dictionary<boolean>) || {};
        this.additionalTextAndImageVisible = vote.additionalTextAndImageVisible ?? false;
        this.additionalText = vote.additionalText ?? '';
        this.additionalImage = vote.additionalImage ?? ({} as FileType);
        this.waitingScreenVisible = vote.waitingScreenVisible ?? false;
        this.waitingScreenText = vote.waitingScreenText ?? '';
        this.waitingScreenImage = vote.waitingScreenImage ?? ({} as FileType);
        this.statusScreenVisible = vote.statusScreenVisible ?? false;
        this.statusScreenText = vote.statusScreenText ?? '';
        this.statusScreenImage = vote.statusScreenImage ?? ({} as FileType);
        this.isOptionConfigured = vote.isOptionConfigured ?? false;
        this.creatorId = vote.creatorId ?? '';
        this.creatorImage = vote.creatorImage ?? '';
        this.creatorName = vote.creatorName ?? '';
        this.favoritesCount = vote.favoritesCount ?? 0;
        this.tags = (vote.tags as Dictionary<string>) ?? {};

        this.creatorNotes = vote.creatorNotes ?? '';
        this.timesUsed = vote.timesUsed ?? 0;
        this.updatedBy = vote.updatedBy ?? undefined;

        this.dndDisabled = !!vote.dndDisabled;
        this.isResizing = !!vote.isResizing;

        this.ballotItemsColumnWidth = vote.ballotItemsColumnWidth ?? defaultBallotItemsColumnWidth;
    }

    createSerializableObject(): Record<string, unknown> {
        return {
            name: this.name,
            description: this.description,
            projectId: this.projectId,
            sessionId: this.sessionId,
            sectionId: this.sectionId,
            templateId: this.templateId,
            visible: this.visible,
            created: this.created,
            updated: this.updated,
            sequence: this.sequence,
            anonymous: this.anonymous,
            allowChangeAnswers: this.allowChangeAnswers,
            useAttributes: this.useAttributes,
            selectedAttributes: this.selectedAttributes,
            additionalTextAndImageVisible: this.additionalTextAndImageVisible,
            additionalText: this.additionalText,
            additionalImage: this.additionalImage,
            waitingScreenVisible: this.waitingScreenVisible,
            waitingScreenText: this.waitingScreenText,
            waitingScreenImage: this.waitingScreenImage,
            statusScreenVisible: this.statusScreenVisible,
            statusScreenText: this.statusScreenText,
            statusScreenImage: this.statusScreenImage,
            isOptionConfigured: this.isOptionConfigured,
            type: this.type,
            creatorId: this.creatorId,
            creatorImage: this.creatorImage,
            creatorName: this.creatorName,
            creatorNotes: this.creatorNotes,
            timesUsed: this.timesUsed,
            tags: this.tags,
        };
    }
}
