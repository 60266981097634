import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';

import { Dictionary } from '@accenture/shared/data';

type FromToDateValidatorFormGroup = FormGroup<Dictionary<FormControl<Date>>>;

export const fromToDateValidator = (
    fromDateControlName: string,
    toDateControlName: string,
    setBothErrors?: boolean,
): ValidatorFn => {
    return (formGroup: FromToDateValidatorFormGroup): ValidationErrors | null => {
        const fromControl = formGroup.controls[fromDateControlName];
        const toControl = formGroup.controls[toDateControlName];
        const fromControlErrors = (fromControl || <AbstractControl>{}).errors;
        const toControlErrors = (toControl || <AbstractControl>{}).errors;
        const isFromGrTo = fromControl?.value && toControl?.value && fromControl?.value > toControl?.value;

        if (isFromGrTo) {
            toControl.setErrors({ ...toControlErrors, invalidDate: true });
            toControl.markAsTouched();

            if (setBothErrors) {
                fromControl.setErrors({ ...fromControlErrors, invalidDate: true });
                fromControl.markAsTouched();
            }

            return { invalidDate: true };
        } else {
            if (!!toControlErrors?.invalidDate) {
                delete toControlErrors.invalidDate;
                !Object.keys(toControlErrors).length ? toControl.setErrors(null) : toControl.setErrors(toControlErrors);
            }

            if (!!fromControlErrors?.invalidDate) {
                delete fromControlErrors.invalidDate;
                !Object.keys(fromControlErrors).length
                    ? fromControl.setErrors(null)
                    : fromControl.setErrors(fromControlErrors);
            }
        }

        return null;
    };
};
