export enum LoaderType {
    SpinnerDefault = 'spinner',
    SpinnerWhite = 'spinner-white',
}

export enum LoaderSize {
    Default = '88px',
    Small = '40px',
    MediumSmall = '24px',
    XSmall = '20px',
}

export const loaderImageUrls = {
    [LoaderType.SpinnerDefault]: '/assets/erp-shared-ui/spinner.png',
    [LoaderType.SpinnerWhite]: '/assets/erp-shared-ui/spinner-white.png',
};
