<mat-slide-toggle
  class="accenture-toggle mb-16"
  color="primary"
  [checked]="data.displayAgenda"
  (change)="updateDisplayAgenda($event.checked)"
>
  Display Agenda to Participants
</mat-slide-toggle>
<mat-slide-toggle
  class="accenture-toggle mb-16"
  color="primary"
  [disabled]="!data.displayAgenda"
  [checked]="data.selfNavigate"
  (change)="updateSelfNavigate($event.checked)"
>
  Allow Participants to self-navigate
</mat-slide-toggle>
