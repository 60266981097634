import { ConnectionPositionPair } from '@angular/cdk/overlay';

export interface TooltipConfig {
    data: any;
    position: TooltipPosition;
    class: string;
}

export enum TooltipPosition {
    Top = 'TOP',
    Left = 'LEFT',
    Right = 'RIGHT',
    Bottom = 'BOTTOM',
}

export const positionMap = {
    [TooltipPosition.Left]: new ConnectionPositionPair(
        { originX: 'start', originY: 'center' },
        { overlayX: 'end', overlayY: 'center' },
    ),
    [TooltipPosition.Right]: new ConnectionPositionPair(
        { originX: 'end', originY: 'center' },
        { overlayX: 'start', overlayY: 'center' },
    ),
    [TooltipPosition.Top]: new ConnectionPositionPair(
        { originX: 'center', originY: 'top' },
        { overlayX: 'center', overlayY: 'bottom' },
    ),
    [TooltipPosition.Bottom]: new ConnectionPositionPair(
        { originX: 'center', originY: 'bottom' },
        { overlayX: 'center', overlayY: 'top' },
    ),
};
