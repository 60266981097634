import { Injectable } from '@angular/core';
import { unset } from 'lodash';
import { firstValueFrom,map, Observable } from 'rxjs';

import {
    ActivityOptions,
    ActivityOptionsType,
    CollectionsSortObject,
    DBPathHelper,
    Dictionary,
    ParentType,
    TemplateAccessType,
} from '@accenture/shared/data';
import { FirestoreService } from '@accenture/shared/data-access';

@Injectable({
    providedIn: 'root',
})
export class ActivityOptionsService {
    constructor(private firestoreService: FirestoreService) {}

    getOptions(type: ParentType): Observable<ActivityOptionsType[]> {
        return this.firestoreService.getDocumentsByProperty<ActivityOptionsType>(
            DBPathHelper.getTagsPath(),
            'type',
            type,
        );
    }

    getCurrentUserCollectionsSorting(userId: string | undefined): Observable<CollectionsSortObject> {
        return this.firestoreService
            .getDocument<CollectionsSortObject>(`/users/${userId}/filters/collectionsFilters`)
            .pipe(
                map((filter: CollectionsSortObject) => {
                    unset(filter, 'id');
                    return filter;
                }),
            );
    }

    getNewOptionId(): string {
        return this.firestoreService.getPushId();
    }

    async updateOptions(
        selectedOption: Dictionary<string>,
        activityId: string,
        isAddNew: boolean | undefined,
    ): Promise<void> {
        const optionId = Object.keys(selectedOption)[0];
        const optionName = Object.values(selectedOption)[0];
        let option = {
            activities: [activityId],
            type: ParentType.Activities,
            created: this.firestoreService.timestamp,
            updated: this.firestoreService.timestamp,
        } as ActivityOptionsType;

        if (isAddNew) {
            option = {
                ...option,
                name: optionName,
            };
            await this.firestoreService.update(DBPathHelper.getTagsPath(optionId), option);
        } else {
            await this.firestoreService.update(DBPathHelper.getTagsPath(optionId), {
                activities: this.firestoreService.arrayUnion(activityId),
                updated: this.firestoreService.timestamp,
            });
        }
    }

    async removeOption(activityId: string, optionToRemove: string): Promise<void> {
        if (!optionToRemove) {
            return;
        }
        await this.firestoreService.update(DBPathHelper.getTagsPath(optionToRemove), {
            activities: this.firestoreService.arrayRemove(activityId),
            updated: this.firestoreService.timestamp,
        });
    }

    async removeOptionFromAccess(
        accessPath: string,
        templateId: string,
        option: ActivityOptions,
        selectedOptionId: string,
    ): Promise<void> {
        const templateAccesses = await firstValueFrom(
            this.firestoreService.getDocumentsByProperty<Partial<TemplateAccessType>>(
                accessPath,
                'templateId',
                templateId,
            ),
        );

        if (!templateAccesses.length) {
            return;
        }

        const batchData = [];

        for (const templateAccess of templateAccesses) {
            if (!templateAccess?.id) {
                continue;
            }

            batchData.push({
                path: `${accessPath}/${templateAccess.id}`,
                data: {
                    [option]: { [selectedOptionId]: this.firestoreService.deleteField },
                    updated: this.firestoreService.timestamp,
                },
            });
        }

        await this.firestoreService.writeBatch(batchData);
    }
}
