import { createFeatureSelector, createSelector } from '@ngrx/store';

import { Collection, CollectionsDisplayMode, CollectionSession, UserCollection } from '@accenture/shared/data';

import { AppState } from '../app.state';
import { COLLECTION_FEATURE_KEY, CollectionState } from './collection.reducer';

export const collectionState = createFeatureSelector<CollectionState>(COLLECTION_FEATURE_KEY);

export const selectCollection = createSelector<AppState, [CollectionState], Collection>(
    collectionState,
    (collectionStateData) => collectionStateData?.collection,
);

export const selectUserCollection = createSelector<AppState, [CollectionState], UserCollection>(
    collectionState,
    (collectionStateData) => collectionStateData?.userCollection,
);

export const selectCollectionSessions = createSelector<AppState, [CollectionState], CollectionSession[]>(
    collectionState,
    (collectionStateData) => collectionStateData?.sessions,
);

export const selectCollectionsDisplayMode = createSelector<AppState, [CollectionState], CollectionsDisplayMode>(
    collectionState,
    (collectionStateData) => collectionStateData?.displayMode,
);
