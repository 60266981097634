import { AfterContentInit, ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Observable } from 'rxjs';

import { ActivityType, Brainstorm, FileType, Present, Vote } from '@accenture/shared/data';
import { IconSize } from '@accenture/shared/ui';
import { alphabeticalKeyValueOrder, trackById } from '@accenture/shared/util';

import { ActivityPreviewFacade, ActivityPreviewViewModel } from './activity-preview-facade';

@Component({
    selector: 'accenture-activity-preview',
    templateUrl: './activity-preview.component.html',
    styleUrls: ['./activity-preview.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [ActivityPreviewFacade],
})
export class ActivityPreviewComponent implements AfterContentInit {
    @Input() activityId: string;

    @Output() closePanelClicked = new EventEmitter();
    @Output() navigateToActivityClicked = new EventEmitter();

    vm$: Observable<ActivityPreviewViewModel>;

    trackById = trackById;
    alphabeticalKeyValueOrder = alphabeticalKeyValueOrder;
    iconSize = IconSize;
    activityType = ActivityType;

    constructor(private facade: ActivityPreviewFacade) {}

    showContentSection(vm: ActivityPreviewViewModel): boolean {
        return vm.content.length && vm.activity.type !== ActivityType.LiveShare;
    }

    showToolSection(vm: ActivityPreviewViewModel): boolean {
        return !!(
            (vm.activity as Brainstorm | Vote).importedFiles?.length
            || (vm.activity as Present).presentationFile
            || (vm.activity.type === ActivityType.LiveShare && vm.content.length)
        );
    }

    closePanel(): void {
        this.closePanelClicked.emit();
    }

    downloadFile(event: MouseEvent, file: FileType): void {
        this.facade.downloadFile(event, file);
    }

    ngAfterContentInit(): void {
        this.vm$ = this.facade.vm$(this.activityId);
    }
}
