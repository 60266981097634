import { createFeatureSelector, createSelector } from '@ngrx/store';

import { TeamMember } from '@accenture/shared/data';

import { PUBLIC_TEMPLATE_FEATURE_KEY, PublicTemplateState } from './public-template.reducer';

export const publicTemplateState = createFeatureSelector<PublicTemplateState>(PUBLIC_TEMPLATE_FEATURE_KEY);

export const selectPublicTeamMember = createSelector(
    publicTemplateState,
    publicTemplateStateData => publicTemplateStateData?.parentTeamMember,
);
