<div *ngIf="vm$ | async as vm" class="session-team-container" (accentureClickOutside)="closePanel.emit()">
  <div class="tt9-side-panel-header">
    <h6 class="tt9-h2">Session team</h6>

    <div class="spacer"></div>

    <button
      class="tt9-btn-icon-filled mr-8"
      matTooltipClass="tt9-arrow-tooltip-above"
      matTooltip="Add new members to the session"
      (click)="addMembers()"
    >
      <accenture-icon>icon-person-add</accenture-icon>
    </button>

    <accenture-import-button-tt9
      [tooltipClassName]="'tt9-arrow-tooltip-above-right'"
      [importType]="importType"
      [importResultType]="importResultType"
      [parentType]="parentType"
      [iconName]="'icon-file-import'"
      (importStarted)="showProgressSnackbar()"
      (importCompleted)="showSuccessSnackbar()"
      (importFailed)="showImportFailed($event)"
    />

    <button class="tt9-btn-icon-filled ml-8" (click)="closePanel.emit()">
      <accenture-icon>icon-close</accenture-icon>
    </button>
  </div>

  <div class="body">
    <!-- User search -->
    <div class="user-search-container">
      <mat-form-field class="user-search tt9-form-field no-label no-margin" appearance="outline" floatLabel="always">
        <input
          #search
          matInput
          placeholder="Search"
          [accentureDisabledControl]="vm.isUserSearchAvailable"
          [formControl]="searchControl"
          (input)="updateSearchValue(search.value)"
        />

        <accenture-icon matPrefix>icon-search</accenture-icon>
        <accenture-icon *ngIf="searchControl.value" matSuffix (click)="clearSearch($event)">
          icon-close
        </accenture-icon>
      </mat-form-field>
    </div>
    <!-- /User search -->

    <!-- Leaders list -->
    <ng-container *accentureLoaded="!vm.isLoading">
      <ng-container
        *ngTemplateOutlet="
          userList;
          context: {
            users: vm.leaderUsers,
            userRole: sessionRoles.Leader,
            mainUserId: vm.sessionCreatorId,
            currentUserId: vm.currentUserId,
            sessionId: vm.sessionId,
            userInActionIds: vm.userInActionIds,
            teamMemActivities: vm.teamMemActivities,
            sessionActivities: vm.sessionActivities,
            showGetLinks: vm.showGetLinks
          }
        "
      ></ng-container>
      <!-- Leaders list -->

      <!-- Participants list -->
      <ng-container
        *ngTemplateOutlet="
          userList;
          context: {
            users: vm.participantUsers,
            userRole: sessionRoles.Participant,
            mainUserId: vm.sessionCreatorId,
            currentUserId: vm.currentUserId,
            sessionId: vm.sessionId,
            userInActionIds: vm.userInActionIds,
            teamMemActivities: vm.teamMemActivities,
            sessionActivities: vm.sessionActivities,
            showGetLinks: vm.showGetLinks
          }
        "
      ></ng-container>
      <!-- Participants list -->
    </ng-container>
  </div>
  <div class="team-list-footer">
    <button class="tt9-btn-default expand-btn" (click)="navigateToSessionTeamPage(vm.sessionId)">
      Open In Expanded View
    </button>
  </div>
</div>

<ng-template
  #userList
  let-sessionId="sessionId"
  let-users="users"
  let-userRole="userRole"
  let-currentUserId="currentUserId"
  let-mainUserId="mainUserId"
  let-userInActionIds="userInActionIds"
  let-teamMemActivities="teamMemActivities"
  let-sessionActivities="sessionActivities"
  let-showGetLinks="showGetLinks"
>
  <div class="user-list">
    <span class="user-list-header h6">{{ userRole | displayUserAccessRole }}s</span>
    <div
      *ngFor="let user of users; trackBy: trackById; let userIndex = index"
      [class.hovered]="user.id === openedMenuUserId"
    >
      <div class="user-list-item">
        <div class="user-list-userpic">
          <div class="tt9-userpic small tt9-tooltip">
            <img *ngIf="user.imageUrl" [src]="user.imageUrl" [alt]="user.displayName" alt="{{ user.displayName }}" />
            <ng-container *ngIf="!user.imageUrl">{{ user.displayName | userInitials }}</ng-container>
          </div>
          <div *ngIf="user.isOnline" class="user-list-userpic-online-badge"></div>
        </div>

        <span
          class="user-list-item-username text-overflow-ellipsis tt9-body-medium-2"
          matTooltipPosition="above"
          matTooltipClass="tt9-arrow-tooltip-below-right"
          [matTooltip]="user.displayName"
        >
          {{ user.displayName }}
        </span>

        <div
          *ngIf="isUserHasMenu(user, mainUserId, currentUserId)"
          class="user-list-item-actions"
          [class.disabled]="userInActionIds.has(user.id)"
        >
          <div
            #userMenuTrigger="matMenuTrigger"
            [matMenuTriggerFor]="userMenu"
            (menuOpened)="setTriggerMenuData(user.id, userMenuTrigger)"
            (menuClosed)="setTriggerMenuData()"
            (click)="$event.stopPropagation()"
          >
            <accenture-icon> icon-more </accenture-icon>
          </div>
          <mat-menu #userMenu="matMenu" class="tt9-menu" (click)="$event.stopPropagation()">
            <ng-container *ngIf="!user.guest">
              <button
                *ngFor="let role of availableRolesMap[userRole]"
                mat-menu-item
                (click)="changeRole($event, sessionId, user.id, role)"
              >
                Change to {{ role | displayUserAccessRole | lowercase }}
              </button>
            </ng-container>

            <button mat-menu-item (click)="deleteUser($event, sessionId, user.id)">Delete</button>
          </mat-menu>
        </div>
      </div>
    </div>

    <!-- Empty screen -->
    <span *ngIf="!users.length" class="tt9-input-text empty-screen">{{ emptyScreenTitle }}</span>
    <!-- Empty screen -->
  </div>
</ng-template>
