import { Injectable } from '@angular/core';
import { Message, User } from '@progress/kendo-angular-conversational-ui';
import { map, Observable } from 'rxjs';

import {
    aiConciergeImageUrl,
    BotChatExchange,
    BotChatExchangeTypes,
    BotContext,
    DBPathHelper,
} from '@accenture/shared/data';
import { FirestoreService } from '@accenture/shared/data-access';
import { sortByDateAsc } from '@accenture/shared/util';

export const ChatBot: User = {
    id: 0,
    avatarUrl: aiConciergeImageUrl,
};

export const ChatUser: User = {
    id: 1,
};

export const BotName = 'AI Concierge';

export const BotGreeting: Message = {
    author: ChatBot,
    text: `Greetings, I am the **${BotName}** for ThinkTank Leader and Teams.`,
    timestamp: null as Date,
};

export const BotThinking: Message = {
    author: ChatBot,
    typing: true,
};

@Injectable({
    providedIn: 'root',
})
export class BotChatService {
    constructor(private firestoreService: FirestoreService) {}

    getConversation(userId: string): Observable<BotChatExchange[]> {
        return this.firestoreService
            .getCollection<BotChatExchange>(DBPathHelper.getBotConversationPath(userId))
            .pipe(map((items: BotChatExchange[]) => items.sort(sortByDateAsc())));
    }

    async sendMessage(userId: string, context: BotContext, message: string): Promise<string> {
        const documentId = await this.firestoreService.addDocument(DBPathHelper.getBotConversationPath(userId), {
            message,
            context,
            type: BotChatExchangeTypes.User,
            created: this.firestoreService.timestamp,
        });

        return documentId;
    }

    async deleteMessage(userId: string, messageId: string): Promise<void> {
        return this.firestoreService.delete(DBPathHelper.getBotConversationPath(userId, messageId));
    }

    async updateMessage(userId: string, messageId: string, message: string): Promise<void> {
        return this.firestoreService.update(DBPathHelper.getBotConversationPath(userId, messageId), { message });
    }
}
