import { Injectable, TemplateRef } from '@angular/core';
import { Store } from '@ngrx/store';
import { WindowCloseResult, WindowRef, WindowService } from '@progress/kendo-angular-dialog';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { distinctUntilChanged, map, startWith, switchMap, tap } from 'rxjs/operators';

import { ActivityService } from '@accenture/activity/shared/domain';
import { BotName, FeatureToggleName, SessionService } from '@accenture/erp-deployment/shared/domain';
import {
    AppState,
    selectActivityId,
    selectAuthenticatedUser,
    selectFeatureToggle,
    selectParentTeamMember,
    selectProjectId,
    selectSessionId,
    selectSessionTeamMember,
} from '@accenture/global-store';
import { BotActions, errorSnackbarText, ParentType } from '@accenture/shared/data';
import { FirestoreService } from '@accenture/shared/data-access';
import { SnackbarService, SnackBarTypes } from '@accenture/shared/ui';

export interface AiMakeNotesButtonModel {
    available: boolean;
    notes: string;
    isLoading: boolean;
}

export const initialState: AiMakeNotesButtonModel = {
    available: false,
    notes: '',
    isLoading: false,
};

@Injectable()
export class AiMakeNotesButtonFacade {
    private isLoading$ = new BehaviorSubject<boolean>(false);
    userId?: string;
    projectId?: string;
    sessionId?: string;
    activityId?: string;
    vm$ = this.buildViewModel();

    windowRef!: WindowRef;

    constructor(
        private store: Store<AppState>,
        private snackbarService: SnackbarService,
        private fireStoreService: FirestoreService,
        private sessionService: SessionService,
        private activityService: ActivityService,
        private windowService: WindowService,
    ) {}

    getType(): string {
        const { sessionId, activityId } = this;
        return activityId ? 'Activity' : sessionId ? 'Session' : 'Project';
    }

    showNotes(titleBarContent: TemplateRef<unknown>, content: TemplateRef<unknown>, notes: string): void {
        if (this.windowRef) {
            this.windowRef.close();
        } else {
            this.windowRef = this.windowService.open({
                titleBarContent,
                content,
                width: 450,
                height: 600,
            });

            this.windowRef.result.subscribe(result => {
                if (result instanceof WindowCloseResult) {
                    this.windowRef = null;
                    console.info('notes closed.');
                }
            });
        }
    }

    async makeNotes(): Promise<void> {
        if (this.windowRef) {
            this.windowRef.close();
        }
        try {
            this.isLoading$.next(true);
            this.snackbarService.showSnackBar(
                BotName,
                `Facilitation notes generation for ${this.getType()} in progress`,
                SnackBarTypes.Info,
                false,
            );
            const { userId, projectId, sessionId, activityId } = this;
            const action = activityId ? BotActions.FacilitateActivity : BotActions.FacilitateSession;

            const result = await this.fireStoreService.cloudFunctionCallable<any>('aiMakeFacilitationNotes', {
                userId,
                projectId,
                sessionId,
                activityId,
                action,
            });

            if (result) {
                const { notification, instructions } = result;

                const data = {
                    facilitationNotes: instructions,
                };
                switch (action) {
                    case BotActions.FacilitateActivity:
                        this.activityService.updateActivity(ParentType.Projects, projectId, activityId, data);
                        break;
                    case BotActions.FacilitateSession:
                        this.sessionService.updateSessionDocument(ParentType.Projects, projectId, sessionId, data);
                }

                this.snackbarService.showSuccessSnackBar(BotName, notification);
            } else {
                this.snackbarService.showErrorSnackBar(BotName, errorSnackbarText);
            }
        } catch (e) {
            console.error(e);
            this.snackbarService.showErrorSnackBar(BotName, errorSnackbarText);
        } finally {
            this.isLoading$.next(false);
        }
    }

    private buildViewModel(): Observable<AiMakeNotesButtonModel> {
        const notes$ = combineLatest([
            this.store.select(selectProjectId),
            this.store.select(selectSessionId),
            this.store.select(selectActivityId),
        ]).pipe(
            tap(([projectId, sessionId, activityId]) => {
                this.projectId = projectId;
                this.sessionId = sessionId;
                this.activityId = activityId;
            }),
            switchMap(() => this.getNotes()),
        );

        return combineLatest([
            this.store.select(selectFeatureToggle(FeatureToggleName.AiMakeNotesAvailable)),
            this.store.select(selectAuthenticatedUser),
            this.store.select(selectSessionTeamMember),
            this.store.select(selectParentTeamMember),
            notes$,
            this.isLoading$.asObservable().pipe(distinctUntilChanged()),
        ]).pipe(
            map(([enabled, user, sessionAccess, projectAccess, notes, isLoading]) => {
                this.userId = user.id;

                const { isProjectAdmin } = projectAccess;
                const { isSessionLeader } = sessionAccess;

                return {
                    available: enabled && (isSessionLeader || isProjectAdmin),
                    notes,
                    isLoading,
                };
            }),
            startWith(initialState),
        );
    }

    private getNotes(): Observable<string> {
        return this.activityId
            ? this.activityService
                  .getActivity(ParentType.Projects, this.projectId, this.activityId)
                  .pipe(map(activity => activity?.facilitationNotes))
            : this.sessionId
            ? this.sessionService
                  .getSession(ParentType.Projects, this.projectId, this.sessionId)
                  .pipe(map(session => session?.facilitationNotes))
            : of('');
    }
}
