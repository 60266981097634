import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

import { Dictionary } from '@accenture/shared/data';
import { LoadedDescription } from '@accenture/shared/ui';

import { AddSessionTt9DialogFacade } from './add-session-tt9-dialog-facade';

@Component({
    selector: 'accenture-add-session-tt9-dialog',
    templateUrl: './add-session-tt9-dialog.component.html',
    styleUrls: ['./add-session-tt9-dialog.component.scss'],
    providers: [AddSessionTt9DialogFacade],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddSessionTt9DialogComponent {
    vm$ = this.facade.vm$;

    loaderText: LoadedDescription;

    constructor(private facade: AddSessionTt9DialogFacade) {}

    updateSearchValue(searchValue: string): void {
        this.facade.updateSearchValue(searchValue);
    }

    updateSelectedTemplates(selectedTemplatesMap: Dictionary<boolean>): void {
        this.facade.updateSelectedTemplates(selectedTemplatesMap);
    }

    closeDialog(): void {
        this.facade.closeDialog();
    }

    addSessions(): void {
        this.loaderText = LoadedDescription.AddingSessionsToProject;
        this.facade.addTemplatesToSession();
    }
}
