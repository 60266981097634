import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { combineLatest, from } from 'rxjs';
import { exhaustMap, filter, map, switchMap, take, takeUntil } from 'rxjs/operators';

import { UserStatus } from '@accenture/shared/data';
import { UserService } from '@accenture/shared/data-access';

import { AppState } from '../app.state';
import * as fromProjectActions from '../project/project.actions';
import * as fromAuthActions from './auth.actions';

@Injectable()
export class AuthEffects {
    retrieveAuthenticatedUser$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromAuthActions.retrieveAuthenticatedUser),
            switchMap(({ firebaseUserId }) =>
                this.angularFireAuth.authState.pipe(
                    filter(firebaseUser => !!firebaseUser),
                    map(firebaseUser => ({ firebaseUser, id: firebaseUserId })),
                    take(1),
                ),
            ),
            switchMap(({ id, firebaseUser }) =>
                combineLatest([
                    from(firebaseUser.getIdTokenResult()),
                    this.userService.getUser(id).pipe(takeUntil(this.actions$.pipe(ofType(fromAuthActions.signOut)))),
                ]).pipe(
                    map(([token, user]) => {
                        if (!user) {
                            return fromAuthActions.retrieveAuthenticatedUserFailure({
                                message: `No user document was found for id: ${id}`,
                            });
                        }
                        if (user.status === UserStatus.Deactivated) {
                            this.angularFireAuth.signOut();
                            return fromAuthActions.retrieveAuthenticatedUserFailure({
                                message: UserStatus.Deactivated,
                            });
                        }

                        user.roles = {
                            ...user.roles,
                            admin: token.claims.admin ?? false,
                            collectionCreator: token.claims.collectionCreator ?? false,
                            templateCreator: token.claims.templateCreator ?? false,

                            //TODO DELETE WHEN COLLECTION FEATURE WILL BE FINISHED
                            projectCreator: token.claims.projectCreator ?? false,

                            sessionCreator: token.claims.sessionCreator ?? false,
                            api: token.claims.api ?? false,
                        };
                        return fromAuthActions.retrieveAuthenticatedUserSuccess({ user });
                    }),
                ),
            ),
        ),
    );

    signOut$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromAuthActions.signOut),
            exhaustMap(() => {
                this.store.dispatch(fromProjectActions.clearProjectStore());
                return from(this.userService.logout());
            }),
            exhaustMap(() => from(this.router.navigate(['authentication']))),
            map(() => fromAuthActions.signOutSuccess()),
        ),
    );

    constructor(
        private actions$: Actions,
        private userService: UserService,
        private angularFireAuth: AngularFireAuth,
        private router: Router,
        private store: Store<AppState>,
    ) {}
}
