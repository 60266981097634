import { LexoRank } from 'lexorank';

export class SequenceGeneration {
    static between(previousSequence: string, nextSequence: string): string {
        if (!previousSequence || !nextSequence) {
            const errorMessage = `${
                previousSequence
                    ? 'nextSequence is falsy'
                    : nextSequence
                    ? 'previousSequence and nextSequence are falsy'
                    : 'previousSequence is falsy'
            }`;
            throw new TypeError(errorMessage);
        }

        const previousRank = LexoRank.parse(previousSequence);
        const nextRank = LexoRank.parse(nextSequence);
        return previousRank.between(nextRank).toString();
    }

    static beforeFirst(firstSequence: string): string {
        if (!firstSequence) {
            throw new TypeError('firstSequence is falsy');
        }
        return LexoRank.parse(firstSequence).genPrev().toString();
    }

    static afterLast(lastSequence: string): string {
        if (!lastSequence) {
            throw new TypeError('lastSequence is falsy');
        }
        return LexoRank.parse(lastSequence).genNext().toString();
    }

    static initial(): string {
        return LexoRank.middle().toString();
    }
}

// return '-1' if firstSequence before secondSequence, and '1' if vice versa
export const compareSequences = (firstSequence: string, secondSequence: string): number => {
    return LexoRank.parse(firstSequence).compareTo(LexoRank.parse(secondSequence));
};

export const getNewSequenceAfterMoved = <T extends { sequence: string }>(
    previousIndex: number,
    currentIndex: number,
    items: T[],
    item: T,
): string => {
    switch (currentIndex) {
        case previousIndex:
            return item.sequence;
        case 0:
            return SequenceGeneration.beforeFirst(items[currentIndex].sequence as string);
        case items.length - 1:
            return SequenceGeneration.afterLast(items[currentIndex].sequence as string);
        default: {
            const nextIndex = previousIndex > currentIndex ? currentIndex - 1 : currentIndex + 1;
            const prev = items[currentIndex].sequence as string;
            const next = items[nextIndex].sequence as string;
            return SequenceGeneration.between(prev, next);
        }
    }
};
