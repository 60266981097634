<div class="confirmation-dialog p-16">
  <div class="mr-16" style="position: relative">
    <p class="tt9-body-medium-1 text-overflow-ellipsis-1 mb-8">
      {{ data["title"] }}
    </p>

    <div *ngIf="!data['isDisplayLikeHtml']" class="tt9-body-2 pr-16">
      {{ data["text"] }}
    </div>

    <div *ngIf="data['isDisplayLikeHtml']" class="tt9-body-2" [innerHtml]="textAsHtml"></div>

    <ng-content></ng-content>
  </div>

  <button class="tt9-btn-icon confirmation-dialog-close" (click)="closeDialog()">
    <accenture-icon>icon-close</accenture-icon>
  </button>
  <div class="d-flex-end mt-32" [ngClass]="{ 'd-flex-between': data['isToggleVisible'] }">
    <div *ngIf="data['isToggleVisible']">
      <mat-slide-toggle class="tt9-toggle" color="primary" [checked]="true" (change)="changeToggle($event.checked)">
        Include table responses
      </mat-slide-toggle>
    </div>
    <div class="d-flex">
      <button *ngIf="data['closeButton']" class="tt9-btn-default" (click)="cancel()">
        {{ data["closeButtonTxt"] || "Cancel" }}
      </button>

      <button
        *ngIf="data['confirmBtn']"
        class="tt9-btn ml-8 {{ data['confirmButtonClass'] }}"
        [ngClass]="{ 'btn-error': data['isWarning'] }"
        (click)="confirmDialog()"
      >
        {{ data["confirmBtnText"] || "Confirm" }}
      </button>
    </div>
  </div>
</div>
