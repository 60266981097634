import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Timestamp } from 'firebase/firestore';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import {
    Activity,
    ActivityFactory,
    ActivityType,
    CollectionsSortObject,
    DBPathHelper,
    DuplicateType,
    ParentType,
    VoteSummaryData,
} from '@accenture/shared/data';
import { FirestoreService } from '@accenture/shared/data-access';
import { removeNoValuesKeys, sortBySequenceAsc } from '@accenture/shared/util';

@Injectable({
    providedIn: 'root',
})
export class ActivityService {
    private deletingActivityIds = new BehaviorSubject<string[]>([]);
    readonly deletingActivityIds$ = this.deletingActivityIds.asObservable();

    constructor(private firestoreService: FirestoreService, private router: Router) {}

    updateDeletingActivityIds(activityId: string): void {
        this.deletingActivityIds.next([...this.deletingActivityIds.getValue(), activityId]);
    }

    removeActivityIdFromDeletingActivityIds(removedActivityId: string): void {
        this.deletingActivityIds.next(
            this.deletingActivityIds.getValue().filter((activityId) => activityId !== removedActivityId),
        );
    }

    async createActivity(
        parentType: ParentType,
        parentId: string,
        activities: Activity[],
        activityData: Activity,
    ): Promise<string> {
        const { id, ...data } = activityData;

        const newActivity = removeNoValuesKeys({
            ...data,
            created: Timestamp.now(),
            updated: Timestamp.now(),
        }) as Activity;

        if (id) {
            await this.firestoreService.addDocumentWithKey(
                DBPathHelper.getActivityPath(parentType, parentId),
                id,
                newActivity,
            );
            return id;
        }
        const activityId = await this.firestoreService.addDocument(
            DBPathHelper.getActivityPath(parentType, parentId),
            newActivity,
        );

        return activityId;
    }

    async updateActivity(
        parentType: ParentType,
        parentId: string,
        activityId: string,
        activity: Partial<Activity>,
    ): Promise<void> {
        await this.firestoreService.update(
            DBPathHelper.getActivityPath(parentType, parentId, activityId),
            this.firestoreService.replaceEmptyFields({
                ...activity,
                updated: this.firestoreService.timestamp,
            }),
        );
    }

    async deleteActivityField(
        parentType: ParentType,
        parentId: string,
        activityId: string,
        optionId: string,
    ): Promise<void> {
        await this.firestoreService.upsert(DBPathHelper.getActivityPath(parentType, parentId, activityId), {
            tags: { [optionId]: this.firestoreService.deleteField },
        });
    }

    // TODO: remove when projects is no longer used
    async addActivityToSession(
        templateId: string,
        projectId: string,
        sessionId: string,
        userId: string,
        toParentType: ParentType,
        fromParentType: ParentType,
        publicAccessId?: string,
        template?: Activity,
        saveResponses = false,
    ): Promise<void> {
        return this.firestoreService.cloudFunctionCallable('duplicateData', {
            userId,
            saveResponses,
            from: {
                publicAccessId,
                activityId: templateId || template.id,
                parentId: templateId || template.id,
                parentType: fromParentType,
            },
            to: {
                sessionId,
                parentId: projectId,
                parentType: toParentType,
            },
            activity: template,
            type: DuplicateType.Activity,
        });
    }

    async addActivityToSessionNew(
        templateId: string,
        sessionId: string,
        userId: string,
        toParentType: ParentType,
        fromParentType: ParentType,
        publicAccessId?: string,
        template?: Activity,
        saveResponses = false,
    ): Promise<void> {
        return this.firestoreService.cloudFunctionCallable('duplicateDataTt9', {
            userId,
            saveResponses,
            from: {
                publicAccessId,
                activityId: templateId || template.id,
                parentId: templateId || template.id,
                parentType: fromParentType,
            },
            to: {
                sessionId,
                parentId: sessionId,
                parentType: toParentType,
            },
            activity: template,
            type: DuplicateType.Activity,
        });
    }

    async deleteActivity(parentType: ParentType, parentId: string, activityId: string): Promise<void> {
        await this.firestoreService.cloudFunctionCallable('deleteActivity', {
            activityId,
            parentType,
            parentId,
        });
    }

    getActivity<T extends Activity>(parentType: ParentType, parentId: string, activityId: string): Observable<T> {
        return this.firestoreService.getDocument<T>(DBPathHelper.getActivityPath(parentType, parentId, activityId));
    }

    navigateToEditActivity(projectId: string, sessionId: string, activityId: string, activityType: ActivityType): void {
        this.router.navigate([
            'project',
            projectId,
            'session',
            sessionId,
            `${activityType.toLowerCase()}-edit`,
            activityId,
        ]);
    }

    navigateToEditActivityNew(sessionId: string, activityId: string, activityType: ActivityType): void {
        this.router.navigate(['session', sessionId, `${activityType.toLowerCase()}-edit`, activityId]);
    }

    getActivities(parentType: ParentType, parentId: string, type?: ActivityType): Observable<Activity[]> {
        const fields = new Map();
        if (type) {
            fields.set('type', type);
        }

        return this.firestoreService
            .getDocumentsByMultiplePropertiesWithoutCaching<Activity>(
                DBPathHelper.getActivityPath(parentType, parentId),
                fields,
                'sequence',
            )
            .pipe(
                map((activities: Activity[]) =>
                    sortBySequenceAsc(
                        activities
                            .map(({ id, ...activity }) => ActivityFactory.create(id as string, activity as Activity))
                            .filter((activity) => !!activity),
                    ),
                ),
            );
    }

    getActivityTemplatesByIds(selectedTemplatesIds: string[]): Observable<Activity[]> {
        return this.firestoreService.getDocumentsByIds<Activity>('activityTemplates', selectedTemplatesIds);
    }

    getTemplateActivities(templateId: string, type?: ActivityType): Observable<Activity[]> {
        const fields = new Map();
        if (type) {
            fields.set('type', type);
        }

        return this.firestoreService
            .getDocumentsByMultipleProperties<Activity>(`templates/${templateId}/activities`, fields, 'sequence')
            .pipe(
                map((activities: Activity[]) =>
                    sortBySequenceAsc(
                        activities
                            .map(({ id, ...activity }) => ActivityFactory.create(id as string, activity as Activity))
                            .filter((activity) => !!activity),
                    ),
                ),
            );
    }

    getProjectActivities(projectId: string, sessionId: string): Observable<Activity[]> {
        return this.firestoreService
            .getDocumentsByMultipleProperties<Activity>(
                `/projects/${projectId}/activities`,
                new Map([['sessionId', sessionId]]),
                'sequence',
            )
            .pipe(
                map((activities: Activity[]) =>
                    activities
                        .map(({ id, ...activity }) => ActivityFactory.create(id as string, activity as Activity))
                        .filter((activity) => !!activity),
                ),
            );
    }

    getAllParentActivities(
        parentType: ParentType,
        parentId: string,
        activityType?: ActivityType,
    ): Observable<Activity[]> {
        return this.firestoreService.getCollection<Activity>(`/${parentType}/${parentId}/activities`, 'sequence').pipe(
            map((activities: Activity[]) =>
                activities.reduce((acc, { id, ...activity }) => {
                    if (activityType && activity && activity.type !== activityType) {
                        return acc;
                    }
                    const activityInstance = ActivityFactory.create(id as string, activity as Activity);
                    if (activityInstance) {
                        acc.push(activityInstance);
                    }
                    return acc;
                }, []),
            ),
        );
    }
    // TODO: Delete after project  deprecation
    getProjectActivity<T extends Activity>(projectId: string, activityId: string): Observable<T> {
        return this.firestoreService.getDocument(`projects/${projectId}/activities/${activityId}`);
    }

    getSessionActivity<T extends Activity>(sessionId: string, activityId: string): Observable<T> {
        return this.firestoreService.getDocument(DBPathHelper.getSessionActivityPath(sessionId, activityId));
    }

    getParentActivity<T extends Activity>(parentType: ParentType, parentId: string, activityId: string): Observable<T> {
        return this.firestoreService.getDocument(DBPathHelper.getActivityPath(parentType, parentId, activityId));
    }

    getProjectActivitySummaryFilters(projectId: string, activityId: string): Observable<VoteSummaryData> {
        return this.firestoreService.getDocument<VoteSummaryData>(
            `projects/${projectId}/voteSummaryFilters/${activityId}`,
        );
    }

    getSessionActivitySummaryFilters(sessionId: string, activityId: string): Observable<VoteSummaryData> {
        return this.firestoreService.getDocument<VoteSummaryData>(
            `sessions/${sessionId}/voteSummaryFilters/${activityId}`,
        );
    }

    updateProjectActivitySummaryFilters(
        projectId: string,
        activityId: string,
        data: Partial<VoteSummaryData>,
    ): Promise<void> {
        return this.firestoreService.update<VoteSummaryData>(
            `projects/${projectId}/voteSummaryFilters/${activityId}`,
            data as VoteSummaryData,
        );
    }

    updateSessionActivitySummaryFilters(
        sessionId: string,
        activityId: string,
        data: Partial<VoteSummaryData>,
    ): Promise<void> {
        return this.firestoreService.update<VoteSummaryData>(
            `sessions/${sessionId}/voteSummaryFilters/${activityId}`,
            data as VoteSummaryData,
        );
    }

    getProjectActivityNotes<T>(projectId, activityId): Observable<T[]> {
        return this.firestoreService.getDocumentsByProperty<T>(
            `projects/${projectId}/notes`,
            'activityId',
            activityId,
            'updated',
        );
    }

    getSessionActivityNotes<T>(parentType: ParentType, sessionId: string, activityId: string): Observable<T[]> {
        return this.firestoreService.getDocumentsByProperty<T>(
            DBPathHelper.getNotePath(parentType, sessionId),
            'activityId',
            activityId,
            'updated',
        );
    }

    // TODO: Delete after project  deprecation
    async saveAsTemplate(
        parentId: string,
        activityId: string | undefined,
        parentType = ParentType.Projects,
        saveResponses = false,
    ): Promise<string> {
        const dictionary = await this.firestoreService.cloudFunctionCallable('duplicateData', {
            saveResponses,
            from: {
                parentType,
                parentId,
                activityId,
            },
            to: {
                parentType: ParentType.ActivityTemplates,
            },
            type: DuplicateType.Activity,
        });
        return dictionary[parentId];
    }

    async saveAsTemplateNew(
        sessionId: string,
        activityId: string | undefined,
        parentType = ParentType.Sessions,
        saveResponses = false,
    ): Promise<string> {
        const dictionary = await this.firestoreService.cloudFunctionCallable('duplicateDataTt9', {
            saveResponses,
            from: {
                parentId: sessionId,
                parentType,
                activityId,
            },
            to: {
                parentType: ParentType.ActivityTemplates,
            },
            type: DuplicateType.Activity,
        });
        return dictionary[sessionId];
    }

    // TODO: Delete after project  deprecation
    duplicate(
        parentType: ParentType,
        parentId: string,
        activityId: string,
        sessionId?: string,
        sequence?: string,
        saveResponses = false,
    ): Promise<void> {
        return this.firestoreService.cloudFunctionCallable('duplicateData', {
            saveResponses,
            from: {
                parentType,
                parentId,
                activityId,
            },
            to: {
                parentType,
                parentId,
                sessionId,
            },
            activity: {
                sequence,
            },
            type: DuplicateType.Activity,
        });
    }

    duplicateNew(
        parentType: ParentType,
        parentId: string,
        activityId: string,
        sessionId?: string,
        sequence?: string,
        saveResponses = false,
    ): Promise<void> {
        return this.firestoreService.cloudFunctionCallable('duplicateDataTt9', {
            saveResponses,
            from: {
                parentType,
                parentId,
                activityId,
            },
            to: {
                parentType,
                parentId,
                sessionId,
            },
            activity: {
                sequence,
            },
            type: DuplicateType.Activity,
        });
    }

    createActivityNameWithCounter(activityName: string, activities: Activity[]): string {
        const filteredActivitiesNumbers: number[] = activities
            .filter((activity) => activity.name.includes(activityName))
            .map((activity) => +activity.name.replace(activityName, ''))
            .sort();
        return `${activityName} ${this.findSmallestMissing(filteredActivitiesNumbers)}`;
    }

    async updateCollectionsSorting(userId: string | undefined, data: CollectionsSortObject): Promise<void> {
        await this.firestoreService.upsert(`users/${userId}/filters/collectionsFilters`, data);
    }

    // TODO: Delete after project deprecation
    async resetSessionVotedActivityCount(
        parentType: ParentType,
        parentId: string,
        sessionId: string,
        activityOrActivityItemId: string,
    ): Promise<void> {
        await this.firestoreService.update(DBPathHelper.getSessionPath(parentType, parentId, sessionId), {
            votedActivityCount: {
                [activityOrActivityItemId]: 0,
            },
        });
    }

    async resetSessionVotedActivityCountNew(
        parentType: ParentType,
        parentId: string,
        activityOrActivityItemId: string,
    ): Promise<void> {
        await this.firestoreService.update(DBPathHelper.getSessionPathNew(parentType, parentId), {
            votedActivityCount: {
                [activityOrActivityItemId]: 0,
            },
        });
    }

    private findSmallestMissing(arr: number[]): number {
        let count = 1;
        if (!arr?.length) {
            return count;
        }
        while (arr.indexOf(count) !== -1) {
            count++;
        }
        return count;
    }
}
