import { InAppActivityItemStatusChangeNotification } from './in-app-activity-status-change';
import { InAppProjectInviteNotification } from './in-app-project-invite-notification';
import { InAppProjectRoleChangeNotification } from './in-app-project-role-change-notification';
import { InAppSessionBulkInviteNotification } from './in-app-session-bulk-invite-notification';
import { InAppSessionInviteNotification } from './in-app-session-invite-notification';
import { InAppSessionInviteTT9Notification } from './in-app-session-invite-tt9-notification';
import { InAppSessionRecordingNotification } from './in-app-session-recording-notification';
import { InAppSessionRoleChangeNotification } from './in-app-session-role-change-notification';
import { InAppTemplateInviteNotification } from './in-app-template-invite-notification';
import { InAppTemplatePublishNotification } from './in-app-template-publish-notification';
import { InAppVirusDetectedInUploadNotification } from './in-app-virus-detected-in-upload-notification';

export enum InAppNotificationType {
    ProjectInvite = 'PROJECT_INVITE_NOTIFICATION',
    ProjectRoleChange = 'PROJECT_ROLE_CHANGE_NOTIFICATION',
    SessionInvite = 'SESSION_INVITE_NOTIFICATION',
    SessionBulkInvite = 'SESSION_BULK_INVITE_NOTIFICATION',
    SessionRoleChange = 'SESSION_ROLE_CHANGE_NOTIFICATION',
    ActivityItemStepChange = 'ACTIVITY_ITEM_STEP_CHANGE_NOTIFICATION',
    TemplateInvite = 'TEMPLATE_INVITE_NOTIFICATION',
    TemplatePublish = 'TEMPLATE_PUBLISH_NOTIFICATION',
    UpdatePublishedTemplate = 'UPDATE_PUBLISHED_TEMPLATE',
    VirusDetectedInUpload = 'VIRUS_DETECTED_IN_UPLOAD',
    SessionRecording = 'SESSION_RECORDING',
}

export type InAppNotification =
    | InAppProjectInviteNotification
    | InAppProjectRoleChangeNotification
    | InAppSessionInviteNotification
    | InAppSessionInviteTT9Notification
    | InAppSessionBulkInviteNotification
    | InAppSessionRecordingNotification
    | InAppSessionRoleChangeNotification
    | InAppActivityItemStatusChangeNotification
    | InAppTemplateInviteNotification
    | InAppTemplatePublishNotification
    | InAppVirusDetectedInUploadNotification;
