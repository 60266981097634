import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Timestamp } from 'firebase/firestore';
import { Observable, tap } from 'rxjs';

import {
    AccessControlRole,
    emptyScreenDescriptions,
    emptyScreenImageUrlSessions,
    emptyScreenTitles,
    inputPlaceholders,
    scssClassSessionStatus,
    Session,
    SessionRole,
    tooltipTexts,
    User,
    UserAccess,
} from '@accenture/shared/data';
import { ImageInputPlaceholdersTypes, imageInputPlaceholdersUrl, LoadedDescription } from '@accenture/shared/ui';
import { isDisabledTooltip, trackById } from '@accenture/shared/util';

import { SessionsListFacade, SessionsListViewModel, SessionsViewTab } from './sessions-list-facade';

@Component({
    selector: 'accenture-sessions-list',
    templateUrl: './sessions-list.component.html',
    styleUrls: ['./sessions-list.component.scss'],
    providers: [SessionsListFacade],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SessionsListComponent {
    vm$: Observable<SessionsListViewModel> = this.facade.vm$.pipe(
        tap(({ searchValue }) => this.searchControl.patchValue(searchValue)),
    );

    protected readonly isDisabledTooltip = isDisabledTooltip;

    loaderText: LoadedDescription;
    trackById = trackById;
    emptyScreenTitles = emptyScreenTitles;
    emptyScreenDescriptions = emptyScreenDescriptions;
    emptyScreenImageUrlSessions = emptyScreenImageUrlSessions;
    imageInputPlaceholdersUrl = imageInputPlaceholdersUrl[ImageInputPlaceholdersTypes.Session];
    tooltipTexts = tooltipTexts;
    inputPlaceholders = inputPlaceholders;
    sessionsViewTab = SessionsViewTab;
    scssClassSessionStatus = scssClassSessionStatus;

    searchControl = new FormControl<string>('', { nonNullable: true });

    constructor(private facade: SessionsListFacade) {}

    isSessionsNotCreated(sessionsLength: number, isSearchShown: boolean, isFiltersApplied: boolean): boolean {
        return !sessionsLength && !isSearchShown && !isFiltersApplied;
    }

    isSessionsNotFound(sessionsLength: number, isSearchShown: boolean, isFiltersApplied: boolean): boolean {
        return !sessionsLength && (isSearchShown || isFiltersApplied);
    }

    getFooterDivider(phaseLength: number, subPhaseLength: number, viewType: SessionsViewTab): string {
        if (!!(phaseLength && subPhaseLength)) {
            return '|';
        }

        if (!phaseLength && !subPhaseLength) {
            return viewType === SessionsViewTab.ListView ? '&#8212;' : '';
        }
    }

    canCreateSession(user: User): boolean {
        return user.roles?.sessionCreator || user.roles?.admin;
    }

    setActiveTab(tab: SessionsViewTab): void {
        this.facade.setActiveTab(tab);
    }

    toggleShowSearch(): void {
        this.facade.toggleShowSearch();
    }

    toggleFiltersAndSortPanel(opened: boolean, event?: Event): void {
        this.facade.toggleFiltersAndSortPanel(opened);

        if (event) {
            event.stopPropagation();
        }
    }

    getImageUrl(imageUrl?: string): string {
        return imageUrl ? `url(${imageUrl})` : '';
    }

    getSessionDate(session: Session, field: string): Date | string {
        const updated = session[field] as Timestamp;
        return updated?.toDate ? updated?.toDate() : new Date();
    }

    openConfirmationDialog(event: Event, sessionId: string, sessionName: string): void {
        event.stopPropagation();
        this.loaderText = LoadedDescription.SessionDeleting;
        this.facade.openDeleteConfirmationDialog(sessionId, sessionName);
    }

    isOptionsVisible(role: SessionRole): boolean {
        return [SessionRole.Leader].includes(role);
    }

    filterSessions(searchValue: string): void {
        this.facade.filterSessions(searchValue);
    }

    clearFilter(event: Event): void {
        event.stopPropagation();
        this.filterSessions('');
    }

    openCreateSessionDialog(): void {
        this.facade.openCreateSessionDialog();
    }

    redirectToSession(sessionId: string): void {
        this.facade.redirectToSession(sessionId);
    }

    saveAsTemplate(event: Event, sessionId: string): void {
        event.stopPropagation();
        this.facade.openSaveAsTemplateDialog(sessionId);
    }

    downloadAnExportReport(event: Event, sessionId: string): void {
        event.stopPropagation();
        this.facade.downloadAnExportReport(sessionId);
    }

    updateSelfNavigate(selfNavigate: boolean, sessionId: string): void {
        this.facade.updateSessionOptions(sessionId, { selfNavigate } as Partial<Session>);
    }

    updateSessionThreads(checked: boolean, sessionId: string): Promise<void> {
        return this.facade.updateSessionOptions(sessionId, {
            sessionThreadDisabled: checked,
            ...(checked ? { sessionThreadIsAnonymous: false } : {}),
        });
    }

    async updateSessionThreadIsAnonymous(checked: boolean, sessionId: string): Promise<void> {
        await this.facade.updateSessionOptions(sessionId, {
            sessionThreadIsAnonymous: checked,
        });
        await this.facade.writeSessionThreadNotification(sessionId, checked);
    }

    updateDisplayAgenda(displayAgenda: boolean, sessionId: string): Promise<void> {
        if (!displayAgenda) {
            return this.facade.updateSessionOptions(sessionId, {
                displayAgenda,
                selfNavigate: false,
            } as Partial<Session>);
        }

        return this.facade.updateSessionOptions(sessionId, { displayAgenda } as Partial<Session>);
    }

    isSessionEditor(role: AccessControlRole): boolean {
        return [SessionRole.Leader].includes(role as SessionRole);
    }

    // ToDo: update type after updates to UserSession
    moveToCollection(session: UserAccess): void {
        this.facade.moveToCollectionDialogOpen(session);
    }
}
