import { Dictionary } from '../dictionary';
import { Question } from '../question';
import { ActivityItemType } from './activity-item-type';
import { OptionsListItem } from './options-list-item';

export class PollQuestion extends Question {
    type = ActivityItemType.PollQuestion;

    choices: Dictionary<OptionsListItem>;

    constructor(data: Partial<PollQuestion>) {
        super(data);

        this.choices = data.choices ?? {};
    }

    createSerializableObject(): Record<string, unknown> {
        return {
            ...super.createSerializableObject(),
            choices: this.choices,
        };
    }
}
