<ng-container *ngIf="vm$ | async as vm">
  <ng-container *accentureLoaded="!vm.isLoading">
    <div class="templates-list">
      <div *ngIf="showActions(vm.areThereAnyFavoriteTemplates, !!vm.searchValue)" class="content-actions">
        <div
          *ngFor="let tab of favoritesTabs; trackBy: trackByValue"
          class="tt9-navigation-tab"
          [ngClass]="{ active: tab === vm.activeFavoriteTab }"
          (click)="setFavoriteTab(tab)"
        >
          <p class="tt9-input-text-small">{{ tab }}</p>
        </div>

        <ng-container *ngIf="vm.areThereAnyFavoriteTemplates">
          <div class="content-actions-search ml-40">
            <mat-form-field
              appearance="outline"
              class="tt9-form-field no-label no-margin width-100"
              floatLabel="always"
            >
              <input
                #search
                matInput
                [formControl]="searchControl"
                [placeholder]="inputPlaceholders.search"
                (input)="setSearchValue(search.value)"
              />
              <accenture-icon matPrefix>icon-search</accenture-icon>
              <accenture-icon *ngIf="vm.searchValue && !search.disabled" matSuffix (click)="clearSearchValue($event)"
                >icon-close</accenture-icon
              >
            </mat-form-field>
          </div>

          <button
            class="tt9-btn-icon-filled tt9-btn-applied"
            matTooltipPosition="above"
            matTooltipClass="tt9-arrow-tooltip-below"
            [matTooltip]="tooltipTexts.filter"
            [class.active]="vm.isFilterAndSortPanelOpened"
            [disabled]="vm.isFilterAndSortPanelOpened || !vm.favoriteTemplatesOfCurrentTab?.length"
            (click)="toggleFilterAndSortPanel(true, $event)"
          >
            <accenture-icon>icon-filter</accenture-icon>

            <div *ngIf="vm.isFiltersApplied && vm.favoriteTemplatesOfCurrentTab?.length" class="applied"></div>
          </button>
        </ng-container>
      </div>

      <ng-container *ngIf="vm.filteredFavoriteTemplates?.length; else emptyTemplates">
        <div class="main-data">
          <div
            *ngFor="let template of vm.filteredFavoriteTemplates; trackBy: trackById; let i = index"
            class="card-container"
            [class.hover]="hoveredCardIndex === i"
            (mouseenter)="hoverTemplateCard(i)"
            (mouseleave)="unhoverTemplateCard(i)"
          >
            <accenture-template-card
              [template]="template"
              [hoveredCardIndex]="hoveredCardIndex"
              [isAllTemplateTab]="vm.activeFavoriteTab === templateTab.All"
              [isFavorite]="true"
              [templateIndex]="i"
              [isTemplatesPage]="true"
              [showDeepLinks]="vm.showDeepLinks"
              [userId]="vm.userId"
              (openPublishDialog)="openPublishDialog($event)"
              (deleteTemplate)="deleteTemplate($event)"
              (useTemplate)="useTemplate($event)"
              (updateFavorite)="removeFromFavorite($event)"
              (saveAsTemplate)="saveAsTemplate($event)"
              (editTemplate)="editTemplate($event, vm.isEditPending)"
              (openForPreview)="openForPreview($event)"
              (copyLinkAccessToClipboard)="copyLinkAccessToClipboard($event)"
              (openMenu)="menuOpened = true"
              (closeMenu)="menuOpened = false"
            ></accenture-template-card>
          </div>
        </div>
      </ng-container>

      <ng-template #emptyTemplates>
        <div class="empty-screen">
          <accenture-empty-screen
            [title]="getEmptyScreenTitle(vm)"
            [imageUrl]="emptyScreenImageUrlFavorite"
          ></accenture-empty-screen>
        </div>
      </ng-template>
    </div>

    <!-- Filter and Sort side panel -->
    <accenture-side-panel
      *ngIf="vm.isFilterAndSortPanelOpened"
      cdkOverlayOrigin
      #origin="cdkOverlayOrigin"
      [panelTrigger]="origin"
      [panelTitle]="'Filter & Sort'"
      [panelOpened]="vm.isFilterAndSortPanelOpened"
      (closePanel)="toggleFilterAndSortPanel(false)"
    >
      <accenture-favorite-templates-filters-and-sort></accenture-favorite-templates-filters-and-sort>
    </accenture-side-panel>
    <!-- / Filter and Sort side panel -->

    <!-- Template preview side panel -->
    <accenture-side-panel
      *ngIf="vm.previewedTemplate"
      cdkOverlayOrigin
      #origin="cdkOverlayOrigin"
      [useDefaultTemplate]="false"
      [panelTrigger]="origin"
      [panelOpened]="!!vm.previewedTemplate"
      (closePanel)="closePreviewPanel()"
    >
      <accenture-template-preview
        [isTemplateOwner]="vm.previewedTemplate.ownerId === vm.userId"
        [isEditPending]="vm.isEditPending"
        [isFavoriteTemplate]="true"
        [hasUnsavedChanges]="!!vm.previewedTemplate.draftTemplateId"
        [canEditTemplate]="isTemplateOwnerOrCollaborator(vm.previewedTemplate, vm.userId)"
        (useTemplateAction)="useTemplate(vm.previewedTemplate)"
        (saveToMyTemplatesAction)="saveAsTemplate(vm.previewedTemplate)"
        (addToFavoritesAction)="removeFromFavorite(vm.previewedTemplate)"
        (editTemplateAction)="editTemplate(vm.previewedTemplate)"
        (toggleTeamMembersPanel)="openTeamMembersPanel($event)"
      ></accenture-template-preview>
    </accenture-side-panel>
    <!-- /Template preview side panel -->

    <!--Project template team members side panel -->
    <accenture-side-panel
      *ngIf="vm.isTemplateTeamPanelOpened"
      #origin="cdkOverlayOrigin"
      cdkOverlayOrigin
      [panelTrigger]="origin"
      [panelOpened]="vm.isTemplateTeamPanelOpened"
      [useDefaultTemplate]="false"
      (closePanel)="closeTeamMembersPanel($event)"
    >
      <accenture-template-team-members-panel
        [showBackArrow]="true"
        [publicAccess]="vm.previewedTemplate"
        (navigateBack)="closeTeamMembersPanel($event)"
        (closePanel)="closeTeamMembersPanel($event)"
      />
    </accenture-side-panel>
    <!-- /Project template team members side panel -->
  </ng-container>
</ng-container>
