import { Directive, ElementRef, Input, OnChanges } from '@angular/core';

const ignoreClassList = [
    'text-overflow-ellipsis',
    'text-overflow-ellipsis-1',
    'text-overflow-ellipsis-2',
    'text-overflow-ellipsis-3',
];

@Directive({
    selector: '[accentureMaxTextWidth]',
})
export class MaxTextWidthDirective implements OnChanges {
    @Input('accentureMinTextWidth') min?: number;
    @Input('accentureMaxTextWidth') max?: number | string;

    private readonly defaultMin = 0;
    private readonly defaultMax = 720;

    constructor(private element: ElementRef<HTMLElement>) {}

    get minWidth(): number {
        return +(this.min || 0) || this.defaultMin;
    }

    get maxWidth(): number {
        return +(this.max || 0) || this.defaultMax;
    }

    ngOnChanges() {
        this.element.nativeElement.setAttribute('style', 'white-space:nowrap');
        const className = this.element.nativeElement.className;
        this.element.nativeElement.className = ignoreClassList.reduce(
            (acc, classToIgnore) => acc.replace(classToIgnore, ''),
            className,
        );
        setTimeout(() => {
            const clientWidth = this.element.nativeElement.scrollWidth + 10;
            const width
                = clientWidth > this.maxWidth ? this.maxWidth : clientWidth < this.minWidth ? this.minWidth : clientWidth;

            this.element.nativeElement.setAttribute('style', `width:${width}px`);
            this.element.nativeElement.className = className;
        });
    }
}
