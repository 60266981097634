import { Injectable } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest, firstValueFrom, Observable } from 'rxjs';
import { map, startWith, switchMap, take } from 'rxjs/operators';

import { ActivityService } from '@accenture/activity/shared/domain';
import {
    OptionsStore,
    saveActivityToSessionInfoSnackBarNew,
    saveActivityToSessionSuccessSnackBarNew,
    SessionOptionsService,
    SessionService,
    TeamMemberService,
} from '@accenture/erp-deployment/shared/domain';
import {
    AppState,
    getParentTeamMemberData,
    getSessionActivitiesData,
    getSessionData,
    selectAuthenticatedUser,
} from '@accenture/global-store';
import {
    addingTemplateErrorSnackbarTitle,
    addingTemplateInProgressSnackbarTitle,
    CollectionOptions,
    errorMessageSnackbarText,
    FileType,
    ParentType,
    ProjectOptions,
    SelectedSessionOptions,
    Session,
    SessionOptions,
    SessionRole,
    SessionStatus,
    templateAddedSnackbarTitle,
    User,
} from '@accenture/shared/data';
import { DialogService, SnackbarService, SnackBarTypes } from '@accenture/shared/ui';

import { UseTemplateStore } from '../component-stores/use-template.store';
import { UseTemplateDialogTT9Component } from '../use-template-dialog-tt9/use-template-dialog-tt9.component';
import { CreateSessionDialogTT9Component } from './create-session-dialog-tt9.component';

export interface CreateSessionDialogViewModel {
    sessionImage: FileType;
    currentOptionToDisplay: SessionOptions | ProjectOptions | CollectionOptions | null;
    isLoading: boolean;
}

const defaultViewModel: CreateSessionDialogViewModel = {
    sessionImage: {} as FileType,
    currentOptionToDisplay: null,
    isLoading: false,
};

@Injectable()
export class CreateSessionDialogTT9Facade {
    private isLoading$ = new BehaviorSubject<boolean>(false);
    private sessionImage$ = new BehaviorSubject<FileType>({} as FileType);
    private useTemplateId!: string;
    private usePublicAccessId!: string;
    private useTemplateType!: ParentType;
    private user!: User;

    vm$ = this.buildViewModel();

    constructor(
        private store: Store<AppState>,
        private sessionService: SessionService,
        private teamMemberService: TeamMemberService,
        private useTemplateStore: UseTemplateStore,
        private dialogRef: MatDialogRef<CreateSessionDialogTT9Component>,
        private dialogService: DialogService,
        private router: Router,
        private snackbarService: SnackbarService,
        private activityService: ActivityService,
        private optionsStore: OptionsStore,
        private sessionOptionsService: SessionOptionsService,
    ) {}

    closeDialog(backToSelectSessionTypeDialog?: boolean): void {
        this.dialogRef.close();

        if (backToSelectSessionTypeDialog) {
            this.dialogService.open(UseTemplateDialogTT9Component, {
                width: 'auto',
                panelClass: 'tt9-modal',
            });
        }
    }

    setCurrentOptionToDisplay(currentOptionToDisplay: SessionOptions | null): void {
        this.optionsStore.setCurrentOptionToDisplay(currentOptionToDisplay);
    }

    setSessionImage(sessionImage: FileType): void {
        this.sessionImage$.next(sessionImage);
    }

    resetSessionOptions(): void {
        this.optionsStore.resetSessionOptions();
        this.optionsStore.resetSessionOptionsToCreate();
        this.optionsStore.setCurrentOptionToDisplay(null);
    }

    async createSession(formValue: {
        name: string;
        description?: string;
        startDate?: Date;
        endDate?: Date;
        imageUrl?: string;
        imageId?: string;
    }): Promise<void> {
        this.isLoading$.next(true);

        if (this.useTemplateId) {
            this.snackbarService.showSnackBar(
                addingTemplateInProgressSnackbarTitle,
                saveActivityToSessionInfoSnackBarNew,
                SnackBarTypes.Info,
            );
        }

        try {
            const sessionImage = this.sessionImage$.value;
            const sessionOptions = await firstValueFrom(this.optionsStore.selectedSessionOptions$.pipe(take(1)));

            const role = SessionRole.Leader;
            const session = <Session>{
                name: formValue.name,
                description: formValue.description || '',
                imageUrl: formValue?.imageUrl || sessionImage.url || '',
                imageId: formValue?.imageId || sessionImage.id || '',
                status: SessionStatus.Draft,
                creatorId: this.user.id,
                creatorName: this.user.displayName,
                creatorImage: this.user.imageUrl,
                teamMembersCount: 1,
                phase: sessionOptions?.phase || {},
                subPhase: sessionOptions?.subPhase || {},
                client: sessionOptions?.client || {},
                project: sessionOptions?.project || {},
                tags: sessionOptions?.tags || {},
                access: {
                    [this.user.id as string]: {
                        role,
                        displayName: this.user.displayName,
                    },
                },
            };

            const sessionId = await this.sessionService.createSessionEntry(
                session,
                formValue.startDate,
                formValue.endDate,
            );

            this.updateOptions(sessionOptions, sessionId);

            await this.teamMemberService.addTeamMemberToSessionNew(ParentType.Sessions, sessionId, this.user, role);

            if (this.useTemplateId) {
                await this.addActivitiesToSession(sessionId);
            }

            this.redirectToSession(sessionId);
        } catch (e) {
            console.error(e);
            this.snackbarService.showSnackBar(null, errorMessageSnackbarText, SnackBarTypes.Error, true);
        } finally {
            this.isLoading$.next(false);
            this.closeDialog();
        }
    }

    redirectToSession(sessionId: string): void {
        const parentType = ParentType.Sessions;

        this.store.dispatch(
            getSessionData({
                parentType,
                sessionId,
            }),
        );

        this.store.dispatch(
            getParentTeamMemberData({
                parentType,
                parentId: sessionId,
            }),
        );

        this.store.dispatch(
            getSessionActivitiesData({
                parentType,
                sessionId,
            }),
        );

        this.router.navigate(['/dashboard', ParentType.Sessions, sessionId]);
    }

    async addActivitiesToSession(sessionId: string): Promise<void> {
        try {
            await this.activityService.addActivityToSessionNew(
                this.useTemplateId,
                sessionId,
                this.user.id,
                ParentType.Sessions,
                this.useTemplateType,
                this.usePublicAccessId,
                undefined,
                true,
            );

            this.snackbarService.showSnackBar(
                templateAddedSnackbarTitle,
                saveActivityToSessionSuccessSnackBarNew,
                SnackBarTypes.Success,
                true,
            );
        } catch (e) {
            console.error(e);
            this.snackbarService.showSnackBar(
                addingTemplateErrorSnackbarTitle,
                errorMessageSnackbarText,
                SnackBarTypes.Error,
                true,
            );
        }

        this.resetSessionOptions();
    }

    private buildViewModel(): Observable<CreateSessionDialogViewModel> {
        return combineLatest([
            this.store.pipe(select(selectAuthenticatedUser)),
            this.useTemplateStore.useTemplateId$,
            this.useTemplateStore.useTemplateType$,
            this.useTemplateStore.usePublicAccessId$,
            this.optionsStore.currentOptionToDisplay$,
        ]).pipe(
            switchMap(([user, useTemplateId, useTemplateType, usePublicAccessId, currentOptionToDisplay]) => {
                this.user = user;
                this.useTemplateId = useTemplateId;
                this.useTemplateType = useTemplateType;
                this.usePublicAccessId = usePublicAccessId;

                return combineLatest([this.isLoading$.asObservable(), this.sessionImage$.asObservable()]).pipe(
                    map(([isLoading, sessionImage]) => {
                        return {
                            isLoading,
                            sessionImage,
                            currentOptionToDisplay,
                        };
                    }),
                );
            }),
            startWith(defaultViewModel),
        );
    }

    private async updateOptions(selectedSessionOptions: SelectedSessionOptions, sessionId: string): Promise<void> {
        const optionsToCreateIds = await firstValueFrom(this.optionsStore.sessionOptionsToCreateIds$.pipe(take(1)));

        await this.sessionOptionsService.updateOptions(selectedSessionOptions, sessionId, {}, optionsToCreateIds);
    }
}
