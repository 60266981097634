import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';

import { DBPathHelper, FilesRefs } from '@accenture/shared/data';
import { FirestoreService } from '@accenture/shared/data-access';

@Injectable({
    providedIn: 'root',
})
export class FilesService {
    constructor(private firestoreService: FirestoreService) {}

    get fileId(): string {
        return this.firestoreService.getPushId();
    }

    async createFileReference(fileId: string, data: Partial<FilesRefs>): Promise<void> {
        await this.firestoreService.upsert(`/filesRefs/${fileId}`, data);
    }

    async updateFilesByFieldId(fileIds: string[], fieldToUpdate: string, valueToUpdate: string): Promise<void> {
        const batchData = [];
        for (const fileId of fileIds) {
            batchData.push({
                path: DBPathHelper.getFilesRefsPath(fileId),
                data: { [fieldToUpdate]: valueToUpdate },
            });
        }
        await this.firestoreService.updateBatch(batchData);
    }

    async decrementFilesCount(fileId: string): Promise<void> {
        await this.firestoreService.update(`/filesRefs/${fileId}`, {
            count: this.firestoreService.changeCounterValue(-1),
        });
    }

    async decrementFilesCountByActivityId(activityId: string): Promise<void> {
        const files: FilesRefs[] = await firstValueFrom(
            this.firestoreService.getDocumentsByProperty('filesRefs', 'activityId', activityId),
        );

        const batchData = [];
        for (const file of files) {
            batchData.push({
                path: `/filesRefs/${file.id}`,
                data: { count: this.firestoreService.changeCounterValue(-1) },
            });
        }
        await this.firestoreService.writeBatch(batchData);
    }

    async decrementFilesCountByActivityItemId(activityItemId: string): Promise<void> {
        const files: FilesRefs[] = await firstValueFrom(
            this.firestoreService.getDocumentsByProperty('filesRefs', 'activityItemId', activityItemId),
        );

        const batchData = [];
        for (const file of files) {
            batchData.push({
                path: `/filesRefs/${file.id}`,
                data: { count: this.firestoreService.changeCounterValue(-1) },
            });
        }
        await this.firestoreService.writeBatch(batchData);
    }

    async decrementFilesCountByFieldId(fieldName: string, fieldId: string): Promise<void> {
        const files: FilesRefs[] = await firstValueFrom(
            this.firestoreService.getDocumentsByProperty('filesRefs', fieldName, fieldId),
        );

        const batchData = [];
        for (const file of files) {
            batchData.push({
                path: DBPathHelper.getFilesRefsPath(file.id),
                data: { count: this.firestoreService.changeCounterValue(-1) },
            });
        }
        await this.firestoreService.writeBatch(batchData);
    }
}
