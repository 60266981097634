import { FieldValue, Timestamp } from 'firebase/firestore';

export class NodeTimestamp {
    created: Timestamp | FieldValue;
    updated?: Timestamp | FieldValue;

    constructor(data: any) {
        this.created = data.created ?? Timestamp.now();
        this.updated = data.updated ?? Timestamp.now();
    }
}
