export enum FilterOptions {
    All = 'ALL',
    Agreed = 'AGREED',
    Disagreed = 'DISAGREED',
}

export const filterOptionsValues: { [key in FilterOptions]: string } = {
    [FilterOptions.All]: 'All',
    [FilterOptions.Agreed]: 'Agreed',
    [FilterOptions.Disagreed]: 'Disagreed',
};

export enum SearchFilterOptions {
    OWNED_BY_ANYONE = 'Owned by anyone',
    OWNED_BY_ME = 'Owned by me',
}
