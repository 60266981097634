import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import {
    characterLimitLarge,
    characterLimitText,
    determineHintClass,
    inputPlaceholders,
    PublicAccess,
    TemplateRole,
} from '@accenture/shared/data';
import { SharedMaterialModule } from '@accenture/shared/material';
import { SharedUiModule } from '@accenture/shared/ui';

import { EmailInvitationFormComponent } from '../../email-invitation-form/email-invitation-form.component';
import { TemplateInviteDialogFacade } from './template-invite-dialog-facade';

type InviteForm = FormGroup<{
    message: FormControl<string>;
    [TemplateRole.Collaborator]: FormControl<string[]>;
}>;

@Component({
    selector: 'accenture-template-invite-dialog',
    templateUrl: './template-invite-dialog.component.html',
    styleUrls: ['./template-invite-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        SharedMaterialModule,
        SharedUiModule,
        EmailInvitationFormComponent,
    ],
    providers: [TemplateInviteDialogFacade],
})
export class TemplateInviteDialogComponent implements OnInit {
    vm$ = this.facade.vm$;

    collaborator = TemplateRole.Collaborator;
    inviteText = inputPlaceholders.inviteText;
    determineHintClass = determineHintClass;
    characterLimitLarge = characterLimitLarge;
    characterLimitText = characterLimitText;

    inviteForm!: InviteForm;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: { title: string; templateName: string; publicTemplate?: PublicAccess },
        private facade: TemplateInviteDialogFacade,
    ) {
        if (!!this.data?.publicTemplate) {
            this.facade.setPublicData(this.data?.publicTemplate);
        }
    }

    get collaboratorControl(): FormControl<string[]> {
        return this.inviteForm.controls[TemplateRole.Collaborator];
    }

    invite(): void {
        this.facade.invite(this.collaboratorControl.value, this.inviteForm.controls.message.value);
    }

    ngOnInit(): void {
        this.inviteForm = new FormGroup({
            message: new FormControl(''),
            [TemplateRole.Collaborator]: new FormControl([]),
        });
    }
}
