import { Pipe, PipeTransform } from '@angular/core';

import { ActivityItemType, displayActivityItemsIcons } from '@accenture/shared/data';

@Pipe({
    name: 'displayActivityItemIcon',
})
export class DisplayActivityItemIconPipe implements PipeTransform {
    transform(type: ActivityItemType): unknown {
        return displayActivityItemsIcons[type || ActivityItemType.Unknown];
    }
}
