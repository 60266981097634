import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

import { validationErrors, validationMessages } from '@accenture/activity/shared/domain';
import { inputPlaceholders } from '@accenture/shared/data';
import { LoadedDescription } from '@accenture/shared/ui';

import { ContactSupportDialogFacade } from './contact-support-dialog-facade';

type FeedbackForm = FormGroup<{
    email: FormControl<string>;
    feedback: FormControl<string>;
    applyEmail: FormControl<boolean>;
}>;

@Component({
    selector: 'accenture-contact-support-dialog',
    templateUrl: './contact-support-dialog.component.html',
    styleUrls: ['./contact-support-dialog.component.scss'],
    providers: [ContactSupportDialogFacade],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContactSupportDialogComponent {
    vm$ = this.facade.vm$;

    loaderText: LoadedDescription;
    inputPlaceholders = inputPlaceholders;
    validationErrors = validationErrors;
    validationMessages = validationMessages;

    feedbackForm: FeedbackForm;
    email = new FormControl('');
    feedback = new FormControl('', [Validators.required]);
    applyEmail = new FormControl(false);

    constructor(private facade: ContactSupportDialogFacade, private formBuilder: FormBuilder) {
        this.feedbackForm = this.formBuilder.group({
            email: this.email,
            feedback: this.feedback,
            applyEmail: this.applyEmail,
        });
    }

    updateEmailValidators(): void {
        if (this.feedbackForm.get('applyEmail').value) {
            this.email.addValidators([Validators.required, Validators.email]);
        } else {
            this.email.clearValidators();
        }

        this.email.updateValueAndValidity();
    }

    submitForm(): void {
        if (this.feedbackForm.valid) {
            this.facade.contactSupport(this.feedbackForm.value.email, this.feedbackForm.value.feedback);
        }
    }
}
