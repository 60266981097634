import { Injectable } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest, firstValueFrom, Observable } from 'rxjs';
import { map, startWith, switchMap } from 'rxjs/operators';

import {
    sessionAddFromTemplateErrorMessage,
    sessionAddFromTemplateErrorTitle,
    sessionAddProgressFromTemplateMessage,
    sessionAddProgressFromTemplateTitle,
    sessionAddSuccessFromTemplateMessage,
    sessionAddSuccessFromTemplateTitle,
    SessionService,
    UserTemplateService,
} from '@accenture/erp-deployment/shared/domain';
import { AppState, selectAuthenticatedUserId, selectUserCollection } from '@accenture/global-store';
import { Dictionary, UserCollection, UserTemplate } from '@accenture/shared/data';
import { DialogService, SnackbarService, SnackBarTypes } from '@accenture/shared/ui';

import { SelectSourceTypeDialogComponent } from '../select-source-type-dialog/select-source-type-dialog.component';
import { AddSessionTt9DialogComponent } from './add-session-tt9-dialog.component';

export interface AddSessionTt9DialogViewModel {
    templates: UserTemplate[];
    selectedTemplates: UserTemplate[];
    selectedTemplatesMap: Dictionary<boolean>;
    isLoading: boolean;
}

const defaultViewModel: AddSessionTt9DialogViewModel = {
    templates: [],
    selectedTemplates: [],
    selectedTemplatesMap: {},
    isLoading: true,
};

@Injectable()
export class AddSessionTt9DialogFacade {
    vm$ = this.buildViewModel();

    private searchValue$ = new BehaviorSubject('');
    private selectedTemplatesMap$ = new BehaviorSubject<Dictionary<boolean>>({});
    private isLoading$ = new BehaviorSubject<boolean>(false);
    private userId!: string;
    private selectedTemplatesMap: Dictionary<boolean>;
    private userCollection: UserCollection | undefined;

    constructor(
        private store: Store<AppState>,
        private userTemplateService: UserTemplateService,
        private sessionService: SessionService,
        private dialogRef: MatDialogRef<AddSessionTt9DialogComponent>,
        private snackbarService: SnackbarService,
        private dialogService: DialogService,
    ) {}

    updateSearchValue(value: string): void {
        this.searchValue$.next(value);
    }

    updateSelectedTemplates(selectedTemplatesMap: Dictionary<boolean>): void {
        this.selectedTemplatesMap$.next({
            ...this.selectedTemplatesMap$.getValue(),
            ...selectedTemplatesMap,
        });
    }

    closeDialog(): void {
        this.dialogRef.close();
    }

    async addTemplatesToSession(): Promise<void> {
        this.showSaveAsTemplateInfoSnackBar();

        if (!this.userId) {
            this.showSaveAsTemplateErrorSnackBar();
            this.dialogRef.close();
            return;
        }

        const selectedTemplatesIds = Object.keys(this.selectedTemplatesMap || {}).filter(
            (id) => !!this.selectedTemplatesMap[id],
        );

        const templates = {};
        selectedTemplatesIds.forEach((value) => {
            templates[value] = true;
        });
        try {
            for (const selectedTemplateId of selectedTemplatesIds) {
                await this.sessionService.addTemplatesToSession(
                    selectedTemplateId,
                    this.userId,
                    undefined,
                    this.userCollection,
                    true,
                );
            }
            this.showSaveAsTemplateSuccessSnackBar();
        } catch {
            this.showSaveAsTemplateErrorSnackBar();
        }

        this.dialogRef.close();
    }

    private buildViewModel(): Observable<AddSessionTt9DialogViewModel> {
        return combineLatest([
            this.store.select(selectAuthenticatedUserId),
            this.store.select(selectUserCollection),
        ]).pipe(
            switchMap(([userId, userCollection]) => {
                this.userId = userId;
                this.userCollection
                    = userCollection && Object.keys(userCollection).length > 0 ? userCollection : undefined;

                return combineLatest([
                    this.userTemplateService.getTemplatesAssignmentsByUserId(userId),
                    this.searchValue$,
                    this.selectedTemplatesMap$,
                    this.isLoading$,
                ]).pipe(
                    map(([templates, searchValue, selectedTemplatesMap, isLoading]) => {
                        this.selectedTemplatesMap = selectedTemplatesMap;

                        return {
                            selectedTemplatesMap,
                            isLoading,
                            selectedTemplates: this.getSelectedTemplates(templates),
                            templates: this.getFilteredTemplates(templates, searchValue),
                        };
                    }),
                );
            }),
            startWith(defaultViewModel),
        );
    }

    private getFilteredTemplates(templates: UserTemplate[], searchValue: string): UserTemplate[] {
        return templates.filter((template) => {
            const hasSearchName = template.name?.toLowerCase().includes(searchValue);
            const hasSearchDescription = template.description?.toLowerCase().includes(searchValue);

            return hasSearchName || hasSearchDescription;
        });
    }

    private getSelectedTemplates(templates: UserTemplate[]): UserTemplate[] {
        const selectedTemplatesMap = this.selectedTemplatesMap$.getValue();

        return templates.filter((template) => selectedTemplatesMap[template.templateId]);
    }

    private showSaveAsTemplateInfoSnackBar(): void {
        this.snackbarService.showSnackBar(
            sessionAddProgressFromTemplateTitle,
            sessionAddProgressFromTemplateMessage,
            SnackBarTypes.Info,
            true,
        );
    }

    private showSaveAsTemplateSuccessSnackBar(): void {
        this.snackbarService.showSnackBar(
            sessionAddSuccessFromTemplateTitle,
            sessionAddSuccessFromTemplateMessage,
            SnackBarTypes.Success,
            true,
        );
    }

    private showSaveAsTemplateErrorSnackBar(): void {
        this.snackbarService.showSnackBar(
            sessionAddFromTemplateErrorTitle,
            sessionAddFromTemplateErrorMessage,
            SnackBarTypes.Error,
            true,
        );
    }
}
