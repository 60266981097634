import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { DashboardSessionEffects } from './dashboard.effects';
import { DASHBOARD_SESSION_FEATURE_KEY, dashboardSessionReducer } from './dashboard.reducer';
import { DashboardSessionDataService } from './dashboard-data.service';

@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        StoreModule.forFeature(DASHBOARD_SESSION_FEATURE_KEY, dashboardSessionReducer),
        EffectsModule.forFeature([DashboardSessionEffects]),
    ],
    exports: [],
    providers: [DashboardSessionDataService],
})
export class DashboardSessionModule {}
