import {
    BehaviorSubject,
    combineLatest,
    distinctUntilChanged,
    Observable,
} from 'rxjs';
import {
    map,
    startWith,
} from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { SessionRecordingsService } from '@accenture/activity/shared/domain';
import { fileDownload } from '@accenture/erp-deployment/shared/domain';
import {
    AppState,
    selectAuthenticatedUser,
    selectSessionId,
} from '@accenture/global-store';
import { FirestoreService } from '@accenture/shared/data-access';
import {
    SnackbarService,
    SnackBarTypes,
} from '@accenture/shared/ui';

export interface SessionRecorderListViewTranscriptionViewModel {
    isLoading: boolean;
}

const initialState: SessionRecorderListViewTranscriptionViewModel = {
    isLoading: true,
};

@Injectable()
export class SessionRecorderListViewTranscriptionFacade {
    private isLoading$ = new BehaviorSubject<boolean>(false);

    vm$ = this.buildViewModel();

    constructor(
        private store: Store<AppState>,
        private snackbarService: SnackbarService,
        private firestoreService: FirestoreService,
        private sessionRecordingsService: SessionRecordingsService
    ) {}

    sessionId: string;
    userId: string;

    async save(title: string, recordingId: string, transcription: string) {
        this.isLoading$.next(true);

        await this.sessionRecordingsService.updateTranscription(this.sessionId, recordingId, transcription);

        this.snackbarService.showSuccessSnackBar(title, `Transcription updated..`, true);

        this.isLoading$.next(false);
    }

    async transcribe(title: string, recordingId: string): Promise<string> {
        let data: string;
        this.isLoading$.next(true);

        try {
            const { transcription } = await this.firestoreService.cloudFunctionCallable<any>('aiTranscribe', {
                recordingId,
                sessionId: this.sessionId,
            });
            this.snackbarService.showInfoSnackBar(title, `Audio transcribed.`, true);
            data = transcription;
        } catch (e) {
            console.log(e);
            this.snackbarService.showSnackBar(title, 'Error asking GPT', SnackBarTypes.Error, true);
        }

        this.isLoading$.next(false);

        return data;
    }

    async download(title: string, recordingId: string): Promise<string> {
        let data: string;
        this.isLoading$.next(true);

        try {
            this.snackbarService.showInfoSnackBar(title, `Preparing media for download.`, true);

            const { downloadUrl } = await this.firestoreService.cloudFunctionCallable<any>(
                'downloadTranscribedRecording',
                {
                    recordingId,
                    sessionId: this.sessionId,
                }
            );
            this.snackbarService.showSuccessSnackBar(title, `Media ready.`, true);

            fileDownload(downloadUrl);
        } catch (e) {
            console.log(e);
            this.snackbarService.showSnackBar(title, 'Error asking GPT', SnackBarTypes.Error, true);
        }

        this.isLoading$.next(false);

        return data;
    }

    private buildViewModel(): Observable<SessionRecorderListViewTranscriptionViewModel> {
        return combineLatest([
            this.isLoading$.asObservable().pipe(distinctUntilChanged()),
            this.store.select(selectAuthenticatedUser),
            this.store.select(selectSessionId),
        ]).pipe(
            map(([isLoading, user, sessionId]) => {
                this.userId = user.id;
                this.sessionId = sessionId;

                return {
                    isLoading,
                };
            }),
            startWith(initialState)
        );
    }
}
