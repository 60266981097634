<ng-container *ngIf="vm$ | async as vm">
  <accenture-base-dialog title="Prompt Library">
    <!-- Search Input Box -->
    <div class="prompt-library-search-container">
      <mat-form-field
        class="user-search tt9-form-field no-label no-margin width-100"
        appearance="outline"
        floatLabel="always"
      >
        <input
          #search
          matInput
          placeholder="Search"
          [formControl]="searchControl"
          [placeholder]="Search"
          (input)="filterPromptLibrary(search.value)"
        />
        <accenture-icon matPrefix>icon-search</accenture-icon>
        <accenture-icon *ngIf="searchControl.value" matSuffix (click)="clearSearch($event)">
          icon-close
        </accenture-icon>
      </mat-form-field>
    </div>
    <!-- /Search Input Box -->

    <!-- Table -->
    <div class="prompt-library-table-container">
      <div class="tt9-select-table-container width-100">
        <table class="tt9-select-table width-100">
          <thead>
            <tr class="row-sticky horizontal-shadow">
              <th class="cell-select"></th>
              <th class="tt9-body-2 short-cell">Use Case</th>
              <th class="tt9-body-2 short-cell">Category</th>
              <th class="tt9-body-2 short-cell">Description</th>
              <th class="tt9-body-2 short-cell">Prompt Text</th>
            </tr>
          </thead>

          <tbody>
            <ng-container *ngFor="let promptLibraryItem of vm.promptLibraryItems; trackBy: trackById">
              <ng-container
                *ngTemplateOutlet="rowTemplate; context: { promptLibraryItem: promptLibraryItem }"
              ></ng-container>
            </ng-container>
          </tbody>
        </table>
      </div>
    </div>
    <!-- /Table -->

    <div class="footer d-flex-end">
      <button class="tt9-btn-default back-btn" (click)="closePromptLibraryDialog()">
        <accenture-icon>icon-chevron-left</accenture-icon>
        Back
      </button>

      <button class="tt9-btn ml-16" [disabled]="!selectedPromptId" (click)="confirmSelectedPrompt()">Confirm</button>
    </div>
  </accenture-base-dialog>
</ng-container>

<ng-template #rowTemplate let-promptLibraryItem="promptLibraryItem">
  <tr [class.hover]="selectedPromptId === promptLibraryItem.id" (click)="selectedPrompt(promptLibraryItem)">
    <td class="cell-select">
      <mat-radio-button
        class="tt9-radio-button group-option"
        color="primary"
        [checked]="selectedPromptId === promptLibraryItem.id"
        (click)="$event.preventDefault()"
      ></mat-radio-button>
    </td>

    <td class="short-cell">
      <span
        class="tt9-body-1 text-overflow-ellipsis-2"
        matTooltipClass="tt9-arrow-tooltip-below"
        matTooltipPosition="above"
        [matTooltip]="promptLibraryItem.useCase"
      >
        {{ promptLibraryItem.useCase }}
      </span>
    </td>

    <td class="short-cell">
      <span
        class="tt9-body-1 text-overflow-ellipsis-2"
        matTooltipClass="tt9-arrow-tooltip-below"
        matTooltipPosition="above"
        [matTooltip]="promptLibraryItem.category"
      >
        {{ promptLibraryItem.category }}
      </span>
    </td>

    <td class="short-cell">
      <span
        class="tt9-body-1 text-overflow-ellipsis-2"
        matTooltipClass="tt9-arrow-tooltip-below"
        matTooltipPosition="above"
        [matTooltip]="promptLibraryItem.description"
      >
        {{ promptLibraryItem.description }}
      </span>
    </td>

    <td class="resizable-width">
      <span
        class="tt9-body-1 text-overflow-ellipsis-2"
        matTooltipClass="tt9-arrow-tooltip-below"
        matTooltipPosition="above"
        [matTooltip]="promptLibraryItem.promptText"
      >
        {{ promptLibraryItem.promptText }}
      </span>
    </td>
  </tr>
</ng-template>
