import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, combineLatest, from, of, takeUntil } from 'rxjs';
import { map, skipWhile, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';

import { Activity, NavigationTab, Session, TeamMember, UserSession } from '@accenture/shared/data';

import { selectAuthenticatedUserId } from '../auth/auth.selectors';
import { navigateHome } from '../router/router.actions';
import * as sessionActions from './session.actions';
import { SessionDataService } from './session-data.service';

@Injectable()
export class SessionEffects {
    public getSessionData$ = createEffect(() =>
        this.actions$.pipe(
            ofType(sessionActions.getSessionData),
            switchMap(({ parentType, sessionId }) =>
                combineLatest([
                    this.sessionDataService.getSession(parentType, sessionId).pipe(
                        tap((session) => {
                            if (!session) {
                                this.store.dispatch(navigateHome({ navigationTab: NavigationTab.Sessions }));
                            }
                        }),
                        catchError(() => {
                            this.store.dispatch(navigateHome({ navigationTab: NavigationTab.Sessions }));
                            return of({} as Session);
                        }),
                    ),
                    this.sessionDataService.getAttributeClasses(parentType, sessionId),
                    this.sessionDataService.getAttributes(parentType, sessionId),
                ]).pipe(
                    takeUntil(this.actions$.pipe(ofType(sessionActions.clearSessionSubscriptions))),
                    map(([session, attributeClasses, attributes]) => {
                        return sessionActions.getSessionDataSuccess({
                            session,
                            attributeClasses,
                            attributes,
                        });
                    }),
                    catchError((e) => {
                        console.error('Error on getting the session data', e);
                        return of(sessionActions.getSessionDataError());
                    }),
                ),
            ),
        ),
    );

    public getSessionTeamMemberData$ = createEffect(() =>
        this.actions$.pipe(
            ofType(sessionActions.getParentTeamMemberData),
            withLatestFrom(this.store.select(selectAuthenticatedUserId)),
            switchMap(([{ parentType, parentId }, userId]) => {
                return this.sessionDataService.getSessionTeamMember(parentType, parentId, userId).pipe(
                    takeUntil(this.actions$.pipe(ofType(sessionActions.clearSessionSubscriptions))),
                    map((teamMember: TeamMember) => sessionActions.getSessionTeamMemberDataSuccess({ teamMember })),
                    catchError(() => of(sessionActions.getTeamMemberError())),
                );
            }),
        ),
    );

    public setTeamMemberOfflineStateSession$ = createEffect(() =>
        this.actions$.pipe(
            ofType(sessionActions.setTeamMemberOfflineStateSession),
            switchMap(({ sessionId }) =>
                this.store.select(selectAuthenticatedUserId).pipe(
                    skipWhile((userId) => !userId),
                    take(1),
                    switchMap((userId) =>
                        from(this.sessionDataService.clearOnlineState(sessionId, userId)).pipe(
                            map(() => sessionActions.teamMemberOnlineStateSessionChangedSuccess()),
                            catchError(() => of(sessionActions.teamMemberOnlineStateSessionChangedError())),
                        ),
                    ),
                ),
            ),
        ),
    );

    public getSessionActivitiesData$ = createEffect(() =>
        this.actions$.pipe(
            ofType(sessionActions.getSessionActivitiesData),
            switchMap(({ parentType, sessionId }) =>
                this.sessionDataService.getActivities(parentType, sessionId).pipe(
                    takeUntil(this.actions$.pipe(ofType(sessionActions.clearSessionSubscriptions))),
                    map((activities: Activity[]) => sessionActions.getSessionActivitiesDataSuccess({ activities })),
                ),
            ),
        ),
    );

    public getUserSessions$ = createEffect(() =>
        this.actions$.pipe(
            ofType(sessionActions.getUserSessions),
            withLatestFrom(this.store.select(selectAuthenticatedUserId)),
            switchMap(([_, userId]) => {
                return this.sessionDataService.getUserSessions(userId).pipe(
                    takeUntil(this.actions$.pipe(ofType(sessionActions.clearSessionSubscriptions))),
                    map((userSessions: UserSession[]) => sessionActions.getUserSessionsSuccess({ userSessions })),
                    catchError(() => of(sessionActions.getTeamMemberError())),
                );
            }),
        ),
    );

    constructor(private actions$: Actions, private sessionDataService: SessionDataService, private store: Store) {}
}
