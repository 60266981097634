import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { combineLatest, Observable, of } from 'rxjs';
import { map, startWith, switchMap } from 'rxjs/operators';

import { ActivityService } from '@accenture/activity/shared/domain';
import { CollectionsService } from '@accenture/erp-deployment/shared/domain';
import {
    AppState,
    getRouterState,
    isTeamPage,
    RouterStateUrl,
    selectActivityIdAndParentIds,
    selectAdminRoute,
    selectAuthenticatedUser,
    selectIsAdministrationSession,
    selectSessionData,
    selectUserId,
} from '@accenture/global-store';
import {
    Activity,
    ActivityType,
    Collection,
    CollectionSession,
    ParentType,
    Session,
    User,
} from '@accenture/shared/data';
import { UserService } from '@accenture/shared/data-access';

export interface BreadcrumbsModel {
    isLoading: boolean;
    session?: Session;
    activity?: Activity;
    user?: User;
    parentType?: ParentType;
    isSessionTemplate: boolean;
    isActivityTemplate: boolean;
    isAdminUser: boolean;
    isAdminSession: boolean;
    isTeamPage?: boolean;
}

interface CollectionData {
    id: string;
    name: string;
}

const defaultModel = {
    isLoading: false,
    session: undefined,
    activity: undefined,
    user: undefined,
    parentType: undefined,
    isSessionTemplate: false,
    isActivityTemplate: false,
    isAdminUser: false,
    isAdminSession: false,
    isTeamPage: false,
};

@Injectable()
export class BreadcrumbsTT9Facade {
    private parentType!: ParentType;
    private parentId!: string;
    private sessionId!: string;
    private activityId!: string;
    private activityType!: ActivityType;
    private routerState!: RouterStateUrl;
    private user!: User;

    vm$ = this.buildViewModel();

    constructor(
        private store: Store<AppState>,
        private activityService: ActivityService,
        private userService: UserService,
        private collectionsService: CollectionsService,
        private router: Router,
        private route: ActivatedRoute,
    ) {}

    navigateBack(): void {
        this.router.navigate(['../..'], { relativeTo: this.route });
    }

    navigateToActivityView(): void {
        this.router.navigate(['../..', this.activityType.toLowerCase(), this.activityId], { relativeTo: this.route });
    }

    private buildViewModel(): Observable<BreadcrumbsModel> {
        return combineLatest([
            this.store.select(getRouterState),
            this.store.select(selectActivityIdAndParentIds),
            this.store.select(selectUserId),
            this.store.select(selectAdminRoute),
            this.store.select(isTeamPage),
            this.store.select(selectAuthenticatedUser),
            this.store.select(selectIsAdministrationSession),
        ]).pipe(
            switchMap(
                ([
                    routerState,
                    { parentType, parentId, sessionId, activityId },
                    userId,
                    isAdministration,
                    isTeamPage,
                    user,
                    isAdministrationSession,
                ]) => {
                    this.routerState = routerState;
                    this.parentType = parentType;
                    this.parentId = parentId;
                    this.sessionId = sessionId;
                    this.activityId = activityId;
                    this.user = user;

                    return combineLatest([
                        this.getSession(),
                        this.getActivity(activityId),
                        this.getUser(userId),
                        this.getCollectionData(),
                    ]).pipe(
                        map(([session, activity, user, collection]) => {
                            return {
                                session,
                                activity,
                                user,
                                collection,
                                isTeamPage,
                                parentType: this.parentType,
                                routerState,
                                isSessionTemplate: [ParentType.Templates, ParentType.PublicSessionTemplates].includes(
                                    this.parentType,
                                ),
                                isActivityTemplate: [
                                    ParentType.ActivityTemplates,
                                    ParentType.PublicActivityTemplates,
                                ].includes(this.parentType),
                                isAdminUser: isAdministration,
                                isAdminSession: isAdministrationSession,
                                isLoading: false,
                            };
                        }),
                    );
                },
            ),
            startWith(defaultModel),
        );
    }

    private getSession(): Observable<Session | undefined> {
        return this.store.select(selectSessionData).pipe(
            switchMap(
                (sessionData) =>
                    sessionData?.id === this.sessionId && !!Object.keys(sessionData).length
                        ? of(sessionData)
                        : this.store.select(selectSessionData), // For templates
            ),
        );
    }

    private getActivity(activityId: string): Observable<Activity | undefined> {
        if (activityId) {
            return this.activityService.getActivity(this.parentType, this.parentId, activityId);
        }

        return of(undefined);
    }

    private getUser(userId: string): Observable<User | undefined> {
        if (userId) {
            return this.userService.getUser(userId);
        }
        return of(undefined);
    }

    private getCollectionData(): Observable<CollectionData | null> {
        const collectionData$ = this.collectionsService
            .getCollectionSessionDocument(this.user?.id, this.sessionId)
            .pipe(
                switchMap((collectionSession: CollectionSession) => {
                    if (collectionSession) {
                        return this.collectionsService.getCollectionById(collectionSession.collectionId).pipe(
                            map((collection: Collection) => {
                                return {
                                    id: collectionSession.collectionId,
                                    name: collection.name,
                                };
                            }),
                        );
                    }
                    return of(null);
                }),
            );
        return collectionData$;
    }
}
