import { ChangeDetectionStrategy, Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable, tap } from 'rxjs';

import {
    characterLimitMedium,
    characterLimitText,
    characterLimitXL,
    collectionColors,
    CollectionOptions,
    DefaultCollectionColor,
    determineHintClass,
    displayCollectionColorsName,
    FileType,
    validationMessages,
} from '@accenture/shared/data';
import { ImageInputPlaceholdersTypes, LoadedDescription } from '@accenture/shared/ui';

import { EditCollectionDialogFacade, EditCollectionDialogViewModel } from './edit-collection-dialog-facade';

type OptionsFormGroup = FormGroup<{
    [CollectionOptions.Tag]: FormControl<string>;
}>;

type CollectionEditForm = FormGroup<{
    name: FormControl<string>;
    description: FormControl<string>;
    color: FormControl<string>;
    options: OptionsFormGroup;
}>;

@Component({
    selector: 'accenture-edit-collection-dialog',
    templateUrl: './edit-collection-dialog.component.html',
    styleUrls: ['./edit-collection-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [EditCollectionDialogFacade],
})
export class EditCollectionDialogComponent implements OnInit, OnDestroy {
    @Input() collectionId: string;

    collectionEditForm: CollectionEditForm;
    optionsForm: OptionsFormGroup;

    loaderText = LoadedDescription.CollectionUpdating.toUpperCase();
    imageInputPlaceholder = ImageInputPlaceholdersTypes.Collection;
    characterLimitMedium = characterLimitMedium;
    descriptionCharacterLimit = characterLimitXL;
    displayCollectionColorsName = displayCollectionColorsName;
    collectionColors = collectionColors;
    characterLimitText = characterLimitText;
    determineHintClass = determineHintClass;
    validationMessages = validationMessages;

    vm$: Observable<EditCollectionDialogViewModel> = this.facade.vm$.pipe(
        tap(({ collection, isCollectionUpdated, isSaving }) => {
            if (this.collectionEditForm && isCollectionUpdated && collection?.id && !isSaving) {
                this.collectionEditForm.patchValue({
                    name: collection.name,
                    description: collection.description,
                    color: collection.color,
                });

                this.facade.initializeCollection();
            }
        }),
    );

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: { title: string; cancelBtnText: string },
        private facade: EditCollectionDialogFacade,
        private formBuilder: FormBuilder,
    ) {
        this.initializeForm();
    }

    ngOnInit(): void {
        this.facade.setCollectionId(this.collectionId);
    }

    get selectedColor(): AbstractControl {
        return this.collectionEditForm.get('color') as AbstractControl;
    }

    closeDialog(areChipsOpen: boolean): void {
        areChipsOpen ? this.facade.backToCollection() : this.facade.closeDialog();
    }

    backToCollection(): void {
        this.facade.backToCollection();
    }

    setCollectionImage(collectionImage: FileType): void {
        this.facade.setCollectionImage(collectionImage);
    }

    deleteCollectionImage(): void {
        this.facade.setCollectionImage({} as FileType);
    }

    updateCollection(): void {
        const collectionDetails = this.collectionEditForm.value;
        this.facade.updateCollection(collectionDetails);
    }

    ngOnDestroy(): void {
        this.facade.resetOptionsStore();
    }

    private initializeForm() {
        this.optionsForm = this.formBuilder.group(
            {
                tags: new FormControl(''),
            },
            {
                updateOn: 'blur',
            },
        );

        this.collectionEditForm = this.formBuilder.group({
            name: new FormControl('', { nonNullable: true, validators: Validators.required }),
            description: new FormControl('', { nonNullable: true }),
            color: new FormControl(DefaultCollectionColor, { nonNullable: true }),
            options: this.optionsForm,
        });
    }
}
