import { Dictionary } from './dictionary';
import { NodeTimestamp } from './node-timestamp';
import { Tag } from './tag';

export type SessionOptionsType = Phase | SubPhase | Tag | SessionProject | SessionClient;

export interface SelectedSessionOptions {
    phase: Dictionary<string>; // [id]: name
    client: Dictionary<string>; // [id]: name
    project: Dictionary<string>; // [id]: name
    subPhase: Dictionary<string>;
    tags: Dictionary<string>;
}

export enum SessionOptions {
    Phase = 'phase',
    SubPhase = 'subPhase',
    SessionClient = 'client',
    SessionProject = 'project',
    Tag = 'tags',
}

export const sessionOptionsDialogTitle = {
    [SessionOptions.Phase]: 'All phases',
    [SessionOptions.SessionClient]: 'All clients',
    [SessionOptions.SessionProject]: 'All project names',
    [SessionOptions.SubPhase]: 'All sub-phases',
    [SessionOptions.Tag]: 'All tags',
};

export const sessionOptionsTitle = {
    [SessionOptions.Phase]: 'Phase',
    [SessionOptions.SubPhase]: 'Sub-phase',
    [SessionOptions.SessionProject]: 'Project name',
    [SessionOptions.SessionClient]: 'Client',
    [SessionOptions.Tag]: 'Tags',
};

export enum sessionPageDropdownItems {
    AllSessions = 'All Sessions',
    SessionLeader = 'Session Leader',
    SessionParticipant = 'Session Participant',
}

export const sessionPageDropdownOptions = [
    sessionPageDropdownItems.AllSessions,
    sessionPageDropdownItems.SessionLeader,
    sessionPageDropdownItems.SessionParticipant,
];

export const newOptionText = {
    phase: '(New phase)',
    subPhase: '(New sub-phase)',
    project: '(New project)',
    client: '(New client)',
    tags: '(New tag)',
};

export const notFoundOptionsText = {
    phases: 'No phases found',
    subPhases: 'No sub-phases found',
    clients: 'No client found',
    projects: 'No projects found',
    tags: 'No tags found',
};

export const sessionOptionsArray = [
    SessionOptions.SessionClient,
    SessionOptions.SessionProject,
    SessionOptions.Phase,
    SessionOptions.SubPhase,
    SessionOptions.Tag,
];

export interface Phase extends NodeTimestamp {
    id?: string;
    name: string;
    sessions?: string[];
    default?: boolean;
}

export interface SubPhase extends NodeTimestamp {
    id?: string;
    name: string;
    sessions?: string[];
    default?: boolean;
}

export interface SessionClient extends NodeTimestamp {
    id?: string;
    name: string;
    sessions?: string[];
    default?: boolean;
}

export interface SessionProject extends NodeTimestamp {
    id?: string;
    name: string;
    sessions?: string[];
}

export const projectRequiredHint = 'A project name is required if a client is selected';
