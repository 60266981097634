import { BaseResponse } from './response';

export class BrainstormResponse extends BaseResponse {
    commentsCount?: number;
    upvotesCount?: number;
    bookmarkActive?: boolean;
    groups?: string[];

    // add needed other fields here
    constructor(response: Partial<BrainstormResponse>) {
        super(response);
        this.commentsCount = response.commentsCount ?? 0;
        this.upvotesCount = response.upvotesCount ?? 0;
        this.bookmarkActive = response.bookmarkActive ?? false;
        this.groups = response.groups ?? [];
    }

    createSerializableObject(): Record<string, unknown> {
        return {
            activityItemId: this.activityItemId,
            activityId: this.activityId,
            sessionId: this.sessionId,
            userId: this.userId,
            userDisplayName: this.userDisplayName,
            userImageUrl: this.userImageUrl,
            value: this.value,
            groups: this.groups,
            created: this.created,
            updated: this.updated,
        };
    }
}
