import { Component, ChangeDetectionStrategy, Inject } from '@angular/core';

import { Dictionary } from '@accenture/shared/data';
import { LOADED_DATA } from '../../directives/loaded/loaded-token';

@Component({
    selector: 'accenture-loader-with-description',
    templateUrl: './loader-with-description.component.html',
    styleUrls: ['./loader-with-description.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoaderWithDescriptionComponent {
    constructor(@Inject(LOADED_DATA) public data: Dictionary<string>) {}
}
