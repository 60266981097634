import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, switchMap, takeUntil } from 'rxjs/operators';

import { signOut } from '../auth/auth.actions';
import * as attributesActions from './attributes.actions';
import { AttributesDataService } from './attributes-data.service';

@Injectable()
export class AttributesEffects {
    public getAttributeClasses$ = createEffect(() =>
        this.actions$.pipe(
            ofType(attributesActions.getAttributeClasses),
            switchMap(() =>
                this.attributesDataService.getDefaultAttributeClasses().pipe(
                    map(attributeClasses => attributesActions.getAttributeClassesSuccess({ attributeClasses })),
                    takeUntil(this.actions$.pipe(ofType(signOut))),
                ),
            ),
        ),
    );

    constructor(private actions$: Actions, private attributesDataService: AttributesDataService) {}
}
