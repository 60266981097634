import { AfterViewInit, ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TimePicker,timePicker } from 'analogue-time-picker';

import { DialogService } from '@accenture/shared/ui';

interface InputData {
    title: string;
    onAccept: (proposedDateTime: Date, duration: number) => void;
}

@Component({
    selector: 'accenture-session-book-meeting-dialog',
    templateUrl: './session-book-meeting-dialog.component.html',
    styleUrls: ['./session-book-meeting-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SessionBookMeetingDialogComponent implements AfterViewInit {
    timePicker!: TimePicker;
    selectedDate = new Date();
    duration = 30;

    constructor(@Inject(MAT_DIALOG_DATA) public data: InputData, private dialogService: DialogService) {}

    ngAfterViewInit(): void {
        this.selectedDate = new Date(3600000 * (1 + Math.round(Date.now() / 3600000)));

        const element = document.getElementById('time-picker-entry');
        this.timePicker = timePicker({
            element,
            mode: 12,
            width: '220px',
            time: { hour: this.selectedDate.getHours(), minute: 0 },
        });
    }

    bookMeeting(): void {
        const time = this.timePicker.getTime();

        const startDateTime = this.selectedDate;
        startDateTime.setHours(time.hour);
        startDateTime.setMinutes(time.minute);
        console.log(this.selectedDate);

        this.data.onAccept(this.selectedDate, this.duration);
        this.dialogService.close();
    }

    public setSelectedDate(value: Date): void {
        this.selectedDate = value;
    }
}
