import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ActivityType, AIPromptType, AnalyzePrompt, DBPathHelper } from '@accenture/shared/data';
import { FirestoreService } from '@accenture/shared/data-access';

@Injectable({
    providedIn: 'root',
})
export class AIPromptService {
    constructor(private firestoreService: FirestoreService) {}

    getAnalyzePrompts(type: ActivityType): Observable<AnalyzePrompt[]> {
        return this.firestoreService.getDocumentsByProperty<AnalyzePrompt>(
            DBPathHelper.getAIPromptPath(),
            'type',
            this.getAIPromptType(type),
            'sequence',
        );
    }

    getAIPromptType(type: ActivityType): AIPromptType {
        switch (type) {
            case ActivityType.Brainstorm:
                return AIPromptType.AnalyzeBrainstorm;
            case ActivityType.Present:
                return AIPromptType.AnalyzePresent;
            case ActivityType.Vote:
                return AIPromptType.AnalyzeVote;
            case ActivityType.QuickPoll:
                return AIPromptType.AnalyzeQuickPoll;
            case ActivityType.Table:
                return AIPromptType.AnalyzeTable;
        }
    }
}
