import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, inject, Output } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatMenuTrigger } from '@angular/material/menu';
import { Observable, tap } from 'rxjs';

import { TeamMemberService } from '@accenture/erp-deployment/shared/domain';
import {
    AccessControlRole,
    emptyScreenTitles,
    errorMessageSnackbarText,
    ImportErrorType,
    ImportResultType,
    ImportType,
    invitingTeamMembersErrorSnackbarTitle,
    invitingTeamMembersInProgressSnackbarText,
    invitingTeamMembersInProgressSnackbarTitle,
    ParentType,
    ProjectRole,
    SessionRole,
    TeamMember,
    teamMembersInvitedSnackbarText,
    teamMembersInvitedSnackbarTitle,
} from '@accenture/shared/data';
import { SharedMaterialModule } from '@accenture/shared/material';
import { DialogService, SharedUiModule, SnackbarService, SnackBarTypes } from '@accenture/shared/ui';
import { trackById } from '@accenture/shared/util';

import { NewSessionInviteDialogComponent } from '../dialogs-tt9';
import { EmailsImportWarningSnackbarComponent } from '../emails-import-warning-snackbar/emails-import-warning-snackbar.component';
import { ImportButtonTT9Component } from '../import-button-tt9/import-button.component';
import { SessionTeamTt9Facade, SessionTeamViewModel } from './session-team-tt9-facade';

@Component({
    selector: 'accenture-session-team-tt9',
    standalone: true,
    imports: [
        CommonModule,
        SharedUiModule,
        FormsModule,
        ReactiveFormsModule,
        SharedMaterialModule,
        ImportButtonTT9Component,
    ],
    templateUrl: './session-team-tt9.component.html',
    styleUrls: ['./session-team-tt9.component.scss'],
    providers: [SessionTeamTt9Facade, TeamMemberService, SnackbarService, DialogService],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SessionTeamTt9Component {
    @Output() closePanel = new EventEmitter<void>();

    readonly trackById = trackById;
    readonly sessionRoles = SessionRole;
    readonly availableRolesMap = {
        [SessionRole.Leader]: [SessionRole.Participant],
        [SessionRole.Participant]: [SessionRole.Leader],
    };

    importType = ImportType.Excel;
    importResultType = ImportResultType.Emails;
    parentType = ParentType.Sessions;
    searchControl = new FormControl<string>('', { nonNullable: true });
    openedMenuUserId: string | undefined;
    trigger!: MatMenuTrigger;

    private facade = inject(SessionTeamTt9Facade);
    private snackbarService = inject(SnackbarService);
    private dialogService = inject(DialogService);

    vm$: Observable<SessionTeamViewModel> = this.facade.vm$.pipe(
        tap(({ searchValue }) => this.searchControl.patchValue(searchValue)),
    );

    get emptyScreenTitle(): string {
        return this.searchControl.value ? emptyScreenTitles.noUsersMatch : emptyScreenTitles.noUsersYet;
    }

    setTriggerMenuData(activityId?: string, activityTrigger?: MatMenuTrigger): void {
        this.openedMenuUserId = activityId;
        this.trigger = activityTrigger;
    }

    updateSearchValue(searchValue: string): void {
        this.facade.updateSearchValue(searchValue);
    }

    addMembers(): void {
        this.dialogService.open(NewSessionInviteDialogComponent, {
            title: 'Invite team to session',
            width: '768px',
            disableClose: true,
            panelClass: 'tt9-modal',
        });
    }

    // TODO: Repoint to session
    changeRole(event: Event, sessionId: string, userId: string, role: AccessControlRole): Promise<void> {
        event.stopPropagation();
        this.trigger.closeMenu();
        return;
        // return this.facade.changeUserRole(projectId, sessionId, userId, role);
    }

    // TODO: Repoint to session
    deleteUser(event: Event, sessionId: string, userId: string): void {
        event.stopPropagation();
        this.trigger.closeMenu();
        // return this.facade.openDeleteConfirmationDialog(projectId, sessionId, userId);
    }

    showProgressSnackbar(): void {
        this.snackbarService.showInfoSnackBar(
            invitingTeamMembersInProgressSnackbarTitle,
            invitingTeamMembersInProgressSnackbarText,
            false,
        );
    }

    showSuccessSnackbar(): void {
        this.snackbarService.showSuccessSnackBar(teamMembersInvitedSnackbarTitle, teamMembersInvitedSnackbarText, true);
    }

    showErrorSnackbar(): void {
        this.snackbarService.showErrorSnackBar(invitingTeamMembersErrorSnackbarTitle, errorMessageSnackbarText, true);
    }

    // TODO: Check if projectRole admin checking will be replaced by new condition
    isUserHasMenu(user: TeamMember, mainUserId: string, currentUserId: string): boolean {
        return (
            user.userId !== mainUserId && user.userId !== currentUserId
            // && !(user.role === SessionRole.Leader && projectRole === ProjectRole.Admin)
        );
    }

    clearSearch(event: Event): void {
        event.stopPropagation();
        this.searchControl.patchValue('');
        this.facade.updateSearchValue('');
    }

    // any is expected to process any errors from CF
    showImportFailed(e: any): void {
        if (e?.details?.type === ImportErrorType.EmailsImportWarning) {
            this.showImportWarning(e.details.emails || []);
        } else {
            this.showErrorSnackbar();
        }
    }

    // TODO: Repoint to session
    navigateToSessionTeamPage(sessionId: string): void {
        // this.facade.navigateToSessionTeamPage(projectId, sessionId);
    }

    private showImportWarning(emails: string[]): void {
        this.snackbarService.showCustomSnackBar(
            EmailsImportWarningSnackbarComponent,
            { emails },
            SnackBarTypes.Warning,
            undefined,
        );
    }
}
