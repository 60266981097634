import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { batchLoadingRowsLimit, DBPathHelper, ParentType, TableRow } from '@accenture/shared/data';
import { FirestoreService } from '@accenture/shared/data-access';
import { SequenceGeneration } from '@accenture/shared/util';

@Injectable({
    providedIn: 'root',
})
export class TableRowService {
    constructor(private firestoreService: FirestoreService) {}

    getRows(parentType: ParentType, parentId: string, tableId?: string): Observable<TableRow[]> {
        const path = DBPathHelper.getTableRowPath(parentType, parentId);
        const tableRowObjects$ = tableId
            ? this.firestoreService.getDocumentsByProperty<TableRow>(path, 'activityItemId', tableId, 'sequence')
            : this.firestoreService.getCollection<TableRow>(path);

        return tableRowObjects$.pipe(
            map((tableRowObjects) => {
                return tableRowObjects.map((row) => new TableRow(row));
            }),
        );
    }

    getRowsBatch(
        parentType: ParentType,
        parentId: string,
        tableId?: string,
        lastRowDocument?: any,
    ): Observable<{ tableRowObjects: TableRow[]; lastDocument?: any; isLastBatch: boolean; deletedIds: string[] }> {
        const path = DBPathHelper.getTableRowPath(parentType, parentId);
        let allCurrentIds: Set<string> = new Set();

        return this.firestoreService
            .getDocumentsByQuery<TableRow>(path, (ref) => {
                let query = ref.orderBy('sequence');

                if (tableId) {
                    query = query.where('activityItemId', '==', tableId);
                }

                if (lastRowDocument) {
                    query = query.startAfter(lastRowDocument);
                }

                return query.limit(batchLoadingRowsLimit + 1); // Fetch extra document to check for last batch
            })
            .snapshotChanges()
            .pipe(
                filter((snapshot) => snapshot.every(({ payload }) => payload.doc.metadata.fromCache === false)),
                map((snapshot) => {
                    const newBatchIds = new Set<string>();
                    const tableRowObjects: TableRow[] = [];

                    snapshot.forEach((change) => {
                        const doc = change.payload.doc;
                        const docId = doc.id;
                        newBatchIds.add(docId);

                        if (change.type === 'added' || change.type === 'modified') {
                            tableRowObjects.push(new TableRow({ ...doc.data(), id: docId }));
                        }
                    });

                    // Calculate deleted IDs across all loaded batches
                    const deletedIds = Array.from(allCurrentIds).filter((id) => !newBatchIds.has(id));
                    allCurrentIds = new Set([...allCurrentIds, ...newBatchIds]);

                    const isLastBatch = snapshot.length <= batchLoadingRowsLimit;
                    const lastDocument = snapshot[snapshot.length - (isLastBatch ? 1 : 2)]?.payload.doc || null;

                    return {
                        tableRowObjects: tableRowObjects.slice(0, batchLoadingRowsLimit),
                        lastDocument,
                        isLastBatch,
                        deletedIds,
                    };
                }),
            );
    }

    setRealTimeRowListener(
        parentType: ParentType,
        parentId: string,
        tableId?: string,
        lastDocument?: any,
    ): Observable<{ addedOrUpdated: TableRow[]; deleted: TableRow[] }> {
        const path = DBPathHelper.getTableRowPath(parentType, parentId);

        if (!lastDocument) {
            return of({ addedOrUpdated: [], deleted: [] });
        }

        return this.firestoreService
            .getDocumentsByQuery<TableRow>(path, (ref) => {
                let query = ref.orderBy('sequence');

                if (tableId) {
                    query = query.where('activityItemId', '==', tableId);
                }

                if (lastDocument) {
                    query = query.endAt(lastDocument);
                }

                return query;
            })
            .stateChanges(['added', 'modified', 'removed'])
            .pipe(
                map((changes) => {
                    const addedOrUpdated: TableRow[] = [];
                    const deleted: TableRow[] = [];

                    changes.forEach((change) => {
                        const data = new TableRow({ ...change.payload.doc.data(), id: change.payload.doc.id });

                        if (change.type === 'removed') {
                            deleted.push(data);
                        } else {
                            addedOrUpdated.push(data);
                        }
                    });

                    return { addedOrUpdated, deleted };
                }),
            );
    }

    async addRows(parentType: ParentType, parentId: string, rows: Partial<TableRow>[]): Promise<void> {
        const batchData = [];
        let sequence = SequenceGeneration.initial();
        rows.forEach((row) => {
            batchData.push({
                path: DBPathHelper.getTableRowPath(parentType, parentId, this.firestoreService.getPushId()),
                data: {
                    ...row,
                    sequence,
                    created: this.firestoreService.timestamp,
                    updated: this.firestoreService.timestamp,
                },
            });
            sequence = SequenceGeneration.afterLast(sequence);
        });
        await this.firestoreService.setBatch(batchData);
    }

    async addRow(parentType: ParentType, parentId: string, data: Partial<TableRow>): Promise<string> {
        return await this.firestoreService.addDocument(DBPathHelper.getTableRowPath(parentType, parentId), {
            ...data,
            created: this.firestoreService.timestamp,
            updated: this.firestoreService.timestamp,
        });
    }

    async updateRow(
        parentType: ParentType,
        parentId: string,
        rowId: string,
        rowFields: Partial<TableRow>,
    ): Promise<void> {
        await this.firestoreService.update(
            DBPathHelper.getTableRowPath(parentType, parentId, rowId),
            this.firestoreService.replaceEmptyFields(rowFields),
        );
    }

    async deleteRow(parentType: ParentType, parentId: string, rowId: string): Promise<void> {
        return this.firestoreService.delete(DBPathHelper.getTableRowPath(parentType, parentId, rowId));
    }
}
