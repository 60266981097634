import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest, map, Observable, startWith } from 'rxjs';
import { distinctUntilChanged, switchMap } from 'rxjs/operators';

import { CollectionOptionsService, CollectionsService, FiltersService } from '@accenture/erp-deployment/shared/domain';
import { AppState, selectAuthenticatedUserId } from '@accenture/global-store';
import {
    CollectionFilters,
    CollectionOptions,
    Dictionary,
    initialCollectionFilters,
    ParentType,
    sortOptionsValues,
} from '@accenture/shared/data';

export interface CollectionsFilterAndSortViewModel {
    filtersAndSort: CollectionFilters;
    parentType: ParentType | string;
    sortOptionsValues: Dictionary<string>;
    isLoading: boolean;
}

export const initialViewModel = {
    filtersAndSort: initialCollectionFilters,
    parentType: ParentType.Projects,
    sortOptionsValues: sortOptionsValues,
    isLoading: true,
};

@Injectable()
export class CollectionFiltersAndSortFacade {
    autocompleteValues$ = new BehaviorSubject<Dictionary<string>>({});

    vm$ = this.buildViewModel();

    private userId: string;
    private parentType: ParentType | string;

    constructor(
        private store: Store<AppState>,
        private filtersService: FiltersService,
        private collectionOptionsService: CollectionOptionsService,
        private collectionsService: CollectionsService,
    ) {}

    applyFiltersAndSort(filters: CollectionFilters): void {
        this.collectionsService.updateCollectionsFilters(this.userId, filters);
    }

    setOptionType(optionType: CollectionOptions): void {
        this.filtersService.optionType.next(optionType);
    }

    private buildViewModel(): Observable<CollectionsFilterAndSortViewModel> {
        return this.store.select(selectAuthenticatedUserId).pipe(
            switchMap((userId) => {
                this.userId = userId;
                const filters$ = this.getCollectionsFilters(userId);

                return combineLatest([
                    filters$,
                    this.autocompleteValues$.asObservable().pipe(distinctUntilChanged()),
                ]).pipe(
                    map(([filtersAndSort]) => {
                        return {
                            filtersAndSort,
                            sortOptionsValues: sortOptionsValues,
                            parentType: this.parentType,
                            isLoading: false,
                        };
                    }),
                );
            }),
            startWith(initialViewModel),
        );
    }

    private getCollectionsFilters(userId: string): Observable<CollectionFilters> {
        return this.collectionsService.getCurrentUserCollectionsFilters(userId).pipe(
            map((filtersAndSort) => ({
                ...initialCollectionFilters,
                ...filtersAndSort,
            })),
        );
    }
}
