import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { unset } from 'lodash';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ActivityService } from '@accenture/activity/shared/domain';
import {
    DefaultFilterObject,
    Dictionary,
    errorMessageSnackbarText,
    FavoriteAccess,
    ParentType,
    PublicAccess,
    routerLinksMap,
    Template,
    TemplateTab,
    UserTemplate,
} from '@accenture/shared/data';
import { FirestoreService } from '@accenture/shared/data-access';
import { SnackbarService } from '@accenture/shared/ui';
import { sortByDateDesc } from '@accenture/shared/util';

import {
    savePublicActivityTmpAsTemplateInProgress,
    savePublicActivityTmpAsTemplateSuccessfully,
    savePublicSessionTmpAsTemplateInProgress,
    savePublicSessionTmpAsTemplateSuccess,
    savePublicTemplateTitleError,
    savePublicTemplateTitleProgress,
    savePublicTemplateTitleSuccess,
    sessionCreateFromTemplateErrorMessage,
    sessionCreateFromTemplateErrorTitle,
    sessionCreateProgressFromTemplateMessage,
    sessionCreateProgressFromTemplateTitle,
    sessionCreateSuccessFromTemplateMessage,
    sessionCreateSuccessFromTemplateTitle,
} from '../constants';
import { ProjectService } from './project.service';
import { SessionService } from './session.service';
import { UserTemplateService } from './user-template.service';
@Injectable({
    providedIn: 'root',
})
export class TemplateService {
    deletingTemplateIds$ = new BehaviorSubject<string[]>([]);

    constructor(
        private firestoreService: FirestoreService,
        private projectService: ProjectService,
        private sessionService: SessionService,
        private router: Router,
        private snackbarService: SnackbarService,
        private activityService: ActivityService,
        private userTemplateService: UserTemplateService,
    ) {}

    getTemplate(templateId: string): Observable<Template> {
        return this.firestoreService
            .getDocument<Template>(`/templates/${templateId}`)
            .pipe(map((template) => (template?.id ? new Template(template.id, template) : ({} as Template))));
    }

    getTemplates(): Observable<Template[]> {
        return this.firestoreService
            .getCollection<Template>('/templates')
            .pipe(
                map((templates) =>
                    templates.map((template) => new Template(template.id, template)).sort(sortByDateDesc('updated')),
                ),
            );
    }

    updateDescription(templateId: string, value: string): Promise<void> {
        return this.firestoreService.upsert(`/templates/${templateId}`, {
            description: value,
            updated: this.firestoreService.timestamp,
        });
    }

    updateName(templateId: string, value: string): Promise<void> {
        return this.firestoreService.upsert(`/templates/${templateId}`, {
            name: value,
            updated: this.firestoreService.timestamp,
        });
    }

    getCurrentUserAllTemplatesFilters(
        userId: string | undefined,
    ): Observable<Dictionary<DefaultFilterObject> & { tab?: TemplateTab; homePageTab?: TemplateTab }> {
        return this.firestoreService
            .getDocument<Dictionary<DefaultFilterObject>>(`/users/${userId}/filters/templatesFilters`)
            .pipe(
                map((filter: Dictionary<DefaultFilterObject>) => {
                    unset(filter, 'id');
                    return filter;
                }),
            );
    }

    getCurrentUserAllTemplatesStoreFilters(
        userId: string | undefined,
    ): Observable<Dictionary<DefaultFilterObject> & { tab?: TemplateTab }> {
        return this.firestoreService
            .getDocument<Dictionary<DefaultFilterObject>>(`/users/${userId}/filters/templatesStoreFilters`)
            .pipe(
                map((filter: Dictionary<DefaultFilterObject>) => {
                    unset(filter, 'id');
                    return filter;
                }),
            );
    }

    getFeaturedTemplatesFilters(
        userId: string | undefined,
    ): Observable<Dictionary<DefaultFilterObject> & { tab?: TemplateTab }> {
        return this.firestoreService
            .getDocument<Dictionary<DefaultFilterObject>>(`/users/${userId}/filters/featuredTemplatesFilters`)
            .pipe(
                map((filter: Dictionary<DefaultFilterObject>) => {
                    unset(filter, 'id');
                    return filter;
                }),
            );
    }

    async updateTemplatesFilters(
        userId: string | undefined,
        data: DefaultFilterObject & { tab?: TemplateTab; homePageTab?: TemplateTab },
    ): Promise<void> {
        await this.firestoreService.upsert(`users/${userId}/filters/templatesFilters`, data);
    }

    async updateTemplatesStoreFilters(
        userId: string | undefined,
        data: DefaultFilterObject & { tab?: TemplateTab },
    ): Promise<void> {
        await this.firestoreService.upsert(`users/${userId}/filters/templatesStoreFilters`, data);
    }

    async updateFeaturedTemplatesFilters(
        userId: string | undefined,
        data: DefaultFilterObject & { tab?: TemplateTab },
    ): Promise<void> {
        await this.firestoreService.upsert(`users/${userId}/filters/featuredTemplatesFilters`, data);
    }

    async resetTemplatesStoreFilters(userId: string | undefined, parentTypeTab: ParentType): Promise<void> {
        await this.firestoreService.upsert(`users/${userId}/filters/templatesStoreFilters`, {
            [parentTypeTab]: this.firestoreService.deleteField,
        });
    }

    async updateOptionsFilters(
        userId: string | undefined,
        optionType: string,
        data: string[],
        parentType: ParentType,
    ): Promise<void> {
        await this.firestoreService.upsert(`/users/${userId}/filters/templatesFilters`, {
            [parentType]: {
                [optionType]: data,
            },
        });
    }

    async updateTemplatesStoreOptionsFilters(
        userId: string,
        optionType: string,
        data: string[],
        parentType: ParentType,
    ): Promise<void> {
        await this.firestoreService.upsert(`/users/${userId}/filters/templatesStoreFilters`, {
            [parentType]: {
                [optionType]: data,
            },
        });
    }

    // TODO: remove when projects is no longer used
    async createProjectFromPublicProjectTemplate(publicAccessId: string, templateId: string, userId): Promise<void> {
        const createdProjectId = await this.projectService.createProjectFromProjectTemplate(
            ParentType.PublicProjectTemplates,
            templateId,
            userId,
            true,
            publicAccessId,
        );

        if (createdProjectId) {
            this.router.navigate(['/project', `${createdProjectId}`]);
        }
    }

    async createSessionFromPublicSessionTemplate(publicAccessId: string, templateId: string, userId): Promise<void> {
        const createdSessionId = await this.sessionService.createSessionFromSessionTemplate(
            ParentType.PublicSessionTemplates,
            templateId,
            userId,
            true,
            publicAccessId,
        );

        if (createdSessionId) {
            this.router.navigate(['/session', `${createdSessionId}`]);
        }
    }

    // Use Template (public session template & session template)
    // this is used in templates page and search
    async useTemplateSession(templateId: string, userId: string, publicAccessId?: string): Promise<void> {
        this.snackbarService.showInfoSnackBar(
            sessionCreateProgressFromTemplateTitle,
            sessionCreateProgressFromTemplateMessage,
        );
        try {
            const createdSessionId = await this.sessionService.createSessionFromSessionTemplate(
                publicAccessId ? ParentType.PublicSessionTemplates : ParentType.Templates,
                templateId,
                userId,
                true,
                publicAccessId,
            );

            if (createdSessionId) {
                this.router.navigate(['/dashboard/sessions', `${createdSessionId}`]);
            }

            this.snackbarService.showSuccessSnackBar(
                sessionCreateSuccessFromTemplateTitle,
                sessionCreateSuccessFromTemplateMessage,
            );
        } catch (e) {
            console.error(sessionCreateFromTemplateErrorMessage);
            this.snackbarService.showErrorSnackBar(
                sessionCreateFromTemplateErrorTitle,
                sessionCreateFromTemplateErrorMessage,
            );
        }
    }

    // Save to My Templates (public activity template)
    // this is used in templates page and search
    async savePublicActivityTmpAsTemplate(template: PublicAccess | FavoriteAccess): Promise<void> {
        this.snackbarService.showInfoSnackBar(
            savePublicTemplateTitleProgress,
            savePublicActivityTmpAsTemplateInProgress,
        );
        try {
            const activityTmpId = await this.activityService.saveAsTemplateNew(
                template.templateId,
                template.templateId,
                ParentType.PublicActivityTemplates,
                true,
            );
            const redirectUrl = this.getTemplateLink(template, activityTmpId);
            this.router.navigateByUrl(redirectUrl);
            this.snackbarService.showSuccessSnackBar(
                savePublicTemplateTitleSuccess,
                savePublicActivityTmpAsTemplateSuccessfully,
            );
        } catch (e) {
            console.error(savePublicTemplateTitleError);
            this.snackbarService.showErrorSnackBar(savePublicTemplateTitleError, errorMessageSnackbarText);
        }
    }

    // Save to My Templates (public session template)
    // this is used in templates page and search
    async savePublicSessionTmpAsTemplate(template: PublicAccess | FavoriteAccess): Promise<void> {
        this.snackbarService.showInfoSnackBar(
            savePublicTemplateTitleProgress,
            savePublicSessionTmpAsTemplateInProgress,
        );
        try {
            const sessionTmpId = await this.sessionService.saveAsTemplateNew(
                template.templateId,
                ParentType.Templates,
                ParentType.PublicSessionTemplates,
                true,
            );
            const redirectUrl = this.getTemplateLink(template, sessionTmpId);
            this.router.navigateByUrl(redirectUrl);
            this.snackbarService.showSuccessSnackBar(
                savePublicTemplateTitleSuccess,
                savePublicSessionTmpAsTemplateSuccess,
            );
        } catch (e) {
            console.error(savePublicTemplateTitleError);
            this.snackbarService.showErrorSnackBar(savePublicTemplateTitleError, errorMessageSnackbarText);
        }
    }

    getTemplateLink(template: PublicAccess | FavoriteAccess, templateId?: string): string {
        if (template.templateType === ParentType.PublicActivityTemplates) {
            return `/${routerLinksMap[ParentType.ActivityTemplates]}/${template.activityType.toLowerCase()}-edit/${
                templateId || template.templateId
            }`;
        }

        return `/${routerLinksMap[ParentType.Templates]}/${templateId || template.templateId}`;
    }

    getTemplatesByUserId(user: string): Observable<UserTemplate[]> {
        return combineLatest([this.userTemplateService.getTemplatesByUserIdNew(user), this.deletingTemplateIds$]).pipe(
            map(([allTemplates, deletingTemplateIds]) =>
                allTemplates?.filter((template) => !deletingTemplateIds.includes(template.templateId)),
            ),
        );
    }
}
