import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'accenture-session-creation-dropdown',
    templateUrl: './session-creation-dropdown.component.html',
    styleUrls: ['./session-creation-dropdown.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SessionCreationDropdownComponent {
    createSessionMenuOpen!: boolean;
    @Input() buttonText!: string;
    @Input() isSessionPageButton?: boolean;
    @Output() buttonClick = new EventEmitter<string>();
}
