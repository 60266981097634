import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { combineLatest, map, Observable, startWith, switchMap } from 'rxjs';

import {
    FiltersService,
    OptionsStore,
    ProjectOptionsService,
    SessionOptionsService,
} from '@accenture/erp-deployment/shared/domain';
import { AppState, selectAuthenticatedUserId } from '@accenture/global-store';
import {
    CollectionOptions,
    CollectionSortOptions,
    CollectionsSortObject,
    Dictionary,
    OptionsForSortingByNumberOfUses,
    ProjectOptions,
    SelectedSessionOptions,
    SessionOptions,
    SessionOptionsType,
    SortOrders,
} from '@accenture/shared/data';

export interface SessionOptionsViewModel {
    selectedSessionOptions: SelectedSessionOptions;
    phaseOptions: SessionOptionsType[];
    subPhaseOptions: SessionOptionsType[];
    projectOptions: SessionOptionsType[];
    clientOptions: SessionOptionsType[];
    tags: SessionOptionsType[];
    currentOptionToDisplay: SessionOptions | ProjectOptions | CollectionOptions | null;
    sortCollectionsData: CollectionsSortObject;
    isLoading: boolean;
}

const defaultSessionOptionsModel = {
    selectedSessionOptions: {} as SelectedSessionOptions,
    phaseOptions: [],
    subPhaseOptions: [],
    projectOptions: [],
    clientOptions: [],
    tags: [],
    currentOptionToDisplay: null,
    sortCollectionsData: {} as CollectionsSortObject,
    isLoading: true,
};

@Injectable()
export class SessionOptionsFacade {
    vm$ = this.buildViewModel();

    private userId: string;
    private currentOptionToDisplay: SessionOptions | null;
    // all options from db
    private sessionOptionsValues = {
        phase: [],
        subPhase: [],
        client: [],
        project: [],
        tags: [],
    };
    private sessionOptionsToCreateIds: { [name: string]: string[] } = {
        phase: [],
        subPhase: [],
        tags: [],
    };
    private initialModalLoad = true

    constructor(
        private store: Store<AppState>,
        private sessionOptionsService: SessionOptionsService,
        private optionsStore: OptionsStore,
        private projectOptionsService: ProjectOptionsService,
        private filtersService: FiltersService,
    ) {}

    showOptionChips(optionType: SessionOptions): void {
        this.optionsStore.setCurrentOptionToDisplay(optionType);
    }

    updateSessionOptions(option: Dictionary<string>, currentType?: SessionOptions): void {
        const optionType = currentType || this.currentOptionToDisplay;
        const optionId = Object.keys(option)[0];

        this.optionsStore.setSelectedSessionOption({ option, optionType });

        if (this.sessionOptionsToCreateIds[optionType]?.includes(optionId)) {
            this.optionsStore.removeSessionOptionToCreate({ optionId, optionType });
        }
    }

    updateSessionOptionsToCreate(option: SessionOptionsType, optionType?: SessionOptions): void {
        const currentOptionToDisplay = optionType || this.currentOptionToDisplay;
        const data = {
            option,
            optionType: currentOptionToDisplay,
        };
        this.optionsStore.setSessionOptionToCreate(data);
    }

    getFilteredOptions(
        sessionOption: SessionOptions,
        selectedOptions: Dictionary<string>,
        value: string,
    ): { filteredOptions: SessionOptionsType[]; isOptionExist: boolean } {
        let isOptionExist = false;
        const filteredOptions = this.sessionOptionsValues[sessionOption].filter((option) => {
            const optionName = option.name?.toLowerCase().trim();

            if (optionName === value) {
                isOptionExist = true;
            }

            return optionName?.includes(value) && !Object.keys(selectedOptions || {})?.includes(option.id);
        });

        return {
            isOptionExist,
            filteredOptions,
        };
    }

    async addNewOption(optionType: SessionOptions, optionName: string): Promise<void> {
        const id = this.sessionOptionsService.getNewOptionId();
        const newOption = {
            [id]: optionName,
        };

        const optionToCreate = {
            id,
            name: optionName,
            sessions: [],
        } as SessionOptionsType;

        this.updateSessionOptions(newOption, optionType);
        this.updateSessionOptionsToCreate(optionToCreate, optionType);
    }

    async updateFilters(filters: CollectionsSortObject): Promise<void> {
        this.initialModalLoad = false;

        await this.projectOptionsService.updateCollectionsSorting(this.userId, filters);
    }

    private buildViewModel(): Observable<SessionOptionsViewModel> {
        return this.store.select(selectAuthenticatedUserId).pipe(
            switchMap((userId) => {
                this.userId = userId;

                return combineLatest([
                    this.optionsStore.selectedSessionOptions$,
                    this.sessionOptionsService.getOptions(SessionOptions.SessionClient),
                    this.sessionOptionsService.getOptions(SessionOptions.SessionProject),
                    this.sessionOptionsService.getOptions(SessionOptions.Phase),
                    this.sessionOptionsService.getOptions(SessionOptions.SubPhase),
                    this.sessionOptionsService.getOptions(SessionOptions.Tag),
                    this.optionsStore.currentOptionToDisplay$,
                    this.optionsStore.sessionOptionsToCreateIds$,
                    this.optionsStore.sessionOptionsToCreate$,
                    this.projectOptionsService.getCurrentUserCollectionsSorting(userId),
                ]).pipe(
                    map(
                        ([
                            selectedSessionOptions,
                            clientOptions,
                            projectOptions,
                            phaseOptions,
                            subPhaseOptions,
                            tags,
                            currentOptionToDisplay,
                            sessionOptionsToCreateIds,
                            sessionOptionsToCreate,
                            sortCollectionsData,
                        ]) => {
                            this.currentOptionToDisplay = currentOptionToDisplay as SessionOptions | null;
                            this.sessionOptionsToCreateIds = sessionOptionsToCreateIds;
                            this.sessionOptionsValues = {
                                phase: phaseOptions,
                                subPhase: subPhaseOptions,
                                tags: [...tags, ...sessionOptionsToCreate.tags],
                                client: clientOptions,
                                project: projectOptions,
                            };

                            let options = {
                                tags: [...tags, ...sessionOptionsToCreate.tags],
                                subPhaseOptions: [...subPhaseOptions, ...sessionOptionsToCreate.subPhase],
                                phaseOptions: [...phaseOptions, ...sessionOptionsToCreate.phase],
                                clientOptions: [...clientOptions, ...sessionOptionsToCreate.client],
                                projectOptions: [...projectOptions, ...sessionOptionsToCreate.project],
                            } as Dictionary<SessionOptionsType[]>;

                            let sortingOrderOption: CollectionsSortObject = sortCollectionsData;

                            if(!currentOptionToDisplay) {
                                this.initialModalLoad = true;
                            }

                            if([SessionOptions.SessionProject].includes(currentOptionToDisplay as SessionOptions) && this.initialModalLoad) {
                                sortingOrderOption = {
                                    ...sortingOrderOption,
                                    sortOrder: SortOrders.Asc,
                                    sortOption: CollectionSortOptions.Name
                                }
                            }

                            if (currentOptionToDisplay) {
                                options = this.sortDataToDisplay(
                                    sortingOrderOption,
                                    options.phaseOptions,
                                    options.subPhaseOptions,
                                    options.tags,
                                    options.clientOptions,
                                    options.projectOptions,
                                );
                            }

                            return {
                                sortCollectionsData: sortingOrderOption,
                                currentOptionToDisplay,
                                selectedSessionOptions,
                                tags: options.tags,
                                phaseOptions: options.phaseOptions,
                                subPhaseOptions: options.subPhaseOptions,
                                clientOptions: options.clientOptions,
                                projectOptions: options.projectOptions,
                                isLoading: false,
                            };
                        },
                    ),
                );
            }),
            startWith(defaultSessionOptionsModel),
        );
    }

    private sortDataToDisplay(
        sortCollectionsData: CollectionsSortObject,
        phaseOptions: SessionOptionsType[],
        subPhaseOptions: SessionOptionsType[],
        tags: SessionOptionsType[],
        clientOptions: SessionOptionsType[],
        projectOptions: SessionOptionsType[],
    ): Dictionary<SessionOptionsType[]> {
        return {
            phaseOptions: this.sortSessionOptions(phaseOptions, sortCollectionsData) || [],
            subPhaseOptions: this.sortSessionOptions(subPhaseOptions, sortCollectionsData) || [],
            tags: this.sortSessionOptions(tags, sortCollectionsData) || [],
            clientOptions: this.sortSessionOptions(clientOptions, sortCollectionsData) || [],
            projectOptions: this.sortSessionOptions(projectOptions, sortCollectionsData) || [],
        };
    }

    private sortSessionOptions(
        options: SessionOptionsType[],
        sortOptions: CollectionsSortObject,
    ): SessionOptionsType[] {
        return this.filtersService.sortOptionsData(options, sortOptions, OptionsForSortingByNumberOfUses.Sessions);
    }
}
