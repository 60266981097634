<accenture-base-snack-bar
  [title]="data.title"
  [iconClass]="data.iconClass"
  [iconName]="data.iconName"
  [actionButtonClass]="data.actionButtonClass"
  [isVisibleDoNotShowAgain]="data.isVisibleDoNotShowAgain"
  (snackbarClosed)="onClose()"
  (doNotShowAgain)="onDoNotShowAgain($event)"
>
  <ul class="tt9-snack-bar-list tt9-body-2">
    <li *ngFor="let item of data.listItems; trackBy: trackByValue">
      {{ item }}
    </li>
  </ul>
</accenture-base-snack-bar>
