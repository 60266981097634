<ng-container *ngIf="(showComingSoonPage$ | async) === true || (showComingSoonPageOnDashboard$ | async) === true">
  <div class="coming-soon">
    <div class="coming-soon-empty-container">
      <div class="coming-soon-empty-wrapper">
        <accenture-empty-screen
          [title]="emptyScreenTitles.noAdaptiveDesignYet"
          [description]="emptyScreenDescriptions.noAdaptiveDesignYet"
          [imageUrl]="emptyScreenImageUrlWebPage"
        ></accenture-empty-screen>
      </div>

      <button class="tt9-btn btn-rounded btn-with-border" (click)="back()">
        <span>Continue To View On Mobile</span>
      </button>
    </div>
  </div>
</ng-container>
