import { removeEmptyKeys } from '@accenture/shared/util';

import { TemplateRole } from '../access-control';
import { Activity, ActivityType } from '../activities';
import { ParentType } from '../parent-type';
import { Project } from '../project';
import { Session } from '../session';
import { User } from '../user';
import { InAppNotificationType } from './in-app-notification-type';
import { BaseInAppNotification } from './in-app-notifications';

export class InAppTemplateInviteNotification extends BaseInAppNotification {
    templateId: string;
    templateName: string;
    templateType: ParentType;
    invitingUserId?: string;
    invitingUserDisplayName: string;
    role: TemplateRole;
    activityType?: ActivityType;

    constructor(notification: any);
    constructor(
        userId: string,
        data: Project | Session | Activity,
        templateType: ParentType,
        role: TemplateRole,
        user: Partial<User>,
        activityType: ActivityType | null,
    );
    constructor(
        notificationOrUserId: any,
        data?: Project | Session | Activity,
        templateType?: ParentType,
        role?: TemplateRole,
        user?: Partial<User>,
        activityType?: ActivityType | null,
    ) {
        if (typeof notificationOrUserId === 'object') {
            super(notificationOrUserId as BaseInAppNotification);

            this.id = notificationOrUserId.id;
            this.type = InAppNotificationType.TemplateInvite;
            this.templateId = notificationOrUserId.templateId;
            this.templateName = notificationOrUserId.templateName;
            this.templateType = notificationOrUserId.templateType;
            this.invitingUserId = notificationOrUserId.invitingUserId;
            this.invitingUserDisplayName = notificationOrUserId.invitingUserDisplayName;
            this.role = notificationOrUserId.role;
            this.activityType = notificationOrUserId.activityType;
        } else {
            super(InAppNotificationType.TemplateInvite, notificationOrUserId);

            this.templateId = data.id;
            this.templateName = data.name;
            this.templateType = templateType;
            this.invitingUserId = user.id;
            this.invitingUserDisplayName = user.displayName;
            this.role = role;
            this.activityType = activityType;
        }
    }

    createSerializableObject(): Record<string, unknown> {
        return removeEmptyKeys({
            type: this.type,
            userId: this.userId,
            unread: this.unread,
            templateId: this.templateId,
            templateName: this.templateName,
            templateType: this.templateType,
            invitingUserId: this.invitingUserId,
            invitingUserDisplayName: this.invitingUserDisplayName,
            role: this.role,
            created: this.created,
            updated: this.updated,
            activityType: this.activityType,
        });
    }
}
