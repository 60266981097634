import { Injectable } from '@angular/core';
import { groupBy } from 'lodash';
import { firstValueFrom, Observable } from 'rxjs';

import { BrainstormResponse, Comment, DBPathHelper, ParentType } from '@accenture/shared/data';
import { FirestoreService } from '@accenture/shared/data-access';

import { BrainstormGroupsService } from './brainstorm-groups.service';

@Injectable({
    providedIn: 'root',
})
export class ActivityCommentsService {
    constructor(private firestoreService: FirestoreService, private brainstormGroupsService: BrainstormGroupsService) {}
   // TODO: Delete after project  deprecation
    getProjectCommentsPath(projectId: string, commentId?: string): string {
        return `projects/${projectId}/comments` + (commentId ? `/${commentId}` : '');
    }

    getSessionCommentsPath(sessionId: string, commentId?: string): string {
        return `sessions/${sessionId}/comments` + (commentId ? `/${commentId}` : '');
    }
   // TODO: Delete after project  deprecation
    getProjectResponsePath(projectId: string, responseId?: string): string {
        return `projects/${projectId}/responses` + (responseId ? `/${responseId}` : '');
    }
      
    getSessionResponsePath(sessionId: string, responseId?: string): string {
        return `sessions/${sessionId}/responses` + (responseId ? `/${responseId}` : '');
    }

       // TODO: Delete after project  deprecation
    async addCommentToResponse(projectId: string, comment: Comment): Promise<string> {
        const commentId = this.firestoreService.getPushId();
        const serializableComment = comment.createSerializableObject();
        const fanOutWrites = [
            {
                path: this.getProjectCommentsPath(projectId, commentId),
                data: serializableComment,
            },
            {
                path: this.getProjectResponsePath(projectId, serializableComment.responseId),
                data: { commentsCount: this.firestoreService.changeCounterValue(1) },
            },
        ];
        await this.firestoreService.writeBatch(fanOutWrites);
        return commentId;
    }


    async addCommentToResponseNew(sessionId: string, comment: Comment): Promise<string> {
        const commentId = this.firestoreService.getPushId();
        const serializableComment = comment.createSerializableObject();
        const fanOutWrites = [
            {
                path: this.getSessionCommentsPath(sessionId, commentId),
                data: serializableComment,
            },
            {
                path: this.getSessionResponsePath(sessionId, serializableComment.responseId),
                data: { commentsCount: this.firestoreService.changeCounterValue(1) },
            },
        ];
        await this.firestoreService.writeBatch(fanOutWrites);
        return commentId;
    }
 // TODO: Delete after project  deprecation
    deleteComment(projectId: string, comment: Comment): Promise<void> {
        const batch = this.firestoreService.createBatch();
        const db = this.firestoreService.firestoreRef.firestore;
        batch.delete(db.doc(this.getProjectCommentsPath(projectId, comment.id)));
        batch.update(db.doc(this.getProjectResponsePath(projectId, comment.responseId)), {
            commentsCount: this.firestoreService.changeCounterValue(-1),
        });
        return batch.commit();
    }

    deleteCommentNew(sessionId: string, comment: Comment): Promise<void> {
        const batch = this.firestoreService.createBatch();
        const db = this.firestoreService.firestoreRef.firestore;
        batch.delete(db.doc(this.getSessionCommentsPath(sessionId, comment.id)));
        batch.update(db.doc(this.getSessionResponsePath(sessionId, comment.responseId)), {
            commentsCount: this.firestoreService.changeCounterValue(-1),
        });
        return batch.commit();
    }
     // TODO: Delete after project  deprecation
    async deleteComments(
        projectId: string,
        activityItemId: string,
        commentsIds?: string[],
        comments?: Comment[],
    ): Promise<void> {
        const selectedComments = comments?.length
            ? comments
            : (await firstValueFrom(this.getCommentsByActivityItemId(projectId, activityItemId))).filter(comment =>
                  commentsIds.includes(comment.id),
              );
        const dataForUpdate = [];
        const dataForDelete = [];

        selectedComments.forEach(comment => {
            dataForDelete.push({ path: this.getProjectCommentsPath(projectId, comment.id) });

            dataForUpdate.push({
                path: this.getProjectResponsePath(projectId, comment.responseId),
                data: { commentsCount: this.firestoreService.changeCounterValue(-1) },
            });
        });

        await this.firestoreService.deleteBatch(dataForDelete);
        await this.firestoreService.updateBatch(dataForUpdate);
    }

    async deleteCommentsNew(
        sessionId: string,
        activityItemId: string,
        commentsIds?: string[],
        comments?: Comment[],
    ): Promise<void> {
        const selectedComments = comments?.length
            ? comments
            : (await firstValueFrom(this.getCommentsByActivityItemIdNew(sessionId, activityItemId))).filter(comment =>
                  commentsIds.includes(comment.id),
              );
        const dataForUpdate = [];
        const dataForDelete = [];

        selectedComments.forEach(comment => {
            dataForDelete.push({ path: this.getSessionCommentsPath(sessionId, comment.id) });

            dataForUpdate.push({
                path: this.getSessionResponsePath(sessionId, comment.responseId),
                data: { commentsCount: this.firestoreService.changeCounterValue(-1) },
            });
        });

        await this.firestoreService.deleteBatch(dataForDelete);
        await this.firestoreService.updateBatch(dataForUpdate);
    }
 // TODO: Delete after project  deprecation
    getCommentsForResponseOrderByUpdated(projectId: string, responseId: string): Observable<Comment[]> {
        return this.firestoreService.getDocumentsByProperty<Comment>(
            this.getProjectCommentsPath(projectId),
            'responseId',
            responseId,
            'updated',
        );
    }

 
    getCommentsForResponseOrderByUpdatedNew(sessionId: string, responseId: string): Observable<Comment[]> {
        return this.firestoreService.getDocumentsByProperty<Comment>(
            this.getSessionCommentsPath(sessionId),
            'responseId',
            responseId,
            'updated',
        );
    }
 // TODO: Delete after project  deprecation
    updateCommentValue(projectId: string, commentId: string, value: string): Promise<void> {
        return this.firestoreService.updateDoc(this.getProjectCommentsPath(projectId, commentId), { value });
    }

 
    updateCommentValueNew(sessionId: string, commentId: string, value: string): Promise<void> {
        return this.firestoreService.updateDoc(this.getSessionCommentsPath(sessionId, commentId), { value });
    }
     // TODO: Delete after project  deprecation
    getCommentsByActivityItemId(projectId: string, activityItemId: string): Observable<Comment[]> {
        return this.firestoreService.getDocumentsByProperty<Comment>(
            this.getProjectCommentsPath(projectId),
            'activityItemId',
            activityItemId,
        );
    }

    getCommentsByActivityItemIdNew(sessionId: string, activityItemId: string): Observable<Comment[]> {
        return this.firestoreService.getDocumentsByProperty<Comment>(
            this.getSessionCommentsPath(sessionId),
            'activityItemId',
            activityItemId,
        );
    }
     // TODO: Delete after project  deprecation
    getCommentsByActivityItemIdWithoutCaching(projectId: string, activityItemId: string): Observable<Comment[]> {
        return this.firestoreService.getDocumentsByPropertyWithoutCaching<Comment>(
            this.getProjectCommentsPath(projectId),
            'activityItemId',
            activityItemId,
        );
    }

 
    getCommentsByActivityItemIdWithoutCachingNew(sessionId: string, activityItemId: string): Observable<Comment[]> {
        return this.firestoreService.getDocumentsByPropertyWithoutCaching<Comment>(
            this.getSessionCommentsPath(sessionId),
            'activityItemId',
            activityItemId,
        );
    }
     // TODO: Delete after project  deprecation
    async promoteCommentsTo(
        projectId: string,
        baseResponseObj: Partial<BrainstormResponse>,
        commentsIds: string[],
        responses: BrainstormResponse[],
    ): Promise<void> {
        const dataToWrite = [];
        let groupsToUpdate = {};
        const selectedComments = (
            await firstValueFrom(this.getCommentsByActivityItemId(projectId, baseResponseObj.activityItemId))
        ).filter(comment => commentsIds.includes(comment.id));
        const responsesMap = groupBy(responses, 'id');

        await this.deleteComments(projectId, baseResponseObj.activityItemId, [], selectedComments);

        selectedComments.forEach(comment => {
            const currentsGroupsIds = responsesMap[comment.responseId][0]?.groups;

            const newResponseObj = {
                ...baseResponseObj,
                userId: comment.userId,
                userDisplayName: comment.userDisplayName,
                userImageUrl: comment.userImageUrl,
                activityId: comment.activityId,
                value: comment.value,
                groups: currentsGroupsIds || [],
            };
            const newResponse = new BrainstormResponse(newResponseObj);

            if (currentsGroupsIds.length) {
                currentsGroupsIds.forEach(groupId => {
                    groupsToUpdate = {
                        ...groupsToUpdate,
                        [groupId]: groupsToUpdate[groupId] + 1 || 1,
                    };
                });
            }

            dataToWrite.push({
                path: this.getProjectResponsePath(projectId, comment.id),
                data: newResponse.createSerializableObject(),
            });
        });

        await this.brainstormGroupsService.updateGroupsCount(projectId, baseResponseObj.activityItemId, groupsToUpdate);

        await this.firestoreService.setBatch(dataToWrite);
    }

    async promoteCommentsToNew(
        sessionId: string,
        baseResponseObj: Partial<BrainstormResponse>,
        commentsIds: string[],
        responses: BrainstormResponse[],
    ): Promise<void> {
        const dataToWrite = [];
        let groupsToUpdate = {};
        const selectedComments = (
            await firstValueFrom(this.getCommentsByActivityItemIdNew(sessionId, baseResponseObj.activityItemId))
        ).filter(comment => commentsIds.includes(comment.id));
        const responsesMap = groupBy(responses, 'id');

        await this.deleteCommentsNew(sessionId, baseResponseObj.activityItemId, [], selectedComments);

        selectedComments.forEach(comment => {
            const currentsGroupsIds = responsesMap[comment.responseId][0]?.groups;

            const newResponseObj = {
                ...baseResponseObj,
                userId: comment.userId,
                userDisplayName: comment.userDisplayName,
                userImageUrl: comment.userImageUrl,
                activityId: comment.activityId,
                value: comment.value,
                groups: currentsGroupsIds || [],
            };
            const newResponse = new BrainstormResponse(newResponseObj);

            if (currentsGroupsIds.length) {
                currentsGroupsIds.forEach(groupId => {
                    groupsToUpdate = {
                        ...groupsToUpdate,
                        [groupId]: groupsToUpdate[groupId] + 1 || 1,
                    };
                });
            }

            dataToWrite.push({
                path: this.getSessionResponsePath(sessionId, comment.id),
                data: newResponse.createSerializableObject(),
            });
        });

        await this.brainstormGroupsService.updateGroupsCountNew(sessionId, baseResponseObj.activityItemId, groupsToUpdate);

        await this.firestoreService.setBatch(dataToWrite);
    }

     // TODO: Delete after project  deprecation
    async mergeComments(
        responseId: string,
        selectedQuestionId: string,
        projectId: string,
        commentsToMerge: Set<string>,
    ): Promise<void> {
        const dataToUpdate = [];
        const selectedComments = (
            await firstValueFrom(this.getCommentsByActivityItemId(projectId, selectedQuestionId))
        ).filter(comment => commentsToMerge.has(comment.id));

        selectedComments.forEach((comment: Comment) => {
            dataToUpdate.push(
                {
                    path: this.getProjectCommentsPath(projectId, comment.id),
                    data: {
                        ...comment,
                        responseId,
                        created: this.firestoreService.currentTimestamp,
                        updated: this.firestoreService.currentTimestamp,
                    },
                },
                {
                    path: DBPathHelper.getResponsesPath(ParentType.Projects, projectId, comment.responseId),
                    data: {
                        commentsCount: this.firestoreService.changeCounterValue(-1),
                    },
                },
            );
        });
        dataToUpdate.push({
            path: DBPathHelper.getResponsesPath(ParentType.Projects, projectId, responseId),
            data: {
                commentsCount: this.firestoreService.changeCounterValue(selectedComments.length),
            },
        });

        await this.firestoreService.updateBatch(dataToUpdate);
    }

    async mergeCommentsNew(
        responseId: string,
        selectedQuestionId: string,
        sessionId: string,
        commentsToMerge: Set<string>,
    ): Promise<void> {
        const dataToUpdate = [];
        const selectedComments = (
            await firstValueFrom(this.getCommentsByActivityItemIdNew(sessionId, selectedQuestionId))
        ).filter(comment => commentsToMerge.has(comment.id));

        selectedComments.forEach((comment: Comment) => {
            dataToUpdate.push(
                {
                    path: this.getSessionCommentsPath(sessionId, comment.id),
                    data: {
                        ...comment,
                        responseId,
                        created: this.firestoreService.currentTimestamp,
                        updated: this.firestoreService.currentTimestamp,
                    },
                },
                {
                    path: DBPathHelper.getResponsesPath(ParentType.Sessions, sessionId, comment.responseId),
                    data: {
                        commentsCount: this.firestoreService.changeCounterValue(-1),
                    },
                },
            );
        });
        dataToUpdate.push({
            path: DBPathHelper.getResponsesPath(ParentType.Sessions, sessionId, responseId),
            data: {
                commentsCount: this.firestoreService.changeCounterValue(selectedComments.length),
            },
        });

        await this.firestoreService.updateBatch(dataToUpdate);
    }
}
