import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { MsGraphService } from './services/ms-graph.service';
import { MsIdentityService } from './services/ms-identity.service';
import { TeamsService } from './services/teams.service';

@NgModule({
    imports: [CommonModule],
    providers: [MsGraphService, TeamsService, MsIdentityService],
})
export class MicrosoftModule {}
