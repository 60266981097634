import { Injectable, signal } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { isEqual } from 'lodash';
import { BehaviorSubject, combineLatest, firstValueFrom, Observable, of } from 'rxjs';
import { catchError, distinctUntilChanged, filter, map, startWith, switchMap, take } from 'rxjs/operators';

import { CollectionOptionsService, CollectionsService, OptionsStore } from '@accenture/erp-deployment/shared/domain';
import { AppState, selectAuthenticatedUser } from '@accenture/global-store';
import { Collection, collectionOptionsArray, FileType, SelectedCollectionOptions } from '@accenture/shared/data';
import { SnackbarService } from '@accenture/shared/ui';

import { EditCollectionDialogComponent } from './edit-collection-dialog.component';

export interface EditCollectionDialogViewModel {
    collection: Collection;
    collectionImage: FileType;
    isCollectionUpdated: boolean;
    isLoading: boolean;
    isSaving: boolean;
}

export const defaultViewModel = {
    collection: {} as Collection,
    collectionImage: {} as FileType,
    isCollectionUpdated: false,
    isLoading: false,
    isSaving: false,
};

@Injectable()
export class EditCollectionDialogFacade {
    private isLoading$ = new BehaviorSubject<boolean>(false);
    private isSaving$ = new BehaviorSubject<boolean>(false);
    private collectionImage$ = new BehaviorSubject<FileType>(undefined);
    private isCollectionUpdated$ = new BehaviorSubject<boolean>(true);
    private collectionId = signal<string>(null);
    private userId = signal<string>(null);

    vm$ = this.buildViewModel();

    private savedCollectionOptions!: SelectedCollectionOptions;
    private hasSelectedOptions = false;

    constructor(
        private store: Store<AppState>,
        private collectionService: CollectionsService,
        private collectionOptionsService: CollectionOptionsService,
        private dialogRef: MatDialogRef<EditCollectionDialogComponent>,
        private optionsStore: OptionsStore,
        private snackbarService: SnackbarService,
    ) {}

    setCollectionId(collectionId: string) {
        this.collectionId.set(collectionId);
    }

    closeDialog(): void {
        this.dialogRef.close();
    }

    backToCollection(): void {
        this.optionsStore.setCurrentOptionToDisplay(null);
    }

    resetOptionsStore(): void {
        this.optionsStore.resetCollectionOptions();
        this.optionsStore.resetCollectionOptionsToCreate();
        this.optionsStore.setCurrentOptionToDisplay(null);
    }

    setCollectionImage(collectionImage: FileType): void {
        this.collectionImage$.next(collectionImage);
    }

    initializeCollection(): void {
        this.isCollectionUpdated$.next(false);
    }

    async updateCollection(collectionDetails: Partial<Collection>): Promise<void> {
        this.isSaving$.next(true);
        this.isLoading$.next(true);
        const collectionOptions = await firstValueFrom(this.optionsStore.selectedCollectionOptions$.pipe(take(1)));

        const collectionData = {
            name: collectionDetails.name,
            description: collectionDetails.description,
            color: collectionDetails.color,
            tags: collectionOptions.tags || {},
        } as Partial<Collection>;

        const collectionImage = this.collectionImage$.getValue();
        if (collectionImage) {
            collectionData.imageUrl = collectionImage.url || null;
            collectionData.imageId = collectionImage.id || null;
        }

        try {
            await this.collectionService.updateCollectionDocument(this.collectionId(), this.userId(), collectionData);
            await this.updateOptions(collectionOptions, this.collectionId());

            this.closeDialog();
        } catch (e) {
            this.snackbarService.showErrorSnackBar('Error', 'Error saving collection');
            console.error(e);
        } finally {
            this.isLoading$.next(false);
            this.isSaving$.next(false);
        }
    }

    private buildViewModel(): Observable<EditCollectionDialogViewModel> {
        return toObservable(this.collectionId).pipe(
            distinctUntilChanged(),
            filter((collectionId: string) => !!collectionId),
            switchMap((collectionId: string) => {
                return combineLatest({
                    collection: this.getCollection(collectionId),
                    user: this.store.select(selectAuthenticatedUser),
                    collectionImage: this.collectionImage$.asObservable(),
                    isLoading: this.isLoading$.asObservable().pipe(distinctUntilChanged()),
                    isSaving: this.isSaving$.asObservable().pipe(distinctUntilChanged()),
                    isCollectionUpdated: this.isCollectionUpdated$.asObservable().pipe(distinctUntilChanged()),
                    currentOptionToDisplay: this.optionsStore.currentOptionToDisplay$,
                }).pipe(
                    map(
                        ({
                            collection,
                            user,
                            collectionImage,
                            isLoading,
                            isSaving,
                            isCollectionUpdated,
                            currentOptionToDisplay,
                        }) => {
                            this.userId.set(user.id);

                            if (!this.hasSelectedOptions) {
                                const options = {
                                    tags: collection?.tags || {},
                                };
                                this.savedCollectionOptions = options;
                                this.optionsStore.setSelectedCollectionOptions(options);
                                this.hasSelectedOptions = true;
                            }

                            const oldCollectionImage = {
                                url: collection?.imageUrl,
                                id: collection?.imageId,
                            } as FileType;

                            return {
                                collection,
                                isLoading,
                                isSaving,
                                currentOptionToDisplay,
                                isCollectionUpdated,
                                collectionImage: collectionImage || oldCollectionImage,
                            };
                        },
                    ),
                );
            }),
            startWith(defaultViewModel),
            catchError(() => {
                return of({
                    ...defaultViewModel,
                    isLoading: false,
                });
            }),
        );
    }

    private getCollection(collectionId: string): Observable<Collection> {
        return this.collectionService.getCollectionById(collectionId).pipe(
            distinctUntilChanged((previousPayload: Collection, currentPayload: Collection) => {
                if (isEqual(previousPayload, currentPayload)) {
                    return true;
                }

                this.isCollectionUpdated$.next(true);
                return false;
            }),
        );
    }

    private async updateOptions(
        selectedCollectionOptions: SelectedCollectionOptions,
        sessionId: string,
    ): Promise<void> {
        const optionsToCreateIds = await firstValueFrom(this.optionsStore.collectionOptionsToCreateIds$.pipe(take(1)));

        const optionsToRemove = collectionOptionsArray.reduce((acc, optionType) => {
            acc[optionType] = Object.keys(this.savedCollectionOptions?.[optionType] || {}).filter(
                (id) => !Object.keys(selectedCollectionOptions[optionType] || {}).includes(id),
            );

            return acc;
        }, {});

        await this.collectionOptionsService.updateOptions(
            selectedCollectionOptions,
            sessionId,
            optionsToRemove,
            optionsToCreateIds,
        );
    }
}
