import { ActivityItem } from './activity-item';
import { ActivityItemType } from './activity-item-type';
import { Table } from './table';
import {
    Attachment,
    BrainstormTopic,
    Date,
    File,
    Image,
    LiveShareResource,
    LongText,
    Number,
    Numeric,
    OptionsList,
    PollQuestion,
    Prompt,
    ShortText,
    Slate,
    Slide,
    Slider,
    SparkPrompt,
    StarVoting,
    Text,
    TopX,
    Video,
} from './';

export class ActivityItemFactory {
    static create(data: any): ActivityItem {
        switch (data.type) {
            case ActivityItemType.Numeric:
                return new Numeric(data);
            case ActivityItemType.Slider:
                return new Slider(data);
            case ActivityItemType.StarVoting:
                return new StarVoting(data);
            case ActivityItemType.Text:
                return new Text(data);
            case ActivityItemType.ShortText:
                return new ShortText(data);
            case ActivityItemType.LongText:
                return new LongText(data);
            case ActivityItemType.SingleSelect:
            case ActivityItemType.MultiSelect:
                return new OptionsList(data);
            case ActivityItemType.Number:
                return new Number(data);
            case ActivityItemType.TopX:
                return new TopX(data);
            case ActivityItemType.Date:
                return new Date(data);
            case ActivityItemType.Video:
                return new Video(data);
            case ActivityItemType.Image:
                return new Image(data);
            case ActivityItemType.Attachment:
                return new Attachment(data);
            case ActivityItemType.File:
                return new File(data);
            case ActivityItemType.Slide:
                return new Slide(data);
            case ActivityItemType.PollQuestion:
                return new PollQuestion(data);
            case ActivityItemType.Table:
                return new Table(data);
            case ActivityItemType.Prompt:
                return new Prompt(data);
            case ActivityItemType.SparkPrompt:
                return new SparkPrompt(data);
            case ActivityItemType.BrainstormTopic:
                return new BrainstormTopic(data);
            case ActivityItemType.LiveShareResource:
                return new LiveShareResource(data);
            case ActivityItemType.Slate:
                return new Slate(data);
            default:
                throw new Error('invalid graph node type');
        }
    }
}
