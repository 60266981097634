<ng-container *ngIf="vm$ | async as vm">
  <ng-container *accentureLoaded="!vm.isLoading">
    <div class="confirmation-dialog p-16">
      <div>
        <div class="confirmation-dialog-header">
          <p class="tt9-body-medium-1 text-overflow-ellipsis-1 mb-8 confirmation-dialog-title">
            {{ vm.title }}
          </p>
          <p class="tt9-body-2 confirmation-dialog-description">
            {{ vm.description }}
          </p>
        </div>
  
        <button class="tt9-btn-icon confirmation-dialog-close" (click)="closeDialog()">
          <accenture-icon>icon-close</accenture-icon>
        </button>
      
        <div class="publish-dialog-content">
          <div *ngIf="!!vm.teamMembers.length" class="user-list d-flex-column">
            <ng-container *ngFor="let user of vm.teamMembers; trackBy: trackById">
              <div class="user-list-item">
                <div class="user-list-userpic normal">
                  <div class="tt9-userpic tt9-tooltip">
                    <img *ngIf="user.imageUrl" [src]="user.imageUrl" [alt]="user.displayName" />
                    <ng-container *ngIf="!user.imageUrl">{{ user.displayName | userInitials }}</ng-container>
                    <div *ngIf="user.isOnline" class="userpic-online-badge"></div>
                  </div>
                </div>

                <div class="user-list-item-info d-flex-column">
                  <span
                    class="user-list-item-username text-overflow-ellipsis tt9-body-medium-2"
                    matTooltipPosition="above"
                    matTooltipClass="tt9-arrow-tooltip-below-right tt9-arrow-tooltip-below-right-large"
                    [matTooltip]="user.displayName"
                  >
                    {{ user.displayName }}
                  </span>
                  <span
                    class="user-list-item-email text-overflow-ellipsis tt9-body-2"
                    matTooltipPosition="above"
                    matTooltipClass="tt9-arrow-tooltip-below-right tt9-arrow-tooltip-below-right-large"
                    [matTooltip]="user.email"
                  >
                    {{ user.email }}
                  </span>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
      
      <div class="d-flex-end">
        <button class="tt9-btn-default mr-8" (click)="closeDialog()">{{ vm.closeButtonTxt }}</button>
        <button class="tt9-btn" [ngClass]="{ 'btn-error': vm.isWarning }" (click)="publish()">{{ vm.confirmBtnText }}</button>
      </div>
    </div>
  </ng-container>
</ng-container>
