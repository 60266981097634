import { Injectable } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map, startWith, switchMap } from 'rxjs/operators';

import { CollectionsService, SessionService } from '@accenture/erp-deployment/shared/domain';
import { AppState, selectActivityIdAndParentIds, selectAuthenticatedUser } from '@accenture/global-store';
import { getSnackBarTextForCollectionSession, UserCollection, UserSession } from '@accenture/shared/data';
import { SnackbarService, SnackBarTypes } from '@accenture/shared/ui';
import { sortByDateDesc } from '@accenture/shared/util';

import { AddExistingSessionDialogComponent } from './add-existing-session-dialog.component';

export interface SelectedSession {
    id: string;
    sessionId: string;
    checked?: boolean;
}
export interface AddExistingSessionDialogViewModel {
    userSessions: UserSession[];
    selectedSessions: UserSession[];
    selectedSessionsMap: SelectedSession[];
    selectedSessionsMapIds: string[];
    isAllSelected: boolean;
    isLoading: boolean;
}

const defaultViewModel: AddExistingSessionDialogViewModel = {
    userSessions: [],
    selectedSessions: [],
    selectedSessionsMap: [],
    selectedSessionsMapIds: [],
    isAllSelected: false,
    isLoading: true,
};

@Injectable()
export class AddExistingSessionDialogFacade {
    vm$ = this.buildViewModel();

    private selectedSessionsMap$ = new BehaviorSubject<SelectedSession[]>([]);
    private searchValue$ = new BehaviorSubject<string>('');
    private isLoading$ = new BehaviorSubject<boolean>(false);
    private userId!: string;
    private userCollection: UserCollection;
    private isManySelectedSessions: boolean;
    private filteredUserSessions: UserSession[] = [];

    constructor(
        private store: Store<AppState>,
        private dialogRef: MatDialogRef<AddExistingSessionDialogComponent>,
        private sessionService: SessionService,
        private collectionService: CollectionsService,
        private snackbarService: SnackbarService,
    ) {}

    closeCurrentDialog(): void {
        this.dialogRef.close();
    }

    updateSearchValue(value: string): void {
        this.searchValue$.next(value);
    }

    addRemoveSelectedSession(selectedSession: SelectedSession): void {
        let selectedSessions = this.selectedSessionsMap$.getValue();
        const isSelectedSessionCurrentlyOnList = selectedSessions.some((session) => session.id === selectedSession.id);

        if (isSelectedSessionCurrentlyOnList) {
            selectedSessions = selectedSessions.filter((session) => session.id !== selectedSession.id);
        } else {
            selectedSessions = [...this.selectedSessionsMap$.getValue(), selectedSession];
        }

        this.selectedSessionsMap$.next(selectedSessions);
    }

    addAllSession(): void {
        const setSelectedSessions: SelectedSession[] = this.filteredUserSessions.reduce(
            (acc, userSession) => [
                ...acc,
                {
                    id: userSession.id,
                    sessionId: userSession.sessionId,
                },
            ],
            [],
        );

        this.selectedSessionsMap$.next(setSelectedSessions);
    }

    removeAllSession(): void {
        this.selectedSessionsMap$.next([]);
    }

    async addSessionsToCollection(): Promise<void> {
        this.showSessionSnackBar(SnackBarTypes.Info);

        if (!this.userCollection.collectionId || !this.userId) {
            this.showSessionSnackBar(SnackBarTypes.Error);
            this.dialogRef.close();
            return;
        }

        try {
            const selectedSessions = this.selectedSessionsMap$.getValue();

            await Promise.all(
                selectedSessions.map(
                    async (session) =>
                        await this.collectionService.moveToCollection(
                            this.userCollection,
                            this.userId,
                            session.sessionId,
                            session.id,
                        ),
                ),
            );

            this.showSessionSnackBar(SnackBarTypes.Success);
        } catch (e) {
            console.error(e);
            this.showSessionSnackBar(SnackBarTypes.Error);
        }

        this.dialogRef.close();
    }

    private buildViewModel(): Observable<AddExistingSessionDialogViewModel> {
        return combineLatest([
            this.store.select(selectAuthenticatedUser),
            this.store.select(selectActivityIdAndParentIds),
        ]).pipe(
            switchMap(([user, { collectionId }]) => {
                this.userId = user.id;

                return this.collectionService.getUserCollectionByCollectionId(collectionId).pipe(
                    switchMap((userCollection) => {
                        this.userCollection = userCollection;

                        return combineLatest([
                            this.sessionService.getUserSessionsById(this.userId),
                            this.searchValue$,
                            this.selectedSessionsMap$,
                            this.isLoading$,
                        ]).pipe(
                            map(([userSessions, searchValue, selectedSessionsMap, isLoading]) => {
                                const filteredSession = userSessions
                                    .filter((res) => !res.collectionId)
                                    .sort(sortByDateDesc('updated'));

                                const filteredSessions = this.getFilteredSessions(filteredSession, searchValue);
                                const selectedSessionsMapIds = selectedSessionsMap.map(
                                    (selected) => selected.sessionId,
                                );
                                const isAllSelected = filteredSessions.every((session) =>
                                    selectedSessionsMapIds.includes(session.sessionId),
                                );

                                this.filteredUserSessions = filteredSessions;
                                this.isManySelectedSessions = selectedSessionsMap.length > 1;

                                return {
                                    selectedSessionsMap,
                                    selectedSessionsMapIds,
                                    isAllSelected,
                                    isLoading,
                                    selectedSessions: this.getSelectedSessions(filteredSession),
                                    userSessions: filteredSessions,
                                };
                            }),
                        );
                    }),
                );
            }),
            startWith(defaultViewModel),
        );
    }

    private getFilteredSessions(userSessions: UserSession[], searchValue?: string): UserSession[] {
        return userSessions.filter((userSession) => {
            if (!searchValue) {
                return userSession;
            }

            return userSession.name?.toLowerCase().includes(searchValue);
        });
    }

    private getSelectedSessions(userSessions: UserSession[]): UserSession[] {
        const selectedSessionsMap = this.selectedSessionsMap$.getValue();

        return userSessions.filter((usersession) => selectedSessionsMap[usersession.sessionId]);
    }

    private showSessionSnackBar(type: SnackBarTypes): void {
        const snackbarText = getSnackBarTextForCollectionSession(type, this.isManySelectedSessions);
        switch (type) {
            case 'info':
                this.snackbarService.showInfoSnackBar(
                    snackbarText.title,
                    this.handleDescription(snackbarText.description),
                    true,
                );
                break;
            case 'success':
                this.snackbarService.showSuccessSnackBar(
                    snackbarText.title,
                    this.handleDescription(snackbarText.description),
                    true,
                );
                break;
            case 'error':
                this.snackbarService.showErrorSnackBar(snackbarText.title, snackbarText.description, true);
                break;
        }
    }

    private handleDescription(description: string): string {
        return `${description} "${this.truncateToSingleLine(this.userCollection.name)}"`;
    }

    private truncateToSingleLine(collectionName: string): string {
        const maxLength = 47;

        return collectionName.length > maxLength ? collectionName.slice(0, maxLength - 4) + '...' : collectionName;
    }
}
