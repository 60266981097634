import { ChangeDetectionStrategy, Component, Inject, signal } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import {
    SessionRecordingConsent,
    SessionRecordingConsentResponse,
    SessionRecordingConsentResponseLookup,
} from '@accenture/shared/data';
import { DialogService } from '@accenture/shared/ui';
import { trackById } from '@accenture/shared/util';

import { SessionRecorderCreateDisclaimerFacade } from './session-recorder-create-disclaimer-facade';

@Component({
    selector: 'accenture-session-recorder-create-disclaimer',
    templateUrl: './session-recorder-create-disclaimer.component.html',
    styleUrls: ['./session-recorder-create-disclaimer.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [SessionRecorderCreateDisclaimerFacade],
})
export class SessionRecorderCreateDisclaimerComponent {
    vm$ = this.facade.vm$;
    loaderText!: string;
    trackById = trackById;
    sessionRecordingConsentResponse = SessionRecordingConsentResponse;

    active = signal(SessionRecordingConsentResponse.All);

    statuses = Object.values(SessionRecordingConsentResponse);
    statusLookup = SessionRecordingConsentResponseLookup;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private facade: SessionRecorderCreateDisclaimerFacade,
        private dialogService: DialogService,
    ) {}

    title = 'User Consent Status';

    async proceed(): Promise<void> {
        this.dialogService.close();
        this.data.onAccept();
    }

    closeDialog() {
        this.dialogService.close();
    }

    count(consents: SessionRecordingConsent[], response: SessionRecordingConsentResponse): number {
        switch (response) {
            case SessionRecordingConsentResponse.All:
                return consents.length;
        }

        return consents.filter((consent) => consent.response === response).length;
    }

    filter(consents: SessionRecordingConsent[]): SessionRecordingConsent[] {
        const filter = this.active();
        switch (filter) {
            case SessionRecordingConsentResponse.All:
                return consents;
        }
        return consents.filter((consent) => consent.response === filter);
    }

    filterResponses(status: SessionRecordingConsentResponse): void {
        this.active.set(status);
    }
}
