import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { BehaviorSubject, catchError, combineLatest, from, Observable, of, switchMap } from 'rxjs';
import { distinctUntilChanged, map, startWith } from 'rxjs/operators';

import { LinkAccessService, SessionService } from '@accenture/erp-deployment/shared/domain';
import { AppState, selectFeatureToggle, selectSessionDataName, selectSessionIdNew } from '@accenture/global-store';
import {
    Activity,
    errorMessageSnackbarText,
    FeatureToggleName,
    LinkAccess,
    ParentType,
    sessionInviteCopiedtoClipboardSuccess,
    sessionInviteCopytoClipboardTitle,
    sessionInviteGenerationInfoMessage,
    sessionInviteGenerationInfoTitle,
} from '@accenture/shared/data';
import {
    ConfirmationDialogComponent,
    DialogService,
    QrCodeGenerator,
    SnackbarService,
    SnackBarTypes,
} from '@accenture/shared/ui';
import { formatToHTMLInClipboard } from '@accenture/shared/util';
export interface NewLinkAccessModel {
    linkAccess?: LinkAccess;
    isEnabledLinkAccess: boolean;
    isVisiblePassword: boolean;
    isConfirmationDialogOpen: boolean;
    originalPassword?: string;
    qrData?: string;
    isLoading: boolean;
    isShowGetLinks: boolean;
    hasClient: boolean;
}

export const defaultViewModel: NewLinkAccessModel = {
    linkAccess: undefined,
    isEnabledLinkAccess: false,
    isVisiblePassword: false,
    isConfirmationDialogOpen: false,
    originalPassword: '',
    qrData: '',
    isLoading: false,
    isShowGetLinks: false,
    hasClient: false,
};

@Injectable()
export class LinkAccessFacade {
    passwordVisibility$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    vm$ = this.buildViewModel();

    private sessionId: string;
    private sessionName: string;
    private linkAccessId: string;
    private originalPassword: string;
    private qrCodeDataUrl: string;
    private previousLinkAccessId: string;
    private activities: Activity[];
    private isLoading$ = new BehaviorSubject<boolean>(false);
    private isDeletionInprogress = false;

    constructor(
        private store: Store<AppState>,
        private linkAccessService: LinkAccessService,
        private dialogService: DialogService,
        private snackbarService: SnackbarService,
        private sessionService: SessionService,
    ) {
        // the initial value refers to the first invite dialog the before confirmation dialog
        this.dialogService.isMaterialDialogOpen$.next(false);
    }

    async toggleLinkAccess(linkAccessEnabled: boolean): Promise<void> {
        if (!linkAccessEnabled) {
            this.dialogService.open(ConfirmationDialogComponent, {
                title: 'Disable QR / Link Access',
                cancelBtnText: 'Cancel',
                width: '600px',
                confirmBtnText: 'Apply',
                text: 'QR / Link access will no longer be accessible when disabled. Are you sure you want to disable QR / Link access in this session?',
                confirm: () => this.onDeleteLinkAccessConfirm(),
            });
        } else {
            await this.createLinkAccess();
        }
    }

    togglePasswordVisibility(): void {
        this.passwordVisibility$.next(!this.passwordVisibility$.getValue());
    }

    async updatePassword(originalPassword: string, linkAccessWithActivity?: boolean): Promise<void> {
        if (originalPassword !== this.originalPassword || linkAccessWithActivity) {
            await Promise.all([
                this.linkAccessService.updateLinkAccessPassword(this.linkAccessId, originalPassword),
                this.linkAccessService.updateLinkAccessOriginalPasswordNew(
                    this.sessionId,
                    this.linkAccessId,
                    originalPassword,
                ),
            ]);
        }
    }

    copyInviteToClipboard(): void {
        try {
            this.snackbarService.showInfoSnackBar(sessionInviteGenerationInfoTitle, sessionInviteGenerationInfoMessage);
            setTimeout(() => this.getInviteDetails(), 300);
        } catch (error) {
            this.snackbarService.showSnackBar(
                sessionInviteCopytoClipboardTitle,
                errorMessageSnackbarText,
                SnackBarTypes.Error,
                true,
                false,
                false,
                null,
                20000,
            );
            console.error('Error generating or copying invite details to clipboard:', error);
        }
    }

    private async createLinkAccess(activityId?: string): Promise<string> {
        const newLinkAccess = {
            sessionId: this.sessionId,
            sessionName: this.sessionName,
        };
        if (!!activityId) {
            const selectedActivity
                = this.activities[this.activities.findIndex((activity) => activity.id === activityId)];
            newLinkAccess['activityId'] = selectedActivity.id;
            newLinkAccess['activityName'] = selectedActivity.name;
            newLinkAccess['activityType'] = selectedActivity.type;
        }
        return this.linkAccessService.addLinkAccess(newLinkAccess);
    }

    private async deleteLinkAccess(): Promise<void> {
        this.isDeletionInprogress = true;
        try {
            await this.linkAccessService.deleteLinkAccessOriginalPasswordNew(this.sessionId, this.linkAccessId);
            await this.linkAccessService.deleteLinkAccess(this.linkAccessId);
        } catch (error) {
            console.error('Error', error.message);
        }

        this.isDeletionInprogress = false;
    }

    private buildViewModel(): Observable<NewLinkAccessModel> {
        return combineLatest([
            this.store.select(selectFeatureToggle(FeatureToggleName.ShowGetLinks)),
            this.store.select(selectSessionIdNew),
        ]).pipe(
            switchMap(([showGetLinks, { sessionId }]) => {
                if (!sessionId) {
                    return of(defaultViewModel);
                }
                this.sessionId = sessionId;
                const isShowGetLinks = showGetLinks && !!sessionId;

                const linkAccessAndOriginPassword$ = this.getLinkAccess(sessionId).pipe(
                    switchMap((linkAccess) => {
                        this.linkAccessId = linkAccess?.id;

                        return combineLatest([
                            this.getLinkAccessOriginalPassword(),
                            of(linkAccess),
                            from(this.generateQRCodeForDisplay()).pipe(distinctUntilChanged()),
                        ]).pipe(
                            map(([originalPassword, linkAccess, qrData]) => {
                                return {
                                    originalPassword,
                                    linkAccess,
                                    qrData,
                                };
                            }),
                        );
                    }),
                );

                return combineLatest([
                    linkAccessAndOriginPassword$,
                    this.passwordVisibility$.asObservable(),
                    this.dialogService.isMaterialDialogOpen$.asObservable(),
                    this.store.select(selectSessionDataName),
                    this.isLoading$.asObservable(),
                    this.sessionService.hasClientInSession(ParentType.Sessions, sessionId),
                ]).pipe(
                    map(
                        ([
                            { linkAccess, originalPassword, qrData },
                            isVisiblePassword,
                            isConfirmationDialogOpen,
                            sessionName,
                            isLoading,
                            hasClient,
                        ]) => {
                            if (this.isDeletionInprogress) {
                                linkAccess = null;
                            }

                            this.sessionName = sessionName;
                            this.originalPassword = originalPassword;

                            return {
                                isConfirmationDialogOpen,
                                isLoading,
                                isShowGetLinks,
                                isVisiblePassword,
                                originalPassword,
                                qrData,
                                hasClient,
                                linkAccess: { ...linkAccess, sessionName: sessionName || '' },
                                isEnabledLinkAccess: !!linkAccess,
                            };
                        },
                    ),
                );
            }),
            startWith({
                ...defaultViewModel,
                isLoading: true,
            }),
            catchError((err) => {
                console.error('Error retrieving data for facade, may be firestore rules related', err);
                return of(defaultViewModel);
            }),
        );
    }

    private getLinkAccess(sessionId: string): Observable<LinkAccess | undefined> {
        return this.linkAccessService.getLinkAccessBySession(sessionId);
    }

    private getLinkAccessOriginalPassword(): Observable<string | undefined> {
        if (!this.linkAccessId) {
            return of(undefined);
        }
        return this.linkAccessService.getLinkAccessOriginalPasswordNew(this.sessionId, this.linkAccessId);
    }

    private getInviteDetails(): void {
        const path = `${window.location.protocol}//${window.location.host}/authentication/join-session/${this.linkAccessId}`;
        const password = `Password: ${this.originalPassword || ''}`;
        const sessionName = `Session: ${this.sessionName}`;
        const qrImageUrl = this.qrCodeDataUrl;

        const container = formatToHTMLInClipboard(sessionName, qrImageUrl, path, password);
        const blobHtml = new Blob([container.innerHTML], { type: 'text/html' });
        const containerText = `${sessionName}\nLink: ${path}\n${password}`;
        const blobText = new Blob([containerText], { type: 'text/plain' });
        const data = [new ClipboardItem({ ['text/html']: blobHtml, ['text/plain']: blobText })];
        navigator.clipboard.write(data);

        this.snackbarService.showSnackBar(
            sessionInviteCopytoClipboardTitle,
            sessionInviteCopiedtoClipboardSuccess,
            SnackBarTypes.Success,
            true,
            false,
            false,
            null,
            20000,
        );
    }

    private async generateQRCodeForDisplay(): Promise<string | undefined> {
        if (!this.linkAccessId) {
            return;
        }

        if (this.linkAccessId === this.previousLinkAccessId) {
            return this.qrCodeDataUrl;
        }

        const qrCodeLink = `${window.location.protocol}//${window.location.host}/authentication/join-session/${this.linkAccessId}`;
        this.qrCodeDataUrl = await QrCodeGenerator.generateQRCodeImage(qrCodeLink);

        // Update previousLinkAccessId
        this.previousLinkAccessId = this.linkAccessId;

        return this.qrCodeDataUrl;
    }

    private async onDeleteLinkAccessConfirm(): Promise<void> {
        this.originalPassword = '';

        await this.deleteLinkAccess();
    }
}
