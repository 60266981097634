import {
    AfterViewInit,
    Directive,
    ElementRef,
    EventEmitter,
    Input,
    NgZone,
    OnChanges,
    OnDestroy,
    Output,
    SimpleChanges,
} from '@angular/core';

import { getStringWidth } from '@accenture/shared/util';

@Directive({
    selector: '[accentureTruncatedValue]',
})
export class TruncatedValueDirective implements OnChanges, OnDestroy, AfterViewInit {
    private observer!: ResizeObserver;
    private isShowAll = false;

    @Input('accentureTruncatedValue') accentureValue?: string;
    @Input('accentureIsOneLine') isOneLine?: boolean;
    @Input('accentureIsShowAll') isShowAllValue?: boolean;
    @Input() accentureContainerElement: HTMLElement = document.createElement('div');
    @Input() accentureOffsetWidth: HTMLElement = document.createElement('div');

    @Output() truncatedValueChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    constructor(private zone: NgZone, private element: ElementRef) {}

    ngAfterViewInit() {
        this.isShowAll = true;
        this.observer = new ResizeObserver(() => {
            this.zone.run(() => {
                this.getTruncatedValue();
            });
        });
        this.observer.observe(this.accentureContainerElement);
    }

    ngOnDestroy(): void {
        this.observer.unobserve(this.accentureContainerElement);
    }

    ngOnChanges(changes: SimpleChanges): void {
        const changedProperty = 'isShowAllValue';
        if (changedProperty in changes) {
            this.isShowAllValue = changes[changedProperty].currentValue;
            if (this.isShowAll) {
                this.getTruncatedValue();
            }
        }
        // used to show updated description value in activity headers
        // return empty string if currentValue is undefined so that fields will respond when their value is removed
        if ('accentureValue' in changes) {
            this.accentureValue = changes['accentureValue'].currentValue || '';
            this.getTruncatedValue();
        }
    }

    private getTruncatedValue(): string {
        if (typeof this.accentureValue !== 'string') {
            return '';
        }
        const offsetWidth = this.accentureOffsetWidth.offsetWidth;
        const containerWidth = this.getContainerWidth();
        const value = this.accentureValue.replace(/\n/g, ' '); // replace new lines with a space
        const words = value.split(' ');
        const ellipsis = '...';
        const isSingleLongWord = words.length === 1;

        const fontStyles = this.getFontStyles() || '';
        const ellipsisWidth = getStringWidth(ellipsis, fontStyles);
        const spaceWidth = getStringWidth(' ', fontStyles);

        let truncatedWord = '';
        let lineWidth = 0;
        let lineCount = 1;
        let prevLineWidth = 0;

        for (const [index, word] of words.entries()) {
            const wordWidth = getStringWidth(word, fontStyles) + spaceWidth;

            // Check if this has multiple Lines to define the line count
            if (!this.isOneLine && lineCount === 1 && offsetWidth + wordWidth + lineWidth > containerWidth) {
                prevLineWidth = lineWidth;
                lineWidth = 0;
                if (!isSingleLongWord) {
                    lineCount++;
                }
            }

            // If single word does not fit in the container then truncate the value and add ellipsis then break the remaining value
            if (
                (this.isOneLine && offsetWidth + wordWidth + ellipsisWidth > containerWidth)
                || (!this.isOneLine && wordWidth + ellipsisWidth > containerWidth)
            ) {
                const letters = word.split('');
                if (isSingleLongWord) {
                    lineCount++;
                }

                // wrap long lines to a separate element with `word-break: break-all` styling
                truncatedWord += `<span class='break-all-word'>`;

                for (const letter of letters) {
                    const letterWidth = getStringWidth(letter, fontStyles);
                    const twoLineContainerWidth = this.breakWord(
                        isSingleLongWord,
                        !!this.isOneLine,
                        containerWidth,
                        prevLineWidth,
                    );

                    if (letterWidth + lineWidth + ellipsisWidth >= twoLineContainerWidth) {
                        truncatedWord += `</span>`;
                        truncatedWord;
                        break;
                    }
                    truncatedWord += letter;
                    lineWidth += letterWidth;
                }
            }

            // If this is one line or second line will truncate the value and add ellipsis then break the remaining value
            if (
                (this.isOneLine && offsetWidth + wordWidth + lineWidth + ellipsisWidth > containerWidth)
                || (!this.isOneLine && lineCount === 2 && wordWidth + lineWidth + ellipsisWidth > containerWidth)
            ) {
                truncatedWord += ellipsis;
                break;
            }

            lineWidth += wordWidth;
            // check if last word, it should not add another space when last
            truncatedWord += index + 1 === words.length ? word : word + ' ';
        }
        const valueWidth = getStringWidth(value, fontStyles);
        const truncatedWordWidth = getStringWidth(truncatedWord, fontStyles);
        // removed spaceWidth from computation, since the last space is already being removed from truncatedWord
        const hasEllipsis = valueWidth !== truncatedWordWidth;
        this.truncatedValueChange.emit(hasEllipsis);

        const innerValue = this.isShowAllValue ? value : truncatedWord;
        return (this.element.nativeElement.innerHTML = innerValue);
    }

    private getContainerWidth(): number {
        const computedValueStyle = window.getComputedStyle(this.accentureContainerElement);
        const textContainerWidth = parseInt(computedValueStyle.getPropertyValue('width'));

        return textContainerWidth;
    }

    private getFontStyles(): string {
        const computedValueStyle = window.getComputedStyle(this.accentureContainerElement);

        const fontWeight = computedValueStyle.getPropertyValue('font-weight');
        const fontSize = computedValueStyle.getPropertyValue('font-size');
        const fontFamily = computedValueStyle.getPropertyValue('font-family');

        const fontStyles = `${fontWeight} ${fontSize} ${fontFamily}`;

        return fontStyles;
    }

    // get width on where to break word
    private breakWord(
        isSingleLongWord: boolean,
        isOneLine: boolean,
        containerWidth: number,
        prevLineWidth: number,
    ): number {
        if (isOneLine) {
            return containerWidth;
        }
        // catch singe long word edge case
        // double container width so that truncation will start on 2nd line
        if (isSingleLongWord) {
            return containerWidth * 2;
        }
        // input is not single long word but has a long word that exceeds containerWidth
        // e.g: "Lorem ipsum dolorsitamet,consecteturadipiscingelit...etc"
        return containerWidth * 2 - prevLineWidth;
    }
}
