import { Directive, ElementRef, EventEmitter, Input, NgZone, OnDestroy, OnInit, Output } from '@angular/core';
import { debounceTime, Subscription } from 'rxjs';

import { ResizeObservable } from './resize-observable';
import { ResizedEvent } from './resized.event';

@Directive({
    selector: '[accentureResized]',
})
export class ResizedDirective implements OnInit, OnDestroy {
    private resizeObserverSubscription!: Subscription;
    private oldRect?: DOMRectReadOnly;

    @Input() public debouncer = 0;

    @Output('accentureResized')
    public readonly resized;

    public constructor(private readonly element: ElementRef, private readonly zone: NgZone) {
        this.resized = new EventEmitter<ResizedEvent>();
    }

    public ngOnInit(): void {
        this.resizeObserverSubscription = new ResizeObservable(this.element.nativeElement)
            .pipe(debounceTime(this.debouncer))
            .subscribe(entries => this.zone.run(() => this.observe(entries)));
    }

    public ngOnDestroy(): void {
        if (!this.resizeObserverSubscription.closed) {
            this.resizeObserverSubscription.unsubscribe();
        }
    }

    private observe(entries: ResizeObserverEntry[]): void {
        const domSize = entries[0];
        const resizedEvent = new ResizedEvent(domSize.contentRect, this.oldRect);

        this.oldRect = domSize.contentRect;
        this.resized.emit(resizedEvent);
    }
}
