import { ChangeDetectionStrategy, Component, TemplateRef } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { arrowRotateCwIcon, SVGIcon } from '@progress/kendo-svg-icons';
import { marked } from 'marked';
import { Observable } from 'rxjs';

import { LoaderSize } from '@accenture/shared/ui';

import { AiMakeNotesButtonFacade, AiMakeNotesButtonModel } from './ai-make-notes-button-facade';

@Component({
    selector: 'accenture-ai-make-notes-button',
    templateUrl: './ai-make-notes-button.component.html',
    styleUrls: ['./ai-make-notes-button.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [AiMakeNotesButtonFacade],
})
export class AiMakeNotesButtonComponent {
    public arrowRotateCwIcon: SVGIcon = arrowRotateCwIcon;
    loaderSize = LoaderSize.Small;

    vm$: Observable<AiMakeNotesButtonModel> = this.facade.vm$;

    constructor(private facade: AiMakeNotesButtonFacade, private sanitizer: DomSanitizer) {}

    tooltip(notes: string): string {
        return `${notes ? 'View' : 'Generate'} Facilitation notes for ${this.facade.getType()}`;
    }

    async makeNotes(): Promise<void> {
        await this.facade.makeNotes();
    }

    showNotes(titleBar: TemplateRef<unknown>, content: TemplateRef<unknown>, notes: string) {
        this.facade.showNotes(titleBar, content, notes);
    }

    renderMarkdown(text: string): string {
        return this.sanitizer.bypassSecurityTrustHtml(marked(text)) as string;
    }
}
