import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import {
    ActivityItemType,
    BaseResponse,
    DBPathHelper,
    FileTypeResponse,
    ParentType,
    ResponseValue,
    SessionStatus,
    TableColumnType,
    TableResponse,
    User,
} from '@accenture/shared/data';
import { FirestoreService } from '@accenture/shared/data-access';

import { ActivityResponsesService } from './activity-responses.service';

@Injectable({
    providedIn: 'root',
})
export class TableResponseService {
    constructor(
        private firestoreService: FirestoreService,
        private activityResponsesService: ActivityResponsesService,
    ) {}

    // TODO: Delete after project  deprecation
    getResponses(
        parentId: string,
        activityItemId: string,
        parentType = ParentType.Projects,
    ): Observable<TableResponse[]> {
        return this.firestoreService
            .getDocumentsByMultipleProperties<TableResponse>(
                this.activityResponsesService.getResponsePath(parentId, undefined, parentType),
                new Map<string, any>([
                    ['activityItemId', activityItemId],
                    ['current', true],
                ]),
                'created',
            )
            .pipe(map((responses) => responses.map((response) => new TableResponse(response))));
    }

    getResponsesByRowIds(
        parentId: string,
        rowIds: string[],
        activityItemId: string,
        parentType = ParentType.Sessions,
    ): Observable<TableResponse[]> {
        if (rowIds.length === 0) {
            return of([]);
        }
        return this.firestoreService
            .getDocumentsByArrayProperty<TableResponse>(
                this.activityResponsesService.getResponsePathNew(parentId, undefined, parentType),
                'rowId',
                rowIds,
            )
            .pipe(
                map((responses) =>
                    responses
                        .filter(
                            (response) =>
                                (!activityItemId || response.activityItemId === activityItemId)
                                && response.current === true,
                        )
                        .map((response) => new TableResponse(response)),
                ),
            );
    }

    setRealTimeResponseListener(
        parentId: string,
        rowIds: string[],
        activityItemId: string,
        parentType = ParentType.Sessions,
    ): Observable<TableResponse[]> {
        if (rowIds.length === 0) {
            return of([]);
        }

        const responsePath = this.activityResponsesService.getResponsePathNew(parentId, undefined, parentType);

        return this.firestoreService
            .getDocumentsByMultipleProperties<TableResponse>(
                responsePath,
                new Map<string, any>([
                    ['activityItemId', activityItemId],
                    ['current', true],
                ]),
                'created',
            )
            .pipe(
                map((responses) => responses.map((response) => new TableResponse(response))),
                map((responses) => {
                    return responses.filter((response) => rowIds.includes(response.rowId));
                }),
            );
    }

    getResponsesNew(
        parentId: string,
        activityItemId: string,
        parentType = ParentType.Sessions,
    ): Observable<TableResponse[]> {
        return this.firestoreService
            .getDocumentsByMultipleProperties<TableResponse>(
                this.activityResponsesService.getResponsePathNew(parentId, undefined, parentType),
                new Map<string, any>([
                    ['activityItemId', activityItemId],
                    ['current', true],
                ]),
                'created',
            )
            .pipe(map((responses) => responses.map((response) => new TableResponse(response))));
    }

    // TODO: Delete after project  deprecation
    async deleteResponses(
        projectId: string,
        responses: TableResponse[],
        parentType: ParentType = ParentType.Projects,
    ): Promise<void> {
        const deleteData = [];
        const batchFilesData = [];
        (responses || []).forEach((response) => {
            if (response.type === ActivityItemType.Attachment && response.value) {
                Object.keys(response.value).forEach((fileId) => {
                    batchFilesData.push({
                        path: DBPathHelper.getFilesRefsPath(fileId),
                        data: { count: this.firestoreService.changeCounterValue(-1) },
                    });
                });
            }
            deleteData.push({
                path: this.activityResponsesService.getResponsePath(projectId, response.id, parentType),
            });
        });

        await this.firestoreService.writeBatch(batchFilesData);
        await this.firestoreService.deleteBatch(deleteData);
    }

    async deleteResponsesNew(
        sessionId: string,
        responses: TableResponse[],
        parentType: ParentType = ParentType.Sessions,
    ): Promise<void> {
        const deleteData = [];
        const batchFilesData = [];
        (responses || []).forEach((response) => {
            if (response.type === ActivityItemType.Attachment && response.value) {
                Object.keys(response.value).forEach((fileId) => {
                    batchFilesData.push({
                        path: DBPathHelper.getFilesRefsPath(fileId),
                        data: { count: this.firestoreService.changeCounterValue(-1) },
                    });
                });
            }
            deleteData.push({
                path: this.activityResponsesService.getResponsePathNew(sessionId, response.id, parentType),
            });
        });

        await this.firestoreService.writeBatch(batchFilesData);
        await this.firestoreService.deleteBatch(deleteData);
    }

    async updateResponse(
        user: User,
        parentId: string,
        sessionId: string,
        activityId: string,
        activityItemId: string,
        columnId: string,
        type: TableColumnType,
        rowId: string,
        responseValue: ResponseValue,
        currentResponse: TableResponse | undefined,
        isSummaryStep: boolean,
        parentType = ParentType.Projects,
        updateSessionStatus = false,
    ): Promise<void> {
        const isFile = [ActivityItemType.Attachment, ActivityItemType.File].includes(type);
        const response = new TableResponse({
            sessionId,
            activityId,
            activityItemId,
            columnId,
            type,
            rowId,
            value: responseValue,
            current: true,
            userId: user.id,
            userDisplayName: user.displayName,
            userImageUrl: user.imageUrl,
            created: this.firestoreService.timestamp,
            updated: this.firestoreService.timestamp,
        });

        if (isFile) {
            const newFileIds = Object.keys(responseValue);
            const currentFileIds = Object.keys(currentResponse?.value || {});

            const newFiles = Object.values(responseValue).filter((file) => !currentFileIds.includes(file.id));
            const fileIdsToRemove = currentFileIds.filter((fileId) => !newFileIds.includes(fileId));

            const newFilesMap = newFiles.reduce((acc, file) => {
                acc[file.id] = file;
                return acc;
            }, {});

            await this.createResponseFilesReferences(
                parentId,
                sessionId,
                activityId,
                activityItemId,
                newFilesMap,
                columnId,
                rowId,
            );

            const dataForUpdateFileRef = [];

            fileIdsToRemove.forEach((fileId) => {
                dataForUpdateFileRef.push({
                    path: DBPathHelper.getFilesRefsPath(fileId),
                    data: { commentsCount: this.firestoreService.changeCounterValue(-1) },
                });

                response.value[fileId] = this.firestoreService.deleteField;
            });

            await this.firestoreService.updateBatch(dataForUpdateFileRef);

            if (!currentResponse?.id) {
                await this.activityResponsesService.writeResponse(parentId, sessionId, response, undefined, parentType);
            } else {
                const responseUpdates: Partial<BaseResponse> = {
                    id: currentResponse.id,
                    userId: user.id,
                    userDisplayName: user.displayName,
                    userImageUrl: user.imageUrl,
                    value: response.value,
                    updated: this.firestoreService.timestamp,
                };

                await this.updateResponseInDatabase(parentId, responseUpdates, parentType);
            }
        }

        if (!currentResponse?.id && !isFile) {
            await this.activityResponsesService.writeResponse(parentId, sessionId, response, undefined, parentType);
        }

        if (currentResponse?.id && isSummaryStep && !isFile) {
            await this.activityResponsesService.writeResponse(parentId, sessionId, response, undefined, parentType);

            const responseUpdates: Partial<TableResponse> = {
                id: currentResponse.id,
                current: this.firestoreService.deleteField as any,
                updated: this.firestoreService.timestamp,
            };

            await this.updateResponseInDatabase(parentId, responseUpdates, parentType);
        }

        if (currentResponse?.id && !isSummaryStep && !isFile) {
            const responseUpdates: Partial<BaseResponse> = {
                id: currentResponse.id,
                userId: user.id,
                userDisplayName: user.displayName,
                userImageUrl: user.imageUrl,
                value: response.value,
                created: this.firestoreService.timestamp,
                updated: this.firestoreService.timestamp,
            };

            await this.updateResponseInDatabase(parentId, responseUpdates, parentType);
        }

        if (updateSessionStatus) {
            await this.firestoreService.updateDoc(DBPathHelper.getSessionPath(parentType, parentId, sessionId), {
                status: SessionStatus.Active,
            });
        }
    }

    async updateResponseNew(
        user: User,
        parentId: string,
        sessionId: string,
        activityId: string,
        activityItemId: string,
        columnId: string,
        type: TableColumnType,
        rowId: string,
        responseValue: ResponseValue,
        currentResponse: TableResponse | undefined,
        isSummaryStep: boolean,
        parentType = ParentType.Sessions,
        updateSessionStatus = false,
    ): Promise<void> {
        const isFile = [ActivityItemType.Attachment, ActivityItemType.File].includes(type);
        const response = new TableResponse({
            sessionId,
            activityId,
            activityItemId,
            columnId,
            type,
            rowId,
            value: responseValue,
            current: true,
            userId: user.id,
            userDisplayName: user.displayName,
            userImageUrl: user.imageUrl,
            created: this.firestoreService.timestamp,
            updated: this.firestoreService.timestamp,
        });

        if (isFile) {
            const newFileIds = Object.keys(responseValue);
            const currentFileIds = Object.keys(currentResponse?.value || {});

            const newFiles = Object.values(responseValue).filter((file) => !currentFileIds.includes(file.id));
            const fileIdsToRemove = currentFileIds.filter((fileId) => !newFileIds.includes(fileId));

            const newFilesMap = newFiles.reduce((acc, file) => {
                acc[file.id] = file;
                return acc;
            }, {});

            await this.createResponseFilesReferences(
                parentId,
                sessionId,
                activityId,
                activityItemId,
                newFilesMap,
                columnId,
                rowId,
            );

            const dataForUpdateFileRef = [];

            fileIdsToRemove.forEach((fileId) => {
                dataForUpdateFileRef.push({
                    path: DBPathHelper.getFilesRefsPath(fileId),
                    data: { commentsCount: this.firestoreService.changeCounterValue(-1) },
                });

                response.value[fileId] = this.firestoreService.deleteField;
            });

            await this.firestoreService.updateBatch(dataForUpdateFileRef);

            if (!currentResponse?.id) {
                await this.activityResponsesService.writeResponseNew(parentId, response, undefined, parentType);
            } else {
                const responseUpdates: Partial<BaseResponse> = {
                    id: currentResponse.id,
                    userId: user.id,
                    userDisplayName: user.displayName,
                    userImageUrl: user.imageUrl,
                    value: response.value,
                    updated: this.firestoreService.timestamp,
                };

                await this.updateResponseInDatabaseNew(parentId, responseUpdates, parentType);
            }
        }

        if (!currentResponse?.id && !isFile) {
            await this.activityResponsesService.writeResponseNew(parentId, response, undefined, parentType);
        }

        if (currentResponse?.id && isSummaryStep && !isFile) {
            await this.activityResponsesService.writeResponseNew(parentId, response, undefined, parentType);

            const responseUpdates: Partial<TableResponse> = {
                id: currentResponse.id,
                current: this.firestoreService.deleteField as any,
                updated: this.firestoreService.timestamp,
            };

            await this.updateResponseInDatabaseNew(parentId, responseUpdates, parentType);
        }

        if (currentResponse?.id && !isSummaryStep && !isFile) {
            const responseUpdates: Partial<BaseResponse> = {
                id: currentResponse.id,
                userId: user.id,
                userDisplayName: user.displayName,
                userImageUrl: user.imageUrl,
                value: response.value,
                created: this.firestoreService.timestamp,
                updated: this.firestoreService.timestamp,
            };

            await this.updateResponseInDatabase(parentId, responseUpdates, parentType);
        }

        if (updateSessionStatus) {
            await this.firestoreService.updateDoc(DBPathHelper.getSessionPathNew(parentType, sessionId), {
                status: SessionStatus.Active,
            });
        }
    }

    private async createResponseFilesReferences(
        projectId: string,
        sessionId: string,
        activityId: string,
        activityItemId: string,
        files: FileTypeResponse,
        columnId: string,
        rowId: string,
    ): Promise<void> {
        await Promise.all(
            Object.keys(files).map((key) =>
                this.firestoreService.upsert(`/filesRefs/${key}`, {
                    projectId,
                    ...(sessionId && { sessionId }), // Add sessionId only if it exists
                    activityId,
                    activityItemId,
                    columnId,
                    rowId,
                    url: files[key].url,
                    count: 1,
                }),
            ),
        );
    }

    //TODO delete after project deprecation
    private updateResponseInDatabase(
        parentId: string,
        responseUpdate: Partial<TableResponse>,
        parentType = ParentType.Projects,
    ): Promise<void> {
        return this.firestoreService.update<Partial<TableResponse>>(
            this.activityResponsesService.getResponsePath(parentId, responseUpdate.id, parentType),
            {
                ...responseUpdate,
            },
        );
    }

    private updateResponseInDatabaseNew(
        parentId: string,
        responseUpdate: Partial<TableResponse>,
        parentType = ParentType.Sessions,
    ): Promise<void> {
        return this.firestoreService.update<Partial<TableResponse>>(
            this.activityResponsesService.getResponsePathNew(parentId, responseUpdate.id, parentType),
            {
                ...responseUpdate,
            },
        );
    }
}
