import { Timestamp } from 'firebase/firestore';

import { TemplateRole } from './access-control';
import { FilterOptions } from './filter-options';
import { ParentType } from './parent-type';
import { ProjectOptions } from './project-options';
import { SessionOptions } from './session-options';
import { SortOptions } from './sort-options';
import { SortOrders } from './sort-orders';

// TODO: Delete all project-related options here after project deprecation
export interface BaseSortOptions {
    sortOrder?: SortOrders;
    sortOption?: SortOptions;
    adminSortOption?: SortOptions;
    adminSortOrder?: SortOrders;
}

export interface BaseFilterOptions {
    filterOptions?: FilterOptions;
}

export interface BaseFilterAntSortOptions extends BaseSortOptions, BaseFilterOptions {}

export interface DefaultCollectionSortObject {
    collectionsSortOption?: CollectionSortOptions;
    collectionsSortOrder?: SortOrders;
}

export enum PublicTemplateRoleForFilter {
    Viewer = 'TEMPLATE_VIEWIER',
}

export interface DefaultFilterObject extends BaseSortOptions, DefaultCollectionSortObject {
    roles?: string[];
    activityTypes?: string[];
    templateTypes?: string[];
    templateRoles?: (TemplateRole | PublicTemplateRoleForFilter)[];
    currentUserTemplates?: boolean; //will be removed to TemplateStoreFilterObject
    user?: string;
    tags?: string[];
    clients?: string[];
    clientNames?: string[];
    projectNames?: string[];
    phases?: string[];
    subPhases?: string[];
    statuses?: string[];
    fromDate?: Timestamp;
    toDate?: Timestamp;
    startDate?: Timestamp;
    endDate?: Timestamp;
    userTypes?: string[];
}

export enum CollectionSortOptions {
    Frequency = 'FREQUENCY',
    Name = 'NAME',
}

export enum OptionsForSortingByNumberOfUses {
    Projects = 'PROJECTS',
    Collections = 'COLLECTIONS',
    Sessions = 'SESSIONS',
    Activity = 'ACTIVITY',
}

export const templateOptionsFieldForSorting = {
    [ParentType.ProjectTemplates]: OptionsForSortingByNumberOfUses.Projects,
    [ParentType.Templates]: OptionsForSortingByNumberOfUses.Sessions,
    [ParentType.ActivityTemplates]: OptionsForSortingByNumberOfUses.Activity,
};

export const sortByNumberOfUsesValue = {
    [OptionsForSortingByNumberOfUses.Projects]: 'projects',
    [OptionsForSortingByNumberOfUses.Sessions]: 'sessions',
    [OptionsForSortingByNumberOfUses.Activity]: 'activities',
    [OptionsForSortingByNumberOfUses.Collections]: 'collections',
};

export const sortOptionsValues = {
    [SortOptions.Updated]: 'Last updated',
    [SortOptions.Created]: 'Date created',
    [SortOptions.LastViewed]: 'Last viewed',
    [SortOptions.Name]: 'Name',
};

export const sortAdminOptionsValues = {
    [SortOptions.User]: 'User',
    [SortOptions.Email]: 'Email',
    [SortOptions.LastLogin]: 'Last login',
};

export const sessionsSortOptionsValues = {
    [SortOptions.Updated]: 'Last updated',
    [SortOptions.Created]: 'Date created',
    [SortOptions.LastViewed]: 'Last viewed',
    [SortOptions.Name]: 'Name',
    [SortOptions.StartDate]: 'Start date',
    [SortOptions.EndDate]: 'End date',
};

export const dashboardSortOptionsValues = {
    [SortOptions.LastViewed]: 'Last viewed',
    [SortOptions.Updated]: 'Last updated',
    [SortOptions.Created]: 'Date created',
    [SortOptions.Name]: 'Alphabetical',
};

export const presentSortOptionsValues = {
    [SortOptions.Created]: 'Time added',
    [SortOptions.Alphabetical]: 'Alphabetical',
    [SortOptions.Likes]: 'Likes',
    [SortOptions.Comments]: 'Comments',
};

export const templatesSortOptionsValues = {
    [SortOptions.Updated]: 'Last updated',
    [SortOptions.Created]: 'Date created',
    [SortOptions.Name]: 'Name',
};

export const templatesStoreSortOptionsValues = {
    [SortOptions.Created]: 'Date published',
    [SortOptions.Updated]: 'Last updated',
    [SortOptions.Name]: 'Name',
    [SortOptions.TimesUsed]: 'Number of times used',
    [SortOptions.MostFavorite]: 'Most favorited',
};

export const favoriteTemplatesSortOptionsValues = {
    [SortOptions.Updated]: 'Last updated',
    [SortOptions.Name]: 'Name',
};

export const templatesStoreQuickSortOptionsValues = {
    [SortOptions.Created]: 'New',
    [SortOptions.TimesUsed]: 'Most Used',
    [SortOptions.MostFavorite]: 'Most Favorited',
};

export const OptionsFiltersFields = {
    [SessionOptions.Phase]: 'phases',
    [SessionOptions.SubPhase]: 'subPhases',
    [SessionOptions.Tag]: 'tags',
    [ProjectOptions.Client]: 'clients',
};

export const optionsPanelTitle = {
    [ProjectOptions.Client]: 'All clients',
    [ProjectOptions.Tag]: 'All tags',
    [SessionOptions.Phase]: 'All phases',
    [SessionOptions.SubPhase]: 'All sub-phases',
};

export const initialBaseSortOptionsObject: BaseSortOptions = {
    sortOption: SortOptions.Updated,
    sortOrder: SortOrders.Dsc,
    adminSortOption: SortOptions.User,
    adminSortOrder: SortOrders.Asc,
};

export const initialBaseFilterOptionsObject: BaseFilterOptions = {
    filterOptions: FilterOptions.All,
};

export const initialBaseFilterAntSortOptions: BaseFilterAntSortOptions = {
    ...initialBaseSortOptionsObject,
    ...initialBaseFilterOptionsObject,
};

export const initialDefaultFilterObject: DefaultFilterObject = {
    roles: null,
    activityTypes: null,
    templateTypes: null,
    currentUserTemplates: false, //will be removed to TemplateStoreFilterObject
    tags: null,
    clients: null,
    phases: null,
    subPhases: null,
    fromDate: null,
    toDate: null,
    startDate: null,
    endDate: null,
    statuses: null,
    sortOrder: SortOrders.Dsc,
    sortOption: SortOptions.Updated,
    collectionsSortOption: CollectionSortOptions.Name,
    collectionsSortOrder: SortOrders.Asc,
    adminSortOption: SortOptions.User,
    adminSortOrder: SortOrders.Asc,
};

export const initialTemplatesFilterObject: DefaultFilterObject = {
    ...initialDefaultFilterObject,
    sortOption: SortOptions.Created,
};

export const initialDefaultCollectionSortObject: DefaultCollectionSortObject = {
    collectionsSortOption: CollectionSortOptions.Name,
    collectionsSortOrder: SortOrders.Asc,
};

// TODO: Remove after project deperecation
export const initialUserProjectSessionFilterObject: DefaultFilterObject = {
    ...initialDefaultFilterObject,
    sortOrder: SortOrders.Asc,
    sortOption: SortOptions.Name,
};

export const initialUserSessionFilterObject: DefaultFilterObject = {
    ...initialDefaultFilterObject,
    sortOrder: SortOrders.Asc,
    sortOption: SortOptions.Name,
};

export const initialFeaturedTemplatesFilterObject: DefaultFilterObject = {
    ...initialDefaultFilterObject,
    sortOption: SortOptions.Updated,
    sortOrder: SortOrders.Dsc,
};

export const userProjectSessionSortOptionsValues = {
    [SortOptions.Name]: 'Name',
};

export const collectionOptionValueName = {
    name: 'Options name',
    frequency: 'Frequency of use',
    projectName: 'Option name',
};

export const collectionSortOptionsValues = {
    [CollectionSortOptions.Frequency]: collectionOptionValueName.frequency,
    [CollectionSortOptions.Name]: collectionOptionValueName.name,
};

export interface CollectionsSortObject {
    sortOrder?: SortOrders;
    sortOption?: CollectionSortOptions;
}
