import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { isUndefined } from 'lodash';
import { map, Observable, skipWhile, take } from 'rxjs';

import { AppState, selectAuthenticatedUser } from '@accenture/global-store';
import { UserClaim } from '@accenture/shared/data';

@Injectable()
export class UserAdminGuard {
    constructor(private router: Router, private store: Store<AppState>) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.verifyAdminUserAccess();
    }

    private verifyAdminUserAccess(): Observable<boolean> {
        return this.store.select(selectAuthenticatedUser).pipe(
            skipWhile(user => isUndefined(user.roles?.[UserClaim.Admin])),
            take(1),
            map(user => {
                if (user.roles?.[UserClaim.Admin]) {
                    return true;
                }
                this.redirectToHome();
                return false;
            }),
        );
    }

    private redirectToHome(): void {
        this.router.navigate(['/home']);
    }
}
