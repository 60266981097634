import { Injectable } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { distinctUntilChanged, map, startWith, switchMap } from 'rxjs/operators';

import { CollectionsService, UserSessionService } from '@accenture/erp-deployment/shared/domain';
import { AppState, selectAuthenticatedUserId } from '@accenture/global-store';
import {
    CollectionSession,
    errorMessageSnackbarText,
    removeSessionDoneText,
    removeSessionDoneTitle,
    removeSessionErrorTitle,
    removeSessionText,
    removeSessionTitle,
    UserSession,
} from '@accenture/shared/data';
import { SnackbarService } from '@accenture/shared/ui';

import { RemoveFromCollectionDialogueComponent } from './remove-from-collection-dialogue.component';

export interface RemoveFromCollectionDialogModel {
    userSession: UserSession;
    collectionName: string;
    isLoading: boolean;
}

const defaultViewModel: RemoveFromCollectionDialogModel = {
    userSession: {} as UserSession,
    collectionName: '',
    isLoading: true,
};

@Injectable()
export class RemoveFromCollectionDialogueFacade {
    private sessionId!: string;
    private collectionName: string;
    private sessionId$ = new BehaviorSubject<string>(null);

    vm$ = this.buildViewModel();

    constructor(
        private dialogRef: MatDialogRef<RemoveFromCollectionDialogueComponent>,
        private snackbarService: SnackbarService,
        private collectionsService: CollectionsService,
        private store: Store<AppState>,
        private userSessionService: UserSessionService,
    ) {}

    async removeFromCollection(): Promise<void> {
        this.snackbarService.showInfoSnackBar(removeSessionTitle, this.handleDescription(removeSessionText));

        try {
            this.closeDialog();
            await this.collectionsService.removeFromCollection(this.sessionId);
            this.snackbarService.showSuccessSnackBar(
                removeSessionDoneTitle,
                this.handleDescription(removeSessionDoneText),
            );
        } catch (err) {
            this.snackbarService.showErrorSnackBar(removeSessionErrorTitle, errorMessageSnackbarText);
            console.error('Removing Session error', err);
        }
    }

    closeDialog(): void {
        this.dialogRef.close();
    }

    updateSessionData(data: { session: CollectionSession | UserSession; collectionName: string }): void {
        this.sessionId$.next(data.session.sessionId);
        this.collectionName = data.collectionName;
    }

    private buildViewModel(): Observable<RemoveFromCollectionDialogModel> {
        return combineLatest([
            this.sessionId$.asObservable().pipe(distinctUntilChanged()),
            this.store.select(selectAuthenticatedUserId),
        ]).pipe(
            switchMap(([sessionId, userId]) => {
                const userSession$ = this.userSessionService.getByUserAndSessionNew(userId, sessionId);
                this.sessionId = sessionId;
                return combineLatest([userSession$]).pipe(
                    map(([userSession]) => {
                        return {
                            userSession,
                            collectionName: this.collectionName,
                            isLoading: false,
                        } as RemoveFromCollectionDialogModel;
                    }),
                );
            }),
            startWith(defaultViewModel),
        );
    }

    private handleDescription(description: string): string {
        return `${description} "${this.truncateToSingleLine(this.collectionName)}"`;
    }

    private truncateToSingleLine(description: string): string {
        const maxLength = 38;

        return description.length > maxLength ? description.slice(0, maxLength - 4) + '...' : description;
    }
}
