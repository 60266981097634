import { GroupColors } from '../activity-group';
import { Dictionary } from '../dictionary';

export const DefaultCollectionColor = '#D1CDFF';
export const collectionColors = [DefaultCollectionColor, ...Object.values(GroupColors)];

export const displayCollectionColorsName: Dictionary<string> = {
    [DefaultCollectionColor]: 'Default Color',
    [GroupColors.Red]: 'Red',
    [GroupColors.Orange]: 'Orange',
    [GroupColors.Yellow]: 'Yellow',
    [GroupColors.Blue]: 'Blue',
    [GroupColors.LightBlue]: 'Light Blue',
    [GroupColors.Green]: 'Green',
    [GroupColors.LightGreen]: 'Light Green',
    [GroupColors.Teal]: 'Teal',
    [GroupColors.Violet]: 'Violet',
    [GroupColors.Purple]: 'Purple',
};
