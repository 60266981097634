import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import {
    betaHintActivity,
    characterLimitLarge,
    characterLimitText,
    determineHintClass,
    validationMessages,
} from '@accenture/shared/data';
import { DialogService } from '@accenture/shared/ui';

interface InputData {
    title: string;
    header: string;
    confirm: string;
    placeholder: string;
    isGenerateActivity: boolean;
    onAccept: (prompt) => void;
}

type AiPromptsForm = FormGroup<{
    generateActivityControl: FormControl<string>;
    summarizeControl: FormControl<string>;
}>;

@Component({
    selector: 'accenture-ai-prompt-entry-dialog',
    templateUrl: './ai-prompt-entry-dialog.component.html',
    styleUrls: ['./ai-prompt-entry-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AiPromptEntryDialogComponent {
    placeholderPromptText!: string;
    headerActivity!: string;
    characterLimitLarge = characterLimitLarge;
    validationMessages = validationMessages;
    betaHintActivity = betaHintActivity;
    characterLimitText = characterLimitText;
    determineHintClass = determineHintClass;

    aiPromptsForm: AiPromptsForm = new FormGroup({
        generateActivityControl: new FormControl('', { validators: Validators.required, nonNullable: true }),
        summarizeControl: new FormControl('', { nonNullable: true }),
    });

    constructor(@Inject(MAT_DIALOG_DATA) public data: InputData, private dialogService: DialogService) {
        this.placeholderPromptText = data.placeholder;
        this.headerActivity = data.header;
    }

    close(): void {
        this.dialogService.close();
    }

    accept(prompt: string): void {
        this.data.onAccept(prompt);
        this.dialogService.close();
    }
}
