export class Attribute {
    id: string;
    classId: string;
    name: string;
    sequence: string;

    constructor(data: Partial<Attribute>) {
        this.id = data.id ?? '';
        this.classId = data.classId ?? '';
        this.name = data.name ?? '';
        this.sequence = data.sequence ?? '';
    }

    createSerializableObject(): Attribute {
        return {
            classId: this.classId,
            name: this.name,
            sequence: this.sequence,
        } as Attribute;
    }
}

export const defaultAttributeId = 'DEFAULT_ATTRIBUTE';
export const defaultAttributeClassId = 'DEFAULT_ATTRIBUTE_CLASS';
export const defaultAttribute = {
    id: defaultAttributeId,
    name: 'Default',
} as Attribute;

export const allAttributeId = 'ALL_ATTRIBUTE';
export const allAttribute = {
    id: allAttributeId,
    name: 'All',
} as Attribute;
