import { Dictionary } from '../dictionary';
import { ImportResultType, ImportType } from './import-type';

export enum ImportErrorType {
    InvalidImportTypeError,
    WorkbookNotFoundOnGoogleDriveError,
    TableImportWarning,
    EmailsImportWarning,
}

export class InvalidImportTypeError extends Error {
    constructor(public importType: ImportType, public resultType?: ImportResultType) {
        super('Invalid import types.');
    }
}

export class WorkbookNotFoundOnGoogleDriveError extends Error {
    constructor(public spreadsheetId: string) {
        super(`Workbook with spreadsheetId=${spreadsheetId} is not found on Google Drive.`);
    }
}

export class TableImportWarning extends Error {
    constructor(public columns: string[], public cells: Dictionary<string[]>, public activityItemId: string) {
        super('Table Import Warnings');
    }
}

export class EmailsImportWarning extends Error {
    constructor(public emails: string[]) {
        super('Email Import Warnings');
    }
}
