import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { CollectionSession } from '@accenture/shared/data';
import { LoadedDescription } from '@accenture/shared/ui';

import { AddExistingSessionDialogFacade, SelectedSession } from './add-existing-session-dialog-facade';

@Component({
    selector: 'accenture-add-existing-session-dialog',
    templateUrl: './add-existing-session-dialog.component.html',
    styleUrls: ['./add-existing-session-dialog.component.scss'],
    providers: [AddExistingSessionDialogFacade],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddExistingSessionDialogComponent {
    @Input() collection: CollectionSession[];
    loaderText: LoadedDescription;
    vm$ = this.facade.vm$;

    constructor(private facade: AddExistingSessionDialogFacade) {}

    closeCurrentDialog(): void {
        this.facade.closeCurrentDialog();
    }

    updateSearchValue(searchValue: string): void {
        this.facade.updateSearchValue(searchValue);
    }

    addRemoveSelectedSession(selectedSession: SelectedSession): void {
        this.facade.addRemoveSelectedSession(selectedSession);
    }

    addAllSession(): void {
        this.facade.addAllSession();
    }

    removeAllSession(): void {
        this.facade.removeAllSession();
    }

    addExistingSessions(): void {
        this.facade.addSessionsToCollection();
    }
}
