import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { EmptyScreenType, imageUrl } from '@accenture/shared/data';

@Component({
    selector: 'accenture-empty-screen',
    templateUrl: './empty-screen.component.html',
    styleUrls: ['./empty-screen.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmptyScreenComponent {
    @Input() emptyScreenType?: EmptyScreenType = EmptyScreenType.Default;
    @Input() title!: string;
    @Input() description?: string;
    @Input() buttonText?: string;
    @Input() buttonIcon?: string;
    @Input() imageUrl?: { path: string } = imageUrl;
    @Input() isButtonVisible? = true;
    @Input() buttonClass?: string;
    @Input() isHomepage?: boolean = false;
    @Input() userGuest?: boolean;

    @Output() buttonClick = new EventEmitter();

    emptyScreenTypes = EmptyScreenType;
}
