<ng-container *ngIf="vm$ | async as vm">
  <ng-container *accentureLoaded="!vm.isLoading; description: loaderText">
    <accenture-base-dialog [title]="'Add existing session'">
      <div class="content-container">
        <accenture-add-existing-session-table
          [userSessions]="vm.userSessions"
          [selectedSessionsMap]="vm.selectedSessionsMap"
          [isAllSelected]="vm.isAllSelected"
          [selectedSessionsMapIds]="vm.selectedSessionsMapIds"
          (selectedSession)="addRemoveSelectedSession($event)"
          (addAllSession)="addAllSession($event)"
          (removeAllSession)="removeAllSession($event)"
          (searchValue)="updateSearchValue($event)"
        />
      </div>

      <div class="footer d-flex mb-3">
        <span class="tt9-body-1 mr-auto tt9-color-neutral-500">
          {{ vm.selectedSessionsMap.length ? vm.selectedSessionsMapIds.length + " session(s) selected" : " " }}
        </span>
        <button class="tt9-btn-default back-btn" (click)="closeCurrentDialog()">Cancel</button>
        <button class="tt9-btn ml-16" [disabled]="!vm.selectedSessionsMapIds.length" (click)="addExistingSessions()">
          Add
        </button>
      </div>
    </accenture-base-dialog>
  </ng-container>
</ng-container>
