import * as fromRouter from '@ngrx/router-store';

import * as fromApplicationConfiguration from './application-configuration/application-configuration.reducer';
import * as fromAttributes from './attributes/attributes.reducer';
import * as fromAuth from './auth/auth.reducer';
import * as fromCollection from './collection/collection.reducer';
import * as fromDashboardSession from './dashboard/dashboard.reducer';
import * as fromProject from './project/project.reducer';
import * as fromPublicTemplate from './public-template/public-template.reducer';
import { RouterStateUrl } from './router/router.reducer';
import * as fromSession from './session/session.reducer';

export interface AppState {
    router: fromRouter.RouterReducerState<RouterStateUrl>;
    authenticatedUser: fromAuth.AuthenticatedUserState;
    attributes: fromAttributes.AttributesState;
    project: fromProject.ProjectState;
    session: fromSession.SessionState;
    applicationConfiguration: fromApplicationConfiguration.ApplicationConfigurationState;
    publicTemplate: fromPublicTemplate.PublicTemplateState;
    collection: fromCollection.CollectionState;
    dashboardSession: fromDashboardSession.DashboardSessionState;
}

export const initialState = {
    router: {},
    authenticatedUser: {},
    attributes: [],
    project: {},
    applicationConfiguration: {},
    publicTemplate: {},
    collection: {},
};
