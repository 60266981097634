<div *ngIf="vm$ | async as vm" class="wrapper">
  <ng-container *accentureLoaded="!vm.isLoading">
    <div class="home-header">
      <div class="user-profile-image-container tt9-userpic large">
        <img *ngIf="vm.user.imageUrl" class="user-profile-image" [src]="vm.user.avatar" [alt]="vm.user.displayName" />
        <span *ngIf="!vm.user.imageUrl" class="user-profile-image-initials d-flex-center tt9-h3">
          {{ vm.user.displayName | userInitials }}
        </span>
      </div>

      <div class="user-name">
        <p class="tt9-h1">Hello {{ vm.user.displayName }}</p>
        <p class="tt9-h4">It's great to see you!</p>
      </div>

      <button
        class="tt9-btn-icon-filled btn-rounded btn-edit"
        matTooltipPosition="left"
        matTooltipClass="tt9-arrow-tooltip-right"
        [matTooltip]="tooltipTexts.editProfile"
        [routerLink]="['/profile']"
      >
        <accenture-icon>icon-edit</accenture-icon>
      </button>
    </div>

    <div class="dashboard-content">
      <div class="dashboard-projects-wrapper">
        <accenture-projects-list></accenture-projects-list>
      </div>

      <div class="dashboard-templates-wrapper">
        <div class="templates-header d-flex-between">
          <span class="tt9-h2">My Templates</span>

          <button
            *ngIf="templatesList.hasVisibleTemplates"
            class="tt9-btn btn-icon"
            (click)="navigateToTemplatesPage()"
          >
            <accenture-icon class="mr-8">icon-template</accenture-icon>
            <span>Get More Templates</span>
          </button>
        </div>

        <accenture-templates-list #templatesList></accenture-templates-list>
      </div>
    </div>
  </ng-container>
</div>
