import { TableStatus } from '../activity-items';
import { Dictionary } from '../dictionary';
import { NodeTemplate, NodeType } from '../node-template';
import { FileType } from '../responses';
import { UpdatedByUser } from '../user';
import { Activity } from './activity';
import { ActivityType } from './activity-type';

export class TableActivity extends NodeTemplate implements Activity {
    projectId?: string;
    templateId?: string;
    sessionId?: string;
    sectionId?: string;
    type = ActivityType.Table;
    sequence: string;
    visible: boolean;
    allowChangeAnswers: boolean;
    validate: boolean;
    validateRequired: boolean;
    creatorId?: string;
    creatorName?: string;
    creatorImage?: string;
    favoritesCount?: number;

    // pre validation
    waitingScreenVisible?: boolean;
    waitingScreenText?: string;
    waitingScreenImage?: FileType;

    // pre summary
    statusScreenVisible?: boolean;
    statusScreenText?: string;
    statusScreenImage?: FileType;
    tags?: Dictionary<string>;

    // for public activity templates
    timesUsed?: number;
    creatorNotes?: string;
    updatedBy?: UpdatedByUser;

    dndDisabled?: boolean;

    constructor(id: string | undefined, tableActivity: Partial<TableActivity>) {
        super(id, tableActivity, NodeType.Activity);

        this.projectId = tableActivity.projectId ?? '';
        this.templateId = tableActivity.templateId ?? '';
        this.sessionId = tableActivity.sessionId ?? '';
        this.sectionId = tableActivity.sectionId;
        this.sequence = tableActivity.sequence ?? '';
        this.visible = tableActivity.visible ?? true;
        this.allowChangeAnswers = tableActivity.allowChangeAnswers ?? false;
        this.validate = tableActivity.validate ?? false;
        this.validateRequired = tableActivity.validateRequired ?? false;
        this.waitingScreenVisible = tableActivity.waitingScreenVisible ?? false;
        this.waitingScreenText = tableActivity.waitingScreenText ?? '';
        this.waitingScreenImage = tableActivity.waitingScreenImage ?? ({} as FileType);
        this.statusScreenVisible = tableActivity.statusScreenVisible ?? false;
        this.statusScreenText = tableActivity.statusScreenText ?? '';
        this.statusScreenImage = tableActivity.statusScreenImage ?? ({} as FileType);
        this.creatorId = tableActivity.creatorId ?? '';
        this.creatorImage = tableActivity.creatorImage ?? '';
        this.creatorName = tableActivity.creatorName ?? '';
        this.favoritesCount = tableActivity.favoritesCount ?? 0;
        this.tags = (tableActivity.tags as Dictionary<string>) ?? {};

        this.creatorNotes = tableActivity.creatorNotes ?? '';
        this.timesUsed = tableActivity.timesUsed ?? 0;
        this.updatedBy = tableActivity.updatedBy ?? undefined;

        this.dndDisabled = !!tableActivity.dndDisabled;
    }

    createSerializableObject(): Record<string, unknown> {
        return {
            id: this.id,
            projectId: this.projectId,
            templateId: this.templateId,
            sessionId: this.sessionId,
            sectionId: this.sectionId,
            name: this.name,
            description: this.description,
            sequence: this.sequence,
            visible: this.visible,
            allowChangeAnswers: this.allowChangeAnswers,
            validate: this.validate,
            validateRequired: this.validateRequired,
            waitingScreenVisible: this.waitingScreenVisible,
            waitingScreenText: this.waitingScreenText,
            waitingScreenImage: this.waitingScreenImage,
            statusScreenVisible: this.statusScreenVisible,
            statusScreenText: this.statusScreenText,
            statusScreenImage: this.statusScreenImage,
            type: this.type,
            created: this.created,
            updated: this.updated,
            creatorId: this.creatorId,
            creatorImage: this.creatorImage,
            creatorName: this.creatorName,
            creatorNotes: this.creatorNotes,
            timesUsed: this.timesUsed,
            tags: this.tags,
        };
    }
}

export const tableActivitySteps = [
    {
        id: TableStatus.Setup,
        label: 'SETUP',
    },
    {
        id: TableStatus.Validate,
        label: 'VALIDATION',
    },
    {
        id: TableStatus.Summary,
        label: 'SUMMARY',
    },
];
