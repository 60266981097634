import { ChangeDetectionStrategy, Component, Inject, OnDestroy } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import {
    characterLimitMedium,
    characterLimitText,
    characterLimitXL,
    determineHintClass,
    FileType,
    User,
} from '@accenture/shared/data';
import { ImageInputMode, ImageInputPlaceholdersTypes, LoadedDescription } from '@accenture/shared/ui';

import { CreateProjectDialogFacade } from './create-project-dialog-facade';

type ProjectForm = FormGroup<{
    name: FormControl<string>;
    description: FormControl<string>;
}>;

// TODO: REMOVE AFTER COLLECTIONS WILL BE IMPLEMENTED
@Component({
    selector: 'accenture-create-project-dialog',
    templateUrl: './create-project-dialog.component.html',
    styleUrls: ['./create-project-dialog.component.scss'],
    providers: [CreateProjectDialogFacade],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreateProjectDialogComponent implements OnDestroy {
    vm$ = this.facade.vm$;

    loaderText: LoadedDescription;
    imageInputMode = ImageInputMode;
    imageInputPlaceholder = ImageInputPlaceholdersTypes.Project;

    characterLimitMedium = characterLimitMedium;
    descriptionCharacterLimit = characterLimitXL;
    characterLimitText = characterLimitText;
    determineHintClass = determineHintClass;

    projectForm: ProjectForm = this.formBuilder.group({
        name: new FormControl('', { nonNullable: true, validators: Validators.required }),
        description: new FormControl('', { nonNullable: true }),
    });

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: { title: string; createOnlyProject: boolean },
        private facade: CreateProjectDialogFacade,
        private formBuilder: FormBuilder,
    ) {}

    setProjectImage(projectImage: FileType): void {
        this.facade.setProjectImage(projectImage);
    }

    deleteProjectImage(): void {
        const projectImage = {} as FileType;

        this.facade.setProjectImage(projectImage);
    }

    async createProject(user: User, projectImage: FileType): Promise<void> {
        try {
            const { name, description } = this.projectForm.value;
            await this.facade.createProject(name, description, user, projectImage, this.data.createOnlyProject);
        } catch (e) {
            console.log('Error while creating project', e);
        }
    }

    openUseTemplateModal(isChipsOpen?: boolean): void {
        this.facade.backToProjectData();
        this.facade.openUseTemplateModal(this.data.createOnlyProject, isChipsOpen);
    }

    ngOnDestroy(): void {
        this.facade.openUseTemplateModal(false, true);
        this.facade.deleteUnusedData();
    }
}
