import { Injectable } from '@angular/core';
import { groupBy } from 'lodash';
import { firstValueFrom, Observable } from 'rxjs';

import { BrainstormResponse, Comment, DBPathHelper, ParentType } from '@accenture/shared/data';
import { FirestoreService } from '@accenture/shared/data-access';

import { BrainstormGroupsService } from './brainstorm-groups.service';

@Injectable({
    providedIn: 'root',
})
export class ActivityTT9CommentsService {
    constructor(private firestoreService: FirestoreService, private brainstormGroupsService: BrainstormGroupsService) {}

    getSessionCommentsPath(sessionId: string, commentId?: string): string {
        return DBPathHelper.getCommentPath(ParentType.Sessions, sessionId, commentId);
    }

    getSessionResponsePath(sessionId: string, responseId?: string): string {
        return DBPathHelper.getResponsesPath(ParentType.Sessions, sessionId, responseId);
    }

    async addCommentToResponse(sessionId: string, comment: Comment): Promise<string> {
        const commentId = this.firestoreService.getPushId();
        const serializableComment = comment.createSerializableObject();
        const fanOutWrites = [
            {
                path: this.getSessionCommentsPath(sessionId, commentId),
                data: serializableComment,
            },
            {
                path: this.getSessionResponsePath(sessionId, serializableComment.responseId),
                data: { commentsCount: this.firestoreService.changeCounterValue(1) },
            },
        ];
        await this.firestoreService.writeBatch(fanOutWrites);
        return commentId;
    }

    deleteComment(sessionId: string, comment: Comment): Promise<void> {
        const batch = this.firestoreService.createBatch();
        const db = this.firestoreService.firestoreRef.firestore;
        batch.delete(db.doc(this.getSessionCommentsPath(sessionId, comment.id)));
        batch.update(db.doc(this.getSessionResponsePath(sessionId, comment.responseId)), {
            commentsCount: this.firestoreService.changeCounterValue(-1),
        });
        return batch.commit();
    }

    async deleteComments(
        sessionId: string,
        activityItemId: string,
        commentsIds?: string[],
        comments?: Comment[],
    ): Promise<void> {
        const selectedComments = comments?.length
            ? comments
            : (await firstValueFrom(this.getCommentsByActivityItemId(sessionId, activityItemId))).filter((comment) =>
                  commentsIds.includes(comment.id),
              );
        const dataForUpdate = [];
        const dataForDelete = [];

        selectedComments.forEach((comment) => {
            dataForDelete.push({ path: this.getSessionCommentsPath(sessionId, comment.id) });

            dataForUpdate.push({
                path: this.getSessionResponsePath(sessionId, comment.responseId),
                data: { commentsCount: this.firestoreService.changeCounterValue(-1) },
            });
        });

        await this.firestoreService.deleteBatch(dataForDelete);
        await this.firestoreService.updateBatch(dataForUpdate);
    }

    getCommentsForResponseOrderByUpdated(sessionId: string, responseId: string): Observable<Comment[]> {
        return this.firestoreService.getDocumentsByProperty<Comment>(
            this.getSessionCommentsPath(sessionId),
            'responseId',
            responseId,
            'updated',
        );
    }

    updateCommentValue(sessionId: string, commentId: string, value: string): Promise<void> {
        return this.firestoreService.updateDoc(this.getSessionCommentsPath(sessionId, commentId), { value });
    }

    getCommentsByActivityId(sessionId: string, activityId: string): Observable<Comment[]> {
        return this.firestoreService.getDocumentsByProperty<Comment>(
            this.getSessionCommentsPath(sessionId),
            'activityId',
            activityId,
        );
    }

    getCommentsByActivityItemId(sessionId: string, activityItemId: string): Observable<Comment[]> {
        return this.firestoreService.getDocumentsByProperty<Comment>(
            this.getSessionCommentsPath(sessionId),
            'activityItemId',
            activityItemId,
        );
    }

    getCommentsByActivityItemIdWithoutCaching(sessionId: string, activityItemId: string): Observable<Comment[]> {
        return this.firestoreService.getDocumentsByPropertyWithoutCaching<Comment>(
            this.getSessionCommentsPath(sessionId),
            'activityItemId',
            activityItemId,
        );
    }

    async promoteCommentsTo(
        sessionId: string,
        baseResponseObj: Partial<BrainstormResponse>,
        commentsIds: string[],
        responses: BrainstormResponse[],
    ): Promise<void> {
        const dataToWrite = [];
        let groupsToUpdate = {};
        const selectedComments = (
            await firstValueFrom(this.getCommentsByActivityItemId(sessionId, baseResponseObj.activityItemId))
        ).filter((comment) => commentsIds.includes(comment.id));
        const responsesMap = groupBy(responses, 'id');

        await this.deleteComments(sessionId, baseResponseObj.activityItemId, [], selectedComments);

        selectedComments.forEach((comment) => {
            const currentsGroupsIds = responsesMap[comment.responseId][0]?.groups;

            const newResponseObj = {
                ...baseResponseObj,
                userId: comment.userId,
                userDisplayName: comment.userDisplayName,
                userImageUrl: comment.userImageUrl,
                activityId: comment.activityId,
                value: comment.value,
                groups: currentsGroupsIds || [],
            };
            const newResponse = new BrainstormResponse(newResponseObj);

            if (currentsGroupsIds.length) {
                currentsGroupsIds.forEach((groupId) => {
                    groupsToUpdate = {
                        ...groupsToUpdate,
                        [groupId]: groupsToUpdate[groupId] + 1 || 1,
                    };
                });
            }

            dataToWrite.push({
                path: this.getSessionResponsePath(sessionId, comment.id),
                data: newResponse.createSerializableObject(),
            });
        });

        // TODO brainstorm activity formatting needed
        // await this.brainstormGroupsService.updateGroupsCount(sessionId, baseResponseObj.activityItemId, groupsToUpdate);

        await this.firestoreService.setBatch(dataToWrite);
    }

    updateCommentValueNew(sessionId: string, commentId: string, value: string): Promise<void> {
        return this.firestoreService.updateDoc(this.getSessionCommentsPath(sessionId, commentId), { value });
    }

    async mergeComments(
        responseId: string,
        selectedQuestionId: string,
        sessionId: string,
        commentsToMerge: Set<string>,
    ): Promise<void> {
        const dataToUpdate = [];
        const selectedComments = (
            await firstValueFrom(this.getCommentsByActivityItemId(sessionId, selectedQuestionId))
        ).filter((comment) => commentsToMerge.has(comment.id));

        selectedComments.forEach((comment: Comment) => {
            dataToUpdate.push(
                {
                    path: this.getSessionCommentsPath(sessionId, comment.id),
                    data: {
                        ...comment,
                        responseId,
                        created: this.firestoreService.currentTimestamp,
                        updated: this.firestoreService.currentTimestamp,
                    },
                },
                {
                    path: DBPathHelper.getResponsesPath(ParentType.Sessions, sessionId, comment.responseId),
                    data: {
                        commentsCount: this.firestoreService.changeCounterValue(-1),
                    },
                },
            );
        });
        dataToUpdate.push({
            path: DBPathHelper.getResponsesPath(ParentType.Sessions, sessionId, responseId),
            data: {
                commentsCount: this.firestoreService.changeCounterValue(selectedComments.length),
            },
        });

        await this.firestoreService.updateBatch(dataToUpdate);
    }
}
