import { removeEmptyKeys } from '@accenture/shared/util';

import { Dictionary } from '../dictionary';
import { NodeTemplate, NodeType } from '../node-template';
import { DefaultCollectionColor } from './collection-colors';

export class Collection extends NodeTemplate {
    creatorId: string;
    creatorName: string;
    creatorImage?: string;
    color?: string;
    tags?: Dictionary<string>;

    constructor(id: string, data: Partial<Collection>) {
        super(id, data, NodeType.Collection);
        this.creatorId = data.creatorId ?? '';
        this.creatorName = data.creatorName ?? '';
        this.creatorImage = data.creatorImage ?? '';
        this.tags = (data.tags as Dictionary<string>) ?? {};
        this.color = data.color ?? DefaultCollectionColor;
    }

    createSerializableObject(): Record<string, unknown> {
        return removeEmptyKeys({
            name: this.name,
            description: this.description,
            imageUrl: this.imageUrl,
            imageId: this.imageId,
            color: this.color,
            creatorId: this.creatorId,
            creatorName: this.creatorName,
            creatorImage: this.creatorImage,
            created: this.created,
            updated: this.updated,
        });
    }
}
