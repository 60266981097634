import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { isUndefined } from 'lodash';
import { map, Observable, skipWhile, switchMap, take } from 'rxjs';

import { AppState, selectAuthenticatedUser } from '@accenture/global-store';
import { ProjectRole } from '@accenture/shared/data';

import { UserAccessService } from '../services/user-access.service';
import { getRouterParams } from '../utils';

@Injectable()
export class ProjectAdminGuard {
    constructor(private router: Router, private store: Store<AppState>, private userAccessService: UserAccessService) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        const { projectId } = getRouterParams(route);

        return this.verifyProjectAdminRole(projectId);
    }

    private verifyProjectAdminRole(projectId: string): Observable<boolean> {
        return this.store.select(selectAuthenticatedUser).pipe(
            skipWhile(user => isUndefined(user.id)),
            switchMap(user => this.userAccessService.getUserAssignmentByProjectId(user.id, projectId)),
            take(1),
            map(userAccess => {
                if (userAccess?.role === ProjectRole.Admin) {
                    return true;
                }

                this.redirectToHome();
                return false;
            }),
        );
    }

    private redirectToHome(): void {
        this.router.navigate(['/home']);
    }
}
