import { FileType } from '../responses';

export class OptionsListItem {
    id: string;
    label: string;
    sequence: string;
    image?: FileType;
    color?: string;

    constructor(data: Partial<OptionsListItem>) {
        this.id = data.id;
        this.label = data.label ?? '';
        this.sequence = data.sequence ?? '';
        this.image = data.image ?? ({} as FileType);
        this.color = data.color ?? '';
    }
}
