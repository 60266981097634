<ng-container *ngIf="vm$ | async as vm">
  <ng-container *accentureLoaded="!vm.isLoading">
    <accenture-base-dialog [title]="data?.title">
      <form class="attributes">
        <div class="attributes-content">
          <div class="attributes-content-header" [formGroup]="attributesClassForm">
            <mat-form-field appearance="outline" floatLabel="auto" class="tt9-form-field width-100">
              <mat-label>Attribute group name</mat-label>
              <input
                #inputAttributeGroupName
                matInput
                type="text"
                formControlName="attributesClassName"
                [maxlength]="characterLimitMedium"
              />
              <mat-hint
                class="mat-hint-limit"
                [class]="determineHintClass(inputAttributeGroupName.value.length, characterLimitMedium)"
                >{{ characterLimitText(inputAttributeGroupName.value.length, characterLimitMedium) }}</mat-hint
              >
              <mat-error *ngIf="attributesClassNameControl?.hasError(validationErrors.required)">{{
                validationMessages.required
              }}</mat-error>
            </mat-form-field>

            <mat-form-field
              *ngIf="vm.attributes.length || (!vm.attributes.length && !!vm.searchValue)"
              appearance="outline"
              floatLabel="always"
              class="tt9-form-field width-100 mt-16"
            >
              <input
                #search
                matInput
                placeholder="Search"
                [formControl]="searchControl"
                (input)="updateSearchValue(search.value)"
                (keydown.enter)="$event.preventDefault()"
              />
              <accenture-icon matPrefix>icon-search</accenture-icon>
              <accenture-icon *ngIf="search.value" matSuffix (click)="resetSearchValue()">icon-close</accenture-icon>
            </mat-form-field>
          </div>

          <div
            *ngIf="vm.attributes.length"
            #attributesContainer
            cdkDropList
            cdkDropListLockAxis="y"
            cdkDropListOrientation="vertical"
            class="attributes-content-container"
            [cdkDropListDisabled]="!!vm.searchValue"
            (cdkDropListDropped)="updateSequence($event)"
          >
            <div class="attributes-list">
              <div
                #attributeElement
                *ngFor="let attribute of vm.attributes; trackBy: trackById; last as isLast; let i = index"
                cdkDrag
                class="attribute accenture-drag-left-container"
                [cdkDragData]="attribute"
                (click)="$event.stopPropagation()"
              >
                <button cdkDragHandle class="drag-left-handler accenture-btn-icon drag-button pl-12">
                  <accenture-icon>icon-drag-vertical</accenture-icon>
                </button>

                <div *cdkDragPlaceholder class="accenture-drag-left-placeholder-container">
                  <div class="accenture-drag-left-placeholder attributes-drag-placeholder">
                    <p class="attribute-name">{{ attribute.name }}</p>
                    <accenture-icon>icon-close</accenture-icon>
                  </div>
                </div>

                <div *cdkDragPreview></div>

                <mat-form-field
                  appearance="outline"
                  floatLabel="always"
                  class="attribute-input tt9-form-field no-margin no-label invisible"
                  (change)="updateAttribute(attribute.id, $event.target.value)"
                >
                  <input
                    #inputAttributeName
                    matInput
                    placeholder="Attribute Name"
                    [attr.maxLength]="characterLimitMedium"
                    [value]="attribute.name"
                    [id]="attribute?.id"
                    (focus)="onFocusAttribute(attribute.id)"
                    (blur)="onBlurAttribute()"
                  />
                  <mat-hint
                    class="mat-hint-limit"
                    [class]="determineHintClass(inputAttributeName.value.length, characterLimitMedium)"
                    >{{ characterLimitText(inputAttributeName.value.length, characterLimitMedium) }}</mat-hint
                  >
                  <accenture-icon matSuffix (click)="removeAttribute(attribute)">icon-close</accenture-icon>
                </mat-form-field>
              </div>
            </div>
          </div>

          <div *ngIf="isShowSeparateLine()" class="separator"></div>

          <div *ngIf="!vm.attributes.length && !!vm.searchValue" class="attributes-empty-container">
            <accenture-empty-screen
              [title]="emptyScreenTitles.noAttributesMatch"
              [imageUrl]="noAttributesFindImageUrl"
            ></accenture-empty-screen>
          </div>

          <p *ngIf="!vm.attributes.length && !vm.searchValue" class="tt9-body-1 empty-screen-title">
            {{ emptyScreenTitles.noAttributesAddedYet }}
          </p>

          <div *ngIf="!vm.searchValue" class="attributes-content-buttons">
            <button class="tt9-btn-ghost btn-icon add-attribute-btn" (click)="addAttribute($event)">
              <accenture-icon>icon-add</accenture-icon>
              Add attribute
            </button>

            <accenture-import-button-tt9
              class="colored-import-button"
              [importType]="importType"
              [importResultType]="importResultType"
              [importTitle]="'Import attributes'"
              [parentType]="vm.parentType"
              [showButtonText]="true"
              (importCompleted)="importAttributes($event)"
             />
          </div>
        </div>

        <div class="footer">
          <div class="update-container" *ngIf="vm.attributesHasUpdates && !vm.isLoading">
            <span>Attributes edit data updates available</span>
            <span class="update-btn" (click)="updateAttributeValue()">Update</span>
          </div>

          <div class="buttons">
            <button class="tt9-btn-default" (click)="cancelForm()">Cancel</button>
            <button class="tt9-btn ml-16" [disabled]="isSaveFormDisabled()" (click)="submitForm()">Save</button>
          </div>
        </div>
      </form>
    </accenture-base-dialog>
  </ng-container>
</ng-container>
