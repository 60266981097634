import { KeyValue } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';

import {
    characterLimitMedium,
    characterLimitText,
    CollectionOptions,
    collectionOptionsTitle,
    CollectionOptionsType,
    CollectionsSortObject,
    determineHintClass,
    Dictionary,
    newCollectionOptionText,
    OptionsForSortingByNumberOfUses,
    SelectedCollectionOptions,
    validationMessages,
} from '@accenture/shared/data';
import { alphabeticalKeyValueOrder, trackById } from '@accenture/shared/util';

import { optionPlaceholderText } from './collection-options.constants';
import { CollectionOptionsFacade, CollectionOptionsViewModel } from './collection-options-facade';

type OptionsFormGroup = FormGroup<{
    [CollectionOptions.Tag]: FormControl<string>;
}>;

@Component({
    selector: 'accenture-collection-options',
    templateUrl: './collection-options.component.html',
    styleUrls: ['./collection-options.component.scss'],
    providers: [CollectionOptionsFacade],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CollectionOptionsComponent {
    @Input() isCreateCollectionModal: boolean;
    @Input() optionsFormGroup: OptionsFormGroup;

    vm$ = this.facade.vm$;

    filteredOptions: CollectionOptionsType[] = []; // options for autocomplete

    collectionOptions = CollectionOptions;
    collectionOptionsTitle = collectionOptionsTitle;
    optionPlaceholderText = optionPlaceholderText;
    newOptionText = newCollectionOptionText;
    alphabeticalKeyValueOrder = alphabeticalKeyValueOrder;
    characterLimitMedium = characterLimitMedium;
    characterLimitText = characterLimitText;
    determineHintClass = determineHintClass;
    validationMessages = validationMessages;
    collectionToCount = OptionsForSortingByNumberOfUses;

    trackById = trackById;

    constructor(private facade: CollectionOptionsFacade, private fb: FormBuilder) {
        this.optionsFormGroup = this.fb.group(
            {
                tags: new FormControl(''),
            },
            {
                updateOn: 'blur',
            },
        );
    }

    hasSelectedOptions(collectionOptions: Dictionary<string>): boolean {
        return !!Object.keys(collectionOptions || {}).length;
    }

    displayEmptyOption(): null {
        // for matAutocomplete to display if we reset control value
        return null;
    }

    getOptionsData({ tags, currentOptionToDisplay }: CollectionOptionsViewModel): CollectionOptionsType[] {
        return tags;
    }

    isNewOptionShown(
        collectionOption: CollectionOptions,
        selectedOptions: SelectedCollectionOptions,
        value: string,
    ): boolean {
        const searchValue = value?.toLowerCase().trim();
        const optionsToDisplay = this.facade.getFilteredOptions(
            collectionOption,
            selectedOptions[collectionOption],
            searchValue,
        );
        this.filteredOptions = optionsToDisplay.filteredOptions;

        return !optionsToDisplay.isOptionExist;
    }

    getControl(collectionOption: CollectionOptions.Tag): FormControl<string> {
        return this.optionsFormGroup.controls[collectionOption];
    }

    showOptionChips(optionType: CollectionOptions, event?: Event): void {
        this.facade.showOptionChips(optionType);

        if (event) {
            event.stopPropagation();
        }
    }

    updateSelectedOptions(data: {
        option: CollectionOptionsType;
        isSelected: boolean;
        selectedOption?: CollectionOptionsType;
    }): void {
        const option = {
            [data.option.id]: data.option.name,
        } as Dictionary<string>;

        this.facade.updateCollectionOptions(option);
    }

    updateFilters(filterObject: CollectionsSortObject): void {
        this.facade.updateFilters(filterObject);
    }

    removeOptionValue(collectionOptions: CollectionOptions, selectedOption?: KeyValue<string, string>): void {
        if (selectedOption) {
            const option = {
                [selectedOption.key]: selectedOption.value,
            } as Dictionary<string>;

            this.facade.updateCollectionOptions(option, collectionOptions);
        }
    }

    updateOptionValue(selectedOption: CollectionOptionsType, collectionOptions: CollectionOptions): void {
        const option = {
            [selectedOption.id]: selectedOption.name,
        } as Dictionary<string>;

        this.facade.updateCollectionOptions(option, collectionOptions);
    }

    addNewOption(collectionOption: CollectionOptions): void {
        const controlValue = this.getControl(collectionOption).value?.trim();
        if (!controlValue) {
            return;
        }

        this.facade.addNewOption(collectionOption, controlValue);
    }
}
