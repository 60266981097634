import { Injectable } from '@angular/core';
import { MsalService } from '@azure/msal-angular';

import { MsGraphScopes } from '@accenture/shared/data';

import { TeamsService } from './teams.service';

@Injectable({
    providedIn: 'root',
})
export class MsIdentityService {
    constructor(private msalService: MsalService, private teamsService: TeamsService) {}

    async refreshAccessToken(): Promise<string | null> {
        const { inTeams } = this.teamsService;

        if (inTeams) {
            return this.teamsService.getToken();
        } else {
            const account = this.msalService.instance.getActiveAccount();

            if (account) {
                const { accessToken } = await this.msalService.instance.acquireTokenSilent({
                    account,
                    scopes: MsGraphScopes,
                });
                return accessToken;
            }
        }

        return null;
    }

    isSsoLoggedIn(): boolean {
        const { inTeams } = this.teamsService;
        if (inTeams) {
            return true;
        }
        return !!this.msalService.instance.getActiveAccount();
    }
}
