import { Pipe, PipeTransform } from '@angular/core';

import { ResponseValue } from '@accenture/shared/data';

@Pipe({
    name: 'scapeHtml',
})
export class ScapeHtmlPipe implements PipeTransform {
    transform(html: ResponseValue): string {
        if (!html || typeof html !== 'string') {
            return '';
        }
        return html
            .replace(/&/g, '&amp;')
            .replace(/</g, '&lt;')
            .replace(/>/g, '&gt;')
            .replace(/"/g, '&quot;')
            .replace(/'/g, '&#039;');
    }
}
