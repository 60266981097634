<ng-container [ngSwitch]="vm.type">
  <div
    *ngSwitchCase="imageModalType.QuickPollOption"
    #quickPollHeader
    class="image-modal-header-quick-poll-option d-flex-start"
    (accentureResized)="setQuickPollOptionImageHeight()"
  >
    <div class="option-info">
      <h3 class="tt9-h1 text-accent text-overflow-ellipsis-2">{{ vm.description }}</h3>
      <div class="d-flex mt-8">
        <span class="circle mr-8" [style.background]="vm.optionData.color"></span>
        <span class="tt9-body-1 text-accent mr-8">{{ vm.optionData.percentage }}</span>
        <span class="tt9-body-1 text-accent"
          >{{ vm.optionData.count }} {{ vm.optionData.count === 1 ? "vote" : "votes" }}</span
        >
      </div>
    </div>

    <ng-container *ngTemplateOutlet="closeButton"></ng-container>
  </div>

  <div *ngSwitchDefault class="image-modal-header">
    <ng-container
      *ngTemplateOutlet="
        closeButton;
        context: {
          iconSize: iconSize.Small
        }
      "
    ></ng-container>
  </div>
</ng-container>

<div class="content-wrapper d-flex-column" [ngClass]="{ 'quick-poll-option-content-wrapper': isQuickPollOptionImage }">
  <div
    *ngIf="vm.file?.url"
    class="m-auto"
    [ngStyle]="{ height: isQuickPollOptionImage ? quickPollOptionImageHeight : '' }"
  >
    <img class="image" [ngClass]="{ 'quick-poll-option-image': isQuickPollOptionImage }" [src]="vm.file?.url" />
  </div>
  <span
    *ngIf="vm.description"
    class="tt9-input-text-small text-accent mt-24 hidden-all-handsets"
    [ngClass]="{ 'mt-104': !vm.file?.url }"
    >{{ vm.description }}</span
  >
</div>

<ng-template #closeButton let-iconSize="iconSize">
  <button class="tt9-image-input-close-btn close" (click)="onClose()">
    <span class="hidden-all-handsets">Close</span>
    <span class="hidden-all-desktops-and-tablets">
      <accenture-icon [size]="iconSize">icon-close</accenture-icon>
    </span>
  </button>
</ng-template>
