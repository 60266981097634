import { Injectable } from '@angular/core';
import { Timestamp } from 'firebase/firestore';
import { isNull, isUndefined, omitBy, unset } from 'lodash';
import { BehaviorSubject, map, Observable } from 'rxjs';

import {
    Activity,
    DBPathHelper,
    DefaultFilterObject,
    DuplicateType,
    ExportType,
    FileType,
    initialDefaultFilterObject,
    initialProjectFilters,
    ParentType,
    Project,
    ProjectFilters,
    ProjectQueryFilters,
    ProjectRole,
    ProjectsSummaryExportData,
    ProjectSummary,
    SelectedProjectOptions,
    TemplateRole,
    User,
    UserAccess,
} from '@accenture/shared/data';
import { FanOutWrite, FirestoreService } from '@accenture/shared/data-access';
import { removeEmptyKeys, sortBySequenceAsc } from '@accenture/shared/util';

import { TeamMemberService } from './team-member.service';
import { UserAccessService } from './user-access.service';

@Injectable({
    providedIn: 'root',
})
export class ProjectService {
    deletingProjectIds$ = new BehaviorSubject<string[]>([]);

    constructor(
        private firestoreService: FirestoreService,
        private teamMemberService: TeamMemberService,
        private userAccessService: UserAccessService,
    ) {}

    getProjectActivities(projectId: string): Observable<Activity[]> {
        return this.firestoreService
            .getCollection(`/projects/${projectId}/activities`)
            .pipe(map((activities: Activity[]) => sortBySequenceAsc(activities)));
    }

    getProject(parentType: ParentType, projectId: string): Observable<Project> {
        return this.firestoreService.getDocument<Project>(DBPathHelper.getParentPath(parentType, projectId));
    }

    getProjectById(projectId: string, parentType = ParentType.Projects): Observable<Project> {
        return this.firestoreService.getDocument(DBPathHelper.getParentPath(parentType, projectId));
    }

    getProjectTemplateById(projectId: string): Observable<Project> {
        return this.firestoreService.getDocument<Project>(`/projectTemplates/${projectId}`);
    }

    updateProjectDocument(parentType: ParentType, parentId: string, data: Partial<Project>): Promise<void> {
        return this.firestoreService.updateDoc(`${parentType}/${parentId}`, {
            ...this.firestoreService.replaceEmptyFields(data),
            updated: this.firestoreService.timestamp,
        });
    }

    async downloadAnExportProjectReport(data: ProjectsSummaryExportData): Promise<FileType> {
        return await this.firestoreService.cloudFunctionCallable('exportData', {
            ...data,
            exportType: ExportType.ProjectReport,
        });
    }

    async updateUserAccessOptions(
        parentType: ParentType,
        parentId: string,
        userId: string,
        data: SelectedProjectOptions,
    ): Promise<void> {
        if (parentType === ParentType.Projects) {
            await this.userAccessService.updateProjectAssignmentByProjectId(
                parentId,
                userId,
                data as Partial<UserAccess>,
            );
        }

        if (parentType === ParentType.ProjectTemplates) {
            await this.userAccessService.updateProjectTemplateAssignmentByProjectId(
                parentId,
                userId,
                data as Partial<UserAccess>,
            );
        }
    }

    getCurrentUserProjectsFilters(userId: string | undefined): Observable<DefaultFilterObject> {
        return this.firestoreService.getDocument<DefaultFilterObject>(`/users/${userId}/filters/projectsFilters`).pipe(
            map((filter: DefaultFilterObject) => {
                unset(filter, 'id');

                return filter
                    ? {
                          ...initialDefaultFilterObject,
                          ...filter,
                      }
                    : initialDefaultFilterObject;
            }),
        );
    }

    //TODO Delete once collections scope is done
    getAdminProjectsFilters(userId: string | undefined): Observable<ProjectFilters> {
        return this.firestoreService.getDocument(DBPathHelper.getAdminProjectsFilterPath(userId)).pipe(
            map((filter: ProjectFilters) => {
                unset(filter, 'id');
                return filter
                    ? {
                          ...initialProjectFilters,
                          ...filter,
                      }
                    : initialProjectFilters;
            }),
        );
    }

    async updateAdminProjectsFilters(userId: string | undefined, data: ProjectFilters): Promise<void> {
        await this.firestoreService.upsert(`users/${userId}/filters/adminProjectsFilters`, data);
    }

    async createProject(parentType: ParentType, project: Project, creator: User): Promise<string> {
        const projectId = this.firestoreService.getPushId();
        await this.firestoreService.set(
            `${parentType}/${projectId}`,
            removeEmptyKeys({
                ...project,
                creatorId: creator.id,
                creatorName: creator.displayName,
                creatorImage: creator.imageUrl,
                created: this.firestoreService.timestamp,
                updated: this.firestoreService.timestamp,
            }),
        );

        const batchWrites = [];
        const userAccess = omitBy(
            {
                projectId: parentType === ParentType.Projects ? projectId : null,
                templateId: parentType === ParentType.ProjectTemplates ? projectId : null,
                templateType: parentType === ParentType.ProjectTemplates ? ParentType.ProjectTemplates : null,
                userId: creator.id,
                role: parentType === ParentType.Projects ? ProjectRole.Admin : TemplateRole.Owner,
                name: project.name,
                description: project.description || '',
                imageUrl: project.imageUrl || '',
                clients: project.clients || {},
                tags: project.tags || {},
                created: this.firestoreService.timestamp,
                updated: this.firestoreService.timestamp,
                creatorId: creator.id,
            } as UserAccess,
            (result) => isUndefined(result) || isNull(result),
        );

        const userAccessCreate = {
            path: `userAccess/${this.firestoreService.getPushId()}`,
            data: userAccess,
        };
        batchWrites.push(userAccessCreate);

        if (parentType === ParentType.Projects) {
            const teamMemberWrites = this.addUserToProject(projectId, creator, ProjectRole.Admin);
            batchWrites.push(teamMemberWrites);

            const userProjectsCountUpdate = {
                path: `users/${creator.id}`,
                data: {
                    projectsCount: this.firestoreService.changeCounterValue(1),
                },
            };
            batchWrites.push(userProjectsCountUpdate);
        }

        await this.firestoreService.writeBatch(batchWrites);
        await this.completeCreateProject(projectId, Object.keys(project.templates));

        return projectId;
    }

    async updateProject(
        projectId: string,
        templates: { [id: string]: boolean },
        lastSessionSequence: string,
    ): Promise<void> {
        await this.firestoreService.update(`/projects/${projectId}`, {
            templates,
            updated: this.firestoreService.timestamp,
        });

        const templatesArr = Object.keys(templates);

        await this.completeCreateProject(projectId, templatesArr, lastSessionSequence);
    }

    async addSessionTemplatesToProject(
        projectId: string,
        sessionId: string,
        userId: string,
        toParentType: ParentType,
        fromParentType: ParentType,
        publicAccessId?: string,
        saveResponses = false,
    ): Promise<void> {
        await this.firestoreService.cloudFunctionCallable('duplicateData', {
            userId,
            saveResponses,
            from: {
                publicAccessId,
                sessionId,
                parentId: sessionId,
                templateId: sessionId,
                parentType: fromParentType,
            },
            to: {
                projectId,
                parentId: projectId,
                parentType: toParentType,
            },
            type: DuplicateType.Session,
        });

        await this.firestoreService.updateDocumentUpdatedField(
            DBPathHelper.getParentPath(ParentType.Projects, projectId),
        );
    }

    async deleteProject(projectId: string, parentType: ParentType): Promise<void> {
        const softDelete = parentType === ParentType.Projects;

        await this.firestoreService.cloudFunctionCallable('deleteProject', { projectId, parentType, softDelete });
    }

    async completeCreateProject(
        projectId: string,
        templatesIds: string[],
        lastSessionSequence?: string,
    ): Promise<Record<string, unknown>> {
        try {
            return this.firestoreService.cloudFunctionCallable('updateProject', {
                projectId,
                templatesIds,
                lastSessionSequence,
            });
        } catch (e) {
            console.log(e);
            return Promise.reject();
        }
    }

    async updateProjectsFilters(userId: string | undefined, data: DefaultFilterObject): Promise<void> {
        await this.firestoreService.upsert(`users/${userId}/filters/projectsFilters`, data);
    }

    async updateOptionsFilters(
        userId: string | undefined,
        id: string,
        isSelected: boolean,
        optionType: string,
    ): Promise<void> {
        await this.firestoreService.upsert(`/users/${userId}/filters/projectsFilters`, {
            [optionType]: isSelected ? this.firestoreService.arrayRemove(id) : this.firestoreService.arrayUnion(id),
        });
    }

    async saveAsTemplate(projectId: string, parentType = ParentType.Projects, saveResponses = false): Promise<string> {
        const dictionary = await this.firestoreService.cloudFunctionCallable('duplicateData', {
            saveResponses,
            from: {
                parentType,
                parentId: projectId,
            },
            to: {
                parentType: ParentType.ProjectTemplates,
            },
            type: DuplicateType.Project,
        });
        return dictionary[projectId];
    }

    private addUserToProject(projectId: string, creator: User, role: ProjectRole): FanOutWrite {
        const teamMember = this.teamMemberService.buildTeamMember(projectId, creator, role);
        return {
            path: `projects/${projectId}/teamMembers/${creator.id}`,
            data: {
                ...teamMember,
                type: creator.type,
                status: creator.status,
                lastLogin: creator.lastLogin,
            },
        };
    }

    async getProjectSummaryByFilters(
        filters: ProjectQueryFilters,
    ): Promise<{ projects: ProjectSummary[]; lastProjectId: string }> {
        try {
            return this.firestoreService.cloudFunctionCallable('getProjectSummaryByFilters', { filters });
        } catch {
            return {
                projects: [],
                lastProjectId: '',
            };
        }
    }

    async createProjectFromProjectTemplate(
        parentType: ParentType.PublicProjectTemplates | ParentType.ProjectTemplates,
        parentId: string,
        userId: string,
        saveResponses = false,
        publicAccessId?: string,
    ): Promise<string> {
        const dictionary = await this.firestoreService.cloudFunctionCallable('duplicateData', {
            userId,
            saveResponses,
            from: {
                publicAccessId,
                parentType,
                parentId,
            },
            to: {
                parentType: ParentType.Projects,
            },
            type: DuplicateType.Project,
        });
        return dictionary[parentId];
    }

    // to create getSessionsSummaryByFilters
    async getProjectsSummaryByFilters(
        filters: ProjectQueryFilters,
        areFiltersApplied: boolean,
    ): Promise<{ projects: ProjectSummary[]; projectIds: [] }> {
        const timezone = new Date().getTimezoneOffset();
        try {
            return this.firestoreService.cloudFunctionCallable('getProjectsSummaryByFilters', {
                filters,
                areFiltersApplied,
                timezone,
            }); // to create getSessionsSummaryByFilters CF
        } catch {
            return {
                projects: [],
                projectIds: [],
            };
        }
    }

    formatDate(date: Timestamp): { _seconds: number; _nanoseconds: number } {
        const convertedTimestamp = {
            _seconds: date?.seconds,
            _nanoseconds: date?.nanoseconds,
        };
        return convertedTimestamp;
    }

    // to create getSessionsSummaryByIds
    getProjectsByIds(ids: string[]): Observable<ProjectSummary[]> {
        const projectList = this.firestoreService.getDocumentsByIds<Project>(DBPathHelper.getProjectPath(), ids).pipe(
            map((projects) =>
                projects.map(
                    (project) =>
                        ({
                            projectId: project.id,
                            name: project.name,
                            clients: project.clients,
                            tags: project.tags,
                            created: this.formatDate(project.created as Timestamp),
                            updated: this.formatDate(project.updated as Timestamp),
                        } as unknown as ProjectSummary),
                ),
            ),
        );

        return projectList;
    }
}
