import { FieldValue, Timestamp } from 'firebase/firestore';

import { ActivityType } from '../activities';
import { FileType } from '../responses';
import { ActivityItemType } from './activity-item-type';

export interface ActivityItem {
    id?: string;
    type: ActivityItemType;
    sequence: string;
    activityId?: string;
    sessionId?: string;
    created: Timestamp | FieldValue;
    updated?: Timestamp | FieldValue;
    selected?: boolean;
    title?: string;
    dndDisabled?: boolean;

    createSerializableObject(): Record<string, unknown>;
}

export interface ActivityItemViewHeader {
    id?: string;
    type?: ActivityType;
    title?: string;
    description?: string;
    file?: FileType | null;
}

export enum activityItemsDBFilePaths {
    TemplateDefaultResponse = 'form-activity-template-files/default-responses',
    TemplateImages = 'form-activity-template-files/images',
    TemplateAttachments = 'form-activity-template-files/attachments',
    TemplateOptions = 'form-activity-template-files/options',
    SessionDefaultResponses = 'form-activity-session-files/default-responses',
    SessionResponses = 'form-activity-session-files/responses',
    SessionImages = 'form-activity-session-files/images',
    SessionAttachments = 'form-activity-session-files/attachments',
    UserProfileImages = 'users',
    QuickPoll = 'quick-poll-activity/activity-options',
    QuickPollOptions = 'quick-poll-activity/options',
    ProjectActivityImages = 'project-activity-option-images',
}
