import { Injectable } from '@angular/core';

import { BotActions, UserCollection } from '@accenture/shared/data';
import { FirestoreService } from '@accenture/shared/data-access';

@Injectable({
    providedIn: 'root',
})
export class AIGenerateService {
    constructor(private firestoreService: FirestoreService) {}

    //TODO: Delete when the collection feature is finished.
    async aiGenerateData<T, D>(
        prompt: string,
        botAction: BotActions,
        projectId: string,
        userId: string,
    ): Promise<{ notification: string; project: T; sessions?: D[] }> {
        return await this.firestoreService.cloudFunctionCallable<{ notification: string; project: T; sessions: D[] }>(
            'aiGenerateData',
            {
                prompt,
                botAction,
                projectId,
                userId,
            },
        );
    }

    async aiGenerateDataNew<D>(
        prompt: string,
        botAction: BotActions,
        userId: string,
    ): Promise<{ notification: string; sessions?: D[] }> {
        return await this.firestoreService.cloudFunctionCallable<{ notification: string; sessions: D[] }>(
            'aiGenerateDataTt9',
            {
                prompt,
                botAction,
                userId,
            },
        );
    }

    //TODO: Delete when the collection feature is finished.
    async aiSaveGeneratedData<T>(
        dataToSave: T,
        botAction: BotActions,
        projectId: string,
        userId: string,
    ): Promise<{ notification: { title: string; message: string }; projectId?: string }> {
        return await this.firestoreService.cloudFunctionCallable('aiGeneratedDataSave', {
            dataToSave,
            botAction,
            projectId,
            userId,
        });
    }

    async aiSaveGeneratedDataNew<T>(
        dataToSave: T,
        botAction: BotActions,
        userId: string,
        userCollection?: UserCollection,
    ): Promise<{ notification: { title: string; message: string }; sessionId?: string }> {
        return await this.firestoreService.cloudFunctionCallable('aiGeneratedDataSaveTt9', {
            dataToSave,
            botAction,
            userId,
            userCollection,
        });
    }

    //TODO: Delete when the collection feature is finished.
    async aiGenerateActivityData(
        prompt: string,
        action: BotActions,
        sessionId: string,
        activityId: string,
        projectId: string,
        userId: string,
    ): Promise<{ notification: string; count?: number }> {
        return await this.firestoreService.cloudFunctionCallable('aiAutoBuilder', {
            userId,
            projectId,
            sessionId,
            activityId,
            action,
            prompt,
        });
    }

    async aiGenerateActivityDataNew(
        prompt: string,
        action: BotActions,
        sessionId: string,
        activityId: string,
        userId: string,
    ): Promise<{ notification: string; count?: number }> {
        return await this.firestoreService.cloudFunctionCallable('aiAutoBuilderTt9', {
            userId,
            sessionId,
            activityId,
            action,
            prompt,
        });
    }
}
