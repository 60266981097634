import { Pipe, PipeTransform } from '@angular/core';
import linkifyHtml from 'linkify-html';

import { ResponseValue } from '@accenture/shared/data';

@Pipe({
    name: 'displayActivityResponseLinkify',
})
export class DisplayActivityResponseLinkifyPipe implements PipeTransform {
    transform(value: ResponseValue): string {
        if (!value || typeof value !== 'string') {
            return '';
        }
        return linkifyHtml(value as string, { target: '_blank' });
    }
}
