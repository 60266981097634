import { Pipe, PipeTransform } from '@angular/core';

import { ActivityItemType, displayActivityItemsType } from '@accenture/shared/data';

@Pipe({
    name: 'displayActivityItemType',
})
export class DisplayActivityItemTypePipe implements PipeTransform {
    transform(type: ActivityItemType): string {
        return displayActivityItemsType[type || ActivityItemType.Unknown];
    }
}
