import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest, firstValueFrom, Observable } from 'rxjs';
import { map, startWith, switchMap } from 'rxjs/operators';

import { TeamMemberFilterAndSortService, TeamMemberService } from '@accenture/erp-deployment/shared/domain';
import { AppState, selectProjectIdAndSessionId } from '@accenture/global-store';
import { SessionRole, TeamMember, TeamMemberFilters, UserType } from '@accenture/shared/data';

import { SessionsInvitationsByEmailStep } from '../../session-invite-dialog.constants';
import { SelectedForInvitationMemberData } from '../../session-invite-dialog.models';
import { SessionInviteDialogStore } from '../../session-invite-dialog.store';

export interface ProjectMemberViewModel {
    checked: boolean;
    disabled: boolean;
    user: TeamMember;
}

export interface ProjectMembersViewModel {
    projectMemberViewModels: ProjectMemberViewModel[];
    invitationRole: SessionRole.Leader | SessionRole.Participant | undefined;
}

const defaultViewModel: ProjectMembersViewModel = {
    projectMemberViewModels: [],
    invitationRole: undefined,
};

@Injectable()
export class ProjectMembersFacade {
    vm$ = this.buildViewModel();

    private searchValue$ = new BehaviorSubject<string>('');

    constructor(
        private store: Store<AppState>,
        private sessionInviteDialogStore: SessionInviteDialogStore,
        private teamMemberService: TeamMemberService,
        private teamMemberFilterAndSortService: TeamMemberFilterAndSortService,
    ) {}

    setSearchValue(value: string): void {
        this.searchValue$.next(value);
    }

    backOnInviteStep(): void {
        this.sessionInviteDialogStore.setInviteByEmailActiveStep(SessionsInvitationsByEmailStep.Invite);
    }

    async selectMember(email: string, role: SessionRole.Leader | SessionRole.Participant): Promise<void> {
        const selectedMembersData = await firstValueFrom(
            this.sessionInviteDialogStore.selectedForInvitationMemberData$,
        );

        selectedMembersData[email] = role;

        this.sessionInviteDialogStore.setSelectedForInvitationMemberData(selectedMembersData);
    }

    async unselectMember(email: string): Promise<void> {
        const selectedMembersData = await firstValueFrom(
            this.sessionInviteDialogStore.selectedForInvitationMemberData$,
        );

        delete selectedMembersData[email];

        this.sessionInviteDialogStore.setSelectedForInvitationMemberData(selectedMembersData);
    }

    private buildViewModel(): Observable<ProjectMembersViewModel> {
        return this.store.select(selectProjectIdAndSessionId).pipe(
            switchMap(data => {
                return combineLatest({
                    projectMembers: this.getProjectMembers(data.projectId),
                    invitedMemberEmails: this.getInvitedMemberEmails(data.projectId, data.sessionId),
                    membersSelectData: this.sessionInviteDialogStore.selectedForInvitationMemberData$,
                    invitationRole: this.sessionInviteDialogStore.activeInvitationRole$,
                });
            }),
            map(data => {
                const projectMemberViewModels = data.projectMembers.map(member => {
                    return this.createProjectMemberViewModel(
                        member,
                        data.invitedMemberEmails,
                        data.membersSelectData,
                        data.invitationRole,
                    );
                });

                return {
                    projectMemberViewModels,
                    invitationRole: data.invitationRole,
                };
            }),
            startWith(defaultViewModel),
        );
    }

    private getInvitedMemberEmails(projectId: string, sessionId: string): Observable<string[]> {
        return this.teamMemberService
            .getSessionTeamMembers(projectId, sessionId)
            .pipe(map(data => data.map(user => user.email)));
    }

    private getProjectMembers(projectId: string): Observable<TeamMember[]> {
        const filter: Partial<TeamMemberFilters> = { types: [UserType.GeneralUser, UserType.AccentureUser] };

        return combineLatest({
            teamMembers: this.teamMemberFilterAndSortService.getProjectTeamMembersByFilters(projectId, filter),
            searchValue: this.searchValue$,
        }).pipe(
            map(({ teamMembers, searchValue }) => {
                if (!searchValue) {
                    return teamMembers;
                }

                return teamMembers.filter(teamMember => {
                    const isEmailOverlap = teamMember.email.toLowerCase().includes(searchValue);
                    const isDisplayNameOverlap = teamMember.displayName
                        .toLowerCase()
                        .includes(searchValue);

                    return isEmailOverlap || isDisplayNameOverlap;
                });
            }),
        );
    }

    private createProjectMemberViewModel(
        user: TeamMember,
        invitedUserEmails: string[],
        checkData: SelectedForInvitationMemberData,
        invitationRole: SessionRole.Leader | SessionRole.Participant,
    ): ProjectMemberViewModel {
        if (invitedUserEmails.includes(user.email)) {
            return {
                user,
                checked: true,
                disabled: true,
            };
        }

        const checkedUserEmails = Object.keys(checkData);

        return {
            user,
            checked: checkedUserEmails.includes(user.email),
            disabled: checkData[user.email] && checkData[user.email] !== invitationRole,
        };
    }
}
