import { inject, Injectable } from '@angular/core';
import { AngularFirestoreCollection } from '@angular/fire/compat/firestore';
import { Timestamp } from 'firebase/firestore';
import { BehaviorSubject, filter, firstValueFrom, map, Observable } from 'rxjs';

import {
    DBPathHelper,
    defaultInAppNotificationFilters,
    InAppNotification,
    InAppNotificationFactory,
    InAppNotificationFilters,
    lazyLoadingInAppNotificationsLimit,
    SortOrders,
} from '@accenture/shared/data';
import { FirestoreService } from '@accenture/shared/data-access';

@Injectable({
    providedIn: 'root',
})
export class InAppNotificationsService {
    private readonly firestoreService: FirestoreService = inject(FirestoreService);

    private _allLoaded = false;
    get allLoaded(): boolean {
        return this._allLoaded;
    }

    private _lastLoadedDoc?: any;

    private readonly _notifications$ = new BehaviorSubject<InAppNotification[]>([]);
    readonly notifications$ = this._notifications$.asObservable();
    readonly searchValue$ = new BehaviorSubject<string>('');

    async loadNotifications(userId: string, reset?: boolean): Promise<InAppNotification | undefined> {
        if (reset) {
            this._allLoaded = false;
            this._notifications$.next([]);
        }
        if (this._allLoaded) {
            return;
        }

        const notifications = await firstValueFrom(this.getNotifications(userId, reset));
        if (!notifications.length) {
            this._allLoaded = true;
            return;
        }

        this._notifications$.next([...this._notifications$.getValue(), ...notifications]);

        return notifications[0];
    }

    getNotificationsCount(userId: string, lastViewedNotificationDateCreated?: Timestamp): Observable<number> {
        return this.getUnseenNotificationsQuery(userId, lastViewedNotificationDateCreated)
            .valueChanges()
            .pipe(map(notifications => notifications.length));
    }

    getTotalNotificationsCount(userId: string): Observable<number> {
        return this.firestoreService
            .getDocumentsByProperty<InAppNotification>(DBPathHelper.getInAppNotificationsPath(), 'userId', userId)
            .pipe(map(notifications => notifications.length));
    }

    getUnseenNotifications(userId: string, endBefore?: Timestamp): Observable<InAppNotification[]> {
        return this.getUnseenNotificationsQuery(userId, endBefore)
            .snapshotChanges()
            .pipe(
                filter(snapshot => snapshot.every(({ payload }) => payload.doc.metadata.fromCache === false)),
                map(notificationsSnapshots => {
                    return notificationsSnapshots.map(({ payload: { doc } }) =>
                        InAppNotificationFactory.create({
                            id: doc.id,
                            ...doc.data(),
                        }),
                    );
                }),
            );
    }

    async markAllNotificationsAsRead(userId: string): Promise<void> {
        const notifications = await firstValueFrom(
            this.getUnseenNotificationsQuery(userId, undefined, true).snapshotChanges(),
        );
        const writes = notifications.map(
            ({
                payload: {
                    doc: { id },
                },
            }) => ({
                path: DBPathHelper.getInAppNotificationsPath(id),
                data: {
                    unread: this.firestoreService.deleteField,
                    updated: this.firestoreService.timestamp,
                },
            }),
        );
        this._notifications$.next(
            this._notifications$.getValue().map(
                notification =>
                    ({
                        ...notification,
                        unread: false,
                    } as InAppNotification),
            ),
        );
        await this.firestoreService.writeBatch(writes);
    }

    async deleteAllNotifications(userId: string): Promise<void> {
        const notifications = await firstValueFrom(this.getAllNotifications(userId));
        const deletes = notifications.map(notification => ({
            path: DBPathHelper.getInAppNotificationsPath(notification.id),
        }));
        await this.firestoreService.deleteBatch(deletes);
        this._notifications$.next([]);
        this.searchValue$.next('');
        await this.updateFilters(userId, defaultInAppNotificationFilters);
    }

    async writeNotification(notification: InAppNotification): Promise<void> {
        await this.firestoreService.addDocument(DBPathHelper.getInAppNotificationsPath(), {
            ...notification.createSerializableObject(),
            created: this.firestoreService.timestamp,
            updated: this.firestoreService.timestamp,
        });
    }

    async markNotificationAsRead(notificationId: string): Promise<void> {
        this._notifications$.next(
            this._notifications$.getValue().map(
                notification =>
                    ({
                        ...notification,
                        unread: notification.id === notificationId ? false : notification.unread,
                    } as InAppNotification),
            ),
        );
        await this.firestoreService.update(DBPathHelper.getInAppNotificationsPath(notificationId), {
            unread: this.firestoreService.deleteField,
            updated: this.firestoreService.timestamp,
        });
    }

    async deleteNotification(notificationId: string): Promise<void> {
        this._notifications$.next(
            this._notifications$.getValue().filter(notification => notification.id !== notificationId),
        );
        await this.firestoreService.delete(DBPathHelper.getInAppNotificationsPath(notificationId));
    }

    getFilters(userId: string): Observable<InAppNotificationFilters> {
        return this.firestoreService
            .getDocument<InAppNotificationFilters>(DBPathHelper.getInAppNotificationsFiltersPath(userId))
            .pipe(
                map(filters => ({
                    ...defaultInAppNotificationFilters,
                    ...filters,
                })),
            );
    }

    async updateFilters(userId: string, value: Partial<InAppNotificationFilters>) {
        await this.firestoreService.update(DBPathHelper.getInAppNotificationsFiltersPath(userId), {
            ...value,
            updated: this.firestoreService.timestamp,
        });
    }

    async writeBatchNotifications(notifications: InAppNotification[]): Promise<void> {
        const dataToWrite = notifications.reduce((acc, item) => {
            const id = this.firestoreService.getPushId();

            acc.push({
                path: DBPathHelper.getInAppNotificationsPath(id),
                data: {
                    ...item.createSerializableObject(),
                    updated: this.firestoreService.timestamp,
                    created: this.firestoreService.timestamp,
                },
            });
            return acc;
        }, []);

        await this.firestoreService.writeBatch(dataToWrite);
    }

    private getUnseenNotificationsQuery(
        userId: string,
        endBefore?: Timestamp,
        onlyUnread?: boolean,
    ): AngularFirestoreCollection<InAppNotification> {
        return this.firestoreService.getDocumentsByQuery<InAppNotification>(
            DBPathHelper.getInAppNotificationsPath(),
            ref => {
                let query = ref
                    .orderBy('created', this.firestoreService.getSortingDirection(SortOrders.Dsc))
                    .where('userId', '==', userId);
                if (onlyUnread) {
                    query = query.where('unread', '==', true);
                }
                if (endBefore) {
                    query = query.endBefore(endBefore);
                }
                return query;
            },
        );
    }

    private getNotifications(userId: string, reset?: boolean): Observable<InAppNotification[]> {
        if (reset) {
            this._lastLoadedDoc = undefined;
        }

        return this.firestoreService
            .getDocumentsByQuery<InAppNotification>(DBPathHelper.getInAppNotificationsPath(), ref => {
                let query = ref
                    .orderBy('created', this.firestoreService.getSortingDirection(SortOrders.Dsc))
                    .where('userId', '==', userId);

                if (this._lastLoadedDoc) {
                    query = query.startAfter(this._lastLoadedDoc);
                }

                return query.limit(lazyLoadingInAppNotificationsLimit);
            })
            .snapshotChanges()
            .pipe(
                filter(snapshot => snapshot.every(({ payload }) => payload.doc.metadata.fromCache === false)),
                map(notificationsSnapshots => {
                    this._lastLoadedDoc = notificationsSnapshots[notificationsSnapshots.length - 1]?.payload?.doc;
                    return notificationsSnapshots.map(({ payload: { doc } }) =>
                        InAppNotificationFactory.create({
                            id: doc.id,
                            ...doc.data(),
                        }),
                    );
                }),
            );
    }

    private getAllNotifications(userId: string): Observable<InAppNotification[]> {
        return this.firestoreService.getDocumentsByPropertyWithoutCaching<InAppNotification>(
            DBPathHelper.getInAppNotificationsPath(),
            'userId',
            userId,
        );
    }
}
