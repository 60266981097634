import { Injectable } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest, firstValueFrom, Observable } from 'rxjs';
import { map, startWith, take } from 'rxjs/operators';

import { FilesService } from '@accenture/activity/shared/domain';
import { OptionsStore, ProjectOptionsService, ProjectService } from '@accenture/erp-deployment/shared/domain';
import { AppState, selectAuthenticatedUser, selectParentType } from '@accenture/global-store';
import {
    addingTemplateErrorSnackbarTitle,
    addingTemplateInProgressSnackbarTitle,
    CollectionOptions,
    errorMessageSnackbarText,
    FileType,
    ParentType,
    Project,
    ProjectOptions,
    projectOptionsDialogTitle,
    routerLinksMap,
    SelectedProjectOptions,
    SessionOptions,
    templateAddedSnackbarTitle,
    templateHasBeenAddedToTheProjectSnackbarText,
    templateIsBeingAddedToTheProjectSnackbarText,
    User,
} from '@accenture/shared/data';
import { DialogService, LoadedDescription, SnackbarService, SnackBarTypes } from '@accenture/shared/ui';
import { removeNoValuesKeys } from '@accenture/shared/util';

import { UseTemplateStore } from '../component-stores/use-template.store';
import { CreateSessionDialogComponent } from '../create-session-dialog/create-session-dialog.component';
import { SelectSourceTypeDialogComponent } from '../select-source-type-dialog/select-source-type-dialog.component';
import {
    createProjectToUseActivityTemplate,
    createProjectToUseSessionTemplate,
    useTemplateStepOne,
} from '../use-template-dialog/constants';
import { UseTemplateDialogComponent } from '../use-template-dialog/use-template-dialog.component';
import { CreateProjectDialogComponent } from './create-project-dialog.component';

export interface CreateProjectDialogViewModel {
    user: User;
    projectImage: FileType;
    loaderDescription: string;
    subTitle: string;
    currentOptionToDisplay: ProjectOptions | SessionOptions | CollectionOptions | null;
    title: string;
    createProjectButtonName: string;
    isLoading: boolean;
}

const defaultProjectCreatorModel = {
    user: {} as User,
    projectImage: {} as FileType,
    loaderDescription: '',
    subTitle: '',
    currentOptionToDisplay: null,
    title: '',
    createProjectButtonName: '',
    isLoading: true,
};

@Injectable()
export class CreateProjectDialogFacade {
    private isLoading$ = new BehaviorSubject<boolean>(false);
    private projectImage$ = new BehaviorSubject<FileType>({} as FileType);

    private isProjectCreated = false;
    private parentType: ParentType;
    private usePublicAccessId: string;
    private useTemplateId: string;
    private useTemplateType: ParentType;
    private userId: string;

    vm$ = this.buildViewModel();

    constructor(
        private store: Store<AppState>,
        private projectService: ProjectService,
        private projectOptionsService: ProjectOptionsService,
        private router: Router,
        private filesService: FilesService,
        private useTemplateStore: UseTemplateStore,
        private dialogRef: MatDialogRef<CreateProjectDialogComponent>,
        private dialogService: DialogService,
        private optionsStore: OptionsStore,
        private snackbarService: SnackbarService,
    ) {}

    closeDialog(): void {
        this.dialogRef.close();
    }

    backToProjectData(): void {
        this.optionsStore.setCurrentOptionToDisplay(null);
    }

    resetOptions(): void {
        this.optionsStore.resetProjectOptions();
        this.optionsStore.resetProjectOptionsToCreate();
        this.optionsStore.setCurrentOptionToDisplay(null);
    }

    openUseTemplateModal(createOnlyProject: boolean, isChipsOpen?: boolean): void {
        if (isChipsOpen) {
            this.useTemplateStore.setOptionsChipsDisplay(false);
        } else {
            if (createOnlyProject) {
                this.dialogService.open(SelectSourceTypeDialogComponent, {
                    panelClass: 'tt9-modal',
                    width: '768px',
                });
            } else {
                this.dialogService.open(UseTemplateDialogComponent, {
                    width: 'auto',
                    panelClass: 'tt9-modal',
                });
            }
            this.closeDialog();
        }
    }

    openCreateSessionModal(): void {
        this.dialogService.open(CreateSessionDialogComponent, {
            panelClass: 'tt9-modal',
            width: '768px',
        });
    }

    setProjectImage(projectImage: FileType): void {
        this.projectImage$.next(projectImage);
    }

    deleteUnusedData(): void {
        this.resetOptions();
        if (this.isProjectCreated) {
            return;
        }

        this.deleteProjectImage();
    }

    async createProject(
        name: string,
        description: string,
        user: User,
        projectImage: FileType,
        createOnlyProject: boolean,
    ): Promise<void> {
        const projectOptions = await firstValueFrom(this.optionsStore.selectedProjectOptions$.pipe(take(1)));

        const projectData = removeNoValuesKeys<Project>(
            new Project('', {
                name,
                description,
                templates: {},
                tags: projectOptions.tags || {},
                clients: projectOptions.clients || {},
                userId: user.id,
                sessionIds: [],
                imageUrl: projectImage?.url || null,
                imageId: projectImage?.id || null,
            }),
        );

        const projectId = await this.projectService.createProject(ParentType.Projects, projectData, user);

        await this.updateOptions(projectOptions, projectId);

        this.isProjectCreated = true;
        this.closeDialog();

        if (createOnlyProject) {
            this.router.navigate([routerLinksMap[ParentType.Projects], projectId]);
            return;
        }

        if (![ParentType.ActivityTemplates, ParentType.PublicActivityTemplates].includes(this.useTemplateType)) {
            this.snackbarService.showSnackBar(
                addingTemplateInProgressSnackbarTitle,
                templateIsBeingAddedToTheProjectSnackbarText,
                SnackBarTypes.Info,
                false,
            );
            this.closeDialog();
            try {
                await this.projectService.addSessionTemplatesToProject(
                    projectId,
                    this.useTemplateId,
                    this.userId,
                    ParentType.Projects,
                    this.useTemplateType,
                    this.usePublicAccessId,
                    true,
                );

                this.router.navigate([routerLinksMap[ParentType.Projects], projectId]);

                this.snackbarService.showSnackBar(
                    templateAddedSnackbarTitle,
                    templateHasBeenAddedToTheProjectSnackbarText,
                    SnackBarTypes.Success,
                    true,
                );
            } catch (e) {
                console.error(e);
                this.snackbarService.showSnackBar(
                    addingTemplateErrorSnackbarTitle,
                    errorMessageSnackbarText,
                    SnackBarTypes.Error,
                    true,
                );
            }
        } else {
            this.useTemplateStore.setSelectedProjectId(projectId);
            this.useTemplateStore.setIsNewProject(true);
            this.openCreateSessionModal();
        }
    }

    private deleteProjectImage(): void {
        const projectImage = this.projectImage$.getValue();

        this.filesService.decrementFilesCount(projectImage?.id);
    }

    private buildViewModel(): Observable<CreateProjectDialogViewModel> {
        return combineLatest([
            this.store.select(selectAuthenticatedUser),
            this.store.select(selectParentType),
            this.projectImage$.asObservable(),
            this.useTemplateStore.usePublicAccessId$,
            this.useTemplateStore.useTemplateId$,
            this.useTemplateStore.useTemplateType$,
            this.optionsStore.currentOptionToDisplay$,
            this.optionsStore.selectedProjectClients$,
            this.isLoading$.asObservable(),
        ]).pipe(
            map(
                ([
                    user,
                    parentType,
                    projectImage,
                    usePublicAccessId,
                    useTemplateId,
                    useTemplateType,
                    currentOptionToDisplay,
                    selectedProjectClients,
                    isLoading,
                ]) => {
                    this.setActualData(parentType, user.id, usePublicAccessId, useTemplateId, useTemplateType);

                    return {
                        user,
                        parentType,
                        projectImage,
                        isLoading,
                        currentOptionToDisplay,
                        isClientSelected: !!Object.keys(selectedProjectClients).length,
                        createProjectButtonName: useTemplateType === ParentType.ActivityTemplates ? 'Next' : 'Save',
                        subTitle:
                            useTemplateType === ParentType.ActivityTemplates && !currentOptionToDisplay
                                ? useTemplateStepOne
                                : '',
                        title: this.getModalTitle(useTemplateType, currentOptionToDisplay),
                        loaderDescription: (this.parentType === ParentType.Projects
                            ? LoadedDescription.ProjectCreating
                            : LoadedDescription.ProjectTemplateCreating
                        ).toUpperCase(),
                    };
                },
            ),
            startWith(defaultProjectCreatorModel),
        );
    }

    private setActualData(
        parentType: ParentType,
        userId: string,
        usePublicAccessId: string,
        useTemplateId: string,
        useTemplateType: ParentType,
    ): void {
        this.parentType = parentType;
        this.userId = userId;
        this.usePublicAccessId = usePublicAccessId;
        this.useTemplateId = useTemplateId;
        this.useTemplateType = useTemplateType;
    }

    private getModalTitle(
        useTemplateType: ParentType,
        currentOptionToDisplay: ProjectOptions | SessionOptions | CollectionOptions | null,
    ): string {
        if (currentOptionToDisplay) {
            return projectOptionsDialogTitle?.[currentOptionToDisplay] || '';
        }

        return useTemplateType === ParentType.ActivityTemplates
            ? createProjectToUseActivityTemplate
            : createProjectToUseSessionTemplate;
    }

    private async updateOptions(selectedProjectOptions: SelectedProjectOptions, projectId: string): Promise<void> {
        const optionsToCreateIds = await firstValueFrom(this.optionsStore.projectOptionsToCreateIds$.pipe(take(1)));

        await this.projectOptionsService.updateOptions(selectedProjectOptions, projectId, {}, optionsToCreateIds);
    }
}
