import { Injectable } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { isEqual } from 'lodash';
import { BehaviorSubject, combineLatest, Observable, startWith, switchMap } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';

import { AttributeService } from '@accenture/erp-deployment/shared/domain';
import { AppState, selectActivityIdAndParentIds } from '@accenture/global-store';
import { Attribute, AttributeClass, ParentType, updateAttributes } from '@accenture/shared/data';
import { FirestoreService } from '@accenture/shared/data-access';
import { ConfirmationDialogComponent, DialogService } from '@accenture/shared/ui';
import { getNewSequenceAfterMoved, SequenceGeneration, sortBySequenceAsc } from '@accenture/shared/util';

import { AttributesClassEditDialogComponent } from './attributes-edit-dialog.component';

export interface AttributeClassEditDialogModel {
    attributes: Attribute[];
    parentType: ParentType;
    attributeClass: AttributeClass;
    searchValue: string;
    isLoading: boolean;
    attributesHasUpdates: boolean;
    updateAttributesEvent: boolean;
}

const initialState: AttributeClassEditDialogModel = {
    attributes: [],
    parentType: ParentType.Projects,
    attributeClass: {} as AttributeClass,
    searchValue: '',
    isLoading: true,
    attributesHasUpdates: false,
    updateAttributesEvent: true,
};

@Injectable()
export class AttributesClassEditDialogFacade {
    private attributeClassId$: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);

    vm$ = this.buildViewModel();

    private attributesHasUpdates$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private updateAttributesEvent$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
    private attributes$ = new BehaviorSubject<Attribute[]>([]);
    private searchValue$ = new BehaviorSubject<string>('');
    private isLoading$ = new BehaviorSubject<boolean>(false);

    private parentType!: ParentType;
    private parentId!: string;
    private attributeClass!: AttributeClass;
    private deletedAttributes: Attribute[] = [];

    constructor(
        private store: Store<AppState>,
        private dialogService: DialogService,
        private attributeService: AttributeService,
        private firestoreService: FirestoreService,
        public dialogRef: MatDialogRef<AttributesClassEditDialogComponent>,
    ) {}

    updateSearchValue(searchValue: string): void {
        this.searchValue$.next(searchValue);
    }

    closeDialog(): void {
        this.dialogRef.close();
    }

    setAttributes(attributes: Attribute[]): void {
        this.attributes$.next(sortBySequenceAsc(attributes.map(attribute => ({ ...attribute })) as Attribute[]));
    }

    setAttributeClassId(id: string): void {
        this.attributeClassId$.next(id);
    }

    async addAttributeClass(attributeClassName: string, lastSequence: string | undefined): Promise<void> {
        if (!this.parentId || !this.parentType) {
            return;
        }

        const attributes = this.attributes$.getValue();
        const sequence = lastSequence ? SequenceGeneration.afterLast(lastSequence) : SequenceGeneration.initial();

        const classId = await this.attributeService.addAttributeClass(this.parentType, this.parentId, {
            attributes,
            name: attributeClassName,
            sequence,
        } as Partial<AttributeClass>);

        this.attributeService.setActiveClass(classId); // navigate to class after creating
        this.closeDialog();
    }

    addAttribute(): void {
        const attributes = this.attributes$.getValue();
        const lastSequence = attributes.length ? attributes[attributes.length - 1].sequence : null;
        const sequence = attributes?.length ? SequenceGeneration.afterLast(lastSequence) : SequenceGeneration.initial();

        const newAttribute = {
            id: this.firestoreService.getPushId(),
            sequence,
            name: 'New Attribute',
        } as Attribute;

        this.attributes$.next([...attributes, newAttribute]);
    }

    saveAttributeClass(attributeClassName: string, lastSequence: string | undefined): void {
        this.attributeClass?.id
            ? this.updateAttributeClassConfirmation(attributeClassName)
            : this.addAttributeClass(attributeClassName, lastSequence);
    }

    importAttributes(attributeNames: string[]): void {
        let attributes = this.attributes$.getValue();
        for (const attributeName of attributeNames) {
            const lastSequence = attributes.length ? attributes[attributes.length - 1].sequence : null;

            const sequence = attributes?.length
                ? SequenceGeneration.afterLast(lastSequence)
                : SequenceGeneration.initial();

            const newAttribute = {
                id: this.firestoreService.getPushId(),
                sequence,
                name: attributeName,
            } as Attribute;

            attributes = [...attributes, newAttribute];
        }

        this.attributes$.next([...attributes]);
    }

    updateAttributeClassConfirmation(attributeClassName: string): void {
        if (this.deletedAttributes.length) {
            this.dialogService.open(ConfirmationDialogComponent, {
                title: updateAttributes.title,
                confirmBtnText: 'Update',
                cancelBtnText: 'Cancel',
                width: '444px',
                text: updateAttributes.text,
                confirm: () => this.updateAttributeClass(attributeClassName),
            });
        } else {
            this.updateAttributeClass(attributeClassName);
        }
    }

    updateAttributeClass(attributeClassName: string): void {
        this.isLoading$.next(true);
        if (!this.parentId || !this.parentType) {
            return;
        }

        const attributes = this.attributes$.getValue();

        this.attributeService.updateAttributeClass(
            this.parentType,
            this.parentId,
            this.attributeClass.id,
            {
                attributes,
                name: attributeClassName,
                sequence: this.attributeClass.sequence,
            } as AttributeClass,
            this.deletedAttributes,
        );

        this.isLoading$.next(false);
        this.closeDialog();
    }

    updateAttribute(attributeId: string, attributeName: string): void {
        const attributes = this.attributes$.getValue();

        attributes.find(attribute => {
            if (attribute.id === attributeId) {
                attribute.name = attributeName;
            }
        });

        this.attributes$.next(attributes);
    }

    updateSequence(previousIndex: number, currentIndex: number, updatedAttribute: Attribute): void {
        const attributes = this.attributes$.getValue();

        const newSequence = getNewSequenceAfterMoved<Attribute>(
            previousIndex,
            currentIndex,
            attributes,
            updatedAttribute,
        );
        if (newSequence !== updatedAttribute.sequence) {
            attributes.find(attribute => {
                if (attribute.id === updatedAttribute.id) {
                    attribute.sequence = newSequence;
                }
            });

            this.attributes$.next(sortBySequenceAsc(attributes));
        }
    }

    removeAttribute(deletedAttribute: Attribute): void {
        const attributes = this.attributes$.getValue();
        this.attributes$.next(attributes.filter(attribute => attribute.id !== deletedAttribute.id));
        this.deletedAttributes.push(deletedAttribute);
    }

    updateAttributeValue(): void {
        this.updateAttributesEvent$.next(true);
        this.attributesHasUpdates$.next(false);
        this.isLoading$.next(true);
    }

    attributesWasUpdated(): void {
        setTimeout(() => this.isLoading$.next(false));
        setTimeout(() => this.updateAttributesEvent$.next(false));
    }

    private buildViewModel(): Observable<AttributeClassEditDialogModel> {
        return combineLatest([
            this.store.select(selectActivityIdAndParentIds),
            this.attributeClassId$.asObservable(),
        ]).pipe(
            switchMap(([{ parentType, parentId }, attributeClassId]) => {
                this.parentType = parentType;
                this.parentId = parentId;

                return combineLatest([
                    this.attributeService.getAttributeClassWithAttributes(parentType, parentId, attributeClassId).pipe(
                        distinctUntilChanged(
                            (
                                previousPayload: { attributes: Attribute[]; attributeClass: AttributeClass },
                                currentPayload: { attributes: Attribute[]; attributeClass: AttributeClass },
                            ): boolean => {
                                if (isEqual(previousPayload, currentPayload)) {
                                    return true;
                                }

                                this.attributesHasUpdates$.next(true);
                                return false;
                            },
                        ),
                    ),
                    this.searchValue$,
                    this.isLoading$.asObservable().pipe(distinctUntilChanged()),
                    this.updateAttributesEvent$.asObservable().pipe(distinctUntilChanged()),
                    this.attributesHasUpdates$.asObservable().pipe(distinctUntilChanged()),
                ]).pipe(
                    switchMap(
                        ([
                            { attributes, attributeClass },
                            searchValue,
                            isLoading,
                            updateAttributesEvent,
                            attributesHasUpdates,
                        ]) => {
                            if (updateAttributesEvent) {
                                this.attributeClass = attributeClass;
                                this.attributes$.next(attributes);
                            }

                            return this.attributes$.asObservable().pipe(
                                map(currentAttributes => ({
                                    searchValue,
                                    isLoading,
                                    attributesHasUpdates,
                                    parentType,
                                    updateAttributesEvent,
                                    attributeClass: this.attributeClass,
                                    attributes: this.getFilteredAttributes(currentAttributes, searchValue),
                                })),
                            );
                        },
                    ),
                );
            }),
            startWith(initialState),
        );
    }

    private getFilteredAttributes(attributes: Attribute[], searchValue: string): Attribute[] {
        if (!searchValue.trim()) {
            return attributes;
        }

        return attributes.filter(attribute => attribute.name.toLocaleLowerCase().includes(searchValue));
    }
}
