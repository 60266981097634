import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Params, Router, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { isUndefined } from 'lodash';
import { Observable, of, zip } from 'rxjs';
import { map, skipWhile, switchMap, take } from 'rxjs/operators';

import { AppState, selectAuthenticatedUserId } from '@accenture/global-store';
import {
    ActivityType,
    LinkAccess,
    notFoundUrl,
    ParentType,
    QuickPoll,
    Session,
    SessionFocus,
    SessionFocusSteps,
    TableActivity,
    TeamMember,
    Vote,
} from '@accenture/shared/data';

import { RedirectionScreen } from '../models';
import { ActivityService } from '../services/activity.service';
import { LinkAccessService } from '../services/link-access.service';
import { SessionService } from '../services/session.service';
import { SessionFocusService } from '../services/session-focus.service';
import { TeamMemberService } from '../services/team-member.service';
import { isActiveActivityLinkAccess } from '../utils';

@Injectable()
export class ActivityGuard {
    constructor(
        private router: Router,
        private store: Store<AppState>,
        private activityService: ActivityService,
        private sessionFocusService: SessionFocusService,
        private teamMemberService: TeamMemberService,
        private sessionService: SessionService,
        private linkAccessService: LinkAccessService,
    ) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        const { projectId, activityId, sessionId } = this.getParams(route);
        const { isSummary, isWaiting } = this.getRouteStates(state);

        if (projectId && sessionId && activityId) {
            return this.resolveRouteByFocusState(
                projectId,
                activityId,
                sessionId,
                isSummary,
                isWaiting,
                route.queryParams,
            );
        }

        return of(false);
    }

    private resolveRouteByFocusState(
        projectId: string,
        activityId: string,
        sessionId: string,
        isSummary: boolean,
        isWaitingOrStatus: boolean,
        queryParams?: Params,
    ): Observable<boolean> {
        const activityIdFromLinkAccess = queryParams?.activityIdFromLinkAccess;

        return this.store.select(selectAuthenticatedUserId).pipe(
            skipWhile(userId => isUndefined(userId)),
            take(1),
            switchMap(userId =>
                zip([
                    this.activityService.getActivityById(projectId, activityId),
                    this.activityService.hasUserResponsesByActivityId(projectId, activityId, userId),
                    this.sessionFocusService.getSessionFocus(projectId, sessionId),
                    this.sessionService.getSession(ParentType.Projects, projectId, sessionId),
                    this.teamMemberService.getSessionTeamMember(projectId, sessionId, userId),
                    activityIdFromLinkAccess
                        ? this.linkAccessService.getLinkAccessByActivity(activityIdFromLinkAccess)
                        : of(null),
                ]),
            ),
            take(1),
            map(
                ([activity, hasResponses, sessionFocus, session, teamMember, activityLinkAccess]: [
                    Vote | QuickPoll | TableActivity,
                    boolean,
                    SessionFocus,
                    Session,
                    TeamMember,
                    LinkAccess | null,
                ]) => {
                    const fromActiveLink = isActiveActivityLinkAccess(activityLinkAccess);

                    if (!activity || (teamMember?.isSessionParticipant && activity && !activity.visible)) {
                        this.router.navigateByUrl(notFoundUrl);
                        return false;
                    }

                    const isParticipantAndActivityVisible
                        = teamMember?.isSessionParticipant
                        && activity?.visible
                        && sessionFocus?.activityId !== activity.id
                        && !session.selfNavigate;

                    if (isParticipantAndActivityVisible) {
                        if (fromActiveLink) {
                            //if invited from an activity and waiting screen enabled but other activity has focus
                            if (!isWaitingOrStatus && activity.waitingScreenVisible) {
                                this.redirectTo(
                                    projectId,
                                    sessionId,
                                    activityId,
                                    activity.type,
                                    false,
                                    RedirectionScreen.WaitingScreen,
                                    true,
                                );
                                return false;
                            }
                            return true;
                        }
                        this.router.navigateByUrl(notFoundUrl);
                        return false;
                    }

                    if (!teamMember) {
                        return false;
                    }

                    if (teamMember?.isSessionLeader) {
                        return true;
                    }

                    const isFocusOnSummary
                        = sessionFocus
                        && sessionFocus.activityId === activity.id
                        && [
                            SessionFocusSteps.VoteSummary,
                            SessionFocusSteps.PollSummary,
                            SessionFocusSteps.TableSummary,
                        ].includes(sessionFocus.focusStep);
                    const isFocusOnActivity
                        = sessionFocus
                        && sessionFocus.activityId === activity.id
                        && [SessionFocusSteps.Vote, SessionFocusSteps.Poll, SessionFocusSteps.Table].includes(
                            sessionFocus.focusStep,
                        );
                    const activityStatusScreen = [SessionFocusSteps.TableValidate].includes(sessionFocus?.focusStep)
                        ? RedirectionScreen.StatusScreen
                        : RedirectionScreen.SummaryStatus;

                    if (isFocusOnActivity && isSummary && hasResponses && activity.statusScreenVisible) {
                        return true;
                    }

                    // If focus on summary AND is not summary route: navigate to summary
                    if (isFocusOnSummary && !isSummary) {
                        this.redirectTo(projectId, sessionId, activityId, activity.type, true);
                        return false;
                    }

                    // If on Summary AND on Status OR Waiting Screen AND Status Screen is NOT enabled: navigate to summary
                    if (
                        isSummary
                        && !activity.statusScreenVisible
                        && isWaitingOrStatus
                        && sessionFocus?.activityId === activity.id
                    ) {
                        this.redirectTo(projectId, sessionId, activityId, activity.type, true);
                        return false;
                    }

                    // If Status Screen is enabled AND has responses AND focus is not on summary AND NOT in waiting screen
                    if (
                        activity.statusScreenVisible
                        && hasResponses
                        && !isWaitingOrStatus
                        && !activity.allowChangeAnswers
                        && !isFocusOnSummary
                    ) {
                        const isOnVoteStatusScreen = this.isVoteStatusScreen(activity.type);
                        if (isOnVoteStatusScreen) {
                            // navigate to vote view when refocused and in status screen already
                            return true;
                        } else {
                            // navigate to status screen
                            this.redirectTo(
                                projectId,
                                sessionId,
                                activityId,
                                activity.type,
                                true,
                                activityStatusScreen,
                            );

                            return false;
                        }
                    }

                    // If on Summary AND NOT on Status OR Waiting Screen AND Status Screen is enabled: navigate to status screen
                    if (isSummary && activity.statusScreenVisible && !isWaitingOrStatus && !isFocusOnSummary) {
                        this.redirectTo(projectId, sessionId, activityId, activity.type, true, activityStatusScreen);
                        return false;
                    }

                    // If NOT on Summary and if on Status OR Waiting AND Waiting Screen is NOT enabled: navigate to Activity
                    if (!isSummary && !activity.waitingScreenVisible && isWaitingOrStatus) {
                        this.redirectTo(projectId, sessionId, activityId, activity.type, false);
                        return false;
                    }

                    // If NOT on Summary and if NOT on Status OR Waiting AND Waiting Screen is enabled: navigate to waiting screen
                    const activitiesWithWaitingScreenBeforeActivityFocused = [
                        ActivityType.Vote,
                        ActivityType.QuickPoll,
                    ];
                    const allowNavigateToWaitingScreen = activitiesWithWaitingScreenBeforeActivityFocused.includes(
                        activity.type,
                    );
                    if (
                        !isSummary
                        && activity.waitingScreenVisible
                        && !isWaitingOrStatus
                        && !isFocusOnActivity
                        && allowNavigateToWaitingScreen
                    ) {
                        this.redirectTo(
                            projectId,
                            sessionId,
                            activityId,
                            activity.type,
                            false,
                            RedirectionScreen.WaitingScreen,
                        );
                        return false;
                    }

                    return true;
                },
            ),
        );
    }

    private redirectTo(
        projectId: string,
        sessionId: string,
        activityId: string,
        activityType: ActivityType,
        isSummary: boolean,
        activityStatusScreen?: string,
        isWaitingScreenVisible?: boolean,
    ): Promise<boolean> {
        const commonPath = `project/${projectId}/session/${sessionId}`;
        const activityTypeRoute
            = isSummary && activityType === ActivityType.Vote ? 'vote-summary' : activityType.toLowerCase();
        const activityStatusRoute
            = !activityStatusScreen && isSummary && [ActivityType.QuickPoll, ActivityType.Table].includes(activityType)
                ? 'summary'
                : activityStatusScreen;

        if (!activityStatusRoute) {
            return this.router.navigate([commonPath, activityTypeRoute, activityId]);
        }
        if (isWaitingScreenVisible) {
            return this.router.navigate([commonPath, activityTypeRoute, activityId, activityStatusRoute], {
                queryParams: { activityIdFromLinkAccess: activityId },
            });
        }
        return this.router.navigate([commonPath, activityTypeRoute, activityId, activityStatusRoute]);
    }

    private getParams(route: ActivatedRouteSnapshot): Params {
        if (route.children?.length) {
            return this.getParams(route.children[0]);
        }

        return route.params;
    }

    private getRouteStates(state: RouterStateSnapshot): { isSummary: boolean; isWaiting: boolean } {
        const states = {
            isSummary: state?.url?.includes(RedirectionScreen.Summary),
            isWaiting:
                state?.url?.includes(RedirectionScreen.WaitingScreen)
                || state?.url?.includes(RedirectionScreen.SummaryStatus)
                || state?.url?.includes(RedirectionScreen.StatusScreen),
        };

        return states;
    }

    private isVoteStatusScreen(activityType: ActivityType): boolean {
        return activityType === ActivityType.Vote && this.router.url.includes(RedirectionScreen.SummaryStatus);
    }
}
