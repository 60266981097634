import { Question } from '../question';
import { ActivityItemType } from './activity-item-type';

export class ShortText extends Question {
    type = ActivityItemType.ShortText;

    constructor(data: any) {
        super(data);
    }

    createSerializableObject(): Record<string, unknown> {
        return {
            ...super.createSerializableObject(),
        };
    }
}
