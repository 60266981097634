<ng-container *ngIf="vm$ | async as vm">
  <accenture-base-dialog [title]="'Configure Attributes for Session'">
    <div class="attributes-body">
      <ng-container *accentureLoaded="!vm.isLoading">
        <div *ngIf="vm.attributeClasses" class="position-adjuster mb-32">
          <div class="attributes-tabs-container">
            <div #attributesListWrapper class="attributes-tabs" [class.mr-24]="isBtnVisible">
              <button
                *ngIf="isBtnVisible"
                [disabled]="scrollToAttributeIndex === 0"
                class="tt9-btn-icon attributes-arrows attributes-arrows-left"
                (click)="scrollToAttribute('prev')"
              >
                <accenture-icon>icon-chevron-left</accenture-icon>
              </button>

              <!-- Attributes class list -->
              <div #attributesListContainer class="navigation">
                <div
                  *ngFor="let attributeClass of vm.attributeClasses; let i = index; trackBy: trackById"
                  class="tt9-navigation-tab attributes-class-tab"
                  [ngClass]="{
                    active: attributeClass.id === vm.activeAttributeClassId,
                    'no-menu': vm.isPublicProjectTemplate
                  }"
                  (click)="setActiveAttributeClass(attributeClass.id, vm.activeAttributeClassId)"
                >
                  <span
                    class="tt9-input-text-small class-name text-overflow-ellipsis-1"
                    #attributeClassName
                    matTooltipClass="tt9-arrow-tooltip-below"
                    matTooltipPosition="above"
                    [matTooltip]="attributeClass.name"
                    [matTooltipDisabled]="isDisabledTooltip(attributeClassName)"
                    >{{ attributeClass.name }}</span
                  >
                  <div *ngIf="!vm.isPublicProjectTemplate" class="tab-menu">
                    <button
                      class="tt9-btn-icon tab-menu-btn"
                      [matMenuTriggerFor]="attributeMenu"
                      (click)="$event.preventDefault()"
                    >
                      <accenture-icon [size]="iconSize.Small" [customColor]="'white'">icon-more</accenture-icon>
                    </button>
                    <mat-menu #attributeMenu="matMenu" class="tt9-menu">
                      <button mat-menu-item (click)="openEditAttributesDialog(attributeClass.id)">
                        <accenture-icon>icon-edit</accenture-icon>
                        <span>Edit</span>
                      </button>

                      <button mat-menu-item (click)="deleteAttributeClass(attributeClass.id)">
                        <accenture-icon>icon-delete</accenture-icon>
                        Delete
                      </button>
                    </mat-menu>
                  </div>
                </div>
                <div
                  #addAttributeBtn
                  *ngIf="
                    isAddButtonVisible(vm.attributeClasses.length, vm.activeAttributes.length) &&
                    !vm.isPublicProjectTemplate
                  "
                >
                  <button
                    *ngIf="vm.defaultAttributeClasses?.length"
                    class="tt9-btn-ghost btn-medium btn-icon add-attributes-btn"
                    [matMenuTriggerFor]="addAttributeMenu"
                    (click)="$event.preventDefault()"
                  >
                    <accenture-icon>icon-add</accenture-icon>
                    Add attribute group
                  </button>

                  <button
                    *ngIf="!vm.defaultAttributeClasses?.length"
                    class="tt9-btn-ghost btn-medium btn-icon add-attributes-btn"
                    (click)="openEditAttributesDialog(null, vm.attributeClasses)"
                  >
                    <accenture-icon>icon-add</accenture-icon>
                    Add attribute group
                  </button>
                </div>
              </div>
              <!-- /Attributes class list -->

              <div *ngIf="isBtnVisible" class="d-flex attributes-arrows attributes-arrows-right">
                <button
                  *ngIf="vm.defaultAttributeClasses?.length"
                  [matMenuTriggerFor]="addAttributeMenu"
                  (click)="$event.preventDefault()"
                  class="tt9-btn-icon"
                >
                  <accenture-icon>icon-add</accenture-icon>
                </button>

                <button
                  *ngIf="!vm.defaultAttributeClasses?.length"
                  (click)="openEditAttributesDialog(null, vm.attributeClasses)"
                  class="tt9-btn-icon"
                >
                  <accenture-icon>icon-add</accenture-icon>
                </button>
                <button
                  class="tt9-btn-icon"
                  [disabled]="attributesListWrapper.scrollWidth === attributesListWrapper.clientWidth"
                  (click)="scrollToAttribute('next')"
                >
                  <accenture-icon>icon-chevron-right-small</accenture-icon>
                </button>
              </div>

              <mat-menu #addAttributeMenu="matMenu" class="tt9-menu">
                <button mat-menu-item (click)="openEditAttributesDialog(null, vm.attributeClasses)">
                  Add new group
                </button>

                <ng-container *ngIf="!!vm.defaultAttributeClasses?.length">
                  <button
                    *ngFor="let defaultAttributeClass of vm.defaultAttributeClasses; trackBy: trackById"
                    mat-menu-item
                    (click)="addDefaultAttributeClass(defaultAttributeClass, vm.attributeClasses)"
                  >
                    Add {{ defaultAttributeClass.name | lowercase }} group
                  </button>
                </ng-container>
              </mat-menu>
              <!-- /Default Attributes class list-->
            </div>
          </div>
        </div>

        <!-- Search-->
        <div *ngIf="vm.activeAttributes.length || vm.searchValue" class="attributes-search mb-32">
          <mat-form-field
            appearance="outline"
            class="mat-form-field tt9-form-field width-100 no-label no-margin"
            floatLabel="always"
          >
            <input
              matInput
              placeholder="Search"
              [value]="vm.searchValue"
              (change)="filterAttributes($event.target.value)"
            />
            <accenture-icon matPrefix>icon-search</accenture-icon>
            <accenture-icon *ngIf="vm.searchValue" matSuffix (click)="filterAttributes('')">
              icon-close
            </accenture-icon>
          </mat-form-field>
        </div>
        <!-- /Search-->

        <div class="d-flex-column attributes-classes-container">
          <!-- Attributes defined in the parent -->
          <div *ngIf="!!vm.activeAttributes.length" class="attributes-class-items">
            <mat-chip-listbox hideSingleSelectionIndicator class="tt9-chip">
              <mat-chip-option
                *ngFor="let attribute of vm.activeAttributes; trackBy: trackById"
                class="tt9-attribute-chip disabled mat-primary mat-standard-chip without-border text-overflow-ellipsis"
                [selectable]="false"
                (click)="$event.stopPropagation()"
              >
                <span>{{ attribute.name }}</span>
              </mat-chip-option>
            </mat-chip-listbox>
          </div>
          <!-- /Attributes defined in the parent -->

          <!-- No attributes defined in the parent -->
          <div *ngIf="!vm.attributeClasses.length || !vm.activeAttributes.length" class="attributes-empty-container">
            <accenture-empty-screen
              [title]="
                emptyScreenTitle(
                  vm.attributeClasses.length,
                  vm.activeAttributeClassId,
                  vm.searchValue,
                  vm.activeAttributes.length
                )
              "
              [description]="
                emptyScreenDescription(
                  vm.attributeClasses.length,
                  vm.activeAttributeClassId,
                  vm.searchValue,
                  vm.activeAttributes.length
                )
              "
              [imageUrl]="vm.searchValue ? noAttributesFindImageUrl : noAttributesYetImage"
            ></accenture-empty-screen>
            <div *ngIf="!vm.searchValue && !vm.attributeClasses.length" class="attributes-empty d-flex-center">
              <button
                *ngFor="let defaultAttributeClass of vm.defaultAttributeClasses; trackBy: trackById"
                class="tt9-btn btn-rounded btn-icon btn-with-border attributes-empty-button"
                (click)="addDefaultAttributeClass(defaultAttributeClass, vm.attributeClasses)"
              >
                <accenture-icon>icon-add</accenture-icon>
                <span>Add {{ defaultAttributeClass.name }} Group</span>
              </button>
              <button
                class="tt9-btn btn-rounded btn-icon btn-with-border attributes-empty-button"
                (click)="openEditAttributesDialog(null, vm.attributeClasses)"
              >
                <accenture-icon>icon-add</accenture-icon>
                <span>{{ addGroup }}</span>
              </button>
            </div>
          </div>

          <!-- /No attributes defined in the parent -->
        </div>
      </ng-container>
    </div>
  </accenture-base-dialog>
</ng-container>
