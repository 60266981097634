import { Question } from '../question';
import { ActivityItemType } from './activity-item-type';

export const defaultMaxValue = 100;

export interface NumericOptions {
    max?: number;
    mask?: NumericMask;
}

export class Numeric extends Question {
    type = ActivityItemType.Numeric;
    options: NumericOptions;

    constructor(data: Partial<Numeric>) {
        super(data);

        const options = data.options || {};
        this.options = {
            max: options.max ?? defaultMaxValue,
            mask: options.mask ?? NumericMask.None,
        };
    }

    createSerializableObject(): Record<string, unknown> {
        return {
            ...super.createSerializableObject(),
            options: {
                max: this.options.max,
                mask: this.options.mask,
            },
        };
    }
}

export enum NumericMask {
    None = '',
    Percent = '%',
    Dollar = '$',
}

export const numericMasksValues = {
    [NumericMask.None]: '',
    [NumericMask.Percent]: 'Percentage %',
    [NumericMask.Dollar]: 'Dollar $',
};
