import { PublicTemplateRoleForFilter, TemplateRole, TemplateType } from '@accenture/shared/data';

export const templateTypesListForFilter = [TemplateType.Approved, TemplateType.Community];
export const templateRolesListForFilter = [
    PublicTemplateRoleForFilter.Viewer,
    TemplateRole.Owner,
    TemplateRole.Collaborator,
];

export const templateRoleToDisplay = {
    [TemplateRole.Owner]: 'Owner',
    [TemplateRole.Collaborator]: 'Collaborator',
    [PublicTemplateRoleForFilter.Viewer]: 'Viewer',
};
