export const confirmationDeletionMessage = {
    session: 'Are you sure you want to delete this session? You will lose all associated data.',
    section: 'Are you sure you want to delete this section? You will lose all the nested activities.',
    activity: 'Are you sure you want to delete this activity? You will lose all the associated data.',
};

export const accessSupportUrl = 'https://accenture2061.zendesk.com/hc/en-us';

export const viewGuidesUrl = 'https://accenture2061.zendesk.com/hc/en-us';

export const deactivateMode = 'deactivate';

export const deleteNotifications = {
    title: 'Delete all notifications',
    text: 'Are you sure you want to delete all notifcations?',
};
