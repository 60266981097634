<div class="d-flex-start">
  <div class="d-flex-center mr-16" [class]="iconClass">
    <accenture-icon>{{ iconName }}</accenture-icon>
  </div>

  <div class="snackbar-content">
    <h6 *ngIf="title" class="tt9-body-medium-2">{{ title }}</h6>
    <ng-content></ng-content>

    <div *ngIf="isVisibleDoNotShowAgain" class="mt-8 d-flex">
      <mat-checkbox color="primary" class="mr-auto tt9-checkbox" (change)="doNotShowAgain.emit($event.checked)">
        <span class="tt9-body-2">Do not show again</span>
      </mat-checkbox>

      <button [class]="actionButtonClass" (click)="snackbarClosed.emit()">OK</button>
    </div>

    <div *ngIf="cancelBtnText" class="mt-8 d-flex">
      <button class="mr-8 tt9-snack-bar-accent-btn" (click)="snackbarClosed.emit()">OK</button>
      <button [class]="actionButtonClass" (click)="snackbarCancel.emit()">
        {{ cancelBtnText }}
      </button>
    </div>
  </div>
</div>

<button *ngIf="!hideCloseButton" class="tt9-btn-icon close-button">
  <accenture-icon [size]="iconSize.Small" (click)="snackbarClosed.emit()">icon-close</accenture-icon>
</button>
