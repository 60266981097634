import { ChangeDetectionStrategy, Component, Inject, OnDestroy } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable, tap } from 'rxjs';

import {
    characterLimitMedium,
    characterLimitText,
    characterLimitXL,
    determineHintClass,
    FileType,
    validationErrors,
    validationMessages,
} from '@accenture/shared/data';
import { ImageInputPlaceholdersTypes, LoadedDescription } from '@accenture/shared/ui';

import { EditProjectDialogFacade, EditProjectDialogViewModel } from './edit-project-dialog-facade';

type ProjectEditForm = FormGroup<{
    projectName: FormControl<string>;
    projectDescription: FormControl<string>;
}>;

// TODO: REMOVE AFTER COLLECTIONS WILL BE IMPLEMENTED
@Component({
    selector: 'accenture-edit-project-dialog',
    templateUrl: './edit-project-dialog.component.html',
    styleUrls: ['./edit-project-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [EditProjectDialogFacade],
})
export class EditProjectDialogComponent implements OnDestroy {
    projectEditForm: ProjectEditForm = this.formBuilder.group({
        projectName: new FormControl('', { nonNullable: true, validators: Validators.required }),
        projectDescription: new FormControl('', { nonNullable: true }),
    });

    validationErrors = validationErrors;
    validationMessages = validationMessages;
    loaderDescription = LoadedDescription.ProjectCreating;
    imageInputPlaceholder = ImageInputPlaceholdersTypes.Project;

    characterLimitMedium = characterLimitMedium;
    descriptionCharacterLimit = characterLimitXL;
    characterLimitText = characterLimitText;
    determineHintClass = determineHintClass;

    vm$: Observable<EditProjectDialogViewModel> = this.facade.vm$.pipe(
        tap(({ project, updateProjectEvent }) => {
            if (updateProjectEvent && project?.id) {
                this.projectEditForm.patchValue({
                    projectName: project.name,
                    projectDescription: project.description,
                });
                this.facade.projectWasUpdated();
            }
        }),
    );

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: { title: string; cancelBtnText: string },
        private facade: EditProjectDialogFacade,
        private formBuilder: FormBuilder,
    ) {}

    get projectNameControl(): FormControl<string> {
        return this.projectEditForm.controls.projectName;
    }

    get projectDescriptionControl(): FormControl<string> {
        return this.projectEditForm.controls.projectDescription;
    }

    getTitle(projectOptionsTitle: string, isOptionChipsDisplayed: boolean): string {
        return projectOptionsTitle && isOptionChipsDisplayed ? projectOptionsTitle : this.data.title;
    }

    closeDialog(): void {
        this.facade.closeDialog();
    }

    backToProject(): void {
        this.facade.backToProject();
    }

    setProjectImage(projectImage: FileType): void {
        this.facade.setProjectImage(projectImage);
    }

    updateProjectForm(): void {
        this.facade.updateProjectForm();
    }

    deleteProjectImage(): void {
        this.facade.setProjectImage({} as FileType);
    }

    createOrUpdateProject(name: string, description: string): void {
        this.facade.createOrUpdateProject(name, description);
    }

    isSaveButtonDisabled(
        isClientSelected: boolean,
        isPublicProjectTemplate: boolean,
        hasClientChanged: boolean,
    ): boolean {
        return this.projectEditForm.invalid || (!isClientSelected && hasClientChanged && !isPublicProjectTemplate);
    }

    ngOnDestroy(): void {
        this.facade.resetOptionsStore();
    }
}
