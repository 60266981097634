import { Directive, ElementRef, EventEmitter, HostListener, Input, Output } from '@angular/core';

// list of possible starting class names that can cause clicking outside effect
const customDisableClass = 'accenture-disable-outside-click';
const classNames = [
    customDisableClass,
    'cdk-overlay',
    'mat-mdc-option',
    'mat-focus',
    'mat-calendar',
    'mat-mdc-select',
    'mdc-list-item',
    'mat-mdc-menu-panel',
    'mat-mdc-menu-content',
    'mat-mdc-menu-item',
    'mat-dialog-container',
    'prevent-outside-click',
    'fsp', // filestack
];

@Directive({
    selector: '[accentureClickOutside]',
})
export class ClickOutsideDirective {
    @Input('accentureOutsideDisabled') disabled!: boolean;
    @Output('accentureClickOutside') clickOutside = new EventEmitter<void>();

    constructor(private el: ElementRef) {}

    @HostListener('document:click', ['$event.target'])
    public onClick(target: any): void {
        if (this.disabled) {
            return;
        }

        // check if element is side panel/preview panel
        const hasSidePanel = this.el.nativeElement.classList.contains('tt9-side-panel');

        const clickedInside = this.el.nativeElement.contains(target);
        // if side panel is present, clicking on a modal outside of the side panel should also trigger close.
        // this is used in /dashboard/home -> Feature Templates -> See more modal -> Preview.
        const clickedInsideModal = !hasSidePanel ? !!target.closest('mat-dialog-container') : false;
        const clickedInsideCustomDisabledTarget = !!target.closest(`.${customDisableClass}`);
        const clickedInsideFilestackModal = !!target.closest('.fsp-picker');
        const clickedInsideDatepickerPopup = !!target.closest('.mat-datepicker-popup');

        // checking if clicked target contains outside click classes that can cause unwanted outsideClick effect
        const clickedOnOverlayBackdrop = classNames.some((className) =>
            Array.from(target.classList as string[]).some((c) => c.startsWith(className)),
        );

        if (
            !clickedInside
            && !clickedInsideCustomDisabledTarget
            && !clickedOnOverlayBackdrop
            && !clickedInsideModal
            && !clickedInsideFilestackModal
            && !clickedInsideDatepickerPopup
        ) {
            this.clickOutside.emit();
        }
    }
}
