import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';

@Component({
    selector: 'accenture-template-editor-actions-panel',
    templateUrl: './template-editor-actions-panel.component.html',
    styleUrls: ['./template-editor-actions-panel.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TemplateEditorActionsPanelComponent {
    @Output() closePanel = new EventEmitter<void>();
    @Output() publishChanges = new EventEmitter<void>();
}
