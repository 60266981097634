import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { groupBy, mapValues } from 'lodash';
import { BehaviorSubject, combineLatest, map, Observable, startWith, tap } from 'rxjs';
import { distinctUntilChanged, switchMap } from 'rxjs/operators';

import {
    ActivityOptionsService,
    FavoriteTemplateService,
    FiltersService,
    ProjectOptionsService,
    SessionOptionsService,
} from '@accenture/erp-deployment/shared/domain';
import {
    AppState,
    selectActivityConfigurationsMap,
    selectActivityConfigurationsVisibleTypesList,
    selectAuthenticatedUserId,
} from '@accenture/global-store';
import {
    ActivityConfigurationsMap,
    ActivityOptions,
    ActivityOptionsType,
    ActivityType,
    DefaultFilterObject,
    Dictionary,
    initialDefaultFilterObject,
    ParentType,
    Phase,
    ProjectOptions,
    ProjectOptionsType,
    SessionOptions,
    SessionOptionsType,
    SubPhase,
    Tag,
    templateTabValue,
} from '@accenture/shared/data';
import { sortAlphabeticByCase } from '@accenture/shared/util';

export interface FavoriteTemplatesFiltersAndSortViewModel {
    filtersAndSort: DefaultFilterObject;

    filteredTags: Tag[];
    tagsMap: Dictionary<string>;

    filteredPhases: Phase[];
    filteredSubPhases: SubPhase[];
    phasesMap: Dictionary<string>;
    subPhasesMap: Dictionary<string>;

    activityTypesList: ActivityType[];
    activityConfigurationsMap: ActivityConfigurationsMap;
    parentTypeTab: ParentType | string;
    isAllCollectionsPanelOpened: boolean;
    isLoading: boolean;
}

export const favoriteTemplatesViewModel = {
    filtersAndSort: initialDefaultFilterObject,

    filteredTags: [],
    tagsMap: {},

    filteredPhases: [],
    filteredSubPhases: [],
    phasesMap: {},
    subPhasesMap: {},

    activityTypesList: [],
    activityConfigurationsMap: {},
    parentTypeTab: ParentType.ProjectTemplates,
    isAllCollectionsPanelOpened: false,
    isLoading: true,
};

@Injectable()
export class FavoriteTemplatesFiltersAndSortFacade {
    autocompleteValues$ = new BehaviorSubject<Dictionary<string>>({});
    isAllCollectionsPanelOpened$ = new BehaviorSubject<boolean>(false);

    vm$ = this.buildViewModel();

    private userId: string;
    private parentTypeTab: string;

    constructor(
        private store: Store<AppState>,
        private favoriteTemplateService: FavoriteTemplateService,
        private filtersService: FiltersService,
        private projectOptionsService: ProjectOptionsService,
        private sessionOptionsService: SessionOptionsService,
        private activityOptionsService: ActivityOptionsService,
    ) {}

    applyFiltersAndSort(filters: DefaultFilterObject): void {
        if (!this.userId) {
            return;
        }

        this.favoriteTemplateService.updateFavoriteFilters(this.userId, { [this.parentTypeTab]: filters });
    }

    setOptionType(optionType: ProjectOptions | SessionOptions | ActivityOptions): void {
        this.filtersService.optionType.next(optionType);
        this.isAllCollectionsPanelOpened$.next(true);
    }

    toggleOptionsPanel(opened: boolean): void {
        this.isAllCollectionsPanelOpened$.next(opened);
    }

    private getTags(): Observable<(ProjectOptionsType | SessionOptionsType | ActivityOptionsType)[]> {
        switch (this.parentTypeTab) {
            case ParentType.ProjectTemplates:
                return this.projectOptionsService.getOptions(ProjectOptions.Tag);
            case ParentType.Templates:
                return this.sessionOptionsService.getOptions(SessionOptions.Tag);
            default:
                return this.activityOptionsService.getOptions(ParentType.Activities);
        }
    }

    private buildViewModel(): Observable<FavoriteTemplatesFiltersAndSortViewModel> {
        return combineLatest([
            this.store.select(selectAuthenticatedUserId),
            this.store.select(selectActivityConfigurationsVisibleTypesList),
            this.store.select(selectActivityConfigurationsMap),
            this.filtersService.favoriteTemplatesViewTab$.pipe(distinctUntilChanged()),
        ]).pipe(
            switchMap(([userId, activityTypesList, activityConfigurationsMap, templateTab]) => {
                this.userId = userId;
                this.parentTypeTab = templateTabValue[templateTab];

                const filters$ = this.getAllFavoriteTemplatesFilters(this.parentTypeTab);
                return combineLatest([
                    filters$,
                    this.getTags(),
                    this.sessionOptionsService.getOptions(SessionOptions.Phase),
                    this.sessionOptionsService.getOptions(SessionOptions.SubPhase),
                    this.autocompleteValues$.asObservable().pipe(distinctUntilChanged()),
                    this.isAllCollectionsPanelOpened$.asObservable().pipe(distinctUntilChanged()),
                ]).pipe(
                    map(
                        ([
                            filtersAndSort,
                            tags,
                            phases,
                            subPhases,
                            autocompleteValues,
                            isAllCollectionsPanelOpened,
                        ]) => {
                            return {
                                filtersAndSort,
                                activityConfigurationsMap,
                                isAllCollectionsPanelOpened,
                                activityTypesList,
                                filteredPhases: this.filterSelectedData(
                                    filtersAndSort.phases,
                                    phases as Phase[],
                                    autocompleteValues.phase,
                                ),
                                filteredSubPhases: this.filterSelectedData(
                                    filtersAndSort.subPhases,
                                    subPhases as SubPhase[],
                                    autocompleteValues.subPhase,
                                ),
                                filteredTags: this.filterSelectedData(
                                    filtersAndSort.tags,
                                    tags as Tag[],
                                    autocompleteValues.tag,
                                ),
                                tagsMap: mapValues(groupBy(tags as Tag[], 'id'), tags => tags[0].name),
                                phasesMap: mapValues(groupBy(phases, 'id'), phases => phases[0].name),
                                subPhasesMap: mapValues(groupBy(subPhases, 'id'), subPhases => subPhases[0].name),
                                parentTypeTab: this.parentTypeTab,
                                isLoading: false,
                            };
                        },
                    ),
                );
            }),
            startWith(favoriteTemplatesViewModel),
        );
    }

    private getAllFavoriteTemplatesFilters(parentTypeTab: ParentType | string): Observable<DefaultFilterObject> {
        return this.store.select(selectAuthenticatedUserId).pipe(
            tap(userId => (this.userId = userId)),
            switchMap(userId =>
                this.favoriteTemplateService
                    .getCurrentUserAllFavoriteTemplatesFilters(userId)
                    .pipe(map(allFilters => (allFilters ? allFilters[parentTypeTab] : {}))),
            ),
            map(filtersAndSort => ({
                ...initialDefaultFilterObject,
                ...filtersAndSort,
            })),
        );
    }

    private filterSelectedData(
        currentData: string[],
        data: (ProjectOptionsType | SessionOptionsType | ActivityOptionsType)[],
        value = '',
    ): (ProjectOptionsType | SessionOptionsType | ActivityOptionsType)[] {
        const filteredData = data.filter(tag => {
            const tagName = tag?.name?.toLowerCase();
            return tagName?.includes(value?.toLowerCase()) && !currentData?.includes(tag.id);
        });
        return sortAlphabeticByCase(filteredData);
    }
}
