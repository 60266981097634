export enum TemplateRole {
    Owner = 'TEMPLATE_OWNER',
    Collaborator = 'TEMPLATE_COLLABORATOR',
}

export enum ProjectRole {
    Admin = 'PROJECT_ADMIN',
    Member = 'PROJECT_MEMBER',
}

export enum SessionRole {
    Leader = 'SESSION_LEADER',
    Participant = 'SESSION_PARTICIPANT',
    Observer = 'SESSION_OBSERVER',
}

export enum CollectionRole {
    Owner = 'COLLECTION_OWNER',
    Viewer = 'COLLECTION_VIEWER',
}

export enum AdminRole {
    GlobalAdmin = 'Global Admin',
}

export const displayRole = {
    [ProjectRole.Admin]: 'Admin',
    [ProjectRole.Member]: 'Member',
    [SessionRole.Leader]: 'Leader',
    [SessionRole.Participant]: 'Participant',
    [SessionRole.Observer]: 'Observer',
    [TemplateRole.Owner]: 'Owner',
    [TemplateRole.Collaborator]: 'Collaborator',
};

export type AccessControlRole = TemplateRole | ProjectRole | SessionRole | CollectionRole;

export const allowedChangeRoleMap: { [key in AccessControlRole]: AccessControlRole } = {
    [ProjectRole.Admin]: ProjectRole.Member,
    [ProjectRole.Member]: ProjectRole.Admin,
    [SessionRole.Leader]: SessionRole.Participant,
    [SessionRole.Participant]: SessionRole.Leader,

    [SessionRole.Observer]: SessionRole.Observer,
    [TemplateRole.Owner]: TemplateRole.Collaborator,
    [TemplateRole.Collaborator]: TemplateRole.Owner,

    [CollectionRole.Owner]: CollectionRole.Owner,
    [CollectionRole.Viewer]: CollectionRole.Viewer, // TODO: Remove/update this when we do shared collections, used temporarily for testing
};

export interface AccessControlData {
    role: AccessControlRole;
    displayName: string;
}

export interface AccessControl {
    [userId: string]: AccessControlData;
}
