<ng-container *ngIf="vm$ | async as vm">
  <ng-container *accentureLoaded="!vm.isLoading; description: loaderText">
    <accenture-base-dialog [title]="vm.contactSupportDialogTitle" [subTitle]="vm.contactSupportDialogSubTitle">
      <div class="p-32">
        <form class="feedback-form" [formGroup]="feedbackForm">
          <div class="mb-32">
            <mat-form-field
              appearance="outline"
              class="textarea tt9-form-field mat-form-field-appearance-outline width-100"
            >
              <textarea matInput formControlName="feedback" [placeholder]="inputPlaceholders.enterText"></textarea>
              <mat-error *ngIf="feedback.hasError(validationErrors.required)">{{
                validationMessages.required
              }}</mat-error>
            </mat-form-field>
          </div>

          <div [ngClass]="{ 'mb-32': applyEmail.value }">
            <mat-checkbox
              class="tt9-checkbox"
              color="primary"
              formControlName="applyEmail"
              (change)="updateEmailValidators()"
            >
              Contact me about my request
            </mat-checkbox>
          </div>

          <div *ngIf="applyEmail.value">
            <mat-form-field appearance="outline" class="tt9-form-field no-margin width-100">
              <mat-label>Email</mat-label>
              <input matInput name="email" formControlName="email" [placeholder]="inputPlaceholders.email" />
              <mat-error *ngIf="email.hasError(validationErrors.required)">{{ validationMessages.required }}</mat-error>
              <mat-error *ngIf="email.hasError(validationErrors.email) && !email.hasError(validationErrors.required)">
                {{ validationMessages.invalidEmail }}
              </mat-error>
            </mat-form-field>
          </div>
        </form>
      </div>

      <div class="footer d-flex-end">
        <button class="tt9-btn ml-16" [disabled]="feedbackForm.invalid" (click)="submitForm()">Submit</button>
      </div>
    </accenture-base-dialog>
  </ng-container>
</ng-container>
