//this is dummy data only
export enum ClientName {
    One = 'CLIENT_ONE',
    Two = 'CLIENT_TWO',
}

export enum ProjectName {
    One = 'PROJECT_ONE',
    Two = 'PROJECT_TWO',
}

export const displayClientName = {
    [ClientName.One]: 'One',
    [ClientName.Two]: 'Two',
};

export const displayProjectName = {
    [ProjectName.One]: 'One',
    [ProjectName.Two]: 'Two',
};
