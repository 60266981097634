import { AccessControl } from './access-control';
import { Dictionary } from './dictionary';
import { NodeTemplate, NodeType } from './node-template';

export enum TemplateType {
    Approved = 'approved',
    Community = 'community',
}

export enum FavoriteTemplateType {
    Approved = 'APPROVED',
    Community = 'COMMUNITY',
    MyTemplate = 'TEMPLATE',
}

export const templateTypeToDisplay = {
    [TemplateType.Approved]: 'Accenture Approved',
    [TemplateType.Community]: 'Community',
};

export const favoriteTemplateTypeToDisplay = {
    [FavoriteTemplateType.Approved]: 'Accenture Approved',
    [FavoriteTemplateType.Community]: 'Community',
    [FavoriteTemplateType.MyTemplate]: 'My Template',
};

export enum TemplateStatus {
    Published = 'published',
}

export const templateStatusToDisplay = {
    [TemplateStatus.Published]: 'Published',
};

export enum UserTemplateType {
    SessionTemplates = 'SESSION_TEMPLATE',
    ActivityTemplate = 'ACTIVITY_TEMPLATE',
};

export class Template extends NodeTemplate {
    access: AccessControl;
    likes?: Dictionary<boolean>;
    creatorId?: string;
    creatorName?: string;
    creatorImage?: string;
    selfNavigate?: boolean;
    displayAgenda?: boolean;
    tags?: Dictionary<string>;
    subPhase?: Dictionary<string>;
    phase?: Dictionary<string>;
    status?: TemplateStatus;
    favoritesCount?: number;
    clients?: Dictionary<string>;

    /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
    constructor(id: string, data: any) {
        super(id, data, NodeType.Session);

        this.access = data.access ?? {};
        this.likes = data.likes ?? {};
        this.creatorId = data.creatorId ?? '';
        this.creatorName = data.creatorName ?? '';
        this.creatorImage = data.creatorImage ?? '';
        this.selfNavigate = data.selfNavigate ?? false;
        this.displayAgenda = data.displayAgenda ?? false;
        this.tags = (data.tags as Dictionary<string>) ?? {};
        this.phase = (data.phase as Dictionary<string>) ?? {};
        this.subPhase = (data.subPhase as Dictionary<string>) ?? {};
        this.status = data.status ?? '';
        this.favoritesCount = data.favoritesCount ?? 0;
        this.clients = (data.clients as Dictionary<string>) ?? {};
    }

    createSerializableObject(): Record<string, unknown> {
        return {
            name: this.name,
            description: this.description,
            imageUrl: this.imageUrl,
            imageId: this.imageId,
            access: this.access,
            created: this.created,
            likes: this.likes,
            updated: this.updated,
            creatorId: this.creatorId,
            creatorName: this.creatorName,
            creatorImage: this.creatorImage,
            favoritesCount: this.favoritesCount,
            clients: this.clients,
        };
    }
}
