import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';

import { DialogService } from '@accenture/shared/ui';

import { EmailsImportWarningDialogComponent } from '../dialogs';

@Component({
    selector: 'accenture-emails-import-warning-snackbar',
    templateUrl: './emails-import-warning-snackbar.component.html',
    styleUrls: ['./emails-import-warning-snackbar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmailsImportWarningSnackbarComponent {
    readonly title = 'Inviting team members warning';
    readonly message = 'Some users have not been invited';

    constructor(
        public snackBarRef: MatSnackBarRef<EmailsImportWarningSnackbarComponent>,
        @Inject(MAT_SNACK_BAR_DATA)
        public data: {
            iconClass: string;
            iconName: string;

            emails: string[];
        },
        private dialogService: DialogService,
    ) {}

    close(): void {
        this.snackBarRef.dismiss();
    }

    viewDetails(): void {
        this.snackBarRef.dismiss();
        this.dialogService.open(EmailsImportWarningDialogComponent, {
            emails: this.data.emails,
            width: '444px',
        });
    }
}
