<ng-container *ngIf="vm$ | async as vm">
  <ng-container *accentureLoaded="!vm.isLoading; description: vm.loaderDescription">
    <accenture-base-dialog [title]="getTitleToDisplay(vm.currentOptionToDisplay)">
      <div class="dialog-body d-flex-column">
        <div class="session-edit-container">
          <ng-container *ngIf="!vm.currentOptionToDisplay">
            <div class="session-edit-image">
              <accenture-image-input
                [file]="vm.sessionImage"
                [mode]="imageInputMode"
                [imagePlaceholder]="imageInputPlaceholder"
                (fileUploaded)="setSessionImage($event)"
                (fileDeleted)="deleteSessionImage()"
              ></accenture-image-input>
            </div>

            <form class="session-edit-form" [formGroup]="sessionEditForm">
              <mat-form-field appearance="outline" floatLabel="auto" class="tt9-form-field width-100">
                <mat-label>Session name</mat-label>
                <input
                  #inputName
                  matInput
                  type="text"
                  formControlName="sessionName"
                  [maxlength]="characterLimitMedium"
                />
                <mat-hint
                  class="mat-hint-limit"
                  [class]="determineHintClass(inputName.value.length, characterLimitMedium)"
                  >{{ characterLimitText(inputName.value.length, characterLimitMedium) }}</mat-hint
                >
                <mat-error *ngIf="sessionNameControl?.hasError(validationErrors.required)">{{
                  validationMessages.required
                }}</mat-error>
              </mat-form-field>

              <mat-form-field appearance="outline" floatLabel="auto" class="tt9-form-field width-100">
                <mat-label>Description</mat-label>
                <input
                  #inputDescription
                  matInput
                  type="text"
                  formControlName="sessionDescription"
                  [maxlength]="descriptionCharacterLimit"
                />
                <mat-hint
                  class="mat-hint-limit"
                  [class]="determineHintClass(inputDescription.value.length, descriptionCharacterLimit)"
                  >{{ characterLimitText(inputDescription.value.length, descriptionCharacterLimit) }}</mat-hint
                >
              </mat-form-field>

              <div *ngIf="!vm.isProjectOrSessionTemplate" class="session-edit-dates">
                <!-- start date -->
                <mat-form-field appearance="outline" floatLabel="auto" class="tt9-form-field date-form-field">
                  <mat-label>Start date</mat-label>
                  <input
                    matInput
                    readonly
                    formControlName="startDate"
                    [placeholder]="inputPlaceholders.startDate"
                    [matDatepicker]="datePickerFrom"
                    (click)="datePickerFrom.open()"
                  />
                  <mat-datepicker-toggle matSuffix [for]="datePickerFrom">
                    <accenture-icon matDatepickerToggleIcon> icon-calendar </accenture-icon>
                  </mat-datepicker-toggle>
                  <mat-datepicker #datePickerFrom></mat-datepicker>
                  <accenture-icon
                    *ngIf="sessionStartDateControl?.value"
                    matSuffix
                    class="mat-select-icon date-reset"
                    (click)="clearDateInput('startDate', $event)"
                  >
                    icon-close
                  </accenture-icon>
                </mat-form-field>
                <!-- /start date -->

                <!-- end date -->
                <mat-form-field appearance="outline" floatLabel="auto" class="tt9-form-field date-form-field">
                  <mat-label>End date</mat-label>
                  <input
                    matInput
                    readonly
                    formControlName="endDate"
                    [placeholder]="inputPlaceholders?.endDate"
                    [matDatepicker]="datePickerTo"
                    (click)="datePickerTo.open()"
                  />
                  <mat-datepicker-toggle matSuffix [for]="datePickerTo">
                    <accenture-icon matDatepickerToggleIcon> icon-calendar </accenture-icon>
                  </mat-datepicker-toggle>
                  <mat-datepicker #datePickerTo></mat-datepicker>
                  <accenture-icon
                    *ngIf="sessionEndDateControl?.value"
                    matSuffix
                    class="mat-select-icon date-reset"
                    (click)="clearDateInput('endDate', $event)"
                  >
                    icon-close
                  </accenture-icon>
                  <mat-error *ngIf="isEndDateGreaterStartDateErrorVisibility">{{
                    validationMessages.endDateGreaterStartDate
                  }}</mat-error>
                </mat-form-field>
                <!-- /end date -->
              </div>
            </form>
          </ng-container>

          <accenture-session-options></accenture-session-options>
        </div>

        <div class="session-edit-footer p-16">
          <div *ngIf="vm.sessionHasUpdates && !vm.isLoading">
            <span>Session edit data updates available</span>
            <span class="session-edit-update ml-8" (click)="updateSessionForm()">Update</span>
          </div>

          <div class="d-flex buttons-container ml-auto">
            <button *ngIf="vm.currentOptionToDisplay" class="tt9-btn-default btn-icon" (click)="backToSessionDialog()">
              <accenture-icon>icon-chevron-left-small</accenture-icon>
              <span>Back</span>
            </button>

            <button
              *ngIf="!vm.currentOptionToDisplay"
              class="tt9-btn-default mr-8"
              [class.btn-icon]="data.cancelButtonIcon"
              (click)="closeDialog()"
            >
              <accenture-icon *ngIf="data.cancelButtonIcon">
                {{ data.cancelButtonIcon }}
              </accenture-icon>
              <span>{{ data.cancelButtonText }}</span>
            </button>

            <button
              *ngIf="!vm.currentOptionToDisplay"
              class="tt9-btn"
              [disabled]="sessionEditForm.invalid"
              (click)="
                createOrUpdateSession(
                  sessionNameControl.value,
                  sessionDescriptionControl.value,
                  sessionStartDateControl.value,
                  sessionEndDateControl.value
                )
              "
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </accenture-base-dialog>
  </ng-container>
</ng-container>
