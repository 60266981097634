<ng-container *ngIf="vm$ | async as vm">
  <ng-container *accentureLoaded="!vm.isLoading">
    <div class="templates-list" [class.home-page]="!vm.isTemplatesPage">
      <div *ngIf="showActions(!!vm.allTemplates?.length, !!vm.searchValue)" class="content-actions mb-16">
        <div class="d-flex-center">
          <ng-container *ngFor="let tab of templateTabs; trackBy: trackByValue">
            <div
              class="tt9-navigation-tab"
              [ngClass]="{ active: tab === vm.activeTemplateTab }"
              (click)="setTemplateTab(tab)"
            >
              <p class="tt9-input-text-small">{{ tab }}</p>
            </div>
          </ng-container>
        </div>

        <ng-container *ngIf="vm.isTemplatesPage && vm.isAnyTemplate">
          <div class="content-actions-search ml-40">
            <mat-form-field
              appearance="outline"
              class="tt9-form-field no-label no-margin width-100"
              floatLabel="always"
            >
              <input
                #search
                matInput
                [formControl]="searchControl"
                [placeholder]="inputPlaceholders.search"
                (input)="setSearchValue(search.value)"
              />

              <accenture-icon matPrefix>icon-search</accenture-icon>

              <accenture-icon *ngIf="vm.searchValue && !search.disabled" matSuffix (click)="clearSearchValue($event)"
                >icon-close</accenture-icon
              >
            </mat-form-field>
          </div>

          <button
            class="tt9-btn-icon-filled tt9-btn-applied"
            matTooltipPosition="above"
            matTooltipClass="tt9-arrow-tooltip-below"
            [matTooltip]="tooltipTexts.filterTemplate"
            [class.active]="vm.isFilterAndSortPanelOpened"
            [disabled]="vm.isFilterAndSortPanelOpened || !vm?.templatesOfCurrentTab?.length"
            (click)="toggleFilterAndSortPanel(true, $event)"
          >
            <accenture-icon>icon-filter</accenture-icon>

            <div *ngIf="vm.isFiltersApplied && vm?.templatesOfCurrentTab?.length" class="applied"></div>
          </button>
        </ng-container>
      </div>

      <div
        class="main-data"
        [ngClass]="{
          fixed: !vm.isTemplatesPage,
          'empty-screen-container': !vm.templates?.length
        }"
      >
        <ng-container *ngIf="vm.templates?.length">
          <div
            *ngFor="let template of vm.templates; trackBy: trackById; let i = index"
            class="card-container"
            (mouseenter)="hoverTemplateCard(i)"
            (mouseleave)="unhoverTemplateCard(i)"
          >
            <accenture-template-card
              [template]="template"
              [hoveredCardIndex]="hoveredCardIndex | async"
              [isTemplatesPage]="vm.isTemplatesPage"
              [isAllTemplateTab]="vm.activeTemplateTab === templateTab.All"
              [isFavorite]="!!vm.favoritesTemplatesMap?.[template.templateId]"
              [templateIndex]="i"
              [userId]="vm.userId"
              (openPublishDialog)="onOpenPublishDialog($event)"
              (deleteTemplate)="onDeleteTemplate($event)"
              (useTemplate)="useTemplate($event)"
              (updateFavorite)="onUpdateFavorite($event)"
              (openMenu)="menuOpened = true"
              (closeMenu)="menuOpened = false"
            ></accenture-template-card>
          </div>
        </ng-container>
        <div *ngIf="!vm.templates?.length" class="empty-screen">
          <accenture-empty-screen
            [title]="getEmptyScreenTitle(vm)"
            [imageUrl]="emptyScreenImageUrlTemplates"
          ></accenture-empty-screen>
          <button
            *ngIf="!vm.isTemplatesPage"
            class="tt9-btn btn-rounded btn-with-border btn-medium-size"
            (click)="navigateToTemplatesPage()"
          >
            <accenture-icon class="mr-8">icon-template</accenture-icon>
            <span>Get More Templates</span>
          </button>
        </div>
      </div>
    </div>

    <!-- Filter and Sort side panel -->
    <accenture-side-panel
      *ngIf="vm.isFilterAndSortPanelOpened"
      cdkOverlayOrigin
      #origin="cdkOverlayOrigin"
      [panelTrigger]="origin"
      [panelTitle]="'Filter & Sort'"
      [panelOpened]="vm.isFilterAndSortPanelOpened"
      (closePanel)="toggleFilterAndSortPanel(false)"
    >
      <accenture-default-filters-and-sort></accenture-default-filters-and-sort>
    </accenture-side-panel>
    <!-- Filter and Sort side panel -->
  </ng-container>
</ng-container>
