<ng-container *ngIf="vm$ | async as vm">
  <div class="content">
    <div class="search mb-32">
      <ng-container *ngTemplateOutlet="search"></ng-container>
    </div>

    <ng-container *ngTemplateOutlet="userList"></ng-container>
  </div>

  <div class="accenture-dialog-actions">
    <button class="btn-icon tt9-btn-default" (click)="backOnInviteStep()">
      <accenture-icon>icon-chevron-left</accenture-icon>
      Back
    </button>
  </div>

  <ng-template #search>
    <mat-form-field appearance="outline" class="tt9-form-field no-label no-margin width-100" floatLabel="always">
      <input #search matInput [formControl]="searchControl" [placeholder]="inputPlaceholders.search" />

      <accenture-icon matPrefix>icon-search</accenture-icon>

      <accenture-icon *ngIf="search.value" matSuffix (click)="clearFilter()">icon-close</accenture-icon>
    </mat-form-field>
  </ng-template>

  <ng-template #userList>
    <div class="user-list">
      <ng-container *ngIf="vm.projectMemberViewModels.length; else emptySearchList">
        <div
          *ngFor="let projectMemberViewModel of vm.projectMemberViewModels; trackBy: trackByUserId"
          class="user d-flex"
        >
          <ng-container *ngTemplateOutlet="userInfo; context: { projectMemberViewModel, role: vm.invitationRole }" />
        </div>
      </ng-container>

      <ng-template #emptySearchList>
        <accenture-empty-screen
          *ngIf="searchControl.value"
          [emptyScreenType]="emptyScreenType.Small"
          [title]="emptyScreenTitles.noUsersMatch"
        ></accenture-empty-screen>
      </ng-template>
    </div>
  </ng-template>
</ng-container>

<ng-template #userInfo let-projectMember="projectMemberViewModel" let-role="role">
  <ng-container *ngIf="addProjectMemberTyping(projectMember) as memberViewModel">
    <ng-container *ngIf="memberViewModel.user as user">
      <div
        class="user-info d-flex-center-between width-100"
        [class.disable]="memberViewModel.disabled"
        (click)="emitCheckboxToggle(selectCheckbox, $event, memberViewModel.disabled)"
      >
        <div class="select-checkbox mr-8">
          <mat-checkbox
            #selectCheckbox
            class="tt9-checkbox"
            color="primary"
            [checked]="memberViewModel.checked"
            [disabled]="memberViewModel.disabled"
            (change)="toggleMemberSelection(memberViewModel.user.email, $event.checked, role)"
            (click)="$event.stopPropagation()"
          ></mat-checkbox>
        </div>

        <div class="d-flex flex-grow-1">
          <div class="userpic-container">
            <div class="tt9-userpic medium tt9-tooltip mr-8">
              <img *ngIf="user.imageUrl" [src]="user.imageUrl" [alt]="user.displayName" />

              <ng-container *ngIf="!user.imageUrl">{{ user.displayName | userInitials }}</ng-container>
            </div>
          </div>

          <div class="d-flex-column">
            <p
              #userName
              *ngIf="user.displayName !== user.email"
              class="tt9-color-neutral tt9-body-medium-1 text-overflow-ellipsis-1"
              matTooltipClass="tt9-arrow-tooltip-above"
              [matTooltip]="user.displayName"
              [matTooltipDisabled]="isTooltipDisabled(userName)"
            >
              {{ user.displayName }}
            </p>

            <p
              #userEmail
              class="tt9-body-2 text-overflow-ellipsis-1"
              matTooltipClass="tt9-arrow-tooltip-above"
              [matTooltip]="user.email"
              [matTooltipDisabled]="isTooltipDisabled(userEmail)"
            >
              {{ user.email }}
            </p>
          </div>
        </div>

        <div *ngIf="user.isProjectAdmin" class="admin-tag tt9-body-2">Admin</div>
      </div>
    </ng-container>
  </ng-container>
</ng-template>
