import { Pipe, PipeTransform } from '@angular/core';

import { DataFlowConnection, dataFlowDescriptionMap } from '@accenture/shared/data';

@Pipe({
    name: 'displayConnectionCriteriaLabel',
    pure: true,
})
export class DisplayConnectionCriteriaLabelPipe implements PipeTransform {
    transform(connections: DataFlowConnection[]): string {
        const connection = connections?.[0];

        if (!connection.filterProperty) return '';

        return dataFlowDescriptionMap[connection.filterProperty](connection.filterValue ?? 0) || '';
    }
}
