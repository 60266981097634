import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';

@Component({
    selector: 'accenture-text-snack-bar',
    templateUrl: './text-snack-bar.component.html',
    styleUrls: ['./text-snack-bar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TextSnackBarComponent {
    constructor(
        public snackBarRef: MatSnackBarRef<TextSnackBarComponent>,
        @Inject(MAT_SNACK_BAR_DATA)
        public data: {
            message: string;
            iconClass: string;
            iconName: string;
            actionButtonClass: string;
            title?: string;
            isVisibleDoNotShowAgain?: boolean;
            hideCloseButton?: boolean;
            cancelBtnText?: string;
            onSnackBarDoNotShowAgain?: (value: boolean) => void;
            onSnackBarCancel?: () => void;
        },
    ) {}

    onDoNotShowAgain(value: boolean): void {
        this.data.onSnackBarDoNotShowAgain?.(value);
    }

    onCancel(): void {
        this.data.onSnackBarCancel?.();
    }

    onClose(): void {
        this.snackBarRef.dismiss();
    }
}
