import { Pipe, PipeTransform } from '@angular/core';

import { TemplateStatus, templateStatusToDisplay } from '@accenture/shared/data';

@Pipe({
    name: 'displayTemplateStatus',
})
export class DisplayTemplateStatusPipe implements PipeTransform {
    transform(type: TemplateStatus): string {
        return templateStatusToDisplay?.[type] || '';
    }
}
