import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';
import { Router } from '@angular/router';

@Component({
    selector: 'accenture-template-store-project-creation-snackbar',
    templateUrl: './template-store-project-creation-snackbar.component.html',
    styleUrls: ['./template-store-project-creation-snackbar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TemplateStoreProjectCreationSnackbarComponent {
    constructor(
        public snackBarRef: MatSnackBarRef<TemplateStoreProjectCreationSnackbarComponent>,
        @Inject(MAT_SNACK_BAR_DATA)
        public data: {
            message: string;
            iconClass: string;
            iconName: string;
            actionButtonClass: string;
            title: string;
            isVisibleNavigateHome: boolean;
            hideCloseButton?: boolean;
        },
        private router: Router,
    ) {}

    onClose(): void {
        this.router.navigate(['/home']);
        this.snackBarRef.dismiss();
    }
}
