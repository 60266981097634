import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Observable } from 'rxjs';

import {
    collectionSortOptionsValues,
    DefaultCollectionSortObject,
    ProjectOptionsType,
    SessionOptionsType,
    sortOrdersValues,
} from '@accenture/shared/data';
import { trackById } from '@accenture/shared/util';

import {
    FavoriteTemplatesOptionsFiltersChipsFacade,
    FavoriteTemplatesOptionsFiltersChipsViewModel,
} from './favorite-templates-options-filters-chips-facade';

@Component({
    selector: 'accenture-favorite-templates-options-filters-chips',
    templateUrl: './favorite-templates-options-filters-chips.component.html',
    styleUrls: ['./favorite-templates-options-filters-chips.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [FavoriteTemplatesOptionsFiltersChipsFacade],
})
export class FavoriteTemplatesOptionsFiltersChipsComponent {
    vm$: Observable<FavoriteTemplatesOptionsFiltersChipsViewModel> = this.facade.vm$;

    sortOrderOptions = sortOrdersValues;
    sortByOptions = collectionSortOptionsValues;

    trackById = trackById;

    constructor(private facade: FavoriteTemplatesOptionsFiltersChipsFacade) {}

    isOptionSelected(selectedOptions: string[], id: string): boolean {
        return Array.isArray(selectedOptions) ? selectedOptions.includes(id) : !!selectedOptions?.[id];
    }

    selectOption(selectedOptions: string[], option: ProjectOptionsType | SessionOptionsType): void {
        const isSelected = this.isOptionSelected(selectedOptions, option.id);
        this.facade.applyOptionsFilter(selectedOptions, option.id, isSelected);
    }

    updateCurrentSort(sortObject: DefaultCollectionSortObject): void {
        this.facade.updateCollectionSortOptions(sortObject);
    }
}
