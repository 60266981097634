import { NodeTemplate, NodeType } from './node-template';
import { PromptCategory } from './prompt-category';
import { PromptType } from './prompt-type';

export class PromptLibrary extends NodeTemplate {
    id?: string;
    category: PromptCategory[];
    type: PromptType[];
    useCase: string;
    description: string;
    promptText: string;

    constructor(id: string, data: Partial<PromptLibrary>) {
        super(id, data, NodeType.Session);
        this.id = id;
        this.category = data.category ?? [];
        this.type = data.type ?? [];
        this.useCase = data.useCase ?? '';
        this.description = data.description ?? '';
        this.promptText = data.promptText ?? '';
    }
}
