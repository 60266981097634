<div class="container mx-2 px-1 my-2 pb-6">
  <div class="recordings-container d-flex-column">
    <div class="recordings-content">
      <ng-container>
        <section accentureScrollSpy class="table-container tt9-team-table-container">
          <table class="tt9-team-table tt9-body-2">
            <thead>
              <tr class="row-sticky">
                <th class="column-name">Recordings</th>
              </tr>
            </thead>
            <tbody>
              <ng-container *ngFor="let recording of recordings; trackBy: trackById">
                <ng-container *ngTemplateOutlet="recordingInfo; context: { recording: recording }"></ng-container>
              </ng-container>
            </tbody>
          </table>

          <ng-template #recordingInfo let-recording="recording">
            <tr>
              <td class="user-cell">
                <div class="d-flex">
                  <div class="icon-container">
                    <div class="tt9-userpic medium tt9-tooltip mr-16">
                      <mat-icon *ngIf="recording.type === recordingType.Capture">videocam</mat-icon>
                      <mat-icon *ngIf="recording.type === recordingType.Upload">upload</mat-icon>
                    </div>
                  </div>
                  <div class="user-cell-info d-flex-column">
                    <p
                      #recordingCreated
                      class="tt9-color-neutral tt9-body-medium-1 text-overflow-ellipsis-1"
                      matTooltipClass="tt9-arrow-tooltip-above"
                      [matTooltip]="recording.fileName || formatDate(recording.created)"
                    >
                      {{ recording.fileName || formatDate(recording.created) }}
                    </p>
                    <p class="text-overflow-ellipsis-1 tt9-caption-1">
                      {{ recording.length }},
                      {{ mediaType[recording.mediaType] }}
                    </p>
                    <p #displayName class="text-overflow-ellipsis-1">
                      {{ recording.displayName || recording.userId }}
                    </p>
                  </div>

                  <button class="tt9-btn-icon menu-btn options" [matMenuTriggerFor]="optionsMenu">
                    <accenture-icon>icon-more</accenture-icon>
                  </button>

                  <mat-menu #optionsMenu="matMenu" class="tt9-menu">
                    <button
                      mat-menu-item
                      (click)="viewTranscription(recording.id, recording.mediaType, recording.transcription)"
                    >
                      Transcription
                    </button>
                    <button mat-menu-item (click)="downloadRecording(recording.fileUrl)">Download</button>
                    <button mat-menu-item class="tt9-btn-default btn-error" (click)="deleteRecording(recording.id)">
                      Delete
                    </button>
                  </mat-menu>
                </div>
              </td>
            </tr>
          </ng-template>
        </section>
      </ng-container>
    </div>
  </div>
</div>
