export enum SortOptions {
    Updated = 'LAST_UPDATED',
    Created = 'DATE_CREATED',
    StartDate = 'START_DATE',
    EndDate = 'END_DATE',
    LastViewed = 'LAST_VIEWED',
    Name = 'NAME',
    TimesUsed = 'TIMES_USED',
    MostFavorite = 'MOST_FAVORITE',
    Upvotes = 'UPVOTES',
    Likes = 'LIKES',
    Alphabetical = 'ALPHABETICAL',
    Comments = 'COMMENTS',
    User = 'USER',
    LastLogin = 'LAST_LOGIN',
    Email = 'EMAIL',
}
