import { Action, createReducer, on } from '@ngrx/store';

import {
    Activity,
    Attribute,
    AttributeClass,
    Dictionary,
    Project,
    Session,
    TeamMember,
    TeamMemberSnackBarsVisibilityOption,
} from '@accenture/shared/data';

import {
    clearProjectStore,
    clearProjectStoreOfSessionSubscriptions,
    clearSessionTeamMember,
    getParentTeamMemberSuccess,
    getProjectAndSessionsAndAttributesSuccess,
    getSessionActivitiesSuccess,
    getSessionSuccess,
    getSessionTeamMemberSnackBarsVisibilitySuccess,
    getSessionTeamMemberSuccess,
} from './project.actions';

export const PROJECT_FEATURE_KEY = 'project';

export interface ProjectState {
    project: Project;
    sessions: Session[];
    session: Session;
    sessionActivities: Activity[];
    attributes: Attribute[];
    attributeClasses: AttributeClass[];
    sessionTeamMember: TeamMember;
    parentTeamMember: TeamMember;
    sessionTeamMemberSnackBarsVisibility: Dictionary<TeamMemberSnackBarsVisibilityOption>;
}

const initialState: ProjectState = {
    project: {} as Project,
    sessions: [],
    session: {} as Session,
    sessionActivities: [],
    attributes: [],
    attributeClasses: [],
    sessionTeamMember: {} as TeamMember,
    parentTeamMember: {} as TeamMember,
    sessionTeamMemberSnackBarsVisibility: {} as Dictionary<TeamMemberSnackBarsVisibilityOption>,
};

const _projectReducer = createReducer(
    initialState,

    on(getProjectAndSessionsAndAttributesSuccess, (state, { project, sessions, attributes, attributeClasses }) => ({
        ...state,
        project,
        sessions,
        attributes,
        attributeClasses,
    })),

    on(getSessionSuccess, (state, { session }) => ({
        ...state,
        session,
    })),

    on(getParentTeamMemberSuccess, (state, { teamMember }) => ({
        ...state,
        parentTeamMember: teamMember,
    })),

    on(getSessionTeamMemberSuccess, (state, { teamMember }) => ({
        ...state,
        sessionTeamMember: teamMember,
    })),

    on(getSessionTeamMemberSnackBarsVisibilitySuccess, (state, { teamMemberSnackBarsVisibility }) => ({
        ...state,
        sessionTeamMemberSnackBarsVisibility: teamMemberSnackBarsVisibility,
    })),

    on(getSessionActivitiesSuccess, (state, { sessionActivities }) => ({
        ...state,
        sessionActivities,
    })),

    on(clearProjectStore, () => ({
        ...initialState,
    })),

    on(clearSessionTeamMember, state => ({
        ...state,
        sessionTeamMember: {} as TeamMember,
    })),
    on(clearProjectStoreOfSessionSubscriptions, state => ({
        ...state,
        session: {} as Session,
        sessionActivities: [],
        sessionTeamMember: {} as TeamMember,
        sessionTeamMemberSnackBarsVisibility: {} as Dictionary<TeamMemberSnackBarsVisibilityOption>,
    })),
);

export function projectReducer(state: any, action: Action) {
    return _projectReducer(state, action);
}
