import { ActivityItem } from './activity-item';
import { ActivityItemType } from './activity-item-type';
import { Prompt } from './prompt';

export enum FeedActions {
    Create = 'CREATE',
    Append = 'APPEND',
    OverWrite = 'OVERWRITE',
}

export class SparkPrompt extends Prompt implements ActivityItem {
    type = ActivityItemType.SparkPrompt;
    feedTargetActivityTypes?: string[];
    feedTargetActions?: string[];

    constructor(data: Partial<SparkPrompt>) {
        super(data);
        this.id = data.id ?? '';
        this.title = data.title ?? '';
        this.sequence = data.sequence ?? '';
        this.sessionId = data.sessionId ?? '';
        this.activityId = data.activityId ?? '';
        this.default = data.default ?? '';
        this.feedTargetActivityTypes = data.feedTargetActivityTypes;
        this.feedTargetActions = data.feedTargetActions;
    }

    createSerializableObject(): Record<string, unknown> {
        return {
            id: this.id,
            title: this.title,
            sequence: this.sequence,
            type: this.type,
            sessionId: this.sessionId,
            activityId: this.activityId,
            default: this.default,
            feedTargetActivityTypes: this.feedTargetActivityTypes,
            feedTargetActions: this.feedTargetActions,
            created: this.created,
            updated: this.updated,
        };
    }
}
