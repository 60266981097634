import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';

@Component({
    selector: 'accenture-activity-focus-notification',
    templateUrl: './activity-focus-notification.component.html',
    styleUrls: ['./activity-focus-notification.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActivityFocusNotificationComponent {
    @Output() navigateToFocusedActivity = new EventEmitter<void>();
}
