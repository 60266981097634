import { Injectable } from '@angular/core';
import firebase from 'firebase/compat/app';
import { Timestamp } from 'firebase/firestore';
import { firstValueFrom, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Activity, DBPathHelper, ParentType, Session, UserTemplate, UserTemplateType } from '@accenture/shared/data';
import { FirestoreService } from '@accenture/shared/data-access';
import WhereFilterOp = firebase.firestore.WhereFilterOp;

@Injectable({
    providedIn: 'root',
})
export class UserTemplateService {
    constructor(private firestoreService: FirestoreService) {}

    private static orderByUpdatedDesc(a: UserTemplate, b: UserTemplate): number {
        const aUpdated = a.updated as Timestamp;
        const bUpdated = b.updated as Timestamp;
        return bUpdated?.toMillis() - aUpdated?.toMillis();
    }

    private getAssignments(
        userId: string,
        orderByField: string,
        userTemplateType?: UserTemplateType,
    ): Observable<UserTemplate[]> {
        const query = new Map([['userId', userId]]);
        if (userTemplateType) {
            query.set('userTemplateType', userTemplateType);
        }
        return this.firestoreService
            .getDocumentsByMultipleProperties('userTemplates', query, orderByField)
            .pipe(map((assignments: UserTemplate[]) => assignments.sort(UserTemplateService.orderByUpdatedDesc)));
    }

    getTemplatesAssignmentsByUserId(
        userId: string,
        userTemplateType: UserTemplateType = UserTemplateType.SessionTemplates,
    ): Observable<UserTemplate[]> {
        return this.getAssignments(userId, 'templateId', userTemplateType);
    }

    getAllUserTemplatesByUserId(userId: string): Observable<UserTemplate[]> {
        return this.firestoreService
            .getDocumentsByProperty<UserTemplate>(DBPathHelper.getUserTemplatesPath(), 'userId', userId)
            .pipe(
                map((userTemplates) =>
                    (userTemplates || []).filter((userTemplates) => !userTemplates?.markedForDelete),
                ),
            );
    }

    getTemplatesByUserIdNew(userId: string): Observable<UserTemplate[]> {
        const templatesTypes = [ParentType.Templates, ParentType.ActivityTemplates] as string[];
        const queries = [
            ['templateType', 'in', templatesTypes],
            ['userId', '==', userId],
        ] as [property: string, operatorsValue: WhereFilterOp, value: any][];

        return this.firestoreService
            .getDocumentsByCombinedProperty('userTemplates', queries)
            .pipe(
                map((assignments: UserTemplate[]) =>
                    assignments.filter((assignment) => !assignment.public).sort(UserTemplateService.orderByUpdatedDesc),
                ),
            );
    }

    async updateUserTemplatesByActivityTemplateId(templateId: string, activity: Partial<Activity>): Promise<void> {
        const userTemplates = await firstValueFrom(
            this.firestoreService.getDocumentsByMultipleProperties<UserTemplate>(
                DBPathHelper.getUserTemplatesPath(),
                new Map([['templateId', templateId]]),
                'templateId',
            ),
        );

        if (userTemplates.length > 0) {
            const template = userTemplates[0];
            await this.firestoreService.update(
                DBPathHelper.getUserTemplatesPath(template.id),
                this.firestoreService.replaceEmptyFields({
                    ...activity,
                    updated: this.firestoreService.timestamp,
                }),
            );
        }
    }

    async updateUserTemplatesBySessionTemplateId(templateId: string, session: Partial<Session>): Promise<void> {
        const userTemplates = await firstValueFrom(
            this.firestoreService.getDocumentsByMultipleProperties<UserTemplate>(
                DBPathHelper.getUserTemplatesPath(),
                new Map([['templateId', templateId]]),
                'templateId',
            ),
        );

        if (userTemplates.length > 0) {
            const template = userTemplates[0];
            await this.firestoreService.update(
                DBPathHelper.getUserTemplatesPath(template.id),
                this.firestoreService.replaceEmptyFields({
                    ...session,
                }),
            );
        }
    }
}
