<ng-container *ngIf="vm$ | async as vm">
  <div class="session-filters" [formGroup]="filtersAndSortForm">
    <div *accentureLoaded="!vm.isLoading" class="session-filters-container d-flex-column">
      <h6 class="tt9-body-medium-1">Filter</h6>

      <!-- filter by client (new) instead of role-->
      <div>
        <mat-form-field
          appearance="outline"
          class="tt9-form-field width-100"
          floatLabel="always"
          [ngClass]="{
            'mat-form-field-invalid': clientsInput.value && !vm.filteredClients.length
          }"
        >
          <mat-label>Client</mat-label>
          <input
            #clientsInput
            matInput
            type="text"
            placeholder="Choose client"
            [matAutocomplete]="clients"
            (input)="updateClientOptions($event.target?.value)"
          />
          <mat-hint *ngIf="clientsInput.value && !vm.filteredClients.length">{{
            notFoundOptionsText.clients
          }}</mat-hint>
          <accenture-icon matSuffix (click)="openFilterAndSortPanel($event, sessionOptions.SessionClient)"
            >icon-label-outline</accenture-icon
          >

          <mat-autocomplete #clients="matAutocomplete">
            <mat-option *ngFor="let option of vm.filteredClients; trackBy: trackById" (click)="setClient(option)">
              {{ option.name }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>

        <mat-chip-listbox hideSingleSelectionIndicator class="tt9-chip">
          <mat-chip-option *ngFor="let clientId of filtersAndSortForm.get('clientNames')?.value">
            <span>{{ vm.clientMap[clientId] | uppercase }}</span>
            <accenture-icon matChipRemove (click)="removeSelectedClient(clientId)">icon-close</accenture-icon>
          </mat-chip-option>
        </mat-chip-listbox>
      </div>
      <!-- filter by client (new) instead of role (old) -->

      <!-- filter by project name (new) instead of status (old) -->
      <div>
        <mat-form-field
          appearance="outline"
          class="tt9-form-field width-100"
          floatLabel="always"
          [ngClass]="{
            'mat-form-field-invalid': projectsInput.value && !vm.filteredProjects.length
          }"
        >
          <mat-label>Project name</mat-label>
          <input
            #projectsInput
            matInput
            type="text"
            placeholder="Choose project name"
            [matAutocomplete]="projects"
            (input)="updateProjectOptions($event.target?.value)"
          />
          <mat-hint *ngIf="projectsInput.value && !vm.filteredProjects.length">{{
            notFoundOptionsText.clients
          }}</mat-hint>
          <accenture-icon matSuffix (click)="openFilterAndSortPanel($event, sessionOptions.SessionProject)"
            >icon-label-outline</accenture-icon
          >

          <mat-autocomplete #projects="matAutocomplete">
            <mat-option *ngFor="let option of vm.filteredProjects; trackBy: trackById" (click)="setProject(option)">
              {{ option.name }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>

        <mat-chip-listbox hideSingleSelectionIndicator class="tt9-chip">
          <mat-chip-option *ngFor="let projectId of filtersAndSortForm.get('projectNames')?.value">
            <span>{{ vm.projectMap[projectId] | uppercase }}</span>
            <accenture-icon matChipRemove (click)="removeSelectedProject(projectId)">icon-close</accenture-icon>
          </mat-chip-option>
        </mat-chip-listbox>
      </div>
      <!-- filter by project name (new) instead of status (old) -->
      <!-- filter by Phase -->
      <div>
        <mat-form-field
          appearance="outline"
          class="tt9-form-field width-100"
          floatLabel="always"
          [ngClass]="{
            'mat-form-field-invalid': phasesInput.value && !vm.filteredPhases.length
          }"
        >
          <mat-label>Phases</mat-label>
          <input
            #phasesInput
            matInput
            type="text"
            placeholder="Choose phase"
            [matAutocomplete]="phase"
            (input)="updatePhaseOptions($event.target?.value)"
          />
          <mat-hint *ngIf="phasesInput.value && !vm.filteredPhases.length">{{ notFoundOptionsText.phases }}</mat-hint>
          <accenture-icon matSuffix (click)="openFilterAndSortPanel($event, sessionOptions.Phase)"
            >icon-label-outline</accenture-icon
          >

          <mat-autocomplete #phase="matAutocomplete">
            <mat-option *ngFor="let option of vm.filteredPhases; trackBy: trackById" (click)="setPhase(option)">
              {{ option.name }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>

        <mat-chip-listbox hideSingleSelectionIndicator class="tt9-chip">
          <mat-chip-option *ngFor="let phasesId of filtersAndSortForm.get('phases')?.value">
            <span>{{ vm.phasesMap[phasesId] | uppercase }}</span>
            <accenture-icon matChipRemove (click)="removeSelectedPhase(phasesId)">icon-close</accenture-icon>
          </mat-chip-option>
        </mat-chip-listbox>
      </div>
      <!-- filter by Phase -->

      <!-- filter by Sub-Phase -->
      <div>
        <mat-form-field
          appearance="outline"
          class="tt9-form-field width-100"
          floatLabel="always"
          [ngClass]="{
            'mat-form-field-invalid': subPhasesInput.value && !vm.filteredSubPhases.length
          }"
        >
          <mat-label>Sub-phases</mat-label>
          <input
            #subPhasesInput
            type="text"
            matInput
            placeholder="Choose sub-phase"
            [matAutocomplete]="subPhase"
            (input)="updateSubPhaseOptions($event.target?.value)"
          />
          <mat-hint *ngIf="subPhasesInput.value && !vm.filteredSubPhases.length">{{
            notFoundOptionsText.subPhases
          }}</mat-hint>
          <accenture-icon matSuffix (click)="openFilterAndSortPanel($event, sessionOptions.SubPhase)">
            icon-label-outline
          </accenture-icon>

          <mat-autocomplete #subPhase="matAutocomplete">
            <mat-option *ngFor="let option of vm.filteredSubPhases; trackBy: trackById" (click)="setSubPhase(option)">
              {{ option.name }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>

        <mat-chip-listbox hideSingleSelectionIndicator class="tt9-chip">
          <mat-chip-option *ngFor="let subPhasesId of filtersAndSortForm.get('subPhases')?.value">
            <span>{{ vm.subPhasesMap[subPhasesId] | uppercase }}</span>
            <accenture-icon matChipRemove (click)="removeSelectedSubPhase(subPhasesId)">icon-close</accenture-icon>
          </mat-chip-option>
        </mat-chip-listbox>
      </div>
      <!-- filter by Sub-Phase -->

      <!-- filter by tag -->
      <div>
        <mat-form-field
          appearance="outline"
          class="tt9-form-field width-100"
          floatLabel="always"
          [ngClass]="{
            'mat-form-field-invalid': tagsInput.value && !vm.filteredTags.length
          }"
        >
          <mat-label>Tags</mat-label>
          <input
            #tagsInput
            type="text"
            matInput
            placeholder="Choose tags"
            [matAutocomplete]="tags"
            (input)="updateTagOptions($event.target?.value)"
          />
          <mat-hint *ngIf="tagsInput.value && !vm.filteredTags.length">{{ notFoundOptionsText.tags }}</mat-hint>
          <accenture-icon matSuffix (click)="openFilterAndSortPanel($event, sessionOptions.Tag)">
            icon-label-outline
          </accenture-icon>

          <mat-autocomplete #tags="matAutocomplete">
            <mat-option *ngFor="let option of vm.filteredTags; trackBy: trackById" (click)="setTag(option)">
              {{ option.name }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>

        <mat-chip-listbox hideSingleSelectionIndicator class="tt9-chip">
          <mat-chip-option *ngFor="let tagId of filtersAndSortForm.get('tags')?.value">
            <span>{{ vm.tagsMap[tagId] | uppercase }}</span>
            <accenture-icon matChipRemove (click)="removeSelectedTag(tagId)">icon-close</accenture-icon>
          </mat-chip-option>
        </mat-chip-listbox>
      </div>
      <!-- filter by tag -->

      <div class="session-filters-dates">
        <!-- filter by date start -->
        <mat-form-field appearance="outline" class="tt9-form-field date-form-field custom-width" floatLabel="always">
          <mat-label>Start date</mat-label>
          <input
            matInput
            readonly
            formControlName="startDate"
            [placeholder]="inputPlaceholders.chooseDateSecondary"
            [matDatepicker]="datePickerStart"
            (click)="datePickerStart.open()"
          />
          <mat-datepicker-toggle matSuffix [for]="datePickerStart">
            <accenture-icon matDatepickerToggleIcon> icon-calendar </accenture-icon>
          </mat-datepicker-toggle>
          <mat-datepicker #datePickerStart></mat-datepicker>
          <accenture-icon
            matSuffix
            *ngIf="filtersAndSortForm.controls.startDate.value"
            class="mat-select-icon date-reset"
            (click)="clearDateInput('startDate', $event)"
          >
            icon-close
          </accenture-icon>
        </mat-form-field>
        <!-- filter by date start -->

        <!-- filter by date end -->
        <mat-form-field appearance="outline" class="tt9-form-field date-form-field custom-width" floatLabel="always">
          <mat-label>End date</mat-label>
          <input
            matInput
            readonly
            formControlName="endDate"
            [placeholder]="inputPlaceholders.chooseDateSecondary"
            [matDatepicker]="datePickerEnd"
            (click)="datePickerEnd.open()"
          />
          <mat-datepicker-toggle matSuffix [for]="datePickerEnd">
            <accenture-icon matDatepickerToggleIcon> icon-calendar </accenture-icon>
          </mat-datepicker-toggle>
          <mat-datepicker #datePickerEnd></mat-datepicker>
          <accenture-icon
            matSuffix
            *ngIf="filtersAndSortForm.controls.endDate.value"
            class="mat-select-icon date-reset"
            (click)="clearDateInput('endDate', $event)"
          >
            icon-close
          </accenture-icon>
          <mat-error *ngIf="isEndDateGreaterStartDateErrorVisibility">{{
            validationMessages.endDateGreaterStartDate
          }}</mat-error>
        </mat-form-field>
      </div>
      <!-- filter by date end -->

      <div class="session-filters-dates">
        <!-- filter by date from -->
        <mat-form-field appearance="outline" class="tt9-form-field date-form-field custom-width" floatLabel="always">
          <mat-label>Date created from</mat-label>
          <input
            matInput
            readonly
            formControlName="fromDate"
            [placeholder]="inputPlaceholders.chooseDateSecondary"
            [matDatepicker]="datePickerFrom"
            (click)="datePickerFrom.open()"
          />
          <mat-datepicker-toggle matSuffix [for]="datePickerFrom">
            <accenture-icon matDatepickerToggleIcon> icon-calendar </accenture-icon>
          </mat-datepicker-toggle>
          <mat-datepicker #datePickerFrom></mat-datepicker>
          <accenture-icon
            matSuffix
            *ngIf="filtersAndSortForm.controls.fromDate.value"
            class="mat-select-icon date-reset"
            (click)="clearDateInput('fromDate', $event)"
          >
            icon-close
          </accenture-icon>
        </mat-form-field>
        <!-- filter by date from -->

        <!-- filter by date to -->
        <mat-form-field appearance="outline" class="tt9-form-field date-form-field custom-width" floatLabel="always">
          <mat-label>Date created to</mat-label>
          <input
            matInput
            readonly
            formControlName="toDate"
            [placeholder]="inputPlaceholders.chooseDateSecondary"
            [matDatepicker]="datePickerTo"
            (click)="datePickerTo.open()"
          />
          <mat-datepicker-toggle matSuffix [for]="datePickerTo">
            <accenture-icon matDatepickerToggleIcon> icon-calendar </accenture-icon>
          </mat-datepicker-toggle>
          <mat-datepicker #datePickerTo></mat-datepicker>
          <accenture-icon
            matSuffix
            *ngIf="filtersAndSortForm.controls.toDate.value"
            class="mat-select-icon date-reset"
            (click)="clearDateInput('toDate', $event)"
          >
            icon-close
          </accenture-icon>
          <mat-error *ngIf="isToDateGrFromDateErrorVisibility"
            >'Date Created To' must be equal or greater than 'Date Created From'.</mat-error
          >
        </mat-form-field>
        <!-- filter by date to -->
      </div>

      <h6 class="tt9-body-medium-1">Sort</h6>

      <!-- sorting by -->
      <div>
        <mat-form-field class="tt9-form-field width-100" appearance="outline" floatLabel="always">
          <mat-label> Sort by </mat-label>
          <mat-select
            formControlName="sortOption"
            placeholder="Sort by"
            [disableOptionCentering]="true"
            [panelClass]="['tt9-select-panel-custom']"
          >
            <ng-container *ngFor="let sortOption of vm.sortOptionsValues | keyvalue">
              <mat-option [value]="sortOption.key">
                {{ sortOption.value }}
              </mat-option>
            </ng-container>
          </mat-select>
        </mat-form-field>
      </div>
      <!-- sorting by -->

      <!-- sorting order -->
      <div>
        <mat-form-field class="tt9-form-field width-100" appearance="outline" floatLabel="always">
          <mat-label> Sort order </mat-label>
          <mat-select
            formControlName="sortOrder"
            placeholder="Sort order"
            [disableOptionCentering]="true"
            [panelClass]="['tt9-select-panel-custom']"
          >
            <ng-container *ngFor="let sortOrder of sortOrdersValues | keyvalue">
              <mat-option [value]="sortOrder.key">
                {{ sortOrder.value }}
              </mat-option>
            </ng-container>
          </mat-select>
        </mat-form-field>
      </div>
      <!-- sorting order -->
    </div>

    <!-- reset form controls values link -->
    <div class="session-filters-footer">
      <button class="tt9-neutral-btn" [disabled]="resetAllLinkDisabled" (click)="resetFiltersAndSortForm()">
        Reset All
      </button>
    </div>
    <!-- reset form controls values link -->
  </div>

  <accenture-side-panel
    *ngIf="vm.isAllCollectionsPanelOpened"
    cdkOverlayOrigin
    #origin="cdkOverlayOrigin"
    [panelTrigger]="origin"
    [panelTitle]="optionPanelTitle"
    [panelOpened]="vm.isAllCollectionsPanelOpened"
    [showBackArrow]="true"
    (closePanel)="toggleOptionsPanel(false)"
    (navigateBack)="navigateToFilterPanel()"
  >
    <accenture-default-sessions-options-filters-chips
      (click)="$event.stopPropagation()"
    ></accenture-default-sessions-options-filters-chips>
  </accenture-side-panel>
</ng-container>
