import { Injectable } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { isEqual } from 'lodash';
import { BehaviorSubject, combineLatest, firstValueFrom, Observable } from 'rxjs';
import { distinctUntilChanged, filter, map, startWith, switchMap, take } from 'rxjs/operators';

import {
    CollectionsService,
    LinkAccessService,
    OptionsStore,
    SessionOptionsService,
    SessionService,
    TeamMemberService,
    UserAccessService,
} from '@accenture/erp-deployment/shared/domain';
import {
    AppState,
    getParentTeamMemberData,
    getSessionActivitiesData,
    getSessionData,
    selectActivityIdAndParentIds,
    selectAuthenticatedUser,
    selectAuthenticatedUserId,
    selectSessionData,
} from '@accenture/global-store';
import {
    CollectionOptions,
    CollectionRole,
    CollectionSession,
    creatingSessionErrorSnackbarTitle,
    errorSnackbarText,
    FileType,
    ParentType,
    ProjectOptions,
    routerLinksMap,
    SelectedSessionOptions,
    Session,
    SessionFocusSteps,
    SessionOptions,
    sessionOptionsArray,
    SessionRole,
    SessionStatus,
    User,
} from '@accenture/shared/data';
import { DialogService, LoadedDescription, SnackbarService } from '@accenture/shared/ui';
import { removeEmptyKeys, SequenceGeneration } from '@accenture/shared/util';

import { SelectSessionSourceTypeDialogComponent } from '../select-session-source-type-dialog/select-session-source-type-dialog.component';
import { EditSessionDialogNewComponent } from './edit-session-dialog-new.component';

export interface EditSessionDialogNewViewModel {
    session: Session;
    sessionImage: FileType;
    loaderDescription: string;
    sessionHasUpdates: boolean;
    updateSessionEvent: boolean;
    currentOptionToDisplay: SessionOptions | CollectionOptions | ProjectOptions | null;
    isLoading: boolean;
}

const defaultCreateSessionNewModel: EditSessionDialogNewViewModel = {
    session: {} as Session,
    sessionImage: {} as FileType,
    loaderDescription: '',
    sessionHasUpdates: false,
    updateSessionEvent: true,
    currentOptionToDisplay: null,
    isLoading: false,
};

@Injectable()
export class EditSessionDialogNewFacade {
    private isLoading$ = new BehaviorSubject<boolean>(false);
    private sessionImage$ = new BehaviorSubject<FileType>(undefined);
    private sessionHasUpdates$ = new BehaviorSubject<boolean>(false);
    private updateSessionEvent$ = new BehaviorSubject<boolean>(true);

    vm$ = this.buildViewModel();

    private parentId!: string;
    private parentType!: ParentType;
    private collectionId!: string;
    private user!: User;
    private userId!: string;
    private savedSessionOptions!: SelectedSessionOptions;
    private hasSelectedOptions = false;

    constructor(
        private store: Store<AppState>,
        private sessionService: SessionService,
        private teamMemberService: TeamMemberService,
        private userAccessService: UserAccessService,
        private router: Router,
        private dialogRef: MatDialogRef<EditSessionDialogNewComponent>,
        private dialogService: DialogService,
        private optionsStore: OptionsStore,
        private sessionOptionsService: SessionOptionsService,
        private collectionsService: CollectionsService,
        private linkAccessService: LinkAccessService,
        private snackbarService: SnackbarService,
    ) {}

    setSessionImage(sessionImage: FileType): void {
        this.sessionImage$.next(sessionImage);
    }

    updateSessionForm(): void {
        this.hasSelectedOptions = false;
        this.updateSessionEvent$.next(true);
        this.sessionHasUpdates$.next(false);
        this.sessionImage$.next(undefined);
        this.isLoading$.next(true);
    }

    sessionWasUpdated(): void {
        setTimeout(() => this.updateSessionEvent$.next(false));
        setTimeout(() => this.isLoading$.next(false));
    }

    createOrUpdateSession(session: Partial<Session>, startDate?: Date, endDate?: Date): void {
        this.parentId
            ? this.updateSession(session, startDate, endDate)
            : this.createSessionEntry(session, startDate, endDate);
    }

    closeDialog(backToSelectSourceTypeDialog?: boolean): void {
        this.dialogRef.close();

        if (backToSelectSourceTypeDialog) {
            this.dialogService.open(SelectSessionSourceTypeDialogComponent, {
                width: '768px',
                panelClass: 'tt9-modal',
            });
        }
    }

    backToSessionDialog(): void {
        this.optionsStore.setCurrentOptionToDisplay(null);
    }

    resetOptions(): void {
        this.optionsStore.resetSessionOptions();
        this.optionsStore.resetSessionOptionsToCreate();
        this.optionsStore.setCurrentOptionToDisplay(null);
    }

    private buildViewModel(): Observable<EditSessionDialogNewViewModel> {
        return combineLatest([
            this.store.pipe(select(selectActivityIdAndParentIds)),
            this.store.select(selectAuthenticatedUserId),
            this.store.select(selectAuthenticatedUser).pipe(filter((user) => !!user)),
            this.sessionImage$.asObservable().pipe(distinctUntilChanged()),
            this.sessionHasUpdates$.asObservable().pipe(distinctUntilChanged()),
            this.updateSessionEvent$.asObservable().pipe(distinctUntilChanged()),
            this.optionsStore.currentOptionToDisplay$.pipe(distinctUntilChanged()),
            this.isLoading$.asObservable(),
        ]).pipe(
            switchMap(
                ([
                    { parentType, parentId, collectionId },
                    userId,
                    user,
                    sessionImage,
                    sessionHasUpdates,
                    updateSessionEvent,
                    currentOptionToDisplay,
                    isLoading,
                ]) => {
                    this.userId = userId;
                    this.user = user;
                    this.parentId = parentId;
                    this.collectionId = collectionId;
                    this.parentType = parentType;

                    return this.store.select(selectSessionData).pipe(
                        distinctUntilChanged((previousPayload: Session, currentPayload: Session) => {
                            if (isEqual(previousPayload, currentPayload)) {
                                return true;
                            }
                            this.sessionHasUpdates$.next(true);
                            return false;
                        }),
                        map((session) => {
                            const oldSessionImage = {
                                url: session?.imageUrl,
                                id: session?.imageId,
                            } as FileType;

                            if (!this.hasSelectedOptions) {
                                const options = {
                                    phase: session?.phase || {},
                                    subPhase: session?.subPhase || {},
                                    tags: session?.tags || {},
                                    client: session?.client || {},
                                    project: session?.project || {},
                                };
                                this.optionsStore.setSelectedSessionOptions(options);
                                this.savedSessionOptions = options;

                                this.hasSelectedOptions = true;
                            }

                            return {
                                session,
                                isLoading,
                                sessionHasUpdates,
                                updateSessionEvent,
                                currentOptionToDisplay,
                                sessionImage: sessionImage || oldSessionImage,
                                loaderDescription: LoadedDescription.SessionCreating.toUpperCase(),
                            };
                        }),
                    );
                },
            ),
            startWith(defaultCreateSessionNewModel),
        );
    }

    // TODO: remove this one once project is deprecated
    private async createSession(formValue: {
        name: string;
        description?: string;
        startDate?: Date;
        endDate?: Date;
    }): Promise<void> {
        this.isLoading$.next(true);

        const sessionImage = this.sessionImage$.value;
        const sessions = await firstValueFrom(
            this.userAccessService.getSessionsAssignmentsByUserId(this.userId).pipe(take(1)),
        );
        const sessionOptions = await firstValueFrom(this.optionsStore.selectedSessionOptions$.pipe(take(1)));
        const lastSessionSequence = !!sessions[sessions.length - 1] ? sessions[sessions.length - 1].sequence : null;
        const sequence = lastSessionSequence
            ? SequenceGeneration.afterLast(lastSessionSequence)
            : SequenceGeneration.initial();

        const teamMembersCount = 1;

        const session = new Session('', {
            sequence,
            teamMembersCount,
            name: formValue.name,
            description: formValue.description || '',
            imageUrl: sessionImage?.url || '',
            imageId: sessionImage?.id || '',
            status: SessionStatus.Draft,
            creatorId: this.userId,
            creatorName: this.user.displayName,
            creatorImage: this.user.imageUrl,
            phase: sessionOptions?.phase || {},
            subPhase: sessionOptions?.subPhase || {},
            tags: sessionOptions?.tags || {},
            access: {
                [this.userId as string]: {
                    role: SessionRole.Leader,
                    displayName: this.user.displayName,
                },
            },
        });

        const sessionId = await this.sessionService.createSessionNew(
            ParentType.Sessions,
            session,
            formValue.startDate,
            formValue.endDate,
        );

        await this.teamMemberService.addTeamMemberToSessionNew(
            ParentType.Sessions,
            sessionId,
            this.user,
            SessionRole.Leader,
        );
        await this.sessionService.createSessionUserAccess(session, sessionId, this.userId);
        await this.updateOptions(sessionOptions, sessionId);

        if (!!this.collectionId) {
            const collection = await firstValueFrom(this.collectionsService.getCollectionById(this.collectionId));
            const collectionSessionDoc = new CollectionSession(
                removeEmptyKeys({
                    sessionId,
                    imageUrl: sessionImage?.url || '',
                    imageId: sessionImage?.id || '',
                    userId: this.userId,
                    collectionId: this.collectionId,
                    collectionName: collection.name,
                    role: CollectionRole.Owner,
                    sessionName: formValue.name,
                    sessionDescription: formValue.description || '',
                    tags: sessionOptions?.tags || {},
                }),
            );

            await this.collectionsService.addSessionToCollection(collectionSessionDoc);
            this.isLoading$.next(false);
            return this.closeDialog();
        }

        this.isLoading$.next(false);

        const parentRoute = routerLinksMap[ParentType.Sessions];

        this.redirectToSession(sessionId, parentRoute);
        this.closeDialog();
    }

    private async createSessionEntry(session: Partial<Session>, startDate?: Date, endDate?: Date): Promise<void> {
        this.isLoading$.next(true);

        const sessionOptions = await firstValueFrom(this.optionsStore.selectedSessionOptions$.pipe(take(1)));
        const sessionImage = this.sessionImage$.value;

        const sessionData = new Session('', {
            name: session.name,
            description: session.description || '',
            imageUrl: session?.imageUrl || sessionImage?.url || '',
            imageId: session?.imageId || sessionImage?.id || '',
            status: SessionStatus.Draft,
            creatorId: this.userId,
            creatorName: this.user.displayName,
            creatorImage: this.user.imageUrl,
            phase: sessionOptions?.phase || {},
            client: sessionOptions?.client || {},
            project: sessionOptions?.project || {},
            subPhase: sessionOptions?.subPhase || {},
            tags: sessionOptions?.tags || {},
            access: {
                [this.userId as string]: {
                    role: SessionRole.Leader,
                    displayName: this.user.displayName,
                },
            },
        });

        try {
            const sessionId = await this.sessionService.createSessionEntry(sessionData, startDate, endDate);

            await this.teamMemberService.addTeamMemberToSessionNew(
                ParentType.Sessions,
                sessionId,
                this.user,
                SessionRole.Leader,
            );

            await this.updateOptions(sessionOptions, sessionId);
            this.closeDialog();

            this.redirectToSession(sessionId);
            return;
        } catch (e) {
            this.snackbarService.showErrorSnackBar(creatingSessionErrorSnackbarTitle, errorSnackbarText);
            console.error(e);
        } finally {
            this.isLoading$.next(false);
        }
    }

    redirectToSession(sessionId: string, parentRoute?: string): void {
        const parentType = this.parentType;

        this.store.dispatch(
            getSessionData({
                parentType,
                sessionId,
            }),
        );

        this.store.dispatch(
            getParentTeamMemberData({
                parentType,
                parentId: sessionId,
            }),
        );

        this.store.dispatch(
            getSessionActivitiesData({
                parentType,
                sessionId,
            }),
        );

        parentRoute
            ? this.router.navigate([parentRoute, sessionId])
            : this.router.navigate(['/dashboard', ParentType.Sessions, sessionId]);
    }

    private async updateSession(session: Partial<Session>, startDate?: Date, endDate?: Date): Promise<void> {
        this.isLoading$.next(true);
        const sessionOptions = await firstValueFrom(this.optionsStore.selectedSessionOptions$.pipe(take(1)));

        const sessionData = {
            name: session.name,
            description: session.description,
            imageUrl: session.imageUrl,
            imageId: session.imageId,
            startDate: session.startDate,
            endDate: session.endDate,
            phase: sessionOptions?.phase || {},
            subPhase: sessionOptions?.subPhase || {},
            client: sessionOptions?.client || {},
            project: sessionOptions?.project || {},
            tags: sessionOptions?.tags || {},
        } as Partial<Session>;

        const sessionImage = this.sessionImage$.getValue();
        if (sessionImage) {
            sessionData.imageUrl = sessionImage?.url || null;
            sessionData.imageId = sessionImage?.id || null;
        }

        await this.sessionService.updateSessionDocumentNew(
            this.parentType,
            this.parentId,
            sessionData,
            startDate,
            endDate,
        );

        await this.linkAccessService.updateLinkAccessSessionName(this.parentId, sessionData.name);
        await this.updateOptions(sessionOptions, this.parentId);

        this.isLoading$.next(false);
        this.closeDialog();
    }

    private async updateOptions(selectedSessionOptions: SelectedSessionOptions, sessionId: string): Promise<void> {
        const optionsToCreateIds = await firstValueFrom(this.optionsStore.sessionOptionsToCreateIds$.pipe(take(1)));

        const optionsToRemove = sessionOptionsArray.reduce((acc, optionType) => {
            acc[optionType] = Object.keys(this.savedSessionOptions?.[optionType] || {}).filter(
                (id) => !Object.keys(selectedSessionOptions[optionType] || {}).includes(id),
            );

            return acc;
        }, {});

        await this.sessionOptionsService.updateOptions(
            selectedSessionOptions,
            sessionId,
            optionsToRemove,
            optionsToCreateIds,
        );
    }
}
