import { Timestamp } from 'firebase/firestore';

import { CollectionSortOptions } from '../default-filters-and-sort';
import { SortOptions } from '../sort-options';
import { SortOrders } from '../sort-orders';

export interface CollectionFilters {
    clients?: string[];
    fromDate?: Timestamp;
    toDate?: Timestamp;
    startDate?: Timestamp;
    endDate?: Timestamp;
    sortOption?: SortOptions;
    sortOrder?: SortOrders;
    collectionsSortOption?: CollectionSortOptions;
    collectionsSortOrder?: SortOrders;
}

export const initialCollectionFilters: CollectionFilters = {
    clients: [],
    fromDate: null,
    toDate: null,
    startDate: null,
    endDate: null,
    sortOption: SortOptions.Updated,
    sortOrder: SortOrders.Dsc,
    collectionsSortOption: CollectionSortOptions.Name,
    collectionsSortOrder: SortOrders.Asc,
};
