import { Timestamp } from 'firebase/firestore';

import { removeEmptyKeys } from '@accenture/shared/util';

export enum SessionRecordingConsentResponse {
    Accepted = 'ACCEPTED',
    Declined = 'DECLINED',
    None = 'NONE',
    All = 'ALL',
}

export const SessionRecordingConsentResponseLookup = {
    [SessionRecordingConsentResponse.Accepted]: 'Accepted',
    [SessionRecordingConsentResponse.Declined]: 'Declined',
    [SessionRecordingConsentResponse.None]: 'No Response',
    [SessionRecordingConsentResponse.All]: 'All',
};
export class SessionRecordingConsent {
    id?: string;
    userId?: string;
    displayName: string;
    imageUrl?: string;
    email?: string;
    response: SessionRecordingConsentResponse;
    disclaimerId?: string;
    recordingId?: string;
    created: Timestamp;
    updated: Timestamp;

    constructor(message: Partial<SessionRecordingConsent>) {
        this.id = message.id ?? '';
        this.userId = message.userId ?? '';
        this.displayName = message.displayName ?? '';
        this.imageUrl = message.imageUrl ?? '';
        this.email = message.email ?? '';
        this.response = message.response ?? SessionRecordingConsentResponse.None;
        this.disclaimerId = message.disclaimerId ?? '';
        this.recordingId = message.recordingId ?? '';
        this.created = message.created ?? Timestamp.now();
        this.updated = message.updated ?? Timestamp.now();
    }

    createSerializableObject(): Record<string, unknown> {
        return removeEmptyKeys({
            userId: this.userId,
            displayName: this.displayName,
            imageUrl: this.imageUrl,
            email: this.email,
            response: this.response,
            recordingId: this.recordingId,
            disclaimerId: this.disclaimerId,
            created: this.created,
            updated: this.updated,
        });
    }
}
