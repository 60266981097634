import { ComponentType } from '@angular/cdk/overlay';
import { Directive, ElementRef, HostListener, Input, OnDestroy } from '@angular/core';

import { TooltipConfig, TooltipPosition } from '../../models/tooltip';
import { TooltipService } from '../../services/tooltip.service';
import { TooltipRef } from './tooltip-ref';

@Directive({ selector: '[accentureTooltip]' })
export class TooltipDirective implements OnDestroy {
    @Input('accentureTooltipTemplate') component!: ComponentType<any>;
    @Input('accentureTooltipPosition') position = TooltipPosition.Bottom;
    @Input('accentureTooltipTimerClose') tooltipTimerClose = 300;
    @Input('accentureTooltipDefaultBehavior') tooltipDefaultBehavior = false;
    @Input('accentureTooltipDisabled') tooltipDefaultDisabled = false;
    @Input('accentureTooltipData') data: any = {};
    @Input('accentureTooltipClass') class!: string;

    private tooltipRef!: TooltipRef;
    private tooltipTimer!: ReturnType<typeof setTimeout>;
    private closeDisabled = false;

    constructor(private elementRef: ElementRef, private tooltipService: TooltipService) {}

    @HostListener('mouseout')
    hide() {
        if (!this.tooltipRef || !this.component) {
            return;
        }

        if (!!this.tooltipDefaultBehavior) {
            return;
        }

        clearTimeout(this.tooltipTimer);
        this.closeDisabled = false;
        this.tooltipTimer = setTimeout(() => {
            if (!this.closeDisabled && this.tooltipRef) {
                this.closeTooltip();
            }
        }, this.tooltipTimerClose);
    }

    @HostListener('mouseleave')
    hideOnLeave() {
        if (!this.tooltipRef || !this.component) {
            return;
        }

        if (!!this.tooltipDefaultBehavior) {
            this.closeTooltip();
            return;
        }
    }

    @HostListener('mouseenter')
    show() {
        // setTimeout need for the matTooltip component to appear in the DOM first
        setTimeout(() => {
            this.setArrowDirection();
        }, 0);

        if (!!this.tooltipDefaultDisabled) {
            // need to close after selection (if the same tooltip)
            this.closeTooltip();
            return;
        }

        if (this.tooltipRef || !this.component) {
            if (!this.tooltipDefaultBehavior) {
                clearTimeout(this.tooltipTimer);
            }
            return;
        }
        const config = this.getTooltipConfig();

        // Default behavior for tooltips
        if (this.tooltipDefaultBehavior) {
            this.tooltipRef = this.tooltipService.open(this.elementRef, this.component, config);
            return;
        }

        // for Vote Question firstly
        this.tooltipRef = this.tooltipService.open(this.elementRef, this.component, config);
        this.tooltipRef.overlayRef.hostElement.addEventListener('mouseleave', this.closeTooltip.bind(this));
        this.tooltipRef.overlayRef.hostElement.addEventListener('mouseenter', () => (this.closeDisabled = true));
    }

    ngOnDestroy() {
        this.closeTooltip();
    }

    private setArrowDirection(): void {
        const hostPosition = this.elementRef.nativeElement.getBoundingClientRect();
        const matTooltipCollection = document.getElementsByTagName('mat-tooltip-component');
        const matTooltip = matTooltipCollection?.item(matTooltipCollection.length - 1);
        const matTooltipContentContainer = matTooltip?.firstChild as HTMLElement;

        const classList = matTooltipContentContainer?.classList;

        if (
            matTooltip?.parentElement?.offsetTop
            && Math.round(hostPosition.bottom) === matTooltip?.parentElement?.offsetTop
        ) {
            return;
        }

        if (
            matTooltip?.parentElement?.offsetLeft
            && Math.round(hostPosition.right) === matTooltip?.parentElement?.offsetLeft
        ) {
            return;
        }

        if (classList?.contains('tt9-arrow-tooltip-above')) {
            classList.replace('tt9-arrow-tooltip-above', 'tt9-arrow-tooltip-below');
        }
        if (classList?.contains('tt9-arrow-tooltip-above-left')) {
            classList.replace('tt9-arrow-tooltip-above-left', 'tt9-arrow-tooltip-below-left');
        }
        if (classList?.contains('tt9-arrow-tooltip-left')) {
            classList.replace('tt9-arrow-tooltip-left', 'tt9-arrow-tooltip-right');
        }
    }

    private closeTooltip(): void {
        this.closeDisabled = false;
        this.tooltipRef?.close();
        this.tooltipRef = null as any as TooltipRef;
    }

    private getTooltipConfig(): TooltipConfig {
        return {
            data: this.data,
            class: this.class || '',
            position: this.position,
        };
    }
}
