import { Action, createReducer, on } from '@ngrx/store';

import { CollectionsDisplayMode } from '@accenture/shared/data';

import { setDashboardSessionsDisplayMode, toggleSideNavigationDisplay } from './dashboard.actions';

export const DASHBOARD_SESSION_FEATURE_KEY = 'dashbboard_session';

export interface DashboardSessionState {
    displayMode: CollectionsDisplayMode;
    isSideNavigationDisplayed: boolean;
}

const initialState: DashboardSessionState = {
    displayMode: CollectionsDisplayMode.IconsView,
    // isSideNavigationDisplayed: true,
    // temporarily set to false
    isSideNavigationDisplayed: false,
};

const _dashboardSessionReducer = createReducer(
    initialState,

    on(setDashboardSessionsDisplayMode, (state, { displayMode }) => {
        return { ...state, displayMode };
    }),

    on(toggleSideNavigationDisplay, (state) => {
        return { ...state, isSideNavigationDisplayed: !state.isSideNavigationDisplayed };
    }),
);

export function dashboardSessionReducer(state: any, action: Action) {
    return _dashboardSessionReducer(state, action);
}
