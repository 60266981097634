import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { isUndefined } from 'lodash';
import { map, Observable, skipWhile, switchMap, take } from 'rxjs';

import { AppState, selectAuthenticatedUserId } from '@accenture/global-store';
import { SessionRole, UserAccess, UserAccessSession } from '@accenture/shared/data';

import { UserAccessService } from '../services/user-access.service';
import { getRouterParams } from '../utils';

@Injectable()
export class SessionLeaderGuard {
    constructor(private router: Router, private store: Store<AppState>, private userAccessService: UserAccessService) {}

    canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
        const { projectId, sessionId } = getRouterParams(route);

        return this.verifySessionLeaderRole(projectId, sessionId);
    }

    private verifySessionLeaderRole(projectId: string, sessionId: string): Observable<boolean> {
        return this.store.select(selectAuthenticatedUserId).pipe(
            skipWhile(userId => isUndefined(userId)),
            switchMap(userId => this.userAccessService.getUserAssignmentByProjectId(userId, projectId)),
            take(1),
            map((userAccess: UserAccess) => {
                const sessionAccess: UserAccessSession = userAccess?.sessions?.[sessionId];
                if (sessionAccess?.role === SessionRole.Leader) {
                    return true;
                }

                this.redirectToHome();
                return false;
            }),
        );
    }

    private redirectToHome(): void {
        this.router.navigate(['/home']);
    }
}
