export const downloadFile = (url: string, name?: string): void => {
    const a = document.createElement('a');

    a.setAttribute('href', url);
    a.setAttribute('download', name || '');
    a.setAttribute('target', '_blank');
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
};
