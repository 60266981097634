import { BaseResponse } from './response';

export class ProomptResponse extends BaseResponse {
    prompt?: string;
    // add needed other fields here
    constructor(response: Partial<ProomptResponse>) {
        super(response);
        this.prompt = response.prompt ?? '';
    }

    createSerializableObject(): Record<string, unknown> {
        return {
            activityItemId: this.activityItemId,
            activityId: this.activityId,
            sessionId: this.sessionId,
            userId: this.userId,
            value: this.value,
            prompt: this.prompt,
            created: this.created,
            updated: this.updated,
        };
    }
}
