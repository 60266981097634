<div class="confirmation-dialog p-16">
  <p class="confirmation-dialog-title tt9-body-medium-1 text-overflow-ellipsis-1 mb-8 mr-16">
    {{ title }}
  </p>
  <button class="tt9-btn-icon confirmation-dialog-close" (click)="close()">
    <accenture-icon>icon-close</accenture-icon>
  </button>

  <div class="confirmation-dialog-content">
    <p class="tt9-body-2">Users with the following emails have not been invited:</p>

    <ul class="list tt9-body-2">
      <li *ngFor="let email of data.emails">
        {{ email }}
      </li>
    </ul>

    <p class="tt9-body-2">All non-Accenture users should be invited via guest link directly to the session.</p>
  </div>

  <div class="d-flex-end mt-16">
    <button class="tt9-btn-default text-uppercase" (click)="close()">ok</button>
  </div>
</div>
