import { canActivate } from '@angular/fire/compat/auth-guard';
import { Routes } from '@angular/router';
import { map } from 'rxjs/operators';

import { notFoundUrl, ParentType } from '@accenture/shared/data';

import { AuthSystemGuard } from './auth-system.guard';
import { DevEnvGuard } from './dev-env.guard';

const redirectToPlatformAuthentication = () =>
    map((user) => {
        if (user) {
            return true;
        }
        const url = window.location.href.toString().split(window.location.host)[1];
        sessionStorage.setItem('urlBeforeRedirectUnauthorized', url);

        return ['authentication', 'platform'];
    });

export const routes: Routes = [
    {
        path: 'dashboard',
        canActivate: [AuthSystemGuard],
        data: { authGuardPipe: redirectToPlatformAuthentication },
        loadChildren: async () => (await import('@accenture/dashboard')).DashboardModule,
    },
    {
        path: 'activity-template',
        canActivate: [AuthSystemGuard],
        loadChildren: async () => (await import('@accenture/activity-templates')).ActivityTemplatesModule,
        data: { authGuardPipe: redirectToPlatformAuthentication, parentType: ParentType.ActivityTemplates },
    },
    {
        path: 'public-activity-template',
        canActivate: [AuthSystemGuard],
        loadChildren: async () => (await import('@accenture/activity-templates')).ActivityTemplatesModule,
        data: { authGuardPipe: redirectToPlatformAuthentication, parentType: ParentType.PublicActivityTemplates },
    },
    {
        path: 'template',
        redirectTo: 'session-template',
    },
    {
        path: 'session-template',
        canActivate: [AuthSystemGuard],
        loadChildren: async () => (await import('@accenture/session-template')).SessionTemplateModule,
        data: { authGuardPipe: redirectToPlatformAuthentication, parentType: ParentType.Templates },
    },
    {
        path: 'public-session-template',
        canActivate: [AuthSystemGuard],
        loadChildren: async () => (await import('@accenture/session-template')).SessionTemplateModule,
        data: { authGuardPipe: redirectToPlatformAuthentication, parentType: ParentType.PublicSessionTemplates },
    },
    {
        path: 'project',
        canActivate: [AuthSystemGuard],
        loadChildren: async () => (await import('@accenture/erp-deployment/project')).ProjectModule,
        data: { authGuardPipe: redirectToPlatformAuthentication, parentType: ParentType.Projects },
    },
    {
        path: 'session',
        canActivate: [AuthSystemGuard],
        loadChildren: async () => (await import('@accenture/session-editor-tt9')).SessionEditorTt9Module,
        data: { authGuardPipe: redirectToPlatformAuthentication, parentType: ParentType.Sessions },
    },
    {
        path: 'collection',
        canActivate: [AuthSystemGuard],
        loadChildren: async () => (await import('@accenture/collections')).CollectionsModule,
        data: { authGuardPipe: redirectToPlatformAuthentication, parentType: ParentType.Collections },
    },
    {
        path: 'project-template',
        canActivate: [AuthSystemGuard],
        loadChildren: async () => (await import('@accenture/erp-deployment/project')).ProjectModule,
        data: { authGuardPipe: redirectToPlatformAuthentication, parentType: ParentType.ProjectTemplates },
    },
    {
        path: 'public-project-template',
        canActivate: [AuthSystemGuard],
        loadChildren: async () => (await import('@accenture/erp-deployment/project')).ProjectModule,
        data: { authGuardPipe: redirectToPlatformAuthentication, parentType: ParentType.PublicProjectTemplates },
    },
    {
        path: 'profile',
        loadChildren: async () => (await import('@accenture/admin/user-profile')).UserProfileModule,
        canActivate: [AuthSystemGuard],
        data: { authGuardPipe: redirectToPlatformAuthentication },
    },
    {
        path: 'admin',
        loadChildren: async () => (await import('@accenture/admin/user-admin')).UserManagementModule,
        canActivate: [AuthSystemGuard],
        data: { authGuardPipe: redirectToPlatformAuthentication },
    },
    {
        path: 'api',
        ...canActivate(redirectToPlatformAuthentication),
        loadChildren: async () => (await import('@accenture/admin/api')).ApiModule,
    },
    {
        path: 'file-access',
        loadChildren: async () => (await import('@accenture/file-access')).FileAccessModule,
        canActivate: [AuthSystemGuard],
        data: { authGuardPipe: redirectToPlatformAuthentication },
    },
    {
        path: 'style-guide',
        loadChildren: async () => (await import('@accenture/shared/styling')).StylingModule,
        canLoad: [DevEnvGuard],
    },
    {
        path: 'authentication',
        loadChildren: async () => (await import('@accenture/authentication')).AuthenticationModule,
    },
    {
        path: notFoundUrl,
        loadComponent: async () => (await import('@accenture/shared/ui')).NotAvailableComponent,
    },
    {
        path: '',
        redirectTo: '/dashboard/home',
        pathMatch: 'full',
    },
    {
        path: '**',
        redirectTo: '/dashboard/home',
        pathMatch: 'full',
    },
];
