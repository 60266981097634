import { Observable } from 'rxjs';

export class ResizeObservable extends Observable<ResizeObserverEntry[]> {
    constructor(elem: HTMLElement) {
        super(subscriber => {
            const resizeObserver = new ResizeObserver(entries => {
                subscriber.next(entries);
            });

            resizeObserver.observe(elem);

            return function unsubscribe() {
                resizeObserver.unobserve(elem);
                resizeObserver.disconnect();
            };
        });
    }
}
