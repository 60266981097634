import { removeEmptyKeys } from '@accenture/shared/util';

import { ProjectRole } from '../access-control';
import { Project } from '../project';
import { InAppNotificationType } from './in-app-notification-type';
import { BaseInAppNotification } from './in-app-notifications';

//TODO: Delete after project deprecation
export class InAppProjectRoleChangeNotification extends BaseInAppNotification {
    projectId: string;
    projectName: string;
    role: ProjectRole;
    newRole: ProjectRole;

    constructor(notification: any);
    constructor(userId: string, project: Project, role: ProjectRole, newRole: ProjectRole);
    constructor(notificationOrUserId: any, project?: Project, role?: ProjectRole, newRole?: ProjectRole) {
        if (typeof notificationOrUserId === 'object') {
            super(notificationOrUserId as BaseInAppNotification);

            this.id = notificationOrUserId.id;
            this.type = InAppNotificationType.ProjectRoleChange;
            this.projectId = notificationOrUserId.projectId;
            this.projectName = notificationOrUserId.projectName;
            this.role = notificationOrUserId.role;
            this.newRole = notificationOrUserId.newRole;
        } else {
            super(InAppNotificationType.ProjectRoleChange, notificationOrUserId);

            this.projectId = project.id;
            this.projectName = project.name;
            this.role = role;
            this.newRole = newRole;
        }
    }

    createSerializableObject(): Record<string, unknown> {
        return removeEmptyKeys({
            type: this.type,
            userId: this.userId,
            unread: this.unread,
            projectId: this.projectId,
            projectName: this.projectName,
            role: this.role,
            newRole: this.newRole,
            created: this.created,
            updated: this.updated,
        });
    }
}
