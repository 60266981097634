<mat-form-field
  class="sessions-panel-dropdown tt9-form-field no-label no-margin"
  [appearance]="appearance"
  [floatLabel]="floatLabel"
>
  <mat-select
    class="sessions-panel-dropdown-active-tab"
    [value]="defaultValue"
    [panelClass]="['tt9-select-panel-custom']"
    [ngClass]="{ 'tt9-body-2': isSearch }"
    (selectionChange)="selectionClick.emit($event)"
  >
    <ng-container
      class="sessions-panel-dropdown-option"
      *ngIf="!isSearch; else searchOptions"
    >
      <mat-option
        *ngFor="let items of sessionDropdownFilter; trackBy: trackById"
        [value]="items"
      >
        <span>{{ displaySessionSelectRole[items] }}</span>
      </mat-option>
    </ng-container>

    <ng-template #searchOptions>
      <mat-option
        *ngFor="let items of searchFilterOptions; trackBy: trackById"
        class="tt9-body-1"
        [value]="items"
      >
        <span>{{ items }}</span>
      </mat-option>
    </ng-template>
  </mat-select>
</mat-form-field>
