<ng-container *ngIf="vm$ | async as vm">
  <ng-container *accentureLoaded="!vm.isLoading; description: loaderText">
    <accenture-base-dialog [title]="title">
      <div class="d-flex-column">
        <div class="p-32">
          <p class="tt9-body-2 tt9-color-neutral-400 mb-8">Add an additional prompt for a more precise output.</p>
          <mat-form-field appearance="outline" class="mat-form-field tt9-form-field textarea width-100">
            <textarea
              matInput
              #userInput
              cdkTextareaAutosize
              #autosize="cdkTextareaAutosize"
              rows="3"
              cdkAutosizeMaxRows="5"
              type="text"
              [placeholder]="placeholderPromptText"
              class="summary-textarea"
              [maxLength]="characterLimitLarge"
              [ngClass]="{
                'max-length': characterLimitLarge === userInput.value.length
              }"
            ></textarea>
            <mat-hint
              class="mat-hint-limit tt9-helper-text"
              [class]="determineHintClass(userInput.value.length, characterLimitLarge)"
              >{{ characterLimitText(userInput.value.length, characterLimitLarge) }}
            </mat-hint>
            <mat-error>MAT ERROR</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div *ngIf="!!summary" class="footer d-flex summarize-render">
        <div [innerHTML]="rendered()"></div>
      </div>
      <div class="footer d-flex-end accenture-dialog-actions">
        <div class="d-flex">
          <button *ngIf="!!summary" class="tt9-btn mr-8" (click)="download($event)">Download</button>
          <button class="tt9-btn btn-success" (click)="summarize($event, userInput.value)">Summarize</button>
        </div>
      </div>
    </accenture-base-dialog>
  </ng-container>
</ng-container>
