import {
    ActivityType,
    AllTopicsValue,
    DataFlowConnection,
    DataFlowFilters,
    Dictionary,
    FilterProperty,
    TableColumn,
    ValueRange,
} from '..';

export class DataFlowOptionsConnectionForm {
    topics: string[];
    contentToMove: string;
    filter: string;
    top_x: number | null;
    bottom_x: number | null;
    transformIntoResponses: boolean;
    top_parameter: string[];
    bottom_parameter: string[];
    destinationColumn: Partial<TableColumn>[] | null;

    constructor(
        data: DataFlowConnection,
        topicsIdByActivityIds: Dictionary<string | string[]>,
        columns?: Partial<TableColumn>[],
    ) {
        const filterParameterValue
            = data.filterProperty === FilterProperty.TopX
                ? DataFlowFilters.Top
                : data.filterProperty === FilterProperty.BottomX
                ? DataFlowFilters.Bottom
                : false;

        const checkedTopics = [];

        if (data.sourceType === ActivityType.Brainstorm) {
            data.sourceIds.forEach(sourceId =>
                data.topics[sourceId] === AllTopicsValue
                    ? checkedTopics.push(...topicsIdByActivityIds[sourceId], sourceId)
                    : checkedTopics.push(...data.topics[sourceId]),
            );
        }

        if (columns) {
            const column = columns.find(item => data?.destinationColumn.id === item.id);
            this.destinationColumn = column ? [column] : null;
        }

        this.topics = checkedTopics;
        this.contentToMove = this.contentToMoveValue(data);
        this.filter = data.filterProperty;
        this.top_x = filterParameterValue === DataFlowFilters.Top ? data.filterValue : ValueRange.ValueRange5;
        this.bottom_x = filterParameterValue === DataFlowFilters.Bottom ? data.filterValue : ValueRange.ValueRange5;
        this.transformIntoResponses = data.transformToResponses;
        this.top_parameter
            = filterParameterValue === DataFlowFilters.Top ? Object.values(data.filterParameterByActivityId || {}) : [];
        this.bottom_parameter
            = filterParameterValue === DataFlowFilters.Bottom
                ? Object.values(data.filterParameterByActivityId || {})
                : [];
    }

    createSerializableObject(): Record<string, unknown> {
        return {
            topics: this.topics,
            contentToMove: this.contentToMove,
            filter: this.filter,
            top_x: this.top_x,
            bottom_x: this.bottom_x,
            transformIntoResponses: this.transformIntoResponses,
            top_parameter: this.top_parameter,
            bottom_parameter: this.bottom_parameter,
            destinationColumn: this.destinationColumn,
        };
    }

    private contentToMoveValue(data: DataFlowConnection): DataFlowFilters {
        if (data.moveComments && data.moveResponses) {
            return DataFlowFilters.ResponsesAndComments;
        }

        if (data.moveTopics && data.moveResponses) {
            return DataFlowFilters.TopicsWithResponses;
        }

        if (data.moveTopics) {
            return DataFlowFilters.Topics;
        }

        return data.moveComments ? DataFlowFilters.Comments : DataFlowFilters.Responses;
    }
}
