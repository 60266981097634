import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Timestamp } from 'firebase/firestore';

import {
    accentureApproveImgPath,
    ActivityType,
    activityTypeImages,
    activityTypesToDisplay,
    FavoriteAccess,
    navigationCardImageUrl,
    ParentType,
    PublicAccess,
    publicParentTypes,
    routerLinksMap,
    TemplateAccessType,
    TemplateStatus,
    TemplateTab,
    UserTemplate,
} from '@accenture/shared/data';
import { IconSize, ImageInputPlaceholdersTypes, imageInputPlaceholdersUrl } from '@accenture/shared/ui';
import { isTouchDevice } from '@accenture/shared/util';

import { copyLinkToClipboardText, saveToMyTemplatesText } from './constants';

@Component({
    selector: 'accenture-featured-template-card',
    templateUrl: './featured-template-card.component.html',
    styleUrls: ['./featured-template-card.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FeaturedTemplateCardComponent {
    @Input() template!: TemplateAccessType;
    @Input() hoveredCardIndex!: number;
    @Input() isTemplatesPage!: boolean;
    @Input() activeTemplateTab!: TemplateTab;
    @Input() templateIndex!: number;
    @Input() userId!: string;
    @Input() isAllTemplateTab!: boolean;
    @Input() showDeepLinks!: boolean;

    @Output() openPublishDialog = new EventEmitter<TemplateAccessType>();
    @Output() openForPreview = new EventEmitter<{ template: TemplateAccessType; event?: Event }>();
    @Output() openMenu = new EventEmitter<void>();
    @Output() closeMenu = new EventEmitter<void>();

    smallIconSize = IconSize.Small;
    accentureApproveImgPath = accentureApproveImgPath;

    copyLinkToClipboardLabel = copyLinkToClipboardText;
    saveToMyTemplatesLabel = saveToMyTemplatesText;
    isTouchDevice = isTouchDevice();

    getImageUrl(imageUrl?: string): string {
        return imageUrl ? `url(${imageUrl})` : '';
    }

    getTemplateType(activityType: string) {
        return activityTypesToDisplay[activityType] ?? TemplateTab.Sessions;
    }

    isPublic(templateType: ParentType): boolean {
        return publicParentTypes.includes(templateType);
    }

    isApproved(template: TemplateAccessType): boolean {
        return this.isPublic(template.templateType) && (template as FavoriteAccess | PublicAccess).approved;
    }

    isFeatured(template: TemplateAccessType): boolean {
        return this.isPublic(template.templateType) && !!(template as FavoriteAccess | PublicAccess).featured;
    }

    getEmptyImage(templateType: ParentType, activityType?: ActivityType | undefined): string {
        switch (templateType) {
            case ParentType.Templates:
            case ParentType.PublicSessionTemplates:
                return imageInputPlaceholdersUrl[ImageInputPlaceholdersTypes.Session];
            case ParentType.ActivityTemplates:
            case ParentType.PublicActivityTemplates:
                return activityTypeImages[activityType] || activityTypeImages['default'];
            default:
                return navigationCardImageUrl.path;
        }
    }

    isPublished(template: TemplateAccessType): boolean {
        return (template as UserTemplate).status === TemplateStatus.Published;
    }

    isDraft(template: TemplateAccessType, userId: string): boolean {
        const templateData = template as PublicAccess | FavoriteAccess;
        return (
            this.isPublic(template.templateType)
            && templateData.draftTemplateId
            && (templateData.ownerId === userId || (templateData.teamMembersIds || []).includes(userId))
        );
    }

    getTemplateUpdateDate(template: TemplateAccessType): Date {
        const updated = template.updated as Timestamp;
        return updated?.toDate ? updated.toDate() : new Date();
    }

    getTemplateLink(templateType: ParentType, templateId: string, activityType: ActivityType | undefined): string {
        if (activityType) {
            return `/activity-template/${activityType.toLowerCase()}-edit/${templateId}`;
        }

        return `/${routerLinksMap[templateType]}/${templateId}`;
    }

    isPublicSessionTemplate(templateType: ParentType): boolean {
        return this.isPublic(templateType) && templateType === ParentType.PublicSessionTemplates;
    }
}
