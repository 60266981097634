import { ChangeDetectionStrategy, Component } from '@angular/core';

import { TemplatesTab, User } from '@accenture/shared/data';
import { trackByValue } from '@accenture/shared/util';

import { TemplatesFacade } from './templates-facade';

@Component({
    selector: 'accenture-templates',
    templateUrl: './templates.component.html',
    styleUrls: ['./templates.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [TemplatesFacade],
})
export class TemplatesComponent {
    vm$ = this.facade.vm$;
    templatesTabs = [TemplatesTab.MyTemplates, TemplatesTab.Favorites, TemplatesTab.TemplateStore];
    trackByValue = trackByValue;
    templatesTab = TemplatesTab;

    constructor(private facade: TemplatesFacade) {}

    isTabVisible(tab: TemplatesTab, userRoles: Record<string, boolean>): boolean {
        if (tab === TemplatesTab.TemplateStore) {
            return userRoles?.templateCreator || userRoles?.admin;
        }
        return true;
    }

    getTemplatesCount(
        user: User,
        tab: TemplatesTab,
        publicTemplatesLength: number,
        favoriteTemplatesLength: number,
    ): number {
        switch (tab) {
            case TemplatesTab.MyTemplates:
                return user.activityTemplatesCount + user.templatesCount + user.projectTemplatesCount || 0;
            case TemplatesTab.Favorites:
                return favoriteTemplatesLength || 0;
            case TemplatesTab.TemplateStore:
                return publicTemplatesLength;
            default:
                return 0;
        }
    }

    setTemplatesTab(tab: TemplatesTab): void {
        this.facade.setTemplatesTab(tab);
    }
}
