import {
    AfterViewInit,
    Directive,
    ElementRef,
    EventEmitter,
    Input,
    NgZone,
    OnChanges,
    OnDestroy,
    Output,
    SimpleChanges,
} from '@angular/core';
import { truncate } from 'lodash';

import { getStringWidth } from '@accenture/shared/util';

@Directive({
    selector: '[accentureTruncateTextEllipsis]',
})
export class ResponseCardResponseEllipsisDirective implements OnChanges, OnDestroy, AfterViewInit {
    @Input('accentureTruncateTextEllipsis') elementHTMLForEllipsis = '';
    @Input() containerElement: HTMLElement = document.createElement('div');
    @Input() offsetWidth: HTMLElement = document.createElement('div');
    @Input() isShowAll = false;
    @Input() lineCount = 3;
    @Input() buttonWidth = 0;
    @Input() isAdminSessionTags = false;

    @Output() truncatedValueChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    private observer!: ResizeObserver;
    private previousWidth!: number;
    private previousHeight!: number;
    private isChangedProperty = false;

    constructor(private zone: NgZone, private element: ElementRef) {}

    ngOnChanges(changes: SimpleChanges): void {
        const [isShowAll, elementHTMLForEllipsis] = ['isShowAll', 'elementHTMLForEllipsis'];
        this.isChangedProperty = true;
        if (isShowAll in changes || elementHTMLForEllipsis in changes) {
            this.setResponseTemplate();
        }
    }

    ngAfterViewInit(): void {
        this.observer = new ResizeObserver((entries) => {
            for (const entry of entries) {
                const { width, height } = entry.contentRect;

                if (
                    (this.previousWidth !== width
                        && this.previousHeight !== height
                        && this.previousWidth !== undefined)
                    || !this.isChangedProperty
                ) {
                    this.zone.run(() => this.setResponseTemplate());
                }
                this.previousWidth = width;
                this.previousHeight = height;
                this.isChangedProperty = false;
            }
        });
        this.observer.observe(this.containerElement);
    }

    ngOnDestroy(): void {
        this.observer.unobserve(this.containerElement);
    }

    // remove leading and trailing line breaks
    // then split to an array but include line breaks (\n) in the array
    // if response has 1 or more leading line breaks, retain only 1
    private transformWords(response: string): string[] {
        const hasLeadingLineBreak = response.match(/^\n/g);
        const res = response
            .trim()
            .split(/(\n)/)
            .flatMap((part) => part.split(' ').filter(Boolean));

        // only add 1 leading line break even if there are multiple
        if (hasLeadingLineBreak) {
            return ['\n'].concat(res);
        }

        return res;
    }

    // don't add space width if next word is a line break
    // OR if it is the last word
    private addSpaceWidth(index: number, words: string[]): number {
        const fontStyles: string = this.getFontStyles() || '';
        const spaceWidth = getStringWidth(' ', fontStyles, true);
        if (words[index + 1] === '\n' || index + 1 >= words.length) {
            return 0;
        }
        return spaceWidth;
    }

    private setTruncatedTemplate(): void {
        const initialElement: HTMLSpanElement = this.getInitialElementByResponse();
        const userNameOffsetWidth: number = this.offsetWidth.offsetWidth;
        const containerWidth: number = this.getContainerWidth();
        const htmlElement: HTMLElement = this.element.nativeElement;
        const words: string[] = this.transformWords(initialElement.innerText);

        const fontStyles: string = this.getFontStyles() || '';
        const displayWord: string[] = [];
        const spaceWidth = getStringWidth(' ', fontStyles, true);

        let hideTruncatedWord = false;
        let truncatedWord = '';
        let originalWord = '';
        let truncatedWordIndex = 0;
        let lineWidth = 0;
        let truncatedWordIndexLetter = 0;
        let currentLine = 1;

        for (const [wordIndex, word] of words.entries()) {
            const wordWidth = getStringWidth(word, fontStyles, true) + this.addSpaceWidth(wordIndex, words);
            const isAddNameWidth = currentLine === 1 && wordIndex === 0;

            let isLongWord = false;
            let isLastLetterWord = false;

            // handle line breaks
            if (word === '\n') {
                currentLine++;

                if (currentLine > this.lineCount) {
                    truncatedWord = word;
                    truncatedWordIndex = wordIndex;
                    hideTruncatedWord = true;
                    break;
                }

                displayWord[wordIndex] = word;
                lineWidth = 0;
                continue; // proceed to next iteration
            }

            lineWidth
                += (isAddNameWidth ? userNameOffsetWidth + spaceWidth : 0)
                + wordWidth
                + (this.isAdminSessionTags ? spaceWidth : 0);

            if (containerWidth < lineWidth) {
                // handle long words
                if (containerWidth < wordWidth) {
                    const letters = word.split('');

                    //to be wrapped in span
                    displayWord[wordIndex] = /https?:\/\/[^\s]+/.test(word) ? word : `longWord${wordIndex}`;
                    isLongWord = true;

                    let currentLineWidth
                        = (currentLine === 1 ? userNameOffsetWidth : 0) + (wordIndex === 0 ? 0 : lineWidth - wordWidth);

                    for (const [longWordLetterIndex, letter] of letters.entries()) {
                        const letterWidth = getStringWidth(letter, fontStyles, true);

                        currentLineWidth += letterWidth;

                        if (containerWidth < currentLineWidth) {
                            if (this.lineCount === currentLine) {
                                // 1 - get previous letter index
                                // 8 - approximately ellipsis width
                                truncatedWordIndexLetter = longWordLetterIndex - 1 - 8;
                                truncatedWord = this.truncateEmoticonAndWord(word, truncatedWordIndexLetter);
                                truncatedWordIndex = wordIndex;
                                originalWord = word;
                                hideTruncatedWord = truncatedWordIndexLetter < 0;
                                break;
                            }

                            currentLine++;
                            currentLineWidth = letterWidth;
                        }

                        lineWidth = currentLineWidth;

                        if (letters.length - 1 === longWordLetterIndex) {
                            isLastLetterWord = true;
                            currentLine--;
                            break;
                        }
                    }
                }

                // normal truncation
                if (this.lineCount === currentLine && !truncatedWordIndexLetter) {
                    truncatedWord = word;
                    truncatedWordIndex = wordIndex;
                    hideTruncatedWord = true;
                    break;
                }

                displayWord[wordIndex] = isLongWord ? displayWord[wordIndex] : word;
                currentLine++;
                lineWidth = isLastLetterWord ? lineWidth : wordWidth;
            } else {
                displayWord[wordIndex] = word;
            }
        }

        const hasEllipsis = !!truncatedWord || !!truncatedWordIndex;
        this.truncatedValueChange.emit(hasEllipsis);
        const displayWords = displayWord.join(' ');

        if (hasEllipsis) {
            htmlElement.innerHTML = this.createEllipsisTemplate(
                truncatedWordIndex,
                truncatedWord,
                hideTruncatedWord,
                originalWord,
                displayWords,
            );
        } else {
            htmlElement.innerHTML = this.createLongWordTemplate(displayWords);
        }
    }

    private getContainerWidth(): number {
        const computedValueStyle = window.getComputedStyle(this.containerElement);
        const textContainerWidth = parseInt(computedValueStyle.getPropertyValue('width'));

        const adjustedWidth = textContainerWidth - (this.isAdminSessionTags ? this.buttonWidth : 0);

        return adjustedWidth;
    }

    private getFontStyles(): string {
        const computedValueStyle = window.getComputedStyle(this.containerElement);

        const fontWeight = computedValueStyle.getPropertyValue('font-weight');
        const fontSize = computedValueStyle.getPropertyValue('font-size');
        const fontFamily = computedValueStyle.getPropertyValue('font-family');

        const fontStyles = `${fontWeight} ${fontSize} ${fontFamily}`;

        return fontStyles;
    }

    private createEllipsisTemplate(
        truncatedWordIndex: number,
        truncatedWord: string,
        hideTruncatedWord: boolean,
        originalWord: string,
        displayWords: string,
    ): string {
        const element: HTMLElement = this.getInitialElementByResponse();
        const words: string[] = this.transformWords(element.innerText).slice(0, truncatedWordIndex + 1);
        const newWords: string[] = displayWords.split(' ').slice(0, truncatedWordIndex + 1);
        const ellipsisTemplate = this.handleBreakWord(
            words,
            newWords,
            truncatedWordIndex,
            hideTruncatedWord,
            truncatedWord,
            originalWord,
        );
        return ellipsisTemplate;
    }

    private createLongWordTemplate(displayWords: string): string {
        const element: HTMLElement = this.getInitialElementByResponse();
        const words: string[] = this.transformWords(element.innerText);
        const newWords: string[] = displayWords.split(' ');
        const longWordTemplate = this.handleBreakWord(words, newWords);
        return longWordTemplate;
    }

    // handle break word when link or word is larger than the container
    private handleBreakWord(
        words: string[],
        newWords: string[],
        truncatedWordIndex?: number,
        hideTruncatedWord?: boolean,
        truncatedWord?: string,
        originalWord?: string,
    ): string {
        return words.reduce((resultHTML: string, word: string, i: number): string => {
            const isLink = /https?:\/\/[^\s]+/.test(word);
            const isLongWord = word !== newWords[i];
            const isWordForEllipsis = i === truncatedWordIndex;
            const ellipsisWordText = hideTruncatedWord ? '' : `${truncatedWord}`;
            const wordText = isWordForEllipsis ? ellipsisWordText : `${word}`;

            if (isLink) {
                const linkElement = document.createElement('a');
                linkElement.innerText = wordText;
                linkElement.href = originalWord ?? word;

                const spanElement = document.createElement('span');
                spanElement.classList.add('break-all-word');
                spanElement.appendChild(linkElement);
                resultHTML += spanElement.outerHTML + ' ';

                return resultHTML;
            }

            if (isLongWord) {
                const spanElement = document.createElement('span');
                spanElement.classList.add('break-all-word');
                spanElement.innerText = wordText;
                resultHTML += spanElement.outerHTML + ' ';

                return resultHTML;
            }

            // remove space if wordText is \n OR if next word is \n
            resultHTML += wordText + this.appendSpace(wordText, words[i + 1], newWords.length - 1, i);

            return resultHTML;
        }, '');
    }

    // remove space if wordText is \n
    // OR if next word is \n
    // OR at the last word of un-truncated response
    private appendSpace(wordText: string, nextWord: string, newWordsLastItem: number, index: number): string {
        if (wordText === '\n' || nextWord === '\n' || index === newWordsLastItem) {
            return '';
        }
        return ' ';
    }

    private getInitialElementByResponse(): HTMLSpanElement {
        const span = document.createElement('span');
        span.innerHTML = this.elementHTMLForEllipsis.replace(/<br>/g, '\n');

        return span;
    }

    private resetElement(): void {
        this.element.nativeElement.innerHTML = this.transformWords(this.elementHTMLForEllipsis.replace(/<br>/g, '\n'))
            .join(' ')
            .replace(/ \n | \n|\n /g, '\n'); // trim spaces surrounding \n
    }

    private isShowLessButton(): void {
        const userNameOffsetWidth: number = this.offsetWidth.offsetWidth;
        const containerWidth: number = this.getContainerWidth();
        const initialElement: HTMLSpanElement = this.getInitialElementByResponse();
        const fontStyles: string = this.getFontStyles() || '';
        const text = initialElement.innerText;
        const calculateAccessibleWidth = this.lineCount * containerWidth;
        const calculateTextWidth = getStringWidth(text, fontStyles, true) + userNameOffsetWidth;

        if (calculateTextWidth > calculateAccessibleWidth) {
            this.truncatedValueChange.emit(true);
        }
    }

    private setResponseTemplate(): void {
        if (this.isShowAll) {
            this.resetElement();
            this.isShowLessButton();
            return;
        }

        this.setTruncatedTemplate();
    }

    private truncateEmoticonAndWord(text: string, length: number): string {
        //used truncate from lodash to handle and prevent showing of any unrecognized character
        const truncateData = truncate(text, {
            length,
            separator: /[\s,]+/,
        });

        return truncateData;
    }
}
