import { ComponentType, OverlayRef } from '@angular/cdk/overlay';
import { Directive, Input, OnDestroy, TemplateRef, ViewContainerRef } from '@angular/core';

import { LoadingComponent } from '../../components';
import { LoaderWithDescriptionComponent } from '../../components/loader-with-description/loader-with-description.component';
import { LoadedService } from '../../services/loaded.service';

@Directive({
    selector: '[accentureLoaded]',
})
export class LoadedDirective implements OnDestroy {
    // TODO: refactor
    private description?: string;
    private component?: ComponentType<any>;
    private loaded?: boolean;
    private overlayRef?: OverlayRef;

    // if no input properties specified - content will  be hidden initially
    // it will be displayed when loading completed

    // specify this property to control loader visibility
    @Input()
    public set accentureLoaded(loaded: boolean) {
        this.loaded = loaded;
        this.processDataChanges();
    }

    // specify this property if you want to add additional text to the spinner
    // the content inside loader won't be hidden
    @Input()
    public set accentureLoadedDescription(description: string | undefined) {
        this.description = description;
        this.processDataChanges();
    }

    // specify this property if you want to use custom component for spinner
    // the content inside loader won't be hidden
    @Input()
    public set accentureLoadedComponent(component: ComponentType<any>) {
        this.component = component;
        this.processDataChanges();
    }

    constructor(
        private templateRef: TemplateRef<any>,
        private viewContainer: ViewContainerRef,
        private loadedService: LoadedService,
    ) {}

    ngOnDestroy(): void {
        this.destroyOverlay();
    }

    private processDataChanges(): void {
        this.showLoader(!this.loaded);
    }

    private destroyOverlay(): void {
        this.overlayRef?.dispose();
    }

    private showLoader(show: boolean): void {
        if (this.component) {
            return this.showCustomLoader(show, this.component);
        }

        if (this.description) {
            return this.showLoaderWithDescription(show);
        }

        this.showDefaultLoader(show);
    }

    private showCustomLoader(showOverlay: boolean, component: ComponentType<any>): void {
        this.toggleOverlay(showOverlay, true, true, component, {});
    }

    private showDefaultLoader(showOverlay: boolean): void {
        if (showOverlay) {
            this.viewContainer.remove();
        }
        this.toggleOverlay(showOverlay, false, false, LoadingComponent, {});
    }

    private showLoaderWithDescription(showOverlay: boolean): void {
        const loaderData = { description: this.description };

        this.toggleOverlay(showOverlay, true, true, LoaderWithDescriptionComponent, loaderData);
    }

    private toggleOverlay(
        showOverlay: boolean,
        showBackdrop: boolean,
        showContentDuringLoading: boolean,
        component: ComponentType<any>,
        data: any,
    ): void {
        if (showOverlay) {
            if (showContentDuringLoading) {
                this.ensureViewRendered();
            } else {
                this.destroyOverlay();
            }

            this.showOverlay(component, showBackdrop, data);
        } else {
            this.destroyOverlay();
            this.ensureViewRendered();
        }
    }

    private ensureViewRendered(): void {
        if (!this.viewContainer.length) {
            this.viewContainer.createEmbeddedView(this.templateRef);
        }
    }

    private showOverlay(component: ComponentType<any>, backdrop: boolean, data: any): void {
        this.destroyOverlay();
        this.overlayRef = this.loadedService.show(component, backdrop, data);
    }
}
