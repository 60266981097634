import { FormControl, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { isEmpty, omit } from 'lodash';

import { validationErrors } from './validation-errors';

const invalidRangeError = { [validationErrors.invalidRange]: true };

type RangeValidatorFormGroup = FormGroup<{
    min: FormControl<number>;
    max: FormControl<number>;
}>;

export const rangeValidator: ValidatorFn = (formGroup: RangeValidatorFormGroup): ValidationErrors | null => {
    const minControl = formGroup.controls.min;
    const maxControl = formGroup.controls.max;
    const minControlErrors = minControl.errors;
    const maxControlErrors = maxControl.errors;

    if (!minControl || !maxControl) {
        return null;
    }

    formGroup.markAllAsTouched();

    const isMinGreaterOrEqualThanMax
        = (minControl.value || minControl.value === 0)
        && (maxControl.value || maxControl.value === 0)
        && +minControl.value >= +maxControl.value;

    if (isMinGreaterOrEqualThanMax) {
        minControl.setErrors({ ...minControlErrors, ...invalidRangeError });
        maxControl.setErrors({ ...maxControlErrors, ...invalidRangeError });
    } else {
        const newMinControlErrors = omit(minControlErrors, validationErrors.invalidRange);
        const newMaxControlErrors = omit(maxControlErrors, validationErrors.invalidRange);

        minControl.setErrors(isEmpty(newMinControlErrors) ? null : newMinControlErrors);
        maxControl.setErrors(isEmpty(newMaxControlErrors) ? null : newMaxControlErrors);
    }

    return isMinGreaterOrEqualThanMax ? invalidRangeError : null;
};
