import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest, Observable, of, switchMap } from 'rxjs';
import { distinctUntilChanged, map, startWith } from 'rxjs/operators';

import { deleteUserFromTemplateConfirmation, TeamMemberService } from '@accenture/erp-deployment/shared/domain';
import { AppState, selectActivityIdAndParentIds, selectAuthenticatedUserId } from '@accenture/global-store';
import {
    deletingUserDoneSnackbarTitle,
    deletingUserErrorSnackbarTitle,
    deletingUserFromTemplateDoneText,
    deletingUserFromTemplateInProgressText,
    deletingUserInProgressSnackbarTitle,
    errorSnackbarText,
    ParentType,
    PublicAccess,
    TeamMember,
    TemplateRole,
} from '@accenture/shared/data';
import { ConfirmationDialogComponent, DialogService, SnackbarService } from '@accenture/shared/ui';

import { TemplateInviteDialogComponent } from '../dialogs';

interface TemplateUsersMap {
    owner: TeamMember;
    collaborators: TeamMember[];
}

export interface TemplateTeamMembersPanelViewModel {
    owner: TeamMember;
    collaborators: TeamMember[];
    currentUserId: string;
    userInProcessIds: Set<string>;
    isLoading: boolean;
}

export const defaultViewModel: TemplateTeamMembersPanelViewModel = {
    owner: {} as TeamMember,
    collaborators: [],
    currentUserId: '',
    userInProcessIds: new Set(),
    isLoading: true,
};

@Injectable()
export class TemplateTeamMembersPanelFacade {
    private isLoading$ = new BehaviorSubject<boolean>(false);
    private userInProcessIds: Set<string> = new Set();
    private userInProcessIds$ = new BehaviorSubject<Set<string>>(this.userInProcessIds);
    private searchValue$ = new BehaviorSubject<string>('');
    private publicTemplate$ = new BehaviorSubject<PublicAccess>(undefined);

    private templateCreatorId: string;
    private parentId: string;
    private parentType: ParentType;

    vm$ = this.buildViewModel();

    constructor(
        private store: Store<AppState>,
        private teamMemberService: TeamMemberService,
        private dialogService: DialogService,
        private snackbarService: SnackbarService,
    ) {}

    setSearchValue(value: string): void {
        this.searchValue$.next(value);
    }

    setPublicTemplate(template: PublicAccess): void {
        this.publicTemplate$.next(template);
    }

    addMembers(): void {
        const publicTemplate = this.publicTemplate$.getValue();
        this.dialogService.open(TemplateInviteDialogComponent, {
            publicTemplate,
            title: 'Invite template members',
            width: '768px',
            disableClose: true,
            panelClass: 'tt9-modal',
        });
    }

    async deleteTeamMember(userId: string): Promise<void> {
        this.userInProcessIds.add(userId);

        this.snackbarService.showInfoSnackBar(
            deletingUserInProgressSnackbarTitle,
            deletingUserFromTemplateInProgressText,
        );
        try {
            await this.teamMemberService.deleteUserFromTemplate(this.parentType, this.parentId, userId);
            this.snackbarService.showSuccessSnackBar(deletingUserDoneSnackbarTitle, deletingUserFromTemplateDoneText);
        } catch {
            this.snackbarService.showErrorSnackBar(deletingUserErrorSnackbarTitle, errorSnackbarText);
        } finally {
            this.userInProcessIds.delete(userId);
        }
    }

    openDeleteConfirmationDialog(userId: string): void {
        this.dialogService.open(ConfirmationDialogComponent, {
            width: '444px',
            panelClass: 'tt9-modal',
            title: 'Delete user from template',
            confirmBtnText: 'Delete',
            cancelBtnText: 'Cancel',
            text: deleteUserFromTemplateConfirmation,
            isWarning: true,
            confirm: () => this.deleteTeamMember(userId),
        });
    }

    private buildViewModel(): Observable<TemplateTeamMembersPanelViewModel> {
        return combineLatest([
            this.store.select(selectActivityIdAndParentIds),
            this.store.select(selectAuthenticatedUserId),
            this.publicTemplate$.asObservable().pipe(distinctUntilChanged()),
        ]).pipe(
            switchMap(([{ parentType, parentId }, currentUserId, publicTemplate]) => {
                if ((!parentType || !parentId) && !publicTemplate) {
                    return of({
                        ...defaultViewModel,
                        isLoading: false,
                    });
                }

                this.parentType = !!publicTemplate ? publicTemplate.templateType : parentType;
                this.parentId = !!publicTemplate ? publicTemplate.templateId : parentId;

                return combineLatest([
                    this.getTeamMembers(this.parentType, this.parentId),
                    this.searchValue$.asObservable().pipe(distinctUntilChanged()),
                    this.userInProcessIds$.asObservable(),
                    this.isLoading$.asObservable().pipe(distinctUntilChanged()),
                ]).pipe(
                    map(([users, searchValue, userInProcessIds, isLoading]) => {
                        const usersToShow = this.getUsersToShow(users, searchValue.toLowerCase());
                        const { owner, collaborators } = this.splitUsersByRole(usersToShow);
                        const { ownerDisplayName, ownerId, ownerImageUrl } = publicTemplate || {};
                        const ownerData = !!publicTemplate
                            ? ({
                                  displayName: ownerDisplayName,
                                  userId: ownerId,
                                  imageUrl: ownerImageUrl,
                              } as Partial<TeamMember>)
                            : owner;

                        return {
                            collaborators,
                            currentUserId,
                            userInProcessIds,
                            isLoading,
                            owner: ownerData as TeamMember,
                            isActivityTemplate: parentType === ParentType.ActivityTemplates,
                            templateCreatorId: this.templateCreatorId
                                ? this.templateCreatorId
                                : this.getOwnerId(users, publicTemplate),
                        };
                    }),
                );
            }),
            startWith(defaultViewModel),
        );
    }

    private getTeamMembers(parentType: ParentType, parentId: string): Observable<TeamMember[]> {
        return this.teamMemberService.getTeamMembersByParent(parentType, parentId);
    }

    private getUsersToShow(users: TeamMember[], searchCriteria: string): TeamMember[] {
        return users.filter((user) => user.displayName.toLowerCase().includes(searchCriteria));
    }

    private splitUsersByRole(users: TeamMember[]): TemplateUsersMap {
        return users.reduce(
            (splitUsersMap, user) => {
                if (user.isOwner) {
                    splitUsersMap.owner = user;
                }
                if (user.isCollaborator) {
                    splitUsersMap.collaborators = [...splitUsersMap.collaborators, user];
                }
                return splitUsersMap;
            },
            {
                owner: null,
                collaborators: [],
            },
        );
    }

    private getOwnerId(users: TeamMember[], publicTemplate: PublicAccess): string {
        this.templateCreatorId = !!publicTemplate
            ? publicTemplate.ownerId
            : users.find((user) => user.role === TemplateRole.Owner)?.id;
        return this.templateCreatorId;
    }
}
