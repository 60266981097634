import { AbstractControl, FormGroup, ValidatorFn } from '@angular/forms';

import { VoteFormGroup } from '../extended-forms';
import { validationErrors } from './validation-errors';

const topXError = { [validationErrors.topXMaxChecked]: true };

export const voteTopXValidator = (
    valueRange?: number,
    requiredValidator?: { [id: string]: boolean },
    parameterId?: string,
    attributeId?: string,
): ValidatorFn => {
    return (control: AbstractControl) => {
        const questionItemsGroup = control?.parent?.parent as FormGroup;

        if (!questionItemsGroup) {
            return null;
        }

        const checkedCount = Object.values(questionItemsGroup.controls).reduce((count, voteItemControl) => {
            const control = voteItemControl.get(attributeId);

            return count + Number(control?.value ?? 0);
        }, 0);

        let hasError;
        if (requiredValidator?.['default']) {
            hasError
                = Object.keys(questionItemsGroup.controls).length < valueRange
                    ? Object.keys(questionItemsGroup.controls).length !== checkedCount
                    : checkedCount !== valueRange;
        } else {
            hasError = checkedCount > valueRange;
        }

        const voteFormGroup = questionItemsGroup.parent as VoteFormGroup;

        voteFormGroup.setSelectedVoteItemsCount(checkedCount, parameterId, attributeId);
        voteFormGroup.setVoteTopXValidatorValue(valueRange, parameterId, attributeId);
        voteFormGroup.setVoteFormErrorsMap(hasError, parameterId, attributeId);

        Object.values(questionItemsGroup.controls).forEach(voteItemControl => {
            const attributeControl = voteItemControl.get(attributeId);

            if (attributeControl && !hasError) {
                attributeControl.setErrors(null);
            }
        });

        return hasError ? topXError : null;
    };
};
