import { Injectable } from '@angular/core';
import { AngularFireStorage, AngularFireStorageReference, AngularFireUploadTask } from '@angular/fire/compat/storage';
import { firstValueFrom, Observable } from 'rxjs';
import { finalize, take } from 'rxjs/operators';

import { Dictionary } from '@accenture/shared/data';
import { FirestoreService } from './firestore.service';

export interface FileUploadMonitor {
    task: AngularFireUploadTask;
    fileRef: AngularFireStorageReference;
    fileId: string;
}

export class FileUploadMonitorStatus {
    monitor: FileUploadMonitor;
    uploadPercent: Observable<number | undefined>;
    downloadUrl: string;
    filename: string;
    id: string;

    constructor(monitor: FileUploadMonitor, filename: string, callback: () => void) {
        this.monitor = monitor;
        this.filename = filename;
        this.uploadPercent = this.monitor.task.percentageChanges();
        this.id = monitor.fileId;
        this.downloadUrl = '';

        this.monitor.task
            .snapshotChanges()
            .pipe(
                finalize(async () => {
                    this.downloadUrl = await firstValueFrom(this.monitor.fileRef.getDownloadURL().pipe(take(1)));
                    // call function that tracking uploading
                    callback();
                }),
            )
            .subscribe();
    }
}

@Injectable({
    providedIn: 'root',
})
export class FileUploadService {
    constructor(private firestoreService: FirestoreService, private firestoreStorage: AngularFireStorage) {}

    getFileUploadMonitor(file: File, filePath: string): FileUploadMonitor {
        return {
            fileRef: this.firestoreStorage.ref(filePath),
            task: this.firestoreStorage.upload(filePath, file),
            fileId: this.firestoreService.getPushId(),
        };
    }

    deleteFile(downloadUrl: string): Promise<void> {
        return this.firestoreStorage.storage.refFromURL(downloadUrl).delete();
    }

    uploadFile(filePath: string, file: File, customMetadata?: Dictionary<string>): Promise<string> {
        return this.firestoreStorage
            .upload(filePath, file, { customMetadata })
            .then(async ({ ref }) => ref.getDownloadURL());
    }
}
