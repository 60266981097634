import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { SessionEffects } from './session.effects';
import { SESSION_FEATURE_KEY, sessionReducer } from './session.reducer';
import { SessionDataService } from './session-data.service';

@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        StoreModule.forFeature(SESSION_FEATURE_KEY, sessionReducer),
        EffectsModule.forFeature([SessionEffects]),
    ],
    exports: [],
    providers: [SessionDataService],
})
export class SessionModule {}
