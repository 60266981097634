import { ActivityType } from './activities';

export enum AiProvider {
    OpenAi = 'OPEN_AI',
    AzureOpenAi = 'AZURE_OPEN_AI',
    ChatOpenAi = 'CHAT_OPEN_AI',
}

export enum AIPromptType {
    CreateProject = 'CREATE_PROJECT_PROMPT',
    CreateSession = 'CREATE_SESSION_PROMPT',
    CreateActivity = 'CREATE_ACTIVITY_PROMPT',

    AnalyzeBrainstorm = 'ANALYZE_BRAINSTORM_PROMPT',
    AnalyzeBrainstormResponses = 'ANALYZE_BRAINSTORM_RESPONSES_PROMPT',
    AnalyzePresent = 'ANALYZE_PRESENT_PROMPT',
    AnalyzeVote = 'ANALYZE_VOTE_PROMPT',
    AnalyzeQuickPoll = 'ANALYZE_POLL_PROMPT',
    AnalyzeTable = 'ANALYZE_TABLE_PROMPT',

    AutobuildProject = 'AUTOBUILD_PROJECT_PROMPT',
    AutobuildSession = 'AUTOBUILD_SESSION_PROMPT',
    AutobuildActivity = 'AUTOBUILD_ACTIVITY_PROMPT',
    AutobuildBrainstormTopic = 'AUTOBUILD_BRAINSTORM_TOPIC_PROMPT',
    AutobuildVoteBallotItem = 'AUTOBUILD_VOTE_BALLOT_ITEM_PROMPT',
    AutobuildVoteCriteria = 'AUTOBUILD_VOTE_CRITERIA_PROMPT',
    AutobuildPollQuestion = 'AUTOBUILD_POLL_QUESTION_PROMPT',
    AutobuildTableTable = 'AUTOBUILD_TABLE_TABLE_PROMPT',
    AutobuildPresentationSlides = 'AUTOBUILD_PRESENTATION_SLIDES_PROMPT',

    SummarizeProject = 'SUMMARIZE_PROJECT',
    SummarizeSession = 'SUMMARIZE_SESSION',
    SummarizeActivity = 'SUMMARIZE_ACTIVITY',
    SummarizeMinutesOfMeeting = 'SUMMARIZE_MINUTES_OF_MEETING',
    SummarizeMinutesOfMeetingPDF = 'SUMMARIZE_MINUTES_OF_MEETING_PDF',

    SuggestBrainstormGroups = 'SUGGEST_BRAINSTORM_GROUPS_PROMPT',
}

export enum AIPromptInstructionType {
    RulesAncConcepts = 'RULES_AND_CONCEPTS',
    ActivityDescriptionBrainstorm = 'ACTIVITY_DESCRIPTION_BRAINSTORM',
    ActivityDescriptionVote = 'ACTIVITY_DESCRIPTION_VOTE',
    ActivityDescriptionQuickPoll = 'ACTIVITY_DESCRIPTION_POLL',
    ActivityDescriptionPresent = 'ACTIVITY_DESCRIPTION_PRESENT',
    ActivityDescriptionTable = 'ACTIVITY_DESCRIPTION_TABLE',
}

export enum AIPromptInstructionGroupType {
    ActivityDescription = 'ACTIVITY_DESCRIPTION',
}

export interface AIPrompt {
    id?: string;
    prompt?: string;
    systemMessage?: string;
    provider?: AiProvider; // default AiProvider.AzureOpenAi
    type?: AIPromptType;
    instructions?: AIPromptInstructionType[];
    temperature?: number;
    modelName?: string;
    requiredData?: string[];
    apiVersion?: string;
    instanceName?: string;
    embeddingsDeploymentName?: string;
    deploymentName?: string;
}

export interface AIPromptInstruction {
    id?: string;
    prompt: string;
    type: AIPromptInstructionType;
    group?: AIPromptInstructionGroupType;

    // for activities instructions
    activityType?: ActivityType;
}

export interface AnalyzePrompt extends AIPrompt {
    display: string;
}
