<ng-template
  cdkConnectedOverlay
  [cdkConnectedOverlayOrigin]="panelTrigger"
  [cdkConnectedOverlayOpen]="panelOpened"
  [cdkConnectedOverlayWidth]="customWidth ? customWidth + 'px' : '447px'"
  [cdkConnectedOverlayDisableClose]="overlayDisableClose"
  [cdkConnectedOverlayPanelClass]="'tt9-floating-side-panel-container'"
  [cdkConnectedOverlayHeight]="customHeight ? customHeight + 'px' : '88vh'"
  (detach)="closePanel.emit()"
>
  <div
    [class]="'tt9-floating-side-panel'"
    accentureClickOutside
    [accentureOutsideDisabled]="outsideClickDisabled"
    (accentureClickOutside)="closePanel.emit()"
  >
    <div
      *ngIf="useDefaultTemplate"
      class="tt9-floating-side-panel-header prevent-outside-click"
      [class.secondary]="secondaryHeader"
    >
      <button
        *ngIf="showBackArrow"
        class="tt9-btn-icon-filled mr-8"
        (click)="$event.stopPropagation(); navigateBack.emit()"
      >
        <accenture-icon>icon-chevron-left</accenture-icon>
      </button>

      <div class="d-flex">
        <accenture-icon [size]="iconSize.Medium" [customColor]="'#B1AAFF'">icon-comment</accenture-icon>
        <p>{{ panelTitle }}</p>
      </div>
      <div class="spacer"></div>

      <ng-content select="[header-actions]"></ng-content>

      <button
        *ngIf="!hideCloseBtn"
        class="tt9-btn-icon-filled"
        [ngClass]="{
          'active secondary-btn': secondaryHeader
        }"
        (click)="closePanel.emit()"
      >
        <accenture-icon>icon-dash</accenture-icon>
      </button>
    </div>

    <div
      [ngClass]="{
        'tt9-floating-side-panel-body': useDefaultTemplate,
        'empty-template': !useDefaultTemplate
      }"
    >
      <ng-content></ng-content>
    </div>
  </div>
</ng-template>
