<ng-container *ngIf="vm$ | async as vm">
  <ng-container *accentureLoaded="!vm.isLoading; description: loaderText">
    <accenture-base-dialog [title]="data.createOnlyProject ? data.title : vm.title" [subTitle]="vm.subTitle">
      <div class="content-container">
        <div class="project-create-container">
          <ng-container *ngIf="!vm.currentOptionToDisplay">
            <div class="project-image">
              <accenture-image-input
                [mode]="imageInputMode.Default"
                [imagePlaceholder]="imageInputPlaceholder"
                [file]="vm.projectImage"
                (fileUploaded)="setProjectImage($event)"
                (fileDeleted)="deleteProjectImage()"
              ></accenture-image-input>
            </div>

            <form [formGroup]="projectForm">
              <mat-form-field appearance="outline" floatLabel="always" class="tt9-form-field no-margin width-100 mb-32">
                <mat-label>Project name</mat-label>
                <input
                  #inputName
                  matInput
                  required
                  formControlName="name"
                  placeholder="Name*"
                  [maxLength]="characterLimitMedium"
                />
                <mat-hint
                  class="mat-hint-limit"
                  [class]="determineHintClass(inputName.value.length, characterLimitMedium)"
                  >{{ characterLimitText(inputName.value.length, characterLimitMedium) }}</mat-hint
                >
                <mat-error>Name is required</mat-error>
              </mat-form-field>

              <mat-form-field
                appearance="outline"
                floatLabel="always"
                class="tt9-form-field no-label no-margin width-100 mb-24"
              >
                <input
                  #inputDescription
                  matInput
                  formControlName="description"
                  placeholder="Description"
                  [maxLength]="descriptionCharacterLimit"
                />
                <mat-hint
                  class="mat-hint-limit"
                  [class]="determineHintClass(inputDescription.value.length, descriptionCharacterLimit)"
                  >{{ characterLimitText(inputDescription.value.length, descriptionCharacterLimit) }}</mat-hint
                >
              </mat-form-field>
            </form>
          </ng-container>

          <accenture-project-options [isCreateProjectModal]="true"></accenture-project-options>
        </div>

        <div class="footer d-flex-end">
          <button class="tt9-btn-default btn-icon" (click)="openUseTemplateModal(!!vm.currentOptionToDisplay)">
            <accenture-icon>icon-chevron-left-small</accenture-icon>
            <span>Back</span>
          </button>

          <button
            *ngIf="!vm.currentOptionToDisplay"
            class="tt9-btn ml-16"
            [disabled]="projectForm.controls.name.invalid || !vm.isClientSelected"
            (click)="createProject(vm.user, vm.projectImage)"
          >
            {{ vm.createProjectButtonName }}
          </button>
        </div>
      </div>
    </accenture-base-dialog>
  </ng-container>
</ng-container>
