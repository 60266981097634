import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { groupBy, mapValues } from 'lodash';
import { combineLatest, Observable, of } from 'rxjs';
import { map, startWith, switchMap } from 'rxjs/operators';

import { ActivityItemService, ActivityService } from '@accenture/activity/shared/domain';
import { DataFlowService, SessionFocusService } from '@accenture/erp-deployment/shared/domain';
import {
    AppState,
    selectActivityConfigurationsMap,
    selectActivityIdAndParentIds,
    selectSessions,
} from '@accenture/global-store';
import {
    Activity,
    ActivityConfigurationsMap,
    ActivityType,
    BrainstormTopic,
    DataFlowConnection,
    Dictionary,
    FileType,
    ParentType,
    projectParentTypes,
    SessionFocus,
    Table,
} from '@accenture/shared/data';

export interface ActivityPreviewViewModel {
    activity: Activity;
    connections: Dictionary<DataFlowConnection[]>;
    content: string[];
    activityConfigurationsMap: ActivityConfigurationsMap;
    activitiesById: Dictionary<Activity>;
    sessionNamesById: Dictionary<string>;
    isLoading: boolean;
    isFocused: boolean;
}

const defaultViewModel = {
    activity: {} as Activity,
    connections: {} as Dictionary<DataFlowConnection[]>,
    content: [],
    activityConfigurationsMap: {},
    sessionNamesById: {} as Dictionary<string>,
    activitiesById: {} as Dictionary<Activity>,
    isLoading: true,
    isFocused: false,
};

@Injectable()
export class ActivityPreviewFacade {
    private readonly projectParentTypes = projectParentTypes;

    vm$ = activityId => {
        return this.buildViewModel(activityId);
    };

    constructor(
        private store: Store<AppState>,
        private activityService: ActivityService,
        private dataFlowService: DataFlowService,
        private activityItemsService: ActivityItemService,
        private sessionFocusService: SessionFocusService,
        private router: Router,
    ) {}

    private buildViewModel(previewActivityId): Observable<ActivityPreviewViewModel> {
        return combineLatest([
            this.store.select(selectActivityConfigurationsMap),
            this.store.select(selectActivityIdAndParentIds),
        ]).pipe(
            switchMap(([activityConfigurationsMap, { parentType, parentId, sessionId, templateId }]) => {
                return combineLatest([
                    this.activityService.getActivity(parentType, parentId, previewActivityId),
                    this.dataFlowService.getDataFlowConnectionsByProperty(
                        parentType,
                        parentId,
                        'destinationSessionId',
                        sessionId || templateId,
                    ),
                    this.activityItemsService.getActivityItems<BrainstormTopic | Table>(
                        parentType,
                        parentId,
                        previewActivityId,
                    ),
                    this.projectParentTypes.includes(parentType) ? this.store.select(selectSessions) : of([]),
                    this.activityService.getActivities(parentType, parentId),
                    parentType === ParentType.Projects
                        ? this.sessionFocusService.getSessionFocus(parentId, sessionId)
                        : of({} as SessionFocus),
                ]).pipe(
                    map(([activity, connections, activityItems, sessions, activities, sessionFocus]) => {
                        const sessionNamesById = mapValues(groupBy(sessions, 'id'), sessions => sessions[0].name);
                        const activitiesById = mapValues(groupBy(activities, 'id'), activity => activity[0]);
                        const isFocused = activity?.id === sessionFocus?.activityId;
                        const showContent = [
                            ActivityType.Brainstorm,
                            ActivityType.Table,
                            ActivityType.LiveShare,
                        ].includes(activity?.type);
                        const content = showContent
                            ? activityItems.map(item => item['title'] ?? item['url'] ?? item['label']['default'])
                            : [];

                        return {
                            activity,
                            activityConfigurationsMap,
                            content,
                            sessionNamesById,
                            activitiesById,
                            isFocused,
                            isLoading: false,
                            connections: groupBy(connections, 'destinationId'),
                        };
                    }),
                    startWith(defaultViewModel),
                );
            }),
        );
    }

    downloadFile(event: MouseEvent, { id }: FileType): void {
        event.stopPropagation();
        const url = this.router.serializeUrl(
            this.router.createUrlTree(['/file-access'], { queryParams: { fileId: id } }),
        );
        window.open(url, '_blank');
    }
}
