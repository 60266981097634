import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Inject,
    inject,
    Input,
    Output,
    ViewChild,
} from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import { fromToDateValidator } from '@accenture/erp-deployment/shared/domain';
import {
    characterLimitMedium,
    characterLimitText,
    characterLimitXL,
    determineHintClass,
    FileType,
    inputPlaceholders,
    SessionForm,
    SessionOptions,
    sessionOptionsDialogTitle,
    validationErrors,
    validationMessages,
} from '@accenture/shared/data';
import { ImageInputMode, ImageInputPlaceholdersTypes } from '@accenture/shared/ui';

import { SessionOptionsComponent } from '../session-options/session-options.component';
import { SessionFormFacade } from './session-form-facade';

@Component({
    selector: 'accenture-session-form',
    templateUrl: './session-form.component.html',
    styleUrls: ['./session-form.component.scss'],
    providers: [SessionFormFacade],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SessionFormComponent {
    private facade = inject(SessionFormFacade);

    @Input() sessionForm: SessionForm;
    @Output() onClose = new EventEmitter<void>();
    @Output() onSave = new EventEmitter<void>();
    @ViewChild('sessionOptionForm') sessionOptionForm: SessionOptionsComponent;

    vm$ = this.facade.vm$;

    imageInputMode = ImageInputMode.Default;
    imageInputPlaceholder = ImageInputPlaceholdersTypes.Session;

    validationErrors = validationErrors;
    validationMessages = validationMessages;
    inputPlaceholders = inputPlaceholders;

    characterLimitMedium = characterLimitMedium;
    descriptionCharacterLimit = characterLimitXL;
    characterLimitText = characterLimitText;
    determineHintClass = determineHintClass;

    constructor(
        @Inject(MAT_DIALOG_DATA)
        public data: {
            title: string;
            cancelButtonText: string;
            cancelButtonIcon?: string;
            backToSelectSourceTypeDialog: boolean;
        },
    ) {}

    get nameControl(): FormControl<string> {
        return this.sessionForm.controls.name;
    }

    get descriptionControl(): FormControl<string> {
        return this.sessionForm.controls.description;
    }

    get imageControl(): FormControl<FileType> {
        return this.sessionForm.controls.image;
    }

    get startDateControl(): FormControl<Date | null> {
        return this.sessionForm.controls.startDate;
    }

    get endDateControl(): FormControl<Date | null> {
        return this.sessionForm.controls.endDate;
    }

    get isEndDateGreaterStartDateErrorVisibility(): boolean {
        const toDateControl = this.sessionForm && this.endDateControl;
        return toDateControl && toDateControl.hasError('invalidDate');
    }

    get isFormValid(): boolean {
        return !this.sessionForm.invalid && this.sessionOptionForm?.isSessionOptionFormValid;
    }

    clearDateInput(controlName: string, event: Event): void {
        event.stopPropagation();
        this.sessionForm.get(controlName).reset();
    }

    getTitleToDisplay(currentOptionToDisplay: SessionOptions | null): string {
        return currentOptionToDisplay ? sessionOptionsDialogTitle[currentOptionToDisplay] : this.data.title;
    }

    setSessionImage(image: FileType): void {
        this.sessionForm.controls.image.setValue(image);
    }

    deleteSessionImage(): void {
        this.sessionForm.controls.image.setValue({} as FileType);
    }

    backToSessionForm(): void {
        this.facade.backToSessionForm();
    }

    closeDialog(): void {
        this.onClose.emit();
    }

    updateSessionForm(): void {
        // this.facade.updateSessionForm();
    }

    ngOnDestroy(): void {
        this.facade.resetOptions();
    }
}
