import { createAction, props } from '@ngrx/store';

import { ParentType, TeamMember } from '@accenture/shared/data';

export const getParentTeamMember = createAction(
    '[Templates] Get Team Member by Parent',
    props<{ parentType: ParentType; parentId: string }>(),
);

export const getParentTeamMemberSuccess = createAction(
    '[Templates] Get Parent Team Member Success',
    props<{ teamMember: TeamMember }>(),
);

export const getParentTeamMemberError = createAction('[Templates] Get Parent Team Member Error');

export const setPublicTeamMemberOnlineState = createAction(
    '[Templates] Set Public Template Team Member Online',
    props<{ parentType: ParentType; isOnline: boolean; templateId: string }>(),
);

export const publicTeamMemberOnlineStateChangedSuccess = createAction(
    '[Templates] Team Member Online State Changed Success',
);

export const publicTeamMemberOnlineStateChangedError = createAction(
    '[Templates] Team Member Online State Changed Error',
);

export const clearPublicTemplateStore = createAction('[Templates] Clear Templates Store');
