import { Question } from '../question';
import { ActivityItemType } from './activity-item-type';

export class SliderOptions {
    actionScale?: SliderActionScale;
    min?: number;
    max?: number;
    step?: number;
    labels?: string[];
    defaultResponse?: number;

    constructor(data: Partial<SliderOptions>) {
        this.actionScale = data.actionScale ?? sliderOptionsDefaultValues.actionScale;
        this.min = data.min ?? sliderOptionsDefaultValues.min;
        this.max = data.max ?? sliderOptionsDefaultValues.max;
        this.step = data.step ?? sliderOptionsDefaultValues.step;
        this.labels = data.labels ?? sliderOptionsDefaultValues.labels;
        this.defaultResponse = data.defaultResponse ?? sliderOptionsDefaultValues.defaultResponse;
    }

    createSerializableObject(): Record<string, unknown> {
        return {
            actionScale: this.actionScale,
            min: this.min,
            max: this.max,
            step: this.step,
            labels: this.labels,
            defaultResponse: this.defaultResponse,
        };
    }
}

export enum SliderActionScale {
    Scale2 = 2,
    Scale3 = 3,
    Scale4 = 4,
    Scale5 = 5,
}

export const sliderOptionsDefaultValues = {
    actionScale: SliderActionScale.Scale2,
    min: 0,
    max: 100,
    step: 1,
    defaultValues: false,
    defaultResponse: 0,
    labels: [],
};

export const sliderActionScales = [
    SliderActionScale.Scale2,
    SliderActionScale.Scale3,
    SliderActionScale.Scale4,
    SliderActionScale.Scale5,
];

export class Slider extends Question {
    type = ActivityItemType.Slider;

    options: SliderOptions;

    constructor(data: any) {
        super(data);

        const options = data.options ?? {};
        this.options = new SliderOptions(options);
    }

    createSerializableObject(): Record<string, unknown> {
        return {
            ...super.createSerializableObject(),
            options: this.options.createSerializableObject(),
        };
    }
}
