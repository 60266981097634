import { AbstractControl } from '@angular/forms';

import { EMAIL_REGEX } from '@accenture/erp-deployment/shared/domain';

export function emailValidation(
    control: AbstractControl,
    teamMembersEmails: string[],
    chipsEmails: string[],
    isRegistrationEnabled: boolean,
): { errorEmails: string[]; correctEmails: string[]; invalidEmail: boolean; nonAcnEmail: boolean; sameEmail: boolean } {
    if (!control.value) {
        return;
    }
    const errorEmails = [];
    const correctEmails = [];

    let invalidEmail;
    let nonAcnEmail;
    let sameEmail;
    let emailValue = control.value.trim();
    emailValue = [/,|;|>|</g, /(\s+)/g].reduce((emailString, regex) => emailString.replace(regex, ' '), emailValue);
    emailValue = emailValue.toLowerCase();
    const emailValues = emailValue.split(' ').filter((email: string) => email.includes('@'));

    emailValues.forEach(email => {
        if (!teamMembersEmails.includes(email) && !chipsEmails.includes(email)) {
            if (!email.match(EMAIL_REGEX)) {
                invalidEmail = true;
                errorEmails.push(email);
                return;
            }

            if (!isRegistrationEnabled && !email.includes('@accenture.com')) {
                nonAcnEmail = true;
                errorEmails.push(email);
                return;
            }

            correctEmails.push(email);
        } else {
            if (chipsEmails.includes(email)) {
                sameEmail = true;
            }

            errorEmails.push(email);
        }
    });

    return {
        errorEmails,
        correctEmails,
        invalidEmail,
        nonAcnEmail,
        sameEmail,
    };
}
