import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { DBPathHelper, PublicAccess } from '@accenture/shared/data';
import { FirestoreService } from '@accenture/shared/data-access';

@Injectable({
    providedIn: 'root',
})
export class PublicAccessService {
    constructor(private firestoreService: FirestoreService) {}

    getByDraftTemplateId(draftTemplateId: string): Observable<PublicAccess | undefined> {
        return this.firestoreService
            .getDocumentsByProperty<PublicAccess>(
                DBPathHelper.getPublicAccessPath(),
                'draftTemplateId',
                draftTemplateId,
            )
            .pipe(map(publicAccessArray => publicAccessArray?.[0] || undefined));
    }

    getPublicAccessByTemplateId(templateId: string): Observable<PublicAccess | undefined> {
        return this.firestoreService
            .getDocumentsByPropertyWithoutCaching<PublicAccess | undefined>('publicAccess', 'templateId', templateId)
            .pipe(map((accesses: PublicAccess[]) => accesses[0]));
    }

    async updateWithMerge(id: string, data: Partial<PublicAccess>): Promise<void> {
        const path = `${DBPathHelper.getPublicAccessPath()}/${id}`;

        await this.firestoreService.update(path, {
            ...this.firestoreService.replaceEmptyFields(data),
            updated: this.firestoreService.timestamp,
        });
    }

    async removeDraftTemplateId(id: string): Promise<void> {
        await this.firestoreService.update(DBPathHelper.getPublicAccessPath(id), {
            ...this.firestoreService.replaceEmptyFields({ draftTemplateId: null } as PublicAccess),
        });
    }

    async sendNotificationsAfterPublishChanges(accessData: PublicAccess, userId: string): Promise<void> {
        const env = window.location.origin;

        await this.firestoreService.cloudFunctionCallable('sendNotificationsOnPublishTemplateChanges', {
            env,
            accessData,
            userId
        });
    }
}
