import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';

import { inputPlaceholders } from '@accenture/activity/shared/domain';
import {
    emptyScreenImageUrlSessions,
    emptyScreenTitles,
    noItemsFoundImageUrl,
    UserSession,
} from '@accenture/shared/data';
import { trackById } from '@accenture/shared/util';

import { SelectedSession } from '../add-existing-session-dialog/add-existing-session-dialog-facade';

@Component({
    selector: 'accenture-add-existing-session-table',
    templateUrl: './add-existing-session-table.component.html',
    styleUrls: ['./add-existing-session-table.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddExistingSessionTableComponent {
    @Input() userSessions: UserSession[];
    @Input() selectedSessionsMapIds: [] = [];
    @Input() isAllSelected = false;

    @Output() searchValue = new EventEmitter<string>();
    @Output() selectedSession = new EventEmitter<SelectedSession>();
    @Output() removeAllSession = new EventEmitter();
    @Output() addAllSession = new EventEmitter();

    searchControl = new FormControl<string>('', { nonNullable: true });
    trackById = trackById;
    inputPlaceholders = inputPlaceholders;
    emptyScreenTitles = emptyScreenTitles;
    emptyScreenImageUrlSessions = emptyScreenImageUrlSessions;
    noItemsFoundImageUrl = noItemsFoundImageUrl;

    emitSearchValue(value: string): void {
        const searchValue = value.toLowerCase();

        this.searchControl.patchValue(searchValue);
        this.searchValue.emit(searchValue);
    }

    resetSearchValue(): void {
        this.searchControl.patchValue('');
        this.emitSearchValue('');
        this.removeAllSession.emit();
    }

    toggleSessionSelection(userSession: UserSession): void {
        this.selectedSession.emit({
            sessionId: userSession.sessionId,
            id: userSession.id,
        });
    }

    toggleAllSessionSelection({ checked }: { checked: boolean }): void {
        if (checked) {
            this.addAllSession.emit();
        } else {
            this.removeAllSession.emit();
        }
    }
}
