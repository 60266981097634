import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, Input } from '@angular/core';
import { RouterLink } from '@angular/router';

import { Activity, Dictionary, ParentType, routerLinksMap } from '@accenture/shared/data';
import { SharedMaterialModule } from '@accenture/shared/material';

import { BreadcrumbsFacade } from './breadcrumbs-facade';

@Component({
    selector: 'accenture-breadcrumbs',
    standalone: true,
    templateUrl: './breadcrumbs.component.html',
    styleUrls: ['./breadcrumbs.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [BreadcrumbsFacade],
    imports: [CommonModule, RouterLink, SharedMaterialModule],
})
export class BreadcrumbsComponent {
    @Input() postfix?: string;

    private readonly breadcrumbsDisplayLimit: number = 9;
    private readonly facade: BreadcrumbsFacade = inject(BreadcrumbsFacade);

    readonly linkSymbolLimit: number = 15;
    isTooltipHidden = true;
    vm$ = this.facade.vm$;

    sessionLinksMap: Dictionary<(sessionId: string) => string[]> = {
        [ParentType.Templates]: (sessionId: string) => ['/template', `${sessionId}`],
        [ParentType.PublicSessionTemplates]: (sessionId: string) => ['/public-session-template', `${sessionId}`],
    };

    isDisabledTooltip(name: string): void {
        this.isTooltipHidden = name?.length <= this.breadcrumbsDisplayLimit;
    }

    getProjectLink(parentType: ParentType, parentId: string | undefined): string[] {
        return ['/' + routerLinksMap[parentType], `${parentId}`];
    }

    getSessionLink(parentType: ParentType, parentId: string | undefined, sessionId: string | undefined): string[] {
        const sessionPath = parentType === ParentType.Projects ? 'session' : 'session-template';
        return ![ParentType.Templates, ParentType.PublicSessionTemplates].includes(parentType)
            ? ['/' + routerLinksMap[parentType], `${parentId}`, sessionPath, `${sessionId}`]
            : this.sessionLinksMap[parentType](sessionId as string);
    }

    getActivityLink(
        parentType: ParentType,
        parentId: string | undefined,
        sessionId: string | undefined,
        activity: Activity | undefined,
    ): (string | undefined)[] {
        const sessionLink = this.getSessionLink(parentType, parentId, sessionId);
        return activity ? [...sessionLink, activity.type?.toLowerCase(), activity.id] : sessionLink;
    }

    sliceTextWithEllipsis(text: string, limit: number): string {
        return text?.length > limit ? `${text.slice(0, limit)}…` : text;
    }

    getUserLink(userId: string | undefined): string[] {
        return [`/admin/user/${userId}`];
    }

    getUserProjectLink(userId: string | undefined, projectId: string | undefined): string[] {
        return [`/admin/user/${userId}/project/${projectId}`];
    }

    getAdministrationProjectLink(projectId: string): string[] {
        return [`/admin/projects/${projectId}`];
    }
}
