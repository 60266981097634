import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'highlight',
})
export class HighlightPipe implements PipeTransform {
    transform(value: string, highlight: string): unknown {
        if (!highlight) {
            return value;
        }
        const re = new RegExp(highlight.toLocaleLowerCase(), 'igm');
        return (value || '').replace(re, '<span class="text-highlighted">$&</span>');
    }
}
