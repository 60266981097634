import { OverlayRef } from '@angular/cdk/overlay';

export class TooltipRef {
    constructor(public overlayRef: OverlayRef) {}

    addPanelClass(className: string): void {
        this.overlayRef.addPanelClass(className);
    }

    close(): void {
        this.overlayRef.dispose();
    }
}
