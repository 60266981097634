/* eslint-disable max-len */
import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

import { ParentType } from '@accenture/shared/data';
import { DialogService } from '@accenture/shared/ui';

import { AddActivityTemplateToSessionDialogTT9Component } from '../add-activity-template-to-session-dialog-tt9/add-activity-template-to-session-dialog-tt9.component';
import { UseTemplateStore } from '../component-stores/use-template.store';
import { CreateSessionDialogTT9Component } from '../create-session-dialog-tt9/create-session-dialog-tt9.component';
import { useTemplateActivityTitle, useTemplateSessionTitle } from './constants';

export interface UseTemplateDialogViewModel {
    useTemplateId: string;
    title: string;
    isLoading: boolean;
}

const defaultViewModel: UseTemplateDialogViewModel = {
    useTemplateId: '',
    title: '',
    isLoading: true,
};

@Injectable()
export class UseTemplateDialogTT9Facade {
    private isLoading$ = new BehaviorSubject<boolean>(false);

    vm$ = this.buildViewModel();

    constructor(private useTemplateStore: UseTemplateStore, private dialogService: DialogService) {}

    closeDialog(): void {
        this.dialogService.close();
    }

    openCreateSessionDialog(): void {
        this.dialogService.open(CreateSessionDialogTT9Component, {
            width: '768px',
            cancelButtonText: 'Back',
            cancelButtonIcon: 'icon-chevron-left',
            panelClass: 'tt9-modal',
            backToSelectSessionTypeDialog: true,
        });
    }

    openAddSessionDialog(): void {
        this.dialogService.open(AddActivityTemplateToSessionDialogTT9Component, {
            width: 'auto',
            panelClass: 'tt9-modal',
        });
    }

    private buildViewModel(): Observable<UseTemplateDialogViewModel> {
        return combineLatest([
            this.useTemplateStore.useTemplateType$,
            this.useTemplateStore.useTemplateId$,
            this.isLoading$,
        ]).pipe(
            map(([useTemplateType, useTemplateId, isLoading]) => {
                const title = [ParentType.ActivityTemplates, ParentType.PublicActivityTemplates].includes(
                    useTemplateType,
                )
                    ? useTemplateActivityTitle
                    : useTemplateSessionTitle;
                return {
                    useTemplateId,
                    title,
                    isLoading,
                };
            }),
            startWith(defaultViewModel),
        );
    }
}
