import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { distinctUntilChanged, map, startWith } from 'rxjs/operators';

import { BotName, FeatureToggleName, fileDownload } from '@accenture/erp-deployment/shared/domain';
import {
    AppState,
    selectActivityId,
    selectAuthenticatedUser,
    selectFeatureToggle,
    selectParentTeamMember,
    selectProjectId,
    selectSessionId,
    selectSessionTeamMember,
} from '@accenture/global-store';
import { BotActions, errorSnackbarText, inputPlaceholders, NodeType } from '@accenture/shared/data';
import { FirestoreService } from '@accenture/shared/data-access';
import { AiPromptEntryDialogComponent } from '@accenture/shared/shared-session';
import { DialogService, SnackbarService, SnackBarTypes } from '@accenture/shared/ui';

export interface AiSummarizeButtonModel {
    available: boolean;
    isLoading: boolean;
}

export const initialState: AiSummarizeButtonModel = {
    available: false,
    isLoading: false,
};

@Injectable()
export class AiSummarizeButtonFacade {
    private isLoading$ = new BehaviorSubject<boolean>(false);
    userId?: string;
    projectId?: string;
    sessionId?: string;
    activityId?: string;
    vm$ = this.buildViewModel();

    constructor(
        private store: Store<AppState>,
        private dialogService: DialogService,
        private snackbarService: SnackbarService,
        private fireStoreService: FirestoreService,
    ) {}

    getType(): string {
        const { sessionId, activityId } = this;
        return activityId ? 'Activity' : sessionId ? 'Session' : 'Project';
    }

    private getPlaceholder(type: NodeType): string {
        switch (type) {
            case NodeType.Project:
                return inputPlaceholders.projectSummarizePrompt;
            case NodeType.Session:
                return inputPlaceholders.sessionSummarizePrompt;
            case NodeType.Activity:
                return inputPlaceholders.activitySummarizePrompt;
            default:
                return '';
        }
    }

    summarize(): void {
        const type = this.getType().toLowerCase();
        const placeholder = this.getPlaceholder(type as NodeType);

        this.dialogService.open(AiPromptEntryDialogComponent, {
            placeholder,
            title: `Summarize ${type}`,
            header: inputPlaceholders.headerSummarize,
            panelClass: 'tt9-modal',
            width: '768px',
            confirm: 'Generate',
            onAccept: prompt => this.generateSummary(prompt),
            isGenerateActivity: false
        });
    }

    private buildViewModel(): Observable<AiSummarizeButtonModel> {
        return combineLatest([
            this.store.select(selectFeatureToggle(FeatureToggleName.AiSummarizeAvailable)),
            this.store.select(selectAuthenticatedUser),
            this.store.select(selectSessionTeamMember),
            this.store.select(selectParentTeamMember),
            this.store.select(selectProjectId),
            this.store.select(selectSessionId),
            this.store.select(selectActivityId),
            this.isLoading$.asObservable().pipe(distinctUntilChanged()),
        ]).pipe(
            map(([enabled, user, sessionAccess, projectAccess, projectId, sessionId, activityId, isLoading]) => {
                this.userId = user.id;

                const { isProjectAdmin } = projectAccess;
                const { isSessionLeader } = sessionAccess;

                this.projectId = projectId;
                this.sessionId = sessionId;
                this.activityId = activityId;

                return {
                    available: enabled && (isSessionLeader || isProjectAdmin),
                    isLoading,
                };
            }),
            startWith(initialState),
        );
    }

    private async generateSummary(prompt: string): Promise<void> {
        try {
            const type = this.getType();
            this.isLoading$.next(true);
            this.snackbarService.showSnackBar(
                `${type} summary report in progress`,
                `${type} summary report is being created by AI`,
                SnackBarTypes.Info,
                false,
            );
            const { userId, projectId, sessionId, activityId } = this;
            const action = activityId
                ? BotActions.SummarizeActivity
                : sessionId
                ? BotActions.SummarizeSession
                : BotActions.SummarizeProject;

            const result = await this.fireStoreService.cloudFunctionCallable<any>('aiSummarize', {
                prompt,
                userId,
                projectId,
                sessionId,
                activityId,
                action,
            });

            if (result) {
                const { notification, fileUrl } = result;
                fileDownload(fileUrl);
                this.snackbarService.showSuccessSnackBar(
                    `${type} summary report created`,
                    `${type} summary report has been generated.`,
                );
            } else {
                this.snackbarService.showErrorSnackBar(`${type} summary creation error`, errorSnackbarText);
            }
        } catch (e) {
            console.error(e);
            this.snackbarService.showErrorSnackBar(`${this.getType()} summary creation error`, errorSnackbarText);
        } finally {
            this.isLoading$.next(false);
        }
    }
}
