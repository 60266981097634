import { ChangeDetectionStrategy, Component, Inject, inject, OnDestroy } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { tap } from 'rxjs';

import { fromToDateValidator } from '@accenture/erp-deployment/shared/domain';
import { Session, SessionForm, SessionOptions, sessionOptionsDialogTitle } from '@accenture/shared/data';
import { LoadedDescription } from '@accenture/shared/ui';

import { EditSessionDialogNewFacade } from './edit-session-dialog-new-facade';

@Component({
    selector: 'accenture-edit-session-new-dialog',
    templateUrl: './edit-session-dialog-new.component.html',
    styleUrls: ['./edit-session-dialog-new.component.scss'],
    providers: [EditSessionDialogNewFacade],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditSessionDialogNewComponent implements OnDestroy {
    private facade = inject(EditSessionDialogNewFacade);
    private formBuilder = inject(FormBuilder);

    vm$ = this.facade.vm$.pipe(
        tap(({ session }) => {
            if (this.sessionForm && session?.id && !this.sessionForm.dirty) {
                this.sessionForm.patchValue({
                    name: session.name,
                    description: session.description,
                    image: {
                        id: session.imageId,
                        url: session.imageUrl,
                        sequence: '',
                    },
                    startDate: session.startDate?.toDate(),
                    endDate: session.endDate?.toDate(),
                });
            }
        }),
    );

    loaderDescription = LoadedDescription.SessionCreating.toUpperCase();

    sessionForm: SessionForm = this.formBuilder.group(
        {
            name: new FormControl('', { nonNullable: true, validators: Validators.required }),
            description: new FormControl('', { nonNullable: true }),
            startDate: new FormControl(null),
            endDate: new FormControl(null),
            image: new FormControl(null),
        },
        {
            updateOn: 'change',
            validators: fromToDateValidator('startDate', 'endDate'),
        },
    );

    constructor(
        @Inject(MAT_DIALOG_DATA)
        public data: {
            title: string;
            cancelButtonText: string;
            cancelButtonIcon?: string;
            backToSelectSourceTypeDialog: boolean;
        },
    ) {}

    getTitle(currentOption: SessionOptions | null): string {
        if (currentOption) {
            return sessionOptionsDialogTitle[currentOption];
        }

        return this.data.title;
    }

    onClose() {
        this.facade.closeDialog();
    }

    onSave() {
        const session = {
            name: this.sessionForm.value.name,
            description: this.sessionForm.value.description,
            imageUrl: this.sessionForm.value.image?.url,
            imageId: this.sessionForm.value.image?.id,
        } as Partial<Session>;

        this.facade.createOrUpdateSession(session, this.sessionForm.value.startDate, this.sessionForm.value.endDate);
    }

    ngOnDestroy(): void {
        this.facade.resetOptions();
    }
}
