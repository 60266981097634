import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { Observable } from 'rxjs';

import {
    ActivityOptions,
    ActivityOptionsType,
    CollectionOptions,
    CollectionOptionsType,
    Dictionary,
    ProjectOptions,
    ProjectOptionsType,
    SelectedActivityOptions,
    SelectedCollectionOptions,
    SelectedProjectOptions,
    SelectedSessionOptions,
    SessionOptions,
    SessionOptionsType,
} from '@accenture/shared/data';

export interface OptionsState {
    hasClientChanged: boolean;
    currentOptionToDisplay: SessionOptions | ProjectOptions | CollectionOptions | null;
    selectedSessionOptions: SelectedSessionOptions;
    selectedProjectOptions: SelectedProjectOptions;
    selectedCollectionOptions: SelectedCollectionOptions;
    selectedActivityOptions: SelectedActivityOptions;
    sessionOptionsToCreate: {
        [key: string]: SessionOptionsType[];
    };
    projectOptionsToCreate: {
        [key: string]: ProjectOptionsType[];
    };
    collectionOptionsToCreate: {
        [key: string]: CollectionOptionsType[];
    };
    activityOptionsToCreate: {
        [key: string]: ActivityOptionsType[];
    };
}

@Injectable({
    providedIn: 'root',
})
export class OptionsStore extends ComponentStore<OptionsState> {
    constructor() {
        super({
            hasClientChanged: false,
            currentOptionToDisplay: null,
            selectedSessionOptions: {} as SelectedSessionOptions,
            selectedProjectOptions: {} as SelectedProjectOptions,
            selectedCollectionOptions: {} as SelectedCollectionOptions,
            selectedActivityOptions: {} as SelectedActivityOptions,
            sessionOptionsToCreate: {
                phase: [],
                subPhase: [],
                client: [],
                project: [],
                tags: [],
            },
            projectOptionsToCreate: {
                clients: [],
                tags: [],
            },
            collectionOptionsToCreate: {
                clients: [],
                tags: [],
            },
            activityOptionsToCreate: {
                tags: [],
            },
        });
    }

    readonly hasClientChanged$: Observable<boolean> = this.select((state) => state.hasClientChanged);

    readonly currentOptionToDisplay$: Observable<SessionOptions | ProjectOptions | CollectionOptions | null>
        = this.select((state) => state.currentOptionToDisplay);

    readonly selectedSessionOptions$: Observable<SelectedSessionOptions> = this.select(
        (state) => state.selectedSessionOptions,
    );

    readonly selectedProjectOptions$: Observable<SelectedProjectOptions> = this.select(
        (state) => state.selectedProjectOptions,
    );

    readonly selectedCollectionOptions$: Observable<SelectedCollectionOptions> = this.select(
        (state) => state.selectedCollectionOptions,
    );

    readonly selectedProjectClients$: Observable<Dictionary<string>> = this.select(
        (state) => state.selectedProjectOptions?.clients || {},
    );

    readonly selectedActivityOptions$: Observable<SelectedActivityOptions> = this.select(
        (state) => state.selectedActivityOptions,
    );

    readonly sessionOptionsToCreate$: Observable<{ [name: string]: SessionOptionsType[] }> = this.select(
        (state) => state.sessionOptionsToCreate,
    );

    readonly projectOptionsToCreate$: Observable<{ [name: string]: ProjectOptionsType[] }> = this.select(
        (state) => state.projectOptionsToCreate,
    );

    readonly collectionOptionsToCreate$: Observable<{ [name: string]: CollectionOptionsType[] }> = this.select(
        (state) => state.collectionOptionsToCreate,
    );

    readonly activityOptionsToCreate$: Observable<{ [name: string]: SessionOptionsType[] }> = this.select(
        (state) => state.activityOptionsToCreate,
    );

    readonly sessionOptionsToCreateIds$: Observable<{ [name: string]: string[] }> = this.select(
        this.sessionOptionsToCreate$,
        (options) => {
            return {
                phase: options.phase?.map(({ id }) => id),
                subPhase: options.subPhase?.map(({ id }) => id),
                tags: options.tags?.map(({ id }) => id),
                client: options.client?.map(({ id }) => id),
                project: options.project?.map(({ id }) => id),
            };
        },
    );

    readonly projectOptionsToCreateIds$: Observable<{ [name: string]: string[] }> = this.select(
        this.projectOptionsToCreate$,
        (options) => {
            return {
                clients: options.clients?.map(({ id }) => id),
                tags: options.tags?.map(({ id }) => id),
            };
        },
    );

    readonly collectionOptionsToCreateIds$: Observable<{ [name: string]: string[] }> = this.select(
        this.collectionOptionsToCreate$,
        (options) => {
            return {
                tags: options.tags?.map(({ id }) => id),
            };
        },
    );

    readonly setHasClientChanged = this.updater((state: OptionsState, hasClientChanged: boolean) => ({
        ...state,
        hasClientChanged,
    }));

    readonly setCurrentOptionToDisplay = this.updater(
        (state: OptionsState, currentOptionToDisplay: SessionOptions | ProjectOptions | CollectionOptions | null) => ({
            ...state,
            currentOptionToDisplay,
        }),
    );

    readonly setSelectedSessionOptions = this.updater(
        (state: OptionsState, selectedSessionOptions: SelectedSessionOptions) => ({
            ...state,
            selectedSessionOptions,
        }),
    );

    readonly setSelectedProjectOptions = this.updater(
        (state: OptionsState, selectedProjectOptions: SelectedProjectOptions) => ({
            ...state,
            selectedProjectOptions,
        }),
    );

    readonly setSelectedCollectionOptions = this.updater(
        (state: OptionsState, selectedCollectionOptions: SelectedCollectionOptions) => ({
            ...state,
            selectedCollectionOptions,
        }),
    );

    readonly setSelectedActivityOptions = this.updater(
        (state: OptionsState, selectedActivityOptions: SelectedActivityOptions) => ({
            ...state,
            selectedActivityOptions,
        }),
    );

    readonly resetSessionOptions = this.updater((state: OptionsState) => ({
        ...state,
        selectedSessionOptions: {
            phase: {},
            subPhase: {},
            tags: {},
            client: {},
            project: {},
        },
    }));

    readonly resetProjectOptions = this.updater((state: OptionsState) => ({
        ...state,
        selectedProjectOptions: {
            clients: {},
            tags: {},
        },
    }));

    readonly resetCollectionOptions = this.updater((state: OptionsState) => ({
        ...state,
        selectedCollectionOptions: {
            tags: {},
        },
    }));

    readonly resetSessionOptionsToCreate = this.updater((state: OptionsState) => ({
        ...state,
        sessionOptionsToCreate: {
            phase: [],
            subPhase: [],
            client: [],
            project: [],
            tags: [],
        },
    }));

    readonly resetProjectOptionsToCreate = this.updater((state: OptionsState) => ({
        ...state,
        projectOptionsToCreate: {
            clients: [],
            tags: [],
        },
    }));

    readonly resetCollectionOptionsToCreate = this.updater((state: OptionsState) => ({
        ...state,
        collectionOptionsToCreate: {
            tags: [],
        },
    }));

    readonly resetHasClientChanged = this.updater((state: OptionsState) => ({
        ...state,
        hasClientChanged: false,
    }));

    readonly setSelectedSessionOption = this.updater(
        (state: OptionsState, data: { option: Dictionary<string>; optionType: SessionOptions }) => {
            return this.setSelectedOptions(state, data, 'selectedSessionOptions', 'sessionOptionsToCreate');
        },
    );

    readonly setSelectedProjectOption = this.updater(
        (state: OptionsState, data: { option: Dictionary<string>; optionType: ProjectOptions }) => {
            return this.setSelectedOptions(state, data, 'selectedProjectOptions', 'projectOptionsToCreate');
        },
    );

    readonly setSelectedCollectionOption = this.updater(
        (state: OptionsState, data: { option: Dictionary<string>; optionType: CollectionOptions }) => {
            return this.setSelectedOptions(state, data, 'selectedCollectionOptions', 'collectionOptionsToCreate');
        },
    );

    readonly setSelectedActivityOption = this.updater(
        (state: OptionsState, data: { option: Dictionary<string>; optionType: ActivityOptions }) => {
            return this.setSelectedOptions(state, data, 'selectedActivityOptions', 'activityOptionsToCreate');
        },
    );

    readonly setSessionOptionToCreate = this.updater(
        (state: OptionsState, data: { option: SessionOptionsType; optionType: SessionOptions }) => {
            return {
                ...state,
                sessionOptionsToCreate: {
                    ...state.sessionOptionsToCreate,
                    [data.optionType]: [...state.sessionOptionsToCreate[data.optionType], data.option],
                },
            };
        },
    );

    readonly setProjectOptionToCreate = this.updater(
        (state: OptionsState, data: { option: ProjectOptionsType; optionType: ProjectOptions }) => {
            return {
                ...state,
                projectOptionsToCreate: {
                    ...state.projectOptionsToCreate,
                    [data.optionType]: [...state.projectOptionsToCreate[data.optionType], data.option],
                },
            };
        },
    );

    readonly setCollectionOptionToCreate = this.updater(
        (state: OptionsState, data: { option: CollectionOptionsType; optionType: CollectionOptions }) => {
            return {
                ...state,
                collectionOptionsToCreate: {
                    ...state.collectionOptionsToCreate,
                    [data.optionType]: [...state.collectionOptionsToCreate[data.optionType], data.option],
                },
            };
        },
    );

    readonly setActivityOptionToCreate = this.updater(
        (state: OptionsState, data: { option: ActivityOptionsType; optionType: ActivityOptions }) => {
            return {
                ...state,
                activityOptionsToCreate: {
                    ...state.activityOptionsToCreate,
                    [data.optionType]: [...state.activityOptionsToCreate[data.optionType], data.option],
                },
            };
        },
    );

    readonly removeProjectOptionToCreate = this.updater(
        (state: OptionsState, data: { optionId: string; optionType: ProjectOptions }) => {
            return this.updateOptions(state, data, 'projectOptionsToCreate');
        },
    );

    readonly removeCollectionOptionToCreate = this.updater(
        (state: OptionsState, data: { optionId: string; optionType: CollectionOptions }) => {
            return this.updateOptions(state, data, 'collectionOptionsToCreate');
        },
    );

    readonly removeSessionOptionToCreate = this.updater(
        (state: OptionsState, data: { optionId: string; optionType: SessionOptions }) => {
            return this.updateOptions(state, data, 'sessionOptionsToCreate');
        },
    );

    readonly removeActivityOptionToCreate = this.updater(
        (state: OptionsState, data: { optionId: string; optionType: ActivityOptions }) => {
            return this.updateOptions(state, data, 'activityOptionsToCreate');
        },
    );

    private updateOptions(
        state: OptionsState,
        data: { optionId: string; optionType: SessionOptions | ProjectOptions | ActivityOptions | CollectionOptions },
        field: string,
    ) {
        const newOptionValue = state[field][data.optionType].filter((item) => item.id !== data.optionId);

        return {
            ...state,
            [field]: {
                ...state[field],
                [data.optionType]: newOptionValue,
            },
        };
    }

    private setSelectedOptions(
        state: OptionsState,
        data: {
            option: Dictionary<string>;
            optionType: SessionOptions | ProjectOptions | ActivityOptions | CollectionOptions;
        },
        fieldToUpdate: string,
        fieldToCreate: string,
    ) {
        let newOptionValue;
        // could be more than 1 value
        if (
            [SessionOptions.Tag, ProjectOptions.Tag, ActivityOptions.Tag, CollectionOptions.Tag].includes(
                data.optionType,
            )
        ) {
            newOptionValue = { ...state[fieldToUpdate]?.[data.optionType] };
            const tagId = Object.keys(data.option || {})[0];

            if (Object.keys(newOptionValue).includes(tagId)) {
                delete newOptionValue[tagId];
            } else {
                newOptionValue = { ...newOptionValue, ...data.option } as Dictionary<string>;
            }
        } else {
            // could be only 1 value
            const prevOptionId = Object.keys(state[fieldToUpdate]?.[data.optionType] || {})[0] as string;
            const hasFieldToCreate = !!state[fieldToCreate]?.[data.optionType].find(
                (option) => option.id === prevOptionId,
            );
            // if previous value was new => remove from list of optionsToCreate
            if (hasFieldToCreate) {
                this.removeOptionsToCreate(prevOptionId, data.optionType, fieldToCreate);
            }

            const hasCurrentOption
                = Object.keys(data.option || {})[0] === Object.keys(state[fieldToUpdate]?.[data.optionType] || {})[0];
            newOptionValue = hasCurrentOption ? {} : data.option;
        }

        return {
            ...state,
            [fieldToUpdate]: {
                ...state[fieldToUpdate],
                [data.optionType]: newOptionValue,
            },
        };
    }

    private removeOptionsToCreate(
        optionId: string,
        optionType: ProjectOptions | SessionOptions | ActivityOptions | CollectionOptions,
        fieldToCreate: string,
    ): void {
        switch (fieldToCreate) {
            case 'sessionOptionsToCreate':
                this.removeSessionOptionToCreate({ optionId, optionType: optionType as SessionOptions });
                break;
            case 'projectOptionsToCreate':
                this.removeProjectOptionToCreate({ optionId, optionType: optionType as ProjectOptions });
                break;
            case 'activityOptionsToCreate':
                this.removeActivityOptionToCreate({ optionId, optionType: optionType as ActivityOptions });
                break;
            default:
                this.removeProjectOptionToCreate({ optionId, optionType: optionType as ProjectOptions });
                break;
        }
    }
}
