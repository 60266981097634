import { NodeTemplate, NodeType } from '../node-template';
import { Activity, ActivityType } from './';

export class Whiteboard extends NodeTemplate implements Activity {
    type = ActivityType.Whiteboard;

    sessionId?: string;
    projectId?: string;
    templateId?: string;
    visible?: boolean;
    sequence: string;

    freeForAll?: boolean;

    constructor(id: string | undefined, whiteboard: Partial<Whiteboard>) {
        super(id, whiteboard, NodeType.Activity);

        this.sessionId = whiteboard.sessionId ?? '';
        this.projectId = whiteboard.projectId ?? '';
        this.templateId = whiteboard.templateId ?? '';
        this.visible = whiteboard.visible ?? false;
        this.sequence = whiteboard.sequence ?? '';
        this.freeForAll = whiteboard.freeForAll ?? false;
    }

    createSerializableObject(): Record<string, unknown> {
        return {
            id: this.id,
            name: this.name,
            description: this.description,
            projectId: this.projectId,
            sessionId: this.sessionId,
            templateId: this.templateId,
            visible: this.visible,
            sequence: this.sequence,
            type: this.type,
            created: this.created,
            updated: this.updated,
            freeForAll: this.freeForAll,
        };
    }
}
