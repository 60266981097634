import { createFeatureSelector, createSelector } from '@ngrx/store';
import { isEmpty } from 'lodash';

import {
    Activity,
    Attribute,
    AttributeClass,
    ParentType,
    Project,
    publicParentTypes,
    Session,
    SessionRole,
    TeamMember,
    TemplateRole,
} from '@accenture/shared/data';

import { AppState } from '../app.state';
import { selectParentType } from '../router/router.selectors';
import { PROJECT_FEATURE_KEY, ProjectState } from './project.reducer';

export const projectState = createFeatureSelector<ProjectState>(PROJECT_FEATURE_KEY);

export const selectProjectAttributes = createSelector<AppState, [ProjectState], Attribute[]>(
    projectState,
    (projectStateData) => projectStateData?.attributes,
);

export const selectSessions = createSelector<AppState, [ProjectState], Session[]>(
    projectState,
    (projectStateData) => projectStateData?.sessions,
);

export const selectSession = createSelector<AppState, [ProjectState], Session>(
    projectState,
    (projectStateData) => projectStateData?.session,
);

export const selectSessionName = createSelector<AppState, [ProjectState], string>(
    projectState,
    (projectStateData) => projectStateData?.session?.name,
);

export const selectSessionTeamMembersCount = createSelector<AppState, [ProjectState], number>(
    projectState,
    (projectStateData) => projectStateData?.session?.teamMembersCount,
);

export const selectProject = createSelector<AppState, [ProjectState], Project>(
    projectState,
    (projectStateData) => projectStateData?.project,
);

export const selectProjectAttributeClasses = createSelector<AppState, [ProjectState], AttributeClass[]>(
    projectState,
    (projectStateData) => projectStateData?.attributeClasses,
);

export const selectSessionActivities = createSelector<AppState, [ProjectState], Activity[]>(
    projectState,
    (projectStateData) => projectStateData.sessionActivities || [],
);

export const selectSessionTeamMember = createSelector(
    projectState,
    (projectStateData) => projectStateData?.sessionTeamMember || ({} as TeamMember),
);

export const selectSessionTeamMemberSnackBarsVisibility = createSelector(
    projectState,
    (projectStateData) => projectStateData?.sessionTeamMemberSnackBarsVisibility || {},
);

export const selectIsSessionLeader = createSelector(
    selectSessionTeamMember,
    (sessionTeamMember) => !!sessionTeamMember.isSessionLeader,
);

export const selectSessionVisibleActivities = createSelector<AppState, [Activity[], boolean], Activity[]>(
    selectSessionActivities,
    selectIsSessionLeader,
    (sessionActivities, isSessionLeader) => {
        return sessionActivities.filter((activityItem: Activity) => activityItem.visible || isSessionLeader);
    },
);

export const selectIsSessionParticipant = createSelector(
    selectSessionTeamMember,
    (sessionTeamMember) => sessionTeamMember.isSessionParticipant,
);

export const selectParentTeamMember = createSelector(
    projectState,
    (projectStateData) => projectStateData?.parentTeamMember || ({} as TeamMember),
);

export const selectActiveTeamMember = createSelector(
    selectSessionTeamMember,
    selectParentTeamMember,
    (sessionTeamMember, parentTeamMember) => {
        const teamMember = isEmpty(sessionTeamMember) ? parentTeamMember : sessionTeamMember;
        return teamMember;
    },
);

export const selectCurrentUserUpdateActivityDialogDisabled = createSelector<AppState, [TeamMember], boolean>(
    selectSessionTeamMember,
    (teamMember) => !!teamMember?.isUpdateActivityDialogDisabled,
);

export const selectCurrentUserCanEditSession = createSelector<AppState, [TeamMember, ParentType], boolean>(
    selectActiveTeamMember,
    selectParentType,
    (teamMember, parentType) => {
        const isPublicTemplate = publicParentTypes.includes(parentType);

        return (
            isPublicTemplate
            || [SessionRole.Leader, TemplateRole.Owner, TemplateRole.Collaborator].includes(
                teamMember?.role as SessionRole,
            )
        );
    },
);

export const selectActiveClassesSessionActivities = createSelector(
    selectSessionActivities,
    selectProjectAttributeClasses,
    (sessionActivities: Activity[], attributeClasses: AttributeClass[]) => {
        const selectedIds = sessionActivities.reduce((acc, activity) => {
            if (activity?.useAttributes) {
                Object.keys(activity.selectedAttributes || {}).forEach((attributeClassId) => {
                    if ((activity.selectedAttributes || {})[attributeClassId] && !acc.includes(attributeClassId)) {
                        acc.push(attributeClassId);
                    }
                });
            }

            return acc;
        }, []);

        return attributeClasses.filter((attributeClass) => selectedIds.includes(attributeClass.id));
    },
);
