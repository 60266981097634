import { Injectable } from '@angular/core';
import { AngularFireAuthGuard } from '@angular/fire/compat/auth-guard';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { Store } from '@ngrx/store';
import { combineLatest, Observable, of } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';

import { AppState, selectAuthenticatedUserState } from '@accenture/global-store';
import { User } from '@accenture/shared/data';

@Injectable()
export class AuthSystemGuard {
    constructor(
        private angularFireAuthGuard: AngularFireAuthGuard,
        private msalGuard: MsalGuard,
        private store: Store<AppState>,
    ) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
        return this.angularFireAuthGuard.canActivate(route, state).pipe(
            switchMap((angularFireAuthGuardResult: boolean | UrlTree) => {
                return combineLatest([
                    this.store.select(selectAuthenticatedUserState).pipe(filter(user => !!user)),
                    of(angularFireAuthGuardResult as boolean | UrlTree),
                ]).pipe(
                    switchMap(([user, angularFireAuthGuardResult]: [User, boolean | UrlTree]) => {
                        if (!angularFireAuthGuardResult) {
                            return this.msalGuardCheck(route, state);
                        }

                        // check if user needs additional checks
                        const msalCheck = user.isAccenture;

                        if (!msalCheck) {
                            return of(angularFireAuthGuardResult);
                        }

                        return this.msalGuardCheck(route, state);
                    }),
                );
            }),
        );
    }

    msalGuardCheck(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
        return this.msalGuard.canActivate(route, state).pipe(map(msalGuardResult => msalGuardResult));
    }
}
