import { createAction, props } from '@ngrx/store';

import { ActivityConfiguration, Dictionary, FeatureToggles, MaintenanceConfig } from '@accenture/shared/data';

export const getApplicationConfiguration = createAction('[ApplicationConfiguration] Get Application Configuration');

export const getApplicationConfigurationSuccess = createAction(
    '[ApplicationConfiguration] Get Application Configuration Success',
    props<{
        activityConfigurations: ActivityConfiguration[];
        featureToggles: FeatureToggles;
        timeouts: Dictionary<number>;
        maintenanceConfig: MaintenanceConfig;
    }>(),
);

export const getApplicationConfigurationError = createAction(
    '[ApplicationConfiguration] Get Application Configuration Error',
    props<{ error: any }>(),
);
