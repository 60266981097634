<div
  class="no-elements-container d-flex-column-center"
  [ngClass]="{
    small: emptyScreenTypes.Small === emptyScreenType
  }"
>
  <img
    class="no-elements-container-image"
    *ngIf="emptyScreenTypes.Small !== emptyScreenType && !isHomepage"
    src="{{ imageUrl?.path }}"
    alt="No elements"
  />

  <p
    *ngIf="title && emptyScreenTypes.Small !== emptyScreenType; else smallTitle"
    class="text-center tt9-color-neutral"
    [ngClass]="{
      'no-elements-title-guest': userGuest,
      'tt9-h4-medium': isHomepage,
      'tt9-h3 no-elements-title': !isHomepage
    }"
  >
    {{ title }}
  </p>
  <ng-template #smallTitle class="tt9-caption-1 no-elements-title">
    {{ title }}
  </ng-template>
  <p
    *ngIf="emptyScreenTypes.Small !== emptyScreenType && description"
    class="tt9-color-neutral-500 no-elements-description"
    [ngClass]="{
      'no-elements-description-guest': userGuest,
      'tt9-body-1': isHomepage,
      'tt9-h4': !isHomepage
    }"
  >
    {{ description }}
  </p>
  <button
    *ngIf="!!buttonText && isButtonVisible"
    class="no-elements-button-actions tt9-btn btn-rounded btn-with-border empty-screen-actions btn-medium-size {{
      buttonClass
    }}"
    (click)="buttonClick.emit()"
  >
    <i *ngIf="!!buttonIcon" class="{{ buttonIcon }}"></i>
    <span>{{ buttonText }}</span>
  </button>
  <ng-content />
</div>
