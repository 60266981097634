import { Injectable } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

import { ParentType } from '@accenture/shared/data';
import { DialogService } from '@accenture/shared/ui';

import { AddActivityTemplateToSessionDialogComponent } from '../add-activity-template-to-session-dialog/add-activity-template-to-session-dialog.component';
import { AddSessionToProjectDialogComponent } from '../add-session-to-project-dialog/add-session-to-project-dialog.component';
import { UseTemplateStore } from '../component-stores/use-template.store';
import { CreateProjectDialogComponent } from '../create-project-dialog/create-project-dialog.component';
import { CreateSessionDialogComponent } from '../create-session-dialog/create-session-dialog.component';
import { useTemplateActivityTitle, useTemplateSessionTitle, useTemplateStepOne, useTemplateStepTwo } from './constants';
import { UseTemplateDialogComponent } from './use-template-dialog.component';

export interface UseTemplateDialogViewModel {
    selectedProjectId: string;
    title: string;
    subTitle: string;
    isLoading: boolean;
}

const defaultViewModel: UseTemplateDialogViewModel = {
    selectedProjectId: '',
    title: '',
    subTitle: '',
    isLoading: true,
};

@Injectable()
export class UseTemplateDialogFacade {
    private isLoading$ = new BehaviorSubject<boolean>(false);

    vm$ = this.buildViewModel();

    constructor(
        private useTemplateStore: UseTemplateStore,
        private dialogRef: MatDialogRef<UseTemplateDialogComponent>,
        private dialogService: DialogService,
    ) {}

    closeDialog(): void {
        this.dialogRef.close();
    }

    openCreateProjectDialog(): void {
        this.dialogService.open(CreateProjectDialogComponent, {
            width: '768px',
            panelClass: 'tt9-modal',
        });
    }

    openCreateSessionDialog(): void {
        this.dialogService.open(CreateSessionDialogComponent, {
            width: '768px',
            panelClass: 'tt9-modal',
        });
    }

    openAddSessionDialog(): void {
        this.dialogService.open(AddActivityTemplateToSessionDialogComponent, {
            width: 'auto',
            panelClass: 'tt9-modal',
        });
    }

    openAddProjectDialog(): void {
        this.dialogService.open(AddSessionToProjectDialogComponent, {
            width: 'auto',
            panelClass: 'tt9-modal',
        });
    }

    private buildViewModel(): Observable<UseTemplateDialogViewModel> {
        return combineLatest([
            this.useTemplateStore.useTemplateType$,
            this.useTemplateStore.selectedProjectId$,
            this.isLoading$,
        ]).pipe(
            map(([useTemplateType, selectedProjectId, isLoading]) => {
                const title = [ParentType.ActivityTemplates, ParentType.PublicActivityTemplates].includes(
                    useTemplateType,
                )
                    ? useTemplateActivityTitle
                    : useTemplateSessionTitle;
                const step = selectedProjectId ? useTemplateStepTwo : useTemplateStepOne;
                const subTitle = [ParentType.ActivityTemplates, ParentType.PublicActivityTemplates].includes(
                    useTemplateType,
                )
                    ? step
                    : '';

                return {
                    selectedProjectId,
                    title,
                    subTitle,
                    isLoading,
                };
            }),
            startWith(defaultViewModel),
        );
    }
}
