<ng-container *ngIf="vm$ | async as vm">
  <ng-container *accentureLoaded="!vm.isLoading; description: vm.loaderDescription">
    <accenture-base-dialog [title]="getTitle(vm.title, !!vm.currentOptionToDisplay)">
      <div class="dialog-body d-flex-column">
        <div class="project-edit-container">
          <ng-container *ngIf="!vm.currentOptionToDisplay">
            <div class="project-edit-image">
              <accenture-image-input
                [imagePlaceholder]="imageInputPlaceholder"
                [file]="vm.projectImage"
                (fileUploaded)="setProjectImage($event)"
                (fileDeleted)="deleteProjectImage()"
              ></accenture-image-input>
            </div>

            <form class="project-edit-form" [formGroup]="projectEditForm">
              <mat-form-field appearance="outline" floatLabel="auto" class="tt9-form-field no-margin width-100 mb-24">
                <mat-label>Project name</mat-label>
                <input
                  #inputName
                  matInput
                  type="text"
                  formControlName="projectName"
                  [maxlength]="characterLimitMedium"
                />
                <mat-hint
                  class="mat-hint-limit"
                  [class]="determineHintClass(inputName.value.length, characterLimitMedium)"
                  >{{ characterLimitText(inputName.value.length, characterLimitMedium) }}</mat-hint
                >
                <mat-error *ngIf="projectNameControl?.hasError(validationErrors.required)">{{
                  validationMessages.required
                }}</mat-error>
              </mat-form-field>

              <mat-form-field appearance="outline" floatLabel="auto" class="tt9-form-field no-margin width-100 mb-24">
                <mat-label>Description</mat-label>
                <input
                  #inputDescription
                  matInput
                  type="text"
                  formControlName="projectDescription"
                  [maxlength]="descriptionCharacterLimit"
                />
                <mat-hint
                  class="mat-hint-limit"
                  [class]="determineHintClass(inputDescription.value.length, descriptionCharacterLimit)"
                  >{{ characterLimitText(inputDescription.value.length, descriptionCharacterLimit) }}</mat-hint
                >
              </mat-form-field>
            </form>
          </ng-container>

          <accenture-project-options *ngIf="!vm.project?.id || !vm.updateProjectEvent"></accenture-project-options>
        </div>

        <div class="project-edit-footer p-16">
          <div *ngIf="vm.projectHasUpdates && !vm.isLoading">
            <span>Project edit data updates available</span>
            <span class="project-edit-update ml-8" (click)="updateProjectForm()">Update</span>
          </div>

          <div class="d-flex buttons-container">
            <button *ngIf="vm.currentOptionToDisplay" class="tt9-btn-default btn-icon" (click)="backToProject()">
              <accenture-icon>icon-chevron-left-small</accenture-icon>
              <span>Back</span>
            </button>

            <ng-container *ngIf="!vm.currentOptionToDisplay">
              <button class="tt9-btn-default mr-8" (click)="closeDialog()">
                {{ data.cancelBtnText }}
              </button>
              <button
                class="tt9-btn"
                [disabled]="isSaveButtonDisabled(vm.isClientSelected, vm.isPublicProjectTemplate, vm.hasClientChanged)"
                (click)="createOrUpdateProject(projectNameControl.value, projectDescriptionControl.value)"
              >
                Save
              </button>
            </ng-container>
          </div>
        </div>
      </div>
    </accenture-base-dialog>
  </ng-container>
</ng-container>
