import { Injectable } from '@angular/core';
import { isNull, isUndefined, omitBy, unset } from 'lodash';
import { firstValueFrom, map, Observable } from 'rxjs';

import {
    Collection,
    CollectionFilters,
    CollectionRole,
    CollectionSession,
    DBPathHelper,
    DefaultFilterObject,
    initialCollectionFilters,
    ParentType,
    Session,
    User,
    UserCollection,
    UserSession,
} from '@accenture/shared/data';
import { FanOutWrite, FirestoreService } from '@accenture/shared/data-access';
import { removeEmptyKeys } from '@accenture/shared/util';

import { TeamMemberService } from './team-member.service';

@Injectable({
    providedIn: 'root',
})
export class CollectionsService {
    constructor(private firestoreService: FirestoreService, private teamMemberService: TeamMemberService) {}

    getCollectionById(collectionId: string): Observable<Collection> {
        return this.firestoreService
            .getDocument(DBPathHelper.getCollectionPath(collectionId))
            .pipe(map((baseCollection: Collection) => new Collection(baseCollection.id, baseCollection)));
    }

    getCollectionSessionDocument(userId: string, sessionId: string): Observable<CollectionSession | null> {
        return this.firestoreService
            .getDocumentsByMultipleProperties<CollectionSession>(
                DBPathHelper.getCollectionSessionsPath(),
                new Map<string, any>([
                    ['userId', userId],
                    ['sessionId', sessionId],
                ]),
            )
            .pipe(
                map((collectionSessions) => {
                    if (!!collectionSessions.length) {
                        return (
                            collectionSessions.find((collectionSession) => !collectionSession.markedForDelete) || null
                        );
                    }
                    return null;
                }),
            );
    }

    getCollectionSessionByUserId(userId: string): Observable<CollectionSession[]> {
        return this.firestoreService
            .getDocumentsByProperty<CollectionSession>(DBPathHelper.getCollectionSessionsPath(), 'userId', userId)
            .pipe(
                map((collectionSessions) => {
                    if (!!collectionSessions.length) {
                        return collectionSessions.filter((collectionSession) => !collectionSession.markedForDelete);
                    }
                    return [];
                }),
            );
    }

    async moveToCollection(
        newCollection: UserCollection,
        userId: string,
        sessionId: string,
        userSessionId?: string,
    ): Promise<void> {
        const currentCollectionSessionData = await firstValueFrom(
            this.firestoreService.getDocumentsByMultipleProperties<CollectionSession>(
                DBPathHelper.getCollectionSessionsPath(),
                new Map<string, any>([
                    ['userId', userId],
                    ['sessionId', sessionId],
                ]),
            ),
        );

        const existingCollectionSessionData = currentCollectionSessionData?.[0];

        if (!!existingCollectionSessionData && !existingCollectionSessionData.markedForDelete) {
            const currentCollectionSessionId = currentCollectionSessionData?.[0]?.id;

            const updateCollectionSession = this.firestoreService.updateDoc(
                DBPathHelper.getCollectionSessionsPath(currentCollectionSessionId),
                {
                    collectionId: newCollection.collectionId,
                    collectionName: newCollection.name,
                    updated: this.firestoreService.timestamp,
                } as Partial<CollectionSession>,
            );

            const updateUserSession = this.firestoreService.updateDoc(DBPathHelper.getUserSessionsPath(userSessionId), {
                collectionId: newCollection.collectionId,
                collectionName: newCollection.name,
                color: newCollection?.color || '',
                updated: this.firestoreService.timestamp,
            } as Partial<UserSession>);

            await Promise.all([updateCollectionSession, updateUserSession]);

            return;
        }

        const session = await firstValueFrom(
            this.firestoreService.getDocumentByKey<Session>(DBPathHelper.getParentPath(ParentType.Sessions), sessionId),
        );

        const collectionSessionDoc = new CollectionSession(
            removeEmptyKeys({
                sessionId,
                userId,
                imageUrl: session?.imageUrl || '',
                imageId: session?.imageId || '',
                collectionId: newCollection.collectionId,
                collectionName: newCollection.name,
                sessionRole: session?.access[userId]?.role,
                sessionPhase: session?.phase,
                sessionSubPhase: session?.subPhase,
                role: CollectionRole.Owner,
                sessionName: session.name,
                sessionDescription: session.description || '',
                tags: session?.tags || {},
            }),
        );

        const updateUserSession = this.firestoreService.updateDoc(DBPathHelper.getUserSessionsPath(userSessionId), {
            collectionId: newCollection.collectionId,
            collectionName: newCollection.name,
            color: newCollection?.color || '',
            updated: this.firestoreService.timestamp,
        } as Partial<UserSession>);

        // create new
        const addSessionResponse = await this.addSessionToCollection(collectionSessionDoc);

        // update userCollections
        const updateUserCollectionsSessionsCount = await this.updateUserCollectionsSessionsCount(
            newCollection.collectionId,
            1,
        );

        await Promise.all([updateUserSession, addSessionResponse, updateUserCollectionsSessionsCount]);
    }

    async removeFromCollection(sessionId: string): Promise<void> {
        const currentCollectionSessionData = await firstValueFrom(
            this.firestoreService.getDocumentsByProperty<CollectionSession>(
                DBPathHelper.getCollectionSessionsPath(),
                'sessionId',
                sessionId,
            ),
        );
        const currentUserSessionData = await firstValueFrom(
            this.firestoreService.getDocumentsByProperty<UserSession>(
                DBPathHelper.getUserSessionsPath(),
                'sessionId',
                sessionId,
            ),
        );
        const existingCollectionSessionData = currentCollectionSessionData?.[0];
        const existingUserSessionData = currentUserSessionData?.[0];

        const collectionSessionExists
            = !!existingCollectionSessionData && !existingCollectionSessionData?.markedForDelete;
        const userSessionExists = !!existingUserSessionData && !existingUserSessionData?.markedForDelete;

        if (userSessionExists && collectionSessionExists) {
            const currentCollectionSessionId = currentCollectionSessionData?.[0]?.id;

            await this.firestoreService.delete(
                DBPathHelper.getCollectionSessionsPath(currentCollectionSessionId),
            );
        } else {
            const missingSessions = [
                !userSessionExists ? 'UserSession' : '',
                !collectionSessionExists ? 'CollectionSession' : '',
            ]
                .filter(Boolean)
                .join(' and ');

            throw new Error(`${missingSessions} does not exist or has been marked for deletion`);
        }
    }

    getCollectionAssignmentsByUserId(userId: string): Observable<UserCollection[]> {
        return this.firestoreService
            .getDocumentsByProperty<UserCollection>(DBPathHelper.getUserCollectionsPath(), 'userId', userId)
            .pipe(
                map((userCollections) =>
                    (userCollections || [])
                        .filter((userCollection) => !userCollection?.markedForDelete)
                        .map((userCollection) => {
                            if (!userCollection.updated) {
                                userCollection.updated = userCollection.created;
                            }

                            if (!userCollection.lastViewed) {
                                userCollection.lastViewed = userCollection.updated;
                            }
                            return userCollection;
                        }),
                ),
            );
    }

    getCollectionSessions(collectionId: string): Observable<CollectionSession[]> {
        return this.firestoreService
            .getDocumentsByMultipleProperties<CollectionSession>(
                DBPathHelper.getCollectionSessionsPath(),
                new Map<string, any>([['collectionId', collectionId]]),
            )
            .pipe(
                map((collectionSessions) => {
                    return collectionSessions;
                }),
            );
    }

    getUserCollectionByCollectionId(collectionId: string): Observable<UserCollection> {
        return this.firestoreService
            .getDocumentsByProperty<UserCollection>(DBPathHelper.getUserCollectionsPath(), 'collectionId', collectionId)
            .pipe(
                map((userCollections: UserCollection[]) => {
                    if (!!userCollections.length) {
                        return userCollections.filter((userCollection) => !userCollection.markedForDelete)[0];
                    }
                    return null;
                }),
            );
    }

    getCollectionSessionsByUserId(userId: string): Observable<CollectionSession[]> {
        return this.firestoreService.getDocumentsByProperty<CollectionSession>(
            DBPathHelper.getCollectionSessionsPath(),
            'userId',
            userId,
        );
    }

    getCollectionSessionUserRole(collectionSession: CollectionSession[], filter: string): CollectionSession[] {
        return collectionSession.filter((result) => {
            return result.sessionRole === filter;
        });
    }

    async updateCollectionDocument(collectionId: string, userId: string, data: Partial<Collection>): Promise<void> {
        await this.firestoreService.updateDoc(DBPathHelper.getCollectionPath(collectionId), {
            ...this.firestoreService.replaceEmptyFields(data),
            updated: this.firestoreService.timestamp,
        });

        // User collection syncing is done via consistency trigger. See user-collection-triggers.ts
        await Promise.all([
            this.updateCollectionSessionById(collectionId, userId, data),
            this.updateUserSessionsByCollectionId(collectionId, userId, data),
        ]);
    }

    async createCollection(collection: Collection, creator: User): Promise<string> {
        const collectionId = this.firestoreService.getPushId();
        await this.firestoreService.set(
            DBPathHelper.getCollectionPath(collectionId),
            removeEmptyKeys({
                ...collection,
                creatorId: creator.id,
                creatorName: creator.displayName,
                creatorImage: creator.imageUrl,
                created: this.firestoreService.timestamp,
                updated: this.firestoreService.timestamp,
            }),
        );

        const batchWrites = [];
        const userCollection = omitBy(
            {
                collectionId: collectionId,
                parentType: ParentType.Collections,
                userId: creator.id,
                role: CollectionRole.Owner,
                name: collection.name,
                description: collection.description || '',
                imageUrl: collection.imageUrl || '',
                color: collection.color,
                created: this.firestoreService.timestamp,
                updated: this.firestoreService.timestamp,
                creatorId: creator.id,
                creatorImage: creator.imageUrl || '',
                creatorName: creator.displayName || '',
                sessionsCount: 0,
            } as UserCollection,
            (result) => isUndefined(result) || isNull(result),
        );

        const userCollectionCreate = {
            path: DBPathHelper.getUserCollectionsPath(this.firestoreService.getPushId()),
            data: userCollection,
        };
        batchWrites.push(userCollectionCreate);

        const teamMemberWrites = this.addUserToCollection(collectionId, creator, CollectionRole.Owner);
        batchWrites.push(teamMemberWrites);

        await this.firestoreService.writeBatch(batchWrites);

        return collectionId;
    }

    async deleteCollection(collectionId: string): Promise<void> {
        await this.firestoreService.cloudFunctionCallable('deleteCollection', { collectionId });
    }

    async ungroupCollection(collectionId: string): Promise<void> {
        await this.firestoreService.cloudFunctionCallable('ungroupCollection', { collectionId });
    }

    getSessionsByCollectionId(collectionId: string): Observable<CollectionSession[]> {
        return this.firestoreService.getDocumentsByPropertyWithoutCaching(
            DBPathHelper.getCollectionSessionsPath(),
            'collectionId',
            collectionId,
        );
    }

    async addSessionToCollection(collectionSessionDoc: CollectionSession): Promise<void> {
        const id = this.firestoreService.getPushId();
        await this.firestoreService.set(
            DBPathHelper.getCollectionSessionsPath(id),
            removeEmptyKeys({
                ...collectionSessionDoc,
                created: this.firestoreService.timestamp,
                updated: this.firestoreService.timestamp,
            }),
        );
    }

    async updateCollectionsFilters(userId: string | undefined, data: DefaultFilterObject): Promise<void> {
        await this.firestoreService.upsert(DBPathHelper.getCollectionsFilterPath(userId), data);
    }

    async updateOptionsFilters(
        userId: string | undefined,
        id: string,
        isSelected: boolean,
        optionType: string,
    ): Promise<void> {
        await this.firestoreService.upsert(DBPathHelper.getCollectionsFilterPath(userId), {
            [optionType]: isSelected ? this.firestoreService.arrayRemove(id) : this.firestoreService.arrayUnion(id),
        });
    }

    getCurrentUserCollectionsFilters(userId: string | undefined): Observable<CollectionFilters> {
        return this.firestoreService.getDocument<CollectionFilters>(DBPathHelper.getCollectionsFilterPath(userId)).pipe(
            map((filter: CollectionFilters) => {
                unset(filter, 'id');
                return filter
                    ? {
                          ...initialCollectionFilters,
                          ...filter,
                      }
                    : initialCollectionFilters;
            }),
        );
    }

    async updateUserCollectionsSessionsCount(collectionId: string, counterValue: number): Promise<void> {
        const userCollections = await firstValueFrom(
            this.firestoreService.getDocumentsByProperty<UserCollection>(
                DBPathHelper.getUserCollectionsPath(),
                'collectionId',
                collectionId,
            ),
        );

        const userCollectionId = userCollections[0]?.id;

        if (!userCollectionId) {
            return;
        }

        await this.firestoreService.update(`/userCollections/${userCollectionId}`, {
            sessionsCount: this.firestoreService.changeCounterValue(counterValue),
        });
    }

    private addUserToCollection(collectionId: string, creator: User, role: CollectionRole): FanOutWrite {
        // TODO: collection access needs to add - userCollections collection
        const teamMember = this.teamMemberService.buildTeamMember(collectionId, creator, role);
        return {
            path: DBPathHelper.getTeamMemberPath(ParentType.Collections, collectionId, creator.id),
            data: {
                ...teamMember,
                type: creator.type,
                status: creator.status,
                lastLogin: creator.lastLogin,
            },
        };
    }

    // Unused method but could be reused in case userCollection consistency trigger does not suffice.
    private async updateUserCollectionById(
        collectionId: string,
        userId: string,
        data: Partial<Collection>,
    ): Promise<void> {
        const userCollections = await firstValueFrom(
            this.firestoreService.getDocumentsByMultipleProperties<UserCollection>(
                DBPathHelper.getUserCollectionsPath(),
                new Map([
                    ['collectionId', collectionId],
                    ['userId', userId],
                ]),
            ),
        );

        const userCollectionId = userCollections[0]?.id;

        if (!userCollectionId) {
            return;
        }

        const userCollection = omitBy(
            {
                name: data.name,
                description: data.description,
                imageUrl: data.imageUrl,
                color: data.color,
                updated: this.firestoreService.timestamp,
                lastViewed: this.firestoreService.timestamp,
            } as UserCollection,
            (result) => isUndefined(result),
        );

        await this.firestoreService.update(DBPathHelper.getUserCollectionsPath(userCollectionId), userCollection);
    }

    private async updateCollectionSessionById(
        collectionId: string,
        userId: string,
        data: Partial<Collection>,
    ): Promise<void> {
        const collectionSessions = await firstValueFrom(
            this.firestoreService.getDocumentsByMultipleProperties<UserCollection>(
                DBPathHelper.getCollectionSessionsPath(),
                new Map([
                    ['collectionId', collectionId],
                    ['userId', userId],
                ]),
            ),
        );

        const collectionSessionId = collectionSessions[0]?.id;

        if (!collectionSessionId) {
            return;
        }

        const collectionSession = omitBy(
            {
                collectionName: data.name,
            } as CollectionSession,
            (result) => isUndefined(result) || isNull(result),
        );

        await this.firestoreService.update(
            DBPathHelper.getCollectionSessionsPath(collectionSessionId),
            collectionSession,
        );
    }

    private async updateUserSessionsByCollectionId(
        collectionId: string,
        userId: string,
        data: Partial<Collection>,
    ): Promise<void> {
        const userSessions = await firstValueFrom(
            this.firestoreService.getDocumentsByMultipleProperties<UserSession>(
                DBPathHelper.getUserSessionsPath(),
                new Map([
                    ['collectionId', collectionId],
                    ['userId', userId],
                ]),
            ),
        );

        const userSessionIds = userSessions.map((userSession) => userSession.id);

        if (!userSessionIds?.length) {
            return;
        }

        const batchUpdates = [];

        userSessionIds.forEach(async (userSessionId) => {
            const userSession = omitBy(
                {
                    collectionName: data.name,
                    color: data.color,
                } as UserSession,
                (result) => isUndefined(result) || isNull(result),
            );

            batchUpdates.push({
                path: DBPathHelper.getUserSessionsPath(userSessionId),
                data: userSession,
            });
        });

        await this.firestoreService.updateBatch(batchUpdates);
    }
}
