import { Pipe, PipeTransform } from '@angular/core';

import { AccessControlRole, displayRole } from '@accenture/shared/data';

@Pipe({
    name: 'displayUserAccessRole',
})
export class DisplayUserAccessRolePipe implements PipeTransform {
    transform(userRole: AccessControlRole): string {
        return displayRole[userRole] || 'Not invited';
    }
}
