import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { InvitationObject } from '@accenture/erp-deployment/shared/domain';
import { SessionRole } from '@accenture/shared/data';
import { trackByValue } from '@accenture/shared/util';

import { SessionsInvitationsByEmailStepNew, SessionsInvitationsTabNew } from './session-invite-dialog-tt9.constants';
import { SessionInviteDialogStore } from './session-invite-dialog-tt9.store';
import { SessionInviteDialogFacadeNew, SessionInviteDialogViewModelNew } from './session-invite-dialog-tt9-facade';

@Component({
    selector: 'accenture-session-invite-dialog-tt9',
    templateUrl: './session-invite-dialog-tt9.component.html',
    styleUrls: ['./session-invite-dialog-tt9.component.scss'],
    providers: [SessionInviteDialogFacadeNew, SessionInviteDialogStore],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NewSessionInviteDialogComponent {
    sessionRole = SessionRole;
    invitationsTab = SessionsInvitationsTabNew;
    emailSteps = SessionsInvitationsByEmailStepNew;
    trackByValue = trackByValue;

    vm$ = this.facade.vm$;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: { title: string },
        public dialogRef: MatDialogRef<NewSessionInviteDialogComponent>,
        private facade: SessionInviteDialogFacadeNew,
    ) {}

    getTitle(vm: SessionInviteDialogViewModelNew, title: string): string {
        const members = 'Project members';

        return vm.inviteByEmailActiveStep === SessionsInvitationsByEmailStepNew.SelectMembers ? members : title;
    }

    setInvitationObject(invitationObject: InvitationObject): void {
        this.facade.setInvitationObject(invitationObject);
    }

    invite(): void {
        this.facade.invite();
    }
}
