import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { DBPathHelper, SupportConfig, TermsOfUseConfig } from '@accenture/shared/data';
import { FirestoreService } from '@accenture/shared/data-access';

@Injectable({
    providedIn: 'root',
})
export class ConfigurationService {
    constructor(private firestoreService: FirestoreService) {}

    getTermsOfUse(): Observable<TermsOfUseConfig> {
        return this.firestoreService.getDocument<TermsOfUseConfig>(DBPathHelper.getTermsOfServicePath());
    }

    getSupportConfiguration(): Observable<SupportConfig> {
        return this.firestoreService.getDocument<SupportConfig>(DBPathHelper.getSupportConfigurationPath());
    }
}
