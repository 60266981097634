import { KeyValue } from '@angular/common';
import { ChangeDetectionStrategy, Component, ElementRef, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { tap } from 'rxjs';

import {
    characterLimitMedium,
    characterLimitText,
    CollectionsSortObject,
    determineHintClass,
    Dictionary,
    HintClassType,
    newOptionText,
    notFoundOptionsText,
    OptionsForSortingByNumberOfUses,
    projectRequiredHint,
    SelectedSessionOptions,
    SessionOptions,
    sessionOptionsArray,
    sessionOptionsTitle,
    SessionOptionsType,
    validationErrors,
    validationMessages,
} from '@accenture/shared/data';
import { alphabeticalKeyValueOrder, trackById } from '@accenture/shared/util';

import { SessionOptionsFacade, SessionOptionsViewModel } from './session-options-facade';

@Component({
    selector: 'accenture-session-options',
    templateUrl: './session-options.component.html',
    styleUrls: ['./session-options.component.scss'],
    providers: [SessionOptionsFacade],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SessionOptionsComponent {
    vm$ = this.facade.vm$.pipe(
        tap((vm: SessionOptionsViewModel) => {
            this.hasClient = Object.keys(vm.selectedSessionOptions[SessionOptions.SessionClient] || {})?.length > 0;
            this.hasProject = Object.keys(vm.selectedSessionOptions[SessionOptions.SessionProject] || {})?.length > 0;

            const sessionProjectControl = this.optionsFormGroup.get(SessionOptions.SessionProject);

            if (this.hasClient && !this.hasProject) {
                sessionProjectControl?.reset('');
                sessionProjectControl?.setValidators(Validators.required);
                sessionProjectControl?.markAsTouched();
            } else {
                sessionProjectControl?.clearValidators();
            }
            // Don't forget to update the validation status
            sessionProjectControl?.updateValueAndValidity();

            this.isSessionOptionFormValid = this.optionsFormGroup.valid;
        }),
    );

    @ViewChild('optionsInput') optionsInput: ElementRef;

    filteredOptions: SessionOptionsType[] = []; // options for autocomplete

    trackById = trackById;
    sessionOptions = SessionOptions;
    sessionOptionsTitle = sessionOptionsTitle;
    newOptionText = newOptionText;
    alphabeticalKeyValueOrder = alphabeticalKeyValueOrder;
    optionsFormGroup!: FormGroup<Dictionary<FormControl<string>>>;
    characterLimitMedium = characterLimitMedium;
    collectionToCount = OptionsForSortingByNumberOfUses;
    validationErrors = validationErrors;
    validationMessages = validationMessages;
    projectRequiredIfHasClientText = projectRequiredHint;
    hasClient = false;
    hasProject = false;
    isSessionOptionFormValid = true;
    focusedOption: SessionOptions | null = null;

    constructor(private facade: SessionOptionsFacade, private fb: FormBuilder) {
        this.createFormGroup();
    }

    displayEmptyOption(): null {
        // for matAutocomplete to display if we reset control value
        return null;
    }

    hasMultiplyValues(
        { phase, subPhase, client, project }: SelectedSessionOptions,
        sessionOption: SessionOptions,
    ): boolean {
        switch (sessionOption) {
            case SessionOptions.Phase:
                return !!Object.keys(phase || {}).length;
            case SessionOptions.SubPhase:
                return !!Object.keys(subPhase || {}).length;
            case SessionOptions.SessionClient:
                return !!Object.keys(client || {}).length;
            case SessionOptions.SessionProject:
                return !!Object.keys(project || {}).length;
            default:
                return false;
        }
    }

    getOptionsData({
        phaseOptions,
        subPhaseOptions,
        clientOptions,
        projectOptions,
        tags,
        currentOptionToDisplay,
    }: SessionOptionsViewModel): SessionOptionsType[] {
        switch (currentOptionToDisplay) {
            case SessionOptions.Phase:
                return phaseOptions;
            case SessionOptions.Tag:
                return tags;
            case SessionOptions.SubPhase:
                return subPhaseOptions;
            case SessionOptions.SessionClient:
                return clientOptions;
            case SessionOptions.SessionProject:
                return projectOptions;
        }
    }

    isNewOptionShown(sessionOption: SessionOptions, selectedOptions: SelectedSessionOptions, value: string): boolean {
        const searchValue = value?.toLowerCase().trim();
        const optionsToDisplay = this.facade.getFilteredOptions(
            sessionOption,
            selectedOptions[sessionOption],
            searchValue,
        );
        this.filteredOptions = optionsToDisplay.filteredOptions;

        if ([SessionOptions.SessionClient].includes(sessionOption)) {
            // Client Field cannot be able to create new
            return false;
        }

        return !optionsToDisplay.isOptionExist;
    }

    getControl(sessionOption: SessionOptions): FormControl<string> {
        return this.optionsFormGroup.get(sessionOption) as FormControl<string>;
    }

    showOptionChips(optionType: SessionOptions, event?: Event): void {
        this.facade.showOptionChips(optionType);

        if (event) {
            event.stopPropagation();
        }
    }

    updateSelectedOptions(data: {
        option: SessionOptionsType;
        isSelected: boolean;
        selectedOption?: SessionOptionsType;
    }): void {
        const option = {
            [data.option.id]: data.option.name,
        } as Dictionary<string>;

        this.facade.updateSessionOptions(option);
    }

    updateFilters(filterObject: CollectionsSortObject): void {
        this.facade.updateFilters(filterObject);
    }

    setFocusedSession(sessionOption: SessionOptions): void {
        this.focusedOption = sessionOption;
    }

    removeSessionFocused(): void {
        this.focusedOption = null;
    }

    removeOptionValue(selectedOption: KeyValue<string, string>, sessionOptions: SessionOptions): void {
        const option = {
            [selectedOption.key]: selectedOption.value,
        } as Dictionary<string>;

        this.facade.updateSessionOptions(option, sessionOptions);

        const optionControl = this.optionsFormGroup.get(sessionOptions);

        // clear input
        const shouldSetTheValueEmpty
            = (sessionOptions === SessionOptions.SessionClient && !this.hasClient)
            || (sessionOptions === SessionOptions.SessionProject && !this.hasProject);

        if (shouldSetTheValueEmpty) {
            optionControl.reset('');
        }

        // Set Validation
        if (sessionOptions === SessionOptions.SessionProject && this.hasClient && !this.hasProject) {
            optionControl?.reset('');
            optionControl?.setValidators(Validators.required);
            optionControl?.markAsTouched();
        }

        optionControl?.updateValueAndValidity();
    }

    updateOptionValue(selectedOption: SessionOptionsType, sessionOptions: SessionOptions): void {
        const option = {
            [selectedOption.id]: selectedOption.name,
        } as Dictionary<string>;

        this.facade.updateSessionOptions(option, sessionOptions);
    }

    addNewOption(sessionOption: SessionOptions): void {
        const controlValue = this.getControl(sessionOption).value.trim();
        if (!controlValue) {
            return;
        }

        this.facade.addNewOption(sessionOption, controlValue);
    }

    private createFormGroup(): void {
        this.optionsFormGroup = this.fb.group({});

        sessionOptionsArray.forEach((element) =>
            this.optionsFormGroup.addControl(element, new FormControl<string>('')),
        );
    }

    isFieldRequired(fieldName: string): boolean {
        if (fieldName === SessionOptions.SessionProject) {
            return this.hasClient;
        }

        return false;
    }

    isFieldInvalid(sessionOption: SessionOptions): boolean {
        const control = this.getControl(sessionOption);

        if (sessionOption === SessionOptions.SessionClient) {
            return !this.filteredOptions.length && (control.dirty || control.touched);
        }

        return control.invalid && (control.dirty || control.touched);
    }

    invalidFieldClass(invalid: boolean): Dictionary<boolean> {
        return {
            'ng-dirty': invalid,
            'ng-touched': invalid,
            'mat-form-field-invalid': invalid,
            'ng-invalid': invalid,
            'mat-form-field-hide-placeholder': invalid,
        };
    }

    getErrorMessage(fieldName: string): string {
        const field = this.optionsFormGroup.get(fieldName);
        if (field?.errors?.required) {
            return validationMessages.required;
        }
        return '';
    }

    getCustomHintClass(sessionOption: SessionOptions, hasHelper: string): string {
        let customClass = 'custom-hint';

        if (sessionOption === SessionOptions.SessionProject) {
            if (this.hasProject || hasHelper) {
                customClass = `${customClass} project-hint-with-chip`;
            } else {
                customClass = `${customClass} project-hint`;
            }
        }

        return customClass;
    }

    getFieldHelperProperties(
        sessionOption: SessionOptions,
        value?: string,
    ): { customClass: string; text: string; shouldDisplayText: boolean } {
        const sessionClientControl = this.optionsFormGroup.get(SessionOptions.SessionClient);
        const sessionOptionControl = this.optionsFormGroup.controls[sessionOption];

        let customClass = '';
        let text = '';

        if (sessionOption === SessionOptions.SessionProject && this.hasClient && !this.hasProject) {
            text = this.getErrorMessage(sessionOption);
            customClass = HintClassType.INPUT_ERROR;
        } else if (
            sessionOption === SessionOptions.SessionClient
            && !this.filteredOptions.length
            && sessionClientControl.dirty
        ) {
            text = notFoundOptionsText.clients;
            customClass = HintClassType.INPUT_ERROR;
        } else {
            customClass = determineHintClass(value?.length, this.characterLimitMedium);
            text = characterLimitText(value?.length, this.characterLimitMedium);
        }

        const shouldDisplayText = text.length && this.focusedOption === SessionOptions.SessionProject;

        return {
            customClass,
            shouldDisplayText,
            text,
        };
    }
}
