import { numberOptionsDefaultValues } from './activity-items/number';

export const validationErrors = {
    required: 'required',
    pattern: 'pattern',
    numericRequired: 'numeric-required',
    numericMaxValue: 'numeric-max-value',
    topXMaxChecked: 'topX-max-checked',
    invalidRange: 'invalid-range',
    invalidValueFromRange: 'invalid-value-from-range',
    invalidUrl: 'invalid-url',
    noSelectedTopics: 'no-selected-topics',
    noSelectedParameters: 'no-selected-parameters',
    noParameters: 'no-parameters',
    minLength: 'minlength',
    maxLength: 'maxlength',
    invalidPassword: 'invalid-password',
    tooManyAttempts: 'too-many-attempts',
    email: 'email',
    isAccenture: 'isAccenture',
    invalidEmail: 'invalidEmail',
    nonAcnEmail: 'nonAcnEmail',
    sameEmail: 'sameEmail',
    restrictedEmail: 'restrictedEmail',
    min: 'min',
    invalidCellFormat: 'invalidCellFormat',
    cellsNotPreceding: 'cellsNotPreceding',
    emailNotAvailable: 'emailNotAvailable',
};

export const validationMessages = {
    required: 'This field is required',
    maxNotExceedMin: 'Max value should exceed min value',
    videoLink: 'Please add a link to YouTube or Vimeo video',
    invalidMinMax: 'Please enter valid min and max values',
    noSelectedTopics: 'Please select a topic from the current activity',
    noSelectedCriteria: 'Please select a criteria',
    noValidCriteria: 'There are no valid criteria',
    phonePattern: 'Please enter a valid phone number',
    invalidEmail: 'Please enter a valid email address',
    nonAcnEmail: 'Please enter Accenture email address',
    sameEmail: 'This email address has been already specified in this invite',
    restrictedEmail: (invitationSource: string) => `User with this email address is already in the ${invitationSource}`,
    topXSubDescription: (topXCheckedValuesCount: number, valueRange: number) =>
        `${topXCheckedValuesCount || 0} Voted | ${valueRange} Possible`,
    NumericSubDescription: (validationValue: number, mask: string) => `${Math.abs(validationValue)}${mask} left`,
    maxValueCheck: (validationValue: number, mask: string) =>
        `You have exceeded the total number by ${Math.abs(validationValue)}${mask}`,
    minStepValueCheck: 'Value must be equal to or greater than 1. Please verify.',
    maxStepValueCheck: (max: number, min: number) => `Step must be equal to or less than ${max - min}`,
    stepValueCheck: (max: number, min: number) => `Enter a number equal to or less than ${max - min}`,
    valueFromRangeCheck: (min: number, max: number) =>
        `Please enter a number between ${min || numberOptionsDefaultValues.min} and ${
            max || numberOptionsDefaultValues.max
        }`,
    minPasswordLength: (value: number) => `Password should contain at least ${value} characters`,
    maxPasswordLength: (value: number) => `Password should contain no more than ${value} characters`,
    invalidPassword: 'Please enter the correct session password',
    invalidPasswordSecondary: 'Invalid password',
    tooManyAttempts: 'You have exceeded number of possible attempts. Please wait until you can try again',
    emptyFirstName: 'Please enter your First Name',
    emptyLastName: 'Please enter your Last Name',
    endDateGreaterStartDate: `'End date' must be equal or greater than 'Start date'.`,
    invalidCellFormat: 'Wrong format. Specify in the format "A1"',
    cellsNotPreceding: 'Top left cell should precede bottom right cell',
    createdDateRangeMessage: `'Date created to' must be equal or greater than 'Date created from' date.`,
    updatedDateRangeMessage: `'Last updated to' must be equal or greater than 'Last updated from' date.`,
};

export const errorMessageTemplatesText = {
    activityTemplate: 'Add activity template to session has error',
    sessionTemplate: 'Add session template to project has error',
    publicActivityTemplate: 'Add public activity template to session has error',
    publicSessionTemplate: 'Add public session template to project has error',
};

export const validationHints = {
    valueFromRange: (min: number, max: number) =>
        `Please enter a number between ${min || numberOptionsDefaultValues.min} and ${
            max || numberOptionsDefaultValues.max
        }`,
};

export const inputPlaceholders = {
    chooseDate: 'Choose a date',
    chooseDateSecondary: 'mm/dd/yyyy',
    commentPrompt: 'Comment prompt',
    commentTitle: 'Comment title',
    enterResponse: 'Enter your response here',
    enterNotes: 'Enter your presenter notes here',
    enterComment: 'Enter comment',
    enterCommentOnResponse: 'Enter comments on response',
    enterText: 'Enter text here',
    enterNumber: 'Enter Number here',
    responsePrompt: 'Response prompt',
    reasonForChangeOwnership: 'Enter the reason for change in ownership',
    searchResponse: 'Search',
    noResponseGiven: 'No response given',
    chooseResponse: 'Click to select',
    enterEmail: 'Enter email address',
    message: 'Message',
    inviteText: 'Invite text',
    search: 'Search',
    homepageSearch: 'Search Sessions, Collections, Activities and Templates',
    searchByAdmins: 'Search by admins',
    searchByEmailAndName: 'Search by email, first and last name',
    password: 'Password*',
    email: 'Email*',
    number: 'Number',
    startDate: 'Start date',
    endDate: 'End date',
    enterGptPrompt: 'Type your request or prompt to ThinkGPT',
    enterGptPromptActivity: 'Sample request: Create 1 week daily brainstorm activity for this session.',
    headerActivity:
        'Please specify details to assist in generating content. Clear instructions enhance content accuracy. Each request can generate up to 50 activities.',
    headerSummarize:
        'Please specify details to assist in generating content. Clear instructions enhance content accuracy.',
    projectSummarizePrompt:
        'Example: Summarize the project in paragraph form first followed by highlights of the sessions and activities in table format with corresponding insights.',
    sessionSummarizePrompt:
        'Example: Summarize sessions in paragraph form first followed by highlights of the activities in table format with corresponding insights.',
    activitySummarizePrompt:
        'Example: Summarize all activities in this session in one paragraph first followed by highlights of each activity in table format with corresponding insights.',
    aiConciergeMessage: 'Talk to me...',
};

export const googleStorageFolderForPresentation = 'ppt';
export const googleStorageFolderForImport = 'tmp-import';

export enum ValueRange {
    ValueRange1 = 1,
    ValueRange2 = 2,
    ValueRange3 = 3,
    ValueRange4 = 4,
    ValueRange5 = 5,
    ValueRange6 = 6,
    ValueRange7 = 7,
    ValueRange8 = 8,
    ValueRange9 = 9,
    ValueRange10 = 10,
}

export const valueRanges = [
    ValueRange.ValueRange1,
    ValueRange.ValueRange2,
    ValueRange.ValueRange3,
    ValueRange.ValueRange4,
    ValueRange.ValueRange5,
    ValueRange.ValueRange6,
    ValueRange.ValueRange7,
    ValueRange.ValueRange8,
    ValueRange.ValueRange9,
    ValueRange.ValueRange10,
];

export const updateAttributes = {
    title: 'Update attributes',
    text: 'Are you sure you want to apply these changes? You will lose all the associated data.',
};

export const buttonLabels = {
    createNew: 'Create New',
    selectTemplate: 'Select Template',
    generateNew: 'Generate using AI',
    createNewSession: 'Create New Session',
    createNewProject: 'Create New Project',
    selectExistingSession: 'Select Existing Session',
    selectExistingProject: 'Select Existing Project',
    importTable: 'Import Table',
    importJiraIssues: 'Import Jira Issues',
};

export const highlightActivityItemTimer = 1500;

export const accentureApproveImgPath = '/assets/shared-ui/svg/accenture-approved.svg';
export const copyLinkAccessToClipboardText = 'Use the link to create new project based on';

export const virusCheckMessages = {
    virusDetectedOnUpload: (filename: string) =>
        `A virus has been detected in the file ${filename} that was recently uploaded. The file has been deleted from ThinkTank`,
};
