import { createReducer, on } from '@ngrx/store';

import { User } from '@accenture/shared/data';

import {
    retrieveAuthenticatedUser,
    retrieveAuthenticatedUserFailure,
    retrieveAuthenticatedUserSuccess,
    setUserIsAuthenticated,
    signOut,
    signOutSuccess,
} from './auth.actions';

export const AUTH_FEATURE_KEY = 'auth';

export interface AuthenticatedUserState {
    user: User;
    isAuthenticated: boolean | undefined;
    error: string | null;
}

const initialState: AuthenticatedUserState = {
    user: undefined,
    isAuthenticated: undefined,
    error: null,
};

export const authReducer = createReducer(
    initialState,
    on(retrieveAuthenticatedUser, () => initialState),
    on(retrieveAuthenticatedUserSuccess, (state, { user }) => ({ ...state, user, isAuthenticated: true })),
    on(retrieveAuthenticatedUserFailure, (state, { message }) => ({
        ...state,
        error: message,
        isAuthenticated: false,
    })),
    on(setUserIsAuthenticated, (state, { isAuthenticated }) => {
        const newState = {
            ...state,
            isAuthenticated,
        };
        if (!isAuthenticated) {
            newState.user = {} as User;
        }
        return newState;
    }),
    on(signOut, state => ({ ...state, isAuthenticated: false })),
    on(signOutSuccess, () => initialState),
);
