import { SortOrders } from './sort-orders';
import { UserRole, UserStatus, UserType } from './user';

export const lazyLoadingUsersLimit = 20;

export enum UsersFilterSortBy {
    Name = 'firstName', // TODO: change to displayName after release
    Email = 'email',
    LastLogin = 'lastLogin',
}

export enum CollectionSortUserOptions {
    Frequency = 'frequency',
    Name = 'firstName',
}

export const usersSortByMap = {
    [UsersFilterSortBy.Name]: 'User',
    [UsersFilterSortBy.Email]: 'Email',
    [UsersFilterSortBy.LastLogin]: 'Last login',
};

export interface UserFilters {
    roles?: UserRole[];
    statuses?: UserStatus[];
    types?: UserType[];
    sortBy?: UsersFilterSortBy;
    sortOrder?: SortOrders;
    collectionsSortOption?: CollectionSortUserOptions;
    collectionsSortOrder?: SortOrders;
}

export const initialUserFilters: UserFilters = {
    roles: [],
    statuses: [],
    types: [],
    sortBy: UsersFilterSortBy.Name,
    sortOrder: SortOrders.Asc,
    collectionsSortOption: CollectionSortUserOptions.Name,
    collectionsSortOrder: SortOrders.Asc,
};

export interface UserQueryFilters extends UserFilters {
    search: string;
    searchOption: string;
    lastUserId?: string;
}

export enum UserSearchCriteria {
    UserEmailId = 'email',
    UserName = 'UserName',
}

export const userSearchCriteriaMap = {
    [UserSearchCriteria.UserName]: 'User name',
    [UserSearchCriteria.UserEmailId]: 'Email',
};

export const initialUserSearchObject = {
    searchByControl: UserSearchCriteria.UserEmailId,
};
