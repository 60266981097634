import { ChangeDetectionStrategy, Component } from '@angular/core';

import {
    buttonLabels,
    createSessionImageUrl,
    DialogSelectionOption,
    selectSessionImageUrl,
} from '@accenture/shared/data';
import { LoadedDescription } from '@accenture/shared/ui';

import { UseTemplateDialogTT9Facade } from './use-template-dialog-facade-tt9';

@Component({
    selector: 'accenture-use-template-dialog-tt9',
    templateUrl: './use-template-dialog-tt9.component.html',
    styleUrls: ['./use-template-dialog-tt9.component.scss'],
    providers: [UseTemplateDialogTT9Facade],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UseTemplateDialogTT9Component {
    vm$ = this.facade.vm$;

    loaderText!: LoadedDescription;

    constructor(private facade: UseTemplateDialogTT9Facade) {}

    closeDialog(): void {
        this.facade.closeDialog();
    }

    openCreateDialog(selectedSessionId: string): void {
        this.facade.closeDialog();
        if (selectedSessionId) {
            this.facade.openCreateSessionDialog();
        }
    }

    openSelectDialog(selectedSessionId: string): void {
        this.facade.closeDialog();
        if (selectedSessionId) {
            this.facade.openAddSessionDialog();
        }
    }

    getSelectionOptions(useTemplateId: string): DialogSelectionOption[] {
        return [
            {
                name: buttonLabels.createNewSession,
                imageUrl: createSessionImageUrl,
                callBack: () => this.openCreateDialog(useTemplateId),
            },
            {
                name: buttonLabels.selectExistingSession,
                imageUrl: selectSessionImageUrl,
                callBack: () => this.openSelectDialog(useTemplateId),
            },
        ];
    }
}
