import { Timestamp } from 'firebase/firestore';

import { removeEmptyKeys } from '@accenture/shared/util';

export class SessionRecordingDisclaimer {
    id?: string;
    userId?: string;
    displayName: string;
    imageUrl?: string;
    email?: string;
    created: Timestamp;
    updated: Timestamp;

    constructor(message: Partial<SessionRecordingDisclaimer>) {
        this.id = message.id ?? '';
        this.userId = message.userId ?? '';
        this.displayName = message.displayName ?? '';
        this.email = message.email ?? '';
        this.imageUrl = message.imageUrl ?? '';
        this.created = message.created ?? Timestamp.now();
        this.updated = message.updated ?? Timestamp.now();
    }

    createSerializableObject(): Record<string, unknown> {
        return removeEmptyKeys({
            userId: this.userId,
            displayName: this.displayName,
            imageUrl: this.imageUrl,
            email: this.email,
            created: this.created,
            updated: this.updated,
        });
    }
}
