import { Question } from '../question';
import { FileType } from '../responses';
import { ActivityItemType } from './activity-item-type';

export class VideoTypeOptions {
    [id: string]: FileType;
}

export class VideoOptions {
    videos: VideoTypeOptions;

    constructor(data: any) {
        this.videos = data?.videos || {};
    }
}

export class Video extends Question {
    type = ActivityItemType.Video;

    options: VideoOptions;

    constructor(data: any) {
        super(data);

        const options = data.options ?? {};
        this.options = new VideoOptions(options);
    }

    createSerializableObject(): Record<string, unknown> {
        return {
            ...super.createSerializableObject(),
        };
    }
}
