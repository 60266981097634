<ng-container *ngIf="vm$ | async as vm">
  <ng-container *accentureLoaded="!vm.isLoading; description: loaderText">
    <accenture-base-dialog [title]="title">
      <div class="d-flex-column">
        <div class="p-32">
          <mat-form-field appearance="outline" class="mat-form-field tt9-form-field textarea width-100">
            <textarea
              matInput
              #userInput
              cdkTextareaAutosize
              #autosize="cdkTextareaAutosize"
              rows="19"
              cdkAutosizeMaxRows="19"
              type="text"
              [placeholder]="placeholderPromptText"
              [value]="transcription"
              class="summary-textarea"
            ></textarea>
            <mat-error>MAT ERROR</mat-error>
          </mat-form-field>
        </div>
        <div class="accenture-dialog-actions actions-buttons">
          <div class="actions-buttons-left d-flex">
            <button class="tt9-btn-ghost ghost-btn" (click)="reRunTranscription()">
              <mat-icon>restart_alt</mat-icon>
              Re-run Transcription
            </button>
          </div>

          <div class="actions-buttons-right d-flex">
            <button class="tt9-btn-default mr-8" (click)="closeDialog()">Close</button>

            <button class="tt9-btn" (click)="saveChanges(userInput.value)">Save</button>

            <button
              *ngIf="mediaType === mediaTypes.AudioVideo"
              class="tt9-btn ml-8"
              (click)="downloadFile(userInput.value)"
            >
              Save and Download Video
            </button>
          </div>
        </div>
      </div>
    </accenture-base-dialog>
  </ng-container>
</ng-container>
