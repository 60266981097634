import { ComponentType } from '@angular/cdk/overlay';
import { inject,Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';

import { Dictionary } from '@accenture/shared/data';

import { TextSnackBarComponent } from '../components/snack-bars/text-snack-bar/text-snack-bar.component';
import { snackBarDuration } from '../constants';
import { SnackBarComponentData, SnackBarTypes } from '../models/snackBar';

@Injectable({
    providedIn: 'root',
})
export class SnackbarService {
    private snackBar: MatSnackBar = inject(MatSnackBar);

    iconNamesMap: Dictionary<string> = {
        [SnackBarTypes.Info]: 'icon-error',
        [SnackBarTypes.Success]: 'icon-done',
        [SnackBarTypes.Error]: 'icon-warning',
        [SnackBarTypes.Warning]: 'icon-alert-triangle',
        [SnackBarTypes.Notification]: 'icon-error',
        [SnackBarTypes.InfoWarning]: 'icon-alert-triangle',
    };

    showCustomSnackBar<T, D>(
        component: ComponentType<T>,
        data: D,
        type: SnackBarTypes,
        duration = snackBarDuration,
        customPanelClass?: string,
    ): MatSnackBarRef<T> {
        const { panelClass, verticalPosition, iconClass, actionButtonClass, horizontalPosition }
            = this.parametersToUse(type);

        return this.snackBar.openFromComponent(component, {
            data: { ...data, iconClass, actionButtonClass, iconName: this.iconNamesMap[type] },
            duration,
            panelClass: customPanelClass ? [customPanelClass, panelClass] : panelClass,
            verticalPosition,
            horizontalPosition,
        });
    }

    //If you want to show a 3-step notification, it is better to use the methods showInfoSnackBar, showSuccessSnackBar, showErrorSnackBar
    showSnackBar(
        title: string | null,
        message: string,
        type: SnackBarTypes,
        autoDismiss?: boolean,
        isVisibleDoNotShowAgain?: boolean,
        hideCloseButton?: boolean,
        onClickDoNotShowAgain?: () => void,
        duration = snackBarDuration,
        customPanelClass?: string,
    ): MatSnackBarRef<TextSnackBarComponent> {
        const { panelClass, verticalPosition, iconClass, actionButtonClass, horizontalPosition }
            = this.parametersToUse(type);
        const snackBar = this.snackBar.openFromComponent(TextSnackBarComponent, {
            data: {
                message,
                title,
                iconClass,
                actionButtonClass,
                isVisibleDoNotShowAgain,
                hideCloseButton,
                onClickDoNotShowAgain,
                iconName: this.iconNamesMap[type],
            },
            panelClass: customPanelClass ? [panelClass, customPanelClass] : panelClass,
            verticalPosition: verticalPosition,
            horizontalPosition: horizontalPosition,
        });
        if (autoDismiss) {
            // per design requests, some snackbars need to dismiss after 5 seconds
            setTimeout(() => snackBar.dismiss(), duration);
        }

        return snackBar;
    }

    showInfoSnackBar(title: string, message: string, autoDismiss = false): MatSnackBarRef<TextSnackBarComponent> {
        return this.showSnackBar(title, message, SnackBarTypes.Info, autoDismiss);
    }

    showSuccessSnackBar(title: string, message: string, autoDismiss = true): MatSnackBarRef<TextSnackBarComponent> {
        return this.showSnackBar(title, message, SnackBarTypes.Success, autoDismiss);
    }

    showErrorSnackBar(title: string, message: string, autoDismiss = true): MatSnackBarRef<TextSnackBarComponent> {
        return this.showSnackBar(title, message, SnackBarTypes.Error, autoDismiss);
    }

    parametersToUse(snackBarType: SnackBarTypes): SnackBarComponentData {
        switch (snackBarType) {
            default:
            case SnackBarTypes.Info:
                return {
                    panelClass: 'tt9-snack-bar',
                    iconClass: 'tt9-snack-bar-icon',
                    actionButtonClass: 'tt9-snack-bar-action-btn-info',
                    verticalPosition: 'bottom',
                    horizontalPosition: 'right',
                };
            case SnackBarTypes.Success:
                return {
                    panelClass: 'tt9-snack-bar-success',
                    iconClass: 'tt9-snack-bar-success-icon',
                    actionButtonClass: 'tt9-snack-bar-action-btn-success',
                    verticalPosition: 'bottom',
                    horizontalPosition: 'right',
                };
            case SnackBarTypes.Error:
                return {
                    panelClass: 'tt9-snack-bar-error',
                    iconClass: 'tt9-snack-bar-error-icon',
                    actionButtonClass: 'tt9-snack-bar-action-btn-error',
                    verticalPosition: 'bottom',
                    horizontalPosition: 'right',
                };
            case SnackBarTypes.Warning:
                return {
                    panelClass: 'tt9-snack-bar-warning',
                    iconClass: 'tt9-snack-bar-warning-icon',
                    actionButtonClass: 'tt9-snack-bar-action-btn-warning',
                    verticalPosition: 'bottom',
                    horizontalPosition: 'right',
                };
            case SnackBarTypes.Notification:
                return {
                    panelClass: 'tt9-snack-bar-notification',
                    iconClass: 'tt9-snack-bar-notification-icon',
                    actionButtonClass: 'tt9-snack-bar-action-btn-notification',
                    verticalPosition: 'top',
                    horizontalPosition: 'right',
                };
            case SnackBarTypes.InfoWarning:
                return {
                    panelClass: 'tt9-snack-bar-warning-wide',
                    iconClass: 'tt9-snack-bar-warning-icon',
                    actionButtonClass: 'tt9-snack-bar-action-btn-warning',
                    verticalPosition: 'bottom',
                    horizontalPosition: 'center',
                };
        }
    }
}
