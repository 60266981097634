export const invitationText = {
    inviteAdmins:
        'Invite admins and members by sending email. They should log in via Accenture SSO. All non-Accenture users should be invited directly to in the sessions',
    admins: 'Admins',
    inviteAdminsInfo:
        'Project admins will automatically become leaders in all the sessions. They can manage the whole project including sessions and project team',
    members: 'Members',
    inviteMembers:
        'Project members can be either leaders or participants in the sessions. Session leaders can create and manage activities in sessions. Session participants can collaborate and leave responses in the activities.',
};
