// eslint-disable-next-line @nx/enforce-module-boundaries
import QRCodeStyling from 'qr-code-styling';

import { logoUrl } from '../constants';

export class QrCodeGenerator {
    static async generateQRCodeImage(data: string): Promise<string> {
        try {
            const canvas = document.createElement('canvas');
            const qrCode = this.createQRCode(data);
            const blob = await qrCode.getRawData('png');
            const dataUrl = await this.convertBlobToDataUrl(blob as Blob);
            await this.drawQRCodeOnCanvas(canvas, dataUrl);
            return canvas.toDataURL('image/png');
        } catch (error) {
            console.error(`Error generating QR code: ${error}`);
            throw error;
        }
    }

    static createQRCode(data: string): QRCodeStyling {
        return new QRCodeStyling({
            width: 250,
            height: 250,
            type: 'svg',
            data: data,
            image: logoUrl,
            margin: 3,
            qrOptions: {
                typeNumber: 0,
                mode: 'Byte',
                errorCorrectionLevel: 'H',
            },
            imageOptions: {
                hideBackgroundDots: true,
                imageSize: 0.3,
                margin: 0,
            },
            dotsOptions: {
                type: 'extra-rounded',
                color: '#0c0309',
                gradient: {
                    type: 'linear',
                    rotation: 0.22689280275926282,
                    colorStops: [
                        { offset: 0, color: '#7500C0' },
                        { offset: 1, color: '#B455AA' },
                    ],
                },
            },
            backgroundOptions: { color: '#ffffff' },
            cornersSquareOptions: {
                type: 'extra-rounded',
                color: '#B455AA',
            },
            cornersDotOptions: { type: 'square', color: '#7500C0' },
        });
    }

    static convertBlobToDataUrl(blob: Blob): Promise<string> {
        return new Promise<string>((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result as string);
            reader.onerror = reject;
            reader.readAsDataURL(blob);
        });
    }

    static drawQRCodeOnCanvas(canvas: HTMLCanvasElement, dataUrl: string): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            const ctx = canvas.getContext('2d');
            if (!ctx) {
                reject(new Error('Failed to get canvas context'));
                return;
            }

            const qrSize = 250;
            const borderSize = 4;
            const padding = 8;
            const radius = 20;
            const offset = 2;
            const maskOffset = 120;
            const totalSize = qrSize + 2 * (borderSize + padding);
            const totalCanvasSize = totalSize + 2 * padding;
            canvas.width = totalCanvasSize;
            canvas.height = totalCanvasSize;
            const drawRoundedRect = (
                x: number,
                y: number,
                width: number,
                height: number,
                radius: number,
                fillStyle: string,
                strokeStyle: string,
                offset: number,
            ): void => {
                ctx.fillStyle = fillStyle;
                ctx.strokeStyle = strokeStyle;
                ctx.lineWidth = borderSize;
                ctx.beginPath();
                ctx.moveTo(x + radius, y);
                ctx.arcTo(x + width, y, x + width, y + height, radius);
                ctx.arcTo(x + width, y + height, x, y + height, radius);
                ctx.arcTo(x, y + height, x, y, radius);
                ctx.arcTo(x, y, x + width, y, radius);
                ctx.closePath();
                ctx.fill();
                ctx.stroke();
            };

            const maskCenter = (x: number, y: number, width: number, height: number) => {
                ctx.save();
                ctx.clearRect(x - width / 2, y - height / 2, width, height);
                ctx.fillStyle = '#ffffff';
                ctx.fillRect(x - width / 2, y - height / 2, width, height);
                ctx.restore();
            };

            ctx.fillStyle = '#ffffff';
            ctx.fillRect(0, 0, totalCanvasSize, totalCanvasSize);
            drawRoundedRect(
                padding + offset,
                padding + offset,
                totalSize - offset * 2,
                totalSize - offset * 2,
                radius,
                '#ffffff',
                '#7500C0',
                offset,
            );

            maskCenter(totalCanvasSize / 2, padding + offset, maskOffset, borderSize); // Top center
            maskCenter(totalCanvasSize / 2, totalCanvasSize - padding - offset, maskOffset, borderSize); // Bottom center
            maskCenter(padding + offset, totalCanvasSize / 2, borderSize, maskOffset); // Left center
            maskCenter(totalCanvasSize - padding - offset, totalCanvasSize / 2, borderSize, maskOffset); // Right center
            ctx.save();
            ctx.beginPath();
            ctx.moveTo(padding + borderSize + radius, padding + borderSize);
            ctx.arcTo(
                totalCanvasSize - padding - borderSize,
                padding + borderSize,
                totalCanvasSize - padding - borderSize,
                totalCanvasSize - padding - borderSize,
                radius,
            );
            ctx.arcTo(
                totalCanvasSize - padding - borderSize,
                totalCanvasSize - padding - borderSize,
                padding + borderSize,
                totalCanvasSize - padding - borderSize,
                radius,
            );
            ctx.arcTo(
                padding + borderSize,
                totalCanvasSize - padding - borderSize,
                padding + borderSize,
                padding + borderSize,
                radius,
            );
            ctx.arcTo(
                padding + borderSize,
                padding + borderSize,
                totalCanvasSize - padding - borderSize,
                padding + borderSize,
                radius,
            );

            ctx.closePath();
            ctx.clip();
            ctx.fillStyle = '#ffffff';
            ctx.fillRect(
                padding + borderSize,
                padding + borderSize,
                totalSize - 2 * borderSize,
                totalSize - 2 * borderSize,
            );

            const image = new Image();
            image.onload = () => {
                ctx.drawImage(image, padding + borderSize + padding, padding + borderSize + padding, qrSize, qrSize);
                resolve();
            };
            image.onerror = reject;
            image.src = dataUrl;
            ctx.restore();
        });
    }
}
