import { Timestamp } from 'firebase/firestore';

import { removeNoValuesKeys } from '@accenture/shared/util';

import { FileType } from './responses';
import { TeamMember } from './team-member';

export class OwnershipHistory {
    id: string;
    displayName: string;
    imageUrl?: string;
    email: string;
    userId: string;

    fromDate: Timestamp;
    toDate: Timestamp;

    comment?: string;
    file?: FileType;

    constructor(ownershipHistory: OwnershipHistory);
    constructor(teamMember: TeamMember, comment?: string, file?: FileType);
    constructor(document: TeamMember | OwnershipHistory, comment?: string, file?: FileType) {
        if ('fromDate' in document) {
            // if OwnershipHistory doc
            const { id, comment, file: historyFile } = document as OwnershipHistory;
            this.id = id;

            this.comment = comment;
            this.file = historyFile;
            this.fromDate = document.fromDate;
            this.toDate = document.fromDate;
        } else {
            this.comment = comment || '';
            this.file = file || undefined;
        }

        this.displayName = document.displayName;
        this.imageUrl = document.imageUrl || '';
        this.email = document.email;
        this.userId = document.userId;
    }

    createSerializableObject() {
        return removeNoValuesKeys({
            userId: this.userId,
            displayName: this.displayName,
            imageUrl: this.imageUrl,
            email: this.email,
            fromDate: this.fromDate,
            toDate: this.toDate,
            comment: this.comment,
            file: this.file,
        });
    }
}
