import { Injectable } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Timestamp } from 'firebase/firestore';
import { BehaviorSubject, combineLatest, map, Observable, startWith, switchMap, tap } from 'rxjs';

import { ActivityService } from '@accenture/activity/shared/domain';
import {
    saveActivityToSessionInfoSnackBarNew,
    saveActivityToSessionSuccessSnackBarNew,
    SessionService,
} from '@accenture/erp-deployment/shared/domain';
import { AppState, selectAuthenticatedUserId } from '@accenture/global-store';
import {
    addingTemplateErrorSnackbarTitle,
    addingTemplateInProgressSnackbarTitle,
    errorMessageSnackbarText,
    errorMessageTemplatesText,
    ParentType,
    Session,
    templateAddedSnackbarTitle,
    UserSession,
} from '@accenture/shared/data';
import { DialogService, SnackbarService, SnackBarTypes } from '@accenture/shared/ui';

import { AddSessionDialogComponent } from '../..';
import { UseTemplateStore } from '../component-stores/use-template.store';
import { UseTemplateDialogTT9Component } from '../use-template-dialog-tt9/use-template-dialog-tt9.component';

export interface AddActivityTemplateToSessionDialogViewModel {
    templateType: string;
    sessions: UserSession[];
    searchValue: string;
    selectedSessionId: string;
    isLoading: boolean;
}

const defaultViewModel: AddActivityTemplateToSessionDialogViewModel = {
    templateType: '',
    sessions: [],
    searchValue: '',
    selectedSessionId: '',
    isLoading: false,
};

@Injectable()
export class AddActivityTemplateToSessionDialogTT9Facade {
    private searchValue$ = new BehaviorSubject<string>('');
    private selectedSessionId$ = new BehaviorSubject<string>('');
    private isLoading$ = new BehaviorSubject<boolean>(false);

    vm$ = this.buildViewModel();

    private userId!: string;
    private usePublicAccessId: string;
    private useTemplateId: string;
    private useTemplateType: ParentType;

    constructor(
        private store: Store<AppState>,
        private sessionService: SessionService,
        private useTemplateStore: UseTemplateStore,
        private activityService: ActivityService,
        private dialogRef: MatDialogRef<AddSessionDialogComponent>,
        private dialogService: DialogService,
        private snackbarService: SnackbarService,
        private router: Router,
    ) {}

    closeDialog(): void {
        this.dialogRef.close();
    }

    updateSearchValue(value: string): void {
        this.searchValue$.next(value);
    }

    updateSelectedTemplates(value: string): void {
        this.selectedSessionId$.next(value);
    }

    openUseTemplateModal(): void {
        this.closeDialog();
        this.dialogService.open(UseTemplateDialogTT9Component, {
            width: 'auto',
            panelClass: 'tt9-modal',
        });
    }

    async addPublicActivityTemplateToSession(sessionId: string) {
        this.showSnackBar(SnackBarTypes.Info);
        this.closeDialog();

        try {
            await this.activityService.addActivityToSessionNew(
                this.useTemplateId,
                sessionId,
                this.userId,
                ParentType.Sessions,
                this.useTemplateType,
                this.usePublicAccessId,
                undefined,
                true,
            );

            this.showSnackBar(SnackBarTypes.Success);
            this.router.navigate(['dashboard/sessions', sessionId]);
        } catch (e) {
            console.error(errorMessageTemplatesText.publicActivityTemplate);
            this.showSnackBar(SnackBarTypes.Error);
        }
    }

    async addActivityTemplateToSession(sessionId: string): Promise<void> {
        this.showSnackBar(SnackBarTypes.Info);
        this.closeDialog();
        try {
            await this.activityService.addActivityToSessionNew(
                this.useTemplateId,
                sessionId,
                this.userId,
                ParentType.Sessions,
                this.useTemplateType,
                undefined,
                undefined,
                true,
            );
            this.showSnackBar(SnackBarTypes.Success);
            this.router.navigate(['dashboard/sessions', sessionId]);
        } catch (e) {
            console.error(errorMessageTemplatesText.activityTemplate);
            this.showSnackBar(SnackBarTypes.Error);
        }
    }

    private buildViewModel(): Observable<AddActivityTemplateToSessionDialogViewModel> {
        return combineLatest([
            this.useTemplateStore.useTemplateId$,
            this.useTemplateStore.useTemplateType$,
            this.useTemplateStore.usePublicAccessId$,
            this.getUser(),
        ]).pipe(
            tap(([useTemplateId, useTemplateType, usePublicAccessId]) =>
                this.setActualIdsData(useTemplateId, usePublicAccessId, useTemplateType),
            ),
            switchMap(() => {
                return combineLatest([
                    this.sessionService.getUserSessionsById(this.userId),
                    this.searchValue$,
                    this.selectedSessionId$,
                    this.isLoading$,
                ]).pipe(
                    switchMap(([userSessions, searchValue, selectedSessionId, isLoading]) => {
                        return this.sessionService
                            .getSessionsByIdsNew(
                                ParentType.Sessions,
                                userSessions.map((userSession) => userSession.sessionId),
                            )
                            .pipe(
                                map((sessions) => {
                                    return {
                                        searchValue,
                                        selectedSessionId,
                                        isLoading,
                                        templateType: this.useTemplateType,
                                        sessions: this.getFilteredTemplates(
                                            this.transformSessions(userSessions, sessions),
                                            searchValue,
                                        ),
                                    };
                                }),
                            );
                    }),
                );
            }),
            startWith(defaultViewModel),
        );
    }

    private setActualIdsData(useTemplateId: string, usePublicAccessId: string, useTemplateType: ParentType): void {
        this.usePublicAccessId = usePublicAccessId;
        this.useTemplateId = useTemplateId;
        this.useTemplateType = useTemplateType;
    }

    private getUser(): Observable<string> {
        return this.store.pipe(
            select(selectAuthenticatedUserId),
            tap((userId) => (this.userId = userId)),
        );
    }

    private getFilteredTemplates(templates: UserSession[], searchValue: string): UserSession[] {
        return templates.filter((template) => {
            const hasSearchName = template.name?.toLowerCase().includes(searchValue);
            const hasSearchDescription = template.description?.toLowerCase().includes(searchValue);

            return hasSearchName || hasSearchDescription;
        });
    }

    private showSnackBar(snackBarType: SnackBarTypes): void {
        switch (snackBarType) {
            case SnackBarTypes.Info:
                this.snackbarService.showSnackBar(
                    addingTemplateInProgressSnackbarTitle,
                    saveActivityToSessionInfoSnackBarNew,
                    SnackBarTypes.Info,
                );
                break;
            case SnackBarTypes.Success:
                this.snackbarService.showSnackBar(
                    templateAddedSnackbarTitle,
                    saveActivityToSessionSuccessSnackBarNew,
                    SnackBarTypes.Success,
                    true,
                );
                break;
            case SnackBarTypes.Error:
                this.snackbarService.showSnackBar(
                    addingTemplateErrorSnackbarTitle,
                    errorMessageSnackbarText,
                    SnackBarTypes.Error,
                    true,
                );
                break;
        }
    }

    // fill up userSession with session values if missing
    private transformSessions(userSessions: UserSession[], sessions: Session[]): UserSession[] {
        return userSessions
            .map((userSession) => {
                const sessionDetails = sessions.find((session) => session.id === userSession.sessionId);

                return {
                    ...userSession,
                    ...(!userSession.updated && {
                        updated: sessionDetails.updated,
                        lastViewed: sessionDetails.updated,
                        created: sessionDetails.created,
                    }),
                    ...(!userSession.description && { description: sessionDetails?.description }),
                    ...(!userSession.creatorName && { creatorName: sessionDetails?.creatorName }),
                    ...(!userSession.creatorId && { creatorId: sessionDetails?.creatorId }),
                };
            })
            .sort((a, b) => {
                return (b.created as Timestamp).toMillis() - (a.created as Timestamp).toMillis();
            });
    }
}
